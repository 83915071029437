import React, {useEffect, useState} from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import { Box, Select } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
/**
 * 처방전 선택
 * @param {*} props 
 */
export default function FilterSelect(props) {
    const classes = useStyles()
    const [filterTypes, setFilterTypes ] = useState([])
    const [currentFilter, setCurrentFilter] = useState('all')
   
    useEffect(() => {
        setFilterTypes(props.filterTypes)
    },[props.filterTypes])
    
    return (
        <Box className={classes.root}>
            <Select
                defaultValue={'all'}
                className={classes.underline}
                onChange={(event)=>{
                    setCurrentFilter(event.target.value)
                    const selected = filterTypes.find(f => f.programID === event.target.value)
                    if(props.onChange) {
                        props.onChange(selected)
                    }
                }}>
                <MenuItem value={'all'}>
                    <span className={'title'}>프로그램 - 전체</span>
                </MenuItem>
                {filterTypes.map((filterType)=>{
                    return (
                        <MenuItem
                            key={filterType.id}
                            value={filterType.programID}>
                            <span className={'title'}>{filterType.name}</span>
                        </MenuItem>
                    )
                })}
            </Select>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        border: '1px solid #BEBEBE',
        padding: 10,
        
        '& .MuiInput-underline::before': {
            
        },
        '& .MuiSelect-selectMenu': {
            paddingLeft: 10,
            paddingTop: 0,
            paddingBottom: 0
        },
        '& .title': {
            fontWeight: 'bold'
        },
        '& .sub': {
            fontSize:14, 
            paddingLeft: 10,
        },
        '& .MuiSelect-icon': {
            color: '#0055BF',
        },
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
    },
    active: {
        backgroundColor: '#0055BF',
        colo: 'white'
    }
 
}));
