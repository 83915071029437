import React from 'react';
import ReactDOM from 'react-dom';
import * as Three from 'three';
import CameraControls from 'camera-controls';
import Chroma from 'chroma-js';
import DownArrow from '@assets/exercise/fft/downarrow.png';
import UpArrow from '@assets/exercise/fft/uparrow.png';
import TextSprite from '@seregpie/three.text-sprite';

export default class FFTChart extends React.Component {
    container;
    canvas;
    labels;
    arrows;
    
    cameraControls;
    clock;
    renderer;
    scene;
    camera;
    
    graphColors;
    graphGroup;
    graphMaterial;
    graphWireFrame;
    graphWireFrameColor;
    graphWireFrameMaterial;
    
    gridSize;
    
    timeCount;
    timeGuide;
    timeGuideColor;
    timeGuideUnits;
    timeGuideInterval = 40;

    //remote queue first data.
    maxTimeline;

    hzCount;
    hzGuide;
    hzGuideColor;
    hzGuideUnits;

    mvCount;
    mvSize;
    mvGuide;
    mvGuideColor;
    mvGuideUnits;
    mvGuidLabel;

    mvTooltip;
    mvTooltipLabel;
    
    timeHzGrid;
    timeHzGridColor;
    
    timeMvGrid;
    timeMvGridColor;
    
    mvHzGrid;
    mvHzGridColor;

    mvArrows;
    
    cone;
    orderIndex = 0;
    
    guidArrow;
    arrowImage;

    fftGuide = true;
    fftGuidHz = 20;
    fftGuidMv = 7.5;
    fftGuidArrowInterval = 5;
    fftGuidHeightOffset = 10;

    upscaleRate = []
    noise = [0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01]
    mvScalePercent = 0
    noiseCode = 'A'
    currentCamera = {
        target: {},
        position: {}
    }
    cameraPosition = {
        'top' : [13.141295151810011, 52.10236980207323, 11.519514539669107 , 13.146839924676529, -0.5551849352364479, 8.680803504943114],
        'front': [-25.022521583229242, 33.42567198609965, 9.039939014915756 , 16.59763651663131, 1.0430807867142262, 9.121234476308414],
        'side': [0.21674467046534396, 29.43046849434464, 42.60456873922079 , 16.161836109028254, 0.5120702142248988, 1.4906212040572335]
    }
    defaultBoxMaterial = new Three.MeshPhysicalMaterial({
        // vertexColors: Three.VertexColors,
        side: Three.DoubleSide,
        flatShading: true,
        color: new Three.Color( 0x0c058e )

    });
    
    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
            
        CameraControls.install( { THREE: Three } );
        const options = props.options || {};
         // Initialize options
        this.graphColors = options.graphColors
                            ? options.graphColors 
                            : Chroma.scale(['0000ff','00efff','ffffff', '00d917','f3ff00','f800f0', 'ff0000', '860101']).mode('lch')

    
        this.timeGuideInterval = options.timeGuideInterval === undefined ? 40 : options.timeGuideInterval;
        this.graphWireFrame = options.graphWireFrame === undefined ? false : options.graphWireFrame;
        
        if (this.graphWireFrame === true) {
            this.graphWireFrameColor = options.graphWireFrameColor || 0x666666;
        }
        
        this.gridSize = options.gridSize || 1;
        
        this.timeCount = options.timeCount || 120;
        this.timeGuide = options.timeGuide === undefined ? true : options.timeGuide;
        
        if (this.timeGuide === true) {
            this.timeGuideColor = options.timeGuideColor || 0x888888;
            this.timeGuideUnits = options.timeGuideUnits || [ 40, 80, 120];
        }

        this.hzCount = options.hzCount || 30;
        this.hzGuide = options.hzGuide === undefined ? true : options.hzGuide;
        
        if (this.hzGuide === true) {
            this.hzGuideColor = options.hzGuideColor || 0x888888;
            this.hzGuideUnits = options.hzGuideUnits || [ 0, 3, 7, 12, 18, 28, 38 ];
        }

        this.mvCount = options.mvCount || 50;
        this.mvSize = options.mvSize || 1;
        this.mvGuide = options.mvGuide === undefined ? true : options.mvGuide;
        
        if (this.mvGuide === true) {
            this.mvGuideColor = options.mvGuideColor || 0x888888;
            this.mvGuideUnits = options.mvGuideUnits || [ 10, 20, 30, 40, 50 ];
            this.mvGuideLabels = [];
        }
        
        this.timeHzGrid = options.timeHzGrid === undefined ? true : options.timeHzGrid;
        
        if (this.timeHzGrid === true) {
            this.timeHzGridColor = options.timeHzGridColor || 0xCCCCCC;
        }
        
        this.timeMvGrid = options.timeMvGrid === undefined ? true : options.timeMvGrid;
        
        if (this.timeMvGrid === true) {
            this.timeMvGridColor = options.timeMvGridColor || 0xCCCCCC;
        }
        
        this.mvHzGrid = options.mvHzGrid === undefined ? true : options.mvHzGrid;
        
        if (this.mvHzGrid === true) {
            this.mvHzGridColor = options.mvHzGridColor || 0xCCCCCC;
        }


        this.maxTimeline = options.maxTimeline === undefined ? 120 : options.maxTimeline; 
        
        this.cone = options.cone === undefined ? true : options.cone;
        this.cone = false

        this.mvTooltip = false;

        this.fftGuide = options.fftGuide === undefined ? false : options.fftGuide;
        this.mvArrows = [];
        
        this.noiseCode = options.noiseCode
        
        if(null !== props.label){
            this.mvGuidLabel = props.label
        }
    }
    
    /**
     * After render
     */
    componentDidMount() {
        this.container = ReactDOM.findDOMNode(this);
        this.canvas = this.container.querySelector('canvas');
        this.labels = this.container.querySelector('.labels');
        this.arrows = this.container.querySelector('.arrows');

        // Initialize 3D objects
        this.init3D();
        this.drawGuide();

        requestAnimationFrame(this.renderChart.bind(this));
        this.renderer.render(this.scene, this.camera);
    }
    
    componentWillUnmount() {
        this.graphGroup = []
    }

    /**
     * Render
     */
    render() {
        return (
            <div className="fft-chart-container">
                <canvas/>
                <div className="labels"/>
                <div className="arrows"/>
            </div>
        );
    }
    
    /**
     * Initialize 3D objects
     */
    init3D() {
        this.clock = new Three.Clock();

        // Renderer
        this.renderer = new Three.WebGLRenderer({
            canvas: this.canvas,
            antialias: true,
            alpha: true,
            preserveDrawingBuffer: true 
        });
        this.renderer.setClearColor( 0x000000, 0 )
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
        
        // Scene
        this.scene = new Three.Scene();
        
        // Camera
        const width  = this.canvas.width;
        const height = this.canvas.height;
        // const aspectRatio = 0.3;
        // this.camera = new Three.OrthographicCamera(width / -60, width /60, height /60, height / -60, 1, 1000);
        this.camera = new Three.OrthographicCamera(
                                        width * 0.8 / -60, 
                                        width * 0.8 / 60, 
                                        height /60, 
                                        height / -60, 1, 1000);

        // Camera controls
        this.cameraControls = new CameraControls(this.camera, this.renderer.domElement);
        this.cameraControls.mouseButtons.left = CameraControls.ACTION.TRUCK;
        this.cameraControls.mouseButtons.right = CameraControls.ACTION.ROTATE;
        this.cameraControls.mouseButtons.wheel = CameraControls.ACTION.ZOOM;
        this.cameraControls.enabled = false;

        this.cameraControls.zoom(-0.550722441671332, false); 
        this.cameraControls.setLookAt(25.753368017821238,
                                        22.63147035812126,
                                        55.80408941153715,
                                        43.309311401460214,
                                        2.0517571804350543,
                                        10.536654765534694,);
        this.cameraControls.update( this.clock.getDelta());
        
        
        // this.cameraControls.addEventListener('update',(val) => {
        //     // console.log(val);
        //     console.log('position' , this.cameraControls.getPosition());
        //     console.log('zoom' , val.target._camera.zoom);
        //     console.log('targete' , val.target._target);
        //     console.log('');
        // })
        
        this.cameraControls.addEventListener('update',(val) => {
            // const p = this.cameraControls.getPosition()
            // const t = val.target._target
            //
            // console.log(`${p.x}, ${p.y}, ${p.z} , ${t.x}, ${t.y}, ${t.z}`)
            // console.log('zoom' , val.target._camera.zoom);
            // console.log('');

            this.currentCamera.target = val.target._target
            this.currentCamera.position = this.cameraControls.getPosition()
            if(this.cameraUpdate){
                this.cameraUpdate(this.currentCamera)
            }
        })

        

        // Chart object
        this.graphGroup = new Three.Object3D();
        this.graphGroup.position.x = this.gridSize;
        this.scene.add(this.graphGroup);
        
        // Hemi Light
        const hemiLight = new Three.HemisphereLight(0xffffff, 0xffffff, 0.6);
        hemiLight.color.setHSL(0.6, 1, 0.6);
        hemiLight.groundColor.setHSL(0.095, 1, 0.75);
        hemiLight.position.set(0, 50, 0);
        this.scene.add(hemiLight);

        // Direction Light
        const dirLight = new Three.DirectionalLight(0xffffff, 1);
        dirLight.color.setHSL(0.1, 1, 0.95);
        dirLight.position.set(-1, 1.75, 1);
        dirLight.position.multiplyScalar(30);
        dirLight.castShadow = true;
        dirLight.shadow.mapSize.width = 2048;
        dirLight.shadow.mapSize.height = 2048;

        const d = 50;
        
        dirLight.shadow.camera.left = -d;
        dirLight.shadow.camera.right = d;
        dirLight.shadow.camera.top = d;
        dirLight.shadow.camera.bottom = -d;
        
        dirLight.shadow.camera.far = 3500;
        dirLight.shadow.bias = -0.0001;
    
        this.scene.add(dirLight);
    
        // Graph material
        this.graphMaterial = new Three.MeshPhysicalMaterial({
            vertexColors: Three.VertexColors,
            side: Three.DoubleSide,
            flatShading: true,
        });
        
        // Use graph wire frame
        if (this.graphWireFrame === true) {
            this.graphWireFrameMaterial = new Three.LineBasicMaterial({
                color: this.graphWireFrameColor,
                linewidth: 1,
               vertexColors: true,
            });
        }
    };
    
    /**
     * Render chart
     */
    renderChart() {
        if (this.resizeToDisplay()) {
            this.camera.aspect = this.canvas.clientWidth / this.canvas.clientHeight;
            this.camera.updateProjectionMatrix();
        }
    
        // this.translateArrows();

        if(this.cameraControls.enabled){
            const delta = this.clock.getDelta();
            const hasControlsUpdated = this.cameraControls.update( delta );

            requestAnimationFrame(this.renderChart.bind(this));

            if(hasControlsUpdated){
                this.renderer.render(this.scene, this.camera);
            }
        }
    }
    
    /**
     * render canvas once 
     */
    renderChartOnce() {
        this.cameraControls.update( this.clock.getDelta());
        this.renderer.render(this.scene, this.camera);
        requestAnimationFrame(this.renderChart.bind(this));
    }

    /**
     * Resize renderer to display(canvas) size
     * @returns {boolean}
     */
    resizeToDisplay() {
        const width = this.canvas.clientWidth;
        const height = this.canvas.clientHeight;
        const needResize = this.canvas.width !== width || this.canvas.height !== height;
        
        if (needResize) {
            this.renderer.setSize(width, height, false);
        }
        
        return needResize;
    }


    
    /**
     * Set label position
     * @param label
     * @param paddingX
     * @param paddingY
     * @param paddingZ
     */
    setLabelPosition(label, paddingX, paddingY, paddingZ) {
        const { line, text } = label;
        const vector = new Three.Vector3();
       
        if(line){
            line.updateWorldMatrix(true, false);
            line.getWorldPosition(vector);
        }
        
 
        vector.x += paddingX;
        vector.y += paddingY;
        vector.z += paddingZ;
        vector.project(this.camera);

        const transX = (vector.x * .5 + .5) *  this.canvas.clientWidth;
        const transY = (vector.y * -.5 + .5) * this.canvas.clientHeight;

        text.style.display = '';
        text.style.transform = `translate(-50%, -50%) translate(${ transX }px, ${ transY }px)`;
        text.style.zIndex = (-vector.z * .5 + .5) * 100000 | 0;
    }


    translateArrows(){
        for (const arrow of this.mvArrows) {
            this.setImagePosition(arrow, -1, 2, 0);
        }
    }

    setImagePosition(arrow, paddingX, paddingY, paddingZ) {
        const { image, line,  direction } = arrow;
        const vector = new Three.Vector3();
        
        if(line){
            line.updateWorldMatrix(true, false);
            line.getWorldPosition(vector);
        }
    
        vector.x += paddingX;
        vector.y += paddingY + ('up' === direction ? 6 : this.fftGuidHeightOffset);
        vector.z += paddingZ;
        vector.project(this.camera);

        const transX = (vector.x * .5 + .5)  * this.canvas.clientWidth;
        const transY = (vector.y * -.5 + .5) * this.canvas.clientHeight;
        
        image.style.width = '25px';
        image.style.height = '25px';
        image.style.backgroundImage = `url(${'up' === direction? UpArrow : DownArrow})`;
        image.style.backgroundSize = 'contain'
        image.style.display = '';
        image.style.transform = `translate(-50%, -50%) translate(${ transX }px, ${ transY }px)`;
        image.style.zIndex = (-vector.z * .5 + .5) * 100000 | 0;
    }

    /**
     *
     * @param x
     * @param y
     * @param z
     */
    setMvTooltipPosition(x, y, z) {
        const { line, text } = this.mvTooltipLabel;
        const vector = new Three.Vector3();
        
        line.updateWorldMatrix(true, false);
        line.getWorldPosition(vector);
        
        vector.x += x;
        vector.y += y;
        vector.z += z;
        vector.project(this.camera);

        const transX = (vector.x * .5 + .5) * this.canvas.clientWidth;
        const transY = (vector.y * -.5 + .5) * this.canvas.clientHeight;
        
        text.style.display = '';
        text.style.transform = `translate(-50%, -50%) translate(${ transX }px, ${ transY }px)`;
        text.style.zIndex = (-vector.z * .5 + .5) * 100000 | 0;
    }

    /**
     * mv정보 표시
     * @param {*} userData 
     */
    displayTooltipText(userData){
        let text;

        if (!userData){
            text = '';
        } else {
            text = ' ['+userData.time+'초] ' + userData.hz + 'hz : ' + parseFloat(userData.mv * 2).toFixed(2) + 'mV ';
        }
        this.mvTooltipLabel.text.textContent =  text;

    }

    /**
     * Create 2D text
     * @param text
     * @returns {HTMLDivElement}
     */
    create2DText(text) {
        const element = document.createElement('div');
        element.textContent = text;
        
        this.labels.appendChild(element);
        
        return element;
    };

    create2DImage(){
        const element = document.createElement('div');
        this.arrows.appendChild(element);
        return element;
    }
    
    /**
     * Draw guide line
     */
    drawGuide() {
        let geometry, material, line, point, color, i, boxHeight, face, 
            numberOfSides, j, vertexIndex, boxMaterial;
        const faceIndices = [ 'a', 'b', 'c', 'd' ];

        if (this.mvGuide === true) {
            
            // axis
            // geometry = new Three.Geometry();
            // for(i = 0 ; i < this.mvCount; i++){
            //     geometry.vertices.push(new Three.Vector3(0, i * this.mvSize, 0));
            // }
            
            
            // for (i = 0; i < geometry.vertices.length; i++) {
            //     point = geometry.vertices[i];
            //     color = this.graphColors(point.y / 15).rgb();
            //     geometry.colors[i] = new Three.Color(`rgb(${ color[0] }, ${ color[1] }, ${ color[2] })`);
            // }
            
            // material = new Three.LineBasicMaterial({ 
            //     vertexColors: Three.VertexColors, 
            //     linewidth: 4            
            //  });
             
            // this.scene.add(new Three.Line(geometry, material));

            let segments = Math.ceil(50 / 1.5);
            let mvLabelWidth = 1
            segments = segments > 0 ? segments : 1;
            boxHeight = this.mvCount * this.mvSize;
            
            const boxGeometry = new Three.BoxGeometry(mvLabelWidth, boxHeight, 0.01, 1, segments, 1);
            boxGeometry.translate(
                -(mvLabelWidth / 2) ,
                boxHeight / 2,
                0
            );

            for (i = 0; i < boxGeometry.vertices.length; i++) {
                point = boxGeometry.vertices[i];
                color = this.graphColors(point.y / 15).rgb();
                boxGeometry.colors[i] = new Three.Color(`rgb(${ color[0] }, ${ color[1] }, ${ color[2] })`);
            }
            
            for (i = 0; i < boxGeometry.faces.length; i++) {
                face = boxGeometry.faces[i];
                numberOfSides = (face instanceof Three.Face3) ? 3 : 4;
                
                for (j = 0; j < numberOfSides; j++) {
                    vertexIndex = face[faceIndices[j]];
                    face.vertexColors[j] = boxGeometry.colors[vertexIndex];
                }
            }  

            boxMaterial = new Three.MeshPhysicalMaterial({
                vertexColors: Three.VertexColors,
                side: Three.DoubleSide,
                flatShading: true
            });

            const boxMesh = new Three.Mesh(boxGeometry, boxMaterial);
            this.scene.add(boxMesh);
            // if (this.graphWireFrame === true) {
            //     let border = new Three.LineSegments(new Three.EdgesGeometry(boxMesh.geometry), this.graphWireFrameMaterial);
            //     border.renderOrder = 1;
                
            //     boxMesh.add(border);
            // }

            
            // unit
            geometry = new Three.Geometry();
            geometry.vertices.push(new Three.Vector3(0, 0, 0));
            geometry.vertices.push(new Three.Vector3(-1.3, 0, 0));

            material = new Three.LineBasicMaterial({ color: this.mvGuideColor });
     
            for (const unit of this.mvGuideUnits) {
                line = new Three.Line(geometry, material);
                line.position.y = unit * this.mvSize;

                let text = new TextSprite({
                    alignment: 'left',
                    color:'#333',
                    fontFamily: "'Lato', 'Noto Sans KR', sans-serif",
                    fontSize: 0.9,
                    text: `${unit}`
                });
            
                text.position.z = line.position.z;
                text.position.x = line.position.x - 2.3;
                text.position.y = line.position.y;
                
                
                this.scene.add(line);
                this.scene.add(text);
            }
            
            if(this.mvGuidLabel){
                let text = new TextSprite({
                    alignment: 'left',
                    color:'#333',
                    fontFamily: "'Noto Sans KR', sans-serif",
                    fontSize: 1,
                    text: `${this.mvGuidLabel}`
                });

                text.position.z = line.position.z - 4;
                text.position.x = line.position.x + 1.5;
                text.position.y = line.position.y;

                this.scene.add(text);
            }
        }
        
        // HZ
        if (this.hzGuide === true) {
            material = new Three.LineBasicMaterial({ color: this.mvGuideColor });
            
            // axis
            geometry = new Three.Geometry();
            geometry.vertices.push(new Three.Vector3(0, 0, 0));
            geometry.vertices.push(new Three.Vector3(0, 0, this.hzCount * this.gridSize));
            
            this.scene.add(new Three.Line(geometry, material));
            
            // unit
            geometry = new Three.Geometry();
            geometry.vertices.push(new Three.Vector3(0, 0, 0));
            geometry.vertices.push(new Three.Vector3(-1, 0, 0));
            
            for (const unit of this.hzGuideUnits) {
                line = new Three.Line(geometry, material);
                line.position.z = unit * this.gridSize;

                let text = new TextSprite({
                    alignment: 'left',
                    color:'#333',
                    fontFamily: "'Lato', 'Noto Sans KR', sans-serif",
                    fontSize: 0.9,
                    text: `${unit}`
                });

                text.position.x = line.position.x - 2;
                text.position.z = line.position.z;
                
                this.scene.add(line);
                this.scene.add(text);
            }
        }

        

        // HZ
        if (this.mvTooltip === true) {
                    
            // unit
            geometry = new Three.Geometry();
            geometry.vertices.push(new Three.Vector3(0, 0, 0));
            geometry.vertices.push(new Three.Vector3(-1, 0, 0));
            
            line = new Three.Line(geometry, material);
            line.position.x = 5;
            line.position.y = -5;
            line.position.z = 10 * this.gridSize;
            
            let text = this.create2DText('');
            text.className = "mv-tooltip";
            this.mvTooltipLabel = {
                line,
                text,
            };
            this.scene.add(line);

        }

    }
    
    /**
     * Draw grid
     */
    drawGrid() {
        let i, geometry, material, startX, endX, startY, endY, startZ, endZ;

        /**
         * Create & Add line to scene
         * @param material
         * @param startX
         * @param startY
         * @param startZ
         * @param endX
         * @param endY
         * @param endZ
         */
        const addLine = (material, startX, startY, startZ, endX, endY, endZ) => {
            geometry = new Three.Geometry();
            geometry.vertices.push(new Three.Vector3(startX, startY, startZ));
            geometry.vertices.push(new Three.Vector3(endX, endY, endZ));
            
            this.scene.add(new Three.Line(geometry, material));
        };

        // Time * MV
        if (this.timeMvGrid === true) {
            material = new Three.LineBasicMaterial({ color: this.timeMvGridColor });
            
            startY = 0;
            endY = this.mvCount * this.mvSize;
            startZ = endZ = 0;

            // for (i = 0; i <= this.timeCount; i++) {
            //     startX = endX = i * this.gridSize;
                
            //     addLine(material, startX, startY, startZ, endX, endY, endZ);
            // }
            
            startX = 0;
            endX = this.timeCount * this.gridSize;
            startZ = endZ = 0;
            
            for (i = 0; i <= this.mvCount / 10; i++) {
                startY = endY = i * this.mvSize;
                
                addLine(material, startX, startY, startZ, endX, endY, endZ);
            }
        }
        
        // MV * HZ
        if (this.mvHzGrid === true) {
            material = new Three.LineBasicMaterial({ color: this.mvHzGridColor });
            
            startX = endX = this.timeCount * this.gridSize;
            startZ = 0;
            endZ = this.hzCount * this.gridSize;
            
            for (i = 0; i <= this.mvCount; i++) {
                startY = endY = i * this.mvSize;
                
                addLine(material, startX, startY, startZ, endX, endY, endZ);
            }

            startX = endX = this.timeCount * this.gridSize;
            startY = 0;
            endY = this.mvCount * this.mvSize;

            for (i = 0; i <= this.hzCount; i++) {
                startZ = endZ = i * this.gridSize;

                addLine(material, startX, startY, startZ, endX, endY, endZ);
            }
        }
        
        // Time * HZ
        if (this.timeHzGrid === true) {
            material = new Three.LineBasicMaterial({ color: this.timeHzGridColor });
            
            startX = 0;
            endX = this.timeCount * this.gridSize;
            startY = endY = 0;
            
            for (i = 0; i <= this.hzCount; i++) {
                startZ = endZ = i * this.gridSize;
                
                addLine(material, startX, startY, startZ, endX, endY, endZ);
            }

            startY = endY = 0;
            startZ = 0;
            endZ = this.hzCount * this.gridSize;
            
            for (i = 0; i <= this.timeCount; i++) {
                startX = endX = i * this.gridSize;
                
                addLine(material, startX, startY, startZ, endX, endY, endZ);
            }
        }
    }
    
    /**
     * Add data
     * @param order
     * @param data
     * @param withRender
     */
    addData(order, data, withRender = true) {
        this.orderIndex = order;
        
        if(order > this.maxTimeline){
            this.graphGroup.children = this.graphGroup.children.slice(1, this.maxTimeline);
            let el;

            if(0 === order % this.fftGuidArrowInterval){
                el = this.mvArrows.shift();
                if(el){
                    el.image.remove();
                }
            }
          
        }

        let i, j, mv, segments, color, point, face, numberOfSides, vertexIndex,
            boxHeight, minNoiseValue,
            boxMaterial,
            boxMesh;
        
        const graphs = new Three.Object3D();
        const faceIndices = [ 'a', 'b', 'c', 'd' ];

        
        graphs.userData = {
            type: 'bar'
        }
        
        const backgroundHeight = 0.01
        const backgroundGeometry = new Three.BoxGeometry(this.gridSize, backgroundHeight, this.gridSize * this.hzCount, 1, 1, 1);

        backgroundGeometry.translate(
            (-order * this.gridSize) - (this.gridSize / 2),
            backgroundHeight / 2,
            (this.hzCount) / 2 * this.gridSize + (this.gridSize / 2)
        );
        graphs.add(new Three.Mesh(backgroundGeometry, this.defaultBoxMaterial));

        const mergedGeo = new Three.Geometry(); // 하나로 병합할 geometry생성
        
        for (let hz = 0; hz < this.hzCount; hz++) {
            mv = data[hz] - (data[hz] * 0.3);
            mv = parseFloat((mv / 2).toFixed(2));

            if (mv > 50) { mv = 50; }
            
          
            boxHeight = mv === 0 ? 0.01 : mv * this.mvSize;
            segments = Math.ceil(mv / 1.5);
            segments = segments > 0 ? segments : 1;
            
            const boxGeometry = new Three.BoxGeometry(this.gridSize, boxHeight, this.gridSize, 1, segments, 1);
            
            boxGeometry.translate(
                (-order * this.gridSize) - (this.gridSize / 2),
                boxHeight / 2,
                hz * this.gridSize + (this.gridSize / 2)
            );

            if(0 < this.mvScalePercent){
                mv = mv - ( mv * this.mvScalePercent / 100)
            }
            
            minNoiseValue = 0.01
            if(15 < hz) {
                minNoiseValue = 1
            }
            
            if(0.1 <  mv){
                for (i = 0; i < boxGeometry.vertices.length; i++) {
                    point = boxGeometry.vertices[i];
                    color = this.graphColors(point.y / 13).rgb();
                    boxGeometry.colors[i] = new Three.Color(`rgb(${ color[0] }, ${ color[1] }, ${ color[2] })`);
                }
                
                for (i = 0; i < boxGeometry.faces.length; i++) {
                    face = boxGeometry.faces[i];
                    numberOfSides = (face instanceof Three.Face3) ? 3 : 4;
                    
                    for (j = 0; j < numberOfSides; j++) {
                        vertexIndex = face[faceIndices[j]];
                        face.vertexColors[j] = boxGeometry.colors[vertexIndex];
                    }
                }  

                boxMaterial = new Three.MeshPhysicalMaterial({
                    vertexColors: Three.VertexColors,
                    side: Three.DoubleSide,
                    flatShading: true
                });
                
                boxMesh = new Three.Mesh(boxGeometry, boxMaterial);
                boxMesh.updateMatrix()
                mergedGeo.merge(boxMesh.geometry, boxMesh.matrix)
            }
        }
        const material = new Three.MeshPhysicalMaterial({
            vertexColors: Three.VertexColors,
            side: Three.DoubleSide,
            flatShading: true
        });
        const mergedMesh = new Three.Mesh( mergedGeo, material ); // mergedGeo를 사용하여 mesh를 생성한다.
        graphs.add(mergedMesh);

        // Time
        if (this.timeGuide === true) {
            if (0 === order % this.timeGuideInterval) {
                const material = new Three.LineBasicMaterial({ color: this.timeGuideColor });
    
                // unit
                const geometry = new Three.Geometry();
                geometry.vertices.push(new Three.Vector3(0, 0, 0));
                geometry.vertices.push(new Three.Vector3(0, 0, 1));
    
                const line = new Three.Line(geometry, material);
                line.position.x = -order * this.gridSize;
                line.position.z = this.hzCount * this.gridSize;

                let text = new TextSprite({
                    alignment: 'left',
                    color:'#333',
                    fontFamily: "'Lato', 'Noto Sans KR', sans-serif",
                    fontSize: 0.9,
                    text: `${order}`
                });
                
                text.position.x = line.position.x - 0.5
                text.position.z = line.position.z + 3.3
                
                graphs.add(line);
                graphs.add(text);
            }
        }

        this.graphGroup.add(graphs);
        this.graphGroup.translateX(this.gridSize);

        if(withRender){
            this.renderChartOnce()    
        }
    };



    /**
     * 화면을 삭제합니다.
     */
    clearCanvas() {
        // if(0 < this.orderIndex){
            this.graphGroup.translateX(-((this.orderIndex + 1) * this.gridSize));
        // }

        this.graphGroup.children = [];
        this.renderChartOnce();
    }



    saveCanvasImage() {
        this.canvas.focus();
        return this.canvas.toDataURL('image/png');
    }

    /**
     * 카메라 변경
     * @param params
     */
    setCamera(params){
        const { lookAt, target, position, truck } = params;

        this.cameraControls.setLookAt(lookAt[0], lookAt[1],lookAt[2], lookAt[3], lookAt[4], lookAt[5], false);
        this.cameraControls.setTarget(target[0], target[1], target[2], false);
        this.cameraControls.setPosition(position[0], position[1], position[2], false);
        this.cameraControls.truck(truck[0], truck[1]);
        this.renderer.render(this.scene, this.camera);
        requestAnimationFrame(this.renderChart.bind(this));
    }

    /**
     * 특정 범위에 설정된 값의 비율을 조절합니다.
     * @param upscaleRate
     */
    setUpscaleRate(upscaleRate){
        const faceIndices = [ 'a', 'b', 'c', 'd' ];
        
        let timeObject, object, upscaleHz, boxGeometry, ii, jj, point, color, face, numberOfSides, vertexIndex;
        for(let i = 0 ; i < this.graphGroup.children.length; i++){
            timeObject = this.graphGroup.children[i];
            
            //hz
            for(let j = 0 ; j < timeObject.children.length; j++){
                object = timeObject.children[j]
                if(0 ===  object.userData.mv){
                    continue
                }
                
                upscaleHz = 0;
                if('Mesh' === object.type){
                    if(null != upscaleRate[j]){
                        upscaleHz = upscaleRate[j] / 100
                    }
                    object.scale.y = 1 + upscaleHz
                    boxGeometry = object.geometry
                    
                    for (ii = 0; ii < boxGeometry.vertices.length; ii++) {
                        point = boxGeometry.vertices[ii];
                        color = this.graphColors((point.y * object.scale.y) / 15).rgb();
                        boxGeometry.colors[ii] = new Three.Color(`rgb(${ color[0] }, ${ color[1] }, ${ color[2] })`);
                    }

                    for (ii = 0; ii < boxGeometry.faces.length; ii++) {
                        face = boxGeometry.faces[ii];
                        numberOfSides = (face instanceof Three.Face3) ? 3 : 4;

                        for (jj = 0; jj < numberOfSides; jj++) {
                            vertexIndex = face[faceIndices[jj]];
                            face.vertexColors[jj] = boxGeometry.colors[vertexIndex];
                        }
                    }
                }
            }
        }
        this.renderChartOnce()
    }

    /**
     * 카메라 변경
     * @param params
     */
    setCameraPosition(direction){
        const offset = 5
        switch (direction) {
            case 'left':
                this.currentCamera.position.z += offset
                this.currentCamera.position.x += offset / 2
                break
            case 'right':
                this.currentCamera.position.z -= offset
                this.currentCamera.position.x -= offset / 2
                break
            case 'top':
                this.currentCamera.position.y -= offset
                break
            case 'bottom':
                this.currentCamera.position.y += offset
                break
            default:
        }

        this.setCameraLookAt([
            this.currentCamera.position.x,
            this.currentCamera.position.y,
            this.currentCamera.position.z,
            this.currentCamera.target.x,
            this.currentCamera.target.y,
            this.currentCamera.target.z
        ])
    }

    /**
     * 카메라 변경
     * @param params
     */
    setCamera(params){
        const { lookAt } = params;
        this.cameraControls.setLookAt(lookAt[0], lookAt[1],lookAt[2], lookAt[3], lookAt[4], lookAt[5], false);
        this.renderChartOnce()
    }
    
    setCameraLookAt(lookAt, zoom){
        this.cameraControls.setLookAt(lookAt[0], lookAt[1],lookAt[2], lookAt[3], lookAt[4], lookAt[5], false);
        if(zoom){
            this.cameraControls.zoom(zoom, false);    
        }
        this.cameraControls.update( this.clock.getDelta());
        this.renderChartOnce()
    }
    setCameraByType(type, zoom){
        const lookAt = this.cameraPosition[type];
        this.cameraControls.setLookAt(lookAt[0], lookAt[1],lookAt[2], lookAt[3], lookAt[4], lookAt[5], false);
        if(zoom){
            this.cameraControls.zoom(zoom, false);
        }
        this.renderChartOnce()
    }
    setFreeCamera(useCamera){
        this.cameraControls.enabled = useCamera
        this.renderChartOnce()
    }
    updateCameraDelta(){
        this.cameraControls.update( this.clock.getDelta());
    }
}
