import React, { useState } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";


const NotificationBox = () => {
    const classes = useStyles();
    const [checked, setChecked] = useState(true)
    
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography className={classes.title}>브레인헬스 정보 알림 받기</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box display={'flex'} justifyContent={'flex-end'} alignItems={'flex-end'}>
                        <IOSSwitch
                            checked={checked}
                            onChange={(event) => {
                                setChecked(event.target.checked)
                            }}    
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item>
                    <Box className={classes.body}>
                        알림에 동의하시면 정기적 뇌균형상태 측정일, 자각증상, 운동 알림 등<br/>
                         꼭 필요한 정보만 알려드릴게요 (카카오톡, 문자 발송) 
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}



const useStyles = makeStyles(() => ({
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#333',
        marginTop: 5
    },
    body: {
        fontSize:14,
        color: '#333',
    }
}));


const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
   
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});


export default NotificationBox