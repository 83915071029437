
import React from 'react';

import { Line} from 'react-chartjs-2';

export default class LineChart extends React.Component { 
    chartOption
    chartRef

    constructor(props) {
        super(props)

        const perSec = 6
        const chartBuffer = 256 * perSec
        const height = props.height ? props.height : 130
        this.state = {
            time: 0,
            title: props.title,
            chartBuffer: chartBuffer,
            height,
            perSec,
            showXtick: null != this.props.showXtick ? this.props.showXtick : false,
            chartData: {
                labels: new Array(chartBuffer).fill(0).map((_) => ''),
                datasets: [{
                    label: '',
                    fill: false,
                    borderColor: '#016AFF',
                    borderCapStyle: 'round',
                    borderJoinStyle: 'round',
                    pointBorderColor: '#000',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 0,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#999',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 1,
                    pointRadius: 0,
                    borderWidth: 1,
                    pointHitRadius: 10,
                    data: new Array(chartBuffer).fill(512)        // adc^10  = 1024 / 2
                }]
            }
        }
    }
    /**
     * 데이터를 받아 화면을 새로고칩니다.
     * @param {*} time 
     * @param {*} data 
     */
    addData(time, data){

        // console.log(data)
        const before = this.state.chartData.datasets[0].data.slice( 0, this.state.chartBuffer - data.length )
        this.state.chartData.datasets[0].data = data.concat(before);

        const perCount = 256;
        let calTime = time;
        for(let i = 0 ; i < this.state.perSec; i++){
            calTime = time - i;
            if(0 < calTime){
                this.state.chartData.labels[i * perCount] = calTime+'초';
            }
        }   
        this.chartRef.chartInstance.update()
    }

    componentWillMount(){
        // Chart.plugins.register(zoom)

        this.chartOption = {
            legend: {
                display: false
            },
            animation: {
                duration: 0 // general animation time
            },
            tooltips: {
                enabled: false
            },
            responsive: true,
            title: {
                display: false,
                text: this.state.title
            },
            scales: {
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: false,
                        labelString: 'hz'
                    },
                    ticks: {
                        display: this.state.showXtick,
                        autoSkip: true,
                        maxTicksLimit: this.state.perSec,
                        maxRotation: 0,
                        minRotation: 0
                    }
                }],
                yAxes: [{
                    display: false,
                    scaleLabel: {
                        display: false,
                        labelString: 'Value'
                    },
                    ticks: {
                        beginAtZero: true,
                        // steps: 10,
                        // stepValue: 5,
                        max: 1024,
                        display: false
                    }
                }]
            },
            // pan: {
            //     enabled:true,
            //     mode:'x'
            //  },
            // zoom: {
            //     enabled:this.state.zoomable,
            //     mode:'xy'
            // }
        }
    }

    render() {
        return <Line ref={(ref)=> this.chartRef = ref} 
                    data={this.state.chartData} 
                    options={this.chartOption} 
                    height={this.state.height} />
    }
};