import React, {useState, useEffect, useCallback} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {Box, Grid, Button, IconButton} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CircularProgress from '@material-ui/core/CircularProgress';

import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Skeleton from '@material-ui/lab/Skeleton';

import SurveyService from '@user-service/SurveyService'
import { grey, green } from '@material-ui/core/colors';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import {useHistory} from "react-router-dom";
import clsx from 'clsx';
import {useBeforeunload} from "react-beforeunload";
import MeasureService from "../../services/MeasureService";
import AuthProvider from "../../provider/AuthProvider";
import {SurveyPop} from "./SurveyPop";
import {useRecoilState} from "recoil";
import {appProfileButtonStore} from "../../store/headerStore";

const Survey = (props) => {
    const classes = useStyles()
    const history = useHistory()

    const [, setShowProfileButton] = useRecoilState(appProfileButtonStore);
    const [survey, setSurvey] = useState(null)
    const surveyType = props.match.params.surveyType
    
    const fetchSurvey = useCallback(async () => {
        const res = await SurveyService.getMySurvey(surveyType)
        if(res){
          setSurvey(res)
        }
    },[surveyType])
    
    useEffect(()=>{
      setShowProfileButton(false)
      fetchSurvey().then()
    
      return () => {
        setShowProfileButton(true)
      }  
    },[fetchSurvey])

    // useBeforeunload(() => {
    //     return 'message';
    // });    

    // 답변 기본 설정 
    // 사용자가 실제 0이라는 데이터를 가질수도 있기떄문에 Validation은 하지 않습니다.
    const initAnswerValue = (refSurvey) => {
        if(!refSurvey) return 

        const answers = {
            items: []
        }

        const items = refSurvey.items
        if(items){
            for(let i = 0; i < items.length ; i++){
                const item = items[i]
                answers.items.push({
                    id: item.id,
                    answers:[{
                        number: item.id,
                        symptomLevel: 0
                    }]
                })
            }
        }
        return answers
    }

    //자각증상 데이터를 저장합니다.
    const saveSurvey = async (callback) => {
        const result = await SurveyService.saveSurvey({
            id: survey.id, 
            items: answers.items
        })

        if(result.success){
            initAnswerValue(survey)
            setTimeout(()=>{
                callback()
            },1000)
            
        }
    }

    //사용자가 선택한 값을 저장
    const setRatingValue = (id, answerIndex, level) => {
        const updateAnswer = answers.items[answerIndex]

        //이전에는 한라인에 여러개의 답을 가질수 있었지만 
        //변경 후는 무조건 하나입니다. ( 기본 구조를 사용하기 위해서 배열 사용 )
        updateAnswer.answers[0] = {
            number: 1, 
            symptomLevel: level
        }
        answers.items[answerIndex] = updateAnswer
    }

    
    if(!survey) return <></>
    //입력값 초기화 
    const answers = initAnswerValue(survey)
    return (
        <div id="cont-wrap" style={{paddingBottom: 100}} className={AuthProvider.isAppMode() ? 'app' : ''}>
            <div className="sv">
                <Grid container>
                    <Grid item xs={1}>
                        <IconButton
                            className={classes.back}
                            aria-label="delete"
                            onClick={()=>{
                                history.goBack()
                            }}
                        >
                            <ArrowBackIcon style={{fontSize: 50}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <div>
                            <h3>{survey ? survey.subject : ''}</h3>
                            <span>{MeasureService.getSurveyTitle(survey.surveyType)} 측정을 시작합니다.</span>
                            <i>Brain Health</i>
                        </div>
                    </Grid>
                </Grid>
            </div>
            
            {!survey &&
                <LoadingSkeleton/>
            }

            {survey &&
                <React.Fragment>
                    <div className="cont">
                        <div className={clsx('sc-measure', surveyType)}>
                            <div className={'inner'}>
                                <ul>
                                    {survey.items.map((item, index) => {
                                        return (
                                            <SurveyItem
                                                key={`survey-${survey.id}-${index}`}
                                                {...item}
                                                order={index}
                                                surveyType={surveyType}
                                                onRatingValue={setRatingValue.bind(this)}/>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <SurveyPop key={`survey-pop-${survey.id}`} {...survey} surveyType={surveyType} />
                    <SaveForm
                        history={props.history}
                        surveyType={surveyType}
                        onSave={saveSurvey.bind(this)}
                    />
                </React.Fragment>
            }
        </div>
    );
}

//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <div className="editprofile_wrap wrapper">
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </div>
        </Box>
    )
}



/**
 * 제출 하기 확인 다이얼로그 
 * @param {*} props 
 */
export function SaveForm(props){

    const [open, setOpen] = useState(false)
    const [saveStatus, setSaveStatus] = useState('normal')

    const handleClose = () => {
        setOpen(false)
    };

    const handleSave = async () => {
        if(props.onSave){
            setSaveStatus('saving')
            
            await MeasureService.useMeasureType({
                profileID: AuthProvider.getProfileID(),
                measureType: props.surveyType
            })
            
            props.onSave(()=>{
                setSaveStatus('done')

                //2초 뒤에 목록으로 이동
                setTimeout(()=>{
                    if(AuthProvider.isProfileSelectorView()){
                        let nextUrl 
                        switch(props.surveyType){
                            case 'emotion':
                                nextUrl = `/survey/health`
                                break
                            case 'health':
                                nextUrl = `/survey/life`
                                break
                            case 'life':
                                nextUrl = '/survey/recognition'
                                break
                            case 'recognition':
                                nextUrl = '/measureTest'
                                break
                            default:
                                nextUrl = `/survey-year?type=${props.surveyType}`
                        }
                        
                        setOpen(false)
                        // document.location.replace(nextUrl)
                        // props.history.push(nextUrl)
                        props.history.replace( {
                          pathname: '/empty-page',
                          state: {
                            nextUrl
                          }
                        })
                        
                    } else {
                        props.history.push(`/survey-year?type=${props.surveyType}`)    
                    }
                        
                    
                },1000)
            })
        }
    }

    useEffect(() => {
        if(open){
            setTimeout(() => {
                handleSave().then()
            }, 300)
        }
    }, [open])
    
    return  <Box>
                <Grid container justify="center" mb={30}>
                    <Button variant="contained" color="primary" size="large" style={{padding:'10px 60px', fontSize:'1.4rem'}} onClick={()=>{setOpen(true)}}>
                        제출하기
                    </Button>
                </Grid>
                
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description" 
                >
                    <DialogTitle className="common-dialog-title">{MeasureService.getSurveyTitle(props.surveyType)}</DialogTitle>
                    <DialogContent className={'common-dialog-content'} >
                        <Box minWidth={300} minHeight={150}>
                            <Grid container justify="center">
                                {'done' !== saveStatus 
                                        ? <InfoIcon style={{ fontSize: 100, color: grey[300] }} /> 
                                        : <CheckCircleIcon style={{ fontSize: 100, color: green[500] }} /> 
                                }

                            </Grid>
                            <br/>
                            {'done' !== saveStatus 
                                ?<DialogContentText id="alert-dialog-description">
                                    입력하신 내용으로 제출 하시겠습니까? <br/>
                                    제출 후에는 변경 할 수 없습니다.
                                </DialogContentText>
                                :
                                <DialogContentText id="alert-dialog-description">
                                    제출이 완료되었습니다. <br/>
                                    잠시 후 목록으로 이동합니다.
                                </DialogContentText>
                            }
                        </Box>
                    </DialogContent>

                    <DialogActions>
                    {'normal' === saveStatus &&
                        <Box>
                            <Button onClick={handleClose} color="primary" style={{paddingRight: 30}}>
                                다시입력
                            </Button>
                            <Button onClick={handleSave} color="primary" autoFocus variant={'contained'}>
                                제출
                            </Button>
                        </Box>
                    }

                    {'saving' === saveStatus && 
                        <CircularProgress size={24}/>
                    }
                    </DialogActions>
                </Dialog>   
            </Box> 
}


//자각증상 라인
export function SurveyItem(props) {
    const { content, order, id, onRatingValue } = props
    let step = ['life-cycle'].includes(props.surveyType) 
        ? [-50,-40,-30,-20,-10,0,10,20,30,40,50]
        : [10,20,30,40,50,60,70,80,90,100]
        
    let stepText = JSON.parse(JSON.stringify(step)); 
    if('life' === props.surveyType) {
        stepText.reverse()   
    }
    
    const itemBgn = new Array(step.length).fill(true)
    const [value, setValue] = useState( 0);
    
    if(['life-cycle'].includes(props.surveyType)){
        for(let i = 0; i < itemBgn.length; i++ ) {
            if( 0 === value){
                itemBgn[i] = true
            } else if(0 > value) {
                i < 5 
                ? itemBgn[i] = value > step[i]
                : itemBgn[i] = true
            } else {
                i > 5
                ? itemBgn[i] = value < step[i]
                : itemBgn[i] = true
            }
        }
    } else {
        for(let i = 0; i < itemBgn.length; i++ ){
            itemBgn[i] = value < step[i]
        }
    }
    return (
        <li>
            <dl>
                <dt>{(order + 1)}. {content}</dt>
                <dd>
                    <ul>
                        {step.map((step, index) => {
                            return (
                                <li key={`step-${step}`}>
                                    <div
                                        className={clsx('', {
                                            'bgn': itemBgn[index]
                                        })} 
                                        onClick={() => {
                                           setValue(step)
                                            if(onRatingValue) {
                                                onRatingValue(id, order, step)
                                            }
                                        }}
                                    />
                                    {stepText[index]}
                                </li>
                            )
                        })}
                    </ul>
                </dd>
            </dl>
        </li>
    )
}


const useStyles = makeStyles(theme => ({
    marginTop: {
        marginTop:50
    },
    back: {
        marginTop: 30,
        '&:hover': {
            backgroundColor: '#053288',
        }
    },
    titleDesc: {
        width:600,
        color:'#333',
        fontSize:20,
        flexGrow: 1,
        margin:"0 auto",
        alignItems: "center"
    },
    surveyItem : {
        marginBottom:25,
        textAlign:'left',
        color:'#333'
    }, 
    surveyItemTitle: {
        marginBottom:20,
        paddingBottom:20,
        borderBottom: '1px solid #eee',
        fontSize: 22,
        width:'100%',
        color:'#333',
        textAlign: 'left'
    },
    surveyItemWrap: {
        border:1,
        width:600,
        borderColor: '#eee',  
        margin: '0 auto'

    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


export default Survey
