import React from "react";
import {Box} from "@material-ui/core";

class Radar extends React.Component {

    radarBeamRef = React.createRef()
    maxBalance = 9
    balanceCount = 0
    constructor(props) {
        super();
        
        this.state = {
            size: props.size
        }
    }
    
    componentDidMount() {
    }

    update(data, measureCode, range) {
        let direction;
        if('D' === measureCode || 'G' === measureCode){
            direction = -1
        } else {
            direction = 1 
        }
        
        if(data.isHit) {
            this.balanceCount++
        } else {
            this.balanceCount = 0
        }
        /*
        let balance = data.fftLeftSumAvg - data.fftRightSumAvg
        
        if( Math.abs(balance) > this.maxBalance ) {
            if( 0 > balance) balance = this.maxBalance * -1
            else balance = this.maxBalance
        }
        */
        this.radarBeamRef.current.style.webkitTransform = 'rotate('+ parseInt(this.balanceCount * direction * range) +'deg)';
        
    }
    
    render() {
        return (
            <Box position="absolute"
                 left={`calc(50% - ${this.state.size.width / 2}px)`}
                 bottom={20}
                 zIndex={11}
                 style={{ width: this.state.size.width,
                     height: this.state.size.height,
                     backgroundRepeat: 'no-repeat',
                     backgroundPosition: 'center',
                 }}>
                <div className={'radar'} >
                    <div className={'radar-beam'}
                         ref={this.radarBeamRef}>
                    </div>
                </div>
            </Box>
        )
    }
}

export default Radar