import React, {useState, useEffect, useRef} from 'react';
import {
    Box,
    TextField, 
    Grid, 
    Button,
    Typography
 } from '@material-ui/core';

import MeasureService from '@user-service/MeasureService'
import Moment from "moment-timezone";
import MeasureHelper from '@helper/MeasureHelper'
const { measureTypeToCode, getMeasureTypeRange, getMeasureButtons } = MeasureHelper

const ComputeFeedbackMeditation = (props) => {
    
    let defaultMeasureCode = 'default' === props.hzType ? 'C' : 'A'
    let title
    let measureButtons = getMeasureButtons(props.hzType)
    let bandMA, bandMM, bandMB
    let timeMA, timeMM, timeMB
    let stepCountMA, stepCountMM, stepCountMB
    
    switch (props.hzType){
        case 'meditation':
            title = "치유명상"
            break
        case 'meditationHeal':
            title = "신앙 치유명상"
            break
        case 'intoSleep':
            title = "수면속으로"
            break
        case 'meditationTest':
            title = "뇌기능의식패턴"
            break
        default:
            title = ''
    }
    
    const [hzType] = useState(null !== props.hzType ? props.hzType : 'feedback' )
    const [measureCode] = useState(defaultMeasureCode)
    const [measureType, setMeasureType] = useState(props.measureType ? props.measureType : 'type1')
    const computeCodeRef = useRef()
    
    useEffect(() => {
        // fetchComputeHz().then()
    },[measureCode, measureType, hzType])
    
    return (
        <Box>
            <Box mt={2}>
                <Typography>
                    {title} 설정 값
                </Typography>
            </Box>
            <Box>
                <Grid container spacing={1}>
                {measureButtons.map((button, index) => {
                    return (
                        <Grid item key={index}>
                            <Button variant={ button.value ===  measureType ? "contained" : "outlined"}
                                    color="primary"
                                    onClick={() => {
                                        setMeasureType(button.value)
                                        try {
                                            computeCodeRef.current.setMeasureType(button.value)
                                        } catch (e) {}
                                    }}>
                                {button.name}
                            </Button>
                        </Grid>
                    )
                })}
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container>
                    <Grid item xs={4}>
                        <Box mb={1}>
                            <Button variant={'contained'}>10-MA</Button>
                        </Box>
                        <HzMinMax 
                            hzType={props.hzType} 
                            measureType={measureType} 
                            measureCode={'MA'}
                            time={props.time}
                            onSetTime={(time) => {
                                timeMA = time
                            }}
                            onSetBand={(updatedBand, stepCount) => {
                                bandMA = updatedBand
                                stepCountMA = stepCount
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Box mb={1}>
                            <Button variant={'contained'}>10-MM</Button>
                        </Box>
                        <HzMinMax
                            hzType={props.hzType}
                            measureType={measureType}
                            measureCode={'MM'}
                            time={props.time}
                            onSetTime={(time) => {
                                timeMM = time
                            }}
                            onSetBand={(updatedBand, stepCount) => {
                                bandMM = updatedBand
                                stepCountMM = stepCount
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Box mb={1}>
                            <Button variant={'contained'}>10-MB</Button>
                        </Box>
                        <HzMinMax
                            hzType={props.hzType}
                            measureType={measureType}
                            measureCode={'MB'}
                            time={props.time}
                            onSetTime={(time) => {
                                timeMB = time
                            }}
                            onSetBand={(updatedBand, stepCount) => {
                                bandMB = updatedBand
                                stepCountMB = stepCount
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box p={2}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button
                            onClick={async () => {
                                //
                                // if(!timeMA || '' === timeMA || '0' === timeMA){
                                //     alert('MA 코드의 시간을 설정해 주세요')
                                //     return false;
                                // }
                                //
                                // if(!timeMM || '' === timeMA || '0' === timeMA){
                                //     alert('MM 코드의 시간을 설정해 주세요')
                                //     return false;
                                // }
                                //
                                // if(!timeMB || '' === timeMA || '0' === timeMA){
                                //     alert('MB 코드의 시간을 설정해 주세요')
                                //     return false;
                                // }

                                if(bandMA){
                                    await MeasureService.updateComputeHzWithCode(
                                        hzType,
                                        measureTypeToCode(measureType) ,
                                        'MA',
                                        bandMA,
                                        timeMA,
                                        stepCountMA
                                    )
                                }
                              

                                if(bandMM){
                                    await MeasureService.updateComputeHzWithCode(
                                        hzType,
                                        measureTypeToCode(measureType) ,
                                        'MM',
                                        bandMM,
                                        timeMM,
                                        stepCountMM
                                    )
                                }
    
                                if(bandMB){
                                    await MeasureService.updateComputeHzWithCode(
                                        hzType,
                                        measureTypeToCode(measureType) ,
                                        'MB',
                                        bandMB,
                                        timeMB,
                                        stepCountMB
                                    )    
                                }
                                
                                alert('수정되었습니다.');
                            }}
                            variant={"contained"}
                            color="primary" >수정</Button>
                    </Grid>
                    {props.closeDialog &&
                    <Grid item>
                        <Button variant={'outlined'} onClick={() => {
                            props.closeDialog()
                        }}>
                            닫기
                        </Button>
                    </Grid>
                    }
                </Grid>
                
               
            </Box>
        </Box>
    )
}

export function HzMinMax(props){
    const [state, setState] = useState({
        band: null,
        stepCount: 1
    })
    
    const { hzType, measureType, measureCode } = props
    // const [time, setTime] = useState(0)
    const { onSetBand } = props
    let hzValMin, hzValMax
    let range = getMeasureTypeRange(hzType, measureType, measureCode)
    
    /**
     * 설정값을 가져옵니다.
     * @returns {Promise<void>}
     */
    const fetchComputeHz = async () => {
        let res = await MeasureService.getComputeHzLastWithCode(hzType, measureTypeToCode(measureType), measureCode)
        const { band, stepCount } = res

        let newBand = band
        if(!newBand){
            const baseRange = getMeasureTypeRange(hzType, measureType, measureCode)
            newBand = baseRange.map((hz) => {
                return {
                    hz,
                    min: 0,
                    max: 0,
                    overCut: 0,
                }
            })
        }
        
        setState({
            stepCount,
            band: newBand
        })
    }
    
    useEffect(() => {
        fetchComputeHz().then()
    },[hzType, measureType, measureCode])

    const { band, stepCount } = state
    if(!band) return <></>
    
    let keyId, createdAt
    if(band && band[0]){
        keyId = band[0].keyId
        createdAt = band[0].createdAt
    }
    
    let useMinVal
    let useMaxVal
    
    let labelMin, labelMax, explainMin, explainMax
    labelMin = '최소'
    labelMax = '최대'
    
    
    switch (measureCode){
        case 'MA':
            /**
             * 모두 통합 2022.07.23 , 원장님 요청
             */
            // if('meditation' !== hzType){
                useMinVal = true
                useMaxVal = true
                labelMin = '설정값'
                labelMax = '설정값'
                explainMin = 'hz 최소값= 1초전 hz 평균 측정값 - 설정값'
                explainMax = 'hz 최대값 = 1초전 hz 평균 측정값 + 설정'
            // } else {
            //     useMinVal = false
            //     useMaxVal = true
            //     labelMax = '설정값'
            //     explainMin = 'hz의 최소값 = 1초전 hz 평균 측정값'
            //     explainMax = 'hz의 최대값 = 1초전 hz 평균 측정값 + 설정값'    
            // }
            break
        case 'MB':
            useMinVal = true
            useMaxVal = true
            labelMin = '설정값'
            labelMax = '이상 버리는값'
            explainMin = 'hz의 최소값 = 1초전 hz 평균 측정값 - 설정값'
            explainMax = 'hz의 최대값 = 1초전 hz 평균 측정값'
            break;
        case 'MM':
            useMinVal = true
            useMaxVal = true
            labelMin = '설정값'
            labelMax = '설정값'
            explainMin = 'hz 최소값= 1초전 hz 평균 측정값 - 설정값'
            explainMax = 'hz 최대값 = 1초전 hz 평균 측정값 + 설정'
            break
        default:
            useMinVal = true
            useMaxVal = true
    }
    
    return (
        <Box>
            <Box mb={1}>
                <Typography variant="body2" display="block" gutterBottom>
                    {explainMin}
                </Typography>
                <Typography variant="body2" display="block" gutterBottom>
                    {explainMax}
                </Typography>
            </Box>
            <Typography variant="caption" display="block" gutterBottom>
                {Moment.utc(createdAt).tz(Moment.tz.guess()).format('YY년 MM월 DD일 HH시 mm분')} - {keyId}
            </Typography>
            {/*<Box p={2} mb={3}*/}
            {/*style={{*/}
            {/*border: 1,*/}
            {/*borderColor: '#ddd',*/}
            {/*backgroundColor: props.active ? '#ddd' : '#fff'}}>*/}
            {/*    <Box>*/}
            {/*    <TextField*/}
            {/*    label={'치유명상 설정초 초'}*/}
            {/*    value={time}*/}
            {/*    onChange={(event) => {*/}
            {/*        if(props.onSetTime){*/}
            {/*            props.onSetTime(event.target.value)*/}
            {/*        }*/}
            {/*        setTime(event.target.value)*/}
            {/*        if (onSetBand) {*/}
            {/*            onSetBand(band)*/}
            {/*        }*/}
            {/*    }}*/}
            {/*    size="small"/>*/}
            {/*    </Box>*/}
            {/*    <Box mt={1}>*/}
            {/*        ~ {parseInt(time / 60)}분 { time % 60}초 까지 적용 */}
            {/*        <Typography variant={"caption"} display={'block'} gutterBottom>*/}
            {/*            영상 최대 시간은 555초 (9분10초)    */}
            {/*        </Typography>*/}
            {/*        */}
            {/*    </Box>    */}
            {/*</Box>*/}
            
            {
                range.map((val, index) => {
                    const hzVal = band[index]
                    hzValMin = hzVal ? hzVal.min : 0
                    hzValMax = hzVal ? hzVal.max : 0
                    return (
                        <Box p={2}
                             key={val}
                             style={{
                                 border: 1,
                                 borderColor: '#ddd',
                                 backgroundColor: props.active ? '#ddd' : '#fff'
                             }}>
                            <Grid container spacing={1}>
                                {useMinVal &&
                                <Grid item>
                                    <TextField
                                        label={`${val}Hz ${labelMin}`}
                                        value={hzValMin}
                                        inputProps={{min: 0, style: { width: 130 }}}
                                        onChange={(event) => {
                                            if (!band[index]) band[index] = {hz: val, min: 0, max: 0}
                                            band[index].min = event.target.value

                                            setState({
                                                ...state,
                                                band: band
                                            })

                                            if (onSetBand) {
                                                onSetBand({
                                                    ...band
                                                }, stepCount)
                                            }
                                        }}
                                        size="small"/>
                                </Grid>
                                }
                                {useMaxVal &&
                                <Grid item>
                                    <TextField
                                        label={`${val}Hz ${labelMax}`}
                                        value={hzValMax}
                                        inputProps={{min: 0, style: {  width: 130 }}}
                                        onChange={(event) => {
                                            if (!band[index]) band[index] = {hz: val, min: 0, max: 0}
                                            band[index].max = event.target.value
                                            
                                            setState({
                                                ...state,
                                                band: band
                                            })

                                            if (onSetBand) {
                                                onSetBand({
                                                    ...band
                                                }, stepCount)
                                            }
                                        }}
                                        size="small"/>
                                </Grid>
                                }
                            </Grid>
                        </Box>
                    )
                })
            }
            <Box p={2}>
                <Typography style={{fontSize: 16, fontWeight: 'bold', backgroundColor: '#efefef'}}>
                    1회 묶음 처리 수
                </Typography>
                <TextField
                    value={stepCount}
                    onChange={(event) => {
                        setState({
                            ...state,
                            stepCount: event.target.value
                        })

                        if (onSetBand) {
                            onSetBand({
                                ...band
                            }, event.target.value)
                        }
                    }}
                    size="small"/>
            </Box>
        </Box>
    )
}

export default ComputeFeedbackMeditation