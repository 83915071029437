import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';

const MeasureBoxBar = (props) => {
    const classes = useStyles();

    let box = []
    let boxColor = classes.measureBoxCell

    let measureData
    if(!props.data){
        measureData = new Array(60).fill({v:0})
    } else {
        measureData = props.data.split(',');
    } 

    const width = props.width ? props.width : 13
    const height = props.height ? props.height : 20;

    for(let i = 0 ; i < 60; i ++){
        if('green' === props.color){
            boxColor = classes.measureBoxGreenCell
        } else if ('blue' === props.color) {
            boxColor = classes.measureBoxBlueCell
        } else if ('red' === props.color) {
            boxColor = classes.measureBoxRedCell
        } else if ('purple' === props.color) {
            boxColor = classes.measureBoxPurpleCell
        }

        let isColored = false
        try {
            isColored = '1' === measureData[i]
        }catch(e){}

        box.push((<Grid key={i} item 
                    style={{ width: '1.66%', height }}
                    className={isColored ? boxColor : classes.measureBoxCell} />))
    }

    return (
        <Box>
            <Grid container className={classes.measureBox}>
                {box}
            </Grid>
        </Box>
    )
}


const useStyles = makeStyles(theme => ({
    measureBox: {
        margin:0
    },
    measureBoxCell: {
        background: '#efefef',
        border:1,
        borderStyle: 'solid',
        borderColor: '#fff'
    },
    measureBoxBlueCell: {
        background: '#00BFF6',
        border:1,
        borderStyle: 'solid',
        borderColor: '#fff'
    },

    measureBoxGreenCell: {
        background: '#7BC96E',
        border:1,
        borderStyle: 'solid',
        borderColor: '#fff'
    },

    measureBoxRedCell: {
        background: '#E83F3E',
        border:1,
        borderStyle: 'solid',
        borderColor: '#fff'
    },
    
    measureBoxPurpleCell: {
        background: '#B660CD',
        border:1,
        borderStyle: 'solid',
        borderColor: '#fff'
    }

}));



export default MeasureBoxBar
