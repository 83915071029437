import React from 'react';
import {Box, Button, ButtonGroup, Grid, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import clsx from "clsx";
import TestService from "../../../services/TestService";
import ReactAudioPlayer from "react-audio-player";
import cookie from 'react-cookies';

export default class LevelDialog extends React.Component {
    constructor(props) {
        super();
        const subLevel = localStorage.getItem('answerCount')
       
        const measureType =  props.measureType ? props.measureType : 'measure'
        let answerCount
        switch(subLevel){
            case 'sub-level-1' :
                answerCount = 5
                break
            case 'sub-level-2' :
                answerCount = 10
                break
            case 'sub-level-3' :
                answerCount = 15
                break
            default:
                answerCount = 10
        }
        
        this.state = {
            levelMode: 'easy',
            open: false,
            measureType: measureType, // 'measure - 검사', 'develop' - 계발
            onStart: props.onStart,
            history: props.history,
            limitTime: 300,
            level: 1,
            delay: 2000,
            answerCount: 10,
            backgroundColor: TestService.getColorName(measureType, props.exerciseID),
            useAudio: !('yes' === cookie.load('_sanaex'))
        }
    }
    
    open(level) {
        let audio = undefined
        switch (level){
            case '1':
                //주의력 검사
                audio = 'ex1'
                break
        }
        
        this.setState({
            ...this.state,
            open: true,
            level,
            audio
        })
    }
    
    close() {
        this.setState({
            ...this.state,
            open: false
        })
    }
    
    start() {
        if(this.state.onStart){
            this.state.onStart(this.state.delay, this.state.limitTime, this.state.answerCount);
        }
        this.close()
    }

    setLimitTime(limitTime) {
        this.setState({
            ...this.state,
            limitTime
        })
    }
    
    setDelay(delay) {
        this.setState({
            ...this.state,
            delay
        })
    }
    
    onExit(){
        if(this.state.history){
            const returnUrl = 'train' === this.state.measureType ? '/measureTrain' : '/measureTest'
            this.state.history.replace(returnUrl)
        }
    }
    
    getExplainText(testName) {
        if('2' === this.state.level){
            if(['develop','train'].includes(this.state.measureType)){
                return {
                    title: `${testName} 증강 프로그램`,
                    text1Title: `${testName} 증강 프로그램은?`,
                    text1:  <ul className={'box'}>
                        <li>인지능력2단계 발당 향샹 훈련</li>
                        <li>주의&집중 변환과정 독해능력 향상 훈련</li>
                        <li>멘탈 피로회복 능력 향샹 훈련</li>
                        <li>멘탈 재충전 능력 향상 훈련</li>
                        <li>새로운 도전과 적응능력 향상 훈련</li>
                    </ul>,
                    text2Title: `${testName} 증강 프로그램 평가 항목`,
                    text2: <ul className={'box'}>
                        <li>인지 기능과 변환기억 수행능력 평가</li>
                        <li>연령별 뇌의 인지 속도 처리능력 평가</li>
                        <li>우뇌의 두뇌 발달의 수행능력 평가</li>
                        <li>단기기억 저장능력 평가</li>
                        <li>단기기억 변환 인출 재생능력 평가</li>
                        <li>기억 변환 과정의 과정 로드맵 형성 수행능력 평가</li>
                    </ul>,
                    text3: <ul className={'box'}>
                        <li>케릭터를 변별하여 인지한 다음 순서에 상관없이 맞춰보세요.</li>
                        <li>
                            <span>설정한 제한시간과 초마다 띵동 소리와 함께 1개씩 최대 15개의</span> <br/>
                            <span style={{paddingLeft: 12}}>캐릭터가 화면에 나타납니다.</span>
                        </li>
                        <li>순서는 정답에 상관이 없습니다.</li>
                        <li>1개당 10점씩 총 {this.state.answerCount * 10}점 만점입니다.</li>
                    </ul>
                }
            } else {
                return {
                    title: `${testName} 검사`,
                    text1Title: `${testName} 검사 항`,
                    text1:  <ul className={'box'}>
                        <li>본인이 자신에 대해 얼마나 알고 있는지 판단하는 능력</li>
                        <li>좋아하는 것과 싫어하는 것을 판단하고 습득할 수 있는 능력</li>
                        <li>서로 다른 점을 확실하게 구분하는 판단 능력</li>
                        <li>집중하여 관찰한 후 이해한 것을 변환하여 저장하는 능력</li>
                        <li>변환된 정보를 기억하여 인출해내는 능력</li>
                    </ul>,
                    text2Title: `${testName}을 증강해야 할 대상`,
                    text2: <ul className={'box'}>
                        <li>주어진 일을 어떻게 해야 할지 판단이 빨리 서지 않는 사람</li>
                        <li>새로운 일에 긴장되어 자신감이 떨어지고 망설이는 사람</li>
                        <li>이해력이 부족하고 습득이 느린 사람</li>
                        <li>정신이 복잡하고 정리정돈이 잘 안 되고 불안해지는 사람</li>
                        <li>집중이 저하되어 작업기억과 기억 재생 능력이 저하된 사람</li>
                    </ul>,
                    text3: <ul className={'box'}>
                        <li>케릭터를 변별하여 인지한 다음 순서에 상관없이 맞춰보세요.</li>
                        <li>
                            <span>설정한 제한시간과 초마다 띵동 소리와 함께 1개씩 최대 15개의</span> <br/>
                            <span style={{paddingLeft: 12}}>캐릭터가 화면에 나타납니다.</span>
                        </li>
                        <li>순서는 정답에 상관이 없습니다.</li>
                        <li>1개당 10점씩 총 {this.state.answerCount * 10}점 만점입니다.</li>
                    </ul>
                }
            }
        } else {
            if(['develop','train'].includes(this.state.measureType)){
                return {
                    title: `${testName} 증강 프로그램`,
                    text1Title: `${testName} 증강 프로그램은?`,
                    text1:  <ul className={'box'}>
                        <li>인지력과 기억력을 높이는 두뇌계발 훈련</li>
                        <li>작업기억과 기억 재생능력 밣달 훈련</li>
                        <li>단기기억의 정신기능 복원 훈련</li>
                        <li>뇌기능 재충전 향상 훈련</li>
                        <li>기억 인출,연합, 통합능력 향상 훈련</li>
                    </ul>,
                    text2Title: `${testName} 증강 프로그램 평가 항목`,
                    text2: <ul className={'box'}>
                        <li>인지 기능과 작업 기억 수행능력 평가</li>
                        <li>연령별 뇌의 계산 속도 처리능력 평가</li>
                        <li>우뇌의 두뇌 발달의 수행능력 평가</li>
                        <li>단기기억 저장능력 평가</li>
                        <li>단기기억 인출 재생능력 평가</li>
                        <li>기억 순서와 과정 로드맵 형성 수행능력 평가</li>
                    </ul>,
                    text3: <ul className={'box'}>
                        <li>캐릭터를 변별하여 인지한 다음 문자로 변환하여 맞춰보세요.</li>
                        <li>
                            <span>설정한 제한시간과 초마다 띵동 소리와 함께 1개씩 최대 15개의</span> <br/>
                            <span style={{paddingLeft: 12}}>캐릭터가 화면에 나타납니다.</span>
                        </li>
                        <li>순서는 정답에 상관이 없습니다.</li>
                        <li>1개당 10점씩 총 {this.state.answerCount * 10}점 만점입니다.</li>
                    </ul>
                }
            } else {
                return {
                    title: `${testName} 검사`,
                    text1Title: `${testName} 검사 항목`,
                    text1:  <ul className={'box'}>
                        <li>자신이 원하는 것과 원하지 않는 것을 판단하여 습득할 수 있는 능력</li>
                        <li>차이점을 확실하게 구분하는 판단 능력</li>
                        <li>집중하여 관찰한 후 이해한 것을 직업 정보로 저장하는 능력</li>
                        <li>저장한 정보를 상기 기억에서 인출해내는 능력</li>
                    </ul>,
                    text2Title: `${testName}을 증강해야 할 대상`,
                    text2: <ul className={'box'}>
                        <li>주의력이 부족하여 판단이 정확히 서지 않는 사람</li>
                        <li>이것인가 저것인가 혼돈하여 구별해내지 못하는 사람</li>
                        <li>이해력이 부족하고 빨리 습득이 안되는 사람</li>
                        <li>인지력, 집중력, 순발력이 저하된 사람</li>
                        <li>기억력, 계산능력이 저하된 사람</li>
                    </ul>,
                    text3: <ul className={'box'}>
                        <li>캐릭터를 변별하여 인지한 다음 문자로 변환하여 맞춰보세요.</li>
                        <li>
                            <span>설정한 제한시간과 초마다 띵동 소리와 함께 1개씩</span> <br/>
                            <span style={{paddingLeft: 12}}>{this.state.answerCount}개 캐릭터가 화면에 나타납니다.</span>
                        </li>
                        <li>순서는 정답에 상관이 없습니다.</li>
                        <li>1개당 10점씩 총 {this.state.answerCount * 10}점 만점입니다.</li>
                    </ul>
                }
            }
        }

    }
    
    render() {
        const useAudio = this.state.useAudio
        // const testName = '1' === this.state.level ? '인지 변별력' : '메타 인지력'
        const testName = '인지변별력'
        const explainText = this.getExplainText(testName)
        return (
            <Dialog
                disableBackdropClick
                open={this.state.open}
                aria-labelledby="responsive-dialog-title"
                fullWidth
                maxWidth="lg"
                className={clsx(['level-dialog', this.state.measureType, this.state.backgroundColor])}
            >
                <DialogTitle id="responsive-dialog-title">
                    <Grid container>
                        <Grid item xs={6}>
                            <Box style={{fontSize:'1.8rem', paddingLeft: 20, paddingTop: 20, textAlign: 'center'}}>{explainText.title}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box pl={3} mt={2} className={'level-group'}>
                                {'measure' === this.state.measureType
                                    ?
                                    <Box>
                                        <ButtonGroup size="large" style={{width: '100%'}}>
                                            <Button
                                                color={15 === this.state.answerCount ? 'primary' : ''}
                                                variant={15 === this.state.answerCount ? 'contained' : 'outlined'}
                                                startIcon={<DoneOutlineIcon/>}
                                                style={{width: '50%'}}
                                                onClick={() => {
                                                    this.setState({
                                                        ...this.state,
                                                        answerCount: 15
                                                    })
                                                }}
                                            >1급 (15개)</Button>
                                            <Button
                                                color={10 === this.state.answerCount ? 'primary' : ''}
                                                variant={10 === this.state.answerCount ? 'contained' : 'outlined'}
                                                startIcon={<DoneOutlineIcon/>}
                                                style={{width: '50%'}}
                                                onClick={() => {
                                                    this.setState({
                                                        ...this.state,
                                                        answerCount: 10
                                                    })
                                                }}
                                            >2급 (10개)</Button>
                                        </ButtonGroup>
                                    </Box>
                                    :
                                    <Box>
                                        <ButtonGroup size="large" style={{width: '100%'}}>
                                            <Button startIcon={<DoneOutlineIcon/>}
                                                    color={5 === this.state.answerCount ? 'primary' : ''}
                                                    variant={5 === this.state.answerCount ? 'contained' : 'outlined'}
                                                    onClick={() => {
                                                        this.setState({
                                                            ...this.state,
                                                            answerCount: 5
                                                        })
                                                    }}
                                                    style={{width: '50%'}}>기초(5개)</Button>
                                            <Button startIcon={<DoneOutlineIcon/>}
                                                    color={10 === this.state.answerCount ? 'primary' : ''}
                                                    variant={10 === this.state.answerCount ? 'contained' : 'outlined'}
                                                    onClick={() => {
                                                        this.setState({
                                                            ...this.state,
                                                            answerCount: 10
                                                        })
                                                    }}
                                                    style={{width: '50%'}}>중급(10)</Button>
                                            <Button startIcon={<DoneOutlineIcon/>}
                                                    color={15 === this.state.answerCount ? 'primary' : ''}
                                                    variant={15 === this.state.answerCount ? 'contained' : 'outlined'}
                                                    onClick={() => {
                                                        this.setState({
                                                            ...this.state,
                                                            answerCount: 15
                                                        })
                                                    }}
                                                    style={{width: '50%'}}>고급(15)</Button>
                                        </ButtonGroup>
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </Grid>    
                    
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item sm={12} md={6}>
                            <Box>
                                <Typography className={'title point'}>{explainText.text1Title}</Typography>
                                {explainText.text1}
                            </Box>
                            <Box>
                                <Typography className={'title point'}>{explainText.text2Title}</Typography>
                                {explainText.text2}
                            </Box>
                            {this.state.audio &&
                            <div style={{padding: 10}}>
                                <ReactAudioPlayer

                                    autoPlay={useAudio}
                                    src={require(`@audio/${this.state.audio}-slow.mp3`)}
                                    controls
                                    style={{width: '100%'}}
                                />
                                <div style={{padding: 5}}>
                                    <input type="checkbox"
                                           checked={!useAudio} id="chk" style={{width:20, height:20}}
                                           onChange={(event,checked) => {
                                               const status = !useAudio
                                               // setAudio(status)
                                               // setCookie('_sana', status ? 'no' : 'yes')
                                               cookie.save('_sanaex', status ? 'no' : 'yes' , {
                                                   path : '/',
                                                   // secure : true,
                                                   // httpOnly : true
                                               });

                                               this.setState({
                                                   ...this.state,
                                                   useAudio: status
                                               })

                                           }}
                                    /><label htmlFor={'chk'}>다음부터 음성안내를 사용하지 않습니다 </label>
                                </div>
                            </div>
                            }
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Box mt={3} mb={3}>
                                <Typography className={'title'}>{['develop','train'].includes(this.state.measureType) ? '두뇌계발 ' : '검사'}방법</Typography>
                                {explainText.text3}
                                <Box >
                                <Grid container spacing={1} direction={'column'}>
                                  <Grid item>
                                    <Button fullWidth variant={"contained"}
                                            size={'large'}
                                            color={'primary'}
                                            style={{fontSize:'1.1rem'}}
                                            onClick={this.start.bind(this)}>
                                      시작하기
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <Button fullWidth variant={"outlined"}
                                            size={'large'}
                                            style={{fontSize:'1.1rem'}}
                                            onClick={this.onExit.bind(this)}>
                                      종료하기
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Box>
                                <Box pl={3} mt={2}>
                                    <Grid container className={'time-table'}>
                                        <Grid item xs={6}>
                                            <Typography className={'sub-title'}>시간설정</Typography>
                                            <FormControl component="fieldset">
                                                <RadioGroup name="customized-radios">
                                                    <FormControlLabel
                                                        checked={1000 === this.state.delay}
                                                        value={1000} control={<Radio />} label="1초" onClick={()=>{this.setDelay(1000)}} labelPlacement="end"/>
                                                    <FormControlLabel
                                                        checked={1500 === this.state.delay}
                                                        value={1500} control={<Radio />} label="1.5초" onClick={()=>{this.setDelay(1500)}} labelPlacement="end"/>
                                                    <FormControlLabel
                                                        checked={2000 === this.state.delay}
                                                        value={2000} control={<Radio />} label="2초" onClick={()=>{this.setDelay(2000)}} labelPlacement="end"/>
                                                    <FormControlLabel
                                                        checked={2500 === this.state.delay}
                                                        value={2500} control={<Radio />} label="2.5초" onClick={()=>{this.setDelay(2500)}} labelPlacement="end"/>
                                                    <FormControlLabel
                                                        checked={3000 === this.state.delay}
                                                        value={3000} control={<Radio />} label="3초" onClick={()=>{this.setDelay(3000)}} labelPlacement="end"/>
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={'sub-title'}>제한시간 설정</Typography>
                                            <FormControl component="fieldset">
                                                <RadioGroup name="customized-radios">

                                                    <FormControlLabel
                                                        checked={60 === this.state.limitTime}
                                                        value={60} control={<Radio/>} label="1분" onClick={() => {
                                                        this.setLimitTime(60)
                                                    }} labelPlacement="end"/>
                                                    <FormControlLabel
                                                        checked={120 === this.state.limitTime}
                                                        value={120} control={<Radio/>} label="2분" onClick={() => {
                                                        this.setLimitTime(120)
                                                    }} labelPlacement="end"/>
                                                    <FormControlLabel
                                                        checked={180 === this.state.limitTime}
                                                        value={180} control={<Radio/>} label="3분" onClick={() => {
                                                        this.setLimitTime(180)
                                                    }} labelPlacement="end"/>
                                                    <FormControlLabel
                                                        checked={240 === this.state.limitTime}
                                                        value={240} control={<Radio/>} label="4분" onClick={() => {
                                                        this.setLimitTime(240)
                                                    }} labelPlacement="end"/>
                                                    <FormControlLabel
                                                        checked={300 === this.state.limitTime}
                                                        value={300} control={<Radio/>} label="5분" onClick={() => {
                                                        this.setLimitTime(300)
                                                    }} labelPlacement="end"/>
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}
