import React, {useEffect, useState, useCallback, useRef} from 'react';
import { useParams } from "react-router-dom";

import { Box, Grid, Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton'
import PrescriptionSelect from '@user-components/PrescriptionSelect';
import PrescriptionService from '@user-service/PrescriptionService';
import ReactToPrint from "react-to-print";

import CurrentFFT from './CurrentFFT'
import CurrentBalanceBar from './CurrentBalanceBar'
import CurrentBalanceDot from './CurrentBalanceDot'
import CurrentBalancePie from './CurrentBalancePie'
import CurrentBrainActive from './CurrentBrainActive'
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AuthProvider from '@user-provider/AuthProvider'
import Message from "../Message";
import Typography from "@material-ui/core/Typography";
import CurrentExercise from "./CurrentExercise";
import CurrentCalendar from "./CurrentCalendar";
import FirstMeasureMessage from "../../../components/FirstMeasureMessage";
import CurrentMeasureBandTest from "./CurrentMeasureBandTest";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DeletePrescriptionDialog from "./DeletePrescriptionDialog";
import CurrentPreLevel from "./CurrentPreLevel";
import PrintIcon from '@material-ui/icons/Print';
import MeasureCurrentPrint from "./MeasureCurrentPrint";


const MeasureCurrent = ({history}) => {
    let { prescriptionID } = useParams()
    if('last' === prescriptionID ) {
        prescriptionID = null
    }
    const classes = useStyles()

    const [ isLoading, setLoading ] = useState(true)
    const [ measureResult, setMeasureResult ] = useState(null)
    const [ isFirstMeasure, setFirstMeasure ] = useState(false)
    const [ deleteDialogOpen, setDeleteDialogOpen ] = useState(false)

    const printRef = useRef();
    const [printElKey] = useState(Math.random)
    const [ readyToPrintStatus, setReadyToPrintStatus ] = useState({
        resolver: undefined,
        status : 'ready'
    })
    
    const fetch = useCallback(async () => {
        let id = prescriptionID
        if(null === id){
            id = await PrescriptionService.getLastPrescription({});
        }
        
        if(!id){
            setFirstMeasure(true)
            return
        }

        const result = await PrescriptionService.getResult(id)
        
        setMeasureResult(result)    
    },[prescriptionID])
    
    useEffect(()=>{   
        fetch().then(() => {
            setLoading(false)
        })
    },[fetch])
    
    useEffect(() => {
        setReadyToPrintStatus({status: 'ready'})    
    },[prescriptionID])


    if((!measureResult && !isFirstMeasure) || isLoading){
        return <LoadingSkeleton />
    }
    
    
    const printTrigger = () => {
        if('loading' === readyToPrintStatus.status){
            return <Button
                startIcon={<PrintIcon/>}
                disabled={true}
                style={{padding: '7.5px 21px', marginRight: 10}}
                variant={'outlined'} size={'large'}> 
                생성중
            </Button>
        }

        return (
            <Button
                className={'btn-print'}
                startIcon={<PrintIcon/>}
                style={{padding: '7.5px 21px', marginRight: 10}}
                variant={'outlined'} size={'large'}>인쇄
            </Button>
        )
    }
    
    const profileID = AuthProvider.isCustomerView() ? AuthProvider.getCustomerProfileID() : AuthProvider.getProfileID()
    return (
        <>
            <div id="cont-wrap">
                <div className="sv">
                    <div>
                        <h3>뇌기능 분석 {(measureResult && measureResult.id) ? ` - ${measureResult.id} 회차` : ''}</h3>
                        <span>EEG, 균형상태 측정, 뇌기능 의식패턴을 검사 결과를 확인 할 수 있습니다</span>
                        <i>Brain Health</i>
                    </div>
                </div>
                <div className="cont" style={{paddingBottom: 100}}>

                    {isFirstMeasure && <FirstMeasureMessage /> }
                    {!isFirstMeasure &&
                        <React.Fragment>
                            <Box mb={1}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <PrescriptionSelect
                                            prescriptionID={prescriptionID}
                                            url="/measureCurrent/:prescriptionID"/>
                                    </Grid>
                                    <Grid item xs={7} style={{paddingLeft: 10}}>
                                        {['done'].includes(readyToPrintStatus.status) &&
                                        <ReactToPrint
                                            trigger={printTrigger}
                                            content={() => printRef.current}
                                        />
                                        }
                                        
                                        {['ready','loading'].includes(readyToPrintStatus.status) &&
                                            <Button
                                                startIcon={<PrintIcon/>}
                                                style={{padding: '7.5px 21px', marginRight: 10}}
                                                variant={'outlined'} size={'large'}
                                                disabled={'loading' === readyToPrintStatus.status}
                                                onClick={()=>{
                                                    setReadyToPrintStatus({status: 'loading'})
                                                }}
                                            >{'loading' === readyToPrintStatus.status ? '생성중..' : '인쇄'}
                                            </Button>
                                        }
                                        
                                        {AuthProvider.isSuperAdmin() &&
                                            <Button
                                                startIcon={<HighlightOffIcon/>}
                                                style={{padding: '7.5px 21px', color: "red"}}
                                                variant={'outlined'} size={'large'} onClick={() => {
                                                setDeleteDialogOpen(true)
                                            }}>삭제</Button>
                                        }
                                    </Grid>
                                    <Grid item xs={2}>
                                        {AuthProvider.isSuperAdmin() &&
                                            <Box display={'flex'} justifyContent={'flex-end'} justifyItems={'flex-end'}>
                                                <Button
                                                    className={'common-button'}
                                                    onClick={() => {
                                                        history.push('/prescriptionExercise/' + measureResult.id)
                                                    }}
                                                    endIcon={<ChevronRightIcon className={'rightIcon'}/>}
                                                >
                                                    처방전
                                                </Button>
                                            </Box>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                            <Grid container spacing={2} alignItems={"center"} alignContent={"center"} style={{width: '100%'}}>
                                <Grid item xs={12}>
                                    <div className={'print-logo'} >
                                        <img src={require('@images/logo.png')} />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <CurrentFFT measureResult={measureResult}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <CurrentPreLevel type="BALANCE" measureResult={measureResult} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CurrentBalanceBar measureResult={measureResult}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CurrentBalancePie measureResult={measureResult}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <CurrentBalanceDot measureResult={measureResult}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <CurrentBrainActive measureResult={measureResult}/>
                                </Grid>
                                <Grid item xs={12} className={'print-top-100'}>
                                    <CurrentMeasureBandTest measureResult={measureResult}/>
                                </Grid>
                                {/*<Grid item xs={12}>*/}
                                {/*    <CurrentMeasureTest measureResult={measureResult}/>*/}
                                {/*</Grid>*/}
                                <Grid item xs={12}>
                                    <CurrentExercise prescriptionId={measureResult.id}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <CurrentCalendar prescriptionID={measureResult.id} profileID={profileID} title={'뇌기능운동 관리'}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mt={2}>
                                        <Typography variant={'subtitle1'} className={classes.cardTitle}>
                                            담당교육사의 메세지
                                        </Typography>
                                        <Box p={5} pt={2}>
                                            <Message profileID={profileID} prescriptionID={measureResult.id}/>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                </div>
                {measureResult &&
                    <DeletePrescriptionDialog
                        open={deleteDialogOpen}
                        prescriptionID={measureResult.id}
                        onCancel={() => {
                            setDeleteDialogOpen(false)
                        }}
                        onClose={() => {
                            window.location.reload()
                        }}
                    />
                }
            </div>
            {['loading','done'].includes(readyToPrintStatus.status) &&
            <div ref={printRef} className={'print-wrap'}>
                <MeasureCurrentPrint 
                    key={printElKey}
                    prescriptionID={measureResult.id}
                    measureResult={measureResult}
                    profileID={AuthProvider.getProfileID()}
                    onReadyToPrint={() => {
                        setReadyToPrintStatus({
                            status: 'done',
                            resolver: null
                        })
                        
                        const print = document.querySelector('.btn-print')
                        if(print) {
                            setTimeout(() => {
                                print.click()
                            }, 1000)
                        }
                    }}
                />
            </div>
            }
        </>
    )
}

export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className="editprofile_wrap wrapper" xs={6} sm={6}>
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 1400,
        width: 1400,
    },
    headerBox: {
        marginBottom: 40,
        marginTop: 120
    },
    title: {
        fontSize:26,
        fontWeight: 'bold'
    },
    cardTitle: {
        fontWeight: 'bold',
        paddingLeft: 25,
        marginBottom: 20
    },
  
}));



export default MeasureCurrent
