import React, {useEffect, useState} from 'react';
import { Button, Box, Grid, Typography} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

import { saveAs } from 'file-saver';

import PrescriptionService from '@user-service/PrescriptionService'
import MeasureBoxBar from '@user-components/MeasureBoxBar'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import nl2br from 'react-nl2br'


const MeasureHistory = () => {
    const classes = useStyles();
    
    const [histories, setHistories] = useState(null)
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

    useEffect(()=>{ 
        PrescriptionService.getMeasureHistories(currentYear, (result)=>{
            
            var histories = []
            var thisYear = new Date().getFullYear()
            var month = new Date().getMonth() +1

            if(thisYear > currentYear){
                month = 12
            }

            for(var i = month; i >= 1 ; i--){
                var key = currentYear + '-' + ('00' + i).substr(-2)


                const monthData = result[key]
                histories.push({
                    key: key,
                    data: monthData
                })

            }

            setHistories([...histories])

        })
    },[currentYear])

    if(!histories) return <></>

    return (
        <div className="mypage-wrap wrapper2">
                <section className="section section2_2 bdtop pt40">
                    <Typography variant="h5" component="h3" >
                        분석기록
                    </Typography>

                    <Typography variant="subtitle1" 
                                style={{textAlign:'left',paddingTop:20,paddingBottom:20, borderBottom:'1px solid #eee', marginBottom:20, color:'#999'}}>
                    뇌파 측정 결과를 볼 수 있습니다.
                    </Typography>
                </section>

                <Grid container justify="center" spacing={5}> 
                    <Grid item>
                        <Button  startIcon={<KeyboardArrowLeftIcon />} size="large" onClick={()=>{
                            setCurrentYear(currentYear-1)
                        }}>
                            {currentYear-1}년
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button size="large" variant="outlined">
                            <Typography variant="h5" component="h3" >
                                {currentYear}년
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button size="large" endIcon={<KeyboardArrowRightIcon />} onClick={()=>{
                            setCurrentYear(currentYear+1)
                        }}>
                            {currentYear+1}년
                        </Button>
                    </Grid>
                </Grid>

            {histories.map((history)=>{
                const title = history.key.replace('-','년') + '월'

                return <div key={history.key}>
                            <div className={classes.yearTitle}>{title}</div>
                            <MeasureItem {...history}/>
                        </div>
            })}
            
        </div>
    );
}


export function MeasureItem(props) {
    const classes = useStyles();

    // 파일을 다운로드
    // const downloadFile = async (prescriptionID, type)=> {
    //     const file = await PrescriptionService.getBlob(prescriptionID, type)
    //     saveAs(file, prescriptionID + '_' + type + '.txt')
    // }

    return <div>    
            {props.data.map((measure)=>{
                if('measure-request' === measure.status || 'measuring' === measure.status) {
                    return;
                }

                return <section key={measure.id} className="section section2_2 bdtop pt40">
                    <h3 className={classes.measureTitle}>연구소 - {measure.id}회차 측정</h3>
                        <p className="txt02">{measure.createdAt}</p>
                        {/* <Link className="result-btn">
                            <Button>상세보기</Button>
                        </Link> */}
                        {/* <div>
                            <ul className="clearfix">
                                <li>
                                    <span>안정 지수</span>
                                    <b>{measure.rest}</b>
                                </li>
                                <li>
                                    <span>주의력 지수</span>
                                    <b>{measure.attemption}</b>
                                </li>
                                <li>
                                    <span>집중력 지수</span>
                                    <b>{measure.concentration}</b>
                                </li>
                            </ul>
                        </div> */}
                        {/* <Box mt={4} mb={4}>
                            <MeasureBoxBar title={'안정 지수'} value={measure.rest} color={"blue"}  data={measure.measureRest}/>
                            <MeasureBoxBar title={'주의력 지수'} value={measure.attemption} color={"green"}  data={measure.measureAttemption}/>
                            <MeasureBoxBar title={'집중력 지수'} value={measure.concentration} color={"red"}  data={measure.measureConcentration}/>

                        </Box> */}

                        <Grid container justify="flex-end">
                            <Box marginRight={3}>
                                {/* <Button variant="contained" onClick={()=>{
                                    deletePrescription(measure.id);
                                }}>
                                    삭제
                                </Button> */}
                                <Button variant="contained" onClick={()=>{window.location.href='/measureDetail/' + measure.id}}>
                                    결과 상세보기
                                </Button>
                                {/* <Button variant="contained" 
                                        onClick={()=>{
                                            downloadFile(measure.id, 'fft')
                                        }}>
                                    FFT 다운로드
                                </Button>
                                <Button variant="contained" 
                                        onClick={()=>{
                                            downloadFile(measure.id, 'band')
                                        }}>
                                    BAND 다운로드
                                </Button> */}
                            </Box>
                        </Grid>

                        {'done' !== measure.status &&
                            <div className="result-ing">
                                {/* <p className="alignLeft">연구소에서 균형상태를 분석중입니다.</p>
                                <p className="txt02">최대 2~3일 정도가 소요됩니다.</p> */}
                            </div>
                        }

                        {'done' === measure.status &&
                            <div>
                                {/* <p className="result-finish">처방이 완료되었습니다.</p>
                                <div className="result-coment">
                                    <div className="clearfix">
                                        <div>
                                            <Avatar alt="Remy Sharp" src="/static/media/brainIconBlack.a717aa64.png" />
                                            <b>유경진 원장님</b>
                                            <span>브레인헬스 연구소</span>
                                        </div>
                                        <div className="right"><span>2019.11.11</span></div>
                                    </div>
                                    {measure.prescription &&
                                    <p>{nl2br(measure.prescription.message)}</p>
                                    }
                                </div> */}
                            </div>
                        }

                        <div className={classes.emptyBottom}></div>
                    </section>
            })}
            </div>
}


const useStyles = makeStyles(theme => ({
    yearTitle: {
        fontSize:30,
        marginTop: 50,
        marginBottom: 17,
        textAlign: 'left'
    },
    emptyBottom: {
        height:30
    },
    measureTitle: {
        fontSize:14,
        fontWeight: 'bold !important',
        color: '#333',

    }
}));



export default MeasureHistory
