import React from "react";
import {Box, Typography} from "@material-ui/core";

export default class TimeCount extends React.Component {
    isPause = false
    intervalTimer = null
    
    min
    second 
    hour = '00'
    
    sumTimeCount = 0 
    lastTimeCount = 0 
    
    constructor(props) {
        super(props)
        this.state = {
            timeCount: 0,
            textColor: props.textColor ? props.textColor : '#fff',
            textSize: props.textSize ? props.textSize : '24',
            runningTime: props.runningTime,
            style: props.style,
            useAppendTime: null !== props.useAppendTime ? props.useAppendTime : false
        }
    }
    
    componentWillUnmount() {
        if(this.intervalTimer){
            clearInterval(this.intervalTimer)
        }
    }

    update(timeCount) {
        const { useAppendTime } = this.state
        this.sumTimeCount += 1
        this.setState({
            timeCount: useAppendTime ? this.sumTimeCount : timeCount
        })
    }

    
    render() {
        this.min = ('00' + parseInt(this.state.timeCount / 60)).substr(-2)
        this.second = ('00' + parseInt(this.state.timeCount % 60)).substr(-2)
        const variant = this.state.variant ? this.state.variant : 'inherit'
        
        return (
            <Box style={{...this.state.style}}>
                <Typography variant={variant} style={{fontWeight: 'bold', fontSize: '1.5rem'}}>
                    {this.hour}:{this.min}:{this.second}
                </Typography>
            </Box>
        )
    }
    
    getTime(isMeasureTime = false) {
        const { timeCount } = this.state
        let measureTime = timeCount
        
        if(isMeasureTime){
            measureTime = timeCount - this.lastTimeCount
            this.lastTimeCount = timeCount    
        }
        return measureTime
    }
    
    getTimeText(){
        return `${this.min}분 ${this.second}초`
    }
    
    getTimePassedText(defaultCount){
        let past = this.getTimePassed(defaultCount)
       
        
        const min = Math.floor( past / 60)
        const second = Math.round((past  % 60))
        let text = ''
        
        
        if(0 < min) {
            text = `${min}분 `
        }
        text += `${second}초`
        
        return text 
    }
    
    getTimePassed(defaultCount){
        let past = defaultCount - this.state.timeCount
        if(1 === this.state.timeCount){
            past = past + 1
        }
        return past
    }
    
    startWithInterval(){
        if(this.isPause){
            this.isPause = false;
            return;
        }
    
        if(this.intervalTimer){
            clearInterval(this.intervalTimer)
        }
        
        this.lastTimeCount = 0
        this.intervalTimer = setInterval(() => {
            if(this.isPause){
                return
            }
            this.setState({
                timeCount: this.state.timeCount + 1
            })
        }, 1000)
    }
    
    startWithCountdown(defaultCount = 60, ovverHandler){
        if(this.intervalTimer){
            clearInterval(this.intervalTimer)
        }

        let count = defaultCount
        this.setState({
            timeCount: count
        })
        
        this.intervalTimer = setInterval(() => {
            count--
            if(0 === count){
                clearInterval(this.intervalTimer)
                if(ovverHandler){
                    ovverHandler()
                }
            } 
            
            this.setState({
                timeCount: count
            })
        }, 1000)
    }
    
    pauseInterval(){
        this.isPause = true
    }
    
    togglePauseInterval(){
        this.isPause = !this.isPause 
    }
    
    clearInterval(){
        if(this.intervalTimer){
            clearInterval(this.intervalTimer)
        }
    }
    
    resetInterval(){
        if(this.intervalTimer){
            clearInterval(this.intervalTimer)
        }

        this.isPause = false
        this.lastTimeCount = 0
        this.setState({
            timeCount: 0
        })
    }
}