import React from 'react';
import AuthProvider from '@user-provider/AuthProvider'
import {useCookies} from "react-cookie";
import BridgeProvider from "../../provider/BridgeProvider";

const queryString = require('query-string');
export default function Logout() {
    const [,,removeCookie] = useCookies([AuthProvider.userCookieName]);
    
    const isAppMode = AuthProvider.isAppMode()
    const serviceType = AuthProvider.getServiceType()
    
    AuthProvider.remove()
    removeCookie(AuthProvider.userCookieName, AuthProvider.getCookieOption())
  
    try {
      localStorage.removeItem(AuthProvider.userAdminCookieName)  
    } catch {}
    
  
    const qs = queryString.parse(window.location.search)
    if(qs.rtu){
        document.location.replace(qs.rtu)       
    } else {
        let params = ''
        if(isAppMode){
            BridgeProvider.instance.logout()
            
            params = `serviceType=${serviceType}`
        }
        document.location.href = `/login?${params}`
    }
    return <></>;
}


