import React, {useEffect, useRef} from "react";
import {Box, Button, Grid} from "@material-ui/core";

import BrainHexMap from '@user-components/BrainHexMap'
/**
 * 브레인 맵 - 균형상태 분석
 * @param {*} props
 */
export default function ViewBrainHexMap(props) {
    const restMapRef = useRef()
    const attempMapRef = useRef()
    const concentMapRef = useRef()
    const brainactiveMapRef = useRef()
    const { prescription, width, height } = props
    
    useEffect(() => {
        restMapRef.current.setHexMap(prescription.measureRest, 'rest')
        attempMapRef.current.setHexMap(prescription.measureAttemption, 'attemption')
        concentMapRef.current.setHexMap(prescription.measureConcentration, 'concentration')
        brainactiveMapRef.current.setHexMap(prescription.measureBrainactive, 'brainactive')
        
        restMapRef.current.needUpdate()
        attempMapRef.current.needUpdate()
        concentMapRef.current.needUpdate()
        brainactiveMapRef.current.needUpdate()

    }, [prescription]);

    const hexWidth = width ? width : 308
    const hexHeight = height ? height : 300

    return  <Box padding={2}>
        <Grid container justify={'center'} spacing={3}>
            <Grid item>
                <Box m="auto" className={'hex-wrap'}>
                    <BrainHexMap width={hexWidth} height={hexHeight} prescriptionID={props.prescriptionID}  ref={restMapRef} />
                    <MeasureState title={"안정"} value={prescription.restPercent} measureType={'rest'} id={props.prescriptionID} />
                </Box>
            </Grid>
            <Grid item>
                <Box m="auto" className={'hex-wrap'}>
                    <BrainHexMap width={hexWidth} height={hexHeight}  prescriptionID={props.prescriptionID}  ref={attempMapRef} />
                    <MeasureState title={"주의력"} value={prescription.attemptionPercent} measureType={'attemption'} id={props.prescriptionID} />
                </Box>
            </Grid>
            <Grid item>
                <Box m="auto" className={'hex-wrap'}>
                    <BrainHexMap width={hexWidth} height={hexHeight}  prescriptionID={props.prescriptionID}  ref={concentMapRef} />
                    <MeasureState title={"집중력"} value={prescription.concentrationPercent} measureType={'concentration'} id={props.prescriptionID} />
                </Box>
            </Grid>
            <Grid item>
                <Box m="auto" className={'hex-wrap'}>
                    <BrainHexMap width={hexWidth} height={hexHeight}  prescriptionID={props.prescriptionID}  ref={brainactiveMapRef} />
                    <MeasureState title={"뇌활성"} value={prescription.brainactivePercent} measureType={'brainactive'} id={props.prescriptionID} />
                </Box>
            </Grid>
        </Grid>
    </Box>
}

export function MeasureState(props) {
    return (
        <Box display="flex" justifyContent="center">
            <Box>
                <Box mb={1} mt={2} style={{textAlign: 'center'}} className={'print-h1'}>{props.title}</Box>
                <Box style={{textAlign: 'center'}} className={'print-h2'}>
                    <b>{ props.value }%</b>    
                </Box>
                <Box mt={1} className={'show-fft'}>
                    <Button 
                        size="small"
                        variant="outlined"
                        onClick={()=>{
                            window.open(`/measureFFT/${props.id}/${props.measureType}`)
                        }}>
                        FFT
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}