import React from 'react';
import Dialog from '@material-ui/core/Dialog';

export default class Reward extends React.Component {
    videoRef = React.createRef()
    videoSource = 'https://d2iajcsshqwtw3.cloudfront.net/assets/memoryEggReward.webm'
    
    constructor(props) {
        super();

        this.state = {
            levelMode: 'easy',
            open: false,
            onStart: props.onStart,
            onVideoEnded: null
        }
    }

    open(afterVideoEnded) {
        this.setState({
            ...this.state,
            open: true,
            onVideoEnded: afterVideoEnded
        })
    }

    close() {
        this.setState({
            ...this.state,
            open: false
        })
    }

    onVideoEnded() {
        this.setState({
            ...this.state,
            open: false
        },() => {
            if(this.state.onVideoEnded){
                this.state.onVideoEnded()
            }
        })
    }

    onVideoLoad() {
        setTimeout(() => {
            this.videoRef.current.play()
        }, 500)

    }
    
    render() {
        return (
            <Dialog
                disableBackdropClick
                open={this.state.open}
                maxWidth={'lg'}
            >
                <video id="intro-video"
                       onEnded={this.onVideoEnded.bind(this)}
                       onLoadedData={this.onVideoLoad.bind(this)}
                       ref={this.videoRef}
                       poster={'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/memoryEggIntro.png'}
                       style={{height: '100%', width: '100%', objectFit: 'cover' }}>
                    <source src={this.videoSource} type="video/mp4"/>
                </video>
            </Dialog>
        );
    }
}
