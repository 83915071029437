import React from 'react';
import AuthProvider from '@user-provider/AuthProvider'

import {
    Switch,
    Route
} from 'react-router-dom';

import Login from '@user-pages/login/Login';
import Logout from '@user-pages/login/Logout';
import ResetPassword from '@user-pages/login/ResetPassword';
import EditProfile from '@user-pages/login/EditProfile';
import Join from '@user-pages/login/Join';
import JoinDone from '@user-pages/login/JoinDone';
import Profile from '@user-pages/login/Profile';
import ProfileSelector from '@user-pages/login/ProfileSelector';
import ProfileAvatar from '@user-pages/login/ProfileAvatar';
import LoginHelp from '@user-pages/login/LoginHelp'
import RegisterComplete from '@user-pages/login/RegisterComplete'

// import DashBoard from '@user-pages/mypage/dash/DashBoard'      //마이페이지
import DashBoard from '@user-pages/mypage/dashRe/DashBoardRe'      //마이페이지
import Message from '@user-pages/mypage/Message'    //마이페이지 - 메세지
import BrainHealth from '@user-pages/mypage/brainHealth/BrainHealth'        //마이페이지 - 브레인헬스 
import HelpDesk from '@user-pages/mypage/HelpDesk'  //마이페이지 - 도움말
import Logs from '@user-pages/logs/Logs'  //기록 

import MeasureTest from '@user-pages/mypage/measureTest/MeasureTest'        //마이페이지 - 
import MeasureSelect from '@user-pages/mypage/measureSelect/MeasureSelect'  //뇌파 측정 모드 선택
import MeasureSchedule from '@user-pages/mypage/MeasureSchedule'  //뇌파 측정 결과 달력으로 확인
import Measure from '@user-pages/mypage/measure/Measure'  //뇌파측정 뇌 균형상태 측정
import MeasureBand from '@user-pages/mypage/measureBand/MeasureBand'  //뇌파측정 120초 밴드 측정
import MeasureResult from '@user-pages/mypage/MeasureResult'  //뇌파측정 결과
import MeasureDetail from '@user-pages/mypage/measureDetail/MeasureDetail'  //뇌파측정 상세보기 
import MeasureFFT from '@user-pages/mypage/MeasureFFT'  //뇌파측정 균형 개별 상세보기
import MeasureFFTs from '@user-pages/mypage/MeasureFFTs'  //뇌파측정 균형 개별 상세보기
import MeasureHistory from '@user-pages/mypage/MeasureHistory'        //마이페이지 - 브레인헬스 
import MeasureCurrent from '@user-pages/mypage/measureCurrent/MeasureCurrent'        //분석 결과
import MeasureBalanceCurrent from '@user-pages/mypage/measureBalanceCurrent/MeasureBalanceCurrent'        //분석 결과

import PrescriptionExercise from '@user-pages/mypage/measureCurrent/PrescriptionExercise'        //운동
import Feedback from '@user-pages/exercise/feedback/Feedback'        //운동
import MemoryEgg from '@user-pages/exercise/memoryEgg/MemoryEgg'        //운동
import Balance from '@user-pages/exercise/balance/Balance'        //운동
import Concentration from '@user-pages/exercise/Concentration'        //운동
import Relax from '@user-pages/exercise/relax/Relax'        //운동
import Breath from '@user-pages/exercise/breath/Breath'        //운동
import BreathStamp from '@user-pages/exercise/breath/BreathStamp'        //운동
import Meditation from '@user-pages/exercise/meditation/Meditation'        //운동
import MeditationTest from '@user-pages/exercise/meditation/MeditationTest'        //운동
import Synapse from '@user-pages/exercise/synapse/Synapse'        //운동
import ExerciseMeasureBand from '@user-pages/exercise/MeasureBand'  //뇌파측정 120초 밴드 측정
import ExerciseMeasureBandTest from '@user-pages/exercise/measureBandTest/MeasureBandTest'  //뇌파측정 120초 밴드 측정

import MemoryTest from '@user-pages/exercise/memoryTest/MemoryTest'        //운동
import CognitiveTest from '@user-pages/exercise/cognitiveTest/CognitiveTest'        //운동

import Survey from '@user-pages/survey/Survey'  //자각증상
import SurveySimple from '@user-pages/survey/SurveySimple'  //자각증상
import SurveyList from '@user-pages/survey/SurveyList'  //자각증상 리스트
import SurveyAdmin from '@user-pages/survey/SurveyAdmin'  //자각증상 리스트


import LifeCycle from "../pages/life-cycle/LifeCycle";

import Setting from '@user-pages/setting/Setting' //설정 페이지
import NoiseManage from '@user-pages/setting/NoiseManage'
import ComputerManage from "@user-pages/setting/ComputerManage";
import Managers from "@user-pages/setting/Managers";

import Customer from "@user-pages/manage/customer/Customer";
import RequestPrescript from "@user-pages/manage/measure/RequestPrescript";

import Voucher from '@user-pages/payment/Voucher'

import Device from '@user-pages/device/Device'
import DeviceWaiting from '@user-pages/device/DeviceWaiting'
import DeviceDisconnect from '@user-pages/device/DeviceDisconnect'
import DeviceManage from '@user-pages/device/DeviceManage'
import DeviceStatus from '@user-pages/device/DeviceStatus'


import AppWaiting from "../pages/app/AppWaiting";

import UpdateReport from '@user-pages/update/UpdateReport'
import CustomerResponseDialog from "@user-components/CustomerResponseDialog"

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NavBar from '@user-components/NavBar'

import clsx from "clsx";
import NatureFeedback from "../pages/exercise/intoSleep/NatureFeedback";
import IntoSleep from "../pages/exercise/intoSleep/IntoSleep";
import {Helmet} from "react-helmet-async";
import PageService from "../services/PageService";
import Language from "../pages/exercise/language/Language";
import MemoryComplex from "../pages/exercise/memoryComplex/MemoryComplex";
import GatewayPrint from "../pages/gateway/GatewayPrint";
import GatewayLogs from "../pages/gateway/GatewayLogs";
import MeasureTrain from "../pages/mypage/measureTest/MeasureTrain";
import EmptyPage from "../pages/app/EmptyPage";
import CognitiveTestPrint from "../pages/exercise/cognitiveTest/CognitiveTestPrint";
import MemoryTestPrint from "../pages/exercise/memoryTest/MemoryTestPrint";


export function Layout(props) {
    const classes = useStyles();
    if(!AuthProvider.checkAuthToken(props.location.pathname)) return <></>
    const meta = PageService.pageMeta(AuthProvider.getServiceType())
    
    return (
        <Container
            className={clsx(classes.root)}
            maxWidth={false}>

            {AuthProvider.isAppMode() &&
            <Helmet>
                <title>{meta.title}</title>
                <link rel="icon" type="image/png" sizes="16x16" href={meta.favicon16}/>
                <link rel="icon" type="image/png" sizes="32x32" href={meta.favicon32}/>
                <link rel="icon" type="image/png" sizes="96x96" href={meta.favicon96}/>
                <meta name="description" content="브레인헬스"/>
                <meta property="og:image" content="/aseets/images/sns.jpg"/>
            </Helmet>
            }
            
            <NavBar location={props.location} history={props.history}/>
            <Switch>
                {/* 로그인 관련 */}
                <Route path="/reset-password" component={ResetPassword}/>
                <Route path="/login" component={Login}/>
                <Route path="/logout" component={Logout}/>
                <Route path="/login-device" component={Device}/>
                <Route path="/waiting-device" component={DeviceWaiting}/>
                <Route path="/rest-device" component={DeviceWaiting}/>
                <Route path="/disconnect-device" component={DeviceDisconnect}/>

                <Route path="/waiting-app" component={AppWaiting}/>

                <Route path="/join" component={Join} />
                <Route path="/joinDone" component={JoinDone} />
                <Route path="/loginHelp" component={LoginHelp}/>
                <Route path="/profile/avatar/:profileID" component={ProfileAvatar}/>
                <Route path="/editProfile/:profileID" component={EditProfile} />
                <Route path="/profile" component={Profile}/>
                <Route path="/profile-selector" component={ProfileSelector}/>
                <Route path="/registerComplete" component={RegisterComplete}/>

                <Route path="/mypage" component={DashBoard}/>
                <Route path="/dashboard" component={DashBoard}/>
                <Route path="/message" component={Message}/>

                <Route path="/measure/:prescriptionID" component={Measure}/>
                <Route path="/measureTest" component={MeasureTest}/>
                <Route path="/measureTrain" component={MeasureTrain}/>
                <Route path="/measureSelect" component={MeasureSelect}/>
                <Route path="/measureSchedule" component={MeasureSchedule}/>
                <Route path="/measureCurrent/:prescriptionID" component={MeasureCurrent}/>
                <Route path="/measureBalanceCurrent/:prescriptionID" component={MeasureBalanceCurrent}/>
                <Route path="/measureBand/:prescriptionID" component={MeasureBand}/>
                <Route path="/measureResult" component={MeasureResult}/>
                <Route path="/measureDetail/:prescriptionID/:type" component={MeasureDetail}/>
                <Route path="/measureFFT/:prescriptionID/:bandType" component={MeasureFFT}/>
                <Route path="/measureFFTs/:prescriptionID/:bandType" component={MeasureFFTs}/>
                <Route path="/measureHistory" component={MeasureHistory}/>

                <Route path="/prescriptionExercise/:prescriptionID" component={PrescriptionExercise}/>

                <Route path="/brainhealth" component={BrainHealth}/>
                {/* <Route path="/exercise/:exerciseID" component={Exercise}/> */}
                <Route path="/exercise/synapse" component={Synapse}/>
                <Route path="/exercise/feedback" component={Feedback}/>
                <Route path="/exercise/memoryEgg" component={MemoryEgg}/>
                <Route path="/exercise/balance" component={Balance}/>
                <Route path="/exercise/concentration" component={Concentration}/>
                <Route path="/exercise/relax" component={Relax}/>
                <Route path="/exercise/breathStamp" component={BreathStamp}/>
                <Route path="/exercise/breath/:type" component={Breath}/>
                <Route path="/exercise/meditation/:type" component={Meditation}/>
                <Route path="/exercise/meditationTest/:prescriptionID" component={MeditationTest}/>
                <Route path="/exercise/measureBand" component={ExerciseMeasureBand}/>
                <Route path="/exercise/measureBandTest/:prescriptionID" component={ExerciseMeasureBandTest}/>
                <Route path="/exercise/intoSleep/natureFeedback" component={NatureFeedback}/>
                <Route path="/exercise/intoSleep/intoSleep" component={IntoSleep}/>
                
                <Route path="/exercise/memoryTest/:showType" component={MemoryTest}/>
              
                <Route path="/exercise/cognitiveTest/:showType" component={CognitiveTest}/>
              
                <Route path="/exercise/language" component={Language}/>
                <Route path="/exercise/memoryComplex/:showType" component={MemoryComplex}/>

                {/*<Route path="/prescription/:prescriptionID" component={Prescript2ionExercise}/>*/}

                <Route path="/helpdesk" component={HelpDesk}/>
                <Route path="/Logs" component={Logs}/>

                <Route path="/survey-year" component={SurveyList}/>
                <Route path="/survey-admin" component={SurveyAdmin}/>
                <Route path="/survey/recognition" component={SurveySimple}/>
                <Route path="/survey/:surveyType" component={Survey}/>

                <Route path="/life-cycle" component={LifeCycle}/>

                <Route path="/update-report" component={UpdateReport}/>
                <Route path="/voucher" component={Voucher}/>

                <Route path="/setting/deviceManage" component={DeviceManage}/>
                <Route path="/setting/noiseManage" component={NoiseManage}/>
                <Route path="/setting/computerManage" component={ComputerManage}/>
                <Route path="/setting/managers" component={Managers}/>
                <Route path="/setting" component={Setting}/>


                <Route path="/manage/customer" component={Customer}/>
                <Route path="/manage/requestPrescript" component={RequestPrescript}/>

                <Route path="/gateway/print/:profileID/:prescriptionID" component={GatewayPrint}/>
                <Route path="/gateway/logs/:profileID" component={GatewayLogs}/>
                <Route path="/gateway/cognitiveTest/print/:logID" component={CognitiveTestPrint}/>
               <Route path="/gateway/memoryTest/print/:logID" component={MemoryTestPrint}/>

              <Route path="/empty-page" component={EmptyPage}/>

                {/* <Route path="/brainhealth"><MypagePage/></Route> */}
                {/* <Route path="/helpdesk"><HelpdeskPage/></Route> */}

            </Switch>

            <CustomerResponseDialog />
            <DeviceStatus />
        </Container>
    );
}

const useStyles = makeStyles(() => ({
    root: {
        margin: 0,
        padding:0,
        justify: "center",
        alignItems: "center",
    },
    showNav: {
        display: 'show'
    },
    hideNav: {
        display: 'non'
    },
    boxWrap: {
        maxWidth: 1280
    }
}));

