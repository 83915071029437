import React, {useEffect} from "react";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ReactAudioPlayer from "react-audio-player";
import {Button} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import AuthProvider from "../../../provider/AuthProvider";

/**
 * 측정 종료가 되면 결과 페이지로 이동하기 위한 팝업
 */
export function EndMeasureDialog(props) {
    const [,setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(()=>{
        setOpen(props.open)
    },[props.open])

    let url = `/measureCurrent/last`
    if(AuthProvider.isProfileSelectorView()){
      url = `/survey/emotion`
    }

    return (
        <Dialog
            disableBackdropClick
            fullScreen={fullScreen}
            open={props.open}
            aria-labelledby="responsive-dialog-title"
            className={'innerViewport'}
        >
            <DialogTitle className="common-dialog-title" >항상성 자기조절 측정 완료</DialogTitle>
            <DialogContent className={'common-dialog-content'}>
                항상성 자기조절 측정을 완료하셨습니다.
                <br/>
                <br/>
                <ReactAudioPlayer
                    src={require('@audio/04-03.mp3')}
                    autoPlay
                    controls
                    onEnded={()=>{
                        // props.history.replace(url)
                      document.location.replace(url)
                    }}
                />
            </DialogContent>
            <DialogActions className={'common-dialog-action'}>
                <Button
                    autoFocus
                    fullWidth={true}
                    onClick={()=>{
                        // props.history.replace(url)
                      document.location.replace(url)
                    }}>
                    결과 확인
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EndMeasureDialog