import React, {useCallback, useEffect, useState} from "react";
import {Box, Grid} from "@material-ui/core";

import PrescriptionService from '@user-service/PrescriptionService';
import Typography from "@material-ui/core/Typography";
/**
 * 측정한 FFT 결과를 보여주는 차트
 * @param {*} props
 */
export default function BrainFFT(props) {
    const [ fftImages , setFFTImages ] = useState(null);
    const { id, fftType } = props.prescription
    
    
    const fetch = useCallback(async () => {
        setFFTImages(await PrescriptionService.getFFTImage(id))
    }, [id])
    
    useEffect(() => {
        fetch().then()
    },[fetch])
    
    const height = 'split' !== fftType ? 900 : 550
    return (
        <Grid container>
            <Grid item xs={12} style={{position: 'relative'}} >
                {fftImages
                    ?
                    <React.Fragment>
                        <Box 
                            style={{
                            backgroundImage: `url(${fftImages.ch1})`,
                            height: height,
                            backgroundSize: '100%',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                        }}/>

                        {'split' === fftType &&
                        <Box
                            style={{
                                backgroundImage: `url(${fftImages.ch2})`,
                                height: height,
                                backgroundSize: '100%',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                marginTop: -150
                            }}/>
                        }
                    </React.Fragment>
                    :
                    <Box display={'flex'} minHeight={400}>
                        <Box m="auto">
                            <Typography variant="h6" style={{color:'#999'}}>
                                뇌기능(EEG) 측정이 필요합니다.
                            </Typography>
                        </Box>
                    </Box>
                }
            </Grid>
        </Grid>
    )
}
