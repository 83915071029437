import Axios from 'axios';


export default class SurveyService {

    /**
     * 설문조사 리스트를 가져옵니다.
     * @param code
     * @param {*} callback
     */
    static async getSurveyByCode(code, callback){
        try{
            const result = await Axios.get('/api/survey?c=' + code)

            callback({
                success: true,
                data: result.data
            })

        }catch(e){
            //to do
            let message = ''
            switch(e.response.status){
                case 400:
                     message = '입력한 정보가 정확하지 않습니다.'
                     break
                case 404:
                    message = "설문조사 내용이 없습니다."
                    break;
                default:
                    message = '오류가 발생했습니다.'
            }

            callback({
                success: false,
                errorCode: e.response.status,
                message: message
            })
        }
    }
    
    static async getMySurvey(surveyType){
        let result = null
        
        try{
            const res = await Axios.get('/api/survey',{
                params:{
                    surveyType
                }
            })
            result = res.data
            result.surveyType = surveyType
        }catch(e){}
        
        return result
    }

    static async deleteSurvey(currentTab){
        let result = null
        try{
            await Axios.delete(`/api/surveys/answers/this-month/${currentTab}`)
        }catch(e){}

        return result
    }
    
    /**
     * 선택한 해당년도에 설문한 결과를 가져옵니다.
     * @param {*} year
     * @param surveyType
     */
    static async getMySurveyHistory(year, surveyType = 'emotion'){
        let result = null
        try{
            const res = await Axios.get('/api/surveys/history',{
                params: {
                    year,
                    surveyType
                }
            })
            result = res.data
        }catch(e){}
        
        return result
    }
    
    static async getSurveyRecent(surveyType, customerProfileID = null){
        let result = null
        try{
            const res = await Axios.get('/api/surveys/recent',{
                params: {
                    surveyType,
                    customerProfileID
                }
            })
            result = res.data
            result.surveyType = surveyType
        }catch(e){}

        return result
    }

    /**
     * 입력한 자각증상20을 저장합니다.
     * @param {*} survey 
     */
    static async saveSurvey(survey){
        try{
            await Axios.post('/api/survey',survey)
            return {
                success: true,
            }

        }catch(e){
            //to do
            let message = ''
            switch(e.response.status){
                case 400:
                     message = '입력한 정보가 정확하지 않습니다.'
                     break
                case 404:
                    message = "설문조사 내용이 없습니다."
                    break;
                default:
                    message = '오류가 발생했습니다.'
            }

            return{
                success: false,
                errorCode: e.response.status,
                message: message
            }
        }
    }


    /**
     * 관리자에서 사용할 자각증상 참여 사용자들 
     * @param {*} callback 
     */
    static async getSurveyParticipants(callback){
        try{
            const result = await Axios.get('/api/admin/surveys/participants',{
                params: {
                    page:1,
                    perPage: 100
                }
            })
            
            callback({
                success: true,
                data: result.data
            })

        }catch(e){
            //to do
            let message = ''
            switch(e.response.status){
                case 400:
                     message = '입력한 정보가 정확하지 않습니다.'
                     break
                default:
                    message = '오류가 발생했습니다.'
            }

            return{
                success: false,
                errorCode: e.response.status,
                message: message
            }
        }
    }


    /**
     * 선택한 사용자의 자각증상내용을 가져옵니다.
     * @param {*} year 
     * @param {*} profileID 
     * @param {*} callback 
     */
    static async getSurveyHistoryByProfile(year, profileID, callback){
        try{
            const result = await Axios.get('/api/admin/surveys/history/profileID/' + profileID,{
                params: {
                    year: year
                }
            })
            
            callback({
                success: true,
                data: result.data
            })

        }catch(e){
            //to do
            let message = ''
            switch(e.response.status){
                case 400:
                     message = '입력한 정보가 정확하지 않습니다.'
                     break
                default:
                    message = '오류가 발생했습니다.'
            }

            return{
                success: false,
                errorCode: e.response.status,
                message: message
            }
        }
    }



    /**
     * 사용자에 매핑된 자각증상을 가져옵니다.
     * @param {*} profileID 
     * @param {*} callback 
     */
    static async getSurveyByProfileID(profileID, callback){
        try{
            const result = await Axios.get('/api/admin/survey/profileID/' + profileID)
            
            callback({
                success: true,
                data: result.data
            })

        }catch(e){
            //to do
            let message = ''
            switch(e.response.status){
                case 400:
                     message = '입력한 정보가 정확하지 않습니다.'
                     break
                default:
                    message = '오류가 발생했습니다.'
            }

            return{
                success: false,
                errorCode: e.response.status,
                message: message
            }
        }        
    }

}
