import React, {forwardRef, useCallback, useEffect, useReducer} from "react";
import CssTextField from '@user-components/CssTextField';
import MemberService from '@user-service/MemberService'
import {Box} from "@material-ui/core";

const initState = {
    value: '',
    helpText: '',
    isError: false,
    isValidLoginID: false,
}

function reducer(state, action){
    switch (action.type){
        case 'UPDATE_VALUE':
            return {
                ...state,
                value: action.value,
                helpText: action.helpText,
                isError: action.isError,
                isValidLoginID: action.isValidLoginID
            }
        case 'UPDATE_LOGIN_ID':{
            return {
                ...state,
                isValidLoginID: action.isValid,
                helpText: !action.isValid ? '사용중인 아이디 입니다.' : '',
                isError: !action.isValid
            }
        }
        default:
    }
    return state
}

let timer
const UserIdTextField = forwardRef((props , ref) => {
    const minCharLength = 4

    const defaultState = {
        ...initState,
        value: props.defaultValue ? props.defaultValue.toLowerCase() : ''
    }
    
    const [state, dispatch] = useReducer(reducer, defaultState)
    const {value, isError , helpText, isValidLoginID } = state
    const { valid, defaultValue, placeholder } = props
    
    
    const checkLoginID = useCallback(async (loginID) => {
        if(minCharLength > loginID.length){
            return 
        }
        
        if(timer){
            clearTimeout(timer)
        }
        
        timer = setTimeout(async () => {
            const existID = await MemberService.checkUserId(loginID)
            dispatch({
                type: 'UPDATE_LOGIN_ID',
                isValid: !existID
            })
        }, 500)
            
    }, [])
    
    useEffect(() => {
        try {
            valid(isValidLoginID, value)
        } catch {}
 
        if(defaultValue){
            checkLoginID(value).then()
        }
    }, [isValidLoginID, value])
    
    
    return(
        <Box position={'relative'}>
            <CssTextField 
                 label="아이디"
                 inputRef={ref}
                 fullWidth
                 disabled={props.disabled}
                 defaultValue={defaultValue}
                 placeholder={placeholder}
                 onChange={ async (event) => {
                     dispatch({
                         type: 'UPDATE_VALUE',
                         value: event.target.value.toLowerCase(),
                         isError: false,
                         isValidLoginID: false,
                         helpText: ''
                     })

                     const loginID = event.target.value
                     if (props.checkLoginID) {
                         await checkLoginID(loginID)
                     }
    
                     if (props.change){
                         props.change(event.target.value.toLowerCase())
                     }
                     if (props.update) {
                         if (minCharLength < event.target.value.length) {
                             try {
                                 props.update(true)
                             } catch {}
                         } else {
                             props.update(false)
                         }
                     }
                     
                 }}
                 onKeyPress={(event) => {
                     if('Enter' === event.key){
                         if(minCharLength < event.target.value.length) {
                             try {
                                 props.next()
                             } catch {}
                         }
                     }
                 }}
                 inputProps={{
                     form : {
                         autocomplete: 'off',    
                     },
                     style: {textTransform: 'lowercase'}
                 }}
                 error={isError}
                 helperText={helpText}
            />
            {isValidLoginID &&
            <Box position={'absolute'} right={0} bottom={5}>
                <img src={require('@assets/images/checkIcon.png')} alt={'사용가능'}/>
            </Box>
            }
        </Box>
    )
})

export default UserIdTextField