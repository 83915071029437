import {Box, Card, CardActionArea, CardContent, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import PieChart from '@user-components/PieChart';
import { useHistory } from "react-router-dom";
import AuthProvider from "@user-provider/AuthProvider";

export default function BalanceBar(props) {
    const classes = useStyles();
    const history = useHistory()

    const ableClick = true !== props.clickDisabled
    const { id, bandRangeData, isEEG } = props.measureResult
    const labels = ['δ','θ','α','SMR','β', 'βM', 'βh']

    const handler = () => {
        if(AuthProvider.canViewMeasureDetail() && ableClick){
            history.push('/measureDetail/' + id + '/balanceBrain');
        }
    }

    return (
        <Box className={classes.root}>
            <Card className={classes.card} onClick={handler}>
                <CardActionArea>
                    <CardContent>
                        <Typography variant={'subtitle1'} className={classes.title}>
                            좌우뇌 균형상태
                        </Typography>
           
                            {'yes' === isEEG &&
                            <Box mt={6} mb={5}>
                                <Grid container justify={'center'}>
                                    {labels.map((label, index) => {
                                        const ch1 = bandRangeData.ch1[index];
                                        const ch2 = bandRangeData.ch2[index];
                                        const total = parseFloat(ch1) + parseFloat(ch2)
                                        const ch1Percent = 0 === total ? 50 : Math.floor(ch1 / total * 100)
                                        const ch2Percent = 0 === total ? 50 : Math.floor(ch2 / total * 100)

                                        return (
                                            <Grid item key={Math.random() * 100}>
                                                <PieChart
                                                    key={Math.random()}
                                                    defaultData={[ch2Percent, ch1Percent]}
                                                    labels={['', '']}
                                                    height={110}
                                                    width={80}
                                                    title={label}
                                                    xLabelDisplay={true}
                                                    useColorIndex={true}
                                                    colors={['#BEF3B0', '#FF00A5']}/>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Box>
                            }

                            {'yes' !== isEEG &&
                            <Box display={'flex'} minHeight={200}>
                                <Box m="auto">
                                    <Typography variant="h6" style={{color:'#999'}}>
                                        뇌기능(EEG) 측정이 필요합니다.
                                    </Typography>
                                </Box>
                            </Box>
                            }
                          
                    </CardContent>
                </CardActionArea>
            </Card>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
    },
    title: {
        fontWeight: 'bold',
        paddingLeft: 15,
        marginBottom: 20
    },
    card: {
        border: "none",
        boxShadow: "none",
        padding: 0
    },
}));

