import React, {useState, useEffect, useCallback} from 'react'

import ProfileService from '@user-service/ProfileService'
import ProfileBox from "./ProfileBox";
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography, Grid, Button } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from "clsx";
import CustomerSearchText from "../manage/customer/CustomerSearchText";
import BridgeProvider from "../../provider/BridgeProvider";
import AuthProvider from "../../provider/AuthProvider";
import {useHistory} from "react-router-dom";

const Profile = () => {
    const [isEditMode, setEditMode] = useState(false)
    const [profiles, setProfiles] = useState(null)
    const [keyword, setKeyword] = useState('')
    const classes = useStyles()
    const history = useHistory()
  
    const fetchProfile = useCallback(async () => {
        let profiles = await ProfileService.getProfiles(keyword)
        if('' !==  keyword){
            profiles = profiles.filter(p => !p.isNew)
        }
        
        setProfiles(profiles)
    }, [keyword])

    useEffect(() => {
        BridgeProvider.instance.closeLoading()
    }, [])
    
    useEffect(()=>{
        fetchProfile().then()
    },[fetchProfile])


    if(!profiles){
        return(<LoadingSkeleton/>)
    }

  
    if(AuthProvider.isProfileSelector()){
      history.replace('/profile-selector')
      return <></>
    }
    
    return (
        <Box className={classes.root}>
            <Box className={classes.background} />
            <Box display={'flex'} justifyContent={'center'} style={{position:'fixed', top:22, width: '100%'}}>
                <Grid container>
                    <Grid item xs={3}>
                        <Box className={classes.logo}>
                            <img src={"https://brhw.kr/images/logo.png"} alt={'브레인헬스 로고'}/>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display={'flex'} justifyContent={'center'}>
                            <Box style={{maxWidth: 500, width:'80%'}}>
                                <CustomerSearchText keyup={async (name) => {
                                    setKeyword(name)
                                }}/>    
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justify="flex-end" style={{paddingRight: 30}}>
                            <Button  className={clsx(classes.editButton,{
                                [classes.edit]: isEditMode,
                                [classes.normal]: !isEditMode
                            })} size="large"
                                     onClick={()=>{
                                         setEditMode(!isEditMode)
                                     }}>
                                {isEditMode ? '완료' : '프로필 관리'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            
            {/*<Box className={classes.add}>*/}
            {/*    */}
            {/*</Box>*/}
            <Box>
                <Typography variant="h5" component="h3" className={classes.title}>
                    {isEditMode 
                        ? '편집할 프로필을 선택하세요'
                        : '사용하실 프로필을 선택해 주세요'
                    }
                </Typography>
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
                <Grid container justify="center" spacing={3} className={classes.profileWrap}>
                    {profiles.map(profile => {
                        return (
                            <Grid key={profile.id} item>
                                <ProfileBox 
                                    {...profile}
                                    isEditMode={isEditMode}
                                />
                            </Grid>
                        )
                    })}

                    {((!profiles || 0 === profiles.length) && '' !== keyword) &&
                        <Grid item style={{color: 'white', zIndex:99}}>검색된 사용자가 없습니다.</Grid>
                    }
                </Grid>
            </Box>
            
        </Box>
    )
}


//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.background} />
            <div className="editprofile_wrap wrapper">
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </div>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        justify: "center",
        alignItems: "center",
        paddingTop: 100,
        paddingBottom: 100,
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
        backgroundColor: '#1E2030',
    },
    title: {
        color: 'white',
        textAlign: 'center',
    },
    add: {
        position: 'fixed',
        right: 30 ,
        top:30,
        zIndex: 0,
        color: 'white',
    },
    logo: {
        position: 'fixed',
        left: 30 ,
        // top: 30,
        zIndex: 0,
        width: `100%`,
    },
    profileWrap: {
        marginTop:20,
        width: '100%',
        maxWidth: 1200
    },
    edit: {
        backgroundColor: '#fff',
        color: '#333',
        '&:hover': {
            backgroundColor: '#CCCCCC'
        }
    },
    normal: {
        color: 'white',
        backgroundColor: '#1E2030',
        '&:hover': {
            backgroundColor: '#12131D'
        }
    },
    isNew: {
        backgroundColor: '#4B4D5A',
        '&:hover': {
            backgroundColor: '#2F303A',    
        }
    },
    card: {
        width: 184,
        height: 184,
        cursor: 'pointer',
        backgroundColor: '#fff',
        borderRadius: 5,
        position: 'relative',
        '& img':  {
            width: 184,
            height: 184
        },
        '& .profileName': {
            color: '#FFFEFE',
            fontSize: 18,
            fontWeight: 500,
            marginTop: 20,
            textAlign: 'center'
        },
        '& .profileName.new': {
            color: '#AEACB0',
            fontWeight: 'normal'
        },
        '& .wrap': {
            width: 184,
            height: 184,
        },
        '& .addIcon': {
            position: 'absolute',
            left: 'calc(50% - 36px)',
            top: '60px',
            fontSize: 70,
            color: '#fff',
            zIndex: 2
        }
    },
    editProfile: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: 184,
        height: 184,
        '& .background': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: 184,
            height: 184,
            backgroundColor: '#000',
            opacity: 0.5,
            zIndex: 1,
            '&:hover': {
                opacity: 0.7
            }
        },
        '& .editIcon' : {
            position: 'absolute',
            left: 'calc(50% - 40px)',
            top: '60px',
            fontSize: 70,
            color: '#fff',
            zIndex: 2
        }
    },
    square: {
        width: '100%',
        height: '100%'
    },
    editWrap: {
        marginTop: 170,  
    },
    editButton: {
        fontSize:  17,
        // fontSize:  20,
        // width: 220,
        // height: 70,
        border: 1,
        borderStyle: 'solid',
        borderColor: '#4B4D5A',
    },
    newProfile: {
        width: '100%',
        height: '100%',
        background:'#eee',
        color:'#999'
    },
    media: {
        height: 170,
    }
}));


export default Profile
