import Axios from 'axios';
import AuthProvider from "../provider/AuthProvider";
import DateHelper from "../helper/DateHelper";
// import { catchClause } from '@babel/types';

export default class ExerciseService {

    /**
     * 디바이스 접속 상태를 체크합니다. 
     */
    static async getExercises(type = 'all'){
        try {
            const result = await Axios.get(`/api/exercises/${type}`)
            
            return {
                success:true,
                errorCode:200,
                data: result.data,
                message: ''
            }

        }catch(e){}

        return {
            success: false,
            // errorCode: e.response.status,
        }
    }


    /**
     * 운동의 상세 정보를 가져옵니다.
     * @param {*} exerciseID 
     * @param {*} callback 
     */
    static async getExerciseDetail(exerciseID, callback){
        try {
            const result = await Axios.get('/api/exercise/' + exerciseID)
            
            callback({
                success:true,
                errorCode:200,
                data: result.data,
                message: ''
            })

        }catch(e){

            var message = ''
            switch(e.response.status){
                case 400:
                     message = '측정기 정보가 잘못되었습니다.'
                     break
                case 401:
                    message = "사용자 정보가 일치하지 않습니다."
                    break;
                case 402:
                    message = "해당 아이디는 허용되지 않습니다. 관리자에게 문의해주세요"
                    break;
                default:
                    message = '오류가 발생했습니다.'
            }

            callback({
                success: false,
                errorCode: e.response.status,
                message: message
            })
        }
    }


    /**
     * 운동 기록을 남깁니다.
     * @param {*} exerciseLog 
     */
    static async saveLog(exerciseLog){
        try {
            await Axios.post('/api/exercises/log', exerciseLog)
            return {
                success: true
            }
            
        }catch(e){

            var message = ''
            switch(e.response.status){
                case 400:
                     message = e.toString()
                     break
                default:
                    message = '오류가 발생했습니다.'
            }

            return {
                success: false,
                errorCode: e.response.status,
                message: message
            }
        }
    }

    static async setExerciseResult({measureHzType, measureLogID, bestWave, resultRate , resultVar, timeCount, isEnd = 'no'}){
        try {
            await Axios.put(`/api/exercise/${measureHzType}/result-rate`, {
                measureLogID,
                bestWave,
                resultRate,
                resultVar: resultVar,
                isEnd,
                timeCount
            })
        } catch(e){}
    }
    
    static async setResultVar({measureHzType, measureLogID, resultVar}){
        try {
            await Axios.put(`/api/exercise/${measureHzType}/result-var`, {
                measureLogID,
                resultVar,
            })
        } catch(e){}
    }

    static async setExerciseTimeCount({measureHzType, measureLogID, timeCount}){
        try {
            await Axios.put(`/api/exercise/${measureHzType}/result-time-count`, {
                measureLogID,
                timeCount
            })
        } catch(e){}
    }
    
    static async getLastExerciseLog({measureHzType, measureCode, createdAt}) {
        try {
            const res = await Axios.get(`/api/exercise/${measureHzType}/log/last`, {
                params: {
                    measureCode,
                    createdAt
                }
            })
            return res.data
        } catch(e){}
    }

    static async getExerciseDoneWeek({prescriptionID, profileID, startDate, endDate}) {
        try {
            const res = await Axios.get(`/api/exercise/week/done`, {
                params: {
                    prescriptionID,
                    profileID,
                    startDate,
                    endDate
                }
            })
            return res.data
        } catch(e){}
    }
    
    static getExerciseResultByScoreTable(scoreTable, currentScore){
        //생년월이 없을때 무조건 적합으로 처리
        // console.log('birthDate', AuthProvider.get('birthDate'))
        if(!AuthProvider.get('birthDate')) return true
        
        const currentYear = DateHelper.now('YYYY')
        const birthYear = DateHelper.format(AuthProvider.get('birthDate') , 'YYYY')
        
        const age = Number(currentYear) - Number(birthYear)
        let isPass = false
            
        scoreTable.map(table => {
            if(table.startScore <= currentScore && table.endScore >= currentScore) {
                if (age >= table.startAge) isPass = true
                if (table.startAge <= age && table.endAge >= age) isPass = true
            }
            return table
        })
        
        // console.log(birthYear, currentScore)
        // console.log(scoreTable)
        
        //스코어 테이블에 포함되지 않는다면 부적합 처리 
        return isPass
    }

  static async getExerciseChallengeResult(exerciseID){
    try {
      const res = await Axios.get(`/api/exercise/challenge/${exerciseID}/result`)
      return res.data || []
    } catch(e){}
  }
  /**
   * 도전 시작
   */
  static async startExerciseChallenge(profileID, exerciseID, challengeLevel){
    try {
      const res = await Axios.post(`/api/exercise/challenge/start`, {
        exerciseID,
        challengeLevel
      })
      return res.data
    } catch(e){}
  }

  static async updateExerciseChallenge(challengeID, scoreInfo ){
    
    try {
      const res = await Axios.put(`/api/exercise/challenge/${challengeID}/end`, {
        scoreInfo
      })
      return res.data
    } catch(e){}
  }
}
