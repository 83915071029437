import React, {useState, useCallback, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'; 
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import {IconButton, ListItemSecondaryAction} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SurveyService from '@user-service/SurveyService'
import Slider from "@material-ui/core/Slider";
import DateHelper from '@helper/DateHelper'

import CustomerService from "@user-service/manage/CustomerService";
import LoadingIndicator from "../../../components/LoadingIndicator";
import nl2br from "react-nl2br";
import EditIcon from "@material-ui/icons/Edit";

const CustomSlider = withStyles({
    root: {
        color: '#00D48F',
        height: 9,
        padding: '5px 0'
    },
    thumb: {
        height: 0,
        width: 0,
        display: 'none'
    },
    active: {},
    valueLabel: {
        display: 'none'
    },
    disabled: {
        color: '#00D48F',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 1,
        position: 'absolute',
        top: 'calc(50% - 1px)',
        backgroundColor: '#8799AD'
    },
})(Slider);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    button: {
        backgroundColor: '#014FB4',
        color: 'white',
        
        '&:hover': {
            backgroundColor: '#052D84'
        }
    },
    deleteNote: {
        color: 'red',
    }
}));

const StyledRating = withStyles({
    iconFilled: {
        color: '#ff6d75',
    },
    iconHover: {
        color: '#ff3d47',
    },
})(Rating);


export function WriteForm(props){
    const classes = useStyles();
    const { profileID, currentNote, onChange } = props 
    
    const [rating, setRating] = React.useState(currentNote ? currentNote.rating : 5);
    const [hover, setHover] = React.useState(-1);
    const [note, setNote] = React.useState(currentNote ? currentNote.note : '')
    const [survey , setSurvey] = useState({
        emotion: [],
        health: []
    })
    const fetch = useCallback( async () => {
        let emotion = await SurveyService.getSurveyRecent('emotion', profileID)
        emotion.result.sort((a,b) => {
            return b.level - a.level
        })

        const health = await SurveyService.getSurveyRecent('health', profileID)
        health.result.sort((a,b) => {
            return b.level - a.level
        })
        
        setSurvey({
            emotion: emotion.result.slice(0,5),
            health: health.result.slice(0,5),
        })
    }, [])
    
    useEffect(() => {
        fetch().then()
    }, [fetch])
    
    useEffect(() => {
        
    },[props.open])

    const save = async () => {
        const params = {
            note,
            rating,
            health: JSON.stringify(health) ,
            emotion: JSON.stringify(emotion),
            prescriptionID: props.prescriptionID,
            noteID: currentNote ? currentNote.id : undefined
        }
        
        await CustomerService.saveMeasureNote(profileID, params)
        props.fetch()
    }
    const deleteNote = async () => {
        if(window.confirm('삭제하시겠습니까?')){
            await CustomerService.deleteMeasureNote(currentNote.id)    
            if(onChange) onChange()
        }
    }
    
    const { emotion, health } = survey
    if(0 === emotion.length) return <></>
    return (
        <Box p={2} mt={2} mb={5}>
            <Box mb={3}>
                <Grid container spacing={1} style={{marginBottom: 20}}>
                    <Grid item xs={6}>
                        <Box style={{backgroundColor: '#0156b8', color: '#fff', textAlign: 'center', padding: '10px 0'}} mb={3}>
                            <Typography>감정측정 결과</Typography>
                        </Box>
                        <Grid container spacing={2}
                              justify={'center'}
                              alignItems={'center'}
                              style={{width: '100%'}} >
                            {emotion.map((row, index) => (
                                <React.Fragment>
                                    <Grid item key={row.order} xs={8}>
                                        <Typography style={{width: '100%', fontSize: 16 }}>
                                            {index + 1}. {row.content} (<b>{row.level}%</b>)
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomSlider
                                            key={Math.random()}
                                            disabled
                                            defaultValue={row.level}
                                            style={{color: '#00D48F'}}/>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Box style={{backgroundColor: '#0156b8', color: '#fff', textAlign: 'center', padding: '10px 0'}} mb={3}>
                            <Typography>운동측정 결과</Typography>
                        </Box>
                        <Grid container spacing={2}
                              justify={'center'}
                              alignItems={'center'}
                              style={{width: '100%'}} >
                            {health.map((row, index) => (
                                <React.Fragment>
                                    <Grid item key={row.order} xs={8}>
                                        <Typography style={{width: '100%', fontSize: 16 }}>
                                            {index + 1}. {row.content} (<b>{row.level}%</b>)
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomSlider
                                            key={Math.random()}
                                            disabled
                                            defaultValue={row.level}
                                            style={{color: '#00D48F'}}/>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <TextField
                id="outlined-multiline-static"
                defaultValue={currentNote ? currentNote.note : ''}
                label="상담 내용을 입력하세요"
                multiline
                rows={5}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                    setNote(event.target.value)
                }}
            />
            <Grid container>
                <Grid item xs={6}>
                    <StyledRating
                        style={{paddingTop:5}}
                        name="customized-color"
                        defaultValue={currentNote ? currentNote.rating : 5}
                        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                        max={10}
                        onChange={(event, newValue) => {
                            setRating(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                            setHover(newHover);
                        }}
                        icon={<FavoriteIcon fontSize="inherit" />}
                    />
                    {!rating && <Box>평가 점수: {hover !== -1 ? hover : rating}점</Box>}
                </Grid>
                <Grid item xs={6}>
                    <Box display={'flex'} justifyContent={'flex-end'} mt={1}>
                        <Button onClick={() => {props.setOpenWriteForm(false)}}>취소</Button>
                        {currentNote &&
                        <Button onClick={deleteNote} className={classes.deleteNote}>삭제</Button>
                        }
                        <Button onClick={save} className={classes.button} variant={'contained'}>저장</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}


export default function CustomerBoard(props) {
    const classes = useStyles();
    const [openWriteForm, setOpenWriteForm] = useState(false)
    const [currentNote, setCurrentNote] = useState(null)
    const [notes, setNotes] = useState()
    
    const closeWithFetch = () => {
        setOpenWriteForm(false)
        fetch().then()
    }
    const fetch = useCallback( async() => {
        if(!props.prescriptionID) return 
        
        const notes = await CustomerService.getMeasureNote(props.prescriptionID)
        setTimeout(() => {setNotes(notes)}, 600)
        
    },[props.prescriptionID])

    useEffect(() => {
        fetch().then()
    },[fetch])
    
    
    if(!notes) return <LoadingIndicator/>
    return (
        <React.Fragment>
            {!openWriteForm &&
            <Box display="flex" justifyContent={'flex-end'}>
                <IconButton
                    className={classes.button}
                    onClick={() => {
                        setOpenWriteForm(!openWriteForm)
                    }}
                >
                    <AddIcon/>
                </IconButton>
            </Box>
            }
            {openWriteForm &&
            <WriteForm 
                prescriptionID={props.prescriptionID} 
                profileID={props.profileID}
                open={openWriteForm}
                setOpenWriteForm={setOpenWriteForm}
                fetch={closeWithFetch}
                currentNote={currentNote}
                onChange={() => {
                    setCurrentNote(null)
                    setOpenWriteForm(false)
                    fetch().then()
                }}
            />
            }
            <List className={classes.root}>
                {notes.map((note, index) => {
                    return (
                        <React.Fragment key={index}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp"/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${DateHelper.format(note.createDate, 'YYYY.MM.DD')} 상담내용`}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                상담사
                                            </Typography>
                                            {" — "}
                                            {nl2br(note.note)}
                                            <Grid container>
                                                <Grid item xs={11}>
                                                    <StyledRating
                                                        key={Math.random() * 10}
                                                        style={{paddingTop:5}}
                                                        name="customized-color"
                                                        defaultValue={note.rating}
                                                        disabled
                                                        max={10}
                                                        icon={<FavoriteIcon fontSize="inherit" />}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    {/*<BarChart*/}
                                                    {/*    defaultData={[90,80,70,60,50]}*/}
                                                    {/*    showTopValue={false}*/}
                                                    {/*    suggestedMax={100}*/}
                                                    {/*    labels={[,,,,,]}*/}
                                                    {/*    spacing={0.96}*/}
                                                    {/*    typeFlag={'emotion'}*/}
                                                    {/*    useColorMap={ColorHelper.getSurveyColorWithBind()}*/}
                                                    {/*/>*/}
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="comments">
                                        <EditIcon onClick={() => {
                                            setCurrentNote(note)
                                            setOpenWriteForm(true)
                                        }}/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </React.Fragment>
                    )
                })}


                {0 === notes.length &&
                    <Box p={22} display={'flex'} justifyContent={'center'}>
                        등록된 내용이 없습니다.
                    </Box>    
                }
            </List>
        </React.Fragment>
    );
}