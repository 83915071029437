import Axios from 'axios';
// import { catchClause } from '@babel/types';


export default class MemberService {

    /**
     * Join member
     * @returns {Promise<void>}
     */
    static async join(data) {
        const { birthDate, cellNumber, knowFrom, loginID, password, certNumber, profileName } = data
        const result = {
            success: false,
            message: ''
        }
        
        try {
            const res = await Axios.post('/api/member', {
                termConditions: 'yes',
                privacyConfirm: 'yes',
                usageGuide: 'yes',
                birthDate,
                cellNumber,
                certNumber,
                knowFrom,
                loginID,
                password,
                profileName,
                passwordRepeat: password
            });
            
            if(201 === res.status){
                result.success = true
            }
            
        } catch(e) {
            switch(e.response.data){
                case 'ERR_CELLPHONE_ALREADY_EXIST':
                    result.message = '이미 등록된 전화번호입니다.'
                    break
                default:
                    break
            }
        }
       
        return result
    }
    
    static async joinWithDeviceRegister(data){
        const {loginID, password, deviceSerial, computerMac } = data
        
        const res = await this.join(data)
        if(!res.success){
            alert(res.message)
            return
        }
        
        const result = await this.login({
            loginID,
            password,
            deviceID: null,
        })
        
        await this.registerDevice({
            memberID: result.data.memberID,
            deviceSerial,
            computerMac,
        });
        
        return {
            success: true
        }
    }

    static async checkUserId(loginID){
        let isValid = false
        try {
            const params = { loginID }
            const result = await Axios.post('/api/checkUserId', params)
            isValid = result.data.success

        }catch(e){
        }

        return isValid
    }
    
    static async checkLoginIDCellPhone(loginID, cellPhone){
        let isValid = false
        try {
            const params = { loginID, cellPhone }
            const result = await Axios.post('/api/checkLoginIDCellPhone', params)
            isValid = result.data.success

        }catch(e){
        }

        return isValid
    }

    static async updatePassword(loginID, newPassword, cellNumber, certNumber){
        let isValid = false
        try {
            const params = { loginID, newPassword, cellNumber, certNumber }
            await Axios.put('/api/updatePassword', params)
            isValid = true
            
        }catch(e){
        }

        return isValid
    }
    static async login(login){
        
        try {
            const result = await Axios.post('/api/login',login)
            
            return {
                success:true,
                errorCode:200,
                data: result.data,
                message: ''
            }

        }catch(e){
            let message
            switch(e.response.status){
                case 400:
                     message = '측정기 정보가 잘못되었습니다.'
                     break
                case 401:
                    message = "사용자 정보가 일치하지 않습니다."
                    break;
                case 402:
                    message = "해당 아이디는 허용되지 않습니다. 관리자에게 문의해주세요"
                    break;
                default:
                    message = '오류가 발생했습니다.'
            }

            return {
                success: false,
                errorCode: e.response.status,
                message: message
            }
        }
        
    }

    static async loginByToken(token){

        try {
            const result = await Axios.post('/api/enc',{t: token})

            return {
                success:true,
                errorCode:200,
                data: result.data,
                message: ''
            }

        }catch(e){
            let message
            switch(e.response.status){
                case 400:
                    message = '측정기 정보가 잘못되었습니다.'
                    break
                case 401:
                    message = "사용자 정보가 일치하지 않습니다."
                    break;
                case 402:
                    message = "해당 아이디는 허용되지 않습니다. 관리자에게 문의해주세요"
                    break;
                default:
                    message = '오류가 발생했습니다.'
            }

            return {
                success: false,
                errorCode: e.response.status,
                message: message
            }
        }

    }

    /**
     * 로그인 아이디 체크 
     * 204 : 아이디 존재함 > 사용 불가능한 아이디 
     * 404 : 아이디 없음 > 사용가능한 아이디
     */
    static async exsistLoginID(loginID) {
        try{
            const result = await Axios.get('/api/members/loginID/' + loginID)

            //존재하는 아이디
            if(204 === result.status) return true
        }catch(e){
            //사용가능
            if( 404 === e.response.status){
                return false
            }
        }
        return true
    }
    
    static async sendSms(smsNumber, withoutCheck = 'no'){
        const result = {
            success : false,
            message : ''
        }
        
        try {
            const res = await Axios.get(`/api/members/cellphone/82-${smsNumber}?withoutCheck=${withoutCheck}`)
            
            if(204 === res.status) {
                result.success = false
                result.message = '이미 가입된 핸드폰 번호입니다.'
            } else {
                result.success = true
            }
        }catch(e){
            console.log('error', e.response.status);
        }
        return result
    }
 
    static async verifyCertNumber(cellNumber, certNumber){
        const result = {
            success: false,
            message : ''
        }
        
        try {
            //정상 인증
            const res = await Axios.post('/api/members/cellphone/82-' + cellNumber,{
                certNumber
            })
            if(204 === res.status) {
                result.success = true
                result.message = '인증이 완료되었습니다.'
            }
            
        }catch(e){
            switch(e.response.data){
                case 'ERR_CERT_NUMBER_NOT_MATCH':
                    result.message = '인증번호가 맞지 않습니다.'
                    break
                case 'ERR_ALREADY_CONFIRMED':
                    result.message = '인증번호가 만료되었습니다.'
                    break
                default:
            }
        }

        return result
    }

    /**
     * 회원가입 처리를 합니다.
     * @param param
     * @param {*} completeHandler
     */
    static async registMember(param, completeHandler){

        const year = param.birthDate.substr(0, 4)
        const month = param.birthDate.substr(4,2)
        const day = param.birthDate.substr(-2)

        const formatBirthDate = year + '-' + month + '-' + day
    

        const member = {

            termConditions: "yes",
            privacyConfirm: "yes",
            usageGuide: "yes",
            loginID: param.loginID,
            password: param.password,
            passwordRepeat: param.password,
            name: param.memberName,
            // regionNumber: "82",
            // cellphone: param.cellPhone,
            // certNumber: param.certNumber,
            gender: param.gender,
            birthDate: formatBirthDate,
            email: -1 === param.email.indexOf('@') 
                    ? `${param.email}@${param.emailHost}`
                    : param.email

        }

        try{
            const result = await Axios.post('/api/member', member)

            //정상 가입
            if(201 === result.status) {
                // if(completeHandler) completeHandler(true)
                return true
            }
        
        }catch(e){
        }

        return false
    }

    /**
     * 회원가입 처리를 합니다.
     * @param param
     * @param {*} completeHandler
     */
    static async registerDevice(param, completeHandler){
        const result = {
            success: false,
        }
        
        try{
            await Axios.put(`/api/device/register/${param.deviceSerial}`, param)
            result.success = true
        }catch{}
        
        return result
    }
    

}
