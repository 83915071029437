import React, {useCallback, useEffect, useRef, useState} from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import SocketProvider from '@user-provider/SocketProvider'

import TimeCount from '@user-pages/exercise/common/TimeCount'
import CodeButtonGroup from '@user-pages/exercise/feedback/CodeButtonGroup'
import ComputeFeedbackDialog from '@user-pages/exercise/common/ComputeFeedbackDialog'
import MonitorDialog from '@user-components/MonitorDialog';
import {IconButton, Select} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import clsx from "clsx";

import ExerciseService from '@user-service/ExerciseService';
import AuthProvider from "../../provider/AuthProvider";
import SettingsIcon from '@material-ui/icons/Settings';
import PauseIcon from '@material-ui/icons/Pause';
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


import ExerciseStartDialog from "@user-pages/exercise/common/ExerciseStartDialog";
import queryString from "query-string";
import ExerciseSaveDialog from "./ExerciseSaveDialog";

function HitCount(props) {
    const classes = useStyles()
    const [state, setState] = useState({
        count: 0, 
        showCount:0,
        maxCount: 0,
        showMaxCount:0,
        termCount: 0,
    })
    
    const [hitCountText] = useState(props.hitCountText)
    const [hitCountName] = useState(props.hitCountName)
    let stateRef = useRef(state)
    
    const { hideHit } = props
    let onMeasureHit = (isHit) => {
        if (isHit) {
            stateRef.current.count += 1
            stateRef.current.termCount += 1
            stateRef.current.showCount = stateRef.current.count
            
            setState({
                ...stateRef.current
            })
        } else {
            const {maxCount, termCount} = stateRef.current
            if (maxCount < termCount) {
                stateRef.current.maxCount = termCount
                stateRef.current.showMaxCount = stateRef.current.maxCount 
            }
            stateRef.current.termCount = 0

            setState({
                ...stateRef.current
            })
        }
    }
    
    useEffect(() => {
        SocketProvider.onMeasureHit = onMeasureHit
        return () => {
            SocketProvider.onMeasureHit = null
        }
    },[])
    
    useEffect(() => {
        if(props.isStart){
            
            stateRef.current.count = 0
            stateRef.current.showCount = 0
            stateRef.current.maxCount = 0
            stateRef.current.showMaxCount = 0
            stateRef.current.termCount = 0
            
            setState({
                ...stateRef.current
            })
        }
    }, [props.isStart])

    if(props.onUpdate){
        props.onUpdate(state)
    }
    
    return (
        <React.Fragment>
            {true !== hideHit &&
            <React.Fragment>
                <Grid item>
                    <div className={classes.modeText} style={{color: 'white', paddingTop: 3}}>
                        Best Wave
                    </div>
                </Grid>
                <Grid item style={{paddingLeft: 10, paddingRight: 10}}>
                    <div className={classes.modeText} style={{paddingTop:4, fontWeight: 'bold'}}>
                        {state.showMaxCount}{hitCountText}
                    </div>
                </Grid>
                <Grid item>
                    <span className={classes.modeText} style={{color: 'white'}}>
                        {hitCountName}
                    </span>
                </Grid>
                <Grid item style={{paddingLeft: 10}}>
                    <div className={classes.modeText} style={{paddingTop:4, fontWeight: 'bold'}}>
                        {state.showCount}{hitCountText}
                    </div>
                </Grid>
            </React.Fragment>
            }
        </React.Fragment>
    )
}

export default function ExerciseStatusBar (props) {
    const classes = useStyles()
    const history = useHistory()
    
    const params = queryString.parse(window.location.search)
    const [prescriptionExerciseID] = useState(params.prescriptionExerciseID)    

    
    const { measureHzType, measureType, measureCode, hideHit, hideIntervalTime, disableMeasureType, checkBoxLabel} = props
    const timeCountRef = useRef()
    const settingDialogRef = useRef()
    const codeGroupRef = React.createRef()
    
    const [maxIntervalTime] = useState(new Array(120).fill(0))
    
    const [measureSetting, setMeasureSetting] = useState({
        currentMeasureType: measureType,
        currentMeasureCode: measureCode,
        intervalTime: 5
    })

    const [hitCountText] = useState(props.hitCountText ? props.hitCountText : 'W')
    const [hitCountName] = useState(props.hitCountName ? props.hitCountName : '최적화율')
    
    const [openMonitor, setOpenMonitor] = useState(false)
    const [useTimeCountInterval] = useState(props.useTimeCountInterval ? props.useTimeCountInterval : false)
    const [useNarration, setUseNarration] = useState(props.useNarration)
    
    
    const [state , setState] = useState({
        isPlaying: false,
        isStart: false,
        isSavingWithExit: false
    })
    const stateRef = useRef(state)
    
    let lastHitCountScore
    const {intervalTime, currentMeasureType, currentMeasureCode} = measureSetting
    const {isPlaying, isStart, isSavingWithExit} = state
    const fetch = useCallback(async () => {
    },[])

    const setIntervalTime = (intervalTime) => { setMeasureSetting({...measureSetting, intervalTime }) }
    
    const goBack = () => {
        if(prescriptionExerciseID) {
            document.location.href = '/dashboard'
        // } else if('measureBand' === measureHzType){
        //     document.location.href = '/brainhealth'
        } else {
            history.goBack()
        }
    }
    const handleClose = async () => {
        if(AuthProvider.isCustomerView()){
            goBack()
            return
        }
        
 
        if(!isStart){
            goBack()    
        } else {
            stateRef.current.isSavingWithExit = true
            setState({
                ...stateRef.current
            })
            
            setTimeout(() => {
                if(props.close){
                    props.close()
                }    
            }, 200)
        }
    }
    
    const handleStart = async () => {
        
        if( 0 === SocketProvider.currentTime){
            if(props.start){
                props.start(intervalTime * 60, currentMeasureType, currentMeasureCode)

                if(useTimeCountInterval){
                    timeCountRef.current.resetInterval()
                    timeCountRef.current.startWithInterval()
                }
            }    
        } else {
            const devicePaused = await SocketProvider.measurePauseToggle()      
            if(props.pause){
                props.pause(devicePaused)

                if(useTimeCountInterval){
                    timeCountRef.current.togglePauseInterval()
                }
            }
        }
    }
    const handleStartAuto = async (intervalTime, measureType, measureCode) => {
        if(props.start){
            props.start(intervalTime * 60, measureType, measureCode)

            if(useTimeCountInterval){
                timeCountRef.current.resetInterval()
                timeCountRef.current.startWithInterval()
            }
        }
    }
    
    const saveExercisesResult = async () => {
        const measureLogID = SocketProvider.getMeasureLogID()
        
        if(!AuthProvider.isCustomerView()){
            if(props.onResultVar){
                await props.onResultVar({
                    measureLogID,
                    measureHzType,
                })
            }
        }
        
        try {
            let bestWave = lastHitCountScore.showMaxCount
            if(0 === bestWave && 0 < lastHitCountScore.showCount){
                bestWave = lastHitCountScore.showCount
            }
            
            await ExerciseService.setExerciseResult({
                measureHzType,
                measureLogID,
                bestWave: bestWave,
                resultRate: lastHitCountScore.showCount,
                timeCount: timeCountRef.current.getTime(useTimeCountInterval)
            })
        } catch(e) {}
        
    }
    
    useEffect(() => {
        fetch().then()
            if(false === useTimeCountInterval) {
                SocketProvider.onMeasureTime = (time) => {
                    timeCountRef.current.update(time)
                }
            }
            
            SocketProvider.onMeasureStartStatusBar = async () => {
                setState({
                    ...state,
                    isPlaying: true,
                    isStart: true,
                })
            }
        
            SocketProvider.onMeasureStopStatusBar = async () => {
                setState({
                    ...state,
                    isPlaying: false,
                    isStart: false,
                })
                
                await saveExercisesResult()
                
                setTimeout(() => {
                    if(stateRef.current.isSavingWithExit){
                        goBack()
                    }
                }, 1300)
            }
            
            if(props.onInitAfter){
                setTimeout(() => {
                    props.onInitAfter({
                        timeCountRef
                    })
                }, 1000)
            }
        return () => {
            SocketProvider.onMeasureTime = null
            SocketProvider.onMeasureStopStatusBar = null
        }
    }, [fetch])
    
    const innerBoxWidth = window.innerWidth - 200
    const checkBoxLabelText = checkBoxLabel ? checkBoxLabel : '효과음만 사용'
    
    return (
        <Box className={classes.root}>
            <ExerciseSaveDialog open={isSavingWithExit} close={goBack.bind(this)}/>
            
            <ExerciseStartDialog
                onUpdate={async  ({measureCode, measureType, exerciseCount}) => {
                    codeGroupRef.current.setMeasureSetting(measureType, measureCode)
                    setMeasureSetting({
                        ...measureSetting,
                        currentMeasureType: measureType,
                        currentMeasureCode: measureCode,
                        intervalTime: exerciseCount
                    })
                }}
                onStart={({measureCode, measureType, exerciseCount}) => {
                    setTimeout(() => {
                        handleStartAuto(exerciseCount, measureType, measureCode).then()
                    }, 500)
                }}
                hzType={measureHzType}
            />
            
            <Box className={classes.wrap}>
                <Grid container style={{width: '100%'}} >
                    <Grid item style={{margin: 'auto'}}>
                        <IconButton 
                            className={classes.icon}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid item style={{width: innerBoxWidth, position: 'relative'}}>
                        <Box className={classes.background}/>
                        <Box
                            display="flex"
                            justifyContent="flex-start"
                            position="absolute"
                            left={20}
                            top={5}
                            zIndex={99}
                        >
                            <Grid container>
                                <Grid item>
                                    <span className={classes.modeText} style={{color: 'white'}}>
                                        최적화 코드: 
                                    </span>
                                </Grid>
                                <Grid item style={{paddingLeft: 10}}>
                                    <CodeButtonGroup
                                        ref={codeGroupRef}
                                        measureTypeStyle={classes.modeText}
                                        measurCodeStyle={classes.modeText}
                                        hzType={measureHzType}
                                        defaultMeasureType={measureType}
                                        defaultCode={measureCode}
                                        useDialogOpen={false}
                                        disabled={disableMeasureType}
                                        onMeasureSetting={(measureType, measureCode) => {
                                            setMeasureSetting({
                                                ...measureSetting,
                                                currentMeasureType: measureType,
                                                currentMeasureCode: measureCode,
                                            })
                                        }}
                                    />
                                </Grid>
                                <Grid item style={{paddingLeft: 10, fontWeight: 'bold'}}>
                                    <Select
                                        defaultValue={intervalTime}
                                        value={intervalTime}
                                        className={clsx(classes.select, {
                                            [classes.hide]: hideIntervalTime
                                        })}
                                        onChange={(event) => {
                                            setIntervalTime(event.target.value)
                                        }}>
                                        {maxIntervalTime.map((value, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={index + 1}
                                                >
                                                    {index + 1}분
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>
                                {props.onNarration &&
                                <Grid item>
                                    <FormControlLabel
                                        color={'white'}
                                        style={{marginTop: -3}}
                                        control={<Checkbox
                                            checked={!useNarration}
                                            style={{
                                                color: "#fff",
                                            }}
                                            onChange={(event) => {
                                                props.onNarration(event.target.checked)
                                                setUseNarration(!event.target.checked)
                                            }}
                                            name=""/>}
                                        label={<span style={{color: 'white'}}>{checkBoxLabelText}</span>}
                                    />
                                </Grid>
                                }
                            </Grid>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            position="absolute"
                            left={"calc(50% - 60px)"}
                            top={5}
                            zIndex={3}
                            style={{color: 'white', fontSize: 30, fontWeight: 'bold'}}>
                                <TimeCount ref={timeCountRef} />
                        </Box>
                        <Box
                            display={'flex'}
                            justifyContent="flex-end"
                            position="absolute"
                            right={20}
                            top={5}
                            zIndex={3}>
                            <Grid container>
                                <HitCount 
                                    isStart={isStart} 
                                    hideHit={hideHit} 
                                    measureHzType={measureHzType} 
                                    hitCountText={hitCountText}
                                    hitCountName={hitCountName}
                                    onUpdate={(score) => {
                                        lastHitCountScore = score 
                                    }}
                                />
                                {AuthProvider.isSuperAdmin() &&
                                    <React.Fragment>
                                        
                                        <Grid item style={{marginTop: -5, marginRight: -10, paddingLeft: 10}}>
                                            <IconButton
                                                className={clsx(classes.iconSetting)}
                                                onClick={() => {
                                                    setOpenMonitor(!openMonitor)
                                                }}
                                            >
                                                <DesktopWindowsIcon/>
                                            </IconButton>
                                        </Grid>
                                        <Grid item style={{marginTop: -5, marginRight: -10, paddingLeft: 10}}>
                                            <IconButton
                                                className={clsx(classes.iconSetting)}
                                                onClick={() => {
                                                    settingDialogRef.current.open(0)
                                                }}
                                            >
                                                <SettingsIcon/>
                                            </IconButton>
                                        </Grid>
                                    </React.Fragment>
                                
                                }
                            </Grid>
                        </Box>
                        {AuthProvider.isSuperAdmin() && 
                        <ComputeFeedbackDialog
                            ref={settingDialogRef}
                            hzType={measureHzType}
                            measureType={measureType}
                        />
                        }
                        {AuthProvider.isSuperAdmin() &&
                        <MonitorDialog
                            open={openMonitor}
                            hzType={measureHzType}
                            isPlaying={isPlaying}
                            measureType={currentMeasureType}
                            measureCode={currentMeasureCode}
                            onTogglePlay={() => {
                                handleStart().then()
                            }}
                            onClose={() => {
                                setOpenMonitor(false)
                            }}
                        />
                        }
                    </Grid>
                    <Grid item style={{margin: 'auto'}}>
                        <IconButton 
                            disabled={AuthProvider.isCustomerView()}
                            className={clsx(classes.icon, classes.iconRed)}
                            onClick={handleStart}
                        >
                            {isPlaying ? <PauseIcon/> : <PlayArrowIcon/> } 
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}


const statusBarHeight = 50
const buttonWidth = 50

const useStyles = makeStyles(() => ({
    root: {
        height: statusBarHeight,
        width: '100%',
        left: 0,
        top: 10,
        position: 'absolute',
        zIndex: 99
    },
    hide: {
        display: 'none'
    },
    modeText: {
        color: '#FFC004', fontSize: 24,
    },
    timeText: {
        color: 'white', fontSize: 28, fontWeight: 'bold'
    },
    wrap: {
        position: 'relative'
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: statusBarHeight,
        left: 0,
        top: 0,
        backgroundColor: 'rgba(0,0,0,0.6)',
        zIndex: 2,
        borderRadius: 35
    },
    iconSetting: {
        height: statusBarHeight,
        width: buttonWidth,
        color: '#fff',
        '& .MuiSvgIcon-root': {
            fontSize: 30
        }
    },
    icon : {
        height: statusBarHeight,
        width: buttonWidth,
        color: '#fff',

        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.7)',
        },
        backgroundColor: 'rgba(0,0,0,0.6)',
        '& .MuiSvgIcon-root': {
            fontSize: 40    
        }
    },
    iconRed: {
        backgroundColor: '#FF0005',
        '&:hover': {
            backgroundColor: '#C90009',
        }
      
    },
    select: {
        color: '#FFC004',
        fontSize: 24,
        fontWeight: 'bold',

        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
        '& .MuiSelect-icon': {
            color: '#FFC004',
        }
    },
}));


