import React, {useEffect, useState, useCallback} from "react";
import {makeStyles, withStyles} from '@material-ui/core/styles';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import {Grid, Typography} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MeasureTestLogs from "./MeasrueTestLogs";
import FilterSelect from "./FilterSelect";
import ExerciseService from "../../../services/ExerciseService";
import MeasureTestLineChart from "./MeasureTestLineChart";
import {setExtra} from "@sentry/react";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const MeasureTestHistoryDialog = (props) => {
    const { onClose, open } = props
    const [ exercises, setExercises ] = useState([])
    const [ currentFilter, setCurrentFilter ] = useState([])
    
    const handleClose = () => {
        
        if(onClose){
            onClose()
        }
    }
    const handleFilter = (program) => {
        if(!program || 'all' === program.programID){
            setCurrentFilter(exercises)
        } else {
            setCurrentFilter([program])
        }
    }
    // const fetch = useCallback( async () => {
    //     const result = await ExerciseService.getExercises('test')
    //     const res = result.success ? result.data : []
    //     seExercises(res)
    //     setCurrentFilter(res)
    //    
    // }, [seExercises, setCurrentFilter])
    //
    useEffect(() => {
        setExercises(props.exercise)
        setCurrentFilter(props.exercise)
        // console.log(props.exercise)
    }, [props.exercise])
    
    // useEffect(()=>{
        // fetch().then()
    // },[fetch])

    // console.log(props)
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle onClose={handleClose} >
                <Grid container spacing={2}>
                    <Grid item style={{paddingLeft: 30}}>
                        <FilterSelect filterTypes={exercises} onChange={(program) => handleFilter(program)} />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{height: '100vh' }}>
                <MeasureTestLineChart currentFilter={currentFilter}/>
                <MeasureTestLogs hideHeader currentFilter={currentFilter}/>
            </DialogContent>
        </Dialog>
    );
}



export default MeasureTestHistoryDialog