import {Box, Grid, Typography} from "@material-ui/core";
import React from "react";

import BarChart from '@user-components/BarChart';
import PieChart from '@user-components/PieChart';

export default function ResultBarPie(props) {
    const labels = props.labels ? props.labels : ['좌뇌', '우뇌']
    const pieLabelPosition = props.pieLabelPosition ? props.pieLabelPosition : ''
    
    const pieData = JSON.parse(JSON.stringify(props.data))
    const pieLabels = JSON.parse(JSON.stringify(labels))
    
    const barChartColor = props.barChartColor ? props.barChartColor :  ['#66CDD9', '#F25C05'];
    const pieChartColor = props.pieChartColor ? props.pieChartColor :  ['#BEF3B0', '#FF00A5'];
    
    const useEachPieChart = true === props.useEachPieChart
    const pieChartList = []
    
    const lineChartXs = props.lineChartXs ? props.lineChartXs : 9
    
    if(useEachPieChart){
        pieData.map((data, index) => {
            
            pieChartList.push(
                <Grid item xs={props.pieChartXs} key={index}>
                    <PieChart
                        defaultData={[data, 100 - data]}
                        height={100}
                        width={100}
                        labels={[pieLabels[index]]}
                        xLabelDisplay={true}
                        useColorIndex={true}
                        fontSize={props.fontSize}
                        legendPosition={pieLabelPosition}
                        colors={[pieChartColor[index], '#efefef']}
                    />
                </Grid>
            )
            return data
        })
    } else {
        pieChartList.push(
            <Grid item xs={3} key={1}>
                <PieChart
                    defaultData={pieData.reverse()}
                    height={60}
                    width={90}
                    fontSize={props.fontSize}
                    labels={pieLabels.reverse()}
                    xLabelDisplay={true}
                    useColorIndex={true}
                    legendPosition={pieLabelPosition}
                    colors={pieChartColor.reverse()}
                />
            </Grid>
        )
    }
    return (
        <Box>
            <Typography variant={"subtitle1"} style={{fontSize: props.titleFontSize ? props.titleFontSize :  props.fontSize }}>
                {props.title}
            </Typography>
            <Grid container>
                <Grid item xs={lineChartXs}>
                    <BarChart
                        defaultData={props.data}
                        useColorIndex={true}
                        labels={labels}
                        height={60}
                        type={'horizontalBar'}
                        fontSize={props.fontSize}
                        suggestedMax={props.suggestedMax ? props.suggestedMax : 100}
                        colors={ barChartColor }
                    />
                </Grid>
                {pieChartList}
            </Grid>
        </Box>
    )
}
