import Logs from "../logs/Logs";
import MeasureCurrentPrint from "../mypage/measureCurrent/MeasureCurrentPrint";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import PrescriptionService from "../../services/PrescriptionService";
import PrintIcon from "@material-ui/icons/Print";
import {Button} from "@material-ui/core";
import ReactToPrint from "react-to-print";

const GatewayPrint = () => {
    let { prescriptionID, profileID } = useParams()
    
    const [ measureResult, setMeasureResult ] = useState(null)
    const printRef = useRef();
    
    const fetch = useCallback(async () => {
        const result = await PrescriptionService.getResult(prescriptionID)
        setMeasureResult(result)
    },[prescriptionID])

    useEffect(()=>{
        fetch().then()
    },[fetch])

    useEffect(() => {
        window.addEventListener(
            "message",
            (e) => {
                if ('print-trigger' === e.data.message) {
                    const print = document.querySelector('.btn-print')
                    if(print) {
                        setTimeout(() => {
                            print.click()
                            window.parent.postMessage({ message: "print-ready" }, '*');
                        }, 1000)
                    }
                }
            },
            false
        );
    }, []);
    
    if(!measureResult) return <></>
    return (
        <>
            <ReactToPrint
                trigger={() => 
                    <div className={'btn-print'}></div>
                }
                content={() => printRef.current}
            />

            <div ref={printRef} className={'print-wrap'}>
                <MeasureCurrentPrint
                    prescriptionID={measureResult.id}
                    measureResult={measureResult}
                    profileID={profileID}
                    onReadyToPrint={() => {
                        const print = document.querySelector('.btn-print')
                        if(print) {
                            setTimeout(() => {
                                print.click()
                                window.parent.postMessage({ message: "print-ready" }, '*');
                            }, 1000)
                        }
                    }}
                />
            </div>
        </>
        
    )
}

export default GatewayPrint
