import React, {forwardRef, useEffect, useReducer} from "react";
import CssTextField from '@user-components/CssTextField';


const initState = {
    value: '',
    isValid: false,
    isError: false
}

function reducer(state, action){
    switch (action.type){
        case 'UPDATE_VALUE':
            return {
                ...state,
                value: action.value,
                isValid: action.isValid,
                isError: action.isError
            }
        default:
    }
    return state
}
const ProfileNameTextField = forwardRef((props, ref) => {
 
    const [state, dispatch] = useReducer(reducer, initState)
    const { value, isValid, isError } = state
    const {valid} = props
    
    useEffect(() => {
        try {
            valid(isValid, value)
        } catch {}
    }, [isValid, value])
    
    return (
        <CssTextField
            label="사용자 이름"
            inputRef={ref}
            fullWidth
            id={''}
            onChange={(event)=>{
                
                let isValid = false
                let isError = true
                
                if(0 < event.target.value.length){
                    isError = false
                    isValid = true
                }
                
                dispatch({
                    type: 'UPDATE_VALUE',
                    value: event.target.value,
                    isValid,
                    isError    
                })
                
            }}
            placeholder={'예) 사용자이름'}
            inputProps={{ maxLength: 20}}
            error={isError}
            helperText={isError ? '이름을 입력해주세요' : ''}
        />
    )
})

export default ProfileNameTextField