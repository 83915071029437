import React, {useState, useEffect, useCallback} from 'react'

import {makeStyles} from '@material-ui/core/styles'
import {Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import CustomerMeasureDialog from "./CustomerMeasureDialog";

import CustomerService from "@user-service/manage/CustomerService";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import DateHelper from '@helper/DateHelper'
import clsx from "clsx";
import Chip from "@material-ui/core/Chip";

const MeasureTestList = ({searchKeyword}) => {
    const [logs, setLogs] = useState()
    const [currentProfile, setCurrentProfile] = useState({
        id: null,
        name: null,
        loginDate: null
    })
    const classes = useStyles()

    const fetch = useCallback(async () => {
        const logs = await CustomerService.getMeasureLogHistory(searchKeyword)
        setLogs(logs)
    }, [searchKeyword])

    useEffect(()=>{
        fetch().then()
    },[fetch])


    if(!logs){
        return(<LoadingSkeleton/>)
    }
    
    return (
        <Box className={clsx('log-history',classes.root)}>

            <CustomerMeasureDialog
                open={null != currentProfile.id}
                profileID={currentProfile.id}
                profileName={currentProfile.name}
                lastLoginDate={currentProfile.loginDate}
                onClose={() => {setCurrentProfile({id:null, name:null, loginDate: null})}}/>

            {(logs && 0 === logs.length) &&
            <List style={{color: 'white', marginTop: 25}}>
                <ListItem>
                    <ListItemText primary={
                        <Box style={{paddingTop: 30, textAlign: 'center', color: '#999'}}>
                            <Typography>측정 기록이 없습니다</Typography>
                        </Box>
                    } />
                </ListItem>
            </List>
            }

            {logs && 0 < logs.length &&
            <List style={{color: 'white', marginTop: 25}}>
                <ListItem>
                    
                    <ListItemText primary={
                        <Box style={{paddingLeft: 10}}>
                            이름
                        </Box>
                    } />
                    <ListItemSecondaryAction>
                        <Box className={clsx('table-wrap')}>
                            <Table  aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{width: '25%'}}>검사명</TableCell>
                                        <TableCell style={{width: '25%'}}>시작시간</TableCell>
                                        <TableCell style={{width: '10%', textAlign:'center'}}>설정시간</TableCell>
                                        <TableCell style={{width: '10%', textAlign:'center'}}>측정시간</TableCell>
                                        <TableCell style={{width: '20%', textAlign:'center'}}>비율 / 정답수</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </Box>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                
                {
                    logs.map((log) => {
                        const { id, profileName, profileImage, profileCode } = log
                        return(
                            <React.Fragment key={id}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar variant="rounded" className={classes.large}>
                                            {profileImage
                                                ? <img src={require(`@images/profile/${profileImage}`)} alt={profileName}/>
                                                : <ImageIcon/>
                                            }
                                        </Avatar>
                                    </ListItemAvatar>

                                    <ListItemText primary={
                                        <Box style={{paddingLeft: 10}}>
                                            {profileName}
                                        </Box>
                                    } secondary={
                                        <span style={{paddingLeft: 10, color: '#999'}}> {profileCode} </span>
                                    } />

                                    <ListItemSecondaryAction>
                                        <Box className={clsx('table-wrap')}>
                                            <Table aria-label="caption table">
                                                <TableBody className={classes.tableBody}>
                                                    <RenderTable key={id} classes={classes} log={log}/>
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        )
                    })
                }
            </List>
            }
        </Box>
    )
}


export function RenderTable(props){
    const {log , classes} = props

    const program = log.program
    const { image, name } =  program

    const resultVar = log.resultVar

    const viewText = resultVar ? resultVar.viewText : '-'
    const scoreText = resultVar && resultVar.scoreText? resultVar.scoreText : '-'
    const correctCount = resultVar && resultVar.correctCount? resultVar.correctCount + '개' : '-'
    let measureTestType
    let buttonCls

    measureTestType = '검사'
    buttonCls = classes.blue
    
    if( resultVar && resultVar.measureType ){
        measureTestType = 'develop' === resultVar.measureType ? '계발' : '검사'
        buttonCls = 'develop' === resultVar.measureType ? classes.green : classes.blue
    }

    return (
        <TableRow>
            <TableCell style={{width: '25%'}}>
                <Box className={classes.exerciseBox}>
                    <Grid container>
                        <Grid item>
                            <div className={'title'}>
                                {measureTestType &&
                                <Chip label={measureTestType}
                                      style={{marginRight: 10,height:24}}
                                      className={buttonCls}
                                />
                                }
                                {name}
                                {viewText &&
                                <span style={{display:'none'}} className={'sub'}>({viewText})</span>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </TableCell>
            <TableCell style={{width: '25%'}} className={'sub'}>{DateHelper.format(log.createdAt,'YY년 MM월 DD일 HH시mm분')}</TableCell>
            <TableCell style={{width: '10%', textAlign:'center'}} className={'sub'}>{log.measureTime}</TableCell>
            <TableCell style={{width: '20%', textAlign:'center'}} >{log.timeCountText}</TableCell>
            <TableCell style={{width: '20%', textAlign:'center'}} >{scoreText} / {correctCount}</TableCell>
        </TableRow>
    )
}


//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <div className="editprofile_wrap wrapper">
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </div>
        </Box>
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center",
        maxWidth: 1280,
        height: '100vh',
        paddingTop: 30,
        margin: 'auto'
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
        backgroundColor: '#1E2030',
    },
    title: {
        color: 'white',
        textAlign: 'center',
        marginBottom: 100,
        fontSize: 42
    },
    small: {
        fontSize: '0.8rem',
        color: '#999',
    },
    logo: {
        position: 'fixed',
        left: 30 ,
        top: 22,
        zIndex: 0,
    },
    add: {
        position: 'fixed',
        right: 30 ,
        top:22,
        zIndex: 0,
    },
    profileWrap: {
        width: '100%'
    },
    back: {
        color: 'white',
        '&:hover': {
            backgroundColor: '#12131D',
        }
    },
    backGray: {
        color: '#999',
    },
    backDisabled: {
        color: '#444'
    },
    tableBody: {
        width: 800
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),

        backgroundColor: 'transparent',
        '& img': {
            width: '100%'
        }
    },
    blue: {
        backgroundColor: '#0156B8',
        color: 'white',
        paddingTop: 1
    },
    green: {
        backgroundColor: '#247C40',
        color: 'white',
        paddingTop: 1
    }
}));


export default MeasureTestList
