import React, {useState, useEffect, useCallback} from 'react'

import {makeStyles} from '@material-ui/core/styles'
import {Box, Grid} from '@material-ui/core'
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";


import LoadingIndicator from "../../../components/LoadingIndicator";
import CenterService from "../../../services/manage/CenterService";
import Button from "@material-ui/core/Button";
import SchoolIcon from '@material-ui/icons/School';
import BusinessIcon from '@material-ui/icons/Business';
import CenterDialog from "./CenterDialog";
import PaymentService from "../../../services/manage/PaymentService";
import DateHelper from "../../../helper/DateHelper";

const PaymentList = ({searchKeyword}) => {
    const [payments, setPayments] = useState([])
    const classes = useStyles()
    
    const fetch = useCallback(async () => {
        const payments = await PaymentService.getPayments(searchKeyword, 100)
        setPayments(payments)
        
    }, [searchKeyword])

    useEffect(()=>{
        fetch().then()

    },[fetch])

    const fmt = new Intl.NumberFormat();
    if(!payments){
        return(<LoadingIndicator/>)
    }
    return (
        <Box className={classes.root}>
            <List style={{color: 'white', marginTop: 25}}>
                {
                    payments.map((payment, index) => {
                        const { productName, memberName, paymentDate, paymentPrice, postNo, address, cellPhone  } = payment
                        return(
                            <React.Fragment key={index}>
                                <ListItem
                                    button
                                    onClick={async () => {
                                    }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Box style={{paddingLeft: 10}}>
                                                [{memberName}] - {productName} 
                                            </Box>
                                        </React.Fragment>
                                    } secondary={
                                        <div style={{paddingLeft: 10, color: '#999'}}>
                                            <span> 
                                                결제일: {DateHelper.format(paymentDate, 'YY년 MM월 DD일 HH시 mm분')} 
                                            </span>
                                            {postNo &&
                                            <div style={{marginTop: 20}}>
                                                <span>주소 : ({postNo}) {address}</span>
                                                <div>
                                                    연락처 : {cellPhone}
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    } />

                                    <ListItemSecondaryAction>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Button
                                                    onClick={() => {
                                                    }}
                                                    size={'large'}
                                                    className={classes.backGray}>
                                                    {fmt.format(paymentPrice)}원
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        )
                    })
                }
            </List>
        </Box>
    )
}



const useStyles = makeStyles(theme => ({
    
    back: {
        color: 'white',
        '&:hover': {
            backgroundColor: '#12131D',
        }
    },
    backGray: {
        color: '#999',
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),

        backgroundColor: 'transparent',
        '& img': {
            width: '100%'
        }
    },
}));


export default PaymentList
