import React, {createRef} from 'react';
import {Box, Button, ButtonGroup, Grid, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from "@material-ui/core/Paper";

import ExerciseService from "../../../services/ExerciseService";
import TestService from "../../../services/TestService";
import clsx from "clsx";
import PrintIcon from "@material-ui/icons/Print";
import DateHelper from "../../../helper/DateHelper";
import html2canvas from "html2canvas";
import PrintHelper from "../../../helper/PrintHelper";
import MemoryComplexData from "./MemoryComplexData";
import _ from "lodash";

export default class CompleteDialog extends React.Component {
    scoreTable = [
        {startAge:20, endAge: 20, startScore:10, endScore: 10},
        {startAge:20, endAge: 20, startScore:9, endScore: 9},
        {startAge:30, endAge: 30, startScore:8, endScore: 8},
        {startAge:40, endAge: 40, startScore:7, endScore: 7},
        {startAge:50, endAge: 50, startScore:6, endScore: 6},
        {startAge:60, endAge: 60, startScore:5, endScore: 5},
        {startAge:70, endAge: 70, startScore:4, endScore: 4},
        {startAge:80, endAge: 80, startScore:3, endScore: 3},
    ]

    printRef = createRef()
  
    constructor(props) {
        super();
        this.state = {
            open: false,
            answers: [],
            history: props.history,
            onReset: props.onReset,
            showType: props.showType,
            gameResults: [],
            viewChar: [],
            exerciseID:props.exerciseID,
            exerciseName: props.exerciseName,
            measureType: props.measureType,
            description: props.description,
            score: {
                time: '00분 00초',
                correctCount: 0,
                count: 0,
                view: 'view1'
            },
            maxStep: 9,
            backgroundColor: TestService.getColorName(props.measureType, props.exerciseID),
            answerType: 'my-answer', // my-answer or correct-answer
        }
    }
    
    open(score, gameResults, sortedViewChar, challengeID) {
        const correctCount = score && score.correctCount ? score.correctCount : 0
        
        this.setState({
            ...this.state,
            open: true,
            gameResults,
            score: {
                ...score,
                isPass: ExerciseService.getExerciseResultByScoreTable(this.scoreTable, correctCount)
            },
            maxStep: gameResults.maxStep,
            viewChar: sortedViewChar
        })
      
      if(challengeID){
        ExerciseService.updateExerciseChallenge(challengeID, score).then()
      }
    } 
    
    close() {
       try{
           // this.state.history.goBack()
         MemoryComplexData.stages = _.cloneDeep(MemoryComplexData.defaultStages)
         this.state.history.push('/measureTrain')
         
       } catch(e) {}
    }
    
    reset(){
      MemoryComplexData.stages = _.cloneDeep(MemoryComplexData.defaultStages)
      
      const nextUrl = `${document.location.pathname}${document.location.search}`
      this.state.history.replace( {
        pathname: '/empty-page',
        state: {
          nextUrl: nextUrl,
          exerciseID: this.state.exerciseID,
          exerciseName: this.state.exerciseName,
          description: this.state.description,
          measureType: this.state.measureType
        }
      })
      
      //this.state.history.replace(nextUrl)
      /*
        this.setState({
            ...this.state,
            open: false,
        }, () => {
            if(this.state.onReset){
                this.state.onReset()
            }
        })
       */
    }
    render() {
        const { score, exerciseName} = this.state
        // const scoreTable = JSON.parse(JSON.stringify(this.scoreTable)).reverse()

        return (
            <Dialog
                disableBackdropClick
                open={this.state.open}
                aria-labelledby="responsive-dialog-title"
                fullWidth
                maxWidth="sm"
                className={clsx(['level-dialog', this.state.measureType, this.state.backgroundColor])}
            >
              <div ref={this.printRef}>
                <DialogTitle id="responsive-dialog-title">
                  <Box position={'absolute'} style={{left: 40, top: 20, display: 'none'}} className={'print-show'}>
                    <img src={require(`@assets/images/bh_logo.png`)} alt={'logo'} style={{height: 44}}/>
                  </Box>
                  <div className={'title-left'}>
                    {exerciseName} {['develop','train'].includes(this.state.measureType)  ? '프로그램' : '검사'}
                  </div>
                  <Box position={'absolute'} style={{right: 10, top: 20}} className={'print-hide'}>
                    {/*<Button*/}
                    {/*  className={'btn-print'}*/}
                    {/*  startIcon={<PrintIcon/>}*/}
                    {/*  style={{padding: '7.5px 21px', marginRight: 10}}*/}
                    {/*  variant={'outlined'} size={'large'}*/}
                    {/*  onClick={() => {*/}
                    {/*    PrintHelper.print(this.printRef)*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  인쇄*/}
                    {/*</Button>*/}
                  </Box>
                  <Box position={'absolute'}
                       style={{right: 40, top: 30, color: '#000', fontWeight: "normal", fontSize: '1rem', display: 'none'}}
                       className={'print-show'}>
                    출력일: {DateHelper.now('YYYY년 MM월 DD일')}
                  </Box>
                  
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3} style={{width: '100%'}}>
                        <Grid item xs={12}>
                            <Box>
                                <Box >  
                                    <Typography className={'title'}>{['develop','train'].includes(this.state.measureType)  ? '두뇌계발' : '검사'} 결과</Typography>
                                    <Grid container spacing={2} className={'result-wrap'}>
                                        <Grid item xs={4}>
                                            <Paper elevation={0} className={'result-box'}>
                                                <Typography>{['develop','train'].includes(this.state.measureType)  ? '측정' : '검사'}시간</Typography>
                                                <Typography className={'score'}>{score.time}</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Paper elevation={0} className={'result-box'}>
                                                <Typography>정답</Typography>
                                                <Typography className={'score'}>{score.correctCount} 개</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Paper elevation={0} className={'result-box'}>
                                                <Typography>성공</Typography>
                                                <Typography className={'score'}>{score.correctCount * 10} 점</Typography>
                                            </Paper>
                                        </Grid>
                                        {/*<Grid item xs={3}>*/}
                                        {/*    <Paper elevation={0} className={'result-box'}>*/}
                                        {/*        <Typography>평가결과</Typography>*/}
                                        {/*        <Typography className={'score'}>{isPass}</Typography>*/}
                                        {/*    </Paper>*/}
                                        {/*</Grid>*/}
                                    </Grid>
                                </Box>
                                <Box mt={3} mb={3} className={'print-hide'}>
                                    <Button fullWidth
                                            variant={"contained"}
                                            size={'large'}
                                            color={'primary'}
                                            onClick={this.reset.bind(this)}>
                                        {['develop','train'].includes(this.state.measureType)  ? '다시하기' : '재검사하기'}
                                    </Button>
                                    <Box mt={1}/>
                                    <Button fullWidth
                                            variant={"outlined"}
                                            size={'large'}
                                            onClick={this.close.bind(this)}>
                                        종료하기
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
              </div>
            </Dialog>
        );
    }
}
