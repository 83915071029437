import React, {useState, useEffect,useCallback} from 'react';
import AuthProvider from '@user-provider/AuthProvider'

// for ui
import { makeStyles } from '@material-ui/core/styles';
import {TextField, Grid, Box, Button, Menu, MenuItem, Typography} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import DateHelper from '@helper/DateHelper'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MessageService from '../../services/MessageService';
import nl2br from 'react-nl2br'
import clsx from "clsx";



const Message = (props) => {
    const hideWriteBox = props.hideWriteBox
    const hideEmptyMessage = props.hideEmptyMessage
    
    const [state, setState] = useState({
        profileID: 0,
        prescriptionID: 0,
        messages: []
    })

    const messageRefresh = useCallback(async () => {
        const profileID = props.profileID ? props.profileID : AuthProvider.getProfileID() 
        const res = await MessageService.getMessages(profileID, props.prescriptionID)
        
        setState({
            profileID,
            prescriptionID: props.prescriptionID,
            messages: res.data ? res.data : []
        })
    },[props.profileID, props.prescriptionID])

    const {profileID, messages, prescriptionID} = state

    useEffect(()=>{
        messageRefresh().then()
    },[messageRefresh])
    
    
    //데이터를 가져오는 로딩표시
    if(0 === profileID) return <LoadingSkection />
    return (
            <React.Fragment>
                {(AuthProvider.isAdmin() && !hideWriteBox )&&
                <InputMessageBox 
                    label={"사용자에게 보낼 메세지를 입력하세요"} 
                    onMessageRefresh={messageRefresh.bind(this)}
                    profileID={profileID}
                    prescriptionID={prescriptionID}
                />
                }
                {messages.map((message)=>{
                    return <MessageBox 
                        key={message.id} 
                        {...message} 
                        onMessageRefresh={messageRefresh.bind(this)}
                        useHighlight={props.useHighlight}
                    />
                })}
                {0 === messages.length && !hideEmptyMessage && 
                <Box textAlign={'center'} p={5}>
                    <Typography variant={'h6'} style={{color: '#ccc'}}>등록된 내용이 없습니다.</Typography>
                </Box>    
                }

            </React.Fragment>
    );
}


//로딩 화면 표시
export function LoadingSkection() {
    const classes = useStyles();

    return <div className="message-wrap wrapper2">
                <Box className={classes.root}>
                    <Skeleton variant="rect" height={150} />

                    <br/>

                    <Skeleton width="60%" />
                    <Skeleton />
                </Box>
            </div>
}


//입력창 
export function InputMessageBox(props) {
    const classes = useStyles();
    const [message, setMessage] = useState('')
    
    async function sendMessageHandler() {
        if('reply' === props.messageType){
            await MessageService.reply({
                parentMessageId: props.parentMessageId,
                message: message
            })

            //새로고침 
            if(props.onReplyMessageRefresh){
                props.onReplyMessageRefresh()
            }

            props.setShowReply(false)
        } else {

            await MessageService.post({
                mentionProfileID: AuthProvider.getProfileID(),
                message: message,
                profileID: props.profileID,
                prescriptionID: props.prescriptionID
            })

            if(props.onMessageRefresh){
                props.onMessageRefresh()
            }

        }        
        setMessage('')
    }



    return( 
        <Grid container spacing={1} style={{marginBottom: 20}}>
            <Grid item xs={10}>
                <TextField 
                    label={props.label}
                    variant="outlined"
                    onKeyUp={(event) => {
                       if('Enter' === event.key && 'reply' === props.messageType){
                           props.setShowReply(false)
                           return false
                       }
                    }}
                    fullWidth
                    value={message}
                    onChange={(event)=>{
                       setMessage(event.target.value)
                    }}
                    multiline={true}
                   className={classes.messageTextField}
                />
            </Grid>
            <Grid item xs={2}>
                <Button className={'' === message ? classes.sendButtonDisabled : classes.sendButtonEnabled }
                        onClick={sendMessageHandler.bind(this)}
                >
                    보내기
                </Button>
            </Grid>
        </Grid>
    )
       
}



//메세지 박스
export function MessageBox(props){
    const classes = useStyles();

    const [replies, setReplies] = useState([])
    const [showReply, setShowReply] = useState(false)
    const [messageType] = useState(props.messageType)

    const [anchorEl, setAnchorEl] = useState(null)
    const showMenu = Boolean(anchorEl)
    
    const [showAlert, setShowAlert] = useState(false)
    

    //팝업 닫기 
    const closeMenuHandler = () => {
        setAnchorEl(null)
    }

    //메세지 삭제하기
    const deleteMessageHandler = async () => {
     
        setAnchorEl(null)
        setShowAlert(true)

    }

    //삭제 확인 창
    const deleteConfrimHandler = async () => {

        const result = await MessageService.deleteMessage(props.id)

        if(!result.success){
            alert('실패')
        } 
        setShowAlert(false)

        //새로고침 
        if('reply' === props.messageType){
            if(props.onReplyMessageRefresh){
                props.onReplyMessageRefresh()
            }
        } else {
            if(props.onMessageRefresh){
                props.onMessageRefresh()
            }
        }
        
    }

    //댓글 가져오기
    const replyMessageRefresh = () => {
        MessageService.getReplies(props.id,(result)=>{
            var replies = []
            if(result.data){
                replies = result.data
            }
            setReplies(replies)
        })
    }


    useEffect(()=>{
        if( 0 < props.answerCount){
            replyMessageRefresh()
        }
    },[replyMessageRefresh,props.answerCount])




    //댓글일경우에 사용하는 클래스 
    return <div className={ "reply" === messageType ? classes.replyMessage : classes.messageBox}>
                <Grid container style={{width: '100%'}}>
                    {/*<Avatar className="nav-user-name" src="">img</Avatar>*/}
                    {/*<Grid item>*/}
                    {/*    <div className="message-tit">{props.profile.name}</div>*/}
                    {/*    <div className="message-subtit">브레인헬스 사용자</div>*/}
                    {/*    /!*<Grid item><Moment fromNow>{props.createdAt}</Moment></Grid>*!/*/}
                    {/*</Grid>*/}
                    <Grid item><b>{DateHelper.format(props.createdAt,'YYYY.MM.DD')}</b></Grid>
                    
                    {AuthProvider.getProfileID() === props.profile.id &&
                    <Grid item className="more-btn">        
                        <Button aria-controls="fade-menu" aria-haspopup="true" onClick={(event)=>{
                            setAnchorEl(event.currentTarget);
                        }}>
                            <img src={require('@images/iconDot3.png')} alt="메뉴아이콘" />
                        </Button>        
                    </Grid>
                    }
                </Grid>        
        
                <div className={clsx(classes.message,
                {
                    [classes.messageHighlight]: props.useHighlight
                }
                )} >
                    {nl2br(props.message)}
                </div>
                {/*<Grid container className="message-foot">*/}
                {/*    <Grid item><Moment fromNow>{props.createdAt}</Moment></Grid>*/}
                    {/*<Grid item onClick={()=>{setShowReply(!showReply)}}>답글달기</Grid>*/}
                {/*</Grid>*/}
                {showReply&&
                <div className="reply-inpt">
                    <InputMessageBox label={"메세지를 입력하세요"} 
                                    messageType={'reply'} 
                                    setShowReply={setShowReply}
                                    parentMessageId={props.id}
                                    onReplyMessageRefresh={replyMessageRefresh.bind(this)}
                                    />
                </div>
                }

                {/*답글을 처리합니다*/}
                {replies.map((reply)=>{
                    return <MessageBox key={reply.id} 
                                        {...reply} 
                                        messageType={'reply'}
                                        onReplyMessageRefresh={replyMessageRefresh.bind(this)}
                                        />
                })}

                <Menu
                    id="fade-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={showMenu}
                    onClose={closeMenuHandler}
                >
                    <MenuItem onClick={deleteMessageHandler}>메세지 삭제하기</MenuItem>
                </Menu>

                <Dialog
                open={showAlert}
                onClose={deleteConfrimHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"메세지 삭제 알림"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        삭제된 메세지는 다시 복구 할 수 없습니다.<br/>
                        삭제하시겠습니까?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{ setShowAlert(false)}} color="primary">취소</Button>
                    <Button onClick={deleteConfrimHandler} color="primary" autoFocus>확인</Button>
                </DialogActions>
                </Dialog>

            </div>
}


//확인 팝업 
export function AlertDialog() {
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <div>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Open alert dialog
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Let Google help apps determine location. This means sending anonymous location data to
              Google, even when no apps are running.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Disagree
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center"
    },
    replyMessage: {
        marginLeft:90,
        borderTop: '1px solid #eee',
        marginTop: 20,
        paddingTop: 20
    },
    messageBox: {
        borderBottom: '1px solid #eee',
        marginBottom: 20
    },
    message: {
        // paddingBottom: 20,
        padding: '10px 0',
    },
    messageHighlight: {
        fontSize: '1.1rem',
        fontWeight: 500,
        backgroundColor: '#FFF7DD',
    },
    messageTextField: {
        // borderColor: "#EEEEEE !important"
        fontSize: 14
    },
    button: {
        margin: theme.spacing(1),
    },
    sendButtonDisabled: {
        background:'#ddd',
        color: '#999;',
        height: 57
    },
    sendButtonEnabled: {
        background: '#29A4FF',
        color: '#fff;',
        height: 57
    },
    sendText: {
        marginTop: 5,
        marginBottom: 20,
        textAlign: 'right',
        color: '#333',
    },
}));

export default Message
