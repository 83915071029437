import React from "react";
import {Box, Grid, Typography} from "@material-ui/core";
import {ViewChar} from "../common/Data";
import _ from "lodash";
import ColorHelper from "../../../helper/ColorHelper";
import AuthProvider from "../../../provider/AuthProvider";
import CheckIcon from "@material-ui/icons/Check";

export class FlipViewBox extends React.Component {
  constructor(props) {
    super();

    /**
     * flip 타입은 색상이 틀리거나 색상이 맞거나
     */
    const stageInfo = props.stageInfo

    this.state = {
      width: props.width,
      height: props.height,
      widthOffset: props.widthOffset,
      stageInfo: stageInfo,
      randomProblem: props.randomProblem,
      renderView: props.renderView,
      blocks: props.blocks,
      programView: props.programView,
      checkCorrect: props.checkCorrect,
      isCorrect: props.isCorrect,
      viewState: 'front',
      doneFlip: props.doneFlip
    }
  }

  componentDidMount() {
    const { stageInfo } = this.state

    setTimeout(() => {
      this.setState({
        ...this.state,
        viewState: 'back'
      })


      if(this.state.doneFlip &&
        'number' === stageInfo.showSub &&
        'back' === this.state.viewState &&
        ['same-number', 'not-same-number'].includes(stageInfo.selectorType)
      ){
        this.state.doneFlip()
      }
    }, stageInfo ? stageInfo.flipDelay : 3000)
  }

  render() {
    const { widthOffset, stageInfo, randomProblem, renderView, blocks } = this.state
    const { width, height, programView, viewState} = this.state

    let showNumberOpacity = 1
    if('show-number' === stageInfo.selectorType && 'back' === viewState) {
      showNumberOpacity = 0
    }

    let text = stageInfo.text;
    // 1개 정답일 경우 
    if(stageInfo.all === 1 && stageInfo.textForOne){
      text = stageInfo.textForOne;
    }
    
    //집중력 프로그램은 이미지가 가로만 넓기 때문에 Top 처리 
    const marginTop = 'view9' === programView ? 50 : 0

    return (
      <>
        <Box position={'absolute'}
             top={-200}
             left={0}
             width={'100%'}
             textAlign={'center'}
             fontSize={'2.2rem'}
             fontWeight={'bold'}
        >
          {text}
        </Box>
        <Grid container spacing={6} justify={"center"}
              style={{
                marginTop: -widthOffset,
                maxWidth: Math.round(randomProblem.length / 2) * (this.state.width + widthOffset),
              }}
        >
          {blocks.map((pos, index) => {
            let grayscale = '0%';
            const problem = randomProblem[index]

            let boxItemStyle = {
              position: 'relative',
              width: width,
              height: height,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              cursor: "pointer",
              borderRadius: 20,
              backgroundColor: '#fff'
            }

            if(ViewChar[programView].cogItemBoxStyle){
              const style = ViewChar[programView].cogItemBoxStyle
              boxItemStyle = _.merge(boxItemStyle, ViewChar[programView].cogItemBoxStyle)
              if(style.border && problem.meta.color) {
                boxItemStyle.borderColor = ColorHelper.updateRGBAlpha(problem.meta.color, 0.3)
              }
            }

            if(stageInfo.background) {
              boxItemStyle.backgroundColor = 'transparent'
            }

            return (
              <Grid item key={index}>
                <Box style={boxItemStyle}
                     onClick={() => {
                       if(0 < stageInfo.flipDelay && 'front' === viewState) return

                       if(this.state.checkCorrect) {
                         this.state.checkCorrect(problem)
                       }
                     }}
                >
                  {'text' === renderView.type &&
                    <Typography
                      variant={"h2"}
                      style={{
                        transitionDuration: '0.5s',
                        textAlign: 'center',
                        lineHeight: `${height - 20 - marginTop}px`,
                        fontWeight: 'bold',
                        
                      }}
                    >
                      {problem.renderViewChar}
                    </Typography>
                  }

                  {'image' === renderView.type &&
                    <div style={{position:'relative'}}>
                      {problem.isSelected &&
                        <Box position={'absolute'} top={'50%'} left={'50%'} zIndex={100}
                             style={{transform: 'translate(-50%, 50%)'}}>
                          {problem.isCorrect &&
                            <svg fill={'#3BB438'}
                                 strokeWidth={1}
                                 stroke={'white'}
                                 xmlns="http://www.w3.org/2000/svg"
                                 width={'100%'}
                                 height={'90%'}
                                 viewBox="0 0 24 24">
                              <path
                                d="M0 11.386l1.17-1.206c1.951.522 5.313 1.731 8.33 3.597 3.175-4.177 9.582-9.398 13.456-11.777l1.044 1.073-14 18.927-10-10.614z"/>
                            </svg>
                          }
                          {!problem.isCorrect &&
                            <svg
                              fill={'#E94825'}
                              strokeWidth={1}
                              stroke={'white'}
                              xmlns="http://www.w3.org/2000/svg"
                              width={'100%'}
                              height={'90%'}
                              viewBox="0 0 24 24">
                              <path
                                d="M24 3.752l-4.423-3.752-7.771 9.039-7.647-9.008-4.159 4.278c2.285 2.885 5.284 5.903 8.362 8.708l-8.165 9.447 1.343 1.487c1.978-1.335 5.981-4.373 10.205-7.958 4.304 3.67 8.306 6.663 10.229 8.006l1.449-1.278-8.254-9.724c3.287-2.973 6.584-6.354 8.831-9.245z"/>
                            </svg>
                          }
                        </Box>
                      }

                      <div style={{position:"absolute"}}>
                        <img style={{
                          width: '100%',
                          height: '100%',
                          filter: `grayscale(${grayscale})`,
                          opacity: 'front' === viewState ? 1 : 0,
                          transitionDuration: '1s',
                          marginTop,
                          paddingTop: !!ViewChar[programView].paddingTop ? ViewChar[programView].paddingTop : '0',
                        }}
                             src={problem.renderViewChar}
                             alt={problem.randomIndex}/>
                      </div>
                      <div style={{position:"absolute", textAlign: 'center', width: '100%'}}>
                        <Typography
                          variant={"h4"}
                          style={{
                            opacity: 'back' === viewState ? 1 : 0,
                            transitionDuration: '1s',
                            lineHeight: `${height - 14}px`,
                            fontWeight: 'bold',
                            borderRadius: 11,
                            fontSize: '3.2rem',
                            background: 'background' === stageInfo.showSubType ? problem.meta.color : '#fff',
                            color: stageInfo.showSubType
                              ? '#fff'
                              : (problem.meta && problem.meta.color) ? problem.meta.color : '#000'
                          }}
                        >
                          {problem.meta.name.substring(0,3)}
                        </Typography>
                      </div>

                      {'number' === stageInfo.showSub &&
                        <div style={{
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          left:0,
                          top:50,
                          textAlign: 'center',
                          lineHeight: `${this.state.height}px`,
                          fontWeight: 'bold',
                          fontSize: '3rem',
                          opacity: showNumberOpacity,
                          paddingTop: !!ViewChar[programView].paddingTop ? ViewChar[programView].paddingTop : '0',
                        }} className="cal-text">
                          {problem.meta.number}
                        </div>
                      }
                    </div>
                  }
                  { (AuthProvider.isSuperAdmin() || AuthProvider.isShowAnswerMember()) 
                    && this.state.isCorrect(problem)
                    && 'back' === viewState
                    &&
                    <Box position={'absolute'} left={10} top={10} zIndex={100} style={{border:'1px solid #999', backgroundColor:'#efefef', borderRadius: 50, width: 30, height: 30}}>
                      <CheckIcon />
                    </Box>
                  }
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </>
    )
  }
}
