import React, {useEffect, useState} from 'react';
import DaumPostcode from 'react-daum-postcode';
import Dialog from '@material-ui/core/Dialog';

const Postcode = (props) => {
    
    const [open, setOpen] = useState(props.open)
    
    useEffect(() => {
        setOpen(props.open)
    },[props.open])
    
    const handleComplete = (data) => {
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            // fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        
        if(props.onClose) props.onClose({
            zipcode: data.zonecode,
            address: data.address,
            addressDetail: extraAddress
        })
        
    }

    const handleClose = () => {
        if(props.onClose) props.onClose(null)
    };

    return (
        <Dialog 
            onClose={handleClose}
            maxWidth={'sm'}
            aria-labelledby="zipcode-modal" 
            open={open}>
            <DaumPostcode
                style={{width: 400}}
                onComplete={handleComplete}
                { ...props }
            />
        </Dialog>
    );
}

export default Postcode