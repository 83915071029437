import React from "react";
import _ from "lodash";
import {Box, Grid, Typography} from "@material-ui/core";
import {ViewChar} from "../common/Data";
import ColorHelper from "../../../helper/ColorHelper";
import AuthProvider from "../../../provider/AuthProvider";
import CheckIcon from "@material-ui/icons/Check";

export class SurfingViewBox extends React.Component {

  constructor(props) {
    super();

    let showDelayProblem = []
    if(props.stageInfo.randomDelay && props.randomProblem){
      showDelayProblem = _.orderBy(props.randomProblem, ['showDelay'])
    }

    const isDoneShowDelayProblem = props.isDoneShowDelayProblem

    this.state = {
      width: props.width,
      height: props.height,
      widthOffset: props.widthOffset,
      stageInfo: props.stageInfo,
      randomProblem: props.randomProblem.map((p) => {
        p.opacity = 1
        if(props.stageInfo.randomDelay && !isDoneShowDelayProblem){
          p.opacity = 0
        }
        return p
      }),
      renderView: props.renderView,
      blocks: props.blocks,
      programView: props.programView,
      checkCorrect: props.checkCorrect,
      isCorrect: props.isCorrect,
      doneRandomDelay: props.doneRandomDelay,
      showDelayProblem,
      isDoneShowDelayProblem,
    }
  }


  showRandomDelay() {
    const r = this.state.showDelayProblem.shift()
    if(!r) {
      setTimeout(() => {
        const { doneRandomDelay } = this.state
        if(doneRandomDelay) {
          this.state.doneRandomDelay(true)
        }
      }, 3000)
      return
    }

    const randomProblem = this.state.randomProblem
    const index = randomProblem.findIndex( p => p.randomIndex === r.randomIndex)
    randomProblem[index].opacity = 1

    setTimeout(() => {
      this.setState({
        ...this.state,
        randomProblem
      })

      this.showRandomDelay()
    }, this.state.stageInfo.randomDelay)
  }

  componentDidMount() {
    const { stageInfo, isDoneShowDelayProblem } = this.state

    if(stageInfo.randomDelay && !isDoneShowDelayProblem) {
      this.showRandomDelay()
    }
  }

  render() {
    const { widthOffset, stageInfo, randomProblem, renderView, doneRandomDelay, isDoneShowDelayProblem} = this.state
    const { width, height, programView} = this.state

    const marginTop = 0
    let text = stageInfo.text;
    // 1개 정답일 경우 
    if(stageInfo.all === 1 && stageInfo.textForOne){
      text = stageInfo.textForOne;
    }
    if(isDoneShowDelayProblem && stageInfo.afterText){
      text = stageInfo.afterText
      
      if(stageInfo.afterTextOne) {
        text = stageInfo.afterTextOne
      }
    }

    const positions = stageInfo.positions
    let blocks = isDoneShowDelayProblem ? randomProblem.map((_, index) => index) : this.state.blocks

    return (
      <>
        {stageInfo.background &&
          <Box position={'absolute'}
               top={-100}
               left={0}
               width={'100%'}
               height={stageInfo.backgroundHeight ? stageInfo.backgroundHeight : '62%'}
               style={{
                 opacity: isDoneShowDelayProblem ? 0 : 1,
                 transitionDuration: '0.5s',
                 backgroundImage: `url(${stageInfo.background})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundPosition: '0% 0%',
                 backgroundSize: '100% 100%'
               }}
          >
          </Box>
        }
        <Box position={'absolute'}
             top={-200}
             left={0}
             width={'100%'}
             textAlign={'center'}
             fontSize={'2.2rem'}
             fontWeight={'bold'}
        >
          {text}
        </Box>
        <Grid container spacing={6} justify={"center"}
              style={{
                marginTop: -widthOffset,
                maxWidth: Math.round(blocks.length / 2) * (this.state.width + widthOffset),
              }}
        >
          {blocks.map((pos, index) => {
            let backgroundColor = stageInfo.background ? 'transparent' : 'rgba(255,255,255)'
            if(isDoneShowDelayProblem) backgroundColor = 'rgba(255,255,255)'


            let textColor = ''
            let grayscale = '0%';

            const problem = randomProblem[index]
            let position
            if( positions && positions[index]) {
              if(stageInfo.all < 3){
                position = positions[index]  
              }
            }


            let boxStyle = {
              position: 'relative',
              width: width,
              height: height,
              color: textColor,
              // border: 10,
              // borderStyle: 'solid',
              // borderColor: '#CFCFCF',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              cursor: "pointer",
              textShadow: '2px 2px 2px rgba(0, 0, 0, 0.3)',
              backgroundColor,
              borderRadius: 20,
            }

            if(position && !isDoneShowDelayProblem){
              boxStyle = {
                ...boxStyle,
                position: 'absolute',
                right: position.right ? position.right : undefined,
                left: position.left ? position.left : undefined,
                top: position.top ? position.top : undefined,
                width: position.width ? position.width : width,
                height: position.height ? position.height : height,
                zIndex: 100 - index
              }
            }


            if(ViewChar[programView].cogItemBoxStyle && isDoneShowDelayProblem){
              const style = ViewChar[programView].cogItemBoxStyle
              boxStyle = _.merge(boxStyle, ViewChar[programView].cogItemBoxStyle)

              if(style.border && problem.meta.color) {
                boxStyle.borderColor = ColorHelper.updateRGBAlpha(problem.meta.color, 0.3)
                
                if(problem.meta.textColor){
                  boxStyle.borderColor = ColorHelper.updateRGBAlpha(problem.meta.textColor, 0.3)
                }
              }
            }
            
            if(stageInfo.background && !isDoneShowDelayProblem) {
              boxStyle.backgroundColor = 'transparent'
            }

            let paddingTop =  (!!ViewChar[programView].paddingTop) ? ViewChar[programView].paddingTop : '0'
            if('background' === stageInfo.showSubType){
              paddingTop = 0
            }
            
            return (
              <Grid item key={index}>
                <Box style={boxStyle}
                     onClick={() => {
                       //random delay가 있고  표시 중일떄
                       if(!isDoneShowDelayProblem && stageInfo.randomDelay) return

                       if(this.state.checkCorrect) {
                         this.state.checkCorrect(problem)
                       }
                     }}
                >
                  { (AuthProvider.isSuperAdmin() || AuthProvider.isShowAnswerMember()) 
                    && this.state.isCorrect(problem)
                    && isDoneShowDelayProblem
                    &&
                    <Box position={'absolute'} left={10} top={10}  zIndex={100} style={{border:'1px solid #999', backgroundColor:'#efefef', borderRadius: 50, width: 30, height: 30}}>
                      <CheckIcon />
                    </Box>
                  }
      
                  {'text' === renderView.type &&
                    <Typography
                      variant={"h2"}
                      style={{
                        transitionDuration: '0.5s',
                        textAlign: 'center',
                        lineHeight: `${height - 20 - marginTop}px`,
                        fontWeight: 'bold'
                      }}
                    >
                      {problem.renderViewChar}
                    </Typography>
                  }

                  {'image' === renderView.type &&
                    <>
                      {problem.isSelected &&
                        <Box position={'absolute'} top={'50%'} left={'50%'} zIndex={100}
                             style={{transform: 'translate(-50%, -50%)'}}>
                          {problem.isCorrect &&
                            <svg fill={'#3BB438'}
                                 strokeWidth={1}
                                 stroke={'white'}
                                 xmlns="http://www.w3.org/2000/svg"
                                 width={'100%'}
                                 height={'90%'}
                                 viewBox="0 0 24 24">
                              <path
                                d="M0 11.386l1.17-1.206c1.951.522 5.313 1.731 8.33 3.597 3.175-4.177 9.582-9.398 13.456-11.777l1.044 1.073-14 18.927-10-10.614z"/>
                            </svg>
                          }
                          {!problem.isCorrect &&
                            <svg
                              fill={'#E94825'}
                              strokeWidth={1}
                              stroke={'white'}
                              xmlns="http://www.w3.org/2000/svg"
                              width={'100%'}
                              height={'90%'}
                              viewBox="0 0 24 24">
                              <path
                                d="M24 3.752l-4.423-3.752-7.771 9.039-7.647-9.008-4.159 4.278c2.285 2.885 5.284 5.903 8.362 8.708l-8.165 9.447 1.343 1.487c1.978-1.335 5.981-4.373 10.205-7.958 4.304 3.67 8.306 6.663 10.229 8.006l1.449-1.278-8.254-9.724c3.287-2.973 6.584-6.354 8.831-9.245z"/>
                            </svg>
                          }
                        </Box>
                      }

                      {(!isDoneShowDelayProblem || !stageInfo.afterShowType) &&
                        <img style={{
                          width: '100%',
                          // height: '100%',
                          maxHeight: '100%',
                          marginTop,
                          filter: `grayscale(${grayscale})`,
                          opacity: isDoneShowDelayProblem ? 1 : problem.opacity,
                          transitionDuration: '0.5s',
                          paddingTop: !!ViewChar[programView].paddingTop ? ViewChar[programView].paddingTop : '0',
                        }}
                             src={problem.renderViewChar}
                             alt={problem.randomIndex}
                        />
                      }


                      {'text' === stageInfo.afterShowType && isDoneShowDelayProblem &&
                        <div style={{
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          left:0,
                          top:0,
                          textAlign: 'center',
                          lineHeight: `${this.state.height}px`,
                          fontWeight: 'bold',
                          fontSize: '3.2rem',
                          color: ('background' === stageInfo.showSubType &&  problem.meta.textColor) ? problem.meta.textColor: problem.meta.color,
                          background: 'background' === stageInfo.showSubType ? problem.meta.color : '#fff',
                          paddingTop: paddingTop,
                          borderRadius: 10,
                        }} className="cal-text">
                          {problem.meta.name.substring(0,3)}
                        </div>
                      }
                    </>
                  }
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </>
    )
  }
}
