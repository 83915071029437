import Axios from 'axios';
import AuthProvider from '@user-provider/AuthProvider'
import NetworkHelper from '@helper/NetworkHelper'
import Moment from 'moment-timezone'

export default class PrescriptionService {

    /**
     * return last measure test log
     * @returns {Promise<null|any>}
     */
    static async getMeasureTestLast(){
        try {
            const result = await Axios.get('/api/prescription/log/measureTest/last')
            return result.data;
        } catch(e){}

        return null
    }

    
    /**
     * @param params
     */
    static async getLastPrescription(params){
        try {
            const result = await Axios.get('/api/prescription/latest/id',{
                params: params
            })
            return result.data
        }catch(e){
        }
        return null
    }

    /**
     * @param limit
     * @returns {Promise<void>}
     */
    static async getLastBands(limit = 2){
        try {
            const result = await Axios.get(`/api/prescription/latest/bands?limit=${limit}`)
            
            let data = result.data
            if(!data){
                data = [{
                    id: 0,
                    values: [0,0,0,0]
                },{
                    id: 0,
                    values: [0,0,0,0]
                }]
            }
            if(!data[1]){
                data[1] = {
                    id: 0,
                    values: [0,0,0,0]
                }
            }
            
            return data
        }catch(e){
        }
        return null
    }
    

    /**
     * 측정한 이미지를 base64 파일을 업로드합니다.
     * @param {*} prescriptionID 
     * @param {*} params 
     */
    static async fileUploadFFT(prescriptionID, params){
        await Axios.post(`/api/prescription/${prescriptionID}/fft-image`, params);
    }
    /**
     * 측정한 이미지를 가져옵니다.
     * @param {*} prescriptionID
     * @param hzType
     * @param measureCode
     */
    static async getFFTImage(prescriptionID, hzType = 'eeg', measureCode = 'C'){
        const convertToImage = (response) => {
            let image = btoa(
                new Uint8Array(response.data)
                  .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
        }
        const config = {
            responseType: 'arraybuffer'
        }
        
        let ch1Res
        let ch2Res
        
        try {
            ch1Res = await Axios.get(`/api/prescription/${prescriptionID}/image/ch1?hzType=${hzType}&measureCode=${measureCode}`, config);            
        } catch {}
        
        try {
            ch2Res = await Axios.get(`/api/prescription/${prescriptionID}/image/ch2?hzType=${hzType}&measureCode=${measureCode}`, config);   
        } catch {}

        return {
            ch1: ch1Res ? convertToImage(ch1Res) : undefined,
            ch2: ch2Res ? convertToImage(ch2Res) : undefined,
        }
    }

    /**
     * 측졍 결과를 가져옵니다.
     * @param id
     * @param callback
     */
    static async getPrescriptionById(id, callback){
        try {
            let result = await Axios.get(`/api/prescription/${id}/result`)
            result.data = NetworkHelper.getDecParam(result.data)
            
            if(200 === result.status){
                callback(result.data)
            }

        }catch(e){
        }
    }
    
    static async getPrescriptionBandAvg(id){
        try {
            let result = await Axios.get(`/api/prescription/${id}/resultBandAvg`)
            return result.data
        } catch {}
        
        return []
    }

    /**
     * fft raw 데이터를 가져옵니다.
     * @param id
     * @param hzType
     * @param measureTypeCode
     * @param measureCode
     * @returns {Promise<*[]|any>}
     */
    static async getFFTData(id, hzType, measureTypeCode, measureCode){
        try {
            const res = await Axios.get(`/api/prescription/${id}/file/${hzType}/${measureTypeCode}/${measureCode}`);
            return res.data    
        } catch (e){
            return []
        }
    }

    /**
     * 측졍 결과를 가져옵니다.
     * @param id
     */
    static async getBalanceByPrescriptionId(id){
        try {
            let result = await Axios.get(`/api/prescription/${id}/resultBalance`)
            return NetworkHelper.getDecParam(result.data)
        }catch(e){}
        
        return null
    }

    /**
     * 처방전에 대한 운동일 수를 가져옵니다.
     * @param prescriptionID
     * @param callback
     */
    static async getExerciseDay(prescriptionID, callback){
        try {
            const result = await Axios.get('/api/prescription/'+ prescriptionID +'/exercise-days')
            
            if(200 === result.status){
                // return result.data
                callback(result.data)
            }
        }catch(e){
            if(500 === e.response.status ){ 
                //todo 
            }
        }
        
        return null
    }


    /**
     * 이번달에 측정한 마지막 기록을 가져옵니다.
     */
    static async getLatest(callback){
        const result = await Axios.get('/api/prescription/this-month/latest')
        if(callback){
            callback(result.data)
        } else {
            return result.data
        }
    }


    /**
     * 진행중인 처방전 데이터 삭제
     */
    static async resetPrescriptionMeasureData(prescriptionID){
        try {
            const result = await Axios.put('/api/prescription/' + prescriptionID + '/reset/measurement')
            
            if(204 === result.status){
                // 생성된 아이디
                return true
            }
        }catch(e){
            if(500 === e.response.status ){ 
                //todo 
            }
        }
        return false
    }

    /**
     * 새로운 처방전을 생성합니다.
     */
    static async newPrescription(measureTypes){
        const params = {
            profileID : AuthProvider.getProfileID(),
            deviceID: AuthProvider.getDeviceID() ,
            channelCount: 2,
            type: 'lab',
            measureTypes: (measureTypes || []).join(',')
        }
        
        try {
            const result = await Axios.post('/api/prescription', params)
            
            if(201 === result.status){
                // 생성된 아이디
                return result.data
            }
        }catch(e){
            if(500 === e.response.status ){ 
                //todo 
            }
        }
        return null
    }


    /**
     * 새로운 측정이면 newScription , 업데이트면 reset처리 합니다.
     */
    static async setCurrent(callback){
        const prescription = await this.getLatest()
        //처방전이 없으면 새로생성합니다.

        let isNew = false;
        if(!prescription) isNew = true;
        else if(prescription.status === 'done') isNew = true;
    
        if ( isNew ){
            await this.newPrescription()
        
        } else if('measure-request' === prescription.status) {
            //진행중인 데이터를 초기화 하고 재시작합니다.
            await this.resetPrescriptionMeasureData(prescription.id)
        } 

        const newPrescription = await this.getLatest()
        callback(newPrescription)
    }


    /**
     * 측정된 데이터를 초마다 서버에 저장합니다.
     * 뇌 균형상태 분석을 추가합니다.
     * @param {*} params 
     */
    static async addBalance(params){
        try {
            await Axios.put('/api/prescription/'+ params.prescriptionID +'/balances/' + params.type,{
                positionData: JSON.stringify(params.positionData),
                hitData: NetworkHelper.getEncParam(params.hitData),
                hitCount: params.hitCount
            })
        }catch(e){
        }
    }

    /**
     * 좌우뇌 균형 측정된 결과를 가져옵니다. 
     * @param prescriptionID
     * @param measureType
     * @returns {Promise<void>}
     */
    static async getBalance(prescriptionID, measureType){
        let result
        try {
            const res = await Axios.get(`/api/prescription/${prescriptionID}/balances/${measureType}`)
            result = res.data
            result.data = NetworkHelper.getDecParam(result.data)
            
        }catch(e){}
        
        return result
    }

    /**
     * 프리스크립션의 상태값을 변경합니다.
     * @param {*} prescriptionID
     * @param hzType
     * @param measureTypeCode
     * @param measureCode
     * @param {*} status
     */
    static async updateStatus(prescriptionID, hzType, measureTypeCode, measureCode, status){
        try {
            const result = await Axios.put('/api/prescription/'+ prescriptionID +'/status',{
                hzType,
                status,
                measureCode,
                measureTypeCode
            })

            if(204 === result.status){
                return true
            }
        }catch(e){
            return false
        }
    }


    /**
     * 처방전에 대한 운동을 가져오빈다.
     * @param {*} prescriptionID
     * @param useFilter
     * @param date
     * @param staffMemberId
     * @param isLast
     */
    static async getExercise(prescriptionID, useFilter = false, date = '', staffMemberId = '', isLast = 'no'){
        try {
            let url = `/api/prescription/${prescriptionID}/exercises?date=${date}&staffMemberId=${staffMemberId}&isLast=${isLast}`;
            // if(date){
            //     url += '?date=' + date
            // }
            // if(staffMemberId){
            //     url += date ?  '&' : '?'
            //     url += 'staffMemberId=' + staffMemberId
            // }
            //
            // url += '&isLast=' + isLast
            
            const result = await Axios.get(url)
            if(useFilter){
                result.data.exercises = result.data.exercises.filter((item) => null !== item.prescriptionExerciseID)
                result.data.exercises.sort((a, b) => {
                    return a.ordinal - b.ordinal
                })
            }
          
            return result.data
        }catch(e){
        }
        return null
    }

    static async getPrescriptExercise(prescriptionID){
        try {
            const result = await Axios.get(`/api/prescription/${prescriptionID}/prescript-exercises`)
            
            return result.data
        }catch(e){
        }
        return null
    }


    static async startPrescriptExercise(prescriptionExerciseID){
        try {
            const result = await Axios.get('/api/prescription/exercise/start/' + prescriptionExerciseID)
            return result.data
        }catch(e){
        }
        return null
    }

    static async endPrescriptExercise(memberExerciseID, prescriptionExerciseID){
        try {
            const result = await Axios.put('/api/prescription/exercise/end/' + memberExerciseID, {
                prescriptionExerciseID
            })
            return result.data
        }catch(e){
        }
        return null
    }

    static async needExerciseToday(prescriptionID){
        if(!prescriptionID) {
            return {
                needExercise: false
            }
        }
        
        try {
            let url = '/api/prescription/'+ prescriptionID +'/needExercise'
            const result = await Axios.get(url)
            return result.data
        }catch(e){}
        
        return {
            needExercise: false
        }
    }
    
    static async getExerciseStep(prescriptionID, date){
        if(!prescriptionID) return null
        
        try {
            let url = '/api/prescription/'+ prescriptionID +'/exercises/step'
            if(date){
                url += '?date=' + date
            }
            
            const result = await Axios.get(url)
            return result.data
        }catch(e){
        }
        return null
    }
    
    static async addPrescriptionExercise(prescriptionID, exercisesID, term){
        try {
            await Axios.post('/api/prescription/'+ prescriptionID +'/exercises', {
                exercisesID,
                term
            })
        }catch(e){
        }
        return null
    }
    
    

    static async updatePrescriptionExercisesCode(prescriptionID, exercises){
        try {
            await Axios.put(`/api/prescription/${prescriptionID}/exercises`, {
                exercises: exercises.map((ex) => {
                    const { measureCode, measureTypeCode, exerciseCount, ordinal, prescriptionExerciseID  } = ex
                    return {
                        measureCode,
                        measureTypeCode,
                        exerciseCount: exerciseCount,
                        ordinal,
                        prescriptionExerciseID
                    }
                })   
            })
        } catch {}
    }

    static async updatePrescriptionWorkCount(prescriptionID, {exerciseWeekCount, exerciseDayCount, prescriptDayCount}){
        try {
            await Axios.put(`/api/prescription/${prescriptionID}/workCount`, {
                exerciseWeekCount, 
                exerciseDayCount, 
                prescriptDayCount
            })
        } catch {}
    }
    
    static async updateExpireDate(prescriptionID, expireDate){
        try {
            await Axios.put('/api/prescription/'+ prescriptionID +'/expire-date', {
                expireDate
            })
        }catch(e){
        }
        return null
    }

    static async updateReservationDate(prescriptionID, reservationDate){
        try {
            await Axios.put('/api/prescription/measure-reservation', {
                reservationDate,
                prescriptionID,
                profileID: AuthProvider.getProfileID()
            })
        }catch(e){
        }
        return null
    }
    
    /**
     * 이번주 운동한 기록을 가져옵니다.
     * @param {*} profileID 
     */
    static async getExerciseWeek(profileID){
        try {
            const result = await Axios.get('/api/prescription/week/' + profileID)
        
            if(204 === result.status){
                return result.data
            }
        }catch(e){
            if(500 === e.response.status ){ 
                //todo 
            }
        }
        return null
    }
    
 

    /**
     * 처방전 결과를 가져옵니다
     * @param prescriptionID
     */
    static async getResult(prescriptionID){
        if(!prescriptionID) return null
        
        try {
            const result = await Axios.get(`/api/prescription/${prescriptionID}/result`)
            return NetworkHelper.getDecParam(result.data)
        }catch(e){}
        
        return null
    }


    /**
     * 처방전 목록을 월별로 가져옵니다.
     * @param year
     * @param callback
     */
    static async getMeasureHistories(year, callback){
        try {
            const result = await Axios.get('/api/prescriptions/histories',{
                params:{
                    year: year
                }
            })

            if(200 === result.status){
                // return result.data
                callback(result.data)
            }
        }catch(e){
            if(500 === e.response.status ){ 
                //todo 
            }
        }
    }

    static async getPrescriptionIds(){
        try {
            const result = await Axios.get('/api/prescriptions/ids')
            return result.data
        }catch(e){
            console.log(e)
        }
        
        return null
    }

    /**
     * 달력의 한달만큼
     * @param start
     * @param end
     */
    static async getPrescriptionByPeriod(start, end){
        try {
            const params = {
                params: {
                    start,
                    end
                }
            }
            const result = await Axios.get('/api/prescriptions/period', params)

            if(200 === result.status){
                const measureInMonth = [];
                const measureData = [];
                const { prescriptions } = result.data

                prescriptions.map((value) => {
                    try {
                        const date = Moment.utc(value.createdDate).tz(Moment.tz.guess())
                        const dateFmt = date.format('YYYY-MM-DD')
                        const index = measureInMonth.indexOf(dateFmt)

                        if(-1 === index){
                            measureInMonth.push(dateFmt)
                            measureData.push({
                                ...value,
                                date: date.format('YYYY-MM-DD'),
                                time: '00:00'
                            });
                        } 
                    } catch(e){
                        console.log(e)
                    }
                    return value
                })

                return measureData
            }
        }catch(e){
            console.log(e)
        }

        return []
    }


    /**
     * 처방전의 상태를 변경합니다.
     * @param prescriptionID
     * @param params
     */
    static async updatePrescription(prescriptionID, params){
        try {
            const result = await Axios.put('/api/admin/prescription/' + prescriptionID + '/prescribe', params)

            if(204 === result.status){
                // return result.data
                return true
            }
        }catch(e){
            if(500 === e.response.status ){ 
                //todo 
            }
        }

        return false
    }

    /**
     * FFT 분석 결과를 가져옵니다.
     * @param {*} prescriptionID
     * @param type
     * @param {*} callback
     */
    static async getMeasureFromFile(prescriptionID, type, callback) {
        try {

            let result;
            if('balance' === type){
                const rest = await Axios.get('/api/prescription/' + prescriptionID + '/file/rest_fft');
                const attemption = await Axios.get('/api/prescription/' + prescriptionID + '/file/attemption_fft');
                const concentration = await Axios.get('/api/prescription/' + prescriptionID + '/file/concentration_fft');

                result = {
                    status: 200,
                    data: {
                        rest: rest.data,
                        attemption: attemption.data,
                        concentration: concentration.data
                    }
                }

            } else { 
                result = await Axios.get('/api/prescription/' + prescriptionID + '/file/' + type)
            }

            if(200 === result.status){
                callback(result.data)
            }
        }catch(e){
            console.log(e)
        }
        
        return null
    }

    /**
     * 메세지를 업데이트 합니다.
     * @param {*} prescriptionID 
     * @param {*} message 
     */
    static async updateMessage(prescriptionID, message){

        try {
            await Axios.put('/api/prescription/' + prescriptionID + '/message',{
                message
            }) 
        } catch (e) {

        }
    }

    /**
     * 측정에 대한 결과 (좋음, 나쁨)를 업데이트합니다.
     * @param {*} prescriptionID 
     * @param {*} fftResult 
     * @param {*} balanceResult 
     */
    static async updateMeasureResult(prescriptionID, fftResult, balanceResult){
        try {
            await Axios.put('/api/prescription/' + prescriptionID + '/measureResult',{
                fftResult,
                balanceResult
            }) 
        } catch (e) {

        }
    }


    /**
     * 측정된 뇌기능 의식패턴을 가져옵니다.
     * @returns {Promise<void>}
     */
    static async getBrainActive(prescriptionID){
        try {
            const res = await Axios.get('/api/prescription/'+ prescriptionID +'/brainActive')
            return res.data
        } catch (e) {}
        
        return []
    }

    static async getMeasureBandTest(prescriptionID){
        try {
            const res = await Axios.get('/api/prescription/'+ prescriptionID +'/measureBandTest')
            return res.data
        } catch (e) {}

        return []
    }

    /**
     * request prescription to customer
     * @param prescriptionID
     * @param measureType
     * @param customerProfileID
     * @returns {Promise<any>}
     */
    static async requestPrescript(prescriptionID, measureType, customerProfileID){
        try {
            const res = await Axios.post(`/api/prescription/${prescriptionID}/request`,{
                measureType,
                customerProfileID,
                managerID: AuthProvider.getManagerID()
            })
            return res.data
        } catch (e) {}
        
        return null
    }

    /**
     * get info prescript request
     * @param requestID
     * @returns {Promise<null|any>}
     */
    static async getPrescriptionRequest(requestID){
        try {
            const res = await Axios.get(`/api/prescription/request/${requestID}`,)
            return res.data
        } catch (e) {}

        return null
    }

    /**
     * get ready request
     * @param prescriptionID
     * @returns {Promise<null|any>}
     */
    static async getReadyRequests(prescriptionID){
        const request = {
            eeg: false,
            default: false,
            meditation: false
        }
        
        try {
            const res = await Axios.get(`/api/prescription/${prescriptionID}/ready-request`)
            for(let r of res.data){
                if('eeg' === r.measureType) {
                    request.eeg = true
                    request.eegRequestID = r.id
                    request.eegRequestDate = r.createDate
                }
                if('default' === r.measureType) {
                    request.default = true
                    request.defaultRequestID = r.id
                    request.defaultRequestDate = r.createDate
                }
                if('meditation' === r.measureType) {
                    request.meditation = true
                    request.meditationRequestID = r.id
                    request.meditationRequestDate = r.createDate
                }
                if('measureBandTest' === r.measureType) {
                    request.measureBandTest = true
                    request.measureBandTestRequestID = r.id
                    request.measureBandTestRequestDate = r.createDate
                }
            }

        } catch (e) {}
        
        return request
    }

    /**
     * update log id by request
     * @param requestID
     * @returns {Promise<void>}
     */
    static async getUpdateRequest(requestID) {
        if(!requestID || '' === requestID || undefined === requestID || 'undefined' === requestID) return null
        
        try {
            await Axios.put(`/api/prescription/request/${requestID}/log`)
        } catch (e) {}
        
        return null
    }
    
    
    static async deletePrescriptionExercise(prescriptionExercise){
        try {
            await Axios.delete(`/api/prescription/exercise/${prescriptionExercise}`)
        } catch (e) {console.log(e)}
    }

    static async deletePrescription(prescriptionID){
        try {
            await Axios.delete(`/api/prescription/delete/${prescriptionID}`)
        } catch (e) {console.log(e)}
    }
    static async updateMeasureTypes(measureTypes){
        
    }
}
