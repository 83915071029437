import React, {useState, useEffect, useCallback} from 'react';

import { connect } from 'react-redux';
import {updateMeasure} from '@reducers/DeviceAction'

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import DeviceService from '../../services/DeviceService';

import { useCookies } from 'react-cookie';
import AuthProvider from '@user-provider/AuthProvider'
import DeviceLogin from '@user-pages/device/DeviceLogin';
import BridgeProvider from "../../provider/BridgeProvider";

const queryString = require('query-string');

const Device = ({history}) => {
    const classes = useStyles();
    
    const [userState, setUserState] = useState('ready')
    const [, setCookie] = useCookies([AuthProvider.userCookieName])
    const deviceInfo = queryString.parse(window.location.search)

    window.app = {
        onDisplayInfo: (displayInfo) => {BridgeProvider.instance.resolve(displayInfo)}
    }
    
    const fetch = useCallback(async () => {
        const result = await DeviceService.loginDevice(deviceInfo)

      
        if(!result.success){
            alert('연결에 실패했습니다.' + result.message)
            return
        }
        
        const info = result.data
        if(!info.loginID){
            setUserState('login')
        } else {
            result.data.loginByDevice = true
            AuthProvider.setCookie(result.data)

            try {
                await BridgeProvider.instance.setToken(AuthProvider.getToken())
                // await window.CefSharp.BindObjectAsync('BRHW')
                // await window.BRHW.setToken(AuthProvider.getToken())
            } catch(e){}

            setCookie(AuthProvider.userCookieName, result.data , AuthProvider.getCookieOption())

          

            try {
                const displayInfo = await BridgeProvider.instance.getDisplayInfo()
                const displayParams = new URLSearchParams(displayInfo);

                const computerInfo = {
                    displayWidth: displayParams.get('width'),
                    displayHeight: displayParams.get('height')
                }
                
                await DeviceService.updateComputerInfo(info.deviceID, computerInfo)
            } catch (e) {}

          if('profile-selector' === result.data.memberType) {
            history.replace('/profile-selector')
          } else {
            history.replace('/profile')
             // window.location.replace('/profile')
          } 
        }
    }, [])
    
    useEffect(()=>{
        fetch().then()
    },[fetch])

    const registerCompleteHandler = async () => {
        await fetch()
    }
    
    if('ready' === userState){
        return (
            <></>
            // <Box className={classes.root}>
            //     <Box className={classes.text} >
            //         브레인 헬스를 시작하고 있습니다 <br/>
            //         잠시만 기다려 주세요
            //     </Box>
            //     <div className={classes.wrap} />
            //     <div className={classes.videoWrap}>
            //         <video src={'https://s3.ap-northeast-2.amazonaws.com/brhw.kr/assets/loginBackground.webm'}
            //                autoPlay
            //                loop
            //                muted
            //                style={{width: '100%'}}
            //         />
            //     </div>
            // </Box>
        )
    }
    if('login' === userState){
        return (
            <Box className={classes.root}>
                <DeviceLogin 
                    {...deviceInfo} 
                    registerCompleteHandler={registerCompleteHandler.bind(this)}
                    returnUrl={`/login-device${window.location.search}`}
                />
            </Box>
        )
    }
}

const useStyles = makeStyles( () => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center"
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
    },
    paper: {
        height: 172,
        width: 172,
        background: '#ddd',
        
    },
    square: {
        width: '100%',
        height: '100%',
        borderColor: '#ddd'

    },
    text: {
        position: 'absolute',
        left: 0,
        top: window.innerHeight / 2 - 100 ,
        zIndex: 3,
        color: 'white',
        width: '100%',
        textAlign: 'center',
        fontSize: 40,
        fontWeight: 500,
        marginBottom: 0,
        letterSpacing: -3
    },
    wrap : {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: window.innerHeight,
        background: '#000',
        zIndex: 1,
        opacity: 0.7,
    },
    videoWrap: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: window.innerHeight,
        background: '#100d14',
        zIndex: 0
    }
}));

const mapStateToProps = state => {
    return { measure: state.DeviceReducer.measure }
}
const mapDispatchToProps = dispatch => {
    return { 
        updateMeasure: (measure) => {
            dispatch(updateMeasure(measure))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Device)
