import React, {useEffect, useRef, createRef, useState } from 'react';

import AudioPlayer from '@user-pages/exercise/common/AudioPlayer'
import StartButton from '@user-pages/exercise/common/StartButton'
import CustomerRequestDialog from "@user-components/CustomerRequestDialog"

import {Grid, Box} from '@material-ui/core';
import SocketProvider from '@user-provider/SocketProvider';
import MeasureHelper from '@helper/MeasureHelper'
// import ExerciseStatusBar from "./ExerciseStatusBar";

import StartMeasureDialog from "./StartMeasureDialog";
import EndMeasureDialog from "./EndMeasureDialog";
import PrescriptionService from '@user-service/PrescriptionService'

import queryString from "query-string";
import {useParams} from "react-router-dom";
import {RealtimeMeasureChart} from "./RealTimeMeasureChart";
const { measureTypeToCode } = MeasureHelper
const MeasureHzType = 'measureBandTest'

let MeasureCodes = ['MA','MM','MB'] 


const MeasureBandTest = (props) => {
    const audioRef = useRef()
    const measureRef = useRef()
    const runningTime = 90

    const qs = queryString.parse(window.location.search)
    const [isRequest] = useState(undefined !== qs.rq)
    const [requestID] = useState(qs.rqs)
    const { prescriptionID } = useParams()
    
    const [currentMeasureCode, setMeasureCode] = useState(null)
    const [showStartDialog, setShowStartDialog] = useState(false)
    const [showEndDialog, setEndDialog] = useState(false)

    const fetch = async () => {
        SocketProvider.connect(async ()=> {
            if(!isRequest && !showStartDialog) {
                setShowStartDialog(true)
            }
        }, window, MeasureHzType)
    }
    
    useEffect(()=>{
        // SocketProvider.connect(()=>{}, window, MeasureHzType)
        MeasureCodes = ['MA','MM','MB']
        fetch().then()
        
        SocketProvider.onMeasure = (data) => {
            measureRef.current.update(data)
        }
        
        SocketProvider.onMeasureStop = async ({fileKey}) => {
            const measureCode = MeasureCodes.shift()
            
            await measureRef.current.saveImage(fileKey)
            
            if(!measureCode) {
                measureRef.current.updateChart()
                if(SocketProvider.isBRHWService){
                    await PrescriptionService.updateStatus(prescriptionID, 'measureBandTest', measureTypeToCode(currentMeasureCode), 'C', 'measure-completed')
                    await PrescriptionService.getUpdateRequest(requestID)
                }
                
                setEndDialog(true)
                return
            }
            
            setMeasureCode(measureCode)
            setTimeout(() => {
                setShowStartDialog(true)
                // startExercise(runningTime, 'type1', measureCode).then()    
            },1000)
            
        }
        
        return () => {
            SocketProvider.measureStop(true).then()
        }
    },[])

    const startExercise = async (runningTime, measureType, measureCode) => {
        measureRef.current.clear()
        measureRef.current.updateChart()
        await SocketProvider.measureStart(runningTime, prescriptionID, MeasureHzType, measureType, measureTypeToCode(measureType), measureCode)
    }
    
    return (
        <Box style={{position: 'relative'}}>
            <Box
                display="flex"
                justifyContent="flex-end"
                position="absolute"
                right={10}
                top={10}
                zIndex={99}
            >
                <Grid container justify="center" spacing={1}>
                    <Grid item>
                        <StartButton
                            onStop={async () =>{
                              MeasureCodes = []
                                // await SocketProvider.measureStop(false, true)
                              document.location.href = `/measureSelect`
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            {isRequest &&
            <CustomerRequestDialog
                handleStart={() => {
                    // setShowStartDialog(true)
                }
                }/>
            }
            
            {/*<ExerciseStatusBar*/}
            {/*    measureHzType={MeasureHzType}*/}
            {/*    measureType={'type1'}*/}
            {/*    hideIntervalTime={true}*/}
            {/*    disableMeasureType={true}*/}
            {/*    close={async () => {*/}
            {/*        await stopExercise()*/}
            {/*    }}*/}
            {/*    start={async () => {*/}
            {/*        if(0 === MeasureCodes.length){*/}
            {/*            MeasureCodes = ['MA','MM','MB']*/}
            {/*        }*/}
            {/*        const measureCode = MeasureCodes.shift()*/}
            {/*        await startExercise(runningTime, 'type1', measureCode)*/}
            {/*    }}*/}
            {/*/>*/}
            <Box className={'innerViewport'}>
                {/*<RealtimeMeasure ref={measureRef} prescriptionID={prescriptionID}/>*/}
                <RealtimeMeasureChart ref={measureRef} prescriptionID={prescriptionID} />
            </Box>
            
            <StartMeasureDialog
                onHide={async () => {
                    let measureCode = currentMeasureCode
                    if(!measureCode){
                        measureCode = MeasureCodes.shift()
                        setMeasureCode(measureCode)    
                    }
                    await startExercise(runningTime, 'type1', measureCode)
                    setShowStartDialog(false)
                }}
                measureCode={currentMeasureCode}
                open={showStartDialog}
                // open={false}
                history={props.history}
                />

            <EndMeasureDialog
                open={showEndDialog}
                history={props.history}
                prescriptionID={prescriptionID}
                />
            
            <AudioPlayer ref={ audioRef } />
        </Box>
    );
}

// 실시간 측정 페이지



export default MeasureBandTest
