
import React from 'react';
import { Pie } from 'react-chartjs-2';


export default class PieChart extends React.Component { 

    chartOption
    chartRef

    constructor(props) {
        super(props)

        let labels = props.labels ? props.labels : ['delta','theta','alpha','SMR','LBeta','MBeta','HBeta']
        let title = props.title ? props.title : ''
        let data = props.defaultData ? props.defaultData : new Array(7).fill(0)
        let height = props.height ? props.height : 130
        let width = props.width ? props.width : 130
        let colors = props.colors ? props.colors : [] 
        let useColorIndex = props.useColorIndex ? props.useColorIndex : false
        // let legendPosition = props.legendPosition ? props.legendPosition : 'bottom' 
        let backgroundColor
        if(data){
            
            backgroundColor = data.map((percent, index) => {
                if(useColorIndex) {
                    return colors[index]; 
                } else {
                    let calcPercent = percent > 50 ? percent - 45 : percent
                    // const index = parseInt(Math.abs(mv));

                    if(0 === colors.length) return 'rgba(0,0,0,0.3)';
                    return colors[calcPercent];
                }
                
            })
            
        }
        
        this.state = {
            time: 0,
            title: title,
            height: height,
            width: width,
            colors: colors,
            fontSize: props.fontSize ? props.fontSize : 14,
            xLabelDisplay: props.xLabelDisplay ? props.xLabelDisplay : false,
            legend: {
                display: props.legendPosition ? true : false,
                position : props.legendPosition,
                labels: {
                    fontSize: props.fontSize,
                    fontColor: '#000',
                },
                reverse: props.labelsReverse? props.labelsReverse : false,
                onClick: () => {}
            },
            chartData: {
                labels: labels,
                datasets: [{
                    backgroundColor: backgroundColor,
                    data: data
                }]
            }
        }

    }
    /**
     * 데이터를 받아 화면을 새로고칩니다.
     * @param {*} time 
     * @param {*} data 
     */
    addData(time, data){
        // const before = this.state.chartData.datasets[0].data.slice( 0, this.state.chartBuffer - data.length )

        let index = 0;
        const colors = data.map((mv) => {
            index = parseInt(mv)
            return this.state.colors[index]
        })

        this.state.chartData.datasets[0].data = data
        this.state.chartData.datasets[0].backgroundColor = colors
        this.state.chartData.datasets[0].borderColor = colors

        this.chartRef.chartInstance.update()
    }

    componentWillMount(){
        // Chart.plugins.register(zoom)

        this.chartOption = {
            legend: this.state.legend,
            animation: {
                duration: 0.3 // general animation time
            },
            responsive: true,
            title: {
                display: false,
                text: this.state.title
            },
            scales: {
                xAxes: [{
                    display: this.state.xLabelDisplay,
                    scaleLabel: {
                        display: true,
                        labelString: this.state.title,
                        fontColor: '#000',
                        fontSize: this.state.fontSize,
                        FontStyle: 'bold'
                    },
                    ticks: {
                        display: false,
                        fontSize: this.state.fontSize,
                        fontColor: '#000',
                        // Include a dollar sign in the ticks
                        callback: function(value, index, values) {
                            return '$'+value;
                        }
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                }]
            }
        }
    }

    render() {
        return <Pie ref={(ref)=> this.chartRef = ref} 
                    data={this.state.chartData} 
                    options={this.chartOption} 
                    width={this.state.width}
                    height={this.state.height} />
    }
};