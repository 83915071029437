
// for socket.io
import socketIOClient from "socket.io-client";
import DeviceService from '@user-service//DeviceService';
import AuthProvider from '@user-provider/AuthProvider'
import Axios from 'axios';
import BridgeProvider from "./BridgeProvider";
import NetworkHelper from "../helper/NetworkHelper";

const zlib = require('zlib');

export default class SocketProvider {

    static socket
    static deviceInfo       //기기 정보

    static onConnect        //서버와 연결
    static onDisconnect     //서버와 종료

    static onMeasure        //측정중- 데이터 받음

    static onMeasureMonitor
    static onMeasureHit
    static onMeasureTime

    static onMeasureStart   //측정 시작
    static onMeasureStop    //측정 종료

    static onMeasureStartStatusBar
    static onMeasureStopStatusBar

    static onMeasureStopByAutoRun // 자동 시작시
    static onDeviceDisconnect   //브레인헬스 기기와 연결이 끊어짐
    static onDeviceStatus

    static onMessages

    static recvData = [] // 서버에서 받은데이터 
    static recvDataCount = 0  //서버에서 보낸 측정휫수 
    static recvDate;

    static sendData = []
    static sendDataCount = 0
    static eventWorker  // 서버에서 받은 데이터를 1초 마다 처리하기 위한 worker 
    static sendWorker

    static requestMeasure    // 측정 요청 파라미터
    static startCheckTimer          //시작 측정 여부 확  

    static fileKey
    static measureLogID

    static isBRHWService = false
    static isRunning = false
    static pause = false
    static measureHzType

    static useEndMeasureOfQueue = false
    static currentTime = 0

    static onDeviceMeasureDataConsole
    /**
     * 기기에서 보내는 이벤트 관련
     */
    static async initDeviceEvent(window){
        if(window && !window.app){
            window.app = {}
        }
        
        try {
          window.app.onDeviceMeasureData = null
          window.app.onDeviceMeasureDataUNC = null
          window.app.onDeviceMeasureStop = null
          window.app.onPacketError = null
        } catch {}

        try {
            window.app.onDeviceMeasureData = (data) => {
                this.onDeviceMeasureData(data) 
            }
            window.app.onDeviceMeasureDataUNC = (data) => {
                const enc = NetworkHelper.getEncParam(JSON.parse(data))
                this.onDeviceMeasureData(enc)
            }
            
            window.app.onDeviceMeasureStop = () => { this.onDeviceMeasureStop() }
            window.app.onPacketError = () => {}
            
            /*
               안드로이드 이벤트 처리
             */
            window.app.onDeviceInfo = (deviceInfo) => {
                BridgeProvider.instance.resolve(deviceInfo) 
            }
            window.app.onVersion = (version) => { BridgeProvider.instance.resolve(version) }
            window.app.onOsVersion = (version) => { BridgeProvider.instance.resolve(version) }
            window.app.onConnectedDevice =(isConnected) => { BridgeProvider.instance.resolve(isConnected)}
            
        } catch (e) {}

        this.isBRHWService = BridgeProvider.isBRHWService
    }

    /**
     * 기기에서 받은 데이터를 서버로 전송합니다.
     * @param data
     */
    static onDeviceMeasureData(data){
        this.sendDataCount++
        this.sendData.push({
            action: 'measure',
            data
        });
    }

    /**
     * stop from client
     * @returns {Promise<void>}
     */
    static async onDeviceMeasureStop(){
        this.sendData.push({
            action: 'stop',
        })
    }

    /**
     * sender queue worker
     */
    static startSendWorker() {
        this.clearSendWork()

        this.sendWorker = setInterval(() => {
            const queue = this.sendData.shift()

            if(!queue) return
            
            if(this.socket.connected){
                this.socket.emit('measure-queue', queue)
            } else {
                this.sendData.unshift(queue)
            }

        }, 300)
    }


    /**
     * clear sender worker
     */
    static clearSendWork(){
        if(this.sendWorker) {
            clearInterval(this.sendWorker)
        }

        this.sendData.length = 0
    }

    /**
     * parse received Data
     * @returns {boolean}
     */
    static parseReceivedData(){

        if(0 === this.recvData.length) { return false }
        if(this.pause) { return false }

        const queueData =  this.recvData.shift()
        if(!queueData) return false;

        const { data , action } = queueData
        try {
            switch (action){
                case 'measure':
                    const jsonString = zlib.inflateSync(Buffer.from(data, 'base64')).toString()
                    const parsedData = JSON.parse(jsonString)

                    if(parsedData.hzType === this.measureHzType){
                        if(this.onMeasure && parsedData){

                            if(this.currentTime === parsedData.time){
                                // if(this.onDeviceStatus){
                                //     this.onDeviceStatus('ERROR_CRC')
                                // }
                                return false
                            }

                            this.onMeasure(parsedData)

                            try { this.onMeasureHit(parsedData.isHit) } catch{}
                            try { this.onMeasureMonitor(parsedData) } catch{}

                            if(this.onMeasureTime){
                                this.currentTime = parsedData.time
                                this.onMeasureTime(parsedData.time)
                            }
                        }
                    }
                    break
                case 'stop':
                    this.measureStop().then()
                    break
                default:

            }
        }catch(e){ console.log(e);}
    }
    /**
     * start received data worker
     */
    static startEventWorker() {
        this.clearEventWorker()
        this.eventWorker = setInterval(this.parseReceivedData.bind(this),1000)
    }

    static clearEventWorker() {
        if(this.eventWorker) {
            clearInterval(this.eventWorker)
            this.eventWorker = null
        }
    }
    /**
     * get status from redis db
     * @returns {Promise<*>}
     */
    static async checkDeviceStatus(){
        const info = {
            memberID: '',
            token: '',
            mac: '',
        }

        if(AuthProvider.isCustomerView()){
            info.memberID = AuthProvider.getCustomerMemberID()
            info.token = AuthProvider.getToken()
            info.mac = AuthProvider.get('computerMac')
        } else {
            try {
                // const deviceInfo = await window.BRHW.getDeviceInfo()
                const deviceInfo = await BridgeProvider.instance.getDeviceInfo()
                const urlParams = new URLSearchParams(deviceInfo)

                info.token = urlParams.get('token')
                info.mac =  urlParams.get('mac')
                
            } catch {}
        }
        if('' === info.token && '' === info.memberID ){
            return null
        }

        const { data } = await DeviceService.connected(info);
        return data
    }

    /**
     * start measure to socket with device
     * @param measureTime
     * @param prescriptionID
     * @param hzType
     * @param measureType
     * @param measureTypeCode
     * @param measureCode
     * @param checkStartCallback
     * @param startCallback
     * @param stepCount
     * @param noiseCode
     * @returns {Promise<boolean>}
     */
    static async measureStart(
        measureTime,
        prescriptionID,
        hzType = 'default',
        measureType,
        measureTypeCode,
        measureCode = 'C',
        checkStartCallback = null,
        startCallback = null,
        stepCount = 1,
        noiseCode = 'A') {

        if(this.isRunning) { return false }
        if(!this.socket) { return false }

        this.setStopStatus()
    
        try {

            this.requestMeasure = {
                profileID: AuthProvider.getProfileID(),
                measureBit: 256,
                measureTime: parseInt(measureTime),
                prescriptionID,
                measureType,
                measureTypeCode,
                measureCode,
                hzType,
                stepCount,
                deviceSerial: this.deviceInfo.deviceSerial,
                noiseCode: noiseCode
            }

            if(!this.isBRHWService){
                alert('기기와 연결되지 않았습니다.')
                if(startCallback) {
                    startCallback(false)
                }
                return false
            }

            this.startSendWorker()
            this.setMeasureOption()

            this.socket.emit('measure-start',
                this.requestMeasure,
                ({success, message, fileKey, logID}) => {
                    this.fileKey = fileKey
                    this.measureLogID = logID

                    if (!success) {
                        if('EMPTY_HZ_SETTINGS' === message){
                            alert('측정 시작에 실패했습니다. 설정값을 확인해주세요')
                        } else if ('NOT_CONNECTED_DEVICE' === message){
                            alert('기기와 연결되지 않았습니다. 연결을 확인해 주세요')
                        }
                    } else {
                        this.isRunning = true

                        try {
                            // window.BRHW.deviceMeasureStart(this.requestMeasure.measureTime, measureType).then()
                            BridgeProvider.instance.deviceMeasureStart(this.requestMeasure.measureTime, measureType).then()

                            if(this.onMeasureStartStatusBar){
                                this.onMeasureStartStatusBar(this.requestMeasure)
                            }
                        } catch(e){
                            this.socket.emit('measure-start-failed', {
                                logID,
                                message: '[E_ST_0001] 측정시작실패'
                            })

                            this.clearSendWork()
                            // this.clearEventWorker()

                            alert('측정 시작에 실패했습니다. 다시 시도해 주세요')
                            this.isRunning = false
                        }
                    }

                    if(startCallback){
                        startCallback(success)
                    }
                })
        }catch(e){
            alert('측정 시작에 실패했습니다')
        }
    }
    /**
     * toggle device data
     * @returns {Promise<boolean>}
     */
    static async measurePauseToggle(){
        if(this.pause){
            try {
                this.pause = false
                await BridgeProvider.instance.deviceMeasureResume()
                // await window.BRHW.deviceMeasureResume()
            } catch {}
            return this.pause
        }

        try {
            if(this.isRunning){
                this.pause = true
                // await window.BRHW.deviceMeasurePause()
                await BridgeProvider.instance.deviceMeasurePause()
            }
        } catch {}

        return this.pause
    }

    /**
     * measure stop to device
     * @param withClearEvent
     * @param force
     * @returns {Promise<boolean>}
     */
    static async measureStop(withClearEvent = false, force= false){
        this.setStopStatus()

        if(withClearEvent){
            this.clearEvent()
        }

        if(!this.isBRHWService){
            return false
        }

        await this.deviceMeasureStop()

        this.clearSendWork()
        // this.clearEventWorker()

        if(this.isRunning){
            try {
                this.socket.emit('measure-stop',{
                    measureBit: 256,
                    measureTime: 180,
                    deviceSerial: this.deviceInfo.deviceSerial,
                    fileKey: this.fileKey,
                    force
                })
            } catch(e){}
            this.isRunning = false
        }
    }

    /**
     * send message to socket server
     * @param msgType
     * @param msgData
     * @returns {boolean}
     */
    static sendMessages(msgType, msgData){
        try {
            this.socket.emit('messages',{
                msgType,
                msgData,
                device: this.deviceInfo
            })
            return true
        } catch(e) {
            console.log(e);
        }

        return false
    }

    /**
     * clear on event
     */
    static clearEvent(){
        this.onMeasure = null
        this.onMeasureStart = null
        this.onMeasureStop = null
        this.onMeasureTime = null

        this.onMeasureRequest = null
    }

    static async deviceMeasureStop(){
        try {
            await BridgeProvider.instance.deviceMeasureStop()
        } catch(e) { }
    }

    static setStopStatus() {
        this.currentTime = 0
        this.startCheckTimer = null

        this.recvData.length = 0
        this.recvData = []

        this.sendData.length = 0
        this.sendData = []

        this.recvDataCount = 0
        this.sendDataCount = 0

        this.useEndMeasureOfQueue = false
        this.pause = false

    }
    static setMeasureOption(){
        const useHzTypes = ['eeg', 'default']
        if(-1 < useHzTypes.indexOf(this.requestMeasure.hzType)){
            this.useEndMeasureOfQueue = true
        }
    }

    static async checkStartMeasure(callback) {
        if(this.startCheckTimer){
            clearTimeout(this.startCheckTimer)
        }

        this.startCheckTimer = setTimeout(async() => {
            try {
                this.recvDate.getTime()
                return false
            } catch (e){}

            this.socket.emit('measure-start', this.requestMeasure)
            await this.checkStartMeasure()
            if(callback){
                callback('restart')
            }
        },5000)
    }

    static log(messageType, message) {
        this.socket.emit('log',{
            deviceSerial: this.deviceInfo.deviceSerial,
            messageType,
            message,
        })
    }

    static isConnected(){
        try {
            return this.socket.connected
        } catch (e){ return false}
    }


    /**
     * socket connect with set events
     * @param callback
     * @param window
     * @param hzType
     * @returns {Promise<void>}
     */
    static async connect(callback, window, hzType) {
        if(AuthProvider.isAppMode()) return 
        
        try {
            await this.deviceMeasureStop()
        } catch {}

        await this.initDeviceEvent(window)
        this.deviceInfo = await this.checkDeviceStatus()
    
        if(!this.deviceInfo){
            try {
                this.socket.disconnect()
            } catch{}
            this.socket = null
            return
        }

        this.measureHzType = hzType
        if(this.socket && this.socket.connected){
            try {
                callback()
            } catch (e) {}
            return
        }

        this.startEventWorker()

        let socketHost =  process.env.REACT_APP_SOCKET_HOST
        if(process.env.NODE_ENV === 'development'){
            const res = await Axios.get(`/api/host-ip`)
            socketHost = `${res.data}:4001`
        }
        
        this.socket = socketIOClient(socketHost, {
            path: process.env.REACT_APP_SOCKET_PATH,
            transports: ['websocket'],
            query: {
                APPLICATION_TYPE: this.isBRHWService ? 'PC' : 'WEB' ,
                // VERSION: this.isBRHWService ? await window.BRHW.getVersion() : '-',
                VERSION: this.isBRHWService ? await BridgeProvider.instance.getVersion() : '-',
                ROOM_ID: this.deviceInfo.roomID,
                DEVICE_ID:  this.deviceInfo.deviceID,
                PROFILE_ID : this.deviceInfo.profileID,
                DEVICE_SERIAL : this.deviceInfo.deviceSerial
            }
        });

        this.socket.on('connected',()=>{
            this.socket.emit('web-login' , this.deviceInfo)
            if(this.onConnect) this.onConnect()
        })

        this.socket.on('measure-queue',(queue) => {
            // if(!this.isRunning){ return false }

            this.recvDataCount++
            this.recvDate = new Date()
            this.recvData.push(queue)
        })

        this.socket.on('measure-start',(data) => {
            if(this.onMeasureStart){
                this.onMeasureStart(data)
            }
        })

        this.socket.on('measure-stop',(data) => {
            this.clearSendWork()
            // this.clearEventWorker()

            if(this.onMeasureStop) this.onMeasureStop(data)
            if(this.onMeasureStopByAutoRun) this.onMeasureStopByAutoRun(data)
            if(this.onMeasureStopStatusBar) this.onMeasureStopStatusBar(data)

            this.setStopStatus()
        })

        this.socket.on('messages', (data) => {
            if(this.onMessages){
                this.onMessages(data)
            }
        })

        if(callback){
            callback(this.deviceInfo.status)
        }
    }


    static getMeasureLogID(){
        return this.measureLogID
    }

    static getRequestMeasure(){
        return this.requestMeasure
    }

    static disconnect(){
        try {
            this.socket.disconnect()
            this.socket = null
        } catch(e){}
    }
}
