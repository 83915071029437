import React, { useEffect, useState, useCallback} from 'react';
import Typography from "@material-ui/core/Typography";
import SocketProvider from '@user-provider/SocketProvider';
import CustomizedDialogs from "@user-components/CustomDialog";
import PrescriptionService from '@user-service/PrescriptionService'
import {useHistory} from "react-router-dom";
import queryString from "query-string";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Box from "@material-ui/core/Box";


export default function CustomerRequestDialog(props) {
    const history = useHistory()
    const qs = queryString.parse(window.location.search)
    const [open, setOpen] = useState(undefined !== qs.rq)
    
    const onMessages = (data) => {
        switch (data.msgType){
            case 'customer-measure-start': 
                if(props.handleStart){
                    props.handleStart()
                }
                setOpen(false)
                break
            default:
        }
    } 
    
    const sendMeasureRequest = useCallback(async () => {
        const request = await PrescriptionService.getPrescriptionRequest(qs.rq)
        
        setTimeout(() => {
            const success = SocketProvider.sendMessages('customer-measure-request',request)
            if(!success){
                sendMeasureRequest()
            }
        }, 500)
    },[qs])
    
    useEffect(() => {
        SocketProvider.onMessages = onMessages
        sendMeasureRequest().then()

        return () => {
            SocketProvider.onMessages = null
        }
    }, []);

    return (
        <CustomizedDialogs
            key={`ga-${Math.random()}`}
            open={open}
            title={'측정 대기 중'}
            content={
                <React.Fragment>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Box>
                            <div style={{textAlign:'center', borderRadius: '50%', background: '#CDD9EE', width: 150, height: 150, margin: 'auto', marginBottom: 20}}>
                                <HourglassEmptyIcon style={{fontSize: 100, color: '#0156B8', marginTop: 25}}/>
                            </div>
                            
                            <Typography gutterBottom>
                                사용자 참여를 기다리는 중입니다 <br/>
                                잠시만 기다려주세요 <br/>
                            </Typography>

                            <Typography gutterBottom style={{fontSize:14, color: '#999', marginTop: 20}}>
                                사용자 참여가 완료되면 해당 메세지가 없어집니다.
                            </Typography>
                        </Box>
                    </Box>
                </React.Fragment>
            }
            close={() => {
                setOpen(false)
                history.goBack()
            }}
            cancel={() => {
                setOpen(false)
                history.goBack()
            }}
        />
    );
}

