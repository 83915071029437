
import React, {useCallback, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {Grid, TextField, Button, Box} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import red from '@material-ui/core/colors/red';

import DateHelper from '@helper/DateHelper'
import ProfileService from '@user-service/ProfileService'
import CustomizedDialogs from "../../components/CustomDialog";
import clsx from "clsx";

const Managers = ({history}) => {
    const classes = useStyles();
    const [managers, setManagers] = React.useState([]);
    const [selectManager , setSelectManager] = React.useState(null)
    
    const fetch = useCallback(async () => {
        const managers = await ProfileService.getManager()

        setManagers(managers)
    }, [])

    const handleDelete = (index) => {
        setSelectManager( managers[index] )
    }

    useEffect(()=>{
        fetch().then()
    },[fetch])

    return (
        <div id="cont-wrap" >
            <div className="sv" >
                <Grid container>
                    <Grid item xs={1}>
                        <IconButton
                            className={classes.back}
                            aria-label="delete"
                            onClick={()=>{
                                history.goBack()
                            }}
                        >
                            <ArrowBackIcon style={{fontSize: 50}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <div>
                            <h3>담당 교육사</h3>
                            <i>Brain Health</i>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className={clsx('cont')} >
                <AddManger onChange={() => {
                    fetch().then()
                }}/>
               
                <List
                    style={{marginTop: 30}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            담당 교육사
                        </ListSubheader>
                    }
                    className={classes.listRoot}
                >
                    {managers.map((manager, index) => {
                        return (
                            <React.Fragment key={Math.random()}>
                                <ListItem className={'item'}>
                                    <ListItemIcon>
                                        <PersonIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                {manager.name}
                                            </Typography>
                                            <div className={classes.small}>
                                                브레인헬스 본사
                                            </div>
                                        </React.Fragment>
                                    } />
                                    <ListItemSecondaryAction>
                                        <React.Fragment>
                                            <span className={classes.date}>{DateHelper.format(manager.createDate, 'YYYY-MM-DD HH:mm')}</span>
                                            <IconButton onClick={() => {
                                                handleDelete(index)
                                            }} className={classes.remove}>
                                                <HighlightOffIcon />
                                            </IconButton>
                                        </React.Fragment>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        )
                    })}
                </List>
            </div>
            <CustomizedDialogs
                key={Math.random()}
                open={!!selectManager}
                title={'교육사 해제'}
                content={
                    <React.Fragment>
                        <Typography gutterBottom className={classes.text} variant={'h5'}>
                            {selectManager
                                ? selectManager.name
                                : ''
                            }
                        </Typography>
                        
                        <Typography gutterBottom className={classes.text}>
                            담당 교육사를 해제하시겠습니까?
                        </Typography>
                        <br/>
                        <Typography gutterBottom className={classes.text}>
                            교육사와 연결이 해재되면 교육사의 서비스를 이용할 수 없으며 <br/>또한 교육사도 고객님의 내용을 열람 할 수 없습니다.
                        </Typography>
                    </React.Fragment>
                }
                data={selectManager}
                close={() => {
                    setSelectManager(null)
                }}
                submit={async () => {
                    await ProfileService.deleteManager(selectManager.id)
                    await fetch()
                    setSelectManager(null)
                }}
                buttonName={'확인'}
            />
        </div>
    );
}

const AddManger = (props) => {
    const [managerCode, setManagerCode] = React.useState('')
    const [error, setError] = React.useState(false)
    return (
        <React.Fragment>
            <Grid container>
                <Grid item>
                    <TextField
                        label="교육사 코드"
                        value={managerCode}
                        onChange={(event) => {
                            setManagerCode(event.target.value)
                            if(error){
                                setError(false)    
                            }
                        }}
                    />
                </Grid>
                <Grid item style={{paddingTop: 20, paddingLeft: 20}}>
                    <Button
                        variant={'outlined'}
                        onClick={async () => {
                            const isAdd = await ProfileService.addManager(managerCode)
                            if(isAdd){
                                if(props.onChange){
                                    props.onChange()
                                    setManagerCode('')
                                }
                            } else {
                                setError(true)
                            }

                        }}
                    > 추가 </Button>
                </Grid>
            </Grid>
            <Box mt={1}>
                <b>이름#0000</b> 형식의 교육사코드를 검색해 주세요
            </Box>
            {error &&
            <Box mt={1}>
                <Typography variant="caption" style={{color: red[700]}}>
                    이미 등록되 교육사거나 입력하신 코드로 담당자를 찾을 수 없습니다.
                </Typography>
            </Box>
            }
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 800,
        margin: 'auto',

        '& .item': {
            padding : '20px 10px'
        }
    },
    back: {
        marginTop: 30,
        '&:hover': {
            backgroundColor: '#053288',
        }
    },
    small: {
        fontSize: 14,
        color: '#999',
    },
    remove: {
        color: '#ccc',
        '&:hover': {
            color: '#666'
        },
        marginLeft: 20
    },
    date: {
        fontSize: 14,
        color: '#999',
        fontFamily: 'Noto Sans KR',
    },
    text:{
        fontFamily: 'Noto Sans KR',
    },
    inline: {
        display: 'inline',
    },
    listRoot: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default Managers
