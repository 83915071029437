import React, {useCallback, useEffect, useRef, useState} from "react";
import {Box, Grid} from "@material-ui/core";

import BarChart from '@user-components/BarChart';
import Typography from "@material-ui/core/Typography";
import ExerciseService from "../../services/ExerciseService";

export default function BrainActiveTest(props) {
    
    const [brainActive, setBrainActive] = useState([])
    const measureCode = ['MA', 'MM' , 'MB']
    const measureName = ['개안', '폐안', '개안']
    
    const fetch = useCallback(async () => {
        // const data = await PrescriptionService.getBrainActive(3868)
        const data = await ExerciseService.getLastExerciseLog({
            measureHzType: 'measureBandTest', 
            measureCode: 'MA',
            createdAt: props.createdAt
        })
        
        const newData = [];
        measureCode.map((code) => {
            newData.push(data[code])
            return code
        })
        setBrainActive(newData)
    },[])

    useEffect(() =>  {
        fetch().then()
    }, [props.measureCount])

    return (
        <Box mt={5}>
            <Grid container spacing={3}>
                {brainActive.map((data, index) => {
                    const defaultData = [
                        data.band.ch1,
                        data.band.ch2
                    ]

                    return (
                        <Grid item key={`ba${index}`} xs={4}>
                            <BarChart
                                key={Math.random()}
                                data={new Array(6).fill(0)}
                                defaultData={defaultData}
                                showTopValue={true}
                                topValueMargin={20}
                                labels={['δ', 'θ', 'α', 'SMR', 'β', 'βM', 'βh']}
                                height={120}
                                useColorIndex={true}
                                showYAxes={true}
                                colors={['#60C3F7', '#A9D8AA']}
                                grouped={true}
                                groups={2}
                            />
                            <Box mt={2} mb={2} >
                                <Typography align={'center'}>{measureName[index]}</Typography>
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}
