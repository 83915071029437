import React, {useRef, useEffect} from 'react';
import {Box, Grid} from '@material-ui/core';

import BarChart from '@user-components/BarChart';
import LineChart from '@user-components/LineChart';
import ColorHelper from '@helper/ColorHelper';
import SocketProvider from '@user-provider/SocketProvider';
import AudioPlayer from '@user-pages/exercise/common/AudioPlayer'
import MeasureHelper from '@helper/MeasureHelper'
import ExerciseStatusBar from "../ExerciseStatusBar";

const { measureTypeToCode } = MeasureHelper
const MeasureHzType = 'feedback'

const Feedback = () => {
    
    const barChartCh1Ref = useRef()
    const barChartCh2Ref = useRef()

    const lineChartCh1Ref = useRef()
    const lineChartCh2Ref = useRef()

    const audioRef = useRef()
    
    useEffect(()=>{
        SocketProvider.connect(()=>{}, window, MeasureHzType)

        //측정 완료메세지를 받으면 
        SocketProvider.onMeasureStop = async () => {
        }

        SocketProvider.onMeasure = (data) => {
            lineChartCh1Ref.current.addData(data.time, data.ch1)
            lineChartCh2Ref.current.addData(data.time, data.ch2)
            
            const calCh1 = data.ch1BandReverse.map((value) => {
                if(50 < parseFloat(value)){
                    return 25
                } else {
                    return parseFloat(value)
                }
            })
            const calCh2 = data.ch2Band.map((value) => {
                if(50 < value){
                    return 25
                } else {
                    return value /2
                }
            })

            barChartCh1Ref.current.addData(data.time, calCh1)
            barChartCh2Ref.current.addData(data.time, calCh2)
            
            AvgCheckWithPlaySound(data.isHit)
        }
        
        return () => {
            SocketProvider.measureStop(true).then()
        }
    },[])

    const startExercise = (runningTime, measureType, measureCode) => {
        audioRef.current.setMode(measureType);
        
        SocketProvider.measureStart(runningTime, 
            0, 
            MeasureHzType, 
            measureType, 
            measureTypeToCode(measureType), 
            measureCode, 
            null , 
            null).then() 
    }
    
    const stopExercise = async () => {
        await SocketProvider.measureStop()
    }
    const AvgCheckWithPlaySound = (isHit) => {
        if(isHit){
            audioRef.current.play()
        }
    }
  
    return (
        <Box p={10}>
            <ExerciseStatusBar
                measureHzType={MeasureHzType}
                measureType={'rest'}
                measureCode={'A'}
                close={async () => {
                    await stopExercise()
                }}
                start={async (runningTime, measureType, measureCode) => {
                    await startExercise(runningTime, measureType, measureCode)
                }}
            />
            <Box className={'innerViewport'}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box mb={3}>좌측 원시뇌파</Box>
                        <LineChart ref={ lineChartCh1Ref } height={12} showXtick={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={3}>우측 원시뇌파</Box>
                        <LineChart ref={ lineChartCh2Ref } height={12} showXtick={true}/>
                    </Grid>
                </Grid>
                <Box mt={4} mb={1}>
                    <Grid container style={{width: '100%'}}>
                        <Grid item xs={6} style={{fontWeight: 'bold', fontSize: 22}}>Left Brain</Grid>
                        <Grid item xs={6} style={{textAlign: 'right',fontWeight: 'bold', fontSize: 22}}>Right Brain</Grid>
                    </Grid>
                </Box>
                <Grid container >
                    <Grid item xs={5} >
                        <Box mt="2">
                            <BarChart ref={ barChartCh1Ref }
                                data={ new Array(6).fill(0) }
                                defaultData={[]}
                                labels={['δ','θ','α','SMR','β', 'βM', 'βh'].reverse()}
                                height={240}
                                suggestedMax={25}
                                maxValue={50}
                                showYAxes={true}
                                yTicks={false}
                                fontSize={25}
                                yLablePosition={'right'}
                                colors={ ColorHelper.getColorsWith(51) }
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box css={{marginTop:-1, height: '100%'}}>
                            {
                                [25,20,15,10,5].map((value, index) => {
                                    return (
                                        <Box key={index}
                                              style={{  
                                                  textAlign: 'center',
                                                  marginTop: 1,
                                                  borderTop:1,
                                                  borderColor: '#aaa',
                                                  height: '18%',
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  justifyContent: "center",
                                                  fontSize: 20,
                                                  fontWeight: 'bold',
                                                  fontFamily: 'Lato, Noto Sans KR',
                                                  backgroundColor:'#efefef' }}
                                            >
                                            {value}
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                          <Box mt="2">
                            <BarChart ref={ barChartCh2Ref }
                                data={ new Array(6).fill(0) }
                                defaultData={[]}
                                labels={['δ','θ','α','SMR','β', 'βM', 'βh']}
                                height={240}
                                suggestedMax={25}
                                maxValue={50}
                                fontSize={25}
                                showYAxes={true}
                                yTicks={false}
                                colors={ ColorHelper.getColorsWith(51) }
                            />
                        </Box>
                    </Grid>
                </Grid>
                <AudioPlayer ref={ audioRef }/>
            </Box>
            {/*<Box>*/}
            {/*    <Grid container>*/}
            {/*        <Grid item xs={8}>*/}
            {/*            <CodeButtonGroup */}
            {/*                hzType={'feedback'}*/}
            {/*                defaultMeasureType={'rest'}*/}
            {/*                ref={codeGroupRef}*/}
            {/*                useDialogOpen={false}*/}
            {/*                updateMeasureType={updateMeasureType.bind(this)}*/}
            {/*                updateMeasureCode={updateMeasureCode.bind(this)}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={4} >*/}
            {/*            <FormControl component="fieldset">*/}
            {/*                <RadioGroup row aria-label="position" name="position" defaultValue="top">*/}
            {/*                    <FormControlLabel checked={1 === checkStep} control={<Radio color="primary" />} label="1개 처리" onClick={() => {updateCheckStep(1)}}/>*/}
            {/*                    <FormControlLabel checked={2 === checkStep} control={<Radio color="primary" />} label="2개 처리" onClick={() => {updateCheckStep(2)}}/>*/}
            {/*                    <FormControlLabel checked={3 === checkStep} control={<Radio color="primary" />} label="3개 처리" onClick={() => {updateCheckStep(3)}}/>*/}
            {/*                    <FormControlLabel checked={4 === checkStep} control={<Radio color="primary" />} label="4개 처리" onClick={() => {updateCheckStep(4)}}/>*/}
            {/*                </RadioGroup>*/}
            {/*            </FormControl>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Box>*/}
            {/*<Box>*/}
            {/*    <Box mt={3} >*/}
            {/*        <Typography>*/}
            {/*            좌 ,우 , 평균값(A), 이전 평균값(L)*/}
            {/*        </Typography>*/}
            {/*        <MvHeatMap ref={ mvHeatmapAvgRef } */}
            {/*                    xLabelLimitCount={30} */}
            {/*                    yLabels={['초', '좌', '우', 'A', 'L']}/>*/}
            {/*    </Box>*/}
            {/*    <Box mt={5}>*/}
            {/*        <MeasureBalanceFeedback ref={mvHeatmapBalanceRef} hzType={'feedback'} />*/}
            {/*    </Box>*/}
            {/*</Box>*/}

            {/*<Box mt={3}>*/}
            {/*    <Grid container>*/}
            {/*        <Grid item xs={12}>*/}
            {/*            <ComputeFeedbackDialog ref={settingDialogRef} hzType={'feedback'}/>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Box>*/}

            {/*<Box mt={5}>*/}
            {/*    <Typography>*/}
            {/*        좌뇌 측정 스펙트럼*/}
            {/*    </Typography>*/}
            {/*    <MvHeatMap ref={ mvHeatmapRef } xLabelLimitCount={30}/>*/}
            {/*</Box>*/}
        </Box>
    );
}



export default Feedback
