import React, {useEffect, useState, useCallback} from "react";
import { makeStyles } from '@material-ui/core/styles';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';

import CommonHelper from "@helper/CommonHelper";
import CustomerService from "@user-service/manage/CustomerService";
import CustomerSearchText from "./CustomerSearchText";
import {Box, Grid, Typography} from "@material-ui/core";

const CustomerSearchDialog = (props) => {
    const classes = useStyles();
    
    const { open, onClose, centerID, centerName, staffMemberID } = props
    const [ customers, setCustomers ] = useState([])
    
    const addCustomer = async (memberID, profileID, index) => {
        const res = staffMemberID 
            ? await CustomerService.addStaffCustomer(memberID, profileID, centerID, staffMemberID)
            : await CustomerService.addCustomer(memberID, profileID, centerID)
        
        if(res.success){
            const update = CommonHelper.clone(customers);
            
            update[index].customerID = res.ID
            update[index].centerID = centerID
            update[index].centerName = centerName
            
            if(staffMemberID){
                update[index].staffMemberID = staffMemberID    
            }
            
            setCustomers(update)
        }
        
    }
    
    const fetchCustomer = useCallback(async ({name , centerID}) => {
        const customers = await CustomerService.getCustomers({name, searchAll: true, limit: 30, centerID})
        setCustomers(customers)
    }, [])
    
    
    useEffect(() => {
        if(open){
            fetchCustomer({centerID}).then()    
        }
    }, [open, fetchCustomer, centerID])
    
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{width: 600}}>
                <CustomerSearchText keyup={async (name) => {
                    await fetchCustomer({name})
                }}/>
            </DialogTitle>
            <DialogContent style={{height: 700}}>
                <List className={classes.root}>
                    {customers.map((customer, index) => {
                        const { name, code, loginID, centerName, memberType } = customer
                        let isAssigned = customer.centerID === centerID
                        
                        // 이동하려는 센터의 회원과 변경할 회원의 센터 아이디가 동일해야합니다. 
                        if(staffMemberID){
                            // 센터가 다르면 변경하지 못합니다.
                            if(customer.centerID !== centerID ) return null
                            isAssigned = customer.staffMemberID === staffMemberID;
                            
                            //일반회원만 추가 가능
                            if('normal' !== memberType){
                                isAssigned = true
                            }
                        }
                        
                        
                        return (
                            <React.Fragment key={index}>
                                <ListItem >
                                    <ListItemAvatar>
                                        <Avatar variant="rounded" className={classes.large}>
                                            {customer.image
                                                ? <img src={require(`@images/profile/${customer.image}`)} alt={name}/>
                                                : <ImageIcon/>
                                            }
                                        </Avatar>
                                    </ListItemAvatar>
                                    
                                    <ListItemText primary={
                                        <Box style={{paddingLeft: 10}}>
                                            {code}
                                        </Box>    
                                    } secondary={
                                        <span style={{paddingLeft: 10}}>{loginID}</span>
                                    } />
    
                                    <ListItemSecondaryAction>
                                        <Grid container spacing={2}>
                                            {/*<Grid item style={{paddingTop: 13}}>*/}
                                            {/*    <Typography variant="caption" gutterBottom style={{color: '#999'}}>*/}
                                            {/*    {DateHelper.format(lastLoginDate, 'YYYY-MM-DD')}*/}
                                            {/*    </Typography>*/}
                                            {/*</Grid>*/}
                                            <Grid item style={{paddingTop: 13}}>
                                                <Typography variant="caption" gutterBottom style={{color: '#999'}}>
                                                {centerName ? centerName : '등록되지 않음'} <br/>
                                                {'normal' === memberType ? '일반회원' : '교육사'}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                {!isAssigned
                                                    ?<Button variant={'outlined'} onClick={async ()=>{
                                                        await addCustomer(customer.memberID, customer.profileID, index)
                                                    }}>{centerName ? '변경' : '추가'}</Button>
                                                    :<Button disabled>추가됨</Button>
                                                }        
                                            </Grid>
                                        </Grid>
                                        
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        )
                    })}
                </List>
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        
        backgroundColor: 'transparent',
        '& img': {
            width: '100%'
        }
    },
}));

export default CustomerSearchDialog