import React, {useEffect, useState, useCallback} from "react";
import {makeStyles, withStyles} from '@material-ui/core/styles';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import {Box, Grid, Typography} from "@material-ui/core";
import CurrentFFT from "../../mypage/measureCurrent/CurrentFFT";
import CurrentBalanceDot from "../../mypage/measureCurrent/CurrentBalanceDot";
import CurrentBalanceBar from "../../mypage/measureCurrent/CurrentBalanceBar";
import CurrentBalancePie from "../../mypage/measureCurrent/CurrentBalancePie";
import CurrentBrainActive from "../../mypage/measureCurrent/CurrentBrainActive";

import PrescriptionSelect from '@user-components/PrescriptionSelect';
import CustomerService from "@user-service/manage/CustomerService";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Message from "../../mypage/Message";
import CurrentExercise from "../../mypage/measureCurrent/CurrentExercise";
import CurrentCalendar from "../../mypage/measureCurrent/CurrentCalendar";
import CurrentPreLevel from "../../mypage/measureCurrent/CurrentPreLevel";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import CustomerBoard from "./CustomerBoard";
import PrescriptionExercise from "../../mypage/measureCurrent/PrescriptionExercise";
import CustomerProfile from "./CustomerProfile";
import CustomerSetting from "./CustomerSetting";
import DeletePrescriptionDialog from "../../mypage/measureCurrent/DeletePrescriptionDialog";
import AuthProvider from "../../../provider/AuthProvider";
import CenterService from "../../../services/manage/CenterService";
import RequestPrescript from "../measure/RequestPrescript";
import clsx from "clsx";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const CustomerMeasureDialog = (props) => {
    const classes = useStyles()
    
    const { profileID, profileName, onClose, open, lastLoginDate, memberID } = props
    const [ measureResult, setMeasureResult ] = useState(null)
    const [ isLoaded, setLoaded ] = useState(false)
    const [ tabValue, setTabValue] = useState('MeasureTab')
    const [ tabIndex, setTabIndex] = useState(0)
    const [ deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [ reloadCnt, setReloadCnt ] = useState(1)
    const [ isCenterOwner, setCenterOwner] = useState(false)
    
    const userAdminTab = ['MeasureTab', 'PrescriptionTab', 'BoardTab', 'ProfileTab', 'SettingTab']
    const userTab = ['MeasureTab', 'BoardTab', 'ProfileTab', 'SettingTab']

    const handleTab = (event, newValue) => {
        setTabIndex(newValue)
        setTabValue(AuthProvider.isSuperAdmin() ? userAdminTab[newValue] : userTab[newValue])
    }
    const handleClose = () => {
        setMeasureResult(null)
        if(onClose){
            onClose({
                reload: true
            })
        }
    }
    const fetchCustomer = useCallback(async () => {
        if(!profileID) return
        setLoaded(false)
        
        const res = await CustomerService.getCustomerLastMeasure(profileID)
        setMeasureResult(res.data)
        setLoaded(true)

    }, [profileID])
    
    const fetchCenterOwner = useCallback(async() => {
        const isOwner = await CenterService.isOwner()
        
        setCenterOwner(isOwner)
    },[])
    
    useEffect(() => {
        if(open){
            fetchCustomer().then()
        }
    }, [open, fetchCustomer])
        
    useEffect(() => {
        fetchCenterOwner().then()
    }, [fetchCenterOwner])
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'xl'}
        >
            <DialogTitle onClose={handleClose}>
                {measureResult &&
                    <Grid container spacing={2}>
                        <Grid item>
                            
                        </Grid>
                        <Grid item>
                            <PrescriptionSelect 
                                prescriptionID={measureResult.id} 
                                useCustomer={true} 
                                profileID={profileID}
                                reload={reloadCnt}
                                onChange={async (changePrescriptionID) => {
                                    const res = await CustomerService.getCustomerMeasure(profileID, changePrescriptionID)
                                    setMeasureResult(res.data)
                                }}
                            />
                        </Grid>
                        <Grid item>
                            {/*<Button*/}
                            {/*    startIcon={<HighlightOffIcon/>}*/}
                            {/*    style={{padding: '12.5px 21px', color: "red"}}*/}
                            {/*    variant={'outlined'} size={'large'} onClick={() => {*/}
                            {/*    setDeleteDialogOpen(true)*/}
                            {/*}}>삭제</Button>*/}
                            
                            <DeletePrescriptionDialog
                                open={deleteDialogOpen}
                                prescriptionID={measureResult.id}
                                onCancel={() => {
                                    setDeleteDialogOpen(false)
                                }}
                                onClose={() => {
                                    setReloadCnt(reloadCnt + 1)
                                    setDeleteDialogOpen(false)
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant={'subtitle1'}>{profileName}</Typography>
                            <div className={classes.small} >{lastLoginDate}</div>
                        </Grid>
                    </Grid>
                }
            </DialogTitle>
            <DialogContent style={{height: '100vh'}}>
                <Box mb={1}>
                    <Tabs
                        value={tabIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTab}
                    >
                        <Tab label="측정결과" />
                        {/*{AuthProvider.isSuperAdmin() &&*/}
                        {/*    <Tab label="뇌기능측정"/>*/}
                        {/*}*/}
                        {AuthProvider.isSuperAdmin() &&
                            <Tab label="처방기록"/>        
                        }
                        <Tab label="상담내용"  />
                        <Tab label="고객정보"  />
                        {(AuthProvider.isSuperAdmin() || isCenterOwner) &&
                        <Tab label="설정"/>
                        }
                    </Tabs>
                </Box>
                {'MeasureTab' === tabValue &&
                    <MeasureTab profileID={profileID} isLoaded={isLoaded} measureResult={measureResult}/>
                }
                {'requestPrescriptionTab' === tabValue &&
                    <RequestPrescriptionTab profileID={profileID} measureResult={measureResult}/>
                }
                {'PrescriptionTab' === tabValue &&
                    <PrescriptionTab profileID={profileID} isLoaded={isLoaded} measureResult={measureResult}/>    
                }
                {'BoardTab' === tabValue &&
                    <BoardTab profileID={profileID} prescriptionID={measureResult ? measureResult.id : 0 }/>    
                }
                {'ProfileTab' === tabValue &&
                    <ProfileTab profileID={profileID} onDeleted={() => {
                        handleClose()
                    }}/>
                }
                {'SettingTab' === tabValue &&
                    <SettingTab profileID={profileID} memberID={memberID}/>
                }
                
            </DialogContent>
        </Dialog>
    );
}

const ProfileTab = (props) => {
    return (
        <CustomerProfile {...props}/>
    )
}

const BoardTab = (props) => {
    return (
        <CustomerBoard {...props}/>
    )
}

const SettingTab = (props) => {
    return (
        // <CustomerProfile {...props}/>
        <CustomerSetting
            memberID={props.memberID} 
            profileID={props.profileID}/>
        
    )
}

const RequestPrescriptionTab = (props) => {
    const { measureResult } = props
    if(!measureResult) return <></>
    return (
        <Box p={3} pt={3} display={'flex'} justifyContent={'center'}>
            <Box mt={3}>
                <RequestPrescript
                    profileID={props.profileID}
                    simpleRender={true}
                />
            </Box>
        </Box>
    )
}

const PrescriptionTab = (props) => {
    const { measureResult } = props
    if(!measureResult) return <></>
    return (
        <Box p={3} pt={1}>
            <PrescriptionExercise 
                profileID={props.profileID}
                prescriptionID={measureResult.id}
                useMessageBox={false}
                simpleRender={true}
            />
        </Box>
    )
}

const MeasureTab = (props) => {
    const classes = useStyles()
    const { measureResult, profileID ,isLoaded } = props
    
    if(!measureResult && isLoaded) return <>
        <Box className={'sec-presc'} style={{height: '100%'}}>
            <Box className={clsx('innerbox', classes.empty)}>
                <img src={require('@images/brainLightIcon.png')} alt={'icon'}/>
                <br/>
                <div>측정 기록이 없습니다</div>
            </Box>
        </Box>
    </>
    
    if(!isLoaded) return <></>
    
    return (
        <Grid container spacing={2} alignItems={"center"} alignContent={"center"} style={{width: '100%'}}>
            <Grid item xs={12}>
                <CurrentFFT
                    measureResult={measureResult}
                    clickDisabled={true}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CurrentPreLevel type="BALANCE" measureResult={measureResult} />
                    </Grid>
                    <Grid item xs={6}>
                        <CurrentBalanceBar
                            measureResult={measureResult}
                            clickDisabled={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CurrentBalancePie
                            key={Math.random()}
                            measureResult={measureResult}
                            clickDisabled={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <CurrentBalanceDot
                    key={Math.random()}
                    measureResult={measureResult}
                    clickDisabled={true}
                />
            </Grid>
            <Grid item xs={6}>
                <CurrentBrainActive
                    key={Math.random()}
                    measureResult={measureResult}
                    clickDisabled={true}
                />
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*    <CurrentMeasureTest measureResult={measureResult} />*/}
            {/*</Grid>*/}
            <Grid item xs={12}>
                <CurrentExercise prescriptionId={measureResult.id}/>
            </Grid>
            <Grid item xs={12}>
                <CurrentCalendar prescriptionID={measureResult.id} profileID={profileID} title={'뇌기능운동 관리'}/>
            </Grid>
            <Grid item xs={12}>
                {/*<MeasureMemo {...measureResult}/>*/}
                <Box mt={2}>
                    <Typography variant={'subtitle1'} className={classes.cardTitle}>
                        담당교육사의 메세지
                    </Typography>
                    <Box p={5} pt={2}>
                        <Message profileID={profileID} prescriptionID={measureResult.id}/>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(() => ({
    title: {
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    small: {
        fontSize: '0.8rem',
        color: '#999'
    },
    cardTitle: {
        fontWeight: 'bold',
        paddingLeft: 25,
        marginBottom: 20
    },
    empty: {
        border:0,
        textAlign: 'center',
        width: '100%',
        height: '100% !important',
        color: '#333',
        fontFamily: `'Lato', 'Noto Sans KR', sans-serif`,
        '& h4': {
            fontSize: '28px !important',
            margin:0,
            height: '90px',
            color: '#999'
        },
        '& img': {
            width : '80px',
            marginTop: '50px'
        }
    }
}));

export default CustomerMeasureDialog