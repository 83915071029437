import React, {forwardRef, useEffect, useReducer} from "react";
import CssTextField from '@user-components/CssTextField';
import {Box, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import MemberService from '@user-service/MemberService'
import Collapse from '@material-ui/core/Collapse';
import clsx from "clsx";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";


const initState = {
    cellDisabled: false,
    isCellError: false,
    cellHelpText: '',
    cellNumber: '',
    certNumber: '',
    smsRemainTime : 30,
    timer: null,
    
    withCert : false,
    certHelpText: '',
    isCertError : false,
    confirmVerify: false,
}

function reducer(state, action){
    const { cellHelpText, cellNumber, isCellError, cellDisabled, timer } = action
    
    switch(action.type){
        case 'UPDATE_WITH_CERT':
            return {
                ...state,
                withCert: action.value
            }
        case 'UPDATE_VERIFY_CERT':
            return {
                ...state,
                certNumber: action.certNumber,
                isCertError: action.value,
                certHelpText: action.message,
                confirmVerify: action.confirmVerify,
                cellDisabled: action.confirmVerify ? true : state.cellDisabled
            }
        
        case 'UPDATE_CELL':
            return {
                ...state,
                cellHelpText,
                cellNumber,
                isCellError
            }
        case 'UPDATE_CELL_MESSAGE':
            return {
                ...state,
                cellHelpText,
                isCellError
            }
        case 'UPDATE_CELL_SEND_SMS': {
            return {
                ...state,
                cellDisabled,
                timer,
                withCert: action.withCert
            }
        }
        case 'UPDATE_TIMER': {
            let cellDisabled = state.cellDisabled
            if(0 === state.smsRemainTime - 1){
                clearInterval(state.timer)
                cellDisabled = false
            }
            
            if(state.confirmVerify){
                cellDisabled = true
            }
            
            return {
                ...state,
                smsRemainTime: cellDisabled ? state.smsRemainTime - 1 : 30,
                cellDisabled
            }
        }
        default:
    }
    return state
}

const PhoneTextField = forwardRef((props, ref) => {
    const classes = useStyles();
    
    const [state, dispatch] =  useReducer(reducer, initState)
    
    const { withCert, isCertError, certHelpText, confirmVerify, certNumber } = state
    const { cellDisabled, isCellError, cellHelpText, cellNumber, smsRemainTime } = state
    
    const { valid } = props
    
    const sendSms = async (cellNumber, withoutCheck = 'no') => {
        const newTimer = setInterval(() => {
            dispatch({
                type: 'UPDATE_TIMER'
            })
        }, 1000)

        const res = await MemberService.sendSms(cellNumber, withoutCheck)
        
        if(res.success){
            dispatch({
                type: 'UPDATE_CELL_SEND_SMS',
                cellDisabled: true,
                timer: newTimer,
                withCert: true
            })    
        } else {
            dispatch({
                type: 'UPDATE_CELL_MESSAGE',
                isCellError: true,
                cellHelpText: res.message,
            })
        }
    }
    
    useEffect(() => {
        if(props.isSend){
            sendSms(cellNumber, 'yes').then()    
        }
    },[props.isSend])
    
    useEffect(() => {
        try {
            valid(confirmVerify, cellNumber, certNumber)
        } catch {}
    }, [confirmVerify, cellNumber, certNumber])
    
    return (
        <Box position={'relative'}>
            <CssTextField
                label="휴대폰번호"
                inputRef={ref}
                fullWidth
                placeholder={'전화번호는 숫자만 입력하세요'}
                disabled={cellDisabled || props.disabled}
                onChange={(event)=>{
                    dispatch({
                        type: 'UPDATE_CELL',
                        isCellError: false,
                        cellNumber: event.target.value,
                        cellHelpText: '',
                    })
                    
                    if(props.change){
                        props.change(event.target.value)
                    }
                }}
                onKeyPress={async (event) => {
                    if('Enter' === event.key) {
                        if(10 > event.target.value.length){
                            dispatch({
                                type: 'UPDATE_CELL_MESSAGE',
                                isCellError: true,
                                cellHelpText: '전화번호를 정확히 입력해주세요',
                            })
                            return false
                        }
    
                        if(!props.hideRequestButton){
                            await sendSms(cellNumber)    
                        }    
                    }
                }}
                error={isCellError}
                helperText={cellHelpText}
            />

            {!props.hideRequestButton &&
            <Box position={'absolute'} right={0} top={0}>
                <Button
                    className={classes.requestButton}
                    disabled={cellDisabled || props.disabled}
                    onClick={async () => {
                        await sendSms(cellNumber)
                    }}
                >
                    {cellDisabled ? `${smsRemainTime}초` : '인증번호요청'}
                </Button>
            </Box>
            }
            
            <Collapse in={withCert}>
                <Box mt={2}>
                    <CssTextField
                        label="SMS로 발송된 번호를 입력하세요"
                        fullWidth
                        disabled={confirmVerify}
                        inputProps={{ maxLength: 6}}
                        onChange={async (event)=>{
                            const certNumber = event.target.value
                            if(6 === certNumber.length) {
                                const res = await MemberService.verifyCertNumber(cellNumber, certNumber)
                     
                                dispatch({
                                    type: 'UPDATE_VERIFY_CERT',
                                    value: !res.success,
                                    confirmVerify: res.success,
                                    message: res.message,
                                    certNumber
                                })
                                return true
                            }
                            
                            if( 6 > certNumber.length && isCertError){
                                dispatch({
                                    type: 'UPDATE_VERIFY_CERT',
                                    value: false,
                                    message: ''
                                })
                            }
                        }}
                        error={isCertError}
                    />
                    {'' !== certHelpText &&
                        <Box className={clsx(classes.message,{
                            [classes.confirmVerifyNumber] : confirmVerify,
                            [classes.failedVerifyNumber] : !confirmVerify
                        })}>
                            {certHelpText}
                        </Box>
                    }
                </Box>
            </Collapse>
            {/*<Box>*/}
            {/*    <FormControl component="fieldset" className={classes.formControl}>*/}
            {/*        <FormGroup>*/}
            {/*            <FormControlLabel*/}
            {/*                className={classes.label}*/}
            {/*                control={<Checkbox*/}
            {/*                    onChange={() => {}}*/}
            {/*                    className={classes.input}*/}
            {/*                    name="1" />}*/}
            {/*                label={<span className={classes.label}>인증하지 않음 ( 패스워드찾기등을 사용하실수 없습니다.) </span>}*/}
            {/*            />*/}
            {/*        </FormGroup>*/}
            {/*    </FormControl>*/}
            {/*</Box>*/}
        </Box>
    )
})

const useStyles = makeStyles(() => ({
   
    requestButton: {
        backgroundColor: '#8799AD',
        color: '#fff',
        height: 32,
        borderRadius: 10,
        marginTop: 20,
        paddingLeft: 10,
        '&:hover' : {
            backgroundColor: '#5D6C7D',
        },
        '&:disabled' : {
            backgroundColor: '#ddd',
            color: '#999'
        }
    },
    label: {
        fontSize: '0.8rem'
    },
    message: {
        marginTop: 5,
    },
    failedVerifyNumber: {
        color: 'red',
        fontSize: 14
    },
    
    confirmVerifyNumber: {
        color: '#1261BD',
        fontWeight: 500,
        fontSize: 14,
        backgroundImage: `url(${require('@assets/images/checkIcon.png')})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: 0,
        paddingLeft : 30,
    }
   
}));

export default PhoneTextField