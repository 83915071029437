import React, {useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import AudioPlayer from '@user-pages/exercise/common/AudioPlayer'
import { Box, Grid, Typography, Button} from '@material-ui/core';
import {useLocation} from "react-router";
import { useHistory } from "react-router-dom";
import TimeCount from '@user-pages/exercise/common/TimeCount'

import {CogBox} from "./CogBox"
import {CogNavigator} from "./CogNavigator" 


import LevelDialog from "./LevelDialog";
import CompleteDialog from "./CompleteDialog";

import ExerciseService from '@user-service/ExerciseService';
import MeasureService from '@user-service/MeasureService';
import TimerDialog from "./TimerDialog";

import { ViewChar } from "../common/Data"
import MemoryComplexData from "./MemoryComplexData";
import ClearFirstStepDialog from "../clearFirstStage/ClearFirstStepDialog";
import AuthProvider from "../../../provider/AuthProvider";
import _ from "lodash";

const queryString = require('query-string');


const MemoryComplex = (props) => {
    const stageRef = useRef()
    const location = useLocation()
    const qs = queryString.parse(window.location.search)
    const history = useHistory()
    
    const prescriptionID = location.state && location.state.prescriptionID ? location.state.prescriptionID : 0
    const showType = props.match.params.showType.indexOf('?') 
                    ? props.match.params.showType.split('?')[0] 
                    : props.match.params.showType

    let stageContainer
    const resizeHandler = () => {
        stageContainer.style.zoom = window.innerHeight / 1080
        
    }
    
    useEffect(() => {
        window.addEventListener('resize', resizeHandler)
        stageContainer = document.getElementById('stage')
        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    },[])
  
    return (
        <Box className="memory-complex" style={{position: 'relative', height: '100%', overflow: 'hidden'}}>
            <Stage ref={stageRef}
                   history={history}
                   showType={showType}
                   prescriptionID={prescriptionID}
                   programView={qs.programView}
                   programID={qs.programID}
                   measureType={location.state.measureType}
                   exerciseID={location.state.exerciseID}
                   exerciseName={location.state.exerciseName}
                   description={location.state.description}
                   onLoaded={() => {
                       setTimeout(() => {
                           resizeHandler()    
                       }, 50)
                   }}
            />
        </Box>
    );
}

export class Stage extends React.Component {
    container
    clientRect

    itemWidth = 176
    itemHeight = 190
    isReverse = false
    useReverse = false

    audioRef = React.createRef()
    timeCountRef = React.createRef()
    levelDialogRef = React.createRef()
    timerDialogRef = React.createRef()
    cogBoxRef = React.createRef()
    cogItemsRef = React.createRef()
    cogNavigationRef = React.createRef()
    completeDialogRef = React.createRef()

    currentStep = 0
    maxStep = 10
        
    randomIndex = []
    gameResults = []
    
    stageInfo
    
    constructor(props) {
        super(props)
      
        const programView = props.programView ? props.programView : 'view5'
        this.stageInfo = MemoryComplexData.stages[programView]

        this.state = {
            items: [0,1,2,3,4,5,6,7,8],
            history: props.history,
            showType: props.showType,
            isOver: false,
            prescriptionID: null !== props.prescriptionID ? props.prescriptionID :  0,
            programView: programView,
            programID: props.programID,            
            exerciseName: props.exerciseName,
            exerciseID: props.exerciseID,
            measureType: props.measureType,
            onLoaded: props.onLoaded,
            display: 'none',
            description: props.description,
            clearFirstStage: false,
        }
    }

    componentDidMount() {
        this.container = ReactDOM.findDOMNode(this)
        this.clientRect = this.container.getClientRects()[0]
        this.audioRef.current.loadEffect('memoryEgg')
        this.cogBoxRef.current.init(this.audioRef)
        
      
      
        this.levelDialogRef.current.open(
          this.state.showType, 
          this.state.programView,
          this.state.description
        )

        if(this.state.onLoaded){
            this.state.onLoaded()
        }
    }
    
    getHzType(){
        return this.state.programID
    }

  /**
   * 
   * @param stageIndex
   * @param delay
   * @param limitTime
   * @param view
   * @param mode , train or challenge
   * @returns {Promise<void>}
   */
    async startExercise(stageIndex, delay, limitTime,view, mode, challengeLevel ) {

      let challengeID
      if('challenge' === mode){
        const res = await ExerciseService.startExerciseChallenge(
          AuthProvider.getProfileID(),
          this.state.exerciseID,
          challengeLevel
        )
        challengeID = res.challengeID
      }
      
      this.setState({
            ...this.state,
            stageIndex,
            display: 'block',
            mode,
            challengeLevel,
            challengeID
        })
        
        this.delay = delay
        this.limitTime = limitTime
        this.view = view
      
        this.maxStep = 10
        // this.maxStep = this.stageInfo.map(m => m.viewBox !== 'firstStage').length
        // 매 시작시 Random shuffle
        if(ViewChar[this.view].sortCogItem){
            // ViewChar[this.view].data.sort(() => Math.random() - 0.5);    
        }
        this.timeCountRef.current.startWithCountdown(this.limitTime, () => {
            this.onCompleteWithDialog()
        })

      // 모드에 따라서 스테이지 방식을 변경합니다 
        let stageInfo = _.cloneDeep(this.stageInfo[stageIndex])
        
        if('challenge' === mode){
            const firstStageCount = this.stageInfo.filter(s => 'firstStage' === s.viewBox).length;
            this.currentStep += firstStageCount
  
            stageInfo = _.cloneDeep(this.stageInfo[this.currentStep])
            stageInfo.all = challengeLevel
        }
        stageInfo.mode = mode
    
        const showNavigation = 'firstStage' !== stageInfo.viewBox

        if(stageInfo.delay) stageInfo.delay = delay
        if(stageInfo.randomDelay) stageInfo.randomDelay = delay
    
        this.cogNavigationRef.current.setMaxStep(this.maxStep, showNavigation)
        this.cogBoxRef.current.next(stageInfo, this.delay, this.view)
        
        
        this.logID = await MeasureService.newMeasureLog({
            prescriptionID: this.state.prescriptionID,
            hzType: this.getHzType(),
            measureTime: limitTime
        })
        
    }


    onPlayEnded(randomIndex) {
        this.randomIndex = randomIndex
        // this.cogItemsRef.current.setSelectable(this.currentStep)
        
        // setTimeout(() => {
        //     this.onSelectEnded(this.randomIndex, 'forward')
        // }, 30 * 1000)
    }
    
    onFirstStageEnded(){
      this.setState({
        ...this.state,
        clearFirstStage: true
      })
    }
    
    onSelectEnded(isCorrect) {
        // const currentStageInfo = this.stageInfo[this.currentStep]
        const { mode, stageIndex, challengeLevel } = this.state
        const currentStageInfo = 'train' === mode 
          ? this.stageInfo[stageIndex]
          : this.stageInfo[this.currentStep]
        
        if('firstStage' !== currentStageInfo.viewBox){
          // 결과페이지에 넣기 위해서 문제 정보와 답변을 저장
          this.gameResults.push({
            // randomIndex: this.useReverse ? this.randomIndex.reverse() : this.randomIndex,
            randomIndex: this.randomIndex,
            selectedIndex: [],
            isCorrect,
          })
        }
        this.currentStep++
      
      setTimeout(() => {
          let firstStageCount = 0 
          if('challenge' == mode) {
            firstStageCount = this.stageInfo.filter(s => 'firstStage' === s.viewBox).length
          }
          
          const nowStep = this.currentStep - firstStageCount
          this.cogNavigationRef.current.next(nowStep, isCorrect)

          if(this.maxStep <= nowStep){
            this.onCompleteWithDialog()
            return false
          }

        let nextStageInfo = this.stageInfo[stageIndex]
        if('challenge' === mode){
          nextStageInfo = this.stageInfo[this.currentStep]
          nextStageInfo.all = challengeLevel
        }

        nextStageInfo.mode = mode
        this.cogBoxRef.current.next(nextStageInfo, this.delay)
        }, 1000)
    }
    
    isCorrect(selectedItems, direction){
        let items = JSON.parse(JSON.stringify(selectedItems))        
        if('reverse' === direction){
            items = items.reverse()
        }
        
        for(let i = 0; i < items.length; i++){
            if(items[i] !== this.randomIndex[i]){
                return false
            }
        }
        return true
    }

    onReset(){
        this.state.history.go(0)
    }
    
    onCompleteWithDialog(){
        this.timeCountRef.current.clearInterval()
        this.audioRef.current.effect('stop')
        
        this.setState({
            ...this.state,
            isOver: true,
        })

        this.onShowResult().then()    
    }
    getViewName() {
        switch(this.state.programView){
            case 'view1':
               return '숫자'
            case 'view3':
               return '그림'
            case 'view4':
               return '기호'
            default:
        }
        
    }
    async onShowResult(){
        const score = {
            time: this.timeCountRef.current.getTimePassedText(this.limitTime),
            timeCount: this.timeCountRef.current.getTimePassed(this.limitTime),
            correctCount: this.gameResults.filter(r => r.isCorrect).length,
            // count: this.cogItemsRef.current.getItem().length,
            count: this.maxStep,
            view: this.view,
            scoreText: '0점',
            mode: this.state.mode,
            challengeLevel: this.state.challengeLevel,
            challengeID: this.state.challengeID
        }

        if(0 < score.correctCount) {
            score.scoreText = Math.floor(score.correctCount / this.maxStep * 100 )+ '점'
        }

        score.measureType = localStorage.getItem('measure-test-type')
        score.viewText = this.getViewName()
      
        this.completeDialogRef.current.open(score, this.gameResults, ViewChar[this.view], this.state.challengeID)    

        await ExerciseService.setExerciseResult({
            measureHzType: this.getHzType(),
            measureLogID: this.logID,
            bestWave: 0,
            resultRate: score.correctCount,
            timeCount: score.timeCount,
            isEnd: 'yes',
            resultVar: JSON.stringify(score),
            measureType: localStorage.getItem('measure-test-type')
        })
    }
    
    onStop(){
        this.timeCountRef.current.clearInterval()
        this.cogBoxRef.current.stop()
        this.onShowResult().then()
    }
    
    render() {

        const renderView = ViewChar[this.view]
        const width = renderView && renderView.width ? renderView.width : 250
        const height = renderView && renderView.height ? renderView.height : 250
        // const spacing = renderView && renderView.spacing ? renderView.spacing : 0 
        const widthOffset = width * 0.25
        
        let cogItemStyle = {zIndex: 20, width: '100%', bottom: 40}
        // if(renderView && renderView.cogItemStyle) cogItemStyle = renderView.cogItemStyle
            
        let cogBoxStyle = {width:'100%', zIndex: 20, top: 'calc(50% - 150px)', height: '100%'}
        // if(renderView && renderView.cogBoxStyle) cogBoxStyle = renderView.cogBoxStyle


        return (
            <Box id={'stage'} style={{height: '100%', display: this.state.display, backgroundColor: '#F6FBFE',}}>
                <Box
                    position={'absolute'}
                    display="flex"
                    justifyContent="center"
                    zIndex={99}
                    width={'100%'}
                >
                    <CogNavigator 
                      ref={this.cogNavigationRef} 
                      maxStep={this.maxCount} 
                      view={this.state.view}
                    />
                </Box>

                
                <Grid container  style={{ position: 'relative', height: '100%', width: '100%'}} >
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            position="absolute"
                            style={cogBoxStyle}>
                                <CogBox
                                  width={width} height={height} widthOffset={widthOffset}
                                  audioRef={this.audioRef}
                                  ref={this.cogBoxRef}
                                  view={this.state.programView}
                                  onSelectEnded={this.onSelectEnded.bind(this)}
                                  onPlayEnded={this.onPlayEnded.bind(this)}
                                  onFirstStageEnded={this.onFirstStageEnded.bind(this)}
                                />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            position="absolute"
                            style={cogItemStyle}>
                            <Box>
                                <Box mb={1} ml={1}>
                                    <Grid container >
                                        <Grid item xs={12}>
                                            {this.state.challengeLevel && 
                                            <span>Lv.{this.state.challengeLevel} 두뇌계발</span>
                                            }
                                            <TimeCount  ref={this.timeCountRef} variant={"h5"} textColor={'#999'}/>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <AudioPlayer ref={ this.audioRef } mode={'balance'}/>
                <LevelDialog ref={this.levelDialogRef} 
                             onStart={this.startExercise.bind(this)}  
                             history={this.state.history}
                             programID={this.state.programID}
                             exerciseID={this.state.exerciseID}
                             programView={this.state.programView}
                             exerciseName={this.state.exerciseName}
                             measureType={this.state.measureType}/>
                <TimerDialog 
                    ref={this.timerDialogRef} 
                    timeSec={3}
                    title={`기억력 프로그램 (${this.getViewName()})`}         
                    onEnd={() => {
                        const delay = 1000
                        const limitTime = 480
                        this.startExercise(this.useReverse, delay, limitTime, this.state.programView).then()
                    }}/>
                <CompleteDialog
                    ref={this.completeDialogRef} 
                    history={this.state.history}
                    showType={this.state.showType}
                    onReset={this.onReset.bind(this)}
                    exerciseName={this.state.exerciseName}
                    exerciseID={this.state.exerciseID}
                    measureType={this.state.measureType}
                    description={this.state.description}
                />
                <ClearFirstStepDialog 
                  open={this.state.clearFirstStage}
                  startExercise={() => {
                    this.state.history.go(0)
                  }}
                />
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    position="absolute"
                    right={10}
                    top={10}
                    zIndex={99}>
                    <Grid container justify="center" spacing={1}>
                        <Grid item>
                            <Button
                                style={{fontSize: '1.2rem', height:54, border: '3px solid #829ABC', color: '#829ABC', width: 160, borderRadius: 40, backgroundColor: '#F1F4F9', marginRight: 39,marginTop:25 }}
                                onClick={this.onStop.bind(this)}>
                                종료
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            
        )
    }
}




export default MemoryComplex
