import { useHistory } from "react-router-dom";
import {Box, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import TestService from "../../services/TestService";

export default function Exercise(props) {
    const classes = useStyles()
    const history = useHistory()
    
    const { prescriptionID, isExpired, onExpired } = props
    const measureTypesCount = props.measureTypesCount

    const measureType = localStorage.getItem('measure-test-type')
    const currentPage = localStorage.getItem('current-page')
    
    let time = props.defaultCount
    time += 'count' === props.defaultCountUnit ? '회':'분'
  
    let color = '#29D99C'
    let clsStyle = classes.exerciseBox
    if('measureTest' === currentPage){
        color = TestService.getColor(measureType, props.id)
        if('measure' === measureType) {
            time = '검사'
            clsStyle = classes.exerciseBox1
        }
        if('develop' === measureType || 'train' === measureType) {
            time = '계발'
        }
        
    }
    
    // console.log(measureTypesCount, props.onNeedTicket)
    return (
        <Box className={clsStyle}
            onClick={() => {
                if(isExpired) {
                    if(onExpired) onExpired()
                    return
                } 
                
                if(measureTypesCount && 0 === measureTypesCount.count){
                    if(props.onNeedTicket){
                        props.onNeedTicket()
                        return
                    }
                }
                
                history.push( {
                    pathname: `/exercise${props.programUrl}`,
                    state: {
                        prescriptionID,
                        exerciseName: props.name,
                        measureType: props.measureType ? props.measureType : 'measure',
                        exerciseID: props.id,
                        description: props.description,
                        programID: props.programID
                    }
                })
            }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Box display={'flex'} justifyContent={'center'}>
                        <img src={require(`@images/${props.image}`)}
                             className={classes.image}
                             alt={props.name}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Grid container direction={'column'} >
                        <Grid item>
                            <Box 
                                className={clsx(classes.exerciseCount)}
                                style={{backgroundColor: color}}
                            >
                                {time}
                            </Box>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.exerciseTitle}>
                                {props.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Box className={classes.exerciseDesc}>
                                {(props.description || '').replace(/<br\/>/, '').split('\n').map(function(item, key) {
                                    return (
                                        <span key={key}>{item}<br/></span>
                                        )
                                    })}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    image:{
        width: '100%',
        '@media (max-width: 600px)': {
            width: '50%',
            marginBottom: 20,
        },
        
    },
    exerciseCount: {
        borderRadius: 30,
        color: '#fff',
        backgroundColor: '#29D99C',
        maxWidth: 70,
        textAlign: 'center',
        fontSize: 18,
        padding: `3px 10px`,
        fontWeight: 'bold',
        '@media (max-width: 780px)': {
            fontSize: '0.8rem',
        },
    },

    exerciseTitle: {
        fontSize: 28,
        fontWeight: 700,
        '@media (max-width: 780px)': {
            marginTop: 10,
            fontSize: '1rem'
        },
    },
    exerciseDesc: {
        fontSize: '1rem',
        maxWidth: 240,
        marginTop: 20,
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: 2,
        color: '#000',
        wordBreak: "break-all",
        overflow: "hidden"
    },
    exerciseBox: {
        width: '100%',
        padding: `42px 20px`,
        backgroundColor: '#F5F9FE',
        borderRadius: 30,
        border: `1px solid #D6E1F1`,
        cursor: 'pointer',
        '@media (max-width: 780px)': {
            padding: `20px 20px`,
        },
        '&:hover' : {
            backgroundColor: '#E1E5EC'
        }
    },
    exerciseBox1: {
        width: '100%',
        padding: `42px 20px`,
        backgroundColor: '#DBE6FF',
        borderRadius: 30,
        border: `2px solid #AFC8F6`,
        cursor: 'pointer',
        '@media (max-width: 780px)': {
            padding: `20px 20px`,
        },
        '&:hover' : {
            backgroundColor: '#AFC8F6'
        }
    },
    
}));