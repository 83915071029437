import React, {createRef} from 'react';
import {Box, Button, ButtonGroup, Grid, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ExerciseService from "../../../services/ExerciseService";
import TestService from "../../../services/TestService";
import clsx from "clsx";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import DateHelper from "../../../helper/DateHelper";


export default class CompleteDialog extends React.Component {
    scoreTable = [
        {startAge:20, endAge: 20, startScore:10, endScore: 10},
        {startAge:20, endAge: 20, startScore:9, endScore: 9},
        {startAge:30, endAge: 30, startScore:8, endScore: 8},
        {startAge:40, endAge: 40, startScore:7, endScore: 7},
        {startAge:50, endAge: 50, startScore:6, endScore: 6},
        {startAge:60, endAge: 60, startScore:5, endScore: 5},
        {startAge:70, endAge: 70, startScore:4, endScore: 4},
        {startAge:80, endAge: 80, startScore:3, endScore: 3},
    ] 
    
    printRef = createRef()
  
    constructor(props) {
        super();
        this.state = {
            open: false,
            answers: [],
            history: props.history,
            onReset: props.onReset,
            showType: props.showType,
            gameResults: [],
            viewChar: [],
            exerciseName: props.exerciseName,
            measureType: props.measureType,
            score: {
                time: '00분 00초',
                correctCount: 0,
                count: 0,
                view: 'view1'
            },
            maxStep: 9,
            backgroundColor: TestService.getColorName(props.measureType, props.exerciseID),
            answerType: 'my-answer' // my-answer or correct-answer
        }
    }
    
    open(score, gameResults, sortedViewChar) {
        const correctCount = score && score.correctCount ? score.correctCount : 0
        
        this.setState({
            ...this.state,
            open: true,
            gameResults,
            score: {
                ...score,
                isPass: ExerciseService.getExerciseResultByScoreTable(this.scoreTable, correctCount)
            },
            maxStep: gameResults.maxStep,
            viewChar: sortedViewChar
        })
    }
    
    close() {
       try{
           // this.state.history.goBack()
           this.state.history.push('/measureTest')
       } catch(e) {}
    }

    reset(){
        this.setState({
            ...this.state,
            open: false,
        }, () => {
            if(this.state.onReset){
                this.state.onReset()
            }
        })
    }
    
    
 
    render() {
        const { score,  exerciseName, viewChar} = this.state
        const scoreTable = JSON.parse(JSON.stringify(this.scoreTable)).reverse()

        let isPass = score.isPass ? '성공' : '아니오'
        if('develop' === this.state.measureType){
            isPass = score.correctCount === score.count ? '성공' : '아니오'
        }
        
        // const totlaCount = 9 === score.count ? 10 : score.count
        const renderViewChar = viewChar.data
      
        return (
            <Dialog
                disableBackdropClick
                open={this.state.open}
                aria-labelledby="responsive-dialog-title"
                fullWidth
                maxWidth="lg"
                className={clsx(['level-dialog print-landscape', this.state.measureType, this.state.backgroundColor])}
            >
                <div ref={this.printRef}>
                  <DialogTitle id="responsive-dialog-title" >
                    <Box position={'absolute'} style={{left: 40, top: 20, display: 'none'}} className={'print-show'}>
                      <img src={require(`@assets/images/bh_logo.png`)} alt={'logo'} style={{height: 44}}/>
                    </Box>
                    <div className={'title-left'}>
                      {exerciseName} {'develop' === this.state.measureType  ? '증강 프로그램' : '검사'}
                    </div>
                    <Box position={'absolute'} style={{right: 10, top: 20}} className={'print-hide'}>
                      {/*<Button*/}
                      {/*    className={'btn-print'}*/}
                      {/*    startIcon={<PrintIcon/>}*/}
                      {/*    style={{padding: '7.5px 21px', marginRight: 10}}*/}
                      {/*    variant={'outlined'} size={'large'}*/}
                      {/*    onClick={() => {*/}
                      {/*      PrintHelper.print(this.printRef)*/}
                      {/*    }}*/}
                      {/*>*/}
                      {/*    인쇄*/}
                      {/*  </Button>*/}
                    </Box>
                    <Box position={'absolute'}  
                       style={{right: 40, top: 30, color: '#000', fontWeight: "normal", fontSize: '1rem', display: 'none'}} 
                       className={'print-show'}>
                      출력일: {DateHelper.now('YYYY년 MM월 DD일')}
                    </Box>
                  </DialogTitle>
                  <DialogContent>
                      <Grid container spacing={3} style={{width: '100%'}}>
                          <Grid item xs={12} md={6}>
                              <Box>
                                  <Box >  
                                      <Typography className={'title'}>{'develop' === this.state.measureType  ? '두뇌계발' : '검사'} 결과</Typography>
                                      <Grid container spacing={2} className={'result-wrap'}>
                                          <Grid item xs={4}>
                                              <Paper elevation={0} className={'result-box'}>
                                                  <Typography>{'develop' === this.state.measureType  ? '측정' : '검사'}시간</Typography>
                                                  <Typography className={'score'}>{score.time}</Typography>
                                              </Paper>
                                          </Grid>
                                          <Grid item xs={4}>
                                              <Paper elevation={0} className={'result-box'}>
                                                  <Typography>성공</Typography>
                                                  <Typography className={'score'}>{score.correctCount} 개</Typography>
                                              </Paper>
                                          </Grid>
                                          <Grid item xs={4}>
                                              <Paper elevation={0} className={'result-box'}>
                                                  <Typography>점수 </Typography>
                                                  <Typography className={'score'}>{score.scoreText}</Typography>
                                              </Paper>
                                          </Grid>
                                          {/*<Grid item xs={3}>*/}
                                          {/*    <Paper elevation={0} className={'result-box'}>*/}
                                          {/*        <Typography>평가결과</Typography>*/}
                                          {/*        <Typography className={'score'}>{isPass}</Typography>*/}
                                          {/*    </Paper>*/}
                                          {/*</Grid>*/}
                                      </Grid>
                                  </Box>
                                  <Box mt={3} mb={3} className={'print-hide'}>
                                      <Button fullWidth
                                              variant={"contained"}
                                              size={'large'}
                                              color={'primary'}
                                              style={{fontSize:'1.1rem'}}
                                              onClick={this.reset.bind(this)}>
                                          {'develop' === this.state.measureType  ? '다시하기' : '재검사하기'}
                                      </Button>
                                      <Box mt={1}/>
                                      <Button fullWidth
                                              variant={"outlined"}
                                              size={'large'}
                                              style={{fontSize:'1.1rem'}}
                                              onClick={this.close.bind(this)}>
                                          종료하기
                                      </Button>
                                  </Box>
                                  <Box mt={2}>
                                      <Typography style={{fontWeight: "bold"}}>평가기준</Typography>
                                  </Box>
                                  <Box mt={2}>
                                      <TableContainer component={Paper}>
                                          <Table aria-label="table" size={'small'} className={'level-table'}>
                                              <TableHead>
                                                  <TableRow>
                                                      <TableCell>연령 기준</TableCell>
                                                      <TableCell>개수</TableCell>
                                                      <TableCell>평균</TableCell>
                                                  </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                  {scoreTable.map((table, index) => {
                                                      const {startAge, startScore} = table
                                                      
                                                      return (
                                                          <TableRow key={`table-${index}`}> 
                                                              <TableCell scope="row">{startAge}대 {7 === index  ? '이하' : '이상'}</TableCell>
                                                              <TableCell>{startScore}개 이상</TableCell>
                                                              <TableCell>{startScore * 10}점 {7 === index  ? '' : '이상'}</TableCell>
                                                          </TableRow>
                                                      )
                                                  })}
                                              </TableBody>
                                          </Table>
                                      </TableContainer>
                                  </Box>
                                  
                              </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                              <Typography className={'title'}>평가 결과</Typography>
                              <Box mb={1}>
                                  <ButtonGroup size="small" style={{width: '100%'}}>
                                      <Button startIcon={<DoneOutlineIcon/>}
                                              color={'my-answer' === this.state.answerType ? 'primary' : ''}
                                              variant={'my-answer' === this.state.answerType ? 'contained' : 'outlined'}
                                              onClick={() => {
                                                this.setState({
                                                    ...this.state,
                                                    answerType : 'my-answer'
                                                })
                                              }}
                                              style={{width: '50%'}}>내가 선택한 답</Button>
                                      <Button startIcon={<DoneOutlineIcon/>}
                                              color={'correct-answer' === this.state.answerType ? 'primary' : ''}
                                              variant={'correct-answer' === this.state.answerType ? 'contained' : 'outlined'}
                                              onClick={() => {
                                                  this.setState({
                                                      ...this.state,
                                                      answerType : 'correct-answer'
                                                  })
                                              }}
                                              style={{width: '50%'}}>정답</Button>
                                  </ButtonGroup>
                              </Box>
                              <List style={{paddingTop: 0}}>
                                  {
                                      new Array(score.count ? score.count : 0).fill(0).map((_, index) => {
                                          let randomIndex, selectedIndex, isCorrect = false , resultText = '', textColor = 'black'
                                          const result = this.state.gameResults[index]
                                          if(result){
                                              randomIndex = result.randomIndex 
                                              selectedIndex = result.selectedIndex
                                              isCorrect= result.isCorrect
                                          }
                                          if(isCorrect){
                                              resultText = '성공'
                                              textColor = '#333'
                                          } else {
                                              resultText = '실패'
                                              textColor = '#bbb'
                                          }
                                          if(!randomIndex) {
                                              resultText = '없음'
                                              textColor = '#999'
                                          } 
                                          
                                          const currentNumber = index + 1
                                          return(
                                              <React.Fragment key={index}>
                                                  <ListItem>
                                                      <Grid container>
                                                          <Grid item style={{paddingRight: 10, lineHeight:`46px`, width:90}}>
                                                              <b style={{paddingRight: 10}}>{currentNumber}</b> 
                                                              <span style={{color: textColor, fontWeight: 'bold'}}>{resultText}</span>
                                                          </Grid>
                                                          {new Array(currentNumber).fill(0).map((val, subIndex) => {
                                                              let backgroundColor = '#fff'
                                                              let grayscale = '0%';
                                                              if(randomIndex ){
                                                                  if(isCorrect){
                                                                      backgroundColor = '#58A25B'        
                                                                  } else{
                                                                      backgroundColor = '#EFEFEF'
                                                                      grayscale = '100%';
                                                                  }
                                                              }  
                                                              
                                                              //my-answer = selectedIndex, correct-answer = randomIndex 
                                                              const viewIndex = 'my-answer' === this.state.answerType ? (selectedIndex || [])[subIndex] :(randomIndex || [])[subIndex]
                                                              if('correct-answer' === this.state.answerType){
                                                                  backgroundColor = randomIndex ? '#58A25B' : '#fff'
                                                                  grayscale = '0%';
                                                              }
                                                              
                                                              return (
                                                                  <Grid item key={`sub-${index}-${subIndex}`}>
                                                                      <Box style={{
                                                                          width: 45,
                                                                          height: 45,
                                                                          borderRadius: '50%',
                                                                          border: 4,
                                                                          borderStyle: 'solid',
                                                                          borderColor: '#efefef',
                                                                          backgroundSize: 'contain',
                                                                          backgroundRepeat: 'no-repeat',
                                                                          backgroundPosition: 'center',
                                                                          color: randomIndex ? '#000' : '#efefef',
                                                                          backgroundColor: backgroundColor
                                                                      }}>
      
                                                                          {'text' === viewChar.type &&
                                                                          <Typography
                                                                              style={{
                                                                                  transitionDuration: '0.5s',
                                                                                  textAlign: 'center',
                                                                                  fontWeight: 'bold',
                                                                                  lineHeight: '36px'
                                                                              }}
                                                                          >
                                                                              {randomIndex ? renderViewChar[viewIndex] : ''}
                                                                          </Typography>
                                                                          }
  
                                                                          {'image' === viewChar.type && randomIndex &&
                                                                          <img style={{
                                                                              width: '100%',
                                                                              height:'100%',  
                                                                              borderRadius: '50%',
                                                                              filter: `grayscale(${grayscale})`
                                                                          }}
                                                                               src={renderViewChar[viewIndex]}
                                                                               alt={index}  />
                                                                          }
                                                                      </Box>
                                                                  </Grid>
                                                              )
                                                          })}
                                                      </Grid>
                                                  </ListItem>
                                                  {/*<Divider />*/}
                                              </React.Fragment>
                                          )
                                      })
                                  }
                              </List>
                          </Grid>
                      </Grid>
                  </DialogContent>
                </div>
            </Dialog>
        );
    }
}
