import React, {useCallback, useReducer} from 'react';
import { useHistory} from "react-router-dom";

import MemberService from '@user-service/MemberService'

import { makeStyles } from '@material-ui/core/styles';
import {Box, Button, IconButton, Typography, Grid} from '@material-ui/core';

import PasswordTextField from "./PassTextField";
import PhoneTextField from "./PhoneTextField";
import clsx from "clsx";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CssTextField from "../../components/CssTextField";

const initState = {
    ableNext: false,
    checkedLoginIDCert: false,
    
    profileName: '',
    loginID: '',
    cellNumber: '',
    password: '',
    passwordConfirm: '',
    
    isSendSms: false,
    isValidLoginID: false,
    isValidPassword: false,
    
    canUpdatePassword: false
    
}

function reducer(state, action){
    let newStatus
    switch(action.type){
        case 'UPDATE_LOGIN_ID':
            newStatus = {
                ...state,
                loginID: action.loginID,
                isValidLoginID:  action.loginID.length > 4,
            }
            break
        case 'UPDATE_PASSWORD':
            newStatus = {
                ...state,
                password: action.password,
                // isValidPassword:  action.isValid,
            }
            break
        case 'UPDATE_PASSWORD_CONFIRM':
            newStatus = {
                ...state,
                passwordConfirm: action.passwordConfirm,
                // isValidPassword:  action.isValid,
            }
            break
        case 'UPDATE_CELL_NUMBER':
            newStatus = {
                ...state,
                cellNumber:  action.cellNumber,
                isValidCertPhone:  action.isValid,
                certNumber: action.certNumber,
            }
            break
        case 'UPDATE_SEND_SMS':
            newStatus = {
                ...state,
                isSendSms:  action.isSend,
            }
            break
        case 'UPDATE_RESET_PASSWORD':
            newStatus = {
                ...state,
                checkedLoginIDCert:  action.isValid
            }
            break
            
        default:
            break
    }
    
    if(newStatus.isValidLoginID &&
        newStatus.isValidCertPhone
    ) {
        newStatus.checkedLoginIDCert = true
    }
    
    if(0 < newStatus.password.length && newStatus.password === newStatus.passwordConfirm){
        newStatus.canUpdatePassword = true
    } else {
        newStatus.canUpdatePassword = false
    }
    
    return newStatus
}

const ResetPassword = () => {
    const classes = useStyles();
    
    const [state, dispatch]= useReducer(reducer, initState)
    const { certNumber, checkedLoginIDCert, cellNumber, loginID, isSendSms, canUpdatePassword, password } = state 
    const history = useHistory()
    
    const updateValidData = useCallback((param) => {
        dispatch({
            ...param,
        })
    }, [state])
    const passwordHandler = async () => {
        const success = await MemberService.updatePassword(loginID, password, cellNumber, certNumber)
        
        const message = success ? '비밀번호가 변경되었습니다' : '인증정보가 잘못되었습니다.\n패스워드 찾기를 다시 시작해주세요'
        window.alert(message)
        history.goBack()
    }
    
    const loginIDHandler = async () => {
        const isValid = await MemberService.checkLoginIDCellPhone(loginID, cellNumber)
        if(!isValid){
            window.alert('일치하는 정보가 없습니다 확인 후 다시 시도해보세요')
            return
        }
        
        dispatch({
            type: 'UPDATE_SEND_SMS',
            isSend: isValid
        })
    }
    
    let canContinue = false
    if(4 < loginID.length &&  4 < cellNumber.length){
        canContinue = true
    }
    
    return (
        <React.Fragment>
            <Box className={classes.background} />
            <Box className={clsx(classes.logo)}>
                <a href={"https://brhw.kr"}>
                    <img src={"https://brhw.kr/images/logo.png"} alt={'브레인헬스 로고'}/>
                </a>
            </Box>
            <Box className={classes.loginBoxWrap}>
                <Box className={classes.loginBox}>
                    <Box className={classes.back} onClick={() => { 
                        history.goBack()
                    }}>
                        <IconButton aria-label="delete">
                            <ArrowBackIcon style={{fontSize: 50}}/>
                        </IconButton>
                    </Box>
                    <Typography className={classes.title}>
                        <span style={{fontWeight: 600}}>비밀번호 찾기</span>
                    </Typography>
                    
                    <Box style={{marginTop: 40, marginBottom: 55}}>
                        <Grid container direction={'column'} spacing={4}>
                            <Grid item xs={12}>
                                <CssTextField
                                    label="아이디"
                                    fullWidth
                                    disabled={checkedLoginIDCert}
                                    placeholder={'아이디를 입력하세요'}
                                    onChange={ async (event) => {
                                        updateValidData({
                                            type: 'UPDATE_LOGIN_ID',
                                            loginID: event.target.value
                                        })
                                    }}
                                    inputProps={{
                                        form : {
                                            autocomplete: 'off',
                                        },
                                        style: {textTransform: 'lowercase'}
                                    }}
                                />
                            </Grid>
                          
                            <Grid item xs={12}>
                                <PhoneTextField 
                                    disabled={checkedLoginIDCert}
                                    hideRequestButton={true}
                                    isSend={isSendSms}
                                    change={(value) => {
                                        updateValidData({
                                            type: 'UPDATE_CELL_NUMBER',
                                            cellNumber: value,
                                        })
                                    }}
                                    valid={(isValid, value, certNumber) => {
                                        updateValidData({
                                            type: 'UPDATE_CELL_NUMBER',
                                            cellNumber: value,
                                            certNumber,
                                            isValid
                                        })
                                    }}
                                />
                            </Grid>
                            {!checkedLoginIDCert &&
                            <Grid item xs={12} style={{marginTop: 30}}>
                                <Button
                                    onClick={loginIDHandler}
                                    disabled={!canContinue}
                                    className={clsx(classes.registerButton, {
                                        [classes.nextAble]: canContinue,
                                        [classes.next]: !canContinue
                                    })}>확인</Button>
                            </Grid>
                            }
                            
                            {checkedLoginIDCert &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Typography className={classes.subtitle}>
                                        변경 할 비밀번호를 입력하세요
                                    </Typography>
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <PasswordTextField
                                        placeholder={'비밀번호 6자리'}
                                        valid={(isValid, value) => {
                                            updateValidData({
                                                type: 'UPDATE_PASSWORD',
                                                password: value,
                                                isValid
                                            })
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <PasswordTextField
                                        label={'비밀번호 확인'}
                                        placeholder={'비밀번호 확인'}
                                        valid={(isValid, value) => {
                                            updateValidData({
                                                type: 'UPDATE_PASSWORD_CONFIRM',
                                                passwordConfirm: value,
                                                isValid
                                            })
                                        }}
                                    />
                                </Grid>
                                <Grid container style={{marginTop: 30}}>
                                    <Grid item xs={12}>
                                        <Button
                                            onClick={passwordHandler}
                                            disabled={!canUpdatePassword}
                                            className={clsx(classes.registerButton, {
                                                [classes.nextAble]: canUpdatePassword,
                                                [classes.next]: !canUpdatePassword
                                            })}>비밀번호 변경</Button>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                            }
                        </Grid>
                    </Box>
                    
    
                </Box>
            </Box>
        </React.Fragment>
    );
}


const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center"
    },
    back: {
        position: 'absolute',
        top: 10,
        left: 30,
        cursor: 'pointer'
    },
    logo: {
        position: 'fixed',
        left: 30 ,
        top: 22,
        zIndex: 0,
        width: `100%`,
    },
    title: {
        fontSize: 40,
        fontFamily: 'Lato, Noto Sans KR',
        lineHeight: '45px',
        fontWeight: 300,
        color: '#333',
        letterSpacing: '-2px'
    },
    subtitle: {
        fontSize: 28,
        fontFamily: 'Lato, Noto Sans KR',
        lineHeight: '35px',
        fontWeight: 600,
        color: '#333',
        letterSpacing: '-2px',
        marginTop: 30
    },
    requestButton: {
        backgroundColor: '#8799AD',
        color: '#fff',
        height: 35,
        borderRadius: 10,
        marginTop: 20,
        paddingLeft: 10,
        '&:hover' : {
            backgroundColor: '#5D6C7D',
        }
    },
    buttonIcon: {
        position: 'absolute',
        left: 20,
        top: 'center',
        zIndex: 1,
        width: 32,
        height: 32
    },
    buttonIconRight: {
        position: 'absolute',
        right: 20,
        top: 'center',
        zIndex: 1,
        width: 32,
        height: 32
    },
    registerButton: {
        position: 'relative',
        width: '100%',
        height: 60,
        borderRadius: 10,
        color: '#333',
        fontSize: 19,
        '&:hover': {
            backgroundColor: '#04308E'
        }
    },
    phone: {
        background: '##fff',
        color: '#333',
        border: `1px solid #0052C1`
    },
    next: {
        backgroundColor: '#ddd',
        color: '#999'
    },
    nextAble: {
        backgroundColor: '#0053BF',
        color: 'white'
    },
    formBox: {
        marginBottom: 60
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
        backgroundImage: `url(${require(`@assets/images/simplebg01.jpg`)})`,
        backgroundSize: 'cover'
    },
    loginBoxWrap: {
        top: 110,
        left: `calc(50% - 250px)`,
        // transform: `translate(-50%, -50%)`,
        position: 'absolute',
        width: 500,
        minHeight: 670,
        zIndex: 2,
    },
    loginBox: {
        borderRadius: 10,
        backgroundColor: '#fff',
        padding: 50,
        width: '100%',
        paddingTop: 95,
        marginBottom: 110
    },
    bottom: {
        position: "absolute",
        bottom: 30,
        width: 500,
        color: '#333',
        fontSize: 14,
        textAlign: 'center'
    },
    textField: {
        backgroundColor: '#ddd'
    },
    explain: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        fontSize: 14,
        marginTop: 20,
        color: '#333',
    },
    hide: {
        display: 'none'
    }
}));



export default ResetPassword
