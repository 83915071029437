import React, {useCallback, useEffect, useState} from 'react';
import {Paper, Grid, Box, Typography, Link} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PrescriptionService from '@user-service/PrescriptionService'
import {Line} from 'react-chartjs-2';

import clsx from 'clsx';
const icons = [
    require(`@assets/images/ico_c01.png`),
    require(`@assets/images/ico_c02.png`),
    require(`@assets/images/ico_c03.png`),
    require(`@assets/images/ico_c04.png`)
]

const DashMeasureRecent = (props) => {
    const classes = useStyles()
    const {attemptionPercent, brainactivePercent, concentrationPercent, restPercent} = props.prescription
    
    const measures = [{
        text:'안정',
        icon: icons[0],
        color: classes.blue,
        value: parseInt(restPercent ? restPercent : 0)
    },{
        text:'주의력',
        icon: icons[1],
        color: classes.green,
        value: parseInt(attemptionPercent ? attemptionPercent : 0)
    },{
        text:'집중력',
        icon: icons[2],
        color: classes.red,
        value: parseInt(concentrationPercent ? concentrationPercent : 0)
    },{
        text:'뇌활성',
        icon: icons[3],
        color: classes.purple,
        value: parseInt(brainactivePercent ? brainactivePercent : 0)
    }]
    
    
    const [bands, setBands] = useState([{
        id: 0,
        values: [0,0,0,0]
    },{
        id: 0,
        values: [0,0,0,0]
    }])
    const fetch = useCallback(async () => {
        const data = await PrescriptionService.getLastBands(2)
        setBands(data)
    }, [])
    
    useEffect(() => {
        fetch().then()
    }, [fetch])
    
    
    return (
        <React.Fragment>
            <div className="innerbox">
                <Link className="bt-more" href={'/measureCurrent/last'}>
                    <span>상세보기</span>
                </Link>
                <h4>뇌기능 정보 <i>Brain Health</i></h4>
                <div className="graph-box">
                    <div className="gr-tit">
                        <p>{bands[0].id}회차</p>
                        <ul>
                            <li className="now"><span>{bands[0].id}회차</span></li>
                            <li className="pre"><span>{bands[1].id}회차</span></li>
                        </ul>
                    </div>
                    <div className="graph">
                        <Grid container style={{width:'100%'}}>
                            <Grid item xs={12}>
                                <MeasureLineChart data={[measures, measures]} bands={bands}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} justify={'center'} style={{marginTop: 20}}>
                                    {measures.map((measure, index) => {
                                        return (
                                            <Grid item key={index}>
                                                <Paper elevation={0} className={clsx(classes.measureBox, measure.color)}>
                                                    <Box className={classes.vertical}>
                                                        <Grid container justify={'center'} alignItems={'center'} spacing={1}>
                                                            <Grid item>
                                                                <Grid container direction={'column'} justify={'center'} alignItems={'center'}>
                                                                    <Grid item>
                                                                        <img src={measure.icon} className={classes.measureIcon} alt={'측정 아이콘'}/>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        {measure.text}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Box display={'flex'} justifyContent={'center'} >
                                                                    <Typography className={classes.measurePercent}>{measure.value}</Typography>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export function MeasureLineChart(props) {
    let bands = props.bands
   
    
    const data = {
        labels: ["안정", "주의력", "집중력", "뇌활성"],
        datasets: [
            {
                label: `${bands[0].id}회차`,
                data: bands[0].values,
                fill: true,
                backgroundColor: "rgba(0,178,245,0.5)",
                borderColor: "rgba(0,178,245,1)",
                pointBorderColor: "rgba(0,178,245,1)",
                pointBackgroundColor: 'white',
                pointBorderWidth: 3,
                pointRadius: 7,
            },
            {
                label: `${bands[1].id}회차`,
                data: bands[1].values,
                fill: true,
                backgroundColor: "rgba(213,153,223,0.5)",
                borderColor: "rgba(213,153,223,1)",
                pointBorderColor: "rgba(213,153,223,1)",
                pointBackgroundColor: 'white',
                pointBorderWidth: 3,
                pointRadius: 7,
            }
        ]
    }
    
    const options = {
        legend: {
            display: false,
            labels: {
                fontColor: "white",
                fontSize: 18
            }
        },
        layout: {
            padding: 5
        },
        scales: {
            xAxes: [{
                display: true,
                gridLines: {
                    display: true,
                    color: "#4476C4",
                },
                scaleLabel: {
                    display: false,
                    labelString: 'Month'
                },
                ticks: {
                    fontColor: "white",
                    fontSize: 18
                },
            }],
            yAxes: [{
                display: true,
                gridLines: {
                    display: true,
                    color: "#4476C4"
                },
                scaleLabel: {
                    display: false,
                    labelString: 'Value'
                },
                ticks: {
                    fontColor: "white",
                    fontSize: 18
                },
            }]
        }
    }
    
    return (
        <Line data={data} key={Math.random()} options={options} height={110}/>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#F5F9FE',
        borderRadius: 30,
        minHeight: 442
    },
    wrap: {
        padding: '20px 24px',
        paddingBottom: 0
    },
    vertical: {
        width: '100%',
        position: 'absolute', 
        top: '50%', 
        transform: 'translateY(-50%)'
    },
    measureBox : {
        height: 73,
        width: 125,
        borderRadius: 30,
        margin: 'auto',
        fontFamily: 'Noto Sans KR',
        fontWeight: 700,
        position: "relative"
    },
    measurePercent: {
      fontWeight: 700,
      fontSize: 34,
      fontFamily: 'Roboto'
    },
    measureIcon: {
        width: 30,
        height:30
    },
    blue: {
        backgroundColor: '#C5EBFD', 
        color: '#00B2F5'
    },
    green: {
        backgroundColor: '#D7EBE0',
        color: '#53B268'
    },
    red: {
        backgroundColor: '#F8EBF0',
        color: '#FF706A'
    },
    purple: {
        backgroundColor: '#ECE0F5',
        color: '#C774D2'
    }
}));

export default DashMeasureRecent;
