import React, {useCallback, useEffect, useState} from 'react';
import {Paper, Grid, Box, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DateHelper from '@helper/DateHelper'
import ColorHelper from '@helper/ColorHelper'
import clsx from "clsx";

import ExerciseService from '@user-service/ExerciseService'
import Moment from "moment-timezone";

const CurrentCalendar = (props) => {
    const classes = useStyles();
    
    const [state, setState] = useState({
        prescriptionID: props.prescriptionID,
        profileID: props.profileID
    })
    const [week, setWeek] = useState(DateHelper.getWeek())
    const [logs, setLogs] = useState([])
    
    const fetchLogs = useCallback(async (startDate, endDate) => {
        const params = {
            prescriptionID: state.prescriptionID,
            profileID: state.profileID, 
            startDate, 
            endDate
        }
        const logs = await ExerciseService.getExerciseDoneWeek(params)
        setLogs(logs)
        
    },[state])
    
    useEffect(() => {
        setState({
            prescriptionID: props.prescriptionID,
            profileID: props.profileID
        })
    }, [props.prescriptionID, props.profileID])
    
    useEffect(()=>{
        fetchLogs(week[0].fullDate, week[6].fullDate).then()
    },[fetchLogs, week])
    
    const weekExercise = week.map((value) => {
        value.isDone = false
        value.time = 12
        
        const log = logs.find((log) => log.date === value.simpleDate)
        if(log && 'yes' === log.isDone){
            value.isDone = true
        }
        
        if(0 === value.weekIndex){
            value.color = ColorHelper.getColor('red')    
        }
        
        return value
    })
    
    return (
        <Paper elevation={0} className={classes.root}>
            {props.title &&
            <Typography variant={'subtitle1'} className={classes.title}>
                {props.title}
            </Typography>
            }
            <Grid container spacing={2} className={classes.wrap}>
                <Grid item xs={7}>
                    <Box pl={2}>
                        <Typography style={{marginTop:5, color:'#666'}} variant={'subtitle1'}>{week[0].year}년 {week[0].month}월</Typography>
                    </Box>
                </Grid>
                <Grid item xs={5} >
                    <Grid container spacing={1} justify={'flex-end'} alignItems={'flex-end'}>
                        <Grid item>
                            <IconButton 
                                aria-label={'prev'}
                                onClick={async () => {
                                    const date = Moment(week[0].fullDate).add(-1, 'day')
                                    setWeek(DateHelper.getWeek(date))
                                }}
                            >
                                <ArrowBackIosIcon fontSize={'small'} />
                            </IconButton>        
                        </Grid>
                        <Grid item>
                            <IconButton 
                                aria-label={'prev'}
                                onClick={async () => {
                                    const date = Moment(week[6].fullDate).add(+1, 'day')
                                    setWeek(DateHelper.getWeek(date))
                                }}
                            >
                                <ArrowForwardIosIcon fontSize={'small'}  />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                {weekExercise.map((exercise, index) => {
                    return (
                        <Grid item xs key={`dash-cal-${index}`}>
                            <Paper className={classes.date} elevation={0} >
                                <Box className={clsx({
                                    [classes.dateDivider]: weekExercise.length - 1 !== index
                                })}>
                                    <Grid container
                                          direction={'column'}
                                          justify={'center'}
                                          alignItems={'center'}
                                          spacing={1}
                                    >
                                        <Grid item>
                                            <Typography style={{color: exercise.color}}>{exercise.date} ({exercise.week})</Typography>
                                        </Grid>
                                        <Grid item >
                                            <Box className={clsx(classes.dateCheck)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill={exercise.isDone ? '#014FB4' : '#ddd'}>
                                                    <path d="M23.334 11.96c-.713-.726-.872-1.829-.393-2.727.342-.64.366-1.401.064-2.062-.301-.66-.893-1.142-1.601-1.302-.991-.225-1.722-1.067-1.803-2.081-.059-.723-.451-1.378-1.062-1.77-.609-.393-1.367-.478-2.05-.229-.956.347-2.026.032-2.642-.776-.44-.576-1.124-.915-1.85-.915-.725 0-1.409.339-1.849.915-.613.809-1.683 1.124-2.639.777-.682-.248-1.44-.163-2.05.229-.61.392-1.003 1.047-1.061 1.77-.082 1.014-.812 1.857-1.803 2.081-.708.16-1.3.642-1.601 1.302s-.277 1.422.065 2.061c.479.897.32 2.001-.392 2.727-.509.517-.747 1.242-.644 1.96s.536 1.347 1.17 1.7c.888.495 1.352 1.51 1.144 2.505-.147.71.044 1.448.519 1.996.476.549 1.18.844 1.902.798 1.016-.063 1.953.54 2.317 1.489.259.678.82 1.195 1.517 1.399.695.204 1.447.072 2.031-.357.819-.603 1.936-.603 2.754 0 .584.43 1.336.562 2.031.357.697-.204 1.258-.722 1.518-1.399.363-.949 1.301-1.553 2.316-1.489.724.046 1.427-.249 1.902-.798.475-.548.667-1.286.519-1.996-.207-.995.256-2.01 1.145-2.505.633-.354 1.065-.982 1.169-1.7s-.135-1.443-.643-1.96zm-12.584 5.43l-4.5-4.364 1.857-1.857 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.642z"/>
                                                </svg>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>        
                    )
                })}
            </Grid>

        </Paper>
    )
};

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 30,
        padding: 15,
        paddingTop: 0
    },
    wrap: {
        paddingLeft: 10,
        paddingRight: 10,
    },
    title: {
        fontWeight: 'bold',
        paddingLeft: 15,
        marginBottom: 20
    },
    date: {
        backgroundColor: '#F5F9FE',
        paddingTop: 20,
    },
    dateDivider: {
        border: 0,
        borderRight: '1px solid #EEEFF1',
    },
    dateNumber: { 
        fontFamily: 'Roboto',
        fontSize: '1.2rem',
        fontWeight: 700
    },
    dateCheck: {
        display: 'flex',
        height: 70, 
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ddd'
    },
    success: {
        color: '#00D48F'
    }
}));

export default CurrentCalendar;
