import React, {useCallback, useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";

import { Box, Grid} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Skeleton from '@material-ui/lab/Skeleton'
import ExerciseService from '@user-service/ExerciseService'
import ExerciseBox from '@user-pages/exercise/ExerciseBox'
import PrescriptionService from "../../../services/PrescriptionService";
import ExpiredDialog from "./ExpiredDialog";


const BrainHealth = (props) => {
    const [exercise, setExercise] = useState(null)
    const [prescriptionID, setPrescriptionID] = useState(null)
    const [isExpired, setExpired] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    
    
    const fetch = useCallback(async () => {
        const result = await ExerciseService.getExercises('exercise')
        const data = result.success ? result.data : []
        
        const id = await PrescriptionService.getLastPrescription();
        await PrescriptionService.getPrescriptionById(id , (prescriptionResult) => {
            // console.log(prescriptionResult.prescription)
            if(prescriptionResult.prescription){
                setExpired(prescriptionResult.prescription.isExpired)     
                setPrescriptionID(id)
            }
        })
        setExercise(data)
    }, [])
    
    useEffect(()=>{   
        fetch().then()    
    },[fetch])

    if(!exercise) return <LoadingSkeleton/>
    
    return (
        <div id="cont-wrap">
            <div className="sv">
                <div>
                    <h3>BRHW</h3>
                    <span>브레인 헬스를 위한 운동입니다.</span>
                    <i>Brain Health</i>
                </div>
            </div>
            <ExpiredDialog 
                open={openDialog}
                prescriptionID={`${prescriptionID}` }
                onClose={() => {
                    setOpenDialog(false)
                }}
            />
            <div className="cont">
                <Grid container spacing={3} >
                    {exercise.map((ex)=>{
                        return (
                            <Grid item md={4} xs={6} key={ex.id}>
                                <ExerciseBox {...ex} 
                                 isExpired={isExpired}
                                 onExpired={()=>{
                                     setOpenDialog(true)
                                 }}
                                />
                            </Grid>
                        )
                    })}                        
                </Grid>
            </div>
        </div>
    );
}

//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <div className="editprofile_wrap wrapper" xs={6} sm={6}>
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </div>
        </Box>
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 1400,
        width: 1400,

    },
    headerBox: {
        marginBottom: 40,
        marginTop: 120
    },
    title: {
        fontSize:26,
        fontWeight: 'bold'
    }
}));




export default BrainHealth
