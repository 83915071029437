import html2canvas from "html2canvas";

const zlib = require('zlib');

export default class PrintHelper {

  static print(printRef, header, footer, delay = 0) {
    html2canvas(printRef.current, {
      scale: 2, // 해상도를 높이기 위해 스케일 조정
      useCORS: true, // CORS 정책 준수 이미지를 로드하기 위해
      onclone: (document) => {
        // console.log(document.documentElement.scrollHeight)
        // 여기서 필요한 DOM 조정을 수행
        // let elements = document.getElementsByClassName('print-hide');
        // for (let element of elements) {
        //   element.style.display = 'none';
        // }
        //
        // elements = document.getElementsByClassName('print-show');
        // for (let element of elements) {
        //   element.style.display = 'block';
        // }
        // elements = document.getElementsByClassName('title-left');
        // for (let element of elements) {
        //   element.style.textAlign = 'left';
        //   element.style.paddingLeft = '70px'
        // }
      }
    }).then((canvas) => {

      // 인쇄 실행
      const imgData = canvas.toDataURL('image/png');
      let windowContent = '<!DOCTYPE html>';
      windowContent += '<html>';
      windowContent += `<style>
            body, html {margin: 0;padding: 0;}
            body {
              page-break-inside: avoid;
            }
            
            #responsive-dialog-title { padding-bottom: 20px; border-bottom:5px solid #3F51B5}
            .print-bottom { border-top:5px solid #3F51B5; text-align: center; padding-top: 10px; font-weight: bold;}
            #responsive-dialog-title h2 { color: #120075; text-align: center; font-size: 1.5rem;font-weight: bold;}
            .title { font-size: 1.3rem; font-weight: bold; text-align: center; padding:20px 20px 10px 20px; min-height:76px;}
            .title.point { text-align: left;}
            .title.point:before { content:'■'; font-size: 1.7rem; padding-right: 6px;}
            .box { background-color: #F6F9FC; padding:30px;}
            .box li:before { content: '-'; padding-right: 4px; }
            .box li { margin-bottom: 4px; font-weight: 400;  font-size: 0.9rem}
            
            .bottom { position: absolute; bottom: 0px; width: 100%}
             img {
                width: 90%;   /* A4 너비 */
                object-fit: contain; /* 이미지 비율 유지 */
                }
               
              .flex-center {display: flex; justify-content: center; margin-top: 20px}
                
            @media print {
              body, html {
                margin: 0;padding: 0;
              }  

              img {
                width: 90%;   /* A4 너비 */
                object-fit: contain; /* 이미지 비율 유지 */
                }
             }
             
          </style>`;
      windowContent += '<body>';
      if(header) windowContent += header.current.outerHTML;
      windowContent += '<div class=\"flex-center\"><img src="' + imgData + '"></div>';
       if(footer) windowContent += '<div class=\"bottom\">' + footer.current.outerHTML + '</div>';
      windowContent += '</body>';
      windowContent += '</html>';


      // iframe 동적 생성
      const printIframe = document.createElement('iframe');
      printIframe.style.width = '0';
      printIframe.style.height = '0';
      printIframe.style.border = 'none';
      document.body.appendChild(printIframe);

      // iframe에 내용 쓰기
      const iframeDoc = printIframe.contentDocument || printIframe.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(windowContent);
      iframeDoc.close();
        
      // const printWin = window.open('', '', 'width=1024,height=768');
      // printWin.document.open();
      // printWin.document.write(windowContent);
      // printWin.document.close();
      // printWin.focus();

      setTimeout(() => {
        printIframe.contentWindow.print();
      }, 300)
    });
  }

  static getPNGImage(targetRef) {
  
    return new Promise((resolve, reject) => {
      html2canvas(targetRef.current, {
        scale: 2, // 해상도를 높이기 위해 스케일 조정
        useCORS: true, // CORS 정책 준수 이미지를 로드하기 위해
      }).then((canvas) => {
        resolve(canvas.toDataURL('image/png'))
      })   
    })
  }
}