import Chroma from 'chroma-js';
export default class ColorHelper {
    static colors = Chroma.scale(['0000ff','00efff','ffffff', '00d917','f3ff00','f800f0','f800f0','f800f0', 'ff0000','ff0000','ff0000', '860101']).mode('lch'); 

    /**
     * 그라디언트 색을 반환합니다.
     */
    static getColors() {
        return this.colors;
    }

    /**
     * 색의 스케일 값을 배열로 리턴합니다.
     * @param blocksCount
     */
    static getColorsWith(blocksCount){
        return this.colors.colors(blocksCount);
    }
    
    static getGaugeColor(blocksCount){
        const colors = Chroma.scale(['009a60', '4aa84e', '92b73a', 'c6bf22', 'edbd02', 'ffad00', 'ff8c00', 'fc6114', 'f43021', 'ed0022']).mode('lch');
        return colors.colors(blocksCount)
    }
    
    static getPreLevelColor(type , blocksCount){
        if('EEG' === type){
            return {
                backgroundColors: ['rgba(248, 132,122,0.3)','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff','rgba(169, 216,170,0.3)','#ffffff'],
                colors:  ['#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000']
            }
        } else {
            // const backgroundColors = Chroma.scale(['FFDAD8', 'FBEAD4', 'CFE9DC']).mode('lch');
            // const colors = Chroma.scale(['FE716C', 'FFC35F', '39B781']).mode('lch');
            return {
                backgroundColors: ['rgba(248, 132,122,0.3)','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff','rgba(169, 216,170,0.3)'],
                colors:  ['#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000']
            }
        }
        /*
        if('EEG' === type){
            return {
                backgroundColors: ['#FF843A','#FF843A','#FFCEB0','#FFCEB0','#FFCEB0','#FFF7CC','#FFF7CC','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'],
                colors:  ['#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000']
            }
        } else {
            const backgroundColors = Chroma.scale(['FFDAD8', 'FBEAD4', 'CFE9DC']).mode('lch');
            const colors = Chroma.scale(['FE716C', 'FFC35F', '39B781']).mode('lch');
            return {
                backgroundColors: backgroundColors.colors(blocksCount),
                colors:  colors.colors(blocksCount)
            }
        }
        */
    }
    
    static getMeasureColors(measureType, blocksCount){
        let start, end
        switch (measureType){
            case 'rest':
                start = '40D5FF'
                end = '5046FE'
                break
            case 'attemption':
                start = '41FCA2'
                end = '42C9FF'
                break
            case 'concentration':
                start = 'FD743E'
                end = 'F6FF4E'
                break
            case 'brainactive':
                start = 'F742FF'
                end = 'FF4673'
                break
            default:
                start = '40D5FF'
                end = '5046FE'
        }
        
        const colors = Chroma.scale([start,end]).mode('lch');
        return colors.colors(blocksCount)
    }
    
    static getSurveyColors(useBindKeys = null){
        const defaultColor = ['#efefef1a',
            '#8feacf',
            '#79BEFF',
            '#E2EEAB',
            '#B1EF58',
            '#00E13D',
            '#fee766',
            '#FF7B14',
            '#ff0100',
            '#502962',
            '#555555'
        ]
        
        if(useBindKeys){
            const colors = []
            useBindKeys.map((key, index) => {
                colors[key] = defaultColor[index]
                return key
            })
            return colors
        } else {
            return defaultColor
        }
    }
    
    static getSurveyColorWithBind(){
        return this.getSurveyColors([0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100])
    }
    
    static getColorPercent(color, max, val){
        let percent = 0 < val ? val / max : 0.1 ; 
        
        if(0.1 > percent){
            percent = 0.1
        }
        
        switch(color){
            case 'red':
                return `rgba(255, 112, 106, ${percent})`
            case 'green':
                return `rgba(83, 178, 104, ${percent})`
            case 'blue': 
                return `rgba(0, 178, 245, ${percent})`
            default:
                return `rgba(0, 178, 245, 1)`
        } 
    }
    
    static getColor(color){
        switch(color){
            case 'red':
                return `rgba(255, 112, 106, 1)`
            case 'green':
                return `rgba(83, 178, 104, 1)`
            case 'blue':
                return `rgba(0, 178, 245, 1)`
            default:
                return `rgba(0, 178, 245, 1)`
        }
    }
    
    static updateRGBAlpha(rgbaString, newAlpha) {
      // RGBA 형식에서 색상 값을 분리합니다.
      let matches = rgbaString.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d*)\)$/);
  
      if (!matches) return rgbaString; // 일치하는 형식이 아니면 원래 문자열을 반환
  
      return `rgba(${matches[1]}, ${matches[2]}, ${matches[3]}, ${newAlpha})`;
   }
}