import React, {forwardRef, useEffect, useReducer} from "react";
import CssTextField from '@user-components/CssTextField';


const initState = {
    value: '',
    isValid: false,
    isError: false
}

function reducer(state, action){
    switch (action.type){
        case 'UPDATE_VALUE':
            return {
                ...state,
                value: action.value,
                isValid: action.isValid,
                isError: action.isError
            }
        default:
    }
    return state
}
const BirthDateTextField = forwardRef((props, ref) => {
 
    const [state, dispatch] = useReducer(reducer, initState)
    const { value, isValid, isError } = state
    const {valid} = props
    
    useEffect(() => {
        try {
            valid(isValid, value)
        } catch {}
    }, [isValid, value])
    
    return (
        <CssTextField
            label="생년월일 8자리"
            inputRef={ref}
            fullWidth
            onChange={(event)=>{
                const birthDate = event.target.value
                
                let isValid = false
                let isError = true
                
                if(8 === birthDate.length){
                    const regexVar = /^([0-9]{4})([0-9]{2})([0-9]{2})$/; // add anchors; use literal
                    isValid = regexVar.test(birthDate); // pass the string, not the Date
                    isError = false
                } 
                
                dispatch({
                    type: 'UPDATE_VALUE',
                    value: event.target.value,
                    isValid,
                    isError    
                })
                
            }}
            placeholder={'예) 19810314'}
            inputProps={{ maxLength: 8}}
            error={isError}
            helperText={isError ? '생년월일 정확히 입력해주세요' : ''}
        />
    )
})

export default BirthDateTextField