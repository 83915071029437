import React, {useEffect, useState} from "react";
import {Box, Grid, Button, Slider, TextField} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import MeasureService from '@user-service/MeasureService'

import MeasureHelper from '@helper/MeasureHelper'
const { measureTypeToCode } = MeasureHelper

export default class SeekBar extends React.Component {
    
    timePoints = []
    videoSpeed = 5
    seekTimeCountRef = React.createRef()
    
    constructor(props) {
        super();
        
        this.state = {
            currentTime: 0,
            onSeekTime: props.onSeekTime,
            onPause: props.onPause,
            onPlay: props.onPlay,
            measureType: props.measureType,
            measureCode: props.measureCode,
            onVideoSpeed : props.onVideoSpeed,
            onReset: props.onReset,
            playTime: props.playTime ? props.playTime : 105
        }

    }
    update(time, measureType, measureCode ){
        this.setState({
            currentTime: time,
            measureCode,
            measureType
        })
    }
    
    reset(){
        this.setState({
            currentTime: 0
        })
    }
    
    getTimePoints(){
        return this.timePoints
    }

    getVideoSpeed(){
        return 1 / this.videoSpeed
    }
 
    render() {
        return (
            <Box>
                <PSlider
                    ref={this.sliderRef}
                    onUpdate={(timePoints) => this.timePoints = timePoints}
                    time={this.state.currentTime}
                    onSeekTime={this.state.onSeekTime}
                    onPause={this.state.onPause}
                    onPlay={this.state.onPlay}
                    measureType={this.state.measureType}
                    measureCode={this.state.measureCode}
                    onVideoSpeed={(videoSpeed) => this.videoSpeed = videoSpeed }
                    onReset={this.state.onReset}
                    playTime={this.state.playTime}
                />
            </Box>
        )
    }
}

export class SeekTimeCount extends React.Component {
    
    constructor() {
        super();
        
        this.state = {
            seekTimeCount: 0
        }
    }
    
    update(timeCount){
        this.setState({
            seekTimeCount: timeCount
        })
    }
    
    render() {
        return (
            <Box>{this.state.seekTimeCount}</Box>
        )
    }
    
}
export function PSlider(props){
    const[settings, setSettings] = useState({
        points: [10,20,30,40,50],
        playTime: props.playTime,
        marks: []
    })
    
    let applyTimer = null
    const settingDialogRef = React.useRef()
    
    const fetch = async () => {
        const res = await MeasureService.getMeditationTimePoint(measureTypeToCode(props.measureType), props.measureCode)
        const marks = res.timePoint.map((point) => {
            return {
                value: point.second,
                label: point.second
            }
        })
        
        if(props.onUpdate){
            props.onUpdate(marks)
        }
        
        setSettings({
            ...settings,
            marks 
        })
        
    }
    useEffect(() => {
        // fetch().then()
    },[])
    
    const PrettoSlider = withStyles({
        // root: {
        //     color: '#52af77',
        //     height: 8,
        // },
        // thumb: {
        //     height: 24,
        //     width: 24,
        //     backgroundColor: '#fff',
        //     border: '2px solid currentColor',
        //     marginTop: -8,
        //     marginLeft: -12,
        //     '&:focus,&:hover,&$active': {
        //         boxShadow: 'inherit',
        //     },
        // },
        // active: {},
        // valueLabel: {
        //     left: 'calc(-50% + 4px)',
        // },
        // track: {
        //     height: 8,
        //     borderRadius: 4,
        // },
        // rail: {
        //     height: 8,
        //     borderRadius: 4,
        // },
    })(Slider);

    return (
        <Box>
            <Box>
                {/*<Box mb={1}>*/}
                {/*    <TextField label="느린 배속 설정  "*/}
                {/*               type="text"*/}
                {/*               color={"primary"}*/}
                {/*               defaultValue={5}*/}
                {/*               inputProps={{ style: { color: 'white', marginBottom: 5}}}*/}
                {/*               onChange={(event) => {*/}
                {/*                   if(props.onVideoSpeed){*/}
                {/*                       props.onVideoSpeed(event.target.value)*/}
                {/*                   }*/}
                {/*               }}*/}
                {/*    />*/}
                {/*</Box>*/}
                {/*<Grid container spacing={1}>*/}
                {/*    <Grid item>*/}
                {/*        <Button variant="outlined" style={{color: 'white', borderColor: 'white'}} onClick={() => {*/}
                {/*            if(props.onPlay){*/}
                {/*                props.onPlay()*/}
                {/*            }*/}
                {/*        }}>재생</Button>*/}
                {/*    </Grid>*/}
                {/*    <Grid item>*/}
                {/*        <Button variant="outlined" style={{color: 'white', borderColor: 'white'}} onClick={() => {*/}
                {/*            if(props.onPause){*/}
                {/*                props.onPause()*/}
                {/*            }*/}
                {/*        }} >중지</Button>*/}
                {/*    </Grid>*/}
                {/*    <Grid item>*/}
                {/*        <Button variant="outlined" style={{color: 'white', borderColor: 'white'}} onClick={() => {*/}
                {/*            if(props.onReset){*/}
                {/*                props.onReset()*/}
                {/*            }*/}
                {/*        }} >초기화</Button>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Box>
            <PrettoSlider
                defaultValue={props.time}
                aria-labelledby="discrete-slider-always"
                step={1}
                min={0}
                max={settings.playTime}
                marks={settings.marks}
                valueLabelDisplay="auto"
                disabled
                onChange={(event, newValue)=>{
                    if(props.onSeekTime){
                        if(applyTimer){
                            clearTimeout(applyTimer)
                        }

                        applyTimer = setTimeout(() => {
                            props.onSeekTime(newValue)    
                        }, 500)
                        
                    }
                }}
            />
        </Box>
    )
}
