import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';

import {Box} from '@material-ui/core';
import AudioPlayer from '@user-pages/exercise/common/AudioPlayer'
import SocketProvider from '@user-provider/SocketProvider'

import MeasureHelper from '@helper/MeasureHelper'
import SeekBar from "./SeekBar";
import StartDialog from "./StartDailog";
import ComputeFeedbackDialog from '@user-pages/exercise/common/ComputeFeedbackDialog'


import StartButton from '@user-pages/exercise/common/StartButton'
import EndDialog from "./EndDailog";
import {useParams} from "react-router-dom";

const { measureTypeToCode } = MeasureHelper
const MeasureHzType = 'meditationTest'
const queryString = require('query-string');

const MeditationTest = (props) => {
    const qs = queryString.parse(window.location.search)
    
    const stageRef = useRef()
    const [status] = useState('stage')
    const [requestID] = useState(qs.rqs)
    const { prescriptionID } = useParams()
    
    useEffect(()=>{
        SocketProvider.connect(()=>{}, window, MeasureHzType)
        
        return () => {
            SocketProvider.measureStop(true).then()
        }
    },[])

    return (
        <Box style={{position: 'relative'}}>
            {'stage' === status &&
            <Stage ref={stageRef} 
                   requestID={requestID}
                   prescriptionID={prescriptionID}
                   history={props.history}/>
            }
        </Box>
    );
}

export class Stage extends React.Component {
    container
    clientRect
    
    settingDialogRef = React.createRef()
    
    videoRef = React.createRef()
    seekBarRef = React.createRef()
    audioRef = React.createRef()
    startDialogRef = React.createRef()
    endDialogRef = React.createRef()

    currentMeasureType = 'type1'
    currentMeasureCode = 'MA'
    
    currentTime = 0
    timePointIndex = 0
    timePoints = [0.7, 20, 44, 92, 98.6]
    measureCodes = ['MA','MM','MB']
    
    
    startDelayTimer = null
    
    effectTimePointIndex = 0
    effectTimePoints = [
        { time: 3.5, effect: 'windChime1' }, 
        { time: 6, effect: 'windChime1' }, 
        { time: 7.2,  effect: 'ex15' }, 
        { time: 11.3, effect: 'ex16', vol: 0.3 }, 
        { time: 23, effect: 'fade' }, 
        { time: 25, effect: 'fade' },{ time: 25.1, effect: 'fade' },{ time: 25.4, effect: 'fade' },{ time: 25.5, effect: 'fade' },{ time: 26.6, effect: 'fade' },{ time: 26.7, effect: 'fade' },
        { time: 28.3, effect: 'fade' },{ time: 29.3, effect: 'fade' },
        { time: 32.2, effect: 'fade' },{ time: 33.9, effect: 'fade' },
        { time: 36.1, effect: 'ex09', vol: 0.3},{ time: 37, effect: 'ex09', vol: 0.3},
        { time: 39.6, effect: 'ex09', vol: 0.3}, { time: 40.2, effect: 'ex09', vol: 0.3},
        { time: 42.8, effect: 'ex09', vol: 0.3},
        { time: 45.6, effect: 'windChimes4'}, { time: 48.5, effect: 'windChimes4'},
        { time: 53, effect: 'fade' },
        { time: 57.3, effect: 'ex30' },
        { time: 58.8, effect: 'ex31' },
        { time: 62, effect: 'fade' },{ time: 63.2, effect: 'blink' },{ time: 64.9, effect: 'blink' },
        { time: 66.7, effect: 'fade' },{ time: 68.2, effect: 'blink' },{ time: 69.9, effect: 'blink' },
        { time: 71.8, effect: 'fade' },{ time: 73.3, effect: 'blink' },{ time: 75.0, effect: 'blink' },
        { time: 76.8, effect: 'fade' },{ time: 78.2, effect: 'blink' },{ time: 79.8, effect: 'blink' },
        { time: 81.7, effect: 'fade' },{ time: 83.2, effect: 'blink' },{ time: 85, effect: 'blink' },
        { time: 86.7, effect: 'fade' },{ time: 88.2, effect: 'blink' },{ time: 89.9, effect: 'blink' },
        { time: 92.2, effect: 'windChimes2' },
        { time: 94.3, effect: 'windChimes21' },{ time: 96.3, effect: 'windChimes21' },
    ]
    
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            history: props.history,
            onMeasureData: props.onMeasureData,
            prescriptionID: props.prescriptionID,
            requestID: props.requestID
        }
    }

    componentDidMount() {
        this.container = ReactDOM.findDOMNode(this)
        this.clientRect = this.container.getClientRects()[0]
        this.audioRef.current.loadEffect(MeasureHzType)

        this.startDialogRef.current.open()
        
        this.currentMeasureCode = this.measureCodes.shift()
        SocketProvider.onMeasureStop = async (data) => {
            if(data.force){
                try {
                    this.videoRef.current.pause()
                    this.audioRef.current.pause()
                    this.endDialogRef.current.open()
                } catch {}
            } else {
                this.currentMeasureCode = this.measureCodes.shift()

                this.audioRef.current.play(this.currentMeasureCode)
                if(this.currentMeasureCode){
                    setTimeout(async () => {
                        await this.startMeasure()    
                    }, 1000)
                }       
            }
        }
    }

    refresh(){
        this.setState({
            ...this.state
        })
    }
    setAudioMode(measureType){
        this.audioRef.current.setMode(measureType)
    }

    async startExercise() {
        this.onReset()
        await this.startMeasure()
    }
    async stopExercise() {
        this.onPause()
        await this.onVideoEnded()
    }
    
    async startMeasure(){
        await SocketProvider.measureStart(
            90,
            this.state.prescriptionID,
            MeasureHzType,
            this.currentMeasureType,
            measureTypeToCode(this.currentMeasureType),
            this.currentMeasureCode,
            null,
            (isStart) => {
                if(isStart){
                    this.onPlay()
                }
            })
    }

    onVideoLoad() {}
    
    async onVideoEnded(){
        try {
            await SocketProvider.measureStop()
        } catch{}
        
        const endDialogRef = this.endDialogRef;
        setTimeout(function(){
            try {
                endDialogRef.current.open()
            } catch {}    
        },3 * 1000);
        
    }
    
    onVideoTimeUpdate(event){
        if(this.videoRef.current.paused) {
            return false
        }
        
        const time = parseInt(event.target.currentTime)
        const timePoint = this.timePoints[this.timePointIndex]
        const effectTimePoint = this.effectTimePoints[this.effectTimePointIndex]

        if(timePoint <  event.target.currentTime){
            // this.audioRef.current.play(this.timePointIndex)
            this.timePointIndex++
        }
        
        try {
            if(effectTimePoint.time < event.target.currentTime){
                this.audioRef.current.effect(effectTimePoint.effect, effectTimePoint.vol ? effectTimePoint.vol : 0.5)
                this.effectTimePointIndex++
            }    
        } catch(e){}
        
        
        if(this.currentTime < time) {
            this.currentTime = time
            this.seekBarRef.current.update(time)
        }
    }
    
    onSeekTime(time) {
        this.videoRef.current.currentTime = time
        this.audioRef.current.pause()
        
        let timePoint = 0
        for(let i = 0; i < this.timePoints.length; i++){
            timePoint = this.timePoints[i];
            
            if(timePoint >= time){
                this.timePointIndex = i
                break
            }
        }

        for(let i = 0; i < this.effectTimePoints.length; i++){
            timePoint = this.effectTimePoints[i];

            if(timePoint.time >= time){
                this.effectTimePointIndex = i
                break
            }
        }

        if(0 < time && 0 === this.timePointIndex){
            this.timePointIndex = 99
        }
        
        if(0 < time && 0 === this.effectTimePointIndex){
            this.effectTimePointIndex = 99
        }
    }
    
    onPlay(){
        const currentTime = parseInt(this.videoRef.current.currentTime);
        this.videoRef.current.playbackRate = 0.38 //this.seekBarRef.current.getVideoSpeed()
        
        if(0 === currentTime){
            if(this.startDelayTimer){
                clearTimeout(this.startDelayTimer)
            }
            this.startDelayTimer = setTimeout(() => {
                try {
                    this.videoRef.current.play()    
                } catch {}
            }, 1000);
        } else {
            this.videoRef.current.play()
        }
    }
    
    onPause(){
        if(this.startDelayTimer){
            clearTimeout(this.startDelayTimer)
        }
        
        this.videoRef.current.pause()
        this.audioRef.current.pause()
    }
    
    onReset(){
        this.timePointIndex = 0
        this.effectTimePointIndex = 0
        this.videoRef.current.pause()
        this.videoRef.current.currentTime = 0
        this.seekBarRef.current.update(0, this.currentMeasureType, this.currentMeasureCode)
    }

    render() {
        return (
            <Box>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    position="absolute"
                    right={10}
                    top={10}
                    zIndex={99}>

                    <StartButton 
                        hzType={'meditationTest'}
                        measureType={this.currentMeasureType}
                        measureCode={this.currentMeasureCode}
                        onStop={async () => {
                            // await SocketProvider.measureStop(false, true)
                          document.location.href = `/measureSelect`
                        }}
                    />
                    <ComputeFeedbackDialog
                        ref={this.settingDialogRef}
                        hzType={MeasureHzType}
                        measureType={'type1'}
                    />
                </Box>

                <Box style={{ position: 'relative', height: window.innerHeight}}>
                    <Box style={{height:'100%'}}>
                        <video id="meditation-video"
                               ref={this.videoRef}
                               muted
                               poster={'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/meditation.png'}
                               onEnded={this.onVideoEnded.bind(this)}
                               onLoadedData={this.onVideoLoad.bind(this)}
                               onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                               style={{height: '100%', width: '100%', objectFit: 'cover' }}>
                            <source src={'https://d2iajcsshqwtw3.cloudfront.net/assets/meditation_normal.webm'} type="video/mp4"/>
                        </video>
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="center"
                        position="fixed"
                        bottom={20}
                        style={{height: 50, width: '100%', zIndex: 20}}>
                        <Box style={{width: '80%'}}>
                            <SeekBar ref={this.seekBarRef} 
                                     onSeekTime={this.onSeekTime.bind(this)}
                                     onPlay={this.onPlay.bind(this)}
                                     onPause={this.onPause.bind(this)}
                                     measureType={this.currentMeasureType}
                                     measureCode={this.currentMeasureCode}
                                     onReset={this.onReset.bind(this)}
                            />    
                        </Box>
                    </Box>
                </Box>
                
                <AudioPlayer ref={ this.audioRef } mode={'breathStamp'} />
                <StartDialog ref={this.startDialogRef} onStart={this.startExercise.bind(this)} history={this.state.history}/>
                <EndDialog ref={this.endDialogRef} requestID={this.state.requestID} history={this.state.history} url={`/measureDetail/${this.state.prescriptionID}/brainActive`}/>
                
            </Box>
        )
    }
}


export default MeditationTest
