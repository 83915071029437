

import React, {useEffect, useState} from "react";
import { Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import CustomizedDialogs from "../../../components/CustomDialog";
import PrescriptionService from "../../../services/PrescriptionService";

const DeletePrescriptionDialog = (props) => {

    const { onClose, onCancel, open, prescriptionID } = props
    const [openDialog, setOpenDialog] = useState(false)
    const classes = useStyles();
    const handleClose = () => {
        if(onClose){
            onClose()
        }
    }
    const handleCancel = () => {
        if(onCancel) {
            onCancel()
        }
    }
    useEffect(() => {
        setOpenDialog(open)
    }, [open, setOpenDialog])
    
    return (
        <CustomizedDialogs
            key={Math.random()}
            open={openDialog}
            title={`${prescriptionID} 회차 삭제`}
            content={
                <React.Fragment>
                    <Typography gutterBottom className={classes.text}>
                        선택하신 회차를 삭제하시겠습니까?
                    </Typography>
                    <br/>
                    <Typography gutterBottom className={classes.text}>
                    <span style={{color:'red'}}>
                    측정 기록, 처방기록, 훈련 기록이 삭제됩니다.
                        <br/>
                    삭제하시겠습니까?
                    </span>

                        <br/>
                        <br/>
                        <b>삭제된 회차는 복구 할 수 없습니다</b>
                    </Typography>
                </React.Fragment>
            }
            close={() => {
                handleCancel()
                
            }}
            submit={async () => {
                await PrescriptionService.deletePrescription(prescriptionID)
                handleClose()

            }}
            buttonName={'삭제'}
        />
         
    );
}


const useStyles = makeStyles(() => ({
    small: {
        fontSize: 14,
        color: '#999',
    }
}));

export default DeletePrescriptionDialog