import Moment from 'moment-timezone'
import 'moment/locale/ko'

const weekText = ['일','월','화','수','목','금','토','일']
export default class DateHelper {
    static format(utcDateTime, format) {
        if(!utcDateTime || '' === utcDateTime){
            return '';
        } 
            
        return Moment.utc(utcDateTime).tz(Moment.tz.guess()).format(format)
    }
    static now(format) {
        return Moment.tz(Moment.tz.guess()).format(format)
    }
    static toDateFormat(date, format) {return Moment(date).format(format)}
    static toUtcFormat(date, format){ return Moment(date).format(format) }
    
    static fromNow(utcDateTime){
        return Moment.utc(utcDateTime).tz(Moment.tz.guess()).fromNow()
    }
    
    static isBefore(utcDateTime){
        if(!utcDateTime) return true
        return Moment.tz(Moment.tz.guess()).isBefore(Moment.utc(utcDateTime).tz(Moment.tz.guess()))
    }

    static secToFormat(timeCount){
        let tempTime = Math.floor(timeCount / 60)
        let timeCountText = '';
        
        if(60 < tempTime){
            timeCountText = (Math.floor(tempTime / 24) + '시간 ') +
                (Math.floor(tempTime % 60) + '분 ')
        } else {
            timeCountText += tempTime + '분'
        }

        tempTime = timeCount % 60
        if(0 < tempTime){
            timeCountText += ' ' + (timeCount % 60) + '초';
        }
        
        return timeCountText
    }
    static weekName(date = null){
        let d = Moment.tz(Moment.tz.guess())
        if(date) {
            d = Moment(date).tz(Moment.tz.guess())
        }
        return  weekText[d.format('E')] + '요일'
    }
    
    static getWeek(startDate) {
        const begin = Moment(startDate).day('Sunday');
        
        const week = []
        let currentDay
        
        for(let i = 0 ; i < 7; i++){
            currentDay = Moment(begin).add(i , 'days')
            
            week.push({
                weekIndex: i,
                week : weekText[i],
                fullDate : currentDay.format('YYYY-MM-DD'),
                simpleDate : currentDay.format('YYYYMMDD'),
                year: currentDay.format('YYYY'),
                month: currentDay.format('MM'),
                date: currentDay.format('DD')
            })
        }
        return week
    }
    
    static getMonthsWithLabel(numMonths){
        const months = [];
        const today = new Date();
        const currentYear = today.getFullYear();
        let month = today.getMonth() + 1;  // getMonth()는 0부터 시작하므로 +1

        for (let i = 0; i < numMonths; i++) {
            const year = currentYear - (month > 0 ? 0 : 1);  // 월이 0보다 작아지면 전년도로 설정
            const formattedMonth = month < 10 ? '0' + month : month.toString();

            let label = "";
            if (year !== currentYear) {
                label = `${year.toString().slice(-2)}년 ${formattedMonth}월`;
            } else {
                label = `${formattedMonth}월`;
            }

            months.push({
                label: label,
                dateMonth: `${year}-${formattedMonth}`
            });

            month--;  // 이전 월로 이동
            if (month <= 0) {
                month = 12;  // 1월 이전은 12월로 설정
            }
        }
        return months.reverse();  // 최근 월 순서로 배열 정렬
    }
}