import {Box, Button, Card, CardContent, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

import PreLevelDialog from "./PreLevelDialog";
import MeasureService from "../../../services/MeasureService";

export default function CurrentPreLevel(props) {
    const classes = useStyles()
    const [showDialog, setShowDialog] = useState(false)
    
    const { measureResult, type } = props
    const [preLevels] = useState(MeasureService.getPreLevel(type))
    const { bandRangeData } = props.measureResult
   

    if(!bandRangeData.ch1[0]){
        return <></>
    }
    
    let currentPreLevels = [0,0]
    if('EEG' === type){
        // 델타파 처리 
        const ch1 = bandRangeData.ch1[0];
        const ch2 = bandRangeData.ch2[0];
        // const total = parseFloat(ch1) + parseFloat(ch2)
        // const deltaAvg = total / 2
        // const level = MeasureService.makeDeltaToPreLevelEEG(deltaAvg)
        currentPreLevels[0] = preLevels.find(pre => pre.level === MeasureService.makeDeltaToPreLevelEEG(ch1))
        currentPreLevels[1] = preLevels.find(pre => pre.level === MeasureService.makeDeltaToPreLevelEEG(ch2))
    } else if ('BALANCE' === type) {
        // ALPHA 파 기준으로 변경
        const ch1 = bandRangeData.ch1[2];
        const ch2 = bandRangeData.ch2[2];
        const total = parseFloat(ch1) + parseFloat(ch2)
        
        // 델타파로 등급을 판정함
        const ch1Percent = 0 === total ? 50 : Math.floor(ch1 / total * 100)
        const ch2Percent = 0 === total ? 50 : Math.floor(ch2 / total * 100)
        
        // const diff1 = Math.abs(50 - ch1Percent) + 1
        // const diff2 = Math.abs(50 - ch2Percent) + 1
        // const level = MeasureService.makeDeltaToPreLevelBalance(diffPercent)
        
        currentPreLevels[0] = preLevels.find(pre => pre.level === MeasureService.makeDeltaToPreLevelBalance(Number(ch1Percent)))
        currentPreLevels[1] = preLevels.find(pre => pre.level === MeasureService.makeDeltaToPreLevelBalance(Number(ch2Percent)))
    }  
    
    const title = 'EEG' === type ? 'EEG' : '좌우뇌균형'
    
    return (
        <>
            {measureResult && currentPreLevels && -1 < measureResult.preLevel &&
            <Box className={classes.root} >
                <PreLevelDialog open={showDialog} type={type} onCancel={() => setShowDialog(false)}/>

                {currentPreLevels.map((currentPreLevel, index) => {
                    return (
                        <Card key={`pre-leve-${index}`} className={classes.card} style={{
                            backgroundColor: currentPreLevel.backgroundColor,
                        }}>
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={2} >
                                    <Grid item xs={11}>
                                        <Typography gutterBottom className={classes.title}>
                                            {measureResult.id} 회차의 {title} 
                                            <b> { 1 === index ? '우뇌(Right Brain)' : '좌뇌(Left Brain)'}</b> 등급은
                                            <b className={classes.preLevel}>{currentPreLevel.levelText}</b> 
                                            <b style={{fontSize: '1rem'}}> {currentPreLevel.levelName}</b> 입니다.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Button style={{marginTop: 5}} onClick={() => {
                                            setShowDialog(true)
                                        }}>측정기준</Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ) 
                })}
            </Box>
            }
        </>
    )
}

const useStyles = makeStyles(() => ({
    root: {padding: 0},
    title: {
        fontSize: '1rem',
        paddingTop: 8
    },
    cardContent: {
      padding: '0 !important',
      paddingLeft: '10px !important'
    },
    preLevel: {
        fontSize: '1.1rem'
    },
    card: {
        boxShadow: "none",
        padding: 0
    },
    item: {
        width: '20%',
        '& .title': {
            fontSize: '1rem',
            padding: 10,
        }
    },
    image: {
        width: 72
    }
}));

