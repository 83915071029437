
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import CustomizedDialogs from "../../components/CustomDialog";
import {green} from "@material-ui/core/colors";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Grid, Box} from "@material-ui/core";


const ExerciseSaveDialog = (props) => {
    const classes = useStyles();
    
    return (
            <CustomizedDialogs
                open={props.open}
                title={'측정 결과 저장'}
                content={
                    <React.Fragment>
                        <Grid container justify="center" pb={3}>
                            <Grid item>
                                <CircularProgress style={{ color: green[500] }} />
                            </Grid>
                        </Grid>
                        <Box mt={3}>
                            <Typography gutterBottom className={classes.text}>
                                측정 결과를 저장중입니다.
                            </Typography>
                            <Typography gutterBottom className={classes.text}>
                                해당 팝업이 오랫동안 종료되지 않으면 종료버튼을 눌러 종료하세요
                            </Typography>
                        </Box>
                        
                    </React.Fragment>
                }
                submit={props.close}
                buttonName={'종료'}
            />
    );
}

const useStyles = makeStyles(() => ({
    small: {
        fontSize: 14,
        color: '#999',
    },
    text:{
        fontFamily: 'Noto Sans KR',
        textAlign: 'center'
        
    },
}));

export default ExerciseSaveDialog
