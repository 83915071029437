import React, {forwardRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Box, Typography} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#333'
    },
    label: {
        fontSize: 14,
        color: '#333',
    },
    input: {
        padding: 2,
    },
    formControl: {
        fontSize: 14,
        '& .Mui-checked .MuiSvgIcon-root': {
            color : '#1261BD'
        }
    }
}));

const FindOutCheck = forwardRef((props, ref) => {
    const classes = useStyles();
    const [value, setValue] = React.useState([false, false, false, false, false]);

    const handleChange = (event) => {
        const index = parseInt(event.target.name)
        
        const checkValue = value
        checkValue[index - 1] = event.target.checked
        setValue(checkValue)
        
        try {
            const toValidValue = []
            checkValue.map((isCheck, index) => {
                if(isCheck){
                    toValidValue.push(index + 1)
                }
                return isCheck
            })
            props.valid(true, toValidValue.join(','))
        } catch {}
    };
    
    return (
        <Box pl={1}>
            <Typography className={classes.title}> 어떻게 알게 되셨나요? (선택사항) </Typography>
           
            <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                    <FormControlLabel
                        className={classes.label}
                        control={<Checkbox 
                            onChange={handleChange}
                            className={classes.input}
                            name="1" />}
                        label={<span className={classes.label}>오프라인 광고</span>}
                    />
                    <FormControlLabel
                        control={<Checkbox
                            onChange={handleChange}
                            className={classes.input}
                            name="2" />}
                        label={<span className={classes.label}>소셜미디어 후기</span>}
                        
                    />
                    <FormControlLabel
                        className={classes.label}
                        control={<Checkbox
                            onChange={handleChange}
                            className={classes.input}
                            name="3" />}
                        label={<span className={classes.label}>기타</span>}
                    />
                </FormGroup>
            </FormControl>
            
            <FormControl required  component="fieldset" className={classes.formControl}>
                <FormGroup>
                    <FormControlLabel
                        className={classes.label}
                        control={<Checkbox
                            onChange={handleChange}
                            className={classes.input}
                            name="4" />}
                        label={<span className={classes.label}>온라인 배너 광고</span>}
                    />
                    <FormControlLabel
                        className={classes.label}
                        control={<Checkbox
                            onChange={handleChange}
                            className={classes.input}
                            name="5" />}
                        label={<span className={classes.label}>지인 추천</span>}
                    />
                </FormGroup>
            </FormControl>
        </Box>
    );
})

export default FindOutCheck