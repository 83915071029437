import React from "react";
import {Box} from "@material-ui/core";
import ReactAudioPlayer from 'react-audio-player';

export default class AudioPlayer extends React.Component {
    audioRef = React.createRef()
    audioEffectRef = React.createRef()
    audioEffect1Ref = React.createRef()
    audioEffect2Ref = React.createRef()
    
    soundSet = [
        require(`@assets/audio/soundRest.mp3`),
        require(`@assets/audio/soundAttemption.mp3`),
        require(`@assets/audio/soundConst.mp3`),
        require(`@assets/audio/soundBalance.mp3`)
    ]
    
    effectSet = []
    
    constructor(props) {
        super(props)
        this.state = {
            mode: props.mode,
            onAudioEnded: props.onAudioEnded,
        }
    }
    
    setMode(mode) {
        const audio = this.audioRef.current.audioEl
        switch (mode) {
            case 'rest':
                audio.src = this.soundSet[0]
                break
            case 'attemption':
                audio.src = this.soundSet[1]
                break
            case 'concentration':
                audio.src = this.soundSet[2]
                break
            case 'balance':
                audio.src = this.soundSet[3]
                break
            default:
                audio.src = this.soundSet[0]
        }
    }
    
    componentDidMount() {
        this.setMode(this.state.mode)
    }
    
    playbackRate(playbackRate){
        this.audioRef.current.playbackRate = playbackRate 
    }
    
    play(effect = '', volume = 1) {
        const audio = this.audioRef.current.audioEl
        if('' !== effect){
            audio.src = this.effectSet[effect]
        }
        if(1 !== volume){
            audio.volume = volume
        }
        
        audio.pause()
        audio.currentTime = 0
        audio.play()
    }

  playWith(sound, volume = 0.5) {
    const audio = this.audioRef.current.audioEl
    if('' !== sound){
      audio.src =sound
    }
    if(1 !== volume){
      audio.volume = volume
    }

    audio.pause()
    audio.currentTime = 0
    audio.play()
  }
    
    pause() {
        const audio = this.audioRef.current.audioEl
        audio.pause()

        const audioEffect = this.audioEffectRef.current.audioEl
        audioEffect.pause()
    }
    
    effect(type, volume) {
        const audio = this.audioEffectRef.current.audioEl
        if(volume){
            audio.volume = volume
        }
        audio.src = this.effectSet[type]
        audio.play()
    }
    
    
    loadEffect(effectType){
        switch (effectType){
            case 'eeg':
                this.effectSet = {
                    'closeEyes': require('@audio/01-03.mp3'),
                    'openEyes': require('@audio/01-04.mp3'),
                }
                break
            case 'memoryEgg':
            case 'balance':
                this.effectSet = {
                    'start': require('@assets/exercise/memoryEgg/audio/Start.mp3'),
                    'stop': require('@assets/exercise/memoryEgg/audio/End.mp3'),
                    'show': require('@assets/exercise/memoryEgg/audio/Star1.mp3')
                }
                break
            case 'concentration':
                this.effectSet = {
                    'start': require('@assets/exercise/memoryEgg/audio/Start.mp3'),
                    'stop': require('@assets/exercise/memoryEgg/audio/End.mp3'),
                    'show': require('@assets/exercise/memoryEgg/audio/Star1.mp3'),
                    'hit': require('@assets/exercise/concentration/audio/hit.mp3'),
                    'ready': require('@assets/exercise/concentration/audio/ready.mp3'),
                    'shoot': require('@assets/exercise/concentration/audio/shoot.mp3'),
                    'readyToShoot': require('@assets/exercise/concentration/audio/readyToShoot.mp3')
                }
                break
            case 'synapse':
                this.effectSet = {
                    'hit': require('@assets/exercise/concentration/audio/hit.mp3'),
                }
                break
            case 'breathStamp':
                this.effectSet = {
                    'breath': require('@assets/exercise/breath/audio/breathWind.mp3'),
                    'stop': require('@assets/exercise/memoryEgg/audio/End.mp3'),
                }
                break
            case 'meditation':
            case 'meditationHeal':
                this.effectSet = {
                    0: require('@assets/exercise/meditation/audio/meditation1.mp3'),
                    1: require('@assets/exercise/meditation/audio/meditation2.mp3'),
                    2: require('@assets/exercise/meditation/audio/meditation4.mp3'),
                    3: require('@assets/exercise/meditation/audio/meditation5_1.mp3'),
                    4: require('@assets/exercise/meditation/audio/meditation7.mp3'),
                    5: require('@assets/exercise/meditation/audio/meditation8.mp3'),
                    6: require('@assets/exercise/meditation/audio/meditation10.mp3'),
                    7: require('@assets/exercise/meditation/audio/meditation11.mp3'),
                    8: require('@assets/exercise/meditation/audio/meditation12.mp3'),
                    9: require('@assets/exercise/meditation/audio/meditation13.mp3'),
                    10: require('@assets/exercise/meditation/audio/meditation15.mp3'),
                    11: require('@assets/exercise/meditation/audio/meditation16.mp3'),
                    12: require('@assets/exercise/meditation/audio/meditation17.mp3'),
                    13: require('@assets/exercise/meditation/audio/meditation19.mp3'), 
                    'fade': require('@assets/exercise/meditation/audio/fade.mp3'),
                    'blink': require('@assets/exercise/meditation/audio/blink.mp3'),
                    'windChime1': require('@assets/exercise/meditation/audio/windChime1.mp3'),
                    'windChimes2': require('@assets/exercise/meditation/audio/windChimes2.mp3'),
                    'windChimes21': require('@assets/exercise/meditation/audio/windChimes21.mp3'),
                    'windChimes4': require('@assets/exercise/meditation/audio/windChimes4.mp3'),
                    'ex15': require('@assets/exercise/meditation/audio/ex15.mp3'),
                    'ex16': require('@assets/exercise/meditation/audio/ex16.mp3'),
                    'ex09': require('@assets/exercise/meditation/audio/ex09.mp3'),
                    'ex30': require('@assets/exercise/meditation/audio/ex30.mp3'),
                    'ex31': require('@assets/exercise/meditation/audio/ex31.mp3'),
                    'start': require('@assets/exercise/memoryEgg/audio/Start.mp3'),
                    'eyeClose': require('@audio/01-03.mp3'),
                    'eyeOpen': require('@audio/01-04.mp3'),
                }
                // this.effectSet = {
                //     0: require('@assets/exercise/meditation/audio/meditation1.mp3'),
                //     1: require('@assets/exercise/meditation/audio/meditation2.mp3'),
                //     2: require('@assets/exercise/meditation/audio/meditation3.mp3'),
                //     3: require('@assets/exercise/meditation/audio/meditation4.mp3'),
                //     4: require('@assets/exercise/meditation/audio/meditation5.mp3'),
                //     5: require('@assets/exercise/meditation/audio/meditation6.mp3'),
                //     6: require('@assets/exercise/meditation/audio/meditation7.mp3'),
                //     7: require('@assets/exercise/meditation/audio/meditation8.mp3'),
                //     8: require('@assets/exercise/meditation/audio/meditation9.mp3'),
                //     9: require('@assets/exercise/meditation/audio/meditation10.mp3'),
                //     10: require('@assets/exercise/meditation/audio/meditation11.mp3'),
                //     11: require('@assets/exercise/meditation/audio/meditation12.mp3'),
                //     12: require('@assets/exercise/meditation/audio/meditation13.mp3'),
                //     13: require('@assets/exercise/meditation/audio/meditation14.mp3'),
                //     14: require('@assets/exercise/meditation/audio/meditation15.mp3'),
                //     15: require('@assets/exercise/meditation/audio/meditation16.mp3'),
                //     16: require('@assets/exercise/meditation/audio/meditation16_1.mp3'),
                //     17: require('@assets/exercise/meditation/audio/meditation17.mp3'),
                //     18: require('@assets/exercise/meditation/audio/meditation18.mp3'),
                //     19: require('@assets/exercise/meditation/audio/meditation18_1.mp3'),
                //     20: require('@assets/exercise/meditation/audio/meditation19.mp3'),
                //     'fade': require('@assets/exercise/meditation/audio/fade.mp3'),
                //     'blink': require('@assets/exercise/meditation/audio/blink.mp3'),
                //     'windChime1': require('@assets/exercise/meditation/audio/windChime1.mp3'),
                //     'windChimes2': require('@assets/exercise/meditation/audio/windChimes2.mp3'),
                //     'windChimes21': require('@assets/exercise/meditation/audio/windChimes21.mp3'),
                //     'windChimes4': require('@assets/exercise/meditation/audio/windChimes4.mp3'),
                //     'ex15': require('@assets/exercise/meditation/audio/ex15.mp3'),
                //     'ex16': require('@assets/exercise/meditation/audio/ex16.mp3'),
                //     'ex09': require('@assets/exercise/meditation/audio/ex09.mp3'),
                //     'ex30': require('@assets/exercise/meditation/audio/ex30.mp3'),
                //     'ex31': require('@assets/exercise/meditation/audio/ex31.mp3'),
                //     'start': require('@assets/exercise/memoryEgg/audio/Start.mp3'),
                //     'eyeClose': require('@audio/01-03.mp3'),
                //     'eyeOpen': require('@audio/01-04.mp3'),
                // }
                break
            case 'meditationTest':
                this.effectSet = {
                    0: require('@assets/exercise/meditation/audio/meditation1.mp3'),
                    1: require('@assets/exercise/meditation/audio/meditation5.mp3'),
                    2: require('@assets/exercise/meditation/audio/meditation10.mp3'),
                    3: require('@assets/exercise/meditation/audio/meditation15.mp3'),
                    // 4: require('@assets/exercise/meditation/audio/meditation17.mp3'),
                    
                    'fade': require('@assets/exercise/meditation/audio/fade.mp3'),
                    'blink': require('@assets/exercise/meditation/audio/blink.mp3'),
                    'windChime1': require('@assets/exercise/meditation/audio/windChime1.mp3'),
                    'windChimes2': require('@assets/exercise/meditation/audio/windChimes2.mp3'),
                    'windChimes21': require('@assets/exercise/meditation/audio/windChimes21.mp3'),
                    'windChimes4': require('@assets/exercise/meditation/audio/windChimes4.mp3'),
                    'ex15': require('@assets/exercise/meditation/audio/ex15.mp3'),
                    'ex16': require('@assets/exercise/meditation/audio/ex16.mp3'),
                    'ex09': require('@assets/exercise/meditation/audio/ex09.mp3'),
                    'ex30': require('@assets/exercise/meditation/audio/ex30.mp3'),
                    'ex31': require('@assets/exercise/meditation/audio/ex31.mp3'),
                    'start': require('@assets/exercise/memoryEgg/audio/Start.mp3'),
                    'MM': require(`@assets/audio/soundRest.mp3`),
                    'MB': require(`@assets/audio/soundRest.mp3`),
                }
                break
            default:
                this.effectSet = {
                    'start': require('@assets/exercise/memoryEgg/audio/Start.mp3'),
                    'stop': require('@assets/exercise/memoryEgg/audio/End.mp3'),
                    'show': require('@assets/exercise/memoryEgg/audio/Star1.mp3')
                }
        }
    }
    
    render() {
        return (
            <Box>
                <ReactAudioPlayer
                    ref={ this.audioRef }
                    onEnded={this.state.onAudioEnded}
                />
                <ReactAudioPlayer ref={ this.audioEffectRef }/>
                <ReactAudioPlayer ref={ this.audioEffect1Ref }/>
                <ReactAudioPlayer ref={ this.audioEffect2Ref }/>
            </Box>
        )
    }
}