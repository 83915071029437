import React, {useEffect} from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { Box, Grid } from '@material-ui/core';
import { green, grey, red } from '@material-ui/core/colors';


const DeviceDisconnect = (props) => {
    const classes = useStyles()
    useEffect(()=>{
    },[])

    return (
        <Box className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <ErrorIcon style={ { fontSize: 100, color: red[300] } }/> 
                </Grid>
                <Grid item xs={12}>
                    <h2 style={ { textAlign: 'center' } }>기기와 연결이 해제되었습니다.</h2>    
                </Grid>
            </Grid>
        </Box>
    )
}


const useStyles = makeStyles(theme => ({
    subTitle: {
        fontSize: 22
    },
}));


export default DeviceDisconnect
