import React from 'react';
import ReactDOM from 'react-dom';
import * as Three from 'three';
import CameraControls from 'camera-controls';
import Chroma from 'chroma-js';
import DownArrow from '@assets/exercise/fft/downarrow.png';
import UpArrow from '@assets/exercise/fft/uparrow.png';
import TextSprite from '@seregpie/three.text-sprite';
import _ from "lodash";

export default class FFTChart extends React.Component {
    container;
    canvas;
    labels;
    arrows;
    
    height = 700;
    
    cameraControls;
    clock;
    renderer;
    scene;
    camera;
    
    graphColors;
    graphGroup;
    graphMaterial;
    graphWireFrame;
    graphWireFrameColor;
    graphWireFrameMaterial;
    
    gridSize;
    
    timeCount;
    timeGuide;
    timeGuideColor;
    timeGuideUnits;
    timeGuideInterval = 40;

    //remote queue first data.
    maxTimeline;

    hzCount;
    hzGuide;
    hzGuideColor;
    hzGuideUnits;

    mvCount;
    mvSize;
    mvGuide;
    mvGuideColor;
    mvGuideUnits;
    mvGuidLabel;
    mvGuideBoxMesh
    
    
    mvGuideLineXY;
    mvGuideBoxMesh2
    mvGuidBYLineGroup = [];
    mvGuidXLineGroup = []
    mvGuidYLineGroup = []
    mvGuidTextGroup = [];
    
    mvTooltip;
    mvTooltipLabel;
    
    timeHzGrid;
    timeHzGridColor;
    
    timeMvGrid;
    timeMvGridColor;
    
    mvHzGrid;
    mvHzGridColor;

    mvArrows;
    
    cone;
    orderIndex = 0;
    
    guidArrow;
    arrowImage;

    fftGuide = true;
    fftGuidHz = 20;
    fftGuidMv = 7.5;
    fftGuidArrowInterval = 5;
    fftGuidHeightOffset = 10;

    upscaleRate = []
    noise = [0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01]
    mvScalePercent = 0
    noiseCode = 'A'

    cameraZoom = -0.5842574538597803
    currentCameraType = ''
    cameraPosition = {
        'top' : [42.24043847740406, 42.814485770980006, 24.633821490356738 , 42.24043847523972, -9.919529645946959, 24.633768756341365],
        'front': [-0.7486032521730195, 28.215403432311106, 29.607473029866885 , 45.564013897166774, 6.996008690145955, 29.605572240000182],
        //'side': [30.124636461775268, 25.282947365332017, 55.14567593105667,45.5679933963594, -7.595409680770065, 16.91750900488123]
        //'side': [30.95468057065358, 27.50471083941312, 53.57015530919996 , 46.39803476830249, -5.373655369601182, 15.341995157977466]
        // 'side' : [37.18167306675405, 19.96481991680698, 61.02278685052439 , 47.00919727987373, -6.218952243294621, 16.315894481404797]
        'side': [30.222102525423466, 19.07490374562707, 60.30900638360478 , 44.9519769488083, -0.7274653808321182, 13.706717186116006]
    }
    
    defaultBoxMaterial = new Three.MeshPhysicalMaterial({
        // vertexColors: Three.VertexColors,
        side: Three.DoubleSide,
        flatShading: true,
        color: new Three.Color( 0x0c058e )

    });

    physicalBoxMaterial = new Three.MeshPhysicalMaterial({
        vertexColors: Three.VertexColors,
        side: Three.DoubleSide,
        flatShading: true
    });
    
    
    brainType  = 'both'
    currentCamera = {
        target: {},
        position: {}
    } 
    
    cameraUpdate
    
    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
            
        CameraControls.install( { THREE: Three } );
        const options = props.options || {};
         // Initialize options
        this.graphColors = options.graphColors
                            ? options.graphColors 
                            : Chroma.scale(['0000ff','00efff','ffffff', '00d917','f3ff00','f800f0', 'ff0000', '860101']).mode('lch')

        
        this.timeGuideInterval = options.timeGuideInterval === undefined ? 40 : options.timeGuideInterval;
        this.graphWireFrame = options.graphWireFrame === undefined ? false : options.graphWireFrame;
        this.height = props.options.height ? props.options.height : this.height
        
        this.marginTop = props.marginTop || 0;
        
        if (this.graphWireFrame === true) {
            this.graphWireFrameColor = options.graphWireFrameColor || 0x666666;
        }
        
        this.gridSize = options.gridSize || 1;
        
        this.timeCount = options.timeCount || 120;
        this.timeGuide = options.timeGuide === undefined ? true : options.timeGuide;
        
        if (this.timeGuide === true) {
            this.timeGuideColor = options.timeGuideColor || 0x888888;
            this.timeGuideUnits = options.timeGuideUnits || [ 40, 80, 120];
        }

        this.hzCount = options.hzCount || 30;
        this.hzSpacing = ( options.hzSpacing ? options.hzSpacing : 0)
        this.hzCount = this.hzCount + this.hzSpacing
        
        this.hzGuide = options.hzGuide === undefined ? true : options.hzGuide;
        
        if (this.hzGuide === true) {
            this.hzGuideColor = options.hzGuideColor || 0x888888;
            this.hzGuideUnits = options.hzGuideUnits || [ 0, 3, 7, 12, 18, 28, 38 ];
        }

        this.mvCount = options.mvCount || 50;
        this.mvSize = options.mvSize || 1;
        this.mvGuide = options.mvGuide === undefined ? true : options.mvGuide;

        // XY 그리드 처리 
        this.mvGuideLineXY = options.mvGuideLineXY === undefined ? true : options.mvGuideLineXY;
        
        if (this.mvGuide === true) {
            this.mvGuideColor = options.mvGuideColor || 0xdddddd;
            this.mvGuideUnits = options.mvGuideUnits || [ 10, 20, 30, 40, 50 ];
            this.mvGuideLabels = [];
        }
        
        this.timeHzGrid = options.timeHzGrid === undefined ? true : options.timeHzGrid;
        
        if (this.timeHzGrid === true) {
            this.timeHzGridColor = options.timeHzGridColor || 0xCCCCCC;
        }
        
        this.timeMvGrid = options.timeMvGrid === undefined ? true : options.timeMvGrid;
        
        if (this.timeMvGrid === true) {
            this.timeMvGridColor = options.timeMvGridColor || 0xCCCCCC;
        }
        
        this.mvHzGrid = options.mvHzGrid === undefined ? true : options.mvHzGrid;
        
        if (this.mvHzGrid === true) {
            this.mvHzGridColor = options.mvHzGridColor || 0xCCCCCC;
        }


        this.maxTimeline = options.maxTimeline === undefined ? 120 : options.maxTimeline; 
        
        this.cone = options.cone === undefined ? true : options.cone;
        this.cone = false

        this.mvTooltip = false;

        this.fftGuide = options.fftGuide === undefined ? false : options.fftGuide;
        this.mvArrows = [];
        
        this.noiseCode = options.noiseCode
        
        if(null !== props.label){
            this.mvGuidLabel = props.label
        }
        
        this.cameraUpdate = props.cameraUpdate
    }
    
    /**
     * After render
     */
    componentDidMount() {
        this.container = ReactDOM.findDOMNode(this);
        this.canvas = this.container.querySelector('canvas');
        this.labels = this.container.querySelector('.labels');
        this.arrows = this.container.querySelector('.arrows');

        // Initialize 3D objects
        this.init3D();
        this.drawGuide();
    
        // requestAnimationFrame(this.renderChart.bind(this));
        // this.renderer.render(this.scene, this.camera);
        this.renderChartOnce()
    }
    
    componentWillUnmount() {
        this.graphGroup = []
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log(prevProps.options.brainType, prevProps.options.cameraType)
        // const brainType = prevProps.options.brainType
        // if(this.pre)
    }

    /**
     * Render
     */
    render() {
        const wrapHeight =  window.innerHeight < this.height ? this.height : window.innerHeight
        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: wrapHeight}}>
                <div className="fft-chart-container" style={{height: this.height, marginTop: this.marginTop}}>
                    <canvas style={{height: '100%'}}/>
                    <div className="labels"/>
                    <div className="arrows"/>
                </div>
            </div>
        );
    }
    
    /**
     * Initialize 3D objects
     */
    init3D() {
        this.clock = new Three.Clock();

        // Renderer
        this.renderer = new Three.WebGLRenderer({
            canvas: this.canvas,
            antialias: true,
            alpha: true,
            preserveDrawingBuffer: true 
        });
        this.renderer.setClearColor( 0x000000, 0 )
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.container.offsetWidth, this.height);
        
        // Scene
        this.scene = new Three.Scene();
        
        // Camera
        const width  = this.canvas.width;
        const height = this.canvas.height;
        

        this.camera = new Three.OrthographicCamera(
            -width / 2, width / 2,
            height / 2, -height / 2,
            1, 1000
        );
        
        // Camera controls
        this.cameraControls = new CameraControls(this.camera, this.renderer.domElement);
        this.cameraControls.mouseButtons.left = CameraControls.ACTION.TRUCK;
        this.cameraControls.mouseButtons.right = CameraControls.ACTION.ROTATE;
        this.cameraControls.mouseButtons.wheel = CameraControls.ACTION.ZOOM;
        this.cameraControls.enabled = false;

        this.camera.zoom = 1
        
        // if (aspect > 1) {
        //     this.camera.left = -aspect;
        //     this.camera.right = aspect;
        //     this.camera.top = 1;
        //     this.camera.bottom = -1;
        // } else {
        //     this.camera.left = -1;
        //     this.camera.right = 1;
        //     this.camera.top = 1 / aspect;
        //     this.camera.bottom = -1 / aspect;
        // }



        // this.cameraControls.zoom(-0.5842574538597803, false);
        this.cameraControls.setLookAt(this.cameraPosition['side'])
        // [30.124636461775268, 25.282947365332017, 55.14567593105667,45.5679933963594, -7.595409680770065, 16.91750900488123]

        this.cameraControls.addEventListener('update',(val) => {
            // const p = this.cameraControls.getPosition()
            // const t = val.target._target
            // console.log(`${p.x}, ${p.y}, ${p.z} , ${t.x}, ${t.y}, ${t.z}`)

            
            this.currentCamera.target = val.target._target
            this.currentCamera.position = this.cameraControls.getPosition()
            if(this.cameraUpdate){
                this.cameraUpdate(this.currentCamera)
            }
        })
        
        
    
        

        // Chart object
        this.graphGroup = new Three.Object3D();
        this.graphGroup.position.x = this.gridSize;
        this.scene.add(this.graphGroup);

       
        // Hemi Light
        const hemiLight = new Three.HemisphereLight(0xffffff, 0xffffff, 0.6);
        hemiLight.color.setHSL(0.6, 1, 0.6);
        hemiLight.groundColor.setHSL(0.095, 1, 0.75);
        hemiLight.position.set(0, 50, 0);
        this.scene.add(hemiLight);

        // Direction Light
        const dirLight = new Three.DirectionalLight(0xffffff, 1);
        dirLight.color.setHSL(0.1, 1, 0.95);
        dirLight.position.set(-1, 1.75, 1);
        dirLight.position.multiplyScalar(30);
        dirLight.castShadow = true;
        dirLight.shadow.mapSize.width = 2048;
        dirLight.shadow.mapSize.height = 2048;

        const d = 50;
        
        dirLight.shadow.camera.left = -d;
        dirLight.shadow.camera.right = d;
        dirLight.shadow.camera.top = d;
        dirLight.shadow.camera.bottom = -d;
        
        dirLight.shadow.camera.far = 3500;
        dirLight.shadow.bias = -0.0001;
    
        this.scene.add(dirLight);
    
        // Graph material
        this.graphMaterial = new Three.MeshPhysicalMaterial({
            vertexColors: Three.VertexColors,
            side: Three.DoubleSide,
            flatShading: true,
        });
        
        // Use graph wire frame
        if (this.graphWireFrame === true) {
            this.graphWireFrameMaterial = new Three.LineBasicMaterial({
                color: this.graphWireFrameColor,
                linewidth: 1,
               vertexColors: true,
            });
        }
    };
    
    /**
     * Render chart
     */
    renderChart() {
        // if(this.cameraControls.enabled){
            requestAnimationFrame(this.renderChart.bind(this));
            
            if(this.cameraControls.update( this.clock.getDelta() )) {
                this.renderer.setRenderTarget( null );
                this.renderer.render(this.scene, this.camera);
            }
        // }
    }
    
    /**
     * render canvas once 
     */
    renderChartOnce() {
        this.renderer.setRenderTarget( null );
        this.renderer.render(this.scene, this.camera);
    }

    /**
     * Resize renderer to display(canvas) size
     * @returns {boolean}
     */
    resizeToDisplay() {
        const width = this.canvas.clientWidth;
        const height = this.canvas.clientHeight;
        const needResize = this.canvas.width !== width || this.canvas.height !== height;
        
        if (needResize) {
            this.renderer.setSize(width, height, false);
        }
        
        return needResize;
    }


    
    /**
     * Set label position
     * @param label
     * @param paddingX
     * @param paddingY
     * @param paddingZ
     */
    setLabelPosition(label, paddingX, paddingY, paddingZ) {
        const { line, text } = label;
        const vector = new Three.Vector3();
       
        if(line){
            line.updateWorldMatrix(true, false);
            line.getWorldPosition(vector);
        }
        
 
        vector.x += paddingX;
        vector.y += paddingY;
        vector.z += paddingZ;
        vector.project(this.camera);

        const transX = (vector.x * .5 + .5) *  this.canvas.clientWidth;
        const transY = (vector.y * -.5 + .5) * this.canvas.clientHeight;

        text.style.display = '';
        text.style.transform = `translate(-50%, -50%) translate(${ transX }px, ${ transY }px)`;
        text.style.zIndex = (-vector.z * .5 + .5) * 100000 | 0;
    }


    translateArrows(){
        for (const arrow of this.mvArrows) {
            this.setImagePosition(arrow, -1, 2, 0);
        }
    }

    setImagePosition(arrow, paddingX, paddingY, paddingZ) {
        const { image, line,  direction } = arrow;
        const vector = new Three.Vector3();
        
        if(line){
            line.updateWorldMatrix(true, false);
            line.getWorldPosition(vector);
        }
    
        vector.x += paddingX;
        vector.y += paddingY + ('up' === direction ? 6 : this.fftGuidHeightOffset);
        vector.z += paddingZ;
        vector.project(this.camera);

        const transX = (vector.x * .5 + .5)  * this.canvas.clientWidth;
        const transY = (vector.y * -.5 + .5) * this.canvas.clientHeight;
        
        image.style.width = '25px';
        image.style.height = '25px';
        image.style.backgroundImage = `url(${'up' === direction? UpArrow : DownArrow})`;
        image.style.backgroundSize = 'contain'
        image.style.display = '';
        image.style.transform = `translate(-50%, -50%) translate(${ transX }px, ${ transY }px)`;
        image.style.zIndex = (-vector.z * .5 + .5) * 100000 | 0;
    }

    /**
     *
     * @param x
     * @param y
     * @param z
     */
    setMvTooltipPosition(x, y, z) {
        const { line, text } = this.mvTooltipLabel;
        const vector = new Three.Vector3();
        
        line.updateWorldMatrix(true, false);
        line.getWorldPosition(vector);
        
        vector.x += x;
        vector.y += y;
        vector.z += z;
        vector.project(this.camera);

        const transX = (vector.x * .5 + .5) * this.canvas.clientWidth;
        const transY = (vector.y * -.5 + .5) * this.canvas.clientHeight;
        
        text.style.display = '';
        text.style.transform = `translate(-50%, -50%) translate(${ transX }px, ${ transY }px)`;
        text.style.zIndex = (-vector.z * .5 + .5) * 100000 | 0;
    }

    /**
     * mv정보 표시
     * @param {*} userData 
     */
    displayTooltipText(userData){
        let text;

        if (!userData){
            text = '';
        } else {
            text = ' ['+userData.time+'초] ' + userData.hz + 'hz : ' + parseFloat(userData.mv * 2).toFixed(2) + 'mV ';
        }
        this.mvTooltipLabel.text.textContent =  text;
    }

    /**
     * Create 2D text
     * @param text
     * @returns {HTMLDivElement}
     */
    create2DText(text) {
        const element = document.createElement('div');
        element.textContent = text;
        
        this.labels.appendChild(element);
        
        return element;
    };

    create2DImage(){
        const element = document.createElement('div');
        this.arrows.appendChild(element);
        return element;
    }
    
    /**
     * Draw guide line
     */
    drawGuide() {
        let geometry, material, line, point, color, i, boxHeight, face, 
            numberOfSides, j, vertexIndex, boxMaterial;
        const faceIndices = [ 'a', 'b', 'c', 'd' ];

        if (this.mvGuide === true) {
            let segments = Math.ceil(50 / 1.5);
            let mvLabelWidth = 0.01
            segments = segments > 0 ? segments : 1;
            boxHeight = this.mvCount * this.mvSize;

            const boxGeometry2 = new Three.BoxGeometry(mvLabelWidth, boxHeight, this.hzSpacing / 3, 1, segments, 1);
            boxGeometry2.translate(
                0 ,
                boxHeight / 2,
                this.hzCount / 2 * this.gridSize + (this.gridSize /2) - ((this.gridSize /2)/2)  
            );

            
            segments = Math.ceil(50 / 1.5);
            mvLabelWidth = 2
            segments = segments > 0 ? segments : 1;
            boxHeight = this.mvCount * this.mvSize;

            const boxGeometry = new Three.BoxGeometry(mvLabelWidth, boxHeight, 0.01, 1, segments, 1);
            boxGeometry.translate(
                mvLabelWidth - (mvLabelWidth/2) ,
                boxHeight / 2,
                0
            );
            

            for (i = 0; i < boxGeometry.vertices.length; i++) {
                point = boxGeometry.vertices[i];
                color = this.graphColors(point.y / 15).rgb();
                boxGeometry.colors[i] = new Three.Color(`rgb(${ color[0] }, ${ color[1] }, ${ color[2] })`);
                boxGeometry2.colors[i] = boxGeometry.colors[i]
            }
            
            for (i = 0; i < boxGeometry.faces.length; i++) {
                face = boxGeometry.faces[i];
                numberOfSides = (face instanceof Three.Face3) ? 3 : 4;
                
                for (j = 0; j < numberOfSides; j++) {
                    vertexIndex = face[faceIndices[j]];
                    face.vertexColors[j] = boxGeometry.colors[vertexIndex];
                }
                
                boxGeometry2.faces[i] = face;
            }  

            boxMaterial = new Three.MeshPhysicalMaterial({
                vertexColors: Three.VertexColors,
                side: Three.DoubleSide,
                flatShading: true
            });
            
            this.mvGuideBoxMesh = new Three.Mesh(boxGeometry, boxMaterial);
            this.scene.add(this.mvGuideBoxMesh);

            
            if(this.mvGuideLineXY){
                this.mvGuideBoxMesh2 = new Three.Mesh(boxGeometry2, boxMaterial);
                this.scene.add(this.mvGuideBoxMesh2);    
            }
            
            // unit
            // geometry = new Three.Geometry();
            // geometry.vertices.push(new Three.Vector3(-1.3, 0, 0));
            // geometry.vertices.push(new Three.Vector3(120 * this.gridSize, 0, 0));

            material = new Three.LineBasicMaterial({ color: this.mvGuideColor });
     
            // 높이 가이드 라인 
            for (const unit of this.mvGuideUnits) {
                const geometry = new Three.Geometry();
                geometry.vertices.push(new Three.Vector3(0, 0, 0));
                geometry.vertices.push(new Three.Vector3(0, 0, 0));

                const xLine = new Three.Line(geometry, material);

                const yGeometry = new Three.Geometry();
                yGeometry.vertices.push(new Three.Vector3(0, 0, 0));
                yGeometry.vertices.push(new Three.Vector3(0, 0, this.gridSize * this.hzCount));

                const yLine = new Three.Line(yGeometry, material);

                xLine.position.y = unit * this.mvSize;
                yLine.position.y = unit * this.mvSize;

                let textOptions = undefined;
                let textX = xLine.position.x - 1.3
                let textZ =  xLine.position.z
                if('front' === this.currentCameraType){
                    textX = -3.4;
                    textZ =   this.hzCount * this.gridSize / 2;
                    textOptions = {
                        fontSize: 1.1,
                        color: '#000'
                    }
                }
                const text = this.textSprite(`${unit}`, textX, xLine.position.y, textZ, textOptions)
                this.scene.add(text);

                this.mvGuidTextGroup.push(text)

                
                if(this.mvGuideLineXY){
                    this.scene.add(xLine);
                    this.scene.add(yLine);
                    //
                    // this.scene.add(this.textSprite(
                    //     `${unit}`,
                    //     this.hzCount + this.gridSize - 1,
                    //     unit * this.mvSize - this.gridSize ,
                    //     this.hzCount / 2 * this.gridSize - (this.gridSize / 2) + 1,
                    //     '#fff'
                    // ));

                    this.mvGuidXLineGroup.push(xLine)
                    this.mvGuidYLineGroup.push(yLine) 
                }
            }

            // 그리드 
            if(this.mvGuideLineXY) {
                for (let i = 0; i < 2; i++) {

                    const yGeometry = new Three.Geometry();
                    yGeometry.vertices.push(new Three.Vector3(0, 0, i * i * this.gridSize * this.hzCount));
                    yGeometry.vertices.push(new Three.Vector3(0, 50 * this.mvSize, i * this.gridSize * this.hzCount));

                    const line = new Three.Line(yGeometry, material);
                    this.mvGuidBYLineGroup.push(line)

                    this.scene.add(line);
                }
            }
            
            
            
            // if(this.mvGuidLabel){
            //     let text = new TextSprite({
            //         alignment: 'left',
            //         color:'#333',
            //         fontFamily: "'Noto Sans KR', sans-serif",
            //         fontSize: 1,
            //         text: `${this.mvGuidLabel}`
            //     });
            //
            //     text.position.z = line.position.z - 4;
            //     text.position.x = line.position.x + 1.5;
            //     text.position.y = line.position.y;
            //
            //     this.scene.add(text);
            // }
        }
        
        // HZ 0 ~ 38 까지이의 텍스트  
        if (this.hzGuide === true) {
            material = new Three.LineBasicMaterial({ color: this.mvGuideColor });
            
            // axis
            geometry = new Three.Geometry();
            geometry.vertices.push(new Three.Vector3(0, 0, 0));
            geometry.vertices.push(new Three.Vector3(0, 0, this.hzCount * this.gridSize));
            
            this.scene.add(new Three.Line(geometry, material));
            
            // unit
            geometry = new Three.Geometry();
            geometry.vertices.push(new Three.Vector3(0, 0, 0));
            geometry.vertices.push(new Three.Vector3(-1, 0, 0));
            
            const hzGuideUnits = [
                {z: 2, text: 28},
                {z: 12, text: 18},
                {z: 18, text: 12},
                {z: 23, text: 7},
                {z: 27, text: 3},
                {z: 30, text: 0},

                {z: 30 + this.hzSpacing, text: 0},
                {z: 33 + this.hzSpacing, text: 3},
                {z: 37 + this.hzSpacing, text: 7},
                {z: 42 + this.hzSpacing, text: 12},
                {z: 48 + this.hzSpacing, text: 18},
                {z: 58 + this.hzSpacing, text: 28},
            ]
            
            for (const unit of hzGuideUnits) {
                line = new Three.Line(geometry, material);
                line.position.z = unit.z * this.gridSize;
                
                const text = this.textSprite(`${unit.text}`, line.position.x - 2 , null, line.position.z, {})
                
                this.scene.add(line);
                this.scene.add(text);
            }
        }

        

        // HZ
        if (this.mvTooltip === true) {
            // unit
            geometry = new Three.Geometry();
            geometry.vertices.push(new Three.Vector3(0, 0, 0));
            geometry.vertices.push(new Three.Vector3(-1, 0, 0));
            
            line = new Three.Line(geometry, material);
            line.position.x = 5;
            line.position.y = -5;
            line.position.z = 10 * this.gridSize;
            
            let text = this.create2DText('');
            text.className = "mv-tooltip";
            this.mvTooltipLabel = {
                line,
                text,
            };
            this.scene.add(line);

        }

    }
    
    /**
     * Draw grid
     */
    drawGrid() {
        let i, geometry, material, startX, endX, startY, endY, startZ, endZ;

        /**
         * Create & Add line to scene
         * @param material
         * @param startX
         * @param startY
         * @param startZ
         * @param endX
         * @param endY
         * @param endZ
         */
        const addLine = (material, startX, startY, startZ, endX, endY, endZ) => {
            geometry = new Three.Geometry();
            geometry.vertices.push(new Three.Vector3(startX, startY, startZ));
            geometry.vertices.push(new Three.Vector3(endX, endY, endZ));
            
            this.scene.add(new Three.Line(geometry, material));
        };

        // Time * MV
        if (this.timeMvGrid === true) {
            material = new Three.LineBasicMaterial({ color: this.timeMvGridColor });
            
            startY = 0;
            endY = this.mvCount * this.mvSize;
            startZ = endZ = 0;

            // for (i = 0; i <= this.timeCount; i++) {
            //     startX = endX = i * this.gridSize;
                
            //     addLine(material, startX, startY, startZ, endX, endY, endZ);
            // }
            
            startX = 0;
            endX = this.timeCount * this.gridSize;
            startZ = endZ = 0;
            
            for (i = 0; i <= this.mvCount / 10; i++) {
                startY = endY = i * this.mvSize;
                
                addLine(material, startX, startY, startZ, endX, endY, endZ);
            }
        }
        
        // MV * HZ
        if (this.mvHzGrid === true) {
            material = new Three.LineBasicMaterial({ color: this.mvHzGridColor });
            
            startX = endX = this.timeCount * this.gridSize;
            startZ = 0;
            endZ = this.hzCount * this.gridSize;
            
            for (i = 0; i <= this.mvCount; i++) {
                startY = endY = i * this.mvSize;
                
                addLine(material, startX, startY, startZ, endX, endY, endZ);
            }

            startX = endX = this.timeCount * this.gridSize;
            startY = 0;
            endY = this.mvCount * this.mvSize;

            for (i = 0; i <= this.hzCount; i++) {
                startZ = endZ = i * this.gridSize;

                addLine(material, startX, startY, startZ, endX, endY, endZ);
            }
        }
        
        // Time * HZ
        if (this.timeHzGrid === true) {
            material = new Three.LineBasicMaterial({ color: this.timeHzGridColor });
            
            startX = 0;
            endX = this.timeCount * this.gridSize;
            startY = endY = 0;
            
            for (i = 0; i <= this.hzCount; i++) {
                startZ = endZ = i * this.gridSize;
                
                addLine(material, startX, startY, startZ, endX, endY, endZ);
            }

            startY = endY = 0;
            startZ = 0;
            endZ = this.hzCount * this.gridSize;
            
            for (i = 0; i <= this.timeCount; i++) {
                startX = endX = i * this.gridSize;
                
                addLine(material, startX, startY, startZ, endX, endY, endZ);
            }
        }
    }
    
    /**
     * Add data
     * @param order
     * @param ch1
     * @param ch2
     * @param withRender
     */
    addData(order, ch1, ch2, withRender = true) {
        if(!ch1 || 0 === ch1.length) return 
        
        if(order > this.maxTimeline){
            this.graphGroup.children = this.graphGroup.children.slice(1, this.maxTimeline);
        }
        
        const data = _.concat(ch1.slice(0, 30).reverse() , new Array(this.hzSpacing).fill(0), ch2.slice(0,30))
        this.orderIndex = order;
        
        let i, j, mv,  segments, color, point, face, numberOfSides, vertexIndex, boxHeight, boxMesh
            
        const graphs = new Three.Object3D();
        graphs.userData = { type: 'bar'}
        
        const faceIndices = [ 'a', 'b', 'c', 'd' ];

        const backgroundHeight = 0.01
        const backgroundGeometry = new Three.BoxGeometry(this.gridSize, backgroundHeight, this.gridSize * this.hzCount);

        backgroundGeometry.translate(
            (-order * this.gridSize) - (this.gridSize / 2),
            backgroundHeight / 2,
            (this.hzCount) / 2 * this.gridSize 
        );
        graphs.add(new Three.Mesh(backgroundGeometry, this.defaultBoxMaterial));

        
        const mergedGeo = new Three.Geometry(); // 하나로 병합할 geometry생성
        for (let hz = 0; hz < this.hzCount; hz++) {
            mv = data[hz] - (data[hz] * 0.3);
            mv = parseFloat((mv / 2).toFixed(2));

            // 최대치 설정
            if (mv > 50) { mv = 50; }
            
            boxHeight = mv === 0 ? 0.01 : mv * this.mvSize;
            
            segments = Math.ceil(mv / 1.5);
            segments = segments > 0 ? segments : 1;

            const boxGeometry = new Three.BoxGeometry(this.gridSize, boxHeight, this.gridSize, 1, segments, 1);
            
            boxGeometry.translate(
                (-order * this.gridSize) - (this.gridSize / 2),
                boxHeight / 2,
                hz * this.gridSize + (this.gridSize / 2)
            );
            
            // console.log(order, (-order * this.gridSize) - (this.gridSize / 2))

            if(0 < this.mvScalePercent){
                mv = mv - ( mv * this.mvScalePercent / 100)
            }
            
            if(0.1 <  mv){
                for (i = 0; i < boxGeometry.vertices.length; i++) {
                    point = boxGeometry.vertices[i];
                    color = this.graphColors(point.y / 13).rgb();
                    boxGeometry.colors[i] = new Three.Color(`rgb(${ color[0] }, ${ color[1] }, ${ color[2] })`);
                }

                for (i = 0; i < boxGeometry.faces.length; i++) {
                    face = boxGeometry.faces[i];
                    numberOfSides = (face instanceof Three.Face3) ? 3 : 4;

                    for (j = 0; j < numberOfSides; j++) {
                        vertexIndex = face[faceIndices[j]];
                        face.vertexColors[j] = boxGeometry.colors[vertexIndex];
                    }
                }

                boxMesh = new Three.Mesh(boxGeometry, this.physicalBoxMaterial);
                boxMesh.updateMatrix()
                mergedGeo.merge(boxMesh.geometry, this.physicalBoxMaterial.matrix)
            } 
        }
        
        const material = new Three.MeshPhysicalMaterial({
            vertexColors: Three.VertexColors,
            side: Three.DoubleSide,
            flatShading: true
        });
        const mergedMesh = new Three.Mesh( mergedGeo, material ); // mergedGeo를 사용하여 mesh를 생성한다.
        graphs.add(mergedMesh);
        
      
        // line draw
        if(this.mvGuideLineXY){
            this.mvGuideBoxMesh2.translateX(this.gridSize)
            for( let i = 0 ; i < this.mvGuidXLineGroup.length; i++){
                this.mvGuidXLineGroup[i].geometry.vertices[1].x = order * this.gridSize
                this.mvGuidXLineGroup[i].geometry.verticesNeedUpdate = true

                this.mvGuidYLineGroup[i].geometry.vertices[0].x = order * this.gridSize
                this.mvGuidYLineGroup[i].geometry.vertices[1].x = order * this.gridSize
                this.mvGuidYLineGroup[i].geometry.verticesNeedUpdate = true
            }

            for( let i = 0; i < this.mvGuidBYLineGroup.length; i++) {
                this.mvGuidBYLineGroup[i].geometry.vertices[0].x = order * this.gridSize
                this.mvGuidBYLineGroup[i].geometry.vertices[1].x = order * this.gridSize
                this.mvGuidBYLineGroup[i].geometry.verticesNeedUpdate = true
            }
        }
        
        if('front' === this.currentCameraType){
            for( let i = 0 ; i < this.mvGuidTextGroup.length; i++){
                this.mvGuidTextGroup[i].position.x += this.gridSize
            }
        }

        // Time
        if (this.timeGuide === true) {
            if (0 === order % this.timeGuideInterval) {
                let material = new Three.LineBasicMaterial({ color: this.timeGuideColor  });
            
                // unit
                const geometry = new Three.Geometry();
                geometry.vertices.push(new Three.Vector3(0, 0, 0));
                geometry.vertices.push(new Three.Vector3(0, 0, 1));
    
                const line = new Three.Line(geometry, material);
                line.position.x = -order * this.gridSize;
                line.position.z = this.hzCount * this.gridSize;
                const xOffset = 'front' === this.currentCameraType ? 0 : 0.5 
              
                // let xOffset = 0.5
                // let zOffset = 2.3
                // let yOffset = undefined;
                // let options = undefined
                // if('front' === this.currentCameraType){
                //     line.position.z = this.hzCount * this.gridSize / 2;
                //     xOffset = 0;
                //     zOffset = 0;
                //     yOffset = 1;
                //     options = {
                //         color: '#fff',
                //         fontSize: 1.3,
                //     }
                // }


                const text = this.textSprite(`${order}`, line.position.x - xOffset , null,  line.position.z + 2.3)
                
                // const text = this.textSprite(`${order}`, line.position.x - xOffset , yOffset, line.position.z + zOffset, options)
                
                graphs.add(line);
                graphs.add(text);
            }
        }

        this.graphGroup.add(graphs);
        this.graphGroup.translateX(this.gridSize);
        if( withRender ){
            this.renderChartOnce()
        }
    };



    textSprite(text, x, y, z, options) {
        const color = options && options.color ? options.color  : '#333'
        const fontSize = options && options.fontSize ? options.fontSize  : 0.9
        const fontWeight = options && options.fontWeight ? options.fontWeight  : 'normal'
        let t = new TextSprite({
            alignment: 'left',
            color:color,
            fontFamily: "'Lato', 'Noto Sans KR', sans-serif",
            fontSize: fontSize,
            fontWeight: fontWeight,
            text: `${text}`
        });

        if(x) t.position.x = x
        if(y) t.position.y = y
        if(z) t.position.z = z

        return t
    }
    
    addDataAll(fftData) {
        if(!fftData) return
        for(let i = 0 ; i < fftData.ch1.length; i++){
            this.addData(i+1, fftData.ch1[i] , fftData.ch2[i], false)    
        }

        this.renderChart()    
        this.renderChartOnce()

    };

    // 화면 크기에 따른 줌 값 계산 함수
    calculateZoom(camera, width, height) {
        const bounds = new Three.Box2();
        bounds.setFromPoints([
            new Three.Vector2(camera.left, camera.bottom),
            new Three.Vector2(camera.right, camera.top),
        ]);

        const targetWidth = bounds.max.x - bounds.min.x;
        const targetHeight = bounds.max.y - bounds.min.y;
        const aspect = width / height;

        const zoomX = width / targetWidth;
        const zoomY = height / targetHeight;
        const zoom = Math.min(zoomX, zoomY) * aspect;
        
        return zoom
    }

    initRender(params){
        this.renderChart()
        this.renderChartOnce()

        setTimeout(() => {
            // let lookAt = [28.921384621587947, 28.749362819870782, 55.362197384465524 , 45.73074625125482, -2.081352074670383, 12.414682058868186]
            let lookAt = this.cameraPosition['side']
            if(params && params.lookAtPos) {
                lookAt = params.lookAtPos
            }

            if(params && params.cameraType){
                lookAt = this.cameraPosition[params.cameraType];
                this.currentCameraType = params.cameraType;

                if('front' === this.currentCameraType){
                    const z = this.hzCount * this.gridSize / 2
                    for( let i = 0 ; i < this.mvGuidTextGroup.length; i++){
                        this.mvGuidTextGroup[i].position.x = -3.4
                        this.mvGuidTextGroup[i].position.z = z;
                    }
                }
            }
            const width  = this.canvas.width;
            const height = this.canvas.height;
            let zoomAspect = 7.5
            if(height > 900){
                zoomAspect = height / 900 * zoomAspect
            }
            const zoom = this.calculateZoom(this.camera, width, height) * zoomAspect;

            // this.camera.zoom = zoom * 10;
            // this.camera.updateProjectionMatrix();

            this.cameraControls.zoom(zoom, false);
            this.cameraControls.setLookAt(
                lookAt[0],lookAt[1],lookAt[2],lookAt[3],lookAt[4],lookAt[5],false
            );
        }, 600)

    }

    updateBrainType(brainType, fftData){
        const tempFftData = JSON.parse(JSON.stringify(fftData))
        this.brainType = brainType
        this.clearCanvas()
        
        if('both' === brainType){
            this.addDataAll(tempFftData)
        } else if('left' === brainType) {
            tempFftData.ch2 = new Array(tempFftData.ch2.length).fill(new Array(tempFftData.ch2[0].length).fill(0))
            this.addDataAll(tempFftData)
        } else if('right' === brainType) {
            tempFftData.ch2 = new Array(tempFftData.ch1.length).fill(new Array(tempFftData.ch1[0].length).fill(0))
            this.addDataAll(tempFftData)
        }
    }

    /**
     * 화면을 삭제합니다.
     */
    clearCanvas() {
        const xPos = (this.orderIndex * this.gridSize) * -1
        this.graphGroup.translateX(xPos);
        if(this.mvGuideLineXY){
            this.mvGuideBoxMesh2.translateX(xPos);
            if(this.mvGuideLineXY){
              for( let i = 0 ; i < this.mvGuidXLineGroup.length; i++){
                this.mvGuidXLineGroup[i].geometry.vertices[1].x = 0
                this.mvGuidXLineGroup[i].geometry.verticesNeedUpdate = true

                this.mvGuidYLineGroup[i].geometry.vertices[0].x = 0
                this.mvGuidYLineGroup[i].geometry.vertices[1].x = 0
                this.mvGuidYLineGroup[i].geometry.verticesNeedUpdate = true
              }

              for( let i = 0; i < this.mvGuidBYLineGroup.length; i++) {
                this.mvGuidBYLineGroup[i].geometry.vertices[0].x = 0
                this.mvGuidBYLineGroup[i].geometry.vertices[1].x = 0
                this.mvGuidBYLineGroup[i].geometry.verticesNeedUpdate = true
              }
            }
        }

        if('front' === this.currentCameraType){
            for( let i = 0 ; i < this.mvGuidTextGroup.length; i++){
                this.mvGuidTextGroup[i].position.x = -3.4
            }
        }
        this.graphGroup.children = [];
        this.renderer.clear()
        this.renderChartOnce();
    }



    saveCanvasImage() {
        this.canvas.focus();
        return this.canvas.toDataURL('image/png');
    }

    /**
     * 카메라 변경
     * @param params
     */
    setCamera(params){
        const { lookAt} = params;
        this.cameraControls.setLookAt(lookAt[0], lookAt[1],lookAt[2], lookAt[3], lookAt[4], lookAt[5], false);
        // this.cameraControls.setPosition(position[0], position[1], position[2], false);
        // this.renderer.render(this.scene, this.camera);

        // this.cameraControls.truck(truck[0], truck[1]);
        // this.cameraControls.setTarget(target[0], target[1], target[2], false);
    }
    setCameraByType(type){
        const lookAt = this.cameraPosition[type];
        this.cameraControls.setLookAt(lookAt[0], lookAt[1],lookAt[2], lookAt[3], lookAt[4], lookAt[5], false);
        
    }


    /**
     * 카메라 변경
     * @param direction
     */
    setCameraPosition(direction){
        const offset = 5
        switch (direction) {
            case 'left':
                this.currentCamera.position.z += offset
                this.currentCamera.position.x += offset / 2  
                break
            case 'right':
                this.currentCamera.position.z -= offset
                this.currentCamera.position.x -= offset / 2
                break
            case 'top':
                this.currentCamera.position.y -= offset
                break
            case 'bottom':
                this.currentCamera.position.y += offset
                break
            default:
        }
        
        this.setCamera({
            lookAt: [
                this.currentCamera.position.x,
                this.currentCamera.position.y,
                this.currentCamera.position.z,
                this.currentCamera.target.x,
                this.currentCamera.target.y,
                this.currentCamera.target.z
            ]
        })
        
    }

    /**
     * 특정 범위에 설정된 값의 비율을 조절합니다.
     * @param upscaleRate
     */
    setUpscaleRate(upscaleRate){
        const faceIndices = [ 'a', 'b', 'c', 'd' ];
        
        let timeObject, object, upscaleHz, boxGeometry, ii, jj, point, color, face, numberOfSides, vertexIndex;
        for(let i = 0 ; i < this.graphGroup.children.length; i++){
            timeObject = this.graphGroup.children[i];
            
            //hz
            for(let j = 0 ; j < timeObject.children.length; j++){
                object = timeObject.children[j]
                if(0 ===  object.userData.mv){
                    continue
                }
                
                upscaleHz = 0;
                if('Mesh' === object.type){
                    if(null != upscaleRate[j]){
                        upscaleHz = upscaleRate[j] / 100
                    }
                    object.scale.y = 1 + upscaleHz
                    boxGeometry = object.geometry
                    
                    for (ii = 0; ii < boxGeometry.vertices.length; ii++) {
                        point = boxGeometry.vertices[ii];
                        color = this.graphColors((point.y * object.scale.y) / 15).rgb();
                        boxGeometry.colors[ii] = new Three.Color(`rgb(${ color[0] }, ${ color[1] }, ${ color[2] })`);
                    }

                    for (ii = 0; ii < boxGeometry.faces.length; ii++) {
                        face = boxGeometry.faces[ii];
                        numberOfSides = (face instanceof Three.Face3) ? 3 : 4;

                        for (jj = 0; jj < numberOfSides; jj++) {
                            vertexIndex = face[faceIndices[jj]];
                            face.vertexColors[jj] = boxGeometry.colors[vertexIndex];
                        }
                    }
                }
            }
        }
        this.renderChartOnce()
    }
    
    setFreeCamera(useCamera){
        this.cameraControls.enabled = useCamera
    }
    updateCameraDelta(){
        this.cameraControls.update( this.clock.getDelta());
    }
    setCameraByType(type){
        const lookAt = this.cameraPosition[type];
        this.cameraControls.setLookAt(lookAt[0], lookAt[1],lookAt[2], lookAt[3], lookAt[4], lookAt[5], false);
    }
    
}
