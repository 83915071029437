import React, {useState, useEffect, useRef} from 'react';
import { Typography, Grid, Box} from '@material-ui/core';
import { useParams } from "react-router-dom";

import StartButton from '@user-pages/exercise/common/StartButton'
import BrainHexMap from '@user-components/BrainHexMap'
import BigBrainHexMap from '@user-components/BigBrainHexMap'
import SocketProvider from '@user-provider/SocketProvider';
import PrescriptionService from '@user-service/PrescriptionService'
import CustomerRequestDialog from "@user-components/CustomerRequestDialog"

import StartMeasureDialog from "./StartMeasureDialog";
import EndMeasureDialog from "./EndMeasureDialog";

import MeasureHelper from '@helper/MeasureHelper'
const { measureTypeToCode } = MeasureHelper
const queryString = require('query-string');

const Measure = (props) => {
    const qs = queryString.parse(window.location.search)
    
    const [showStartDialog, setShowStartDialog] = useState(false)
    const [showEndDialog, setEndDialog] = useState(false)

    const [currentStep, setCurrentStep] = useState(0)
    const measureBalanceRef = useRef();
    const barChartRef = useRef();

    const restMapRef = useRef()
    const attempMapRef = useRef()
    const concentMapRef = useRef()
    const brainActiveMapRef = useRef()
    const bigHexRef = useRef()
    
    const { prescriptionID } = useParams()
    const [isRequest] = useState(undefined !== qs.rq)
    const [requestID] = useState(qs.rqs)
    
    const MeasureHzType = 'default'
    const fetch = async () => {
        SocketProvider.connect(async ()=> {
            if(!isRequest && !showStartDialog) {
                setShowStartDialog(true)
            }
        }, window, MeasureHzType)
    }
    
    useEffect(()=>{
        SocketProvider.connect(()=>{
            fetch().then()
        }, window)

        return () => {
            SocketProvider.measureStop(true)
        }
    },[])

    let measureType = ''
    if(0 === currentStep){
        measureType = 'rest'
    } else if(1 === currentStep){
        measureType = 'attemption'
    } else if(2 === currentStep){
        measureType = 'concentration'
    } else if(3 === currentStep){
        measureType = 'brainactive'
    }
    
    // 팝업 종료 후 호출되는 메세지
    const measureStart = async () => {
        
        setShowStartDialog(false)

        let currentMeasureType
        if(0 === currentStep){
            currentMeasureType = 'rest'
        } else if ( 1 === currentStep ){
            currentMeasureType = 'attemption'
        } else if (2 === currentStep ){
            currentMeasureType = 'concentration'
        } else if(3 === currentStep) {
            currentMeasureType = 'brainactive'
        }

        bigHexRef.current.reset(currentMeasureType)

        if(isRequest) return false
        
        //상태값을 변경후 기기에 시작을 알립니다.
        await PrescriptionService.updateStatus(prescriptionID, 'default', measureTypeToCode(currentMeasureType), 'C', 'measuring')
        
        await SocketProvider.measureStart(
            process.env.REACT_APP_MEASURE_BALANCE_TIME,
            prescriptionID,
            MeasureHzType,
            currentMeasureType,
            measureTypeToCode(currentMeasureType), 
            'C',
            null,
            null,
            1
        )
    }

    SocketProvider.onMeasureStop = async (data) => {
        if (3 === currentStep || data.force ){
            if(SocketProvider.isBRHWService){
                await PrescriptionService.updateStatus(prescriptionID, 'default', measureTypeToCode(measureType), 'C', 'measure-completed')
                await PrescriptionService.getUpdateRequest(requestID)
            } 
            setEndDialog(true)        
        } else {
            setCurrentStep(currentStep + 1)
            setShowStartDialog(true)

            try {
                measureBalanceRef.current.reset(currentStep + 1)
                barChartRef.current.reset()
            } catch(e) {}
        }
    }

    SocketProvider.onMeasure = (data) => {
        let ref;
        if(0 === currentStep){
            ref = restMapRef.current
        } else if(1 === currentStep){
            ref = attempMapRef.current
        } else if(2 === currentStep){
            ref = concentMapRef.current
        } else if(3 === currentStep) {
            ref = brainActiveMapRef.current
        }

        ref.addMeasureData(currentStep, data.time, data.isHit)
        bigHexRef.current.addMeasureData(currentStep, data.time, data.isHit)
    }
    
    return (
        <Box>
            <Box  className={'innerViewport z7'}>
                <Box m="auto" style={{width: 800}} className={'hex-wrap big'}>
                    <BigBrainHexMap width={800} ref={bigHexRef} measureType={measureType}/>
                </Box>
            </Box>
            <Box  className={'innerViewport'} style={{position: 'absolute', left:0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.7)', width: '100%'}}>
                <Box padding={2} style={{width:800, margin:'auto'}}>
                <Grid container justify={'center'} spacing={5}>
                    <Grid item>
                        <Box m="auto" className={'hex-wrap small'}>
                            <BrainHexMap width={150} height={150} prescriptionID={prescriptionID}  ref={restMapRef} />
                            <div style={{textAlign: 'center', color: '#fff'}}>안정</div>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box m="auto" className={'hex-wrap small'}>
                            <BrainHexMap width={150} height={150}  prescriptionID={prescriptionID}  ref={attempMapRef} />
                            <div style={{textAlign: 'center', color: '#fff'}}>주의력</div>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box m="auto" className={'hex-wrap small'}>
                            <BrainHexMap width={150} height={150}  prescriptionID={prescriptionID}  ref={concentMapRef} />
                            <div style={{textAlign: 'center', color: '#fff'}}>집중력</div>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box m="auto" className={'hex-wrap small'}>
                            <BrainHexMap width={150} height={150}  prescriptionID={prescriptionID}  ref={brainActiveMapRef} />
                            <div style={{textAlign: 'center', color: '#fff'}}>뇌활성</div>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            </Box>
            {/*<FFTMeasure ref={ fftMeasureRef } noiseCode={noiseCode}/>*/}
            {/*<MeasureBalanceHit ref={ measureBalanceRef } {...prescription}/>*/}
            <StartMeasureDialog 
                onHide={measureStart.bind(this)} 
                open={showStartDialog}
                // open={false}
                history={props.history}
                currentStep={currentStep}/>
                
            <EndMeasureDialog 
                open={showEndDialog}
                history={props.history}
                requestID={requestID}
                prescriptionID={prescriptionID} />
            <Box
                display="flex"
                justifyContent="flex-end"
                position="absolute"
                right={10}
                top={10}
                zIndex={99}
            >
                <Grid container justify="center" spacing={1}>
                    <Grid item>
                        <StartButton 
                            onStop={async () =>{
                                // await SocketProvider.measureStop(false, true)
                              document.location.href = `/measureSelect`
                            }} 
                        />
                    </Grid>
                </Grid>
            </Box>
            {isRequest &&
            <CustomerRequestDialog
                handleStart={() => {
                    setShowStartDialog(true)
                }
                }/>
            }
        </Box>
    );
}
export default Measure
