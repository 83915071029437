import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {Box, Button, Grid, IconButton, Typography} from '@material-ui/core';
import {useParams} from "react-router-dom";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LevelDialog from "../memoryComplex/LevelDialog";
import queryString from "query-string";
import {useLocation} from "react-router";
import ReactAudioPlayer from "react-audio-player";

const Language = (props) => {
    const stageRef = useRef()
    let { type } = useParams()
    const location = useLocation()
    const qs = queryString.parse(window.location.search)
  
    let stageContainer
    const resizeHandler = () => {
        stageContainer.style.zoom = window.innerHeight / 1080
    }

    useEffect(() => {
        window.addEventListener('resize', resizeHandler)
        stageContainer = document.getElementById('stage')
        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    },[])
    

    return (
        <Box style={{position: 'relative', height:'100%', overflow: 'hidden'}}>
            <Stage 
                ref={stageRef} 
                history={props.history} 
                type={type}
                programView={qs.programView}
                exerciseName={location.state.exerciseName}
                measureType={location.state.measureType}
                exerciseID={location.state.exerciseID}
                description={location.state.description}
                onLoaded={() => {
                    setTimeout(() => {
                        resizeHandler()
                    }, 50)
                }}
            />
        </Box>
    );
}


export class Stage extends React.Component {
    container
    clientRect

    audioRef = React.createRef()
    leftLightsRef = React.createRef()
    rightLightsRef = React.createRef()
    mainBrainRef = React.createRef()
    scoreRef = React.createRef()

    levelDialogRef = React.createRef()
    hitCount = 0

    currentMeasureType = 'rest'
    currentMeasureCode = 'A'
    
    title = ['가','나','다','라','마','바','사','아','자','차','카','타','파','하']
    constructor(props) {
        super(props)
        this.state = {
            background: this.title.map(t => require(`@assets/exercise/language/${t}.jpg`)),
            hitCount: 0,
            onMeasureData: props.onMeasureData,
            stepIndex:0,
            onReset: props.onReset,
            history: props.history,
            onLoaded: props.onLoaded,
            exerciseName: props.exerciseName,
            exerciseID: props.exerciseID,
            measureType: props.measureType,
            display: 'none',
            description: props.description,
            programView: props.programView,
            autoPlay: false
          
        }
    }

    setAudioMode(measureType){
        this.audioRef.current.setMode(measureType)
    }

    reset() {
        this.hitCount = 0
        this.leftLightsRef.current.update(0)
        this.rightLightsRef.current.update(0)
        this.mainBrainRef.current.update(0)
    }

    componentDidMount() {
        this.container = ReactDOM.findDOMNode(this)
        this.clientRect = this.container.getClientRects()[0]
        this.audio = ReactDOM.findDOMNode(this)

      this.levelDialogRef.current.open(
        this.state.showType,
        'language',
        this.state.description
      )
      
        // if(this.state.onLoaded){
        //     this.state.onLoaded()
        // }
    }

  async startExercise() {
    if(this.state.onLoaded){
        this.state.onLoaded()
    }
    
    this.setState({
      ...this.state,
      autoPlay: true,
      stepIndex: 0,
    })
  }

    render() {
        // const backgroundImage = this.state.background[this.state.stepIndex]
        return (
            <Box  id={'stage'} style={{height: '100%'}}>
                <Box position={'absolute'} top={0} left={0} zIndex={10} style={{width: '100%'}}>
                    <LanguageNavigator 
                        maxStep={10} 
                        title={this.title}
                        onChange={(stepIndex)=>{
                            this.setState({
                                ...this.state,
                                stepIndex
                            })
                        }}
                        onClose={()=>{
                            this.state.history.goBack()
                        }}/>
                </Box>
                <Box style={{ position: 'relative', height: '100%'}} >
                  {this.state.background.map((image, index) => {
                    return (
                      <Box 
                        xs={12} key={`back-${index}`}
                        style={{
                          position:'absolute',
                          left:0,
                          top:0,
                          width:'100%',
                          height:'100%',
                          transitionDuration: '0.3s',
                          opacity: this.state.stepIndex === index ? 1 : 0
                        }}
                      >
                        <img
                          src={image}
                          style={{
                            width:'100%',
                            height:'100%'}}
                        />
                      </Box>
                    )
                  })}
                    
                </Box>

                {this.state.autoPlay &&
                  <ReactAudioPlayer
                    autoPlay={true}
                    src={require(`@assets/exercise/language/lang${this.state.stepIndex }.mp3`)}
                    controls
                    style={{width: '100%'}}
                  />
                }
              
                <LevelDialog ref={this.levelDialogRef}
                             onStart={this.startExercise.bind(this)}
                             history={this.state.history}
                             exerciseID={this.state.exerciseID}
                             exerciseName={this.state.exerciseName}
                             measureType={this.state.measureType}
                             programView={this.state.programView}
                />
            </Box>
        )
    }
}

export class LanguageNavigator extends React.Component {
    constructor(props) {
        super();

        this.state = {
            stepIndex: 0,
            corrects:[],
            title: props.title,
            maxStep: props.title.length,
            onClose: props.onClose,
            onChange: props.onChange
        }
    }

    next(){
        let stepIndex = this.state.stepIndex + 1
        if(this.state.maxStep <= stepIndex) {
            stepIndex = this.state.maxStep -1
        }
        this.setState({
            ...this.state,
            stepIndex: stepIndex,
        })
        
        if(this.state.onChange) this.state.onChange(stepIndex)
    }
    prev() {
        let stepIndex = this.state.stepIndex - 1
        if(0 > stepIndex) {
            stepIndex = 0
        }
        this.setState({
            ...this.state,
            stepIndex: stepIndex,
        })
        if(this.state.onChange) this.state.onChange(stepIndex)
    }

    setMaxStep(maxStep){
        this.setState({
            ...this.state,
            maxStep
        })
    }


    render() {
        return (
            <Box>
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    position="absolute"
                    left={40}
                    top={10}
                    zIndex={99}>
                    <Grid container justify="center" spacing={1}>
                        {['left','right'].map((key) => {
                            return (
                                <Grid item key={`button-${key}`}>
                                    <Box style={{
                                        width: 64,
                                        height: 64,
                                        borderRadius: '50%',
                                        border: 4,
                                        borderStyle: 'solid',
                                        borderColor: '#0D448A',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        marginTop: 25,
                                        marginRight: 'left' === key ? 10 : 0, 
                                        backgroundColor: '#fff'}}>
                                        {'left' === key 
                                            ? <IconButton aria-label="prev" onClick={this.prev.bind(this)} style={{width:'100%',height:'100%', color:'#0D448A', fontSize: '3rem', fontWeight: 'bold'}} ><ChevronLeftIcon /></IconButton>
                                            : <IconButton aria-label="next" onClick={this.next.bind(this)} style={{width:'100%',height:'100%', color:'#0D448A', fontSize: '3rem', fontWeight: 'bold'}}><ChevronRightIcon /></IconButton>
                                        }
                                    </Box>
                                </Grid>
                            )    
                        })}
                    </Grid>
                </Box>
                
                <Box mt={3} />
                <Grid container spacing={1} justify={"center"}>
                    {this.state.title.map((title, index) => {
                        let backgroundColor = '#fff'
                        let textColor = '#0D448A'

                        let borderColor = '#0D448A';
                        if(index === this.state.stepIndex){
                            borderColor = '#0D448A'
                            textColor = '#ffffff'
                            backgroundColor = '#0D448A'
                        } 
                        

                        return (
                            <Grid item key={index}
                                style={{cursor:'pointer'}}
                                onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    stepIndex: index,
                                  })
                                  if(this.state.onChange) this.state.onChange(index)
                                }}>
                                <Box style={{
                                    width: 82,
                                    height: 82,
                                    borderRadius: '50%',
                                    border: 4,
                                    borderStyle: 'solid',
                                    borderColor,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundColor}}>
                                    <Typography
                                        style={{
                                            marginTop:-4,
                                            fontWeight: 'bold',
                                            fontSize: '2rem',
                                            textAlign: 'center',
                                            lineHeight: `86px`,
                                            color: textColor
                                        }}
                                    >
                                        {title}
                                    </Typography>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    position="absolute"
                    right={10}
                    top={10}
                    zIndex={99}>
                    <Grid container justify="center" spacing={1}>
                        <Grid item>
                            <Button
                                style={{fontSize: '1.3rem', fontWeight: 'bold', height:54, border: '4px solid #0D448A', color: '#0D448A', width: 160, borderRadius: 40, backgroundColor: '#fff', marginRight: 39,marginTop:25 }}
                                onClick={() => {
                                    if(this.state.onClose) this.state.onClose()
                                }}>
                                종료
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
          
            </Box>
        )
    }
}


export default Language
