import React, {useEffect} from 'react';
import {Box, Grid, Typography} from '@material-ui/core';

import BarChart from '@user-components/BarChart';
import PieChart from '@user-components/PieChart';

const defaultColor = ['#60C3F7','#A9D8AB','#F8807A']
const ResultRegulation = (props) => {
    const { fontSize, titleFontSize } = props
    const  measureData  = props.measureData.slice(0,3)
    useEffect(()=>{
    },[])
    
    return (
        <Box>
            {props.title &&
                <Typography variant={"subtitle1"} style={{fontSize: titleFontSize ? titleFontSize :  props.fontSize }}>
                    {props.title}
                </Typography>
            }
            <Grid container>
                <Grid item xs={9}>
                    <BarChart
                        defaultData={measureData.slice()}
                        showTopValue={true}
                        useColorIndex={true}
                        type={'horizontalBar'}
                        labels={[
                            'α 조절능력',
                            'SMR 조절능력',
                            'β 조절능력',
                        ]}
                        height={100}
                        suggestedMax={50}
                        colors={ defaultColor }
                        fontSize={fontSize}
                    />
                </Grid>
                <Grid item xs={3}>
                    <PieChart
                        defaultData={measureData.map((value) => parseInt(value * 10) )}
                        labels={[
                            'α 조절능력',
                            'SMR 조절능력',
                            'β 조절능력',
                        ]}
                        title={ props.title }
                        colors={ defaultColor }
                        legendPosition={ 'bottom' }
                        useColorIndex={true}
                        fontSize={fontSize}
                    />
                </Grid>
            </Grid>
               
        </Box>
    );
}


export default ResultRegulation
