
export function updateMeasure(measure) {
    return { type: 'UPDATE_MEASURE', measure: measure };
}

export function measureStart(params){
    return { type: 'measureStart', params };
}
  
export function setDevice(){
    return { type: 'setDevice', action: { } };
}