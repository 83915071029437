import React, { createRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import HeatMap from 'react-heatmap-grid';
import MeasureService from '@user-service/MeasureService'

import ColorHelper from '@helper/ColorHelper';
import MeasureHelper from '@helper/MeasureHelper'
const { measureTypeToName, measureTypeToCode } = MeasureHelper


export default class MeasureBalanceFeedback extends React.Component {

    viewRef = createRef()

    constructor(props) {
        super(props)

        this.state = {
            prescription: null,
            hzType: props.hzType,
            measureType: props.measureType ? props.measureType : 'rest',
            measureCode: props.measureCode ? props.measureCode : 'A',
            yLabels: [],
            renderData: null
        }
    }
    /**
     * 데이터를 받아 화면을 새로고칩니다.
     * @param {*} time
     * @param hitArr
     * @param stepCount
     */
    addData(time, hitArr, stepCount){
        this.viewRef.current.addData(time, hitArr, stepCount)
    }

    reset(measureType, measureCode){
        this.setState({
            measureType: measureType,
            measureCode : measureCode
        })
        this.viewRef.current.reset(measureType, measureCode)
    }

    async componentDidMount() {
        const { hzType, measureType, measureCode } = this.state
        const res = await MeasureService.getComputeHzLastWithCode(hzType, measureTypeToCode(measureType), measureCode)
        if(!res) return

        const { band } = res
        const yLabels = band.map( value => value.hz)
        yLabels.unshift('초')

        this.setState({
            ...this.state,
            yLabels,
            renderData: band.map(() => ({hitMv:0,isHit:false}))
        })

    }

    render() {
        const { measureType , renderData, yLabels } = this.state
        if(!renderData) {
            return <></>
        }

        const selectedTypeName = measureTypeToName(measureType)
        return (
            <Box mb={3}>
                <Typography>
                    {selectedTypeName} 측정 상태
                </Typography>
                <Box>
                    <ViewHeatMap ref={this.viewRef} initData={renderData} yLabels={yLabels} />
                </Box>
            </Box>
        );
    }

}


/**
 * 히트맵을 그립니다.
 * @param {*} props
 */
class ViewHeatMap extends React.Component {

    restColor = '#29b6f6'
    attemptionColor = '#66bb6a';
    concentrationColor = '#ef5350';

    //30초마다 새로 갱신합니다.
    maxLength = 30
    yLabels = []

    tempAddArrays = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];

    colorIndex = 0
    currentColor = '#4EC3F7'
    sumHitCount = 0

    constructor(props) {
        super(props)

        this.yLabels = props.yLabels
        this.state = {
            xLabels: [],
            renderData: [],
            initData: props.initData,
            colors: ColorHelper.getColorsWith(51),
            backgroundColor: this.restColor
        }
    }

    componentDidMount() {
        const { initData } = this.state
        this.addData(0, initData, 1)
    }

    addData(time, hzVal, stepCount){
        if(!hzVal) return false

        this.state.xLabels.push(time)

        for(let j = 0 ; j < hzVal.length; j++){
            hzVal[j].color = this.getColor(hzVal[j].isHit, stepCount)
        }

        const tmpRender = this.state.renderData;
        if(!tmpRender[0]){
            tmpRender[0] = []
        }
        tmpRender[0].unshift({ hitMv : time + 's'})

        let innerIndex = 0
        for(let i = 0; i < hzVal.length; i++){
            innerIndex = i + 1
            if(!tmpRender[innerIndex]){
                tmpRender[innerIndex] = []
            }

            tmpRender[innerIndex].unshift(hzVal[i])
        }
        if(this.maxLength < this.state.xLabels.length) {
            this.state.xLabels.pop()

            for(let i = 0; i < hzVal.length + 1; i++){
                tmpRender[i].pop()
            }
        }

        this.setState({
            ...this.state,
            renderData: tmpRender
        })
    }


    reset(measureType, measureCode) {
        let backgroundColor = this.state.backgroundColor

        if(measureType){
            if('rest' === measureType){
                backgroundColor = this.restColor
            } else if ('attemption' === measureType){
                backgroundColor = this.attemptionColor
            } else if ('concentration' === measureType){
                backgroundColor = this.concentrationColor
            }
        }


        this.setState({
            ...this.state,
            measureType,
            measureCode,
            xLabels: [],
            renderData: [],
            backgroundColor
        })
    }

    getColor(isHit, stepCount){
        let colorIndex = this.colorIndex

        if(1 < stepCount && isHit){
            this.sumHitCount += 1

            if(0 === this.sumHitCount % stepCount ){
                this.colorIndex = 0 === this.colorIndex ? 3 : 0
            }
        }

        return this.state.colors[ colorIndex ];
    }

    render() {
        return (
            <HeatMap
                xLabels={this.state.xLabels}
                yLabels={this.yLabels}
                xLabelsLocation={"bottom"}
                xLabelsVisibility={[]}
                yLabelWidth={20}
                xLabelWidth={38}
                yLabelTextAlign={'left'}
                data={this.state.renderData}
                squares
                cellStyle={(background, value, min, max, data, x, y) => {
                    if(0 === y ) {
                        return {
                            background: '#efefef',
                            color: '#000',
                            fontSize: 15,
                            width: '36px',
                            height: '36px',
                            lineHeight: '18px',
                        }
                    }

                    try {
                        return {
                            background: false === value.isHit ? '#efefef' : value.color,
                            color: false === value.isHit ? '#999' : '#fff',
                            fontSize: 13,
                            width: '36px',
                            height: '36px',
                            lineHeight: '18px',
                        }
                    } catch (e){
                        return {
                            background: '#efefef',
                            color: '#999' ,
                            fontSize: 13,
                            width: '36px',
                            height: '36px',
                            lineHeight: '18px',
                        }
                    }

                }}
                cellRender={ (value, x, y) => {
                    if(!value) return ''

                    return <Box className="mv-value">
                        {value.hitMv}
                    </Box>
                }}
            />
        )
    }

}



