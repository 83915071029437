import React, {useEffect, useState, useCallback, useRef} from 'react';

import {Box, Button, Grid, Tab, Tabs, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PieChart from '@user-components/PieChart';
import BarChart from '@user-components/BarChart';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import Moment from 'moment-timezone'
import SurveyYear from "./SurveyYear";
import ColorHelper from '@helper/ColorHelper';
import DateHelper from "@helper/DateHelper";

import SurveyService from '@user-service/SurveyService'
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {MeasureLineChart} from "../life-cycle/LifeCycle";
import MeasureService from "../../services/MeasureService";
import AuthProvider from "../../provider/AuthProvider";
import NeedTicketDialog from "../../components/NeedTicketDialog";
import LoadingIndicator from "../../components/LoadingIndicator";
import ConfirmDialog from "../../components/ConfirmDialog";
import {useRecoilState} from "recoil";
import {appProfileButtonStore} from "../../store/headerStore";

const queryString = require('query-string');
const SurveyList = ({history}) => {
    const querystring = queryString.parse(window.location.search)

    /**
     * App - 자각증상일 때는 emotion 
     * APP - 생활습관일 때는 life 가 기본이도록 변경
     */
    
    let defaultPage = 'survey-life' === AuthProvider.getAppServicePage() ? 'life' : 'emotion'
    let _surveyType = querystring.type ? querystring.type : defaultPage
    
    const classes = useStyles()
    const [surveyType , setSurveyType] = useState(_surveyType)
    const [isLoading , setLoading] = useState(false)
    const [surveyRecent, setSurveyRecent] = useState()
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [colors, setColors] = useState(ColorHelper.getSurveyColors([0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]))
    const [, setShowProfileButton] = useRecoilState(appProfileButtonStore);
    
    const fetchHSurveyRecent = useCallback(async () => {
        const res = await SurveyService.getSurveyRecent(surveyType)
        const measureTypesCount = await MeasureService.getMeasureCount({
            profileID: AuthProvider.getProfileID(), 
            measureGroup: 'life'
        })
        const recent = {
            date: '',
            isSubmitted: false,
            labels: [],
            items: [],
            level: [],
        }

        res.result.map((value, index) => {
            recent.labels.push(index + 1)
            recent.items.push({
                content: value.content,
                level: value.level,
                order: value.order,
            })
            recent.level.push(value.level)
            return value
        })

        recent.surveyType = res.surveyType
        recent.date = res.recentDate
        recent.isSubmitted = res.isSubmitted
        recent.measureTypesCount = measureTypesCount

        if('life-cycle' !== surveyType){
            recent.items.sort((a,b) => {
                return b.level - a.level
            })
        }
        
        if(recent.level){
            if('life' === surveyType) {
                recent.level.map((level,index) => {
                    recent.level[index] = 100 - level + 10;
                    return level
                })
                setColors(ColorHelper.getSurveyColors([110, 100, 90, 80, 70, 60, 50, 40, 30, 20]))
            }
        }
       

        setSurveyRecent(recent)
        setTimeout(() => {
            setLoading(false)
        }, 500)
            
    }, [surveyType])

    useEffect(()=>{
      setShowProfileButton(true)
      fetchHSurveyRecent().then()    
    },[fetchHSurveyRecent])

    let measureTypeCount = 10
    if(surveyRecent && 'life-cycle' !== surveyType){
        const type = surveyRecent.measureTypesCount.find(type => type.key === surveyType)
        measureTypeCount = type.count
    }
    

    let tabHeader = {
        title : '자각증상교정',
        description : '매달 자각 검사를 측정하고 변화를 확인합니다'
    }
    let tabName
    switch (surveyType){
        case 'health': 
            tabName = '운동자각검사'
            break
        case 'life-cycle':
            tabName = '라이프 사이클'
            break
        case 'life':
            tabHeader.title = '생활습관검사'
            tabHeader.description = '매달 생활습관검사를 측정하고 변화를 확인합니다'
            tabName = '생활습관검사'
            break
        case 'recognition':
            tabHeader.title = '인지기능장애 평가'
            tabName = '인지기능장애 평가'
            tabHeader.description = '매달 인지기능장애 평가를 측정하고 변화를 확인합니다'
            break
        default:
            tabName = '감정자각검사'
    }
    return (
        <div id="cont-wrap" className={AuthProvider.isAppMode() ? `app ${AuthProvider.get('viewMode')}` : ''}>
            <div className="sv" >
                <div className={'ti-wrap'}>
                    {/*<h3>{DateHelper.now('M')}월, 자각증상</h3>*/}
                    <h3>{tabHeader.title}</h3>
                    <span>{tabHeader.description}</span>
                    <i>Brain Health</i>
                </div>
            </div>
            <div className="cont">
                {surveyRecent &&
                <Box>
                    <Grid container spacing={1} className={'tabs'}>
                        <Grid item xs={10}>
                            <SurveyTab 
                                surveyType={surveyType}
                                name={tabName}
                                change={(surveyType) => {
                                setLoading(true)
                                setTimeout(() => {
                                    setSurveyType(surveyType)    
                                }, 700)
                                
                            }} />
                        </Grid>
                        {(surveyRecent.isSubmitted || 'life-cycle' === surveyType) &&
                        <Grid item xs={2}>
                            <Box display={'flex'} justifyContent={'flex-end'} width={'100%'}>
                                <Button
                                    startIcon={<RotateLeftIcon/>}
                                    className={classes.button}
                                    endIcon={<ChevronRightIcon className={'rightIcon'}/>}
                                    onClick={async () => {
                                        const res = await SurveyService.getMySurvey(surveyType)
                                        if (res.isSubmitted) {
                                            setConfirmOpen(true)
                                        } else {
                                            history.push(`/survey/${surveyType}`)
                                        }
                                        
                                    }}
                                > 재검사</Button>
                            </Box>
                        </Grid>
                        }
                    </Grid>
                    {isLoading &&
                    <LoadingIndicator/>
                    }
                    {!isLoading &&
                    <React.Fragment>
                        {'life-cycle' !== surveyType &&
                        <React.Fragment>
                            <Box mt={2} mb={5}>
                                {(!surveyRecent.date || !surveyRecent.isSubmitted) &&
                                <Box>
                                    <StartSurveyBox 
                                        surveyType={surveyType} 
                                        history={history}
                                        measureTypeCount={measureTypeCount}
                                        title={tabName}
                                    />
                                </Box>
                                }

                                {surveyRecent.date &&
                                <React.Fragment>
                                    <Box
                                        className={classes.subtitle}>
                                            마지막 검사 :
                                            <b style={{paddingLeft: 10}}>{DateHelper.format(surveyRecent.date, 'YYYY년 MM월')}</b>
                                    </Box>
                                    <Box pl={12} pr={12} display={'flex'} justifyContent={'center'}>
                                        <div style={{width: 'life' === surveyType ? '85%' : '100%'}}>
                                            <BarChart
                                                defaultData={surveyRecent.level}
                                                showTopValue={false}
                                                suggestedMax={100}
                                                height={60}
                                                labels={surveyRecent.labels}
                                                spacing={'life' === surveyType ? 0.87 : 0.96}
                                                typeFlag={surveyRecent.surveyType}
                                                useColorMap={colors}
                                            />
                                        </div>
                                    </Box>
                                    <Top5Survey items={surveyRecent.items} surveyType={surveyType}/>
                                </React.Fragment>
                                }
                            </Box>
                            <SurveyYear recent={surveyRecent} surveyType={surveyType}/>
                        </React.Fragment>
                        }
                        {'life-cycle' === surveyType &&
                        <Box mt={2} mb={5}>
                            {!surveyRecent.date &&
                            <Box>
                                <StartLifeCycleBox surveyType={surveyType} history={history}/>
                            </Box>
                            }
                            
                            <Box
                                className={classes.subtitle}>
                              마지막 검사 :
                              <b style={{paddingLeft: 10}}>{DateHelper.format(surveyRecent.date, 'YYYY년 MM월 DD일')}</b>
                                  
                            </Box>

                            {surveyRecent.date &&
                            <MeasureLineChart surveyRecent={surveyRecent}/>
                            }
                        </Box>
                        }
                    </React.Fragment>
                    }
                </Box>
                }
            </div>
            <ConfirmDialog
                title={'알림'}
                open={confirmOpen}
                onCancel={() => setConfirmOpen(false)}
                onOK={async () => {
                    setConfirmOpen(false)
                    await SurveyService.deleteSurvey(surveyType)
                    history.push(`/survey/${surveyType}`)
                }}
            />
        </div>
    );
}

export function SurveyTab(props) {
    const page = AuthProvider.getAppServicePage()
    
    let _tabItem = []
    
    if('web' === page || 'web' === AuthProvider.getServiceType()){
        _tabItem = [
            {text : '감정자각검사', type: 'emotion'},
            {text : '운동자각검사', type: 'health'},
            {text : '라이프 사이클', type: 'life-cycle'},
            {text : '생활습관검사', type: 'life'},
            {text : '인지기능장애 평가', type: 'recognition'},
        ]
    }

    if('survey' === page ){
        _tabItem = [
            {text : '감정자각검사', type: 'emotion'},
            {text : '운동자각검사', type: 'health'},
            {text : '라이프 사이클', type: 'life-cycle'},
        ]
    }

    if('survey-life' === page){
        _tabItem = [
            {text : '생활습관검사', type: 'life'},
            {text : '인지기능장애 평가', type: 'recognition'},
        ]
    }

    if(AuthProvider.isProfileSelectorView()){
        _tabItem = [
            {text : '감정자각검사', type: 'emotion'},
            {text : '운동자각검사', type: 'health'},
            {text : '생활습관검사', type: 'life'},
            {text : '인지기능장애 평가', type: 'recognition'},
        ]
    }
    const [currentTab, setTab] = useState(_tabItem.findIndex( t => t.type === props.surveyType))
    const [tabItem] = useState(_tabItem)
    
    return (
        <Tabs
            value={currentTab}
            onChange={(event, newValue) => {
                if(currentTab === newValue) return
                setTab(newValue)
                
                props.change(tabItem[newValue].type)
            }}
        >
            {
                tabItem.map((tab, index) => (
                    <Tab key={`tab-${index}`} className={'tab'} label={tab.text}/>
                ))
            }
        </Tabs>
    )
}

export function Top5Survey(props){
    const classes = useStyles()
    const rows = props.items ? props.items.slice(0, 5) : []

    rows.map((r) => {
        if('life' === props.surveyType){
            r.level = 100 - r.level + 10;
        }
        return r
    })
    return (
        <React.Fragment>
            <Box className={classes.title}>증상 TOP5</Box>
            
            <Grid container spacing={2}
                  justify={'center'} 
                  alignItems={'center'}
                  style={{width: '100%', border: '2px solid #DFDFDF'}} >
                {rows.map((row, index) => (
                    <Grid item key={row.order} style={{width: '20%', position: 'relative'}}>
                        <Box className={classes.wrap} style={{marginTop: 40}}>
                            <Typography align={'center'} className={classes.number}>
                                {('00' + (index+1)).substr(-2)}
                            </Typography>
                        </Box>
                        <Box className={classes.wrap} style={{minHeight:42}}>
                            <Typography align={'center'} style={{width: 150, fontSize: 16 }}>
                                {row.content}
                            </Typography>    
                        </Box>
                        <Box className={classes.wrap} style={{marginTop: 50}}>
                            <Box style={{width: 150}}>
                                <PieChart
                                    defaultData={[row.level, 100 - row.level]}
                                    labels={[11,11]}
                                    xLabelDisplay={true}
                                    useColorIndex={true}
                                    colors={['#79BEFF', '#efefef']}
                                /> 
                            </Box>
                        </Box>
                        <Box className={classes.wrap} style={{marginTop: -30, marginBottom: 40}}>
                            <div align={'center'} style={{fontSize:36, fontWeight: 400}}>
                                {row.level}%
                            </div>
                        </Box>
                        {((index+1) !== rows.length) &&
                        <Box position={'absolute'}
                             style={{width: 2, backgroundColor: '#DFDFDF', height: '80%', top: 50, right: 0}}/>
                        }
                            
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    )
}


//자각 증상 설문 시작하기 
export function StartLifeCycleBox(props){
    const classes = useStyles()
    return (
        <Grid container justify="center" className={classes.startWrap}>
            <Grid item>
                <img src={require('@images/brainLightIcon.png')} alt={'icon'}/>
            </Grid>
            <Grid item style={{paddingLeft: 30}}>
                <p className="txt01"><span>라이프 사이클</span>를 체크해보세요 </p>
                <Typography className={"subtitle2"}>
                    라이프 사이클을 시작해보세요
                </Typography>
                <Button
                    className="button"
                    onClick={ async ()=>{
                        const res = await SurveyService.getMySurvey(props.surveyType)
                        if(res.isSubmitted){
                            if(!window.confirm('기존 검진한 내용이 있습니다\n기존 검진을 삭제하고 시작하시겠습니까?')){
                                return false
                            }
                            await SurveyService.deleteSurvey()
                        }
                        
                        props.history.push(`/survey/${props.surveyType}`)
                    }}>시작하기</Button>
            </Grid>
        </Grid>
    )
}

//자각 증상 설문 시작하기 
export function StartSurveyBox(props){
    const classes = useStyles()
    const thisMonth = Moment.utc().format('YYYY년 MM월')
    const [open, setOpen] = useState(false)
    return (
        <React.Fragment>
            <Grid container justify="center"  className={classes.startWrap}>
                <Grid item>
                    <img src={require('@images/brainLightIcon.png')} alt={'icon'}/>
                </Grid>
                <Grid item style={{paddingLeft: 30}}>
                    <p className="txt01">{thisMonth}, <br/> <span>{props.title}</span>를 시작해보세요! </p>
                    
                    <Typography className={"subtitle2"}>
                        매월 검사를 시작해보세요 <br/>
                        브레인헬스 운동을 통해 변화되어 가는 모습을 확인 할 수 있습니다.
                    </Typography>
                  
                    <Button
                      className="button"
                      onClick={async () => {
  
                        if( 0 === props.measureTypeCount) {
                          setOpen(true)
                          return
                        }
  
                        const res = await SurveyService.getMySurvey(props.surveyType)
                        if (res.isSubmitted) {
                          // setConfirmOpen(true)  
                        } else {
                          props.history.push(`/survey/${props.surveyType}`)
                        }
                      }}>시작하기</Button>
                </Grid>
            </Grid>
            <NeedTicketDialog open={open} onClose={() => setOpen(false)}/>
            
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
    title: {
        marginTop: 80,
        fontSize: 26, 
        letterSpacing: -2, 
        fontWeight: 'bold', 
        marginBottom: 20  
    },
    subtitle: {
        fontSize: 26,
        letterSpacing: -2,
        marginBottom: 20,
        marginTop: 10
    },
    startWrap: {
        paddingTop: 95,
        paddingBottom: 95,
        border: '1px solid #ddd',
        '& .txt01': {
            fontSize: 36,
            '& span': {
                fontWeight: 'bold'
            }
        },
        '& .subtitle2': {
            fontSize: 20,
            marginTop: 15,
        },
        '& .button': {
            backgroundColor: '#0054BF',
            borderRadius: 25,
            height: 50,
            width: 180,
            color: 'white',
            fontSize: 20,
            fontWeight: 'bold',
            marginTop: 30
        },
        '& .button:hover': {
            backgroundColor: '#05318E',
        }
        
    },
    wrap: {
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
        marginBottom: 15 
    },
    number: {
        backgroundColor : '#0054BF',
        color: 'white',
        width: 40,
        height: 40,
        borderRadius: 20,
        lineHeight: '40px',
        fontWeight: 'bold',
        marginButton: 15
    },
    button: {
        border: '2px solid #BEBEBE',
        padding: '5px 30px',
        borderRadius: 20,
        color: '#666',
        '& .MuiButton-endIcon': {
            marginRight: -15
        },
        '& .rightIcon': {
            color: '#00DD94',
            fontSize: 25,
            fontWeight: 'bold'
        }
    },
    buttonNoCount: {
        fontSize: 20,
        marginTop: 30,
        fontWeight: 'bold'
    },
}));

export default SurveyList
