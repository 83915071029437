import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ReactAudioPlayer from "react-audio-player";
import {Button, Grid, Box, Typography} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DialogActions from "@material-ui/core/DialogActions";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={clsx('common-dialog-close', classes.closeButton)}   onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default class StartDialog  extends React.Component {
    constructor(props) {
        super();

        this.state = {
            open: false,
            onStart: props.onStart,
            history: props.history,
        }
    }

    open() {
        this.setState({
            ...this.state,
            open: true,
        })
    }

    close() {
        this.setState({
            ...this.state,
            open: false
        })
    }

    start() {
        if(this.state.onStart){
            this.state.onStart(this.state.delay);
        }
        this.close()
    }

    onExit() {
        if(this.state.history){
            this.state.history.replace('/measureSelect')
        }
    }
    render() {
        return (
            <Dialog
                disableBackdropClick
                open={this.state.open}
                className={'innerViewport'}
            >
                <DialogTitle  className="common-dialog-title" onClose={() => {
                    this.state.history.replace('/measureSelect')
                }}>
                    뇌기능 의식 패턴검사
                </DialogTitle>
                <DialogContent className={'common-dialog-content'}>
                    뇌기능 의식패턴 측정을 단계별로 시작합니다. <br/>
                    편안한 마음으로 몸의 긴장을 풀고 이완을 하면서<br/>
                    화면을 바라보시기 바랍니다. <br/>
                    <br/>

                    <ReactAudioPlayer
                        autoPlay
                        src={require('@audio/01-11.mp3')}
                        controls
                        onEnded={()=>{
                           this.start()
                        }}
                    />
                </DialogContent>
                <DialogActions className={'common-dialog-action'}>
                    <Button
                        autoFocus
                        fullWidth={true}
                        endIcon={<ArrowForwardIosIcon />}
                        onClick={()=>{
                            this.start()
                        }}>
                        측정시작
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
