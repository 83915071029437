import React from 'react';
import { useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {Box, Button, Typography, Grid} from '@material-ui/core';
import clsx from "clsx";

const JoinDone = () => {
    const classes = useStyles();
    const history = useHistory()
    
    return (
        <React.Fragment>
            <Box className={classes.background} />
            <Box className={classes.logo}>
                <a href={"https://brhw.kr"}>
                    <img src={"https://brhw.kr/images/logo.png"} alt={'브레인헬스 로고'}/>
                </a>
            </Box>
            <Box className={classes.loginBoxWrap}>
                <Box className={classes.loginBox}>
                    <Typography className={classes.title}>
                        <span style={{fontWeight: 600}}>회원가입</span>이<br/>완료되었습니다!
                    </Typography>
                    
                  
                    {/* 로그인 입력  */ }
                    <Box style={{marginTop: 40, marginBottom: 100}}>
                        <form noValidate autoComplete="off" >
                            <Grid container style={{marginTop: 60}}>
                               <Grid item xs={12}>
                                   <Button 
                                       onClick={() => {
                                            history.replace('/login?rtu=https://brhw.kr')   
                                       }}
                                       className={clsx(classes.registerButton)}>로그인</Button>
                               </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}



const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center"
    },
    logo: {
        position: 'fixed',
        left: 30 ,
        top: 22,
        zIndex: 0,
        width: `100%`,
    },
    title: {
        fontSize: 40,
        fontFamily: 'Noto Sans KR',
        fontWeight: 300,
        marginBottom: 75,
        color: '#333'
    },
    registerButton: {
        position: 'relative',
        width: '100%',
        height: 60,
        borderRadius: 10,
        fontSize: 19,
        backgroundColor: '#0053BF',
        color: 'white',
        '&:hover': {
            backgroundColor: '#04308E',
        }
    },
    formBox: {
        marginBottom: 60
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
        backgroundImage: `url(${require(`@assets/images/simplebg01.jpg`)})`,
        backgroundSize: 'cover'
    },
    loginBoxWrap: {
        top: 110,
        left: `calc(50% - 250px)`,
        // transform: `translate(-50%, -50%)`,
        position: 'absolute',
        width: 400,
        zIndex: 2,
    },
    loginBox: {
        width: '100%',
        minHeight: 470,
        borderRadius: 10,
        backgroundColor: '#fff',
        padding: 50,
        paddingTop: 75,
        marginBottom: 110
    },
    bottom: {
        position: "absolute",
        bottom: 140,
        width: 400,
        color: '#333',
        fontSize: 14,
        textAlign: 'center'
    },
    
}));

export default JoinDone
