import React from "react";
import {Box} from "@material-ui/core";
import ColorHelper from '@helper/ColorHelper';

export default class ActiveProgress extends React.Component {
    width = window.innerWidth - 200
    stepWidth = this.width / 15
    
    constructor() {
        super();

        this.state = {
            box: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
            activeNumber: [],
            activeBoxNumber: [],
            colors: ColorHelper.getGaugeColor(15),
            colorsLabels: ColorHelper.getGaugeColor(15)
        }


        this.state.colorsLabels.push(1)
    }

    update(isHit) {
        let { activeBoxNumber } = this.state
        if(isHit){
            if(this.state.colors.length > this.state.activeBoxNumber.length){
                activeBoxNumber.push(1)
            }
        } else {
            activeBoxNumber = []
        }

        this.setState({
            ...this.state,
            activeBoxNumber
        })
    }

    reset(){
        this.setState({
            ...this.state,
            activeBoxNumber: []
        })
    }

    render()
    {

        return (
            <Box
                display="flex"
                justifyContent="center"
                position="fixed"
                bottom={50}
                style={{ width: '100%', zIndex: 20}}>
                <Box
                    style={{
                        width: this.width,
                        height: 20,
                        background: 'rgba(224,224,224,0.4)',
                        borderRadius: 20
                    }}
                >
                    <Box style={{width: this.width, position: 'relative'}}>
                        {this.state.colorsLabels.map((color, index) => {
                            return (
                                <Box key={index} position="absolute" style={{color: '#aaa'}}  top={25} left={`${index * 6.58}%`}>{index }</Box>
                            )
                        })}
                        <Box position="absolute"
                             left={0}
                             style={{transitionDuration: '0.5s', width: this.state.activeBoxNumber.length * this.stepWidth, height: 20,  borderRadius: 20, backgroundColor: '#FFBE04' }}/>
                    </Box>
                </Box>
            </Box>
        )
    }
}
