

import React, {useEffect, useState, useCallback} from "react";
import {TextField} from "@material-ui/core";

import CustomerService from "@user-service/manage/CustomerService";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import TuneIcon from "@material-ui/icons/Tune";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {makeStyles} from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import GroupIcon from '@material-ui/icons/Group';
import AuthProvider from "../../../provider/AuthProvider";
import MeasureCount from "../measure/MeasureCount";


let timer
const CustomerSettingDialog = (props) => {
    
    const { profileID, profileName, onClose, open, memberID } = props
    const [ state, setState ] = useState({
        rawAmpPercent: '100',
        profileCount: '3'
    })     
    const classes = useStyles();
    
    const handleClose = () => {
        if(onClose){
            onClose()
        }
    }
    
    const fetch = useCallback(async () => {
        const res = await CustomerService.getCustomerConfig(profileID,'RAW_AMP_PERCENT')
        const profileInfo = await CustomerService.getCustomerProfiles(memberID)
        
        let rawAmpPercent = 100
        if('' !== res.data){
            rawAmpPercent = res.data
        }
        
        setState({
            ...state,
            rawAmpPercent,
            profileCount: profileInfo.maxProfileCount,
        })
        
    }, [open])

    useEffect(() => {
        if(open){
            fetch().then()
        }
    }, [open, fetch])
    
    const { rawAmpPercent, profileCount } = state
    

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                설정 - {profileName} 
            </DialogTitle>
            <DialogContent>
                {AuthProvider.isSuperAdmin() &&
                <List
                    style={{marginTop: 30}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            개인 설정
                        </ListSubheader>
                    }
                    className={classes.listRoot}
                >
                    <ListItem className={'item'}>
                        <ListItemIcon>
                            <GroupIcon/>
                        </ListItemIcon>
                        <ListItemText primary={
                            <div>
                                사용자 프로필 수
                                <div className={classes.small}>프로필를 생성 가능한 수 입니다.</div>
                            </div>
                        }/>
                        <ListItemSecondaryAction>
                            <TextField
                                value={profileCount}
                                variant="filled"
                                inputProps={{min: 0, style: {textAlign: 'right', paddingRight: 10, width: 50}}}
                                InputProps={{disableUnderline: true}}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        profileCount: event.target.value
                                    })

                                    const profileCount = parseInt(event.target.value)
                                    if (isNaN(profileCount)) return

                                    if (timer) clearTimeout(timer)
                                    timer = setTimeout(async () => {
                                        await CustomerService.setCustomerProfileCount(memberID, profileCount)
                                    }, 500)
                                }}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider/>

                    <ListItem className={'item'}>
                        <ListItemIcon>
                            <TuneIcon/>
                        </ListItemIcon>
                        <ListItemText primary={
                            <div>
                                증폭 설정
                                <div className={classes.small}>해당 값을 설정하면 사용자별 RAW 데이터의 %증가 후 FFT 처리합니다.</div>
                                <div className={classes.small}>
                                    100 기본값 <br/>
                                    150 = 50% 증폭 <br/>
                                </div>
                            </div>
                        }/>
                        <ListItemSecondaryAction>
                            <TextField
                                value={rawAmpPercent}
                                variant="filled"
                                inputProps={{min: 0, style: {textAlign: 'right', paddingRight: 10, width: 50}}}
                                InputProps={{disableUnderline: true}}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        rawAmpPercent: event.target.value
                                    })

                                    const rawAmpPercent = parseInt(event.target.value)
                                    if (isNaN(rawAmpPercent)) return

                                    if (timer) clearTimeout(timer)
                                    timer = setTimeout(async () => {
                                        await CustomerService.setCustomerConfig(profileID, 'RAW_AMP_PERCENT', rawAmpPercent)
                                    }, 500)

                                }}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                }
                
                <MeasureCount profileID={profileID}/>
                
            </DialogContent>
        </Dialog>
    );
}


const useStyles = makeStyles(() => ({
    small: {
        fontSize: 14,
        color: '#999',
    }
}));

export default CustomerSettingDialog