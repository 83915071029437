import React, {useEffect, useRef, useState} from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

import TimeCount from '@user-pages/exercise/common/TimeCount'
import {IconButton, Select} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import clsx from "clsx";
import ExerciseStartDialog from "@user-pages/exercise/common/ExerciseStartDialog";

import PauseIcon from '@material-ui/icons/Pause';
import MenuItem from "@material-ui/core/MenuItem";



export default function ExerciseStatusBar (props) {
    const classes = useStyles()
    const history = useHistory()
    
    const timeCountRef = useRef()

    const [maxIntervalTime] = useState([3,4,5,6])
    const [intervalTime, setIntervalTime] = useState(5)
    
    const [state , setState] = useState({
        isPlaying: false,
        isStart: false
    }) 
     
    const {isPlaying, isStart} = state
    
    const handleClose = () => {
        if(props.close){
            props.close()
        }
        history.goBack()
    }
    
    const handleStart = async () => {
        if(!isStart && !isPlaying){
            timeCountRef.current.startWithInterval()
            if(props.start){
                props.start(intervalTime)
            }    
        } else {
            timeCountRef.current.togglePauseInterval()
            if(props.toggle){
                props.toggle(intervalTime)
            }
        }
        
        setState({
            ...state,
            isPlaying: !isPlaying,
            isStart: true
        })
    }
    
    useEffect(() => {
    },[])
    
    const innerBoxWidth = window.innerWidth - 200;
    return (
        <Box className={classes.root}>
            <Box className={classes.wrap}>
                <ExerciseStartDialog
                    onStart={() => {
                        setTimeout(() => {
                            handleStart().then()
                        }, 500)
                    }}
                />
                
                <Grid container style={{width: '100%'}} >
                    <Grid item style={{margin: 'auto'}}>
                        <IconButton 
                            className={classes.icon}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid item style={{width: innerBoxWidth, position: 'relative'}}>
                        <Box className={classes.background}/>
                        <Box
                            display="flex"
                            justifyContent="flex-start"
                            position="absolute"
                            left={20}
                            top={5}
                            zIndex={99}
                        >
                            <Grid container>
                                <Grid item>
                                    <span className={classes.modeText} style={{color: 'white'}}>
                                        시간 선택: 
                                    </span>
                                </Grid>
                                <Grid item style={{paddingLeft: 10, fontWeight: 'bold'}}>
                                    <Select
                                        defaultValue={intervalTime}
                                        className={clsx(classes.select)}
                                        onChange={(event) => {
                                            setIntervalTime(event.target.value)
                                        }}>
                                        {maxIntervalTime.map((value, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={value}
                                                >
                                                    {value}초
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            position="absolute"
                            left={"calc(50% - 60px)"}
                            top={5}
                            zIndex={3}
                            style={{color: 'white', fontSize: 30, fontWeight: 'bold'}}>
                                <TimeCount ref={timeCountRef} />
                        </Box>
                        
                    </Grid>
                    <Grid item style={{margin: 'auto'}}>
                        <IconButton 
                            className={clsx(classes.icon, classes.iconRed)}
                            onClick={handleStart}
                        >
                            {isPlaying &&
                            <PauseIcon/>
                            }
                            {!isPlaying &&
                            <PlayArrowIcon/>
                            }
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}


const statusBarHeight = 50
const buttonWidth = 50

const useStyles = makeStyles(theme => ({
    root: {
        height: statusBarHeight,
        width: '100%',
        left: 0,
        top: 10,
        position: 'absolute',
        zIndex: 99
    },
    hide: {
        display: 'none'
    },
    modeText: {
        color: '#FFC004', fontSize: 24,
    },
    timeText: {
        color: 'white', fontSize: 28, fontWeight: 'bold'
    },
    wrap: {
        position: 'relative'
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: statusBarHeight,
        left: 0,
        top: 0,
        backgroundColor: 'rgba(0,0,0,0.6)',
        zIndex: 2,
        borderRadius: 35
    },
    iconSetting: {
        height: statusBarHeight,
        width: buttonWidth,
        color: '#fff',
        '& .MuiSvgIcon-root': {
            fontSize: 30
        }
    },
    icon : {
        height: statusBarHeight,
        width: buttonWidth,
        color: '#fff',

        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.7)',
        },
        backgroundColor: 'rgba(0,0,0,0.6)',
        '& .MuiSvgIcon-root': {
            fontSize: 40    
        }
    },
    iconRed: {
        backgroundColor: '#FF0005',
        '&:hover': {
            backgroundColor: '#C90009',
        }
      
    },
    select: {
        color: '#FFC004',
        fontSize: 24,
        fontWeight: 'bold',

        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
        '& .MuiSelect-icon': {
            color: '#FFC004',
        }
    },
}));


