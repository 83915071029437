import React from 'react';

import {Box, Grid, Typography} from '@material-ui/core';
import {Table, TableBody, TableCell, TableContainer, TableRow} from '@material-ui/core';
import BarChart from '@user-components/BarChart';
import PieChart from '@user-components/PieChart';
import {makeStyles} from "@material-ui/core/styles";

const defaultColor = ['#36A2EB', '#4BC0C0', '#FF9F40', '#FE6384']
const ResultBalance = (props) => {
    const { measureData, zoom, fontSize, suggestedMax } = props

    const total = {
        type: 'total',
        hitCount: new Array(3).fill(0)
    }

    measureData.map((data, index) => {
        let totalHitCount = 0
        for(let i = 0; i < data.hitCount.length; i++){
            totalHitCount += data.hitCount[i]
        }
        total.hitCount[index] = totalHitCount

        return data
    })
    
    return (
        <Box style={{zoom}}>
            {/*<Box display="flex" justifyContent="center" >*/}
            {/*    <Box textAlign="center">*/}
            {/*        <Typography variant="h5"  >*/}
            {/*            뇌기능 성향분석*/}
            {/*        </Typography>*/}
            {/*        <Typography variant="subtitle1" >*/}
            {/*            Brain Function Inclination Analysis*/}
            {/*        </Typography>*/}
            {/*    </Box>*/}
            {/*</Box>*/}

            <Box mt={3}>
                <Grid container justify={ 'center' } >
                    <Grid item>
                        <PieChart
                            key={Math.random()}
                            defaultData={ total.hitCount }
                            labels={ [
                                '8Hz ~ 12Hz',
                                '12Hz ~ 16Hz',
                                '16Hz ~ 20Hz',
                                '20Hz ~ 24Hz'
                            ]}
                            fontSize={fontSize}
                            height={ 350 }
                            width={ 350 }
                            title={ props.title }
                            colors={ defaultColor }
                            legendPosition={ 'right' }
                            useColorIndex={true}
                        />
                    </Grid>
                    <Grid item xs={2}/>
                    <Grid item>
                        <BarChart
                            key={Math.random()}
                            data={ new Array(6).fill(0) }
                            defaultData={total.hitCount}
                            showTopValue={true}
                            useColorIndex={true}
                            fontSize={fontSize}
                            suggestedMax={suggestedMax}
                            topValueFontSize={fontSize}
                            labels={ [
                                '8Hz ~ 12Hz',
                                '12Hz ~ 16Hz',
                                '16Hz ~ 20Hz',
                                '20Hz ~ 24Hz'
                            ]}
                            height={280}
                            colors={ defaultColor }
                        />
                    </Grid>
                </Grid>
                <Grid container justify={ 'center' } spacing={2}>
                    { measureData.map((data, index) => {
                        return (
                            <Grid item key={index}>
                                <BalancePie {...data} />
                            </Grid>
                        )
                    }) }
                </Grid>
            </Box>
        </Box>
    );
}

/**
 * 뇌기능 분석 상태를 DOT 형식으로  표시합니다.
 * @param {*} props
 */
export function BalancePie(props) {
    const classes = useStyles()
    const bandHz = []
    const defaultColors = defaultColor

    let startHz = 8;
    let title = ''
    let pieSize = 200
    let descriptions = []

    if('rest' === props.type){
        startHz = 8
        title = '8 ~ 12Hz Mapping'
        descriptions = ['❉ 멘탈 안정성','❉ 피로회복능력','❉ 재충전 능력','❉ 활동 성향','❉ 지구력']
    } else if ('attemption' === props.type){
        startHz = 12;
        title = '12Hz ~ 16hz Mapping'
        descriptions = ['❉ 주의력','❉ 사회성','❉ 적응성','❉ 관찰력','❉ 면역능력']
    } else if ('concentration' === props.type){
        startHz = 16;
        title = '16Hz ~ 20Hz Mapping'
        descriptions = ['❉ 집중력','❉ 기획력','❉ 발표력','❉ 창의력','❉ 논리력']
    } else if ('brainactive' === props.type){
        startHz = 20;
        title = '20Hz ~ 24z Mapping'
        descriptions = ['❉ 추진력', '❉ 사고력', '❉ 인내/집착력', '❉ 성취력', '❉ 활동력']
    } else if ('total' === props.type){
        title = '뇌기능 균형상태 종합분석'
        pieSize = 300
    }


    let labels = []
    let size = 'total' === props.type ? 3 : 4

    for(let i = 0; i < size; i++){
        labels.push((i + startHz) + 'hz')
        bandHz.push({
            hz: i + startHz,
            value: props.hitCount[i]
        })
    }

    return (
        <Box p={1} className={classes.wrap}>
            <Box pl={1} pr={1} pt={1}>
                <Box display="flex" justifyContent="center"  mb={3}>
                    <Typography variant="subtitle1" >
                        {title}
                    </Typography>
                </Box>
                <Box>
                    <PieChart
                        key={Math.random()}
                        defaultData={ props.hitCount }
                        labels={ labels }
                        height={ pieSize }
                        width={ pieSize }
                        title={ props.title }
                        colors={ defaultColors }
                        legendPosition={'bottom'}
                        useColorIndex={true}
                    />
                </Box>
            </Box>
            <Box mt={3}>
                <TableContainer>
                    <Table  aria-label="caption table" size="small">
                        <TableBody>
                            {bandHz.map((hz, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">{hz.hz} hz</TableCell>
                                    <TableCell align="right">{hz.value} point</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box mt={3}>
                    {descriptions.map((text, index) => (
                        <Box align="left" key={index} pl={2}>
                            <Typography variant={"body2"}>{text}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}


const useStyles = makeStyles(() => ({
    root: {

    },
    wrap: {
        backgroundColor: '#F5F9FE',
        border: '1px solid #BCBEC0'
    }
}));

export default ResultBalance
