const zlib = require('zlib');

export default class NetworkHelper {

    static getEncParam(data, useUrlEncode = false) {
        const enc = zlib.deflateSync(JSON.stringify(data)).toString('base64');
        if(useUrlEncode){
            return enc.replace(/\+/ig,'.')
                        .replace(/\//ig, '_')
                        .replace(/=/ig, '-')
        }
        return enc
    }
    
    
    static getDecParam(data, useUrlEncode = false){
        let refData = data
        if(useUrlEncode){
            refData = refData.replace(/\./ig,'+')
                            .replace(/_/ig, '/')
                            .replace(/-/ig, '=')
        }
        const jsonString = zlib.inflateSync(Buffer.from(refData, 'base64')).toString()
        return JSON.parse(jsonString) 
    }


}