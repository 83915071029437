import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';

import AudioPlayer from '@user-pages/exercise/common/AudioPlayer'
import {Box, Grid} from '@material-ui/core';

import Intro from '@user-pages/exercise/common/Intro'
import SocketProvider from '@user-provider/SocketProvider';
import ExerciseService from '@user-service/ExerciseService';

import MeasureHelper from '@helper/MeasureHelper'
import ExerciseStatusBar from "./ExerciseStatusBar";
import '@user-style/hexgrid.css';

import Hexagon from 'react-hexagon'

const { measureTypeToCode } = MeasureHelper

const MeasureHzType = 'concentration'
const PlaybackRate = 1.7


const Concentration = (props) => {
    const stageRef = useRef()
    const [status, setStatus] = useState('intro')

    useEffect(()=>{
        SocketProvider.connect(()=>{}, window, MeasureHzType)

        return () => {
            SocketProvider.measureStop(true).then()
        }
    },[])

    const onNext = () => {
        setStatus('stage')
    }

    return (
        <Box style={{position: 'relative'}}>
            <Intro 
                onNext={onNext.bind(this)} 
                poster={'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/Brain-ConcentrationIntro.png'}
                src={'https://d2iajcsshqwtw3.cloudfront.net/assets/Brain-ConcentrationIntro.webm'}
                onPlay={() => {
                    setStatus('stage')
                }}
            />
            
            {'stage' === status &&
            <Stage ref={stageRef}
                   history={props.history}
            />
            }
        </Box>
    );
}


export class Stage extends React.Component {
    container
    clientRect

    brainWidth = 600
    brainHeight = 650

    audioRef = React.createRef()
    backgroundRef = React.createRef()
    arrowRef = React.createRef()
    effectRef = React.createRef()
    scoreRef = React.createRef()
    popupRef = React.createRef()
    
    codeGroupRef = React.createRef()
    currentMeasureType = 'concentration'
    currentMeasureCode = 'AA'

    playStatus = 'ready'
    shootInfo = {}

    isAllPause = false
    constructor(props) {
        super(props)

        this.state = {
            items: [1,2,3,4,5,6,7,8,9,10],
            position:[
                { width: 106, height: 120},
                { width: 106, height: 120 },
                { width: 106, height: 120 },
                { width: 106, height: 120 },
                { width: 106, height: 120 },
                { width: 106, height: 120 },
                { width: 106, height: 120 },
                { width: 106, height: 120 },
                { width: 106, height: 120 },
            ],
            background:[]
        }
    }

    componentDidMount() {
        this.container = ReactDOM.findDOMNode(this)
        this.clientRect = this.container.getClientRects()[0]
        this.audio = ReactDOM.findDOMNode(this)
        this.audioRef.current.loadEffect(MeasureHzType)

        SocketProvider.onMeasure = (data) => {
            // debug
            try {
                if('ready' === this.playStatus){
                    if(!this.arrowRef.current.isPlaying()){

                        this.playStatus = 'readyToShoot';
                        this.audioRef.current.effect('readyToShoot')
                        this.popupRef.current.show()

                        this.shootInfo = {
                            bestDirection: data.bestDirection,
                            bestScore: data.bestScore,
                            bestMv: data.bestMv,
                            bestHz: data.bestHz,
                            hitArr: data.hitArr
                        }
                    }
                }
            } catch (e){}
        }
        this.backgroundRef.current.playbackRate = PlaybackRate
    }

    refresh(){
        this.setState({
            ...this.state
        })
    }

    run() {
        this.startSoundWithPlay()
    }

    startSoundWithPlay(){
        this.readyArrow()
        this.refresh()
    }

    async startExercise(runningTime, measureType, measureCode) {
        
        this.currentMeasureType = measureType
        this.currentMeasureCode = measureCode
        
        this.scoreRef.current.reset()

        await SocketProvider.measureStart(runningTime,0, MeasureHzType, this.currentMeasureType, measureTypeToCode(this.currentMeasureType), this.currentMeasureCode)
        this.run()
    }

    stopExercise(playSound = true) {
        this.clearStage()
        if(playSound){
            this.audioRef.current.effect('stop')
        }
        
        SocketProvider.measureStop().then()
    }

    readyArrow(){
        if(this.scoreRef.current.isOver()){
            this.stopExercise()
        } else {
            this.backgroundRef.current.currentTime = 0
            this.backgroundRef.current.play();
            setTimeout(() => {
                this.audioRef.current.effect('ready', 0.6)
            },200)
        }
    }

    playShootArrowEffect(){
        setTimeout(() => {
            if(this.shootInfo.bestScore){
                this.audioRef.current.effect('hit', 0.6)    
            }
        },200)

        this.playStatus = 'ready';
    }

    shootArrow(){
        try {
            this.playStatus = 'shooting'
            this.popupRef.current.hide()
            this.arrowRef.current.play(this.shootInfo)
            this.scoreRef.current.update(this.shootInfo.bestScore)
            // this.scoreBoardRef.current.addHistory(this.shootInfo)

            setTimeout(() => {
                this.audioRef.current.effect('shoot', 0.8)
            },200)
        } catch {}
    }

    clearStage() {
        this.backgroundRef.current.currentTime = 0
        this.backgroundRef.current.pause()

        this.arrowRef.current.stop()
    }

    onVideoTimeUpdate(event) {
        if(this.isAllPause) return false
        
        if(this.backgroundRef.current.paused) {
            this.backgroundRef.current.currentTime = 3.3
            this.backgroundRef.current.play()
            return false
        }
        const time = event.target.currentTime
        if(15.9 <= time){
            this.backgroundRef.current.currentTime = 3.3
        }
    }
    
    render() {
        const height = window.innerHeight
        return (
            <Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    position="absolute"
                    right={40}
                    top={90}
                    zIndex={20}
                    className={'concentration'}
                >
                    <Score ref={this.scoreRef}/>
                </Box>
                <ExerciseStatusBar
                    measureHzType={MeasureHzType}
                    measureType={this.currentMeasureType}
                    measureCode={this.currentMeasureCode}
                    hideHit={true}
                    hideIntervalTime={true}
                    onResultVar={ async ({measureLogID, measureHzType}) => {
                        const json = {
                            score : this.scoreRef.current.getScore()
                        }

                        await ExerciseService.setResultVar({
                            measureLogID,
                            measureHzType,
                            resultVar: JSON.stringify(json)
                        })
                    }}
                    close={async () => {
                        await this.stopExercise(false, true)
                    }}
                    start={async (runningTime, measureType, measureCode) => {
                        await this.startExercise(runningTime, measureType, measureCode)
                    }}
                    pause={(isPause) => {
                        this.isAllPause = isPause
                        
                        if(isPause){
                            this.backgroundRef.current.pause()
                        } else {
                            this.backgroundRef.current.play()
                        }
                    }}
                />

                <Grid container onClick={() => {
                    if(this.isAllPause) return false
                    
                    if('readyToShoot' === this.playStatus){
                        this.shootArrow()
                    }
                }}>
                    <Grid item xs={12} style={{ position: 'relative', height: height}}>
                        <Box>
                            <video 
                                id="background-video" 
                                ref={this.backgroundRef}
                                muted
                                poster={'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/Brain-ConcentrationBG.png'}
                                onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                                style={{height: height, width: '100%', objectFit: 'cover' }}>
                                <source src={'https://d2iajcsshqwtw3.cloudfront.net/assets/BrainConcentrationBG.webm'} type="video/mp4" />
                            </video>
                        </Box>
                        <ArrowVideo
                            ref={this.arrowRef}
                            onEnded={(videoIndex) => {
                                if(1 === videoIndex){
                                    this.readyArrow()
                                } else {
                                    this.playShootArrowEffect()
                                }
                            }}/>
                        <Box
                            display="flex"
                            justifyContent="center"
                            position="absolute"
                            bottom={5}
                            style={{height: 230, width:'100%', display:'none'}}>
                            <Grid container spacing={1} justify={"center"}>
                                <Grid item>
                                    <Box
                                        style={{ width: 300,
                                            height: 230,
                                            borderRadius: 10,
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                            backgroundColor: 'rgba(0,0,0,0.7)'}}>
                                        <Box style={{
                                            color: '#FCD800',
                                            fontSize: 30,
                                            fontWeight: 'bold',
                                            paddingLeft:20,
                                            paddingTop: 20
                                        }}>20</Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                    </Grid>
                </Grid>
                <AudioPlayer ref={ this.audioRef }
                             mode={'concentration'}
                />
                <Popup ref={this.popupRef}/>
            </Box>
        )
    }
}


export class Popup extends React.Component{
    constructor() {
        super()

        this.state = {
            opacity: 0
        }
    }

    show(){
        this.setState({
            opacity: 0.7
        })
    }

    hide(){
        this.setState({
            opacity: 0
        });
    }

    render() {

        return (
            <Box
                display="flex"
                justifyContent="center"
                position="absolute"
                top={window.innerHeight - 250}
                left={'calc(50% - 235px)'}
                style={{
                    opacity: this.state.opacity,
                    backgroundColor: '#fff',
                    padding:15,
                    borderRadius: 15,
                    transitionDuration: 0.5
                }}
            >
                빛 화살이 준비되었습니다. 마우스 왼쪽클릭으로 빛 화살을 발사하세요!
            </Box>
        )
    }
}

class Score extends  React.Component {
    
    defaultOptions = {
        currentScore: 0 ,
        maxScore: 100,
        maxCount: 10,
        currentCount: 0
    }
    constructor() {
        super();
        this.state = this.defaultOptions
    }

    getCount(){
        return this.state.currentCount
    }
    getScore(){
        return this.state.currentScore
    }
    isOver(){
        return this.state.currentCount >= this.state.maxCount
    }

    reset() {
        this.setState({
            ...this.defaultOptions
        })
    }
    update(score) {
        this.setState({
            ...this.state,
            currentScore: this.state.currentScore + (score ? score: 0),
            currentCount: this.state.currentCount + 1
        })
    }

    render() {
        const { currentScore, currentCount, maxCount} = this.state
        return (
            <div className={'score'}>
                <Hexagon 
                    style={{
                        fill: 'rgba(0,0,0,0.7)',
                        stroke: '#838F9A',
                        strokeWidth:5,
                        transitionDuration: 0.5
                    }} className="text">
                    <text x="50%" y="40%" style={{fontSize: 80, fontWeight: 'normal'}}>{currentCount} / {maxCount}</text>
                    <text x="50%" y="65%">
                        {currentScore}
                    </text>
                </Hexagon>
            </div>
        )
    }
}


export class ArrowVideo extends React.Component {
    arrowVideoRef = React.createRef()
    arrowShootVideoRef = React.createRef()

    playIndex = 0
    playStatus = 'ready'

    transitionDuration = 500

    constructor(props) {
        super()

        this.state = {
            onVideoEnded: props.onEnded,
            arrowPoster: [
                'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/arrow/arrow_shine.png',
                'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/arrow/arrow_shot.png',
            ],
            arrowSrc: [
                'https://d2iajcsshqwtw3.cloudfront.net/assets/arrow/shine_arrow_closeup.webm',
                'https://d2iajcsshqwtw3.cloudfront.net/assets/arrow/LT3.webm'
            ],
            missedArrowPoster:[
                'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/arrow/arrow_shot_ml.png',
                'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/arrow/arrow_shot_mr.png',
                'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/arrow/arrow_shot_ml.png',
                'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/arrow/arrow_shot_mr.png',
            ],
            missedArrowSrc: [
                'https://d2iajcsshqwtw3.cloudfront.net/assets/arrow/MLF.webm',
                'https://d2iajcsshqwtw3.cloudfront.net/assets/arrow/MRF.webm',
                'https://d2iajcsshqwtw3.cloudfront.net/assets/arrow/MLB.webm',
                'https://d2iajcsshqwtw3.cloudfront.net/assets/arrow/MRB.webm',
            ]
        }
    }

    componentDidMount() {
        this.arrowVideoRef.current.playbackRate = PlaybackRate
        this.arrowShootVideoRef.current.playbackRate = PlaybackRate
    }

    onArrowVideoEnded() {
        this.arrowVideoRef.current.style.opacity = 0
        this.arrowShootVideoRef.current.style.opacity = 1
        this.arrowShootVideoRef.current.play()

        if(this.state.onVideoEnded){
            if('stop' !== this.playStatus){
                this.state.onVideoEnded(0)
            }
        }
    }

    onVideoTimeUpdate(event) {
        const time = event.target.currentTime
        if(2.8 <= time){
            this.arrowShootVideoRef.current.pause()
            
            setTimeout(() => {
                this.playStatus = 'ready'
            }, 1000)

            this.arrowShootVideoRef.current.style.opacity = 0
            if(this.state.onVideoEnded){
                if('stop' !== this.playStatus){
                    this.state.onVideoEnded(1)
                }
            }
        }
    }

    stop(){
        this.playStatus = 'stop'
        this.arrowVideoRef.current.pause();
        this.arrowVideoRef.current.style.opacity = 0

        this.arrowShootVideoRef.current.pause()
        this.arrowShootVideoRef.current.style.opacity = 0
    }

    play(data){
        // 상태가 재생중이 아닐떄만 처리 합니다.
        if(this.isPlaying()){
            return
        }
        
        this.playStatus = 'playing'
        this.arrowVideoRef.current.style.opacity = 1
        this.arrowVideoRef.current.play()
        
        const index = Math.floor(Math.random() * 4)
        
        const missedArrowSrc = this.state.missedArrowSrc[index]
        const missedArrowPoster = this.state.missedArrowPoster[index]
    
        try {
            this.arrowShootVideoRef.current.poster = data.bestDirection
                ? `https://d2iajcsshqwtw3.cloudfront.net/assets/poster/arrow/arrow_shot.png`
                : missedArrowPoster
        } catch {}
        
        this.arrowShootVideoRef.current.src = data.bestDirection
            ? `https://d2iajcsshqwtw3.cloudfront.net/assets/arrow/${data.bestDirection}.webm`
            : missedArrowSrc
    }

    isPlaying() {
        return ('playing' === this.playStatus)
    }


    render() {
        const height = window.innerHeight
        return (
            <Box>
                <Box
                    display="flex"
                    position="absolute"
                    zIndex={89}
                    style={{left:0, top:0, width: '100%', height:'calc(100%)'}}
                >
                    <video id="arrow-video"
                           ref={this.arrowVideoRef}
                           onEnded={this.onArrowVideoEnded.bind(this)}
                           poster={this.state.arrowPoster[0]}
                           style={{height: height, width: '100%', objectFit: 'cover', opacity: 0, transitionDuration: `0.5s` }}>
                        <source src={this.state.arrowSrc[0]} type="video/mp4" />
                    </video>
                </Box>
                <Box
                    display="flex"
                    position="absolute"
                    zIndex={90}
                    style={{left:0, top:0, width: '100%', height:'100%'}}
                >
                    <video id="arrow-shoot-video"
                           ref={this.arrowShootVideoRef}
                           // onEnded={this.onArrowShootVideoEnded.bind(this)}
                           onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                           style={{height: height, width: '100%', objectFit: 'cover', opacity: 0, transitionDuration: `0.5s` }}>
                    </video>
                </Box>
            </Box>

        )
    }
}

export default Concentration
