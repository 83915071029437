import React, {useState} from "react";
import {Box, Button} from "@material-ui/core";

const queryString = require('query-string');

export class Intro extends React.Component {
    videoRef = React.createRef()
    
    constructor(props) {
        super();

        const params = queryString.parse(window.location.search);

        this.state = {
            onNext: props.onNext,
            onPlay: props.onPlay,
            
            src: props.src,
            poster: props.poster,
            color: props.color ? props.color : 'black',
            opacity: 1,
            duration: '0s',
            display: 'block',
            prescriptionExerciseID: params.prescriptionExerciseID
            
        }
        this.state.textColor = 'white' === this.state.color ? 'black' : 'white' 
    }
    componentDidMount() {
    }

    onVideoEnded() {
        this.setState({
            ...this.state,
            opacity: 0,
            duration: '0.5s'
        })
        
        this.goStage()
    }

    onVideoLoad() {
        
        if(this.state.prescriptionExerciseID) {
            this.goStage()
            return
        }
        
        setTimeout(() => {
            this.videoRef.current.play()
        }, 500)
        
        setTimeout(() => {
            if(this.state.onPlay){
                this.state.onPlay()
            }
        }, 1000)

    }
    goStage() {
        this.setState({
            ...this.state,
            opacity: 0,
            duration: '0.5s',
        })
        
        setTimeout(() => {
            this.setState({
                ...this.state,
                display: 'none'
            })
        }, 500)

        if(this.state.onNext){
            this.state.onNext()
        }
    }

    render() {
        
        return (
            <Box 
                 style={{
                     position: 'fixed',
                     left: 0 ,
                     top: 0,
                     width: '100%',
                     height: window.innerHeight, 
                     zIndex: 9999,
                     opacity: this.state.opacity,
                     transitionDuration: this.state.duration,
                     display: this.state.display
                 }}
            >
                <Box style={{height:'100%'}}>
                    <video id="intro-video"
                           onEnded={this.onVideoEnded.bind(this)}
                           onLoadedData={this.onVideoLoad.bind(this)}
                           ref={this.videoRef}
                           muted
                           autoPlay
                           poster={this.state.poster}
                           style={{height: '100%', width: '100%', objectFit: 'cover' }}>
                        <source src={this.state.src} type="video/mp4"/>
                    </video>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    position="absolute"
                    right={100}
                    bottom={100}
                    zIndex={10}
                >
                    <Button variant={"outlined"}
                            size={"large"}
                            style={{color: this.state.textColor, borderColor: this.state.textColor}}
                            onClick={this.goStage.bind(this)}
                    >
                        시작하기
                    </Button>
                </Box>
            </Box>
        )
    }
}

export default Intro