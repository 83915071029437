import React, {useEffect, useState} from 'react';

import { Box, Button, Grid, Typography} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { makeStyles } from '@material-ui/core/styles';

import SurveyService from '@user-service/SurveyService'
import Skeleton from '@material-ui/lab/Skeleton';
import HeatMap from 'react-heatmap-grid';

const SurveyDetail = (props) => {
    const [survey, setSurvey] = useState(null)
    
    
    useEffect(()=>{ 
        SurveyService.getSurveyByProfileID(props.profileID, (result)=>{
            if(result.data){
                setSurvey(result.data)
            } else {
                setSurvey([])
            }

        })
    },[])

    if(!survey) return <LoadingSkeleton/>

    return (
        <div className="mypage-wrap wrapper2">
            <div>
                <section className="section section2_2 bdtop pt40">
                    <SurveyHeatMap {...survey} profileID={props.profileID} />
            </section>
        </div>
    </div>
    );
}


//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <div className="editprofile_wrap wrapper" xs={6} sm={6}>
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </div>
        </Box>
    )
}

//자각 증상 설문 시작하기 
export function StartSurveyBox(props){

    //이번달에 진행된 자각증상이 있으면 박스를 표시하지 않습니다.
    if(props.isSubmitted)  return <></>

    return <div>
            <section className="section section1">
                <p className="txt01">2019년 12월, <br/> 자각증상 검사를 시작해보세요! </p>
                <Typography variant="subtitle2" 
                    style={{color:'#999'}}>
                    매월 자각증상 검사를 시작해보세요 <br/>
                    브레인헬스 운동을 통해 변화되어 가는 모습을 확인 할 수 있습니다. 

                </Typography>
                <Button className="btn-basic btn-w100" onClick={()=>{
                    window.location.href='/survey'
                }}>시작하기</Button> 
            </section>
        </div>
}

//자각증상 1년치 히트맵 
export function SurveyHeatMap(props) {

    const [surveyItems, setSurveyItems] = useState(null)
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())


    useEffect(()=>{ 
        SurveyService.getSurveyHistoryByProfile(currentYear, props.profileID, (result)=>{
            //등록된 데이터 없다면 기본 필드만 보여주고 
            //데이터가 있으면 등록한 결과를 표시해줍니다.
            if(result.data){
                setSurveyItems(result.data.items)
            } else {
                setSurveyItems([...props.items])
            }

        })
    },[currentYear])

    //설문기록이 없다면 heatmap을 그리지 않습니다.
    if(!surveyItems) return <></>  
    

    const xLabels = new Array(12).fill(0).map((_, i) => `${i+1}월`);
    const xLabelsVisibility = new Array(12).fill(true)
    
    const yLabels = surveyItems.map((item, index)=>`${index+1}. ${item.content}`)
    const data = new Array(yLabels.length).fill(0).map((_, rowIndex) =>
        // new Array(xLabels.length).fill(0).map(() => parseInt(Math.floor(Math.random() * 100) / 10 ) * 10)
        new Array(xLabels.length).fill(0).map((_, columnIndex)=>{
            const rounds = surveyItems[rowIndex].rounds
            if(rounds){
                const key = currentYear + '-' + ('00' + (columnIndex+1)).substr(-2)
                const surveyAnswer = rounds[key]
                
                if(surveyAnswer && 0 < surveyAnswer.length){
                    return surveyAnswer[0].symptomLevel
                } else {
                    return null
                }
            }
        })
    );

    return  <div>
                <Grid container justify="center" spacing={5}>
                    <Grid item>
                        <Button  startIcon={<KeyboardArrowLeftIcon />} size="large" onClick={()=>{
                            setCurrentYear(currentYear-1)
                        }}>
                            {currentYear-1}년
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button size="large" variant="outlined">
                            <Typography variant="h5" component="h3" >
                                {currentYear}년
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button size="large" endIcon={<KeyboardArrowRightIcon />} onClick={()=>{
                            setCurrentYear(currentYear+1)
                        }}>
                            {currentYear+1}년
                        </Button>
                    
                    </Grid>
                </Grid>
                <br/><br/>

                <HeatMap
                        xLabels={xLabels}
                        yLabels={yLabels}
                        xLabelsLocation={"top"}
                        xLabelsVisibility={xLabelsVisibility}
                        xLabelWidth={50}
                        yLabelWidth={230}
                        yLabelTextAlign={'left'}
                        data={data}
                        squares
                        // onClick={(x, y) => alert(`Clicked ${x}, ${y}`)}
                        cellStyle={(background, value, min, max, data, x, y) => {

                            var newBackground = `rgba(123, 201, 110, ${1 - (max - value) / (max - min)})`
                            var color = '#333'
                            if(!value || 0 === value) {
                                newBackground = `#efefef`
                                color = `#efefef`
                            }

                            return {
                                background: newBackground,
                                color: color,
                                fontSize: "15px",
                                width:'45px',
                                height:'45px',
                                lineHeight:'30px'
                            }
                        }}
                        cellRender={value => value && `${value}%`}
                        title={(value, unit) => `${value}`}
                    />
            </div>
                    
}

const useStyles = makeStyles(theme => ({
    yearTitle: {
        fontSize:30,
        marginTop: 50,
        marginBottom: 17,
        textAlign: 'left'
    },
    emptyBottom: {
        height:30
    },
    measureTitle: {
        fontSize:14,
        fontWeight: 'bold !important',
        color: '#333',

    }
}));



export default SurveyDetail
