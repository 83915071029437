import React, {useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { CheckCircle, Cancel } from '@material-ui/icons';
import BridgeProvider from "../provider/BridgeProvider";
import clsx from "clsx";


const DeviceStatusBox = () => {
  const classes = useStyles();
  const [isReceivingData, setReceivingData ] = useState(false);
  const [lastReceived, setLastReceived] = useState(Date.now());
  const timeoutId = useRef(null);

  useEffect(() => {
    try {
      window.app.onDeviceMeasureData = null;
      window.app.onDeviceMeasureDataUNC = null;

      window.app.onDeviceMeasureData = () => {
        setReceivingData(true);
        // setLastReceived(Date.now());
        clearTimeout(timeoutId.current); // 이전 타이머를 취소
        timeoutId.current =  setTimeout(() => { // 10초 후에 상태를 변경
          setReceivingData(false);
        }, 10000);
      };

      window.app.onDeviceMeasureDataUNC = () => {
        setReceivingData(true);
        // setLastReceived(Date.now());
        clearTimeout(timeoutId.current); // 이전 타이머를 취소
        timeoutId.current =  setTimeout(() => { // 10초 후에 상태를 변경
          setReceivingData(false);
        }, 10000);
      };

      setTimeout(() => {
        BridgeProvider.instance.deviceMeasureStart(86400, 'C').then();
      }, 1000);
    } catch (e) {
      console.log(e);
    }

    return () => {
      try {
        BridgeProvider.instance.deviceMeasureStop();
        window.app.onDeviceMeasureData = null;
        window.app.onDeviceMeasureDataUNC = null;
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  // const secondsAgo = Math.floor((Date.now() - lastReceived));
  
  return (
    <Box className={classes.statusBox}>
      {isReceivingData ? (
        <CheckCircle color="primary" className={clsx(classes.statusIcon,{
          [classes.active] : isReceivingData
        })} />
      ) : (
        <Cancel color="error" className={clsx(classes.statusIcon,{
          [classes.error] : !isReceivingData
        })} />
      )}
      <Typography>
        {isReceivingData ? `BRHW 연결됨` : '신호 없음'}
      </Typography>
    </Box>
  );
  

}

const useStyles = makeStyles(theme => ({
  statusBox: {
    borderRadius: 6,
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    backgroundColor: '#333',
    color: 'white',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    marginRight: theme.spacing(1),
    
  },
  active: { color: 'green'},
  error: {color: 'red'}
}));


export default DeviceStatusBox