import React, { useState } from 'react';
import DraggableListItem from './DraggableExerciseItem';
import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {
    Box, Grid
} from '@material-ui/core';

import {
    DragDropContext,
    Droppable,
} from 'react-beautiful-dnd';

const DraggableList = React.memo(({ 
                                      items, 
                                      onDragEnd, 
                                      onUpdate, 
                                      onDelete,
                                      onUpdateWorkCount,
                                      prescriptDayCount,
                                      exerciseWeekCount,
                                      exerciseDayCount,
                                  }) => {
    const classes = useStyles();
    const day = new Array(30).fill(0)
    
    const [pDayCount, setPDayCount] = useState(prescriptDayCount ? prescriptDayCount : 10)
    const [eWeekCount, setEWeekCount] = useState(exerciseWeekCount ? exerciseWeekCount : 3 )
    const [eDayCount, setEDayCount] = useState(exerciseDayCount ? exerciseDayCount : 1)
    
    return (
        <React.Fragment>
            {/*<Box pl={1}>*/}
            {/*    <Grid container spacing={1}>*/}
            {/*        <Grid item>*/}
            {/*            <FormControl className={classes.formControl}>*/}
            {/*                <InputLabel id="label1" className={classes.title}>처방일</InputLabel>*/}
            {/*                <Select*/}
            {/*                    labelId="label1"*/}
            {/*                    id="demo-simple-select"*/}
            {/*                    value={pDayCount}*/}
            {/*                    onChange={(event) => {*/}
            {/*                        onUpdateWorkCount('prescriptDayCount', event.target.value)*/}
            {/*                        setPDayCount(event.target.value)*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    {day.map((value, index) => {*/}
            {/*                        return (*/}
            {/*                            <MenuItem value={index} key={`menu-item-${index}`}>{index}일</MenuItem>*/}
            {/*                        ) */}
            {/*                    })}*/}
            {/*                </Select>*/}
            {/*            </FormControl>*/}
            {/*        </Grid>*/}
            {/*        <Grid item>*/}
            {/*            <FormControl className={classes.formControl}>*/}
            {/*                <InputLabel id="label2">주 횟수</InputLabel>*/}
            {/*                <Select*/}
            {/*                    labelId="label2"*/}
            {/*                    value={eWeekCount}*/}
            {/*                    onChange={(event) => {*/}
            {/*                        onUpdateWorkCount('exerciseWeekCount', event.target.value)*/}
            {/*                        setEWeekCount(event.target.value)*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    <MenuItem value={1}>1일</MenuItem>*/}
            {/*                    <MenuItem value={2}>2일</MenuItem>*/}
            {/*                    <MenuItem value={3}>3일</MenuItem>*/}
            {/*                    <MenuItem value={4}>4일</MenuItem>*/}
            {/*                    <MenuItem value={5}>5일</MenuItem>*/}
            {/*                    <MenuItem value={6}>6일</MenuItem>*/}
            {/*                    <MenuItem value={7}>7일</MenuItem>*/}
            {/*                </Select>*/}
            {/*            </FormControl>*/}
            {/*        </Grid>*/}
            {/*        <Grid item>*/}
            {/*            <FormControl className={classes.formControl}>*/}
            {/*                <InputLabel id="label3">하루에 최대 운동 수</InputLabel>*/}
            {/*                <Select*/}
            {/*                    labelId="label3"*/}
            {/*                    value={eDayCount}*/}
            {/*                    onChange={(event) => {*/}
            {/*                        if(2 < parseInt(event.target.value)){*/}
            {/*                            alert('하루 최대 운동횟수는 2회입니다.')*/}
            {/*                            return*/}
            {/*                        }*/}
            {/*                        onUpdateWorkCount('exerciseDayCount', event.target.value)*/}
            {/*                        setEDayCount(event.target.value)*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    <MenuItem value={1}>1일</MenuItem>*/}
            {/*                    <MenuItem value={2}>2일</MenuItem>*/}
            {/*                </Select>*/}
            {/*            </FormControl>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Box>*/}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-list">
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {items.map((item, index) => (
                                <DraggableListItem 
                                    item={item} 
                                    index={index} 
                                    key={Math.random()}
                                    onUpdate={onUpdate}
                                    onDelete={onDelete}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </React.Fragment>
    );
});

const useStyles = makeStyles((theme) => ({
    title:{
        fontSize: 25,
        color: '#333',
        marginBottom: 5 
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


export default DraggableList;
