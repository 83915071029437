import React, {forwardRef, useState} from "react";
import CssTextField from '@user-components/CssTextField';

const PasswordTextField = forwardRef((props, ref) => {
    const [, setValue] = useState('')
    const [helpText, setHelpText ] = useState('')
    const [isError, setError ] = useState(false)
    
    return <CssTextField
        label={props.label ? props.label : '비밀번호'}
        inputRef={ref}
        fullWidth
        type={props.showPassword ? 'text': 'password'}
        placeholder={props.placeholder}
        onChange={(event)=>{
            setValue(event.target.value)
            const password = event.target.value
            
            if(props.useBirthDate){
                let isError = false
                if(8 === password.length){
                    const regexVar = /^([0-9]{4})([0-9]{2})([0-9]{2})$/; // add anchors; use literal
                    
                    isError = !regexVar.test(password) 
                    setHelpText(isError ? '생년월일을 정확히 입력해주세요' : '') // pass the string, not the Date
                } else {
                    setHelpText('생년월일을 정확히 입력해주세요') // pass the string, not the Date
                    isError = true
                }

                setError(isError)
                
                if(props.valid){
                    props.valid(false, '')
                }
            }
            
            if(props.valid){
                props.valid(true, password)
            }
        }}
        onKeyPress={(event) => {
            if('Enter' === event.key){
                try {
                    props.next()    
                } catch {}
            }
        }}
        autoComplete={'off'}
        error={isError}
        helperText={helpText}
    />
})

export default PasswordTextField