import React from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import clsx from "clsx";

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

export default function ProfileSelectorBox(props) {
    const classes = useStyles();

    const { id, isEditMode, name,  isNew, from, fileName } = props
    const image = props.image ? props.image : fileName   
    return (
        <Box className={classes.card}
             onClick={async ()=>{
                if(props.click) {
                  props.click(props)
                }
             }}>

            <Box className={clsx({
                [classes.editProfile]: isEditMode && !isNew
            })} style={{display:isEditMode && !isNew ? 'block' : 'none'}}>
                <Box style={{position: 'relative'}}>
                    <Box className={'background'} />
                    <EditIcon className={'editIcon'}/>
                </Box>
            </Box>

            <Box 
                style={{}}
                className={clsx('wrap',{
                [classes.isNew] : isNew,
                [classes.default] : !isNew
            })}>
                {(!isNew && image) && 
                <img src={require(`@images/profile/${image}`)} alt={name}/>
                }
                {isNew &&
                <AddIcon className={'addIcon'}/>
                }
            </Box>

            <Box className={clsx('profileName')}>
                {props.lastPrescriptionID && 
                <div>
                  <CheckCircleIcon style={{width: 18, height: 18, marginRight: 4, color: 'green'}}/>
                  {props.lastPrescriptionID} 회차
                </div>
                }

              {!props.lastPrescriptionID &&
                <div>
                  <span style={{color: '#666'}}>측정없음</span> 
                </div>
              }
                <Box mt={2}>
                  <div className={'small'}>{props.cellphone || "-"}</div>
                  <div className={'code'}>{props.code}</div>
                </Box>
            </Box>
        </Box>
    )

}

const wrapWidth = 140
const wrapHeight = 230 

const boxSize = 140
const useStyles = makeStyles(() => ({
    edit: {
        backgroundColor: '#fff',
        color: '#333',
        '&:hover': {
            backgroundColor: '#CCCCCC'
        }
    },
    
    isNew: {
        backgroundColor: '#4B4D5A',
        '&:hover': {
            backgroundColor: '#2F303A',
        }
    },
    default: {
        backgroundColor: '#fff',
        borderRadius: 5, 
    },
    card: {
        width: wrapWidth,
        height: wrapHeight,
        cursor: 'pointer',
        position: 'relative',
        
        '& img':  {
            width: boxSize,
            height: boxSize
        },
        '& .profileName': {
            color: '#FFFEFE',
            fontSize: 18,
            fontWeight: 500,
            marginTop: 10,
            textAlign: 'center'
        },
        '& .profileName.new': {
            color: '#AEACB0',
            fontWeight: 'normal'
        },
        '& .wrap': {
            width: boxSize,
            height: boxSize,
        },
        '& .wrap img': {
            width: '100%',
            borderRadius: 5
        },
        '& .small': {
          fontSize: '0.8rem',
          marginBottom: 5
        },
        '& .code': {
          fontSize: '1.1rem'
        }
        
    },
        
}));

