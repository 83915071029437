import React from 'react';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import HeatMap from 'react-heatmap-grid';
import ColorHelper from '@helper/ColorHelper';
export default class MvHeatMap extends React.Component { 
    colors = ColorHelper.getColorsWith(51) ;
    hz = 20;
    maxMV = 50;
    defaultState;
    constructor(props) {
        super(props)
        this.initData(props);
    }   

    /**
     * 보여줄 페이지 지정
     * @param {*} page 
     */
    setPage(page){
        this.setState({
            page: page
        })
    }

    /**
     * 기본 구조를 초기화 합니다.
     */
    initData(props) {

        const initData = [];
        for(let i = 0 ; i < this.hz+1; i++){
            initData.push([]);
        }

        let yLabels = new Array(this.hz+1).fill(0).map((_, index) => {
            if(0 === index ){
                return '초';
            } else {
                return index - 1
            }
        });
        if(props.yLabels){
            yLabels = props.yLabels;
        }

        this.defaultState = {
            isShow: true,
            xLabels: [],
            yLabels: yLabels,
            data: initData,
            currentSec: 0,
            xLabelLimitCount: props.xLabelLimitCount ? props.xLabelLimitCount : 0,
            page: 1,
            xLabelWidth: 38,
            cellSize: 36,
            updateMap: true, 
            useButton: props.useButton ? props.useButton : false
        }

        this.state = JSON.parse(JSON.stringify(this.defaultState));
    }

    /**
     * 데이터를 받아 화면을 새로고칩니다.
     * @param {*} time 
     * @param {*} data 
     */
    addData(time, data){
        let calMv;

        this.state.xLabels.unshift(time);
        if(!this.state.useButton && this.state.xLabels.length > this.state.xLabelLimitCount){
            this.state.xLabels.pop();
        }

        this.state.data[0] = [time];
        data.map((mv, index) => {
            if(this.hz > index) {
                calMv = parseFloat(mv);
                this.state.data[index+1].unshift(calMv);

                if(!this.state.useButton && this.state.xLabels.length > this.state.xLabelLimitCount) {
                    this.state.data[index+1].pop();
                }
            }
        });
       
        if(this.state.xLabels.length > this.state.page * this.state.xLabelLimitCount){
            this.state.page++;
        }

       this.setState({
            ...this.state,
        })
    }
    /**
     * 전체데이터를 초기화 합니다.
     * @param {*} data 
     */
    setData(mvAll){
        // this.initData();
        
        let time, calMv;
        mvAll.map((data, index) => {
            time = (index + 1);
            this.state.xLabels.unshift(time);
            this.state.data[0] = [time];    

            data.map((mv, index) => {
                if(this.hz > index) {
                    calMv = parseFloat(mv);
                    this.state.data[index+1].unshift(calMv);        
                }
            });
        });

        this.setState({
            ...this.state,
        })

    }
    /**
     * 페이지리셋
     */
    reset(){
        const defaultData = JSON.parse(JSON.stringify(this.defaultState));
        this.setState({
            ...defaultData
        });
    }

    getColor(mv){
        let calMv = parseInt(mv);
        calMv = this.maxMV < calMv ? this.maxMV : calMv
        return this.colors[calMv];
    }

    clearData() {
        this.setState({
            xLabels: [],
            yLabels: [],
            data: []
        })
    }
    
    hide(){
        this.setState({
            isShow: false
        })
    }

    show() {
        this.setState({
            isShow: true
        })
    }

    render() {
        if(!this.state.isShow) {
            return <></>
        }
    
        let xLabels, renderData, start = 0, end = this.state.xLabelLimitCount;

        if( 0 < this.state.xLabelLimitCount && this.state.useButton) {

            start = this.state.xLabels.length - (this.state.page * this.state.xLabelLimitCount);
            if(0 > start) {
                end =  this.state.xLabelLimitCount - Math.abs(start)
                start = 0   
            } else {
                end = start + this.state.xLabelLimitCount
            }

            xLabels = this.state.xLabels.slice(start, end)
            renderData = this.state.data.map((arr) => {
                return arr.slice(start, end)
            })
        } else {
            xLabels = this.state.xLabels
            renderData = this.state.data
        }


        return (
            <Box style={{width: '100%'}}>
                {this.state.useButton &&
                <Grid container justify="center" spacing={5}>
                    {this.state.xLabels.length > 90 &&
                    <Grid item>
                        <Button size="large" 
                                variant={4 === this.state.page ? "contained" : "outlined"} 
                                onClick={() => {
                                    this.setPage(4)
                                }}>

                            <Typography variant="h5" component="h3" >
                                120 ~ 90초
                            </Typography>
                        </Button>
                    </Grid>
                    }

                    {this.state.xLabels.length > 60 &&
                    <Grid item>
                        <Button size="large" 
                                variant={3 === this.state.page ? "contained" : "outlined"} 
                                onClick={() => {
                                    this.setPage(3)
                                }}>

                            <Typography variant="h5" component="h3" >
                                90 ~ 60초
                            </Typography>
                        </Button>
                    </Grid>
                    }

                    {this.state.xLabels.length > 30 &&
                    <Grid item>
                        <Button size="large" 
                                variant={2 === this.state.page ? "contained" : "outlined"} 
                                onClick={() => {
                                    this.setPage(2)
                                }}>
                            <Typography variant="h5" component="h3" >
                                60 ~ 30초
                            </Typography>
                        </Button>
                    </Grid>
                    }
                    <Grid item>
                            <Button size="large" 
                                variant={1 === this.state.page ? "contained" : "outlined"} 
                                onClick={() => {
                                    this.setPage(1)
                                }}>
                            <Typography variant="h5" component="h3" >
                                30 ~ 1초
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
                }

                {this.state.updateMap && 
                <HeatMap 
                    xLabels={xLabels}
                    yLabels={this.state.yLabels}
                    xLabelsLocation={"bottom"}
                    xLabelsVisibility={[]}
                    yLabelWidth={20}
                    xLabelWidth={38}
                    yLabelTextAlign={'left'}
                    data={renderData}
                    squares
                    // onClick={(x, y) => alert(`Clicked ${x}, ${y}`)}
                    cellStyle={(background, value, min, max, data, x, y) => {
                        if(0 == y) {
                            return {
                                background: '#fff',
                                color: '#000',
                                fontSize: 15,
                                width: this.state.cellSize + 'px',
                                height: this.state.cellSize + 'px',
                                lineHeight: '18px',
                            }
                        } 
                    
                        return {
                            background: 0 === value ? '#0c058e' : this.getColor(value),
                            color: (value > 5 && value <30) || value > 50 ? '#333' : '#fff',
                            fontSize: 15,
                            width: this.state.cellSize + 'px',
                            height: this.state.cellSize + 'px',
                            lineHeight: '18px',
                        
                        }
                    }}
                    cellRender={ (value, x, y) => {
                        let textValue = '';
                        if('초' === y) {
                            textValue = x +'s';
                        } else {
                            textValue = parseFloat(value).toFixed(2)
                        }
                        
                        return <Box className="mv-value">
                                    {textValue} 
                                </Box>
                    }}
                    title={(value, unit) => `${parseFloat(value).toFixed(2)}`}
                />
                }
            </Box>
        )
    }
};
