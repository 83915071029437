import React, {useEffect, useState, useCallback} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { Box,  Select } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import CenterService from "../services/manage/CenterService";
import ProfileService from "../services/ProfileService";
/**
 * 처방전 선택
 * @param {*} props 
 */
export default function LastProfileSelect(props) {
    const classes = useStyles()
    const [ profiles, setProfiles ] = useState(null)
    const [ currentValue, setCurrentValue] = useState('')
    
    const fetch = useCallback(async () => {
        setProfiles(await ProfileService.getLastProfileDone())
    },[props.profileID])
    
    useEffect(() => {
        fetch().then()
        
        return (() => {
            setProfiles(null)
        })
    }, [fetch]);
    
    useEffect(() => {
        fetch().then()
    },[fetch, props.reload])
    
    if(!profiles) return <></>
    
    return (
        <Box className={classes.root}>
            <Select
                value={currentValue}
                className={classes.underline}
                onChange={(event)=>{
                    if(props.onChange){
                        props.onChange(event.target.value)
                    }
                }}>
              <MenuItem
                value={-1}
                className={clsx( {
                  [classes.active]: '' === currentValue
                })}
              >
                <span className={clsx('title',{
                  'text-white': '' === currentValue
                })} >전체</span>
              </MenuItem>
              
                {profiles.map((profile)=>{
                    return (
                        <MenuItem
                            key={profile.id}
                            value={profile.code}
                        >
                            <span className={'title'} >{profile.tableNumber} - {profile.code}</span>
                        </MenuItem>
                    )
                })}
            </Select>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        border: '1px solid #4B4D5A',
        padding:17,
        
        '& .MuiInput-underline::before': {
            
        },
        '& .MuiSelect-selectMenu': {
            paddingLeft: 10,
            paddingTop: 0,
            paddingBottom: 0
        },
        '& .title': {
            fontWeight: 'bold'
        },
        '& .sub': {
            fontSize:14, 
            paddingLeft: 10,
        },
        '& .MuiSelect-icon': {
            color: '#0055BF',
        },
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
    },
    active: {
        backgroundColor: '#0055BF',
        color: 'white',
        '& .title': {
            color: 'white'
        }
    },
    gray: {
      color: '#666'
    }
 
}));
