import React, {createRef, useCallback, useEffect, useRef, useState} from 'react';
import {Box, Button, Typography, Grid} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import clsx from "clsx";
import DateHelper from "../../../helper/DateHelper";
import MeasureService from "../../../services/MeasureService";
import PrintIcon from "@material-ui/icons/Print";
import PrintHelper from "../../../helper/PrintHelper";
import {useParams} from "react-router-dom";

const svg = {
    check: 'M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z',
}
const SVG = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={props.width ? props.width : 'auto'}
            height={props.height ? props.height : 'auto'}
            viewBox="0 0 24 24"
            fill={props.color}
        >
            <path
                d={svg[props.name]}
            />
        </svg>
    );
}

const CognitiveTestPrint = () => {

    const printRef = useRef();
    const headerRef = useRef();
    const bottomRef = useRef();
    
    const scoreTable = [
          {startAge:30, endAge: 30, startScore:10, endScore: 10},
          {startAge:40, endAge: 40, startScore:9, endScore: 9},
          {startAge:50, endAge: 50, startScore:8, endScore: 8},
          {startAge:60, endAge: 60, startScore:7, endScore: 7},
          {startAge:70, endAge: 70, startScore:6, endScore: 6},
          {startAge:80, endAge: 80, startScore:5, endScore: 5},
          
      ]
  
    const { logID } = useParams()
  
    const [result, setResult] = useState(null)
    const fetch = useCallback(async () => {
      setResult(await MeasureService.getMeasureLogByID(logID))
    }, [])

    useEffect(() => {
      fetch().then()
      scoreTable.reverse()
    }, [])

    useEffect(() => {
      window.addEventListener(
        "message",
        (e) => {
          if ('print-m1-trigger' === e.data.message) {
            const print = document.querySelector('.btn-print')
            if(print) {
              setTimeout(() => {
                print.click()
                window.parent.postMessage({ message: "print-m1-ready" }, '*');
              }, 1000)
            }
          }
        },
        false
      );
    }, []);
    
    if(!result) return <></>
    
    const { exerciseName , createdAt , resultVar, centerName, code } = result
    const viewMode = 'image'
    const colSize = resultVar.problems.q.length > 0 ? 6 : 12
    const boxSize =  15 > resultVar.problems.q.length ? 100 : 86
    
    return (
        <Box
          position={'relative'}
          className={clsx(['level-dialog'])}>
            <div>
                <div ref={headerRef} 
                     id="responsive-dialog-title" 
                     className="print"
                     style={{
                       padding: 0,
                       borderTop: 0,
                       borderWidth: 3
                     }}
                > 
                  <div className={'title-left'}
                    style={{
                      position: 'relative',
                      paddingTop:28,
                      paddingLeft: 100,
                      paddingBottom:22,
                      fontWeight: "bold",
                      fontSize: '1.5rem',
                      color: '#3F51B5'
                    }}     
                  >
                    <Box style={{left: 30, top: 10, position: 'absolute'}} >
                      <img src={require(`@assets/images/bh_logo.png`)} alt={'logo'} style={{height: 60}}/>
                    </Box>
                    {exerciseName} 검사
                    <Box style={{display: 'inline-block', paddingLeft: 30, fontSize: '1rem', color: '#333'}}>
                      {centerName}, {code}
                    </Box>
                  </div>
                  <Box
                       style={{right: 60, top: 14, color: '#000', fontWeight: "normal", fontSize: '1rem', position: 'absolute', padding: 10, paddingRight: 20, paddingLeft: 20, backgroundColor: '#EBF7FF', borderRadius: 4}}
                       className={'print-show'}>
                      <div style={{fontSize: '0.9rem'}}><span style={{ fontWeight: 'bold', color: '#2F4890' }}>검사일</span>: {DateHelper.format(createdAt,'YYYY.MM.DD')}</div>
                      <div style={{fontSize: '0.9rem'}}><span style={{ fontWeight: 'bold', color: '#2F4890' }}>출력일</span>: {DateHelper.now('YYYY.MM.DD')}</div>
                  </Box>
                </div>
                <div style={{display:'flex', justifyContent: 'center'}}>
                    <Grid container spacing={3} style={{maxWidth: '1182px'}} ref={printRef}>
                        <Grid item xs={6} md={colSize} >
                            <Box marginTop={0}>
                                <Typography className={'title'} >검사 결과</Typography>
                                <Grid container spacing={2} className={'result-wrap'} style={{fontWeight: "bold", minHeight: 0, paddingBottom: 0}}>
                                    <Grid item xs={4}>
                                        <Paper elevation={0} className={'result-box'}>
                                            <Typography className={'text-blue'}>검사시간</Typography>
                                            <Typography className={'score'}>{resultVar.time}</Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Paper elevation={0} className={'result-box'}>
                                            <Typography className={'text-blue'}>인지개수</Typography>
                                            <Typography className={'score'}>{resultVar.correctCount} / {resultVar.count}개</Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Paper elevation={0} className={'result-box'}>
                                            <Typography className={'text-blue'}>판별점수</Typography>
                                            <Typography className={'score'}>{resultVar.scoreText}</Typography>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mt={2}>
                                <Typography className={'title'} style={{fontWeight: "bold", minHeight: 0, paddingBottom: 0, marginTop: 30}}>평가 기준</Typography>
                            </Box>
                            <Box mt={2}>
                                <TableContainer>
                                    <Table aria-label="table" size={'small'} className={ 15 === resultVar.problems.q.length ? 'level-table-d' : 'level-table-c' }>
                                        <TableHead>
                                            <TableRow className={''}>
                                                <TableCell>연령 기준</TableCell>
                                                <TableCell>인지 개수</TableCell>
                                                <TableCell>판별 점수</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {10 === resultVar.count &&
                                            <>
                                            {scoreTable.map((table, index) => {
                                                const {startAge, startScore} = table
                                                let score = startScore * 10;
                                                score = score > 100 ? 100 : score
                                                return (
                                                    <TableRow key={`result-${index}`}>
                                                        <TableCell scope="row">{startAge}대 {0 === index  ? '이상' : ''}</TableCell>
                                                        <TableCell>{startScore}개 이상</TableCell>
                                                        <TableCell>{score}점 이상</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                            </>
                                          }
                                          {15 === resultVar.count &&
                                            <>
                                            <TableRow>
                                              <TableCell scope="row">80대 이상</TableCell>
                                              <TableCell>6 ~ 7개 이상</TableCell>
                                              <TableCell>60 ~ 70점 </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell scope="row">70대 이상</TableCell>
                                              <TableCell>7 ~ 8개 이상</TableCell>
                                              <TableCell>70 ~ 80점 </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell scope="row">60대 이상</TableCell>
                                              <TableCell>8 ~ 9개 이상</TableCell>
                                              <TableCell>80 ~ 90점 </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell scope="row">50대 이상</TableCell>
                                              <TableCell>9 ~ 10개 이상</TableCell>
                                              <TableCell>90 ~ 100점 </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell scope="row">40대 이상</TableCell>
                                              <TableCell>10 ~ 11개 이상</TableCell>
                                              <TableCell>100 ~ 110점 </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell scope="row">30대 이상</TableCell>
                                              <TableCell>11 ~ 12개 이상</TableCell>
                                              <TableCell>110 ~ 120점 </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell scope="row">20대 이상</TableCell>
                                              <TableCell>13 ~ 15개</TableCell>
                                              <TableCell>130 ~ 150점 </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell scope="row">20대 이하</TableCell>
                                              <TableCell>14 ~ 15개</TableCell>
                                              <TableCell>140 ~ 150점 </TableCell>
                                            </TableRow>
                                            </>
                                          }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Grid>
                        {0 < resultVar.problems.q.length &&
                        <Grid item md={6} xs={6}>
                            <Typography className={'title'}>평가 결과</Typography>
                            <Box
                                className={clsx({
                                    'flex': 15 === resultVar.problems.q.length,
                                    'inline': 15 > resultVar.problems.q.length
                                })}>
                                <Box className={clsx({
                                    'left-box-title': 15 === resultVar.problems.q.length,
                                    'left-box-title-land' : 15 > resultVar.problems.q.length
                                })}>정답보기</Box>
                                <Grid 
                                    container spacing={1}
                                    className={clsx({
                                        'result-each-box' : 15 > resultVar.problems.q.length
                                    })}
                                >
                                    {
                                        resultVar.problems.q.map((value, index) => {
                                            // const viewValue = 'image' === viewMode ? gameResults.memoryImages[index] : gameResults.memoryTexts[index]
                                            // const lineHeight = ('text' === viewMode && viewValue.length > 3) ? 43 : boxSize 
                                            const viewValue = require(`@assets/exercise/cognitive/c${value}.png`)
                                          
                                            return (
                                                <Grid item key={`grid-box-${index}`}>
                                                    <Box style={{
                                                        width: boxSize,
                                                        height: boxSize,
                                                        borderRadius: '10px',
                                                        border: 3,
                                                        borderStyle: 'solid',
                                                        borderColor: '#A7A7A7',
                                                        backgroundSize: 'contain',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'center',
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                        {('image' === viewMode) &&
                                                        <img src={viewValue} alt={`grid-box-${index}`}
                                                             style={{width: '100%', borderRadius: 10}}/>
                                                        }

                                                        {('text' === viewMode) &&
                                                        <Typography
                                                            variant={"h6"}
                                                            style={{
                                                                textAlign: 'center',
                                                                width: '100%',
                                                                fontSize: '1rem'
                                                                // lineHeight: `${lineHeight}px`
                                                            }}
                                                        >
                                                            {viewValue}
                                                        </Typography>
                                                        }

                                                    </Box>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Box>
                            <Box mt={5}
                                 className={clsx({
                                     'flex': 15 === resultVar.problems.q.length,
                                     'inline': 15 > resultVar.problems.q.length
                                 })}     
                            >
                                <Box className={clsx({
                                    'left-box-title': 15 === resultVar.problems.q.length,
                                    'left-box-title-land mt45' : 15 > resultVar.problems.q.length
                                })}>선택목록</Box>
                                <Grid container
                                      className={clsx({
                                          'result-each-box' : 15 > resultVar.problems.q.length
                                      })}
                                      spacing={1}>
                                    {
                                      resultVar.problems.a.map((answer, index) => {
                                            const {isCorrect, text} = answer
                                            const image =  require(`@assets/exercise/cognitive/c${answer.index}.png`)
                                            const grayscale = isCorrect ? '0%' : '100%'
                                            return (
                                                <Grid item key={`grid-box-${index}`}>
                                                    <Box style={{
                                                        position: 'relative',
                                                        width: boxSize,
                                                        height: boxSize,
                                                        borderRadius: '10px',
                                                        border: 3,
                                                        borderStyle: 'solid',
                                                        borderColor: '#A7A7A7',
                                                        backgroundSize: 'contain',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'center',
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                        {isCorrect &&
                                                        <Box position={'absolute'} zIndex={10} left={5} top={5}>
                                                            <SVG name={'check'} width={'24px'} height={'24px'}
                                                                 color={'#04CF5C'}/>
                                                        </Box>
                                                        }

                                                        {('image' === viewMode) &&
                                                        <img src={image} alt={`grid-box-${index}`} style={{
                                                            width: '100%',
                                                            filter: `grayscale(${grayscale})`,
                                                            borderRadius: 10
                                                        }}/>
                                                        }

                                                        {('text' === viewMode) &&
                                                        <Typography
                                                            variant={"h6"}
                                                            style={{
                                                                textAlign: 'center',
                                                                fontSize:'1rem',
                                                                width: '100%'
                                                            }}
                                                        >
                                                            {text}
                                                        </Typography>
                                                        }

                                                    </Box>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Box>
                        </Grid>
                        }
                    </Grid>
                </div>
                <Box ref={bottomRef} mt={3} mb={3}  style={{fontSize: '1.1rem', minHeight:44, lineHeight: '44px', fontWeight: 'normal', textAlign: 'center', borderTop: "3px solid #3F51B5"}} >
                  보건복지부 144호 한국뇌건강증진운동연합회 https://kbhd.org
                </Box>
            </div>
          <Button
            className={'btn-print'}
            startIcon={<PrintIcon/>}
            style={{padding: '7.5px 21px', marginRight: 10}}
            variant={'outlined'} size={'large'}
            onClick={() => {
              PrintHelper.print(printRef, headerRef, bottomRef)
            }}
          >
            인쇄
          </Button>
        </Box>
    )

}

export default CognitiveTestPrint;
