import React, {useEffect} from 'react';
import {Box, Grid} from '@material-ui/core';
import ResultBarPie from "./ResultBarPie";

const ResultStress = (props) => {
    const { measureData , showType, fontSize, titleFontSize } = props
    
    useEffect(()=>{
    },[])
    
    return (
        <Box>
            <Box>
                <Grid container spacing={3}>
                    {(!showType || 'emotion' === showType) &&
                    <>    
                        <Grid item xs={12}>
                            <ResultBarPie 
                                title={"정신적 스트레스 분석"} 
                                data={measureData[0].map((value) => parseInt(value * 10) )}
                                barChartColor={['#60C3F7','#F8807A']}
                                pieChartColor={['#60C3F7','#F8807A']}
                                suggestedMax={100}
                                fontSize={fontSize}
                                titleFontSize={titleFontSize}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ResultBarPie 
                                title={"정신적 저항력 분석"} 
                                data={measureData[1].map((value) => parseInt(value * 10) )} 
                                barChartColor={['#BEF3B0', '#FF00A5']}
                                pieChartColor={['#BEF3B0', '#FF00A5']}
                                suggestedMax={100}
                                fontSize={fontSize}
                                titleFontSize={titleFontSize}
                            />
                        </Grid>
                    </>
                    }
                    
                    {(!showType || 'health' === showType) &&
                    <>
                        <Grid item xs={12}>
                        <ResultBarPie 
                            title={"육체적 스트레스 분석"} 
                            data={measureData[2].map((value) => parseInt(value * 10) )}
                            barChartColor={['#60C3F7','#F8807A']}
                            pieChartColor={['#60C3F7','#F8807A']}
                            suggestedMax={100}
                            fontSize={fontSize}
                            titleFontSize={titleFontSize}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ResultBarPie 
                            title={"육체적 저항력 분석"} 
                            data={measureData[3].map((value) => parseInt(value * 10) )}
                            barChartColor={['#BEF3B0', '#FF00A5']}
                            pieChartColor={['#BEF3B0', '#FF00A5']}
                            suggestedMax={100}
                            fontSize={fontSize}
                            titleFontSize={titleFontSize}
                        />
                    </Grid>
                    </>
                    }
                </Grid>
            </Box>
        </Box>
    );
}





export default ResultStress
