
//자각증상20 시작
import React from "react";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Box, Button, Grid} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ReactAudioPlayer from "react-audio-player";
import DialogActions from "@material-ui/core/DialogActions";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useCookies} from "react-cookie";

export function TrainDialog(props){

    const [open, setOpen] = React.useState(true);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [cookie, setCookie] = useCookies()
    const [useAudio , setAudio] = React.useState(!('yes' === cookie._sana))
    // 'yes' === _sana 오디오를 설명을 사용하지 않습니다. 
    const handleClose = () => {
        setOpen(false);
        if(props.onStart){
          props.onStart()  
        }

    };

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle className="common-dialog-title">문항 선행학습</DialogTitle>
                <DialogContent className={'common-dialog-content'} >
                    화면을 보면서 소리내어 발음하고 <br/>
                     클릭하면서 시작해 보세요 
                    <br/>

                    {/*<ReactAudioPlayer*/}
                    {/*    autoPlay={useAudio}*/}
                    {/*    src={require(`@audio/emotion-slow.mp3`)}*/}
                    {/*    controls*/}
                    {/*    onEnded={()=>{*/}
                    {/*        setTimeout(() => {*/}
                    {/*            setOpen(false)    */}
                    {/*        }, 1000)*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <br/>

                </DialogContent>
                <DialogActions className={'common-dialog-action'}>
                  <Button
                    onClick={handleClose}
                    fullWidth
                    endIcon={<ArrowForwardIosIcon />}
                  >
                    시작하기
                  </Button>    
                </DialogActions>
            </Dialog>
        </div>
    )
}
