import React, {useState, useEffect, useCallback} from 'react'

import {makeStyles} from '@material-ui/core/styles'
import {Box, Grid, Typography} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import CustomerMeasureDialog from "./CustomerMeasureDialog";
import CustomerSettingDialog from "./CustomerSettingDialog";

import SocketProvider from '@user-provider/SocketProvider';
import CustomerService from "@user-service/manage/CustomerService";
import AuthProvider from '@user-provider/AuthProvider'
import {useCookies} from "react-cookie";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import DateHelper from '@helper/DateHelper'
import clsx from "clsx";

const CustomerList = ({history, searchKeyword}) => {
    const [customers, setCustomers] = useState()
    const [settingProfile] = useState({
        id: null,
        memberID: null,
        name: null
    })
    const [currentProfile, setCurrentProfile] = useState({
        id: null,
        name: null,
        memberID: null,
        loginDate: null
    })
    const [,setCookie] = useCookies([AuthProvider.userCookieName])
    const classes = useStyles()

    const handleCustomerView = async (memberID, profileID) => {
        
        const { success } = await CustomerService.setCustomerView(memberID, profileID)

        if(success){
            setCookie(AuthProvider.userCookieName, AuthProvider.getUserData(), AuthProvider.getCookieOption())
            SocketProvider.disconnect()
            
            history.push('/dashboard')
        }
    } 
    
    const fetchCustomer = useCallback(async () => {
        const customers = await CustomerService.getCustomersGroup(searchKeyword)
        setCustomers(customers.group)
    }, [searchKeyword])

    useEffect(()=>{
        fetchCustomer().then()
        
        return (() => setCustomers(null))
    },[fetchCustomer])


    if(!customers){
        return(<LoadingSkeleton/>)
    }
    
    const customersKey = Object.keys(customers);
    return (
        <Box className={classes.root}>

            <CustomerMeasureDialog 
                open={null != currentProfile.id}
                profileID={currentProfile.id}
                memberID={currentProfile.memberID}
                profileName={currentProfile.name}
                lastLoginDate={currentProfile.loginDate}
                onClose={(res) => {
                    setCurrentProfile({id:null, name:null, loginDate: null, memberID: null})
                    if(res && res.reload){
                        fetchCustomer().then()
                    }
                }}
            />

            <CustomerSettingDialog
                open={null != settingProfile.id}
                memberID={settingProfile.memberID}
                profileID={settingProfile.id}
                profileName={settingProfile.name}
                onClose={() => {setCurrentProfile({id:null, name:null, loginDate: null, memberID: null})}}/>

            {0 === customersKey.length && 
                <List style={{color: 'white', marginTop: 25}}>
                    <ListItem>
                        <ListItemText primary={
                            <Box style={{paddingTop: 30, textAlign: 'center', color: '#999'}}>
                                <Typography>등록된 사용자가 없습니다</Typography>
                            </Box>
                        } />
                    </ListItem>
                </List>
            }
            
            {
                customersKey.map((key) => {
                    const { memberName, profiles, loginID } = customers[key]
                    return(
                        <List key={key} style={{color: 'white', marginTop: 25}}>
                            <Typography variant={'h5'} className={clsx('text-white')}>
                                {memberName}
                            </Typography>
                          
                            
                            <div className={classes.small}>{loginID}</div>
                            {profiles.map((profile, index) => {
                                const { name, code, lastLoginDate } = profile
                                return (
                                    <React.Fragment key={index}>
                                        <ListItem 
                                            button
                                            onClick={async () => {
                                                setCurrentProfile({
                                                    id: profile.profileID,
                                                    memberID: profile.memberID,
                                                    name: profile.code,
                                                    loginDate: DateHelper.format(lastLoginDate, 'YYYY.MM.DD')
                                                })
                                                
                                            }}>
                                            <ListItemAvatar>
                                                <Avatar variant="rounded" className={classes.large}>
                                                    {profile.image
                                                        ? <img src={require(`@images/profile/${profile.image}`)} alt={name}/>
                                                        : <ImageIcon/>
                                                    }
                                                </Avatar>
                                            </ListItemAvatar>

                                            <ListItemText primary={
                                                <Box style={{paddingLeft: 10}}>
                                                    {code}
                                                </Box>
                                            } secondary={
                                                <span style={{paddingLeft: 10, color: '#999'}}> 접속일: {DateHelper.format(lastLoginDate, 'YYYY.MM.DD')}</span>
                                            } />

                                            <ListItemSecondaryAction>
                                                <Grid container spacing={2}>
                                                    <Grid item>
                                                        <Button
                                                            onClick={async () => {
                                                                await handleCustomerView(profile.memberID, profile.profileID)
                                                            }}
                                                            size={'large'}
                                                            className={classes.backGray}>
                                                            로그인
                                                        </Button>
                                                    </Grid>
                                                    {/*<Grid item>*/}
                                                    {/*   <IconButton*/}
                                                    {/*       onClick={() => {*/}
                                                    {/*           setSettingProfile({*/}
                                                    {/*               id: profile.profileID,*/}
                                                    {/*               memberID: profile.memberID,*/}
                                                    {/*               name: profile.code*/}
                                                    {/*           })*/}
                                                    {/*       }}*/}
                                                    {/*   >*/}
                                                    {/*       <SettingsIcon className={classes.backGray}/>*/}
                                                    {/*   </IconButton>*/}
                                                    {/*</Grid>*/}
                                                </Grid>

                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                )
                            })}
                        </List>
                    )
                })
            }
            
          
        </Box>
    )
}


//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <div className="editprofile_wrap wrapper">
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </div>
        </Box>
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center",
        maxWidth: 1280,
        height: '100vh',
        paddingTop: 30,
        margin: 'auto'
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
        backgroundColor: '#1E2030',
    },
    title: {
        color: 'white',
        textAlign: 'center',
        marginBottom: 100,
        fontSize: 42
    },
    small: {
        fontSize: '0.8rem',
        color: '#999',
    },
    logo: {
        position: 'fixed',
        left: 30 ,
        top: 22,
        zIndex: 0,
    },
    add: {
        position: 'fixed',
        right: 30 ,
        top:22,
        zIndex: 0,
    },
    profileWrap: {
        width: '100%'
    },
    back: {
        color: 'white',
        '&:hover': {
            backgroundColor: '#12131D',
        }
    },
    backGray: {
        color: '#999',
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),

        backgroundColor: 'transparent',
        '& img': {
            width: '100%'
        }
    },
}));


export default CustomerList
