import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import React, {useCallback, useEffect, useState} from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DialpadIcon from "@material-ui/icons/Dialpad";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {TextField, Typography} from "@material-ui/core";
import MeasureService from "../../../services/MeasureService";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";

let timer
const MeasureCount = ({ profileID, centerID, onlyCenter = false}) => {
    const [measureTypesCountAll, setMeasureTypesCountAll] = useState(null)
    const [defMeasureCountAll, setDefMeasureCountAll] = useState(null)
    const classes = useStyles();

    // showWithRemain == 사용자 페이지의 
    const fetch = useCallback(async (profileID) => {
        const measureTypesCountAll = await MeasureService.getMeasureCount({profileID, centerID, measureGroup:'all'})
        
        setMeasureTypesCountAll(measureTypesCountAll)
        setDefMeasureCountAll(measureTypesCountAll)
        
    }, [centerID])

    const updateMeasureCount = (type, allKey, index, value) => {
  
        const measureTypesCount = measureTypesCountAll[allKey]
        const measureType = measureTypesCount[index]
        const update = measureTypesCount.find(m => m.key === measureType.key)
        
        const defVal = defMeasureCountAll[allKey][index]
        
        let newCount = parseInt(value)
        if (isNaN(newCount)) newCount = 0

   
        //기존 남아있던 양 체크 
        if('customer' === type){
            const oriCount = defVal.count
            const appendCount = newCount - oriCount;

            if( 0 > measureType.centerCount - appendCount){
                alert('사용 가능한 수량을 확인 하세요')
                return
            }
            
            update.centerCount += appendCount * -1
            // console.log('ori:' , oriCount, ' append :', appendCount, ' calc:', measureType.centerCount - appendCount )
        } 
        
        if (timer) clearTimeout(timer)
        timer = setTimeout(async () => {
            await MeasureService.updateMeasureTypeCount({
                profileID: onlyCenter ? null : profileID,
                centerID,
                measureType: measureType.key,
                count: newCount,
            })
    
            // 사용자 카운트가 변경되면 센터용 차감 
            if('customer' === type){
                await MeasureService.updateMeasureTypeCount({
                    profileID: null,
                    centerID: update.centerID,
                    measureType: measureType.key,
                    count: update.centerCount,
                })
            }
        }, 500)
        
        'customer' === type ? update.count = newCount : update.centerCount = newCount
        measureTypesCount[index] = update

        measureTypesCountAll[allKey] = measureTypesCount
        setMeasureTypesCountAll({
            ...measureTypesCountAll
        })


    }
    useEffect(() => {
        if(!profileID && !centerID) return 
        
        fetch(profileID).then()
    }, [ fetch, profileID, centerID])
    
    return (
        <Box>
        {(Object.keys(measureTypesCountAll || [])).map((allKey) => {
            const measureTypesCount = measureTypesCountAll[allKey]
            return (
                <List
                    key={allKey}
                    style={{marginTop: 30}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            측정수 설정 - {allKey}
                        </ListSubheader>
                    }
                >
                    {(measureTypesCount || []).map((measureType,index) => {
                        return (
                            <React.Fragment key={measureType.key}>
                                <ListItem className={'item'}>
                                    <ListItemIcon>
                                        <DialpadIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <div>
                                            {measureType.label}
                                            <div className={classes.small}>사용가능한 측정 수 입니다.</div>
                                        </div>
                                    }/>
                                    <ListItemSecondaryAction>
                                        <React.Fragment>
                                            {!onlyCenter &&
                                                <React.Fragment>
                                                    <TextField
                                                        value={measureType.count}
                                                        variant="filled"
                                                        inputProps={{
                                                            min: 0,
                                                            style: {textAlign: 'right', paddingRight: 10, width: 50}
                                                        }}
                                                        InputProps={{disableUnderline: true}}
                                                        onChange={(event) => {
                                                            updateMeasureCount('customer', allKey, index, event.target.value)
                                                            // updateMeasureCount('center', allKey, index, event.target.value)
                                                            // console.log(event.target.value)
                                                        }}/>
                                                    <Typography className={classes.split}>/</Typography>    
                                                </React.Fragment>
                                            }
                                            
                                            <TextField
                                                value={measureType.centerCount}
                                                disabled={!onlyCenter}
                                              
                                                inputProps={{
                                                    min: 0,
                                                    style: {textAlign: 'right', paddingRight: 10, width: 40},
                                                }}
                                                onChange={(event) => {
                                                    updateMeasureCount('center', allKey, index, event.target.value)
                                                }}
                                            />
                                        </React.Fragment>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider/>
                            </React.Fragment>
                        )
                    })}
                </List>
            )
        })}
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    small: {
        fontSize: 14,
        color: '#999',
    },
    split: {
        display: 'inline-block', 
        marginTop: 5, 
        fontSize: '1.5rem' , 
        color: '#999', 
        marginLeft: 10
    }
}));

export default MeasureCount