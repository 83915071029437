import React from 'react';
import {
    Box,
    Grid,
    Button, Select,
} from '@material-ui/core';

import MeasureHelper from '@helper/MeasureHelper'
import MenuItem from "@material-ui/core/MenuItem";
import {withStyles} from "@material-ui/core/styles";
const { measureTypeToCode, getCodeNameSet } = MeasureHelper

class ComputeCode extends React.Component {
    constructor(props) {
        super()
        
        this.state = {
            selectedIndex: 0,
            hzType: props.hzType,
            code : props.defaultCode ? props.defaultCode : 'C',
            measureType: props.measureType ? props.measureType : 'rest',
            measureTypeCode: measureTypeToCode(props.measureType),
            onChange: props.onChange,
            useMeasureCode: props.useMeasureCode,
            viewType: props.viewType ? props.viewType : 'button' 
        }
    }
    
    getCode() {
        return this.state.code
    }

    setMeasureSetting(measureType, measureCode){
        this.setState({
            ...this.state,
            measureType,
            measureTypeCode: measureTypeToCode(measureType),
            code: measureCode
        })
    }
    
    setMeasureType(measureType){
        this.setState({
            ...this.state,
            measureType,
            measureTypeCode: measureTypeToCode(measureType),
        })
    }
    
    setCodeByIndex(codeNames, index) {
        const code = codeNames[index]
        this.setCode(code)
    }
    
    setCode(code){
        this.setState({
            code: code.value
        }, () => {
            if(this.state.onChange){
                this.state.onChange(code.value)
            }
        })
    }
    
    render() {
        const codeNames = getCodeNameSet(this.state.hzType, this.state.measureTypeCode, this.state.useMeasureCode)
        const { classes } = this.props;
        
        if('select' === this.state.viewType){
            if(!codeNames || 0 === codeNames.length){
                return <></>
            }
            
            return (
                <Select
                    className={classes.select}
                    defaultValue={this.state.code}
                    value={this.state.code}
                    onChange={(event)=>{
                        codeNames.map((code, index) => {
                            if(code.value === event.target.value){
                                this.setCode(code)    
                            }
                            return code
                        })
                    }}>
                    {codeNames.map((code, index) => {
                        return (
                            <MenuItem
                                key={code.value}
                                value={code.value}
                            >
                                {code.value}
                            </MenuItem>
                        )
                    })}
                </Select>
            )
        } else {
            return (
                <Grid container spacing={1} >
                    {codeNames.map((code, index) => {
                        return (
                            <Grid item key={index}>
                                <Button variant={"contained"}
                                        color={code.value === this.state.code ? "primary" : "default"}
                                        onClick={() => {
                                            this.setCodeByIndex(codeNames, index)
                                        }}>
                                    {code.name}
                                </Button>
                            </Grid>
                        )
                    })}
                </Grid>
            )
        }
    }
}


const useStyles = theme => ({

    select: {
        color: '#FFC004',
        fontSize: 24,
        fontWeight: 'bold',

        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
        '& .MuiSelect-icon': {
            color: '#FFC004',
        }
    },
});
export default withStyles(useStyles)(ComputeCode)
