import React from 'react';
import { GridGenerator, HexGrid, Layout, Hexagon, Text } from 'react-hexgrid';
import CommonHelper from "@helper/CommonHelper";
import '@user-style/hexgrid.css';
import ColorHelper from '@helper/ColorHelper'
import MeasureHelper from '@helper/MeasureHelper'
import {Box} from "@material-ui/core";

export default class BigBrainHexMap extends React.Component {

    maxHex = 216
    blankHexCoord = [0,216,5,6,
        7,8,17,18,28,29,40,41,53,54,67,68,82,83,98,99,115,116,
        16,27,39,52,66,81,147,
        38,51,161,65,
        55,69,84,100,101,117,133,148,162,175,187,188,198,199,200,211,208,209,210
        ]


    constructor(props) {
        super(props);

        const hex = []
        for (var i = 0 ; i < this.maxHex; i++){
            if(-1 === this.blankHexCoord.indexOf(i)){
                hex.push(i)
            } 
        }

        const colors = ColorHelper.getMeasureColors('rest', this.maxHex + 1)

        this.state = {
            measureCoord: new Array(hex.length).fill({measureType:'', value:0}),
            hexas: GridGenerator.hexagon(8),
            ableHex: hex,
            defaultHex: CommonHelper.clone(hex),
            timeCount: 0,
            measureType : props.measureType,
            width: props.width ? props.width : 300,
            height: props.width ? props.width : 300,
            currentColor: colors[0]
        }

        // for(let i = 0 ; i < colors.length + 1; i++){
            // console.log('.hex-wrap svg g.'+measureType + i + ' g { fill: ' + colors[i] + '}')
        // }
    }

    reset(measureType) {
        const colors = ColorHelper.getMeasureColors(measureType, this.maxHex + 1)
        
        this.setState({
            ...this.state,
            measureCoord: [],
            timeCount: 0,
            measureType,
            ableHex: CommonHelper.clone(this.state.defaultHex),
            currentColor: colors[0]
        })
    }
    
    //기기에서 측정된 데이터를 받을 경우
    addMeasureData = (currentStep, time, isHit, hitMv = '') => {
        let coord 
        const newMeasureCoord = CommonHelper.clone(this.state.measureCoord)
        const stepCount = 0 === time % 8 ? 1 : 3 
        
        for(let i = 0 ; i < stepCount ; i++){
            coord = this.state.ableHex.shift()
            if(null == coord) continue
            
            newMeasureCoord[coord] = { 
                isHit : isHit ? 'yes' : 'no' 
            }
        }
        
        this.setState({
            ...this.state,
            measureCoord: newMeasureCoord,
            timeCount: time,
        })
    }

    /**
     * 이전 측정된 데이터를 화면에 표시합니다.
     * @param data
     * @param measureType
     * @param needUpdate
     */
    setHexMap(data, measureType , needUpdate = false) {
        if(!data) return;
        
        data.split(',').map((value, index) => {
            this.state.measureCoord[index] = {
                measureType: '1' === value ? 'fill ' + measureType : measureType
            }    
        })

        if(needUpdate){
           this.needUpdate();
        }
    }


    /**
     * 화면을 업데이트 합니다.
     */
    needUpdate() {
        this.setState({
            ...this.state
        });
    }

    setAvr = (type, avr) =>{
        if('rest' === type){
            this.setState({
                restAvg: avr
            })
        } else if('attemption' === type){
            this.setState({
                attemAvg: avr
            })
        } else if('concentration' === type){
            this.setState({
                constAvg: avr
            })
        } else if('concentration' === type){
            this.setState({
                brainactiveAvg: avr
            })
        }
    }

    setAvgAll = (avg) =>{
        this.setState({
            restAvg: avg
        });

    }

    render() {
        const { height, measureType, currentColor } = this.state
        let  val, className 
        
        return (
            <React.Fragment>
                <HexGrid height={height} viewBox="-50 -50 100 100" >
                    <Layout size={ { x: 3, y: 3 } } origin={ { x: 0, y: 0 } } flat={ false } spacing={ 1.1 }>
                    { this.state.hexas.map((hex, i) => {
                        className = ""
                        val = this.state.measureCoord[i]
    
                        if(-1 < this.blankHexCoord.indexOf(i)) {
                            className = "blank"
                        } else {
                            className = `${measureType}${i} no-stroke`
                            
                            if(val && 'yes' === val.isHit){
                                className += ' active'
                            } else if (val && 'no' === val.isHit) {
                                className += ' progress'
                            }
                            
                        }
                            
                        return <Hexagon key={i} q={hex.q} r={hex.r} s={hex.s} className={className} />
                        })
                    }
                    </Layout>
                </HexGrid>
                <Box m={'auto'} 
                     style={{
                         marginTop: -100, 
                         fontStyle: 30, 
                         backgroundColor: currentColor, 
                         borderRadius: 30, 
                         width: 100, 
                         textAlign: 'center', 
                         padding: 5, 
                         color:  '#fff' ,
                         fontWeight: 'bold'
                     }}>
                    {MeasureHelper.measureTypeToName(measureType)}
                </Box>
            </React.Fragment>
        )
    }
}
