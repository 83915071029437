import React, {useCallback, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HeatMap from 'react-heatmap-grid';
import ColorHelper from '@helper/ColorHelper';
import PrescriptionService from '@user-service/PrescriptionService'
import clsx from "clsx";

const DashPattern = (props) => {
    const classes = useStyles();
    const { prescription } = props
    
    const [mapValue , setMapValue] = useState([
        [0,0,0,0],
        [0,0,0,0],
        [0,0,0,0],
        [0,0,0,0],
    ])
    
    const fetch = useCallback(async () => {
        const data = await PrescriptionService.getBrainActive(prescription.id)
        setMapValue(data['MA'].active)
    }, [])
    
    useEffect(() => {
        fetch().then()
    }, [])
    
    return (
        <React.Fragment>
            <h4 className={classes.h4}>뇌기능 의식패턴</h4>
            <div className={clsx('pattern', classes.wrap)}>
                <HeatMap
                    xLabels={[4,3,2,1]}
                    yLabels={[4,3,2,1]}
                    xLabelsLocation={"top"}
                    yLabelTextAlign={'center'}
                    data={mapValue}
                    cellStyle={(background, value, min, max, data, x, y) => {
                        let borderTopLeftRadius = 0
                        let borderTopRightRadius = 0
                        let borderBottomLeftRadius = 0
                        let borderBottomRightRadius = 0

                        if( 0 === x  && 0 === y)  borderTopLeftRadius = 30
                        if( 3 === x  && 0 === y)  borderTopRightRadius = 30
                        if( 0 === x  && 3 === y)  borderBottomLeftRadius = 30
                        if( 3 === x  && 3 === y)  borderBottomRightRadius = 30

                        return {
                            backgroundColor: ColorHelper.getColorPercent('blue', max, value),
                            color: '#333',
                            fontSize: 20,
                            height: 100,
                            fontWeight: 'bold',
                            lineHeight: '80px',
                            borderTopLeftRadius,
                            borderTopRightRadius,
                            borderBottomLeftRadius,
                            borderBottomRightRadius
                        }
                    }}
                    cellRender={ (value, x, y) => {
                        return value
                    }}
                    title={(value, unit) => value}
                />
            </div>
        </React.Fragment>
    )
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#F5F9FE',
        borderRadius: 30,
        minHeight: 357,
    },
    wrap: {
        padding: '0 20px',
        marginLeft: '-20px',
        marginTop: '-10px'
    },
    
    blue: {
        backgroundColor: '#C5EBFD',
        color: '#00B2F5'
    },
    green: {
        backgroundColor: '#D7EBE0',
        color: '#53B268'
    },
    red: {
        backgroundColor: '#F8EBF0',
        color: '#FF706A'
    },
    purple: {
        backgroundColor: '#ECE0F5',
        color: '#C774D2'
    }
}));

export default DashPattern;
