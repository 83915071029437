import React, {useState, useEffect, useRef } from 'react';
import { Grid, Box} from '@material-ui/core';
import { useParams } from "react-router-dom";

import SocketProvider from '@user-provider/SocketProvider';
import PrescriptionService from '@user-service/PrescriptionService'
import StartButton from '@user-pages/exercise/common/StartButton'
import AudioPlayer from '@user-pages/exercise/common/AudioPlayer'

import RealtimeMeasureMerged from "./RealtimeMeasureMerged";
import StartDialog from "./StartDailog";
import EndDialog from "./EndDialog";
import MeasureHelper from '@helper/MeasureHelper'
import CustomerRequestDialog from "@user-components/CustomerRequestDialog"
const { measureTypeToCode } = MeasureHelper

const MeasureHzType = 'eeg'
const queryString = require('query-string');

const MeasureBand = ({history}) => {
    const qs = queryString.parse(window.location.search)
    
    const [showStartDialog, setShowStartDialog] = useState(false)
    const [showEndDialog, setEndDialog] = useState(false)
    
    const audioRef = useRef()
    const { prescriptionID } = useParams()
    
    const [noiseCode] = useState(qs.noiseCode ? qs.noiseCode : 'A')
    const [isRequest] = useState(undefined !== qs.rq)
    const [requestID] = useState(qs.rqs)
    
    useEffect(()=>{
        SocketProvider.connect(async ()=>{}, window, MeasureHzType)
          if(!isRequest && !showStartDialog){
              setShowStartDialog(true)    
          }
        return () => {
            // SocketProvider.measureStop(true).then()
        }
    },[])


    const measureStart = async () => {
        audioRef.current.loadEffect('eeg')
        setShowStartDialog(false)

        if(isRequest) return false
        
        await PrescriptionService.updateStatus(prescriptionID, 'eeg', '40', 'C','measuring')
        await SocketProvider.measureStart(
            process.env.REACT_APP_MEASURE_BAND_TIME,
            prescriptionID,
            MeasureHzType,
            'eeg',
            measureTypeToCode('eeg'),
            'C',
            null,
            null,
            1,
            noiseCode
        )
        
    }
    
    const onCloseEyes = () => {
        audioRef.current.effect('closeEyes')
    }
    
    const onOpenEyes = () => {
        audioRef.current.effect('openEyes')
    }
    
    return (
        <Box >
            <Box
                display="flex"
                justifyContent="flex-end"
                position="absolute"
                right={10}
                top={20}
                zIndex={99}>
                
                <Grid container justify="center" spacing={1}>
                    <Grid item>
                        <StartButton 
                            onStop={async () => {
                                // await SocketProvider.measureStop(false)
                              document.location.href = `/measureSelect`
                            }}/>
                    </Grid>
                </Grid>
            </Box>
            
            <Box className={'innerViewport'}>
                <RealtimeMeasureMerged 
                    noiseCode={noiseCode}
                    prescriptionID={prescriptionID} 
                    requestID={requestID}
                    setEndDialog={setEndDialog.bind(this)}
                    onCloseEyes={onCloseEyes.bind(this)}
                    onOpenEyes={onOpenEyes.bind(this)}/>
                
                <StartDialog 
                    onHide={measureStart.bind(this)} 
                    open={showStartDialog} 
                    history={history}
                    disableButton={!isRequest} 
                /> 
                <EndDialog 
                    open={showEndDialog} 
                    history={history}
                    requestID={requestID}
                    url={'N' === noiseCode
                        ? `/setting`
                        : `/measureDetail/${prescriptionID}/fft`
                    }/>
            </Box>
            {isRequest &&
                <CustomerRequestDialog
                    handleStart={() => {
                        setShowStartDialog(true)
                    }
                }/>
            }
            <AudioPlayer ref={audioRef}/>
        </Box>
    );
}

export default MeasureBand
