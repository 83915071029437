import {withStyles} from "@material-ui/core/styles";
import {TextField} from "@material-ui/core";


 const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#999',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#333',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ddd',
            },
        },

    },
})(TextField);
 export default CssTextField