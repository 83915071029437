import React, {useState, useEffect, useCallback} from 'react'

import {makeStyles} from '@material-ui/core/styles'
import {Box, Grid} from '@material-ui/core'
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";


import LoadingIndicator from "../../../components/LoadingIndicator";
import CenterService from "../../../services/manage/CenterService";
import Button from "@material-ui/core/Button";
import SchoolIcon from '@material-ui/icons/School';
import BusinessIcon from '@material-ui/icons/Business';
import CenterDialog from "./CenterDialog";
import MemberService from "../../../services/manage/MemberService";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import DateHelper from "../../../helper/DateHelper";
import EducatorDialog from "./EducatorDialog";

const AdminList = ({searchKeyword}) => {
    const [currentMember, setCurrentMember] = useState(null)
    const [members, setMembers] = useState([])
    const classes = useStyles()
    
    const fetchMember = useCallback(async () => {
        const members = await MemberService.getAdminMember(searchKeyword)
        setMembers(members)
        
    }, [searchKeyword])

    useEffect(()=>{
        fetchMember().then()
        
        return (() => setMembers(null))
    },[fetchMember])


    if(!members){
        return(<LoadingIndicator/>)
    }
    return (
        <Box className={classes.root}>
            <EducatorDialog
                staffMember={currentMember}
                open={!!currentMember}
                onClose={() => {
                    setCurrentMember(null)
                    fetchMember().then()
                }}    
            />
            <List style={{color: 'white', marginTop: 25}}>
                {
                    members.map((member, index) => {
                        const { name, profileImage, centerName, createDate, customerCount, loginID } = member
                        return(
                            <React.Fragment key={index}>
                                <ListItem
                                    button
                                    onClick={async () => {
                                        setCurrentMember(members[index])
                                    }}>

                                    <ListItemAvatar>
                                        <Avatar variant="rounded" className={classes.large}>
                                            {profileImage
                                                ? <img src={require(`@images/profile/${profileImage}`)} alt={name}/>
                                                : <ImageIcon/>
                                            }
                                        </Avatar>
                                    </ListItemAvatar>
                                    
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Box style={{paddingLeft: 10}}>
                                                {name} - {loginID}
                                            </Box>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <span style={{paddingLeft: 10, color: '#999', fontWeight: 'bold'}}> 소속: {centerName ? centerName : '브레인헬스'} </span>
                                            <span style={{paddingLeft: 10, color: '#999'}}> 가입일: {DateHelper.format(createDate, 'YYYY.MM.DD')} </span>
                                        </React.Fragment>    
                                        
                                    } />

                                    <ListItemSecondaryAction>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Button
                                                    size={'large'}
                                                    className={classes.backGray}>
                                                    회원수 :{customerCount} 명
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        )
                    })
                }
            </List>
        </Box>
    )
}



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center",
        maxWidth: 1280,
        height: '100vh',
        paddingTop: 30,
        margin: 'auto'
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
        backgroundColor: '#1E2030',
    },
    title: {
        color: 'white',
        textAlign: 'center',
        marginBottom: 100,
        fontSize: 42
    },
    small: {
        fontSize: '0.8rem',
        color: '#999',
    },
    logo: {
        position: 'fixed',
        left: 30 ,
        top: 22,
        zIndex: 0,
    },
    add: {
        position: 'fixed',
        right: 30 ,
        top:22,
        zIndex: 0,
    },
    profileWrap: {
        width: '100%'
    },
    back: {
        color: 'white',
        '&:hover': {
            backgroundColor: '#12131D',
        }
    },
    backGray: {
        color: '#999',
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),

        backgroundColor: 'transparent',
        '& img': {
            width: '100%'
        }
    },
}));


export default AdminList
