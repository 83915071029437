
import React from 'react';
import {Chart, Bar, HorizontalBar} from 'react-chartjs-2';

export default class BarChart extends React.Component { 
    
    chartData
    chartOption
    chartRef
    typeFlag

    constructor(props) {
        super(props)

        const title = props.title ? props.title : ''
        const height = props.height ? props.height : 130
        const width = props.width ? props.width : null
        const colors = props.colors ? props.colors : [] 
        const suggestedMin = props.suggestedMin ? props.suggestedMin : 0
        const suggestedMax = props.suggestedMax ? props.suggestedMax : this.getSuggestedMax(props)
        const type = props.type ? props.type : 'bar'
        const useColorMap = props.useColorMap ? props.useColorMap : null
        const spacing = props.spacing ? props.spacing : 0.93
        const valueType = props.valueType ? props.valueType : 'float'
        
        let onComplete = null;
        if(props.showTopValue){ 
            
            onComplete = function () {
                const fontSize = props.topValueFontSize ? props.topValueFontSize : Chart.defaults.global.defaultFontSize
                const topFontSize = 14 < fontSize ? fontSize * 0.8 : fontSize
                //바차트 상단에 표시될 상단 마진값 
                const topMargin = 10
                
                let chartInstance = this.chart,
                    ctx = chartInstance.ctx;
                    // ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';
                    
                this.data.datasets.forEach(function (dataset, i) {
                    let meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {

                        let data = dataset.data[index]; 
                        const title = i % 2 === 0  ? '좌' : '우';
                        if('int' === valueType) {
                            try {
                                data = Math.floor(data)
                            } catch {}
                        }

                        ctx.font = Chart.helpers.fontString(fontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                        ctx.fillText(data, bar._model.x, bar._model.y - topMargin);
                        
                        if(props.showTopValueTitle){
                            // 추가 폰트일 경우 설정된 폰트 사이즈보다 작게 처리합니다.
                            ctx.font = Chart.helpers.fontString(topFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                            ctx.fillText(title, bar._model.x, bar._model.y - 5 - props.topValueMargin - topMargin);
                        }
                        
                    });
                });
            }
        }

        this.chartData = this.getChartDateSets(props)
        this.typeFlag = props.typeFlag
        this.state = {
            time: 0,
            title: title,
            height: height,
            width: width,
            colors,
            type,
            animationDuration: props.animationDuration,
            fontSize: props.fontSize ? props.fontSize : 14,
            yTicks : {
                suggestedMin,
                suggestedMax,
                beginAtZero: true,
                fontSize: props.fontSize,
                display: null != props.yTicks ? props.yTicks : true
            },
            maxValue: props.maxValue ? props.maxValue : 0,
            showYAxes: null != props.showYAxes ? props.showYAxes : false,
            yLabelPosition: props.yLablePosition ? props.yLablePosition : 'left',
            onComplete,
            growDirection: props.growDirection ? props.growDirection : 'right',
            useColorMap,
            spacing,
        }

    }


    /**
     * 값의 최대치를 가져옵니다.
     */
    getSuggestedMax(props){
        let maxValue = 0;

        if(!props.grouped){
            let val = 0;
            for(let i = 0 ; i < props.defaultData.length; i++){
                val = parseFloat(props.defaultData[i])
                if(maxValue < val){
                    maxValue = val;
                }
            }
        }else{
            for(let g = 0 ; g < props.groups; g++){
                const data = props.defaultData[g]
                let val = 0;

                for(let i = 0 ; i < data[i]; i++){
                    val =  parseFloat(data[i])
                    if(maxValue < val){
                        maxValue = val
                    }
                }
            }
        }

        let offset = 5
        // if(props.showTopValue) offset = 10

        // console.log(offset, maxValue );
        return maxValue + offset

    }

    /**
     * 차트 그래프를 생성할 초기데이터를 생성합니다.
     * @param props
     */
    getChartDateSets(props){
        const labels = props.labels ? props.labels : ['delta','theta','alpha','SMR','LBeta','MBeta','HBeta']
        const data = props.defaultData ? props.defaultData : new Array(7).fill(0)
        const colors = props.colors ? props.colors : [] 
        const useColorMap = props.useColorMap
        
        if(!props.grouped){
            let backgroundColor = data.map((mv, index) => {
                let colorIndex = index
                if(!props.useColorIndex) {
                    colorIndex = parseInt(Math.abs(mv));
                }
                
                if(useColorMap){
                    return useColorMap[parseInt(Math.abs(mv))]
                } else {
                    if(0 === colors.length) return 'rgba(0,0,0,0.3)';
                    return colors[colorIndex];
                }
            })
            
            
            return {
                labels: labels,
                datasets: [{
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: backgroundColor,
                    // borderColor: 'rgba(0,0,0,0.3)',
                    // borderCapStyle: 'butt',
                    // borderDash: [],
                    // borderDashOffset: 0.0,
                    // borderJoinStyle: 'miter',
                    pointBorderColor: '#999',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 0,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#999',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 1,
                    pointRadius: 0,
                    borderWidth: 1,
                    pointHitRadius: 10,
                    data: data
                }]
            }
        } else {
            const datasets = [];
            for(let i = 0 ; i < props.groups; i++){

                let backgroundColor = data[i].map((mv, index) => {
                    let colorIndex = index;
                    if(!props.useColorIndex) {
                        colorIndex = parseInt(Math.abs(mv));
                    } else {
                        colorIndex = i;
                    }
    
                    if(0 === colors.length) return 'rgba(0,0,0,0.3)';
                    return colors[colorIndex];
                })
            
                
                datasets.push({
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: backgroundColor,
                    // borderColor: 'rgba(0,0,0,0.3)',
                    // borderCapStyle: 'butt',
                    // borderDash: [],
                    // borderDashOffset: 0.0,
                    // borderJoinStyle: 'miter',
                    pointBorderColor: '#999',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 0,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#999',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 1,
                    pointRadius: 0,
                    borderWidth: 1,
                    pointHitRadius: 10,
                    data: data[i]
                });
            }

            return {
                labels: labels,
                datasets: datasets
            }
        }
        

    }


    /**
     * 데이터를 받아 화면을 새로고칩니다.
     * @param {*} time
     * @param refData
     */
    addData(time, refData){
        let data = refData
        if('left' === this.state.growDirection){
                data = refData.map( val => val * -1 )
        }
        
        let index = 0;
        const colors = data.map((mv) => {
            index = parseInt(Math.abs(mv))
            if(50 < index) {
                index = 50
            }

            return this.state.colors[index]
        })
        
        let newData = data
        if(0 < this.state.maxValue){
            newData = data.map((val) => {
                if( val < this.state.maxValue){
                    return val
                } else {
                    return this.state.maxValue
                }
            })
        }

        this.chartData.datasets[0].data = newData
        this.chartData.datasets[0].backgroundColor = colors
        this.chartData.datasets[0].borderColor = colors

        this.chartRef.chartInstance.update()
    }

    reset() {
        this.setState({
            ...this.state
        })
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        let update = false 
        if(this.typeFlag !== nextProps.typeFlag){
            this.typeFlag = nextProps.typeFlag
            this.chartData = this.getChartDateSets(nextProps)
            update = true
        }
        return update
        
    }

    componentWillMount(){
        this.chartOption = {
            legend: {
                display: false
            },
            hover: {
                animationDuration: 0
            },
            animation: {
                // duration: this.state.animationDuration, // general animation time
                onComplete: this.state.onComplete
            },
            tooltips: {
                enabled: false
            },
            responsive: true,
            title: {
                display: false,
                text: this.state.title
            },
            scales: {
                xAxes: [{
                    categoryPercentage: this.state.spacing,
                    barPercentage: this.state.spacing,
                    display: true,
                    scaleLabel: {
                        display: false,
                        labelString: this.state.title
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                    
                    ticks: {
                        fontSize: this.state.fontSize,
                        fontColor: '#000',
                        // Include a dollar sign in the ticks
                        callback: function(value, index, values) {
                            return value;
                        }
                    },
                }],
                yAxes: [{
                    display: this.state.showYAxes,
                    position: this.state.yLabelPosition,
                    scaleLabel: {
                        display: false,
                        labelString: 'mV'
                    },
                    gridLines: {
                        display: true
                    },
                    ticks: this.state.yTicks
                }]
            }
        }
    }

    render() {
        if('horizontalBar' === this.state.type){
            return <HorizontalBar ref={(ref)=> this.chartRef = ref} 
                    options={
                        {
                            legend: {
                                display: false,
                                labels: {
                                    fontSize: this.state.fontSize,
                                }
                            },
                            scales: {
                                xAxes: [{
                                    ticks: {
                                        fontSize: this.state.fontSize,
                                        fontColor: '#000',
                                        suggestedMin: 0,
                                        suggestedMax: this.state.yTicks.suggestedMax,
                                    }
                                }],
                                yAxes: [{
                                    ticks: {
                                        fontSize: this.state.fontSize,
                                        fontStyle: 'bold',
                                    }
                                }]
                            }
                        }
                    }                                    
                    data={this.chartData} 
                    height={this.state.height} />
        } else {
            return <Bar ref={(ref)=> this.chartRef = ref} 
                    data={this.chartData} 
                    options={this.chartOption} 
                    width={this.state.width}
                    height={this.state.height} 
            />
        }
        
    }
};