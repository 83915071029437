import React, {useCallback, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateHelper from '@helper/DateHelper'
import PrescriptionService from '@user-service/PrescriptionService'
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import {Box, Card, CardActionArea, CardContent, Grid} from "@material-ui/core";

const CurrentExercise = (props) => {
    const classes = useStyles()
    const [exercises, setExercises ] = useState(null);

    const fetch = useCallback(async () => {
        const today = DateHelper.now('YYYY-MM-DD');
        const result = await PrescriptionService.getExercise(props.prescriptionId, true, today)

        setExercises(result.exercises)
    }, [props.prescriptionId])

    useEffect(() => {
        fetch().then()
    }, [fetch]);

    if(!exercises) return <></>

    return (
        <Box className={classes.root}>
            <Card className={classes.card}>
                <CardActionArea>
                    <CardContent>
                        <Typography variant={'subtitle1'} className={classes.title}>
                            처방 프로그램
                        </Typography>

                        <div className="sec-presc" style={{background:'#fff'}}>
                            <div className="auto innerbox">
                                <ul>
                            {exercises.map((item, index) => {
                                const { log, image, name, exerciseCount, countUnitName, description, measureTypeName, measureCode } = item
                                return (
                                    <li key={index} className={classes.exercise}>
                                        <dl>
                                            <dt><img src={require(`@images/${image}`)} alt={''}/></dt>
                                            <dd>
                                                <p>
                                                    {index + 1}. {name}
                                                    <span>{measureTypeName} - {measureCode}, {exerciseCount} {countUnitName}</span>
                                                </p>
                                                {description}
                                            </dd>
                                        </dl>
                                    </li>
                                )
                            })}
        
                            {0 === exercises.length &&
                            <li className={'empty'} style={{width: '100%', border: 0}}>
                                <img src={require('@images/brainLightIcon.png')} alt={'icon'}/>
                                <h4>{props.prescriptionId}회차 처방이 준비중입니다.</h4>
                                <div>담당자에게 문의하세요</div>
                            </li>
                            }
                            </ul>
                            </div>
                        </div>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Box>
            
    )
}

const useStyles = makeStyles(() => ({
    check: {
        '& span':{
            backgroundImage: `url(${require('@images/ico_check.png')})`
        }
    },
    title: {
        fontWeight: 'bold',
        paddingLeft: 15,
        marginBottom: 20
    },
    card: {
        border: "none",
        boxShadow: "none",
        padding: 0
    },
}));

export default CurrentExercise
