import React from "react";
import _ from "lodash";
import {ViewChar} from "../common/Data";
import {Box} from "@material-ui/core";
import {TrainDialog} from "./TrainDialog";
import {SurfingViewBox} from "./SurfingViewBox";
import {SoundViewBox} from "./SoundViewBox";
import {DefaultViewBox} from "./DefaultViewBox";
import {FlipViewBox} from "./FlipViewBox";
import {CalcBox} from "./CalcBox";
import {fi} from "date-fns/locale";

export class CogBox extends React.Component {
  audioRef
  direction = 'forward'
  delay = 1000
  view = 'view1'

  timer

  constructor(props) {
    super();

    const randomProblem = []
    const blocks = []

    this.view = props.view
    this.state = {
      blocks,
      randomProblem,
      originalRandomProblem: [],
      selectedItems: [],
      correctResults: [],
      width: props.width,
      height: props.height,
      widthOffset: props.widthOffset,
      currentShowIndex: -1,
      playEnded: props.onPlayEnded,
      showType: props.showType,
      stageInfo: props.stageInfo,
      isEnd: false,
      onSelectEnded: props.onSelectEnded,
      onFirstStageEnded: props.onFirstStageEnded,
      tempIndex: 0,
      firstStageStart: false
    }
  }
  
  
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if(this.state.width !== nextProps.width){
      this.setState({
        ...this.state,
        width: nextProps.width,
        height: nextProps.height
      })
    }
    return true
  }

  init(audioRef){
    this.audioRef = audioRef
  }

  update(selectedItems){
    this.setState({
      ...this.state,
      selectedItems
    })
  }

  reverse(){
    this.direction = 'reverse'
    setTimeout(() => {
      this.setState({
        ...this.state,
        selectedItems: [],
        currentShowIndex: -1
      })
    }, 500)
  }

  makeRandomIndex(stageInfo, randomProblem, selectorType = null){
    const renderView = stageInfo.viewChar ? _.cloneDeep(ViewChar[stageInfo.viewChar]) :_.cloneDeep(ViewChar[this.view])
    const randomIndex = randomProblem.map( p => p.randomIndex)

    const useMetaSubviewFilter = 'image' === stageInfo.showSub
    const shuffled = renderView.data
      .map((v,index) => index)
      .filter((index) => {
        return selectorType
          ? renderView.meta[index].type.includes(selectorType)
          : true
      })
      .filter((value) => {
        return !(-1 < randomIndex.indexOf(value))
      })
      .filter((index) => {
        // if('challenge' === stageInfo.mode){
          if([1,2,3,4].includes(stageInfo.all) && stageInfo.color) {
            // console.log(renderView.meta[index].name, '초급' === renderView.meta[index].level)
            return '초급' === renderView.meta[index].level
          }
        // }
        return true
      })
      .filter((valIndex) => {
        //sub image 중 실제 데이터가 있는것만 사용함
        if(useMetaSubviewFilter){
          return !!renderView.subImages[valIndex]
        }
        return true
      })
      .map(a => ([Math.random(),a]))
      .sort((a,b) => a[0]-b[0])
      .map(a => a[1])

    const newRandomIndex = shuffled[0]

    let renderViewChar = renderView.data[newRandomIndex]
    /**
     * 특정 화면에서는 특정 케릭터를 사용합니다
     */
    if(stageInfo.viewChar) {
      if ('view5-s' === stageInfo.viewChar && 1 === stageInfo.all) {
        renderViewChar = renderView.dataSubSingle[newRandomIndex]
      }else if('view5-s' === stageInfo.viewChar &&
        2 === stageInfo.all &&
        0 < randomProblem.length &&
        0 !== (randomProblem.length % 2)
      ){
        renderViewChar = renderView.dataSub[newRandomIndex]
      }
    }

    /**
     * 나비에서 사용 꽃 또는 나비
     */
    if(stageInfo.pairSub &&
      stageInfo.showSub &&
      0 === (randomProblem.length + 1) % 2
    ){
      renderViewChar =  renderView.subImages[newRandomIndex]
    }

    return {
      randomIndex: newRandomIndex,
      meta: renderView.meta ? renderView.meta[newRandomIndex] : [],
      subView: renderView.subImages ? renderView.subImages[newRandomIndex] : undefined,
      renderViewChar: renderViewChar,
      isCorrect: undefined,
      isSelected: false
    }
  }

  // view1 : 숫자 , view2: 문자 , view3: 그림 
  next(stageInfo, delay , view ) {
    if(delay) this.delay = delay
    if(view)  this.view = view
    
    
    const blocks = []
    let randomProblem = []

    if(stageInfo && 'all' === stageInfo.showType){
      //동일한 비율대로 랜덤데이터를 생성해야 할경우
      if(stageInfo.selectRatio){
        stageInfo.selectRatio.map((selectorType, index) => {

          /*
          기존은 50%의 정답으로 처리했지만 추가요정으로 all 개수가 정답 수
          +2 개가 오답으로 보여집니다. 
          */
          
          if(selectorType === stageInfo.selectorType){
            for(let i = 0 ; i < stageInfo.all; i++){
              randomProblem.push(this.makeRandomIndex(stageInfo, randomProblem , selectorType))
              blocks.push(0)
            }  
          } else {
            const  newProblemCount = stageInfo.all === 1 ? 1 : 2;
            for(let i = 0 ; i < newProblemCount; i++){
              randomProblem.push(this.makeRandomIndex(stageInfo, randomProblem , selectorType))
              blocks.push(0)
            }
          }
          
          /*
          let max = Math.round(stageInfo.all / 2)
          if(1 === stageInfo.all %2 && 1 == index) {
            max = Math.floor(stageInfo.all / 2)
          }

          for(let i = 0 ; i < max; i++){
            randomProblem.push(this.makeRandomIndex(stageInfo, randomProblem , selectorType))
            blocks.push(0)
          }
         */
          return selectorType
        })
      } else {
        for(let i = 0 ; i < stageInfo.all; i++){
          randomProblem.push(this.makeRandomIndex(stageInfo, randomProblem))
          blocks.push(0)
        }

        //flip 모드일 때 추가 
        if(stageInfo.minCorrect && ['show-number', 'not-same-number','same-number'].includes(stageInfo.selectorType)){
          const  newProblemCount = stageInfo.all === 1 ? 1 : 2;
          for(let i = 0 ; i < newProblemCount; i++){
            randomProblem.push(this.makeRandomIndex(stageInfo, randomProblem))
            blocks.push(0)
          }  
        }
      }
      
      
    } else {
      // 스탭별로 표시되는건 play()함수에서 처리합니다
      if(stageInfo.selectorType){
        randomProblem.push( this.makeRandomIndex(stageInfo, randomProblem, stageInfo.selectorType) )
      } else {
        randomProblem.push( this.makeRandomIndex(stageInfo, randomProblem) )  
      }
      blocks.push(0)
    }

    // if(4 === stageInfo.all && stageInfo.minCorrect) stageInfo.minCorrect = 2
    // if((8 === stageInfo.all || 10 === stageInfo.all) && stageInfo.minCorrect) stageInfo.minCorrect = 4
    //
    // 최소 정답 개수가 있으면 그외는 오답 처리합니다.
    if(stageInfo.minCorrect && ['show-number'].includes(stageInfo.selectorType)) {
      stageInfo.minCorrect = stageInfo.all
      
      const [correctProblem, notCorrectProblem] = [randomProblem.slice(0, stageInfo.minCorrect), randomProblem.slice(stageInfo.minCorrect)];
      const shuffledNotCorrect = _.reverse(_.cloneDeep(notCorrectProblem)).map(((p) => {
        p.meta.number = ''
        return p
      }))
      randomProblem = _.cloneDeep(_.shuffle(_.concat(correctProblem, shuffledNotCorrect)))
    } else if (stageInfo.minCorrect &&  ['same-number', 'not-same-number'].includes(stageInfo.selectorType)) {
      // done flip 에서 처리함 
    } else if (stageInfo.minCorrect) {
        let minCorrect = stageInfo.all

        let newProblem = stageInfo.all === 1 ?  1 : 2
        for(let i = 0 ; i< newProblem; i++){
          randomProblem.push(this.makeRandomIndex(stageInfo, randomProblem))
          blocks.push(0)
        }
        
        let correctProblem = []
        let notCorrectProblem = []
  
        correctProblem = randomProblem.slice(0, minCorrect)
        notCorrectProblem = randomProblem.slice(minCorrect)
  
        if('not-same-name' === stageInfo.selectorType ){
          notCorrectProblem = randomProblem.slice(0, minCorrect)
          correctProblem = randomProblem.slice(minCorrect)
        } 
        
        if(2 === randomProblem.length) {
          correctProblem = randomProblem.slice(0,1)
          notCorrectProblem = randomProblem.slice(1)
          
          notCorrectProblem.push(this.makeRandomIndex(stageInfo, randomProblem))
        }
        
        
        const newRandomProblem = []
        for(let i = 0 ; i < notCorrectProblem.length ; i++){
          const problem = notCorrectProblem[i]
          let shuffledNotCorrect = notCorrectProblem[i + 1]
          // if(!shuffledNotCorrect) continue
          if(!shuffledNotCorrect) shuffledNotCorrect = notCorrectProblem[0]
          
          newRandomProblem.push({
            ...problem,
            meta: {
              ...problem.meta,
              color: shuffledNotCorrect.meta.color ? shuffledNotCorrect.meta.color : undefined,
              number: shuffledNotCorrect.meta.number ? shuffledNotCorrect.meta.number : undefined,
              name: shuffledNotCorrect.meta.name,
            },
            subView : shuffledNotCorrect.subView ? shuffledNotCorrect.subView : undefined,
          })    
        }

        if ( 2 === randomProblem.length){
          newRandomProblem.shift()
        }
      
        randomProblem = _.cloneDeep(_.shuffle(_.concat(correctProblem, newRandomProblem)))

    }

    if(stageInfo.randomDelay){
      randomProblem.forEach((problem, i) => {
        problem.showDelay = i * stageInfo.randomDelay
      })
      randomProblem = _.shuffle(randomProblem)
    }
    

    this.setState({
      ...this.state,
      currentShowIndex: -1,
      randomProblem: _.shuffle(randomProblem).map((r, index) => {
        r.originalIndex = index;
        return r
      }),
      selectedItems: [],
      blocks,
      isEnd: false,
      stageInfo: stageInfo,
      tempIndex: this.state.tempIndex + 1,
      isDoneShowDelayProblem: false,
      isDonePlayProblem: false,
    })

    
    setTimeout(() => {
      try {
        if(stageInfo.all === 1 && stageInfo.textForOneSound){
          this.audioRef.current.playWith(stageInfo.textForOneSound)
        } else if(stageInfo.textSound){
          this.audioRef.current.playWith(stageInfo.textSound)  
        }
        
        if('step' === stageInfo.showType){
          this.play(stageInfo)
        }
      } catch {}
    },300)

  }

  stop() {
    if(this.timer){
      clearTimeout(this.timer)
    }
  }

  play(stageInfo) {

    const {randomProblem, blocks} = this.state

    let offset = stageInfo.selectRatio ? 1 : -1
    
    /*
    *1단계에서는 1개를 가지고 순서대로 할 수 없으므로
     1단계 7, 8, 9, 10번 문항만 바꿉니다.
    7번 문항: 먼저 나타난 것을 찾으세요. (캐릭터 2개를 보여주고 찾게 함)
    8번 문항: 첫 번 나타난 과일과 야채를 찾으세요.(과일과 야채 캐릭터 2개 )
    9번 문항: 첫 번 나타난 동물을 찾으세요.(동물 캐릭터 2개 )
    10번 문항: 늦게 나타난 것을 찾으세요. (캐릭터 2개를 보여주고 찾게 함)

     */
    if('challenge' === stageInfo.mode && 1 == stageInfo.all) {
      offset = 1
    }
    if(
      (stageInfo.all === 1 && randomProblem.length === 2 ) ||  
      randomProblem.length > stageInfo.all + offset 
    ){
      // if(this.state.playEnded){
      //     this.state.playEnded(this.state.randomIndex)
      // }

      if('step' === stageInfo.showType){
        let clone = _.cloneDeep(randomProblem)
        clone = 2 === clone.length 
          ? [clone[1], clone[0]]
          : _.shuffle(clone)

        setTimeout(() => {
          this.setState({
            ...this.state,
            randomProblem: clone,
            originalRandomProblem: randomProblem,
            isDonePlayProblem: true
          })
        }, stageInfo.delay)
      }
      return false
    }

    if(this.timer){
      clearTimeout(this.timer)
    }
    
    //비율 설정이 있을때는 비율대로 처리합니다 
    if(stageInfo.selectRatio){
      //비율을 맞추기 위해서 두개의 비교 (동계,하계) 중 다음 타입을 생성할 타입을 정합니다.
      // const typeCount = [0,0]
      // stageInfo.selectRatio.map((type, index) => {
      //   typeCount[index] = randomProblem.filter(p => p.meta.type.includes(type))
      // })
      // const nextSelectorType = typeCount[0].length < typeCount[1].length ? stageInfo.selectRatio[0] : stageInfo.selectRatio[1]
      // randomProblem.push( this.makeRandomIndex(stageInfo, randomProblem, nextSelectorType) )
      
      //정답 + 2개의 오답 형식으로 만들기 위해서 
      // if(1 === stageInfo.all) {
      //   const notSelectorType = stageInfo.selectRatio.filter( t => stageInfo.selectorType !== t)
      //  
      //   0 === randomProblem.length
      //     ? randomProblem.push( this.makeRandomIndex(stageInfo, randomProblem, stageInfo.selectorType) )
      //     : randomProblem.push( this.makeRandomIndex(stageInfo, randomProblem, notSelectorType[0]) )
      // } else {
      //   const notSelectorType = stageInfo.selectRatio.filter( t => stageInfo.selectorType !== t)
      //  
      //   0 === randomProblem.length % stageInfo.all
      //     ? randomProblem.push( this.makeRandomIndex(stageInfo, randomProblem, notSelectorType[0]) )
      //     : randomProblem.push( this.makeRandomIndex(stageInfo, randomProblem, stageInfo.selectorType) )
      // }

      const notSelectorType = stageInfo.selectRatio.filter( t => stageInfo.selectorType !== t)
      const half = Math.ceil(randomProblem.length / 2);
      
      //level 1은 최초 입력되어 있어 오답만 넣습니다.
      if(stageInfo.all === 1) {
        randomProblem.push( this.makeRandomIndex(stageInfo, randomProblem, stageInfo.selectorType) )
      } else {
        if(half === randomProblem.length || randomProblem.length === stageInfo.all){
          randomProblem.push( this.makeRandomIndex(stageInfo, randomProblem, notSelectorType[0]) )
        } else {
          randomProblem.push( this.makeRandomIndex(stageInfo, randomProblem, stageInfo.selectorType) )
        }  
      }
    } else {
      randomProblem.push( this.makeRandomIndex(stageInfo, randomProblem) )
    }

    blocks.push(0)

    this.timer = setTimeout(() => {
      const showIndex = this.state.currentShowIndex + 1
      if(showIndex < this.state.blocks.length){
        try {
          // this.audioRef.current.effect('show')
        } catch {}
      }

      let originalIndex = 0;
      this.setState({
        ...this.state,
        randomProblem: randomProblem.map((r) => {
          if(stageInfo.selectorType && stageInfo.direction){ 
            // console.log(stageInfo.selectorType, r.type, r.type.includes(stageInfo.selectorType))
            if (r.meta.type.includes(stageInfo.selectorType)) {
              r.originalIndex = originalIndex++
            } else {
              r.originalIndex = -1;
            }
          } else {
            r.originalIndex = originalIndex++
          }
          return r
        }),
        currentShowIndex: showIndex,
      }, () => {
        this.play(stageInfo)
      })

    }, stageInfo.delay)
  }

  checkCorrect(problem) {
    //이미 선택된된건 처리하지 않음
    if(problem.isSelected) return

    // 랜던 선택된 같은 타입을 찾음
    const { stageInfo, randomProblem, originalRandomProblem, selectedItems, isEnd } = this.state
    // if(stageInfo.all !== randomProblem.length) return
    if(isEnd) return

    problem.isSelected = true
    selectedItems.push(problem)
    problem.isCorrect = this.isProblemCorrect(problem)

    let isStageEnd = false
    if ('firstStage' === stageInfo.viewBox) {
      if(selectedItems.length === randomProblem.length){
        this.state.onFirstStageEnded()
        return
      }
    } else if ('memory' === stageInfo.type && 'not-same' === stageInfo.selectorType) {
      const correctNames = originalRandomProblem.map(p => p.meta.name)
      // problem.isCorrect = !correctNames.includes(problem.meta.name)

      const correctProblem = randomProblem.filter(p => !correctNames.includes(p.meta.name))
      const correctSelectItems = selectedItems.filter(p => p.isCorrect)

      if (correctSelectItems.length === correctProblem.length) {
        const foundIncorrect = randomProblem.filter(p => false === p.isCorrect)
        this.state.onSelectEnded(0 === foundIncorrect.length)
        isStageEnd = true
      }

    } else if ('all' === stageInfo.showType && 'show-number' === stageInfo.selectorType) {
      // problem.isCorrect = !!problem.meta.number

      const correctProblem = randomProblem.filter(p => !!p.meta.number)
      const correctSelectItems = selectedItems.filter(p => !!p.meta.number)

      if (correctSelectItems.length === correctProblem.length) {
        const foundIncorrect = randomProblem.filter(p => false === p.isCorrect)
        this.state.onSelectEnded(0 === foundIncorrect.length)
        isStageEnd = true
      }
    } else if ('all' === stageInfo.showType && 'same-number' === stageInfo.selectorType){
      // problem.isCorrect = !!problem.meta.type.includes( String(problem.meta.number) )

      const correctProblem = randomProblem.filter(p => p.meta.type.includes( String(p.meta.number) ))
      const correctSelectItems = selectedItems.filter(p => p.isCorrect)

      if(correctSelectItems.length === correctProblem.length){
        const foundIncorrect = randomProblem.filter(p => false === p.isCorrect)
        this.state.onSelectEnded(0 === foundIncorrect.length)
        isStageEnd = true
      }
    } else if ('all' === stageInfo.showType && 'not-same-number' === stageInfo.selectorType){
      // problem.isCorrect = !!!problem.meta.type.includes( String(problem.meta.number) )

      const correctProblem = randomProblem.filter(p => !p.meta.type.includes( String(p.meta.number) ))
      const correctSelectItems = selectedItems.filter(p => p.isCorrect)

      if(correctSelectItems.length === correctProblem.length){
        const foundIncorrect = randomProblem.filter(p => false === p.isCorrect)
        this.state.onSelectEnded(0 === foundIncorrect.length)
        isStageEnd = true
      }
    } else if ('all' === stageInfo.showType && ['not-same-name'].includes(stageInfo.selectorType)) {
      const filter = randomProblem.filter(p => !p.meta.type.includes(p.meta.name));
      const correctCount = selectedItems.filter(p => !p.meta.type.includes(p.meta.name)).length
      
      // problem.isCorrect = !problem.meta.type.includes(problem.meta.name)

      if (correctCount === filter.length) {
        const foundIncorrect = randomProblem.filter(p => false === p.isCorrect)
        this.state.onSelectEnded(0 === foundIncorrect.length)
        isStageEnd = true
      }
    } else if ('all' === stageInfo.showType && ['same-name'].includes(stageInfo.selectorType)) {
      const filter = randomProblem.filter(p => p.meta.type.includes(p.meta.name));
      const correctCount = selectedItems.filter(p => p.meta.type.includes(p.meta.name)).length

      // problem.isCorrect = problem.meta.type.includes(problem.meta.name)

      if (correctCount === filter.length) {
        const foundIncorrect = randomProblem.filter(p => false === p.isCorrect)
        this.state.onSelectEnded(0 === foundIncorrect.length)
        isStageEnd = true
      }
    } else if ('all' === stageInfo.showType && stageInfo.randomDelay) {
      const correctProblem = originalRandomProblem.find(p => p.randomIndex === problem.randomIndex)
      // problem.isCorrect = !!correctProblem

      /**
       * 제시된 이미지 중, 포함여부, 정답
       */
      const includedProblem = randomProblem.filter((rp) => {
        return !!originalRandomProblem.find(p => p.randomIndex === rp.randomIndex)
      })

      /**
       * 선택한 정답
       */
      const includedSelectedItems = selectedItems.filter(rp => {
        return !!originalRandomProblem.find(p => p.randomIndex === rp.randomIndex)
      })

      if(includedSelectedItems.length === includedProblem.length){
        const foundIncorrect = randomProblem.filter(p => false === p.isCorrect)
        this.state.onSelectEnded(0 === foundIncorrect.length)
        isStageEnd = true
      }

    } else if('step' === stageInfo.showType && stageInfo.selectorType && !stageInfo.direction) {
      //정답에서 생물 또는 물건 
      const filter = originalRandomProblem.filter(p => p.meta.type.includes(stageInfo.selectorType));
      //내가 고른 답 중에서  생물또는 물건 


      let isCorrect = problem.meta.type.includes(stageInfo.selectorType)
      if (isCorrect) {
        //내가 선택한 답 중에 타입이 맞는것 
        const filteredSelected = selectedItems.filter(p => p.meta.type.includes(stageInfo.selectorType));
        const answer = filter[filteredSelected.length - 1]

        isCorrect = answer.randomIndex === problem.randomIndex;

      }
      // problem.isCorrect = isCorrect

      /**
       * 도전모드이면서 lv.1일때는 문제가 다름
       * 첫번째 나타난 것을 찾는것이 때문에 처음 클릭한거 여부를 판단
       * 23.09.06
       */
      if('challenge' === stageInfo.mode &&
        selectedItems.length === 1
        && stageInfo.all === 1){
        const foundIncorrect = selectedItems.filter(p => p.meta.type.includes(stageInfo.selectorType)).length
        this.state.onSelectEnded(0 === foundIncorrect.length)
        isStageEnd = true
      }
      
      const correctCount = selectedItems.filter(p => p.meta.type.includes(stageInfo.selectorType)).length
      if (correctCount === filter.length) {
        const foundIncorrect = randomProblem.filter(p => false === p.isCorrect)
        this.state.onSelectEnded(0 === foundIncorrect.length)
        isStageEnd = true
      }
    } else if(['step'].includes(stageInfo.showType) && !stageInfo.selectorType ){
      if('last' === stageInfo.direction){
        const clone = _.cloneDeep(originalRandomProblem)
        clone.reverse()
        const answer = clone[selectedItems.length - 1]
        // problem.isCorrect = answer.randomIndex === problem.randomIndex;
      }

      if('first' === stageInfo.direction) {
        const answer = originalRandomProblem[selectedItems.length - 1]
        // problem.isCorrect = answer.randomIndex === problem.randomIndex;
      }

      /**
       * 도전모드이면서 lv.1일때는 문제가 다름 
       * 첫번째 나타난 것을 찾는것이 때문에 처음 클릭한거 여부를 판단
       * 23.09.06 
       */
      if('challenge' === stageInfo.mode && 
        selectedItems.length === 1 
        && stageInfo.all === 1){
        const foundIncorrect = randomProblem.filter(p => false === p.isCorrect)
        this.state.onSelectEnded(0 === foundIncorrect.length)
        isStageEnd = true
      }
      
      if(selectedItems.length === randomProblem.length){
        const foundIncorrect = randomProblem.filter(p => false === p.isCorrect)
        this.state.onSelectEnded(0 === foundIncorrect.length)
        isStageEnd = true
      }
    } else if(['step'].includes(stageInfo.showType) && stageInfo.selectorType  && stageInfo.direction){
      let clone
      let cloneSelectedItem
      if('last' === stageInfo.direction){
        clone = _.cloneDeep(originalRandomProblem).filter((p) => {
          if(stageInfo.selectorType){
            return p.meta.type.includes(stageInfo.selectorType)
          }
          return true
        })

        cloneSelectedItem = _.cloneDeep(selectedItems).filter(p => {
          if(stageInfo.selectorType){
            return p.meta.type.includes(stageInfo.selectorType)
          }
          return true
        })


        clone.reverse()
        if(stageInfo.selectorType && !problem.meta.type.includes(stageInfo.selectorType)){
          // problem.isCorrect = false
        } else {
          const answer = clone[cloneSelectedItem.length - 1]

          if(answer){
            // problem.isCorrect = answer.randomIndex === problem.randomIndex;
          } else {
            // problem.isCorrect = false
          }
        }
      }

      if('first' === stageInfo.direction) {
        clone = _.cloneDeep(originalRandomProblem).filter((p) => {
          if(stageInfo.selectorType){
            return p.meta.type.includes(stageInfo.selectorType)
          }
          return true
        })

        cloneSelectedItem = _.cloneDeep(selectedItems).filter(p => {
          if(stageInfo.selectorType){
            return p.meta.type.includes(stageInfo.selectorType)
          }
          return true
        })

        if(stageInfo.selectorType && !problem.meta.type.includes(stageInfo.selectorType)){
          // problem.isCorrect = false
        } else {
          const answer = clone[cloneSelectedItem.length - 1]

          if(answer){
            // problem.isCorrect = answer.randomIndex === problem.randomIndex;
          } else {
            // problem.isCorrect = false
          }
        }
      }

      const correctItems = selectedItems.filter(p => p.isCorrect)
      const filterItems = selectedItems.filter(p => p.meta.type.includes(stageInfo.selectorType))


      /**
       * 도전모드이면서 lv.1일때는 문제가 다름
       * 첫번째 나타난 것을 찾는것이 때문에 처음 클릭한거 여부를 판단
       * 23.09.06
       */
      if('challenge' === stageInfo.mode &&
        selectedItems.length === 1
        && stageInfo.all === 1){
        const foundIncorrect = randomProblem.filter(p => false === p.isCorrect)
        this.state.onSelectEnded(0 === foundIncorrect.length)
        isStageEnd = true
      }
      
      if(correctItems.length === clone.length || filterItems.length === clone.length ){
        const foundIncorrect = randomProblem.filter(p => false === p.isCorrect)
        this.state.onSelectEnded(0 === foundIncorrect.length)
        isStageEnd = true
      }


    } else {
      //오답선택
      // problem.isCorrect = problem.meta.type.includes(stageInfo.selectorType)

      //전체 맞음
      const matched = randomProblem.filter( p => p.meta.type.includes(stageInfo.selectorType))
      const correctCount = selectedItems.filter(p => p.meta.type.includes(stageInfo.selectorType)).length
      if( correctCount === matched.length){
        const foundIncorrect = randomProblem.filter(p => false === p.isCorrect)
        this.state.onSelectEnded(0 === foundIncorrect.length)
        isStageEnd = true
      }
    }


    this.setState({
      ...this.state,
      selectedItems,
      isEnd: isStageEnd,
    })
  }


  playAudioAfterText() {
    const {stageInfo} = this.state
    try{
      if(1 === stageInfo.all&& stageInfo.afterTextOneSound){
        this.audioRef.current.playWith(stageInfo.afterTextOneSound)
      }if(stageInfo.afterTextSound) {
        this.audioRef.current.playWith(stageInfo.afterTextSound)
      }  
    } catch {}
  }

  /**
   * 정답 여부를 반환합니다
   * @param problem
   */
  isProblemCorrect(problem){
    // 랜던 선택된 같은 타입을 찾음
    const { stageInfo, originalRandomProblem, selectedItems } = this.state
    // if(stageInfo.all !== randomProblem.length) return
    if ('firstStage' === stageInfo.viewBox) {
      return true
    } else if ('memory' === stageInfo.type && 'not-same' === stageInfo.selectorType) {
      const correctNames = originalRandomProblem.map(p => p.meta.name)
      return !correctNames.includes(problem.meta.name)
    } else if ('all' === stageInfo.showType && 'show-number' === stageInfo.selectorType) {
      return !!problem.meta.number

    } else if ('all' === stageInfo.showType && 'same-number' === stageInfo.selectorType){
      return !!problem.meta.type.includes( String(problem.meta.number) )
    } else if ('all' === stageInfo.showType && 'not-same-number' === stageInfo.selectorType){
      return !!!problem.meta.type.includes( String(problem.meta.number) )
    } else if ('all' === stageInfo.showType && ['not-same-name'].includes(stageInfo.selectorType)) {
      return !problem.meta.type.includes(problem.meta.name)
    } else if ('all' === stageInfo.showType && ['same-name'].includes(stageInfo.selectorType)) {
      return problem.meta.type.includes(problem.meta.name)
    } else if ('all' === stageInfo.showType && stageInfo.randomDelay) {
      const correctProblem = originalRandomProblem.find(p => p.randomIndex === problem.randomIndex)
      return !!correctProblem
    } else if('step' === stageInfo.showType && stageInfo.selectorType && !stageInfo.direction) {
      //정답에서 생물 또는 물건 
      const filter = originalRandomProblem.filter(p => p.meta.type.includes(stageInfo.selectorType));
      //내가 고른 답 중에서  생물또는 물건 


      let isCorrect = problem.meta.type.includes(stageInfo.selectorType)
      if (isCorrect) {
        //내가 선택한 답 중에 타입이 맞는것 
        const filteredSelected = selectedItems.filter(p => p.meta.type.includes(stageInfo.selectorType));
        const answer = filter[filteredSelected.length - 1]

        isCorrect = answer.randomIndex === problem.randomIndex;

      }
      return isCorrect
      
    } else if(['step'].includes(stageInfo.showType) && !stageInfo.selectorType ){
      if('last' === stageInfo.direction){
        const clone = _.cloneDeep(originalRandomProblem)
        clone.reverse()
        const answer = clone[selectedItems.length - 1]
        return answer.randomIndex === problem.randomIndex;
      }

      if('first' === stageInfo.direction) {
        // console.log(selectedItems.length)
        const answer = originalRandomProblem[selectedItems.length - 1]
        // console.log(answer)
        // console.log(problem)
        return answer.randomIndex === problem.randomIndex;
      }
    } else if(['step'].includes(stageInfo.showType) && stageInfo.selectorType  && stageInfo.direction){
      let clone
      let cloneSelectedItem
      if('last' === stageInfo.direction){
        clone = _.cloneDeep(originalRandomProblem).filter((p) => {
          if(stageInfo.selectorType){
            return p.meta.type.includes(stageInfo.selectorType)
          }
          return true
        })

        cloneSelectedItem = _.cloneDeep(selectedItems).filter(p => {
          if(stageInfo.selectorType){
            return p.meta.type.includes(stageInfo.selectorType)
          }
          return true
        })


        clone.reverse()
        if(stageInfo.selectorType && !problem.meta.type.includes(stageInfo.selectorType)){
          return false
        } else {
          const answer = clone[cloneSelectedItem.length - 1]

          if(answer){
            return answer.randomIndex === problem.randomIndex;
          } else {
            return false
          }
        }
      }

      if('first' === stageInfo.direction) {
        clone = _.cloneDeep(originalRandomProblem).filter((p) => {
          if(stageInfo.selectorType){
            return p.meta.type.includes(stageInfo.selectorType)
          }
          return true
        })

        cloneSelectedItem = _.cloneDeep(selectedItems).filter(p => {
          if(stageInfo.selectorType){
            return p.meta.type.includes(stageInfo.selectorType)
          }
          return true
        })

        if(stageInfo.selectorType && !problem.meta.type.includes(stageInfo.selectorType)){
          return false
        } else {
          const answer = clone[cloneSelectedItem.length - 1]

          if(answer){
            return answer.randomIndex === problem.randomIndex;
          } else {
            return false
          }
        }
      }

    } else {
      //오답선택
      return problem.meta.type.includes(stageInfo.selectorType)

    }

    return false;
  }
  render() {
    const renderView = ViewChar[this.view]
    const { randomProblem, stageInfo, widthOffset,originalRandomProblem }  = this.state

    let size = this.state.width
    if( randomProblem.length > 10 ) size = 200
    
    if(!stageInfo) return <></>
    return (
      <Box>
        {stageInfo && 'calculator' !== stageInfo.type &&
          <>
            {['firstStage'].includes(stageInfo.viewBox) &&
              <>
                <TrainDialog
                  open={true}
                  onStart={() => {
                    this.setState({
                      ...this.state,
                      firstStageStart: true
                    })
                  }}
                />
                <SoundViewBox
                  widthOffset={widthOffset}
                  stageInfo={stageInfo}
                  randomProblem={randomProblem}
                  renderView={renderView}
                  blocks={this.state.blocks}
                  width={size}
                  height={size}
                  programView={this.view}
                  firstStageStart={this.state.firstStageStart}
                  checkCorrect={(problem) => {
                    this.checkCorrect(problem)
                  }}
                />
              </>
            }

            {['default'].includes(stageInfo.viewBox) &&
              <DefaultViewBox
                key={Math.random()}
                widthOffset={widthOffset}
                stageInfo={stageInfo}
                randomProblem={randomProblem}
                renderView={renderView}
                originalRandomProblem={originalRandomProblem}
                blocks={this.state.blocks}
                width={size}
                height={size}
                programView={this.view}
                isDonePlayProblem={this.state.isDonePlayProblem}
                isDoneShowDelayProblem={this.state.isDoneShowDelayProblem}
                doneRandomDelay={(isDoneShowDelayProblem) => {
                  // const newRandomProblem = randomProblem.slice(0, 3)
                  // const newProblemCount = randomProblem.length - 3

                  const newProblemCount = stageInfo.all === 1 ? 1 : 2;
                  const newRandomProblem = _.cloneDeep(randomProblem)

                  for(let i = 0 ; i < newProblemCount; i++){
                    newRandomProblem.push(this.makeRandomIndex(stageInfo, newRandomProblem))
                  }

                  this.setState({
                    ...this.state,
                    originalRandomProblem: _.cloneDeep(randomProblem),
                    randomProblem: 2 === _.size(newRandomProblem) 
                      ? [newRandomProblem[1], newRandomProblem[0]]
                     : _.shuffle(newRandomProblem),
                    isDoneShowDelayProblem
                  })

                  this.playAudioAfterText()
                }}
                checkCorrect={(problem) => {
                  this.checkCorrect(problem)
                }}
                isCorrect={(problem) => {
                  return this.isProblemCorrect(problem)
                }}
              />
            }

            {this.state.isDoneShowDelayProblem}
            {['surfing'].includes(stageInfo.viewBox) &&
              <SurfingViewBox
                key={Math.random()}
                widthOffset={widthOffset}
                stageInfo={stageInfo}
                randomProblem={randomProblem}
                renderView={renderView}
                blocks={this.state.blocks}
                width={size}
                height={size}
                programView={this.view}
                isDoneShowDelayProblem={this.state.isDoneShowDelayProblem}
                doneRandomDelay={(isDoneShowDelayProblem) => {
                  // 홀수면 짝수가 나올수 있도록 처리하고 , 짝수면 2개가 더 나오도록 한다 
                  // let newProblemCount = 0 === stageInfo.all % 2 ?  2 : 1
                  const newProblemCount = stageInfo.all === 1 ? 1 : 2;
                  const newRandomProblem = _.cloneDeep(randomProblem)

                  for(let i = 0 ; i < newProblemCount; i++){
                    newRandomProblem.push(this.makeRandomIndex(stageInfo, newRandomProblem))
                  }

                  this.setState({
                    ...this.state,
                    originalRandomProblem: _.cloneDeep(randomProblem),
                    randomProblem: 2 === _.size(newRandomProblem)
                      ? [newRandomProblem[1], newRandomProblem[0]]
                      : _.shuffle(newRandomProblem),
                    isDoneShowDelayProblem
                  })
                  
                  this.playAudioAfterText()
                  
                }}
                checkCorrect={(problem) => {
                  this.checkCorrect(problem)
                }}
                isCorrect={(problem) => {
                  return this.isProblemCorrect(problem)
                }}
              />
            }


            {'flip' === stageInfo.viewBox &&
              <FlipViewBox
                key={this.state.tempIndex}
                tempIndex={this.state.tempIndex}
                widthOffset={widthOffset}
                stageInfo={stageInfo}
                randomProblem={randomProblem}
                renderView={renderView}
                blocks={this.state.blocks}
                width={size}
                height={size}
                programView={this.view}
                doneFlip={() => {
                  let numbers = _.shuffle(_.range(randomProblem.length))
                  
                  const f2 = numbers.slice(stageInfo.all)
                  const f1 = numbers.slice(0, stageInfo.all)

                  if(1 === stageInfo.all) {
                    const problem = this.makeRandomIndex(stageInfo, randomProblem)
                    randomProblem[ f2[0] ].meta.number = problem.meta.number
                  } else {
                    const allNumbers = randomProblem.map(p => p.meta.number)
                    let numbers = _.range(1, 25);

                    if('same-number' === stageInfo.selectorType){
                      const shuffle = _.shuffle(numbers.filter( n => !allNumbers.includes(n)))
                      for(let i = 0 ; i < f2.length; i++){
                        const index = f2[i];
                        randomProblem[index].meta.number = shuffle[i];
                      }
                    } else {
                      const shuffle = _.shuffle(numbers.filter( n => !allNumbers.includes(n)))
                      for(let i = 0 ; i < f1.length; i++){
                        const index = f1[i];
                        randomProblem[index].meta.number = shuffle[i];
                      }
                    }
                  }

                  this.setState({
                    ...this.state,
                    originalRandomProblem,
                  })
                }}
                checkCorrect={(problem) => {
                  this.checkCorrect(problem)
                }}
                isCorrect={(problem) => {
                  return this.isProblemCorrect(problem)
                }}
              />
            }
          </>
        }
        {stageInfo && 'calculation' === stageInfo.viewBox &&
          <CalcBox
            programView={this.view}
            key={Math.random()}
            stageInfo={stageInfo}
            onSelectEnded={(result) => {
              try {
                this.state.onSelectEnded(result)
              } catch(e) {
              }
            }}
          />
        }
      </Box>
    )
  }
}
