import React, {useCallback, useEffect, useState} from 'react';

import {Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Skeleton from '@material-ui/lab/Skeleton'
import ExerciseService from '@user-service/ExerciseService'
import PrescriptionService from '@user-service/PrescriptionService'
import MeasureService from "../../../services/MeasureService";
import AuthProvider from "../../../provider/AuthProvider";
import MeasureTestDefault from "./MeasureTestDefault";
import MeasureTestApp from "./MeasureTestApp";
import MeasureLevelDialog from "./MeasureLevelDialog";
import queryString from "query-string";

const MeasureTrain = () => {
    const [state, setState] = useState({
        exercise: [],
        prescription: { ID : 0 },
        measureTypesCount: [],
    })
  
    const [measureType, setMeasureType] = useState('train')
  
    const fetch = useCallback( async () => {
        let prescription = await PrescriptionService.getLatest();
        const measureTypesCount = await MeasureService.getMeasureCount({
            profileID: AuthProvider.getProfileID(),
            measureGroup: 'measure'
        })
        
        const result = await ExerciseService.getExercises('train')
        const data = result.success ? result.data : []
        setState({
            exercise: data,
            prescription: prescription,
            measureTypesCount: measureTypesCount
        })
        
      
    }, [])
    
    const openDialog = () => {
        setMeasureType(null)
    }
    
    useEffect(()=>{   
        fetch().then()
    },[fetch])
    
    useEffect(() => {/**/
        localStorage.setItem('current-page', 'measureTest')
        localStorage.setItem('measure-test-type', 'train')
        return () => {
            localStorage.setItem('current-page', null)
        }
    }, [])
    
    return (
        !measureType
            ? <MeasureLevelDialog onStart={(measureType) => {
                setMeasureType(measureType)
                localStorage.setItem('measure-test-type', measureType)
            }}/>
            : AuthProvider.isAppMode()
                ? <MeasureTestApp state={state} measureType={measureType} measureTypesCount={state.measureTypesCount} openMeasureTest={openDialog}/>
                : <MeasureTestDefault state={state} measureType={measureType} measureTypesCount={state.measureTypesCount} openMeasureTest={openDialog}/> 
        
    )
}


//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className="editprofile_wrap wrapper" xs={6} sm={6}>
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </Box>
        </Box>
    )
}


const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 1400,
        width: 1400,
        
    },
    headerBox: {
        marginBottom: 40,
        marginTop: 120
    },
    title: {
        fontSize:26,
        fontWeight: 'bold'
    }
}));



export default MeasureTrain
