import React, {useEffect, useState, useCallback} from "react";
import {makeStyles, withStyles} from '@material-ui/core/styles';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import {Box, Grid, Typography} from "@material-ui/core";


import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CenterCustomerList from "./CenterCustomerList";
import MeasureCount from "../measure/MeasureCount";
import SearchTextField from "../customer/CustomerSearchText";
import CenterProfile from "./CenterProfile";


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const CenterDialog = (props) => {
    const classes = useStyles()
    
    const { center, onClose, open, history} = props
    const [ searchKeyword, setKeyword] = useState(null)
    const [ tabValue, setTabValue] = useState(0)
    
    const handleTab = (event, newValue) => {
        setTabValue(newValue)
    }
    const handleClose = () => {
        if(onClose){
            onClose()
        }
    }
    const fetchCustomer = useCallback(async () => {
     

    }, [])

    useEffect(() => {
        if(open){
            fetchCustomer().then()
        }
    }, [open, fetchCustomer])


    if(!center) return <></>
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle onClose={handleClose}>
                <Grid container>
                    <Grid item xs={3}>
                        <Typography variant={'subtitle1'}>{center.name}</Typography>
                        <div className={classes.small} >{center.ownerName} - {center.loginID}</div>
                    </Grid>
                    <Grid item xs={6}>
                        <SearchTextField keyup={(text) => {
                            setKeyword(text)
                        }}/>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Tabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTab}
                    >
                        <Tab label="회원목록" />
                        <Tab label="이용권" />
                        <Tab label="센터정보" />
                    </Tabs>
                </Box>
            </DialogTitle>
            <DialogContent style={{height: '100vh'}}>
     
                {0 === tabValue &&
                    <CenterCustomerList searchKeyword={searchKeyword} centerID={center.id} centerName={center.name} history={history}/>
                }
                {1 === tabValue &&
                    <MeasureCount centerID={center.id} onlyCenter={true}/>    
                }
                {2 === tabValue &&
                    <CenterProfile center={center}/>
                }
                
            </DialogContent>
        </Dialog>
    );
}


const useStyles = makeStyles(() => ({
    title: {
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    small: {
        fontSize: '0.8rem',
        color: '#999'
    },
    cardTitle: {
        fontWeight: 'bold',
        paddingLeft: 25,
        marginBottom: 20
    },
}));

export default CenterDialog