import React, {useEffect} from "react";
import {useTheme, withStyles} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ReactAudioPlayer from "react-audio-player";
import {Button, Typography} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DialogActions from "@material-ui/core/DialogActions";
import AuthProvider from "../../../provider/AuthProvider";

/**
 * 측정을 위한 알림 및 카운트다운
 */
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={clsx('common-dialog-close', classes.closeButton)}   onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


export function StartMeasureDialog(props) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(()=>{
        setOpen(props.open)
    },[props.open])


    let title = '';
    if(0 === props.currentStep){
        title = '안정 측정';
    } else if(1 === props.currentStep){
        title = '주의력 측정';
    } else if(2 === props.currentStep){
        title = '집중력 측정';
    } else if(3 === props.currentStep){
        title = '뇌활성 측정';
    }
    return (
        <Dialog
            disableBackdropClick
            fullScreen={fullScreen}
            open={open}
            aria-labelledby="responsive-dialog-title"
            className={'innerViewport'}
        >
            <DialogTitle  className="common-dialog-title" onClose={() => {
                props.history.replace('/measureSelect')
            }}>
                {title}
            </DialogTitle>
            <DialogContent className={'common-dialog-content'}>
                뇌기능 균형상태를 측정합니다. <br/>
                편안한 마음으로 화면을 바라보시기 바랍니다. <br/>
                1분씩 안정, 주의력, 집중력, 뇌활성을 측정합니다. <br/>
                <br/>
                <ReactAudioPlayer
                    src={require(`@audio/01-0${props.currentStep + 6}.mp3`)}
                    autoPlay
                    controls
                    onEnded={()=>{
                        if(props.onHide){
                            props.onHide()
                        }
                    }}
                />
            </DialogContent>
            {!AuthProvider.isCustomerView() &&
            <DialogActions className={'common-dialog-action'}>
                <Button
                    autoFocus
                    fullWidth={true}
                    endIcon={<ArrowForwardIosIcon/>}
                    onClick={() => {
                        if (props.onHide) {
                            props.onHide()
                        }
                    }}>
                    측정시작
                </Button>
            </DialogActions>
            }
        </Dialog>
    );
}

export default StartMeasureDialog