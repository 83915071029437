import React, {useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import SocketProvider from '@user-provider/SocketProvider';

const DeviceStatus = () => {
    const { enqueueSnackbar } = useSnackbar();

    const addNotification = () => {
        enqueueSnackbar('기기와 연결을 확인하는 중입니다.', { variant: 'error'});
    };

    useEffect(()=>{
        SocketProvider.onDeviceStatus = (deviceStatus) => {
            switch (deviceStatus){
                case 'ERROR_CRC':
                    addNotification()
                    break;
                default:
            }
        }
        
        return () => {
            SocketProvider.onDeviceStatus = null
        }
    },[])
    
    return <></>
}

export default DeviceStatus;

