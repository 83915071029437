import React, {useEffect, useState, useRef, useCallback} from 'react';
import Box from '@material-ui/core/Box';
import PrescriptionService from '@user-service/PrescriptionService'
import SocketProvider from '@user-provider/SocketProvider';
import { useHistory } from "react-router-dom";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import CustomizedDialogs from "../../../components/CustomDialog";

const queryString = require('query-string');

export default function ExerciseStartDialog (props) {
    const history = useHistory()
    
    const params = queryString.parse(window.location.search);
    const encParams = params.p
    const [prescriptionExerciseID] = useState(params.prescriptionExerciseID)
    

    const [state, setState] = useState({
        open: true ,
        type: 'start',
        encParams,
        next: undefined,
        current: undefined
    })
    
    const timeout = () => {
        if(props.onStart){
          props.onStart(state.current)
        }
        
        setState({
            ...state,
            open: false,
        })
    }
    
    const fetch = useCallback(async () => {
        try {
            // const filter = prescriptionExercises.filter(ex => props.hzType === ex.programID)
            // const exercise = filter[0]
            if(undefined === prescriptionExerciseID ) return
            const current = await PrescriptionService.startPrescriptExercise(prescriptionExerciseID)
            setState({
                ...state,
                current
            })
            
            SocketProvider.onMeasureStopByAutoRun = null
            SocketProvider.onMeasureStopByAutoRun = async (data) => {
                const { hzType , measureCode } = data
                let nextInterval = 3000
                let endForWaitingTime = 0 
                try {
                    
                    if('meditation' === hzType || 'meditationHeal' === hzType || 'intoSleep' === hzType){
                        if('MB' !== measureCode){
                            return
                        }
                        
                        if('MB' === measureCode){
                            endForWaitingTime = 13 * 1000
                        }
                        // nextDelay = 1000 * 20
                    }    
                } catch (e) {}
                
                const next = await PrescriptionService.endPrescriptExercise(current.memberExerciseID, prescriptionExerciseID)

                
                // 영상이 끝날때까지 대기 시작을 기다립니다.
                setTimeout(() => {
                    setTimeout(() => {
                        if(next){
                            history.replace(`/exercise${next.programUrl}?prescriptionExerciseID=${next.prescriptionExerciseID}`)
                        } else {
                            history.replace(`/dashboard`)
                        }
                    }, nextInterval)
                }, endForWaitingTime)

            }
            
            setTimeout(() => {
                if(props.onUpdate && current){
                    props.onUpdate(current)
                }    
            }, 300)
            
        }catch(e){
        }
        
    },[encParams])
    
    useEffect(() => {
        fetch().then()
        
        return () => {
            SocketProvider.onMeasureStopByAutoRun = null
        }
    }, [fetch])
    
    
    const { current } = state
    if(!current) return <></> 
    
    return (
        <Box>
            <CustomizedDialogs
                open={state.open}
                disableBackdropClick
                title={'프로그램 시작'}
                content={
                    <React.Fragment>
                        {'start' === state.type && 
                        <Box textAlign={'center'}>
                            <Typography variant={'h6'}>자동으로 프로그램이 실행 됩니다
                            <br/>
                            잠시만 기다려 주세요
                            <br/>
                            </Typography>
                            <Box mt={4}>
                                <Typography variant={'h4'}>
                                    <TimeCounter
                                        current={state.current}
                                        time={5}
                                        onTimeout={timeout.bind(this)}
                                    />
                                </Typography>
                            </Box>
                            <Box mt={3} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <TableContainer style={{maxWidth: '80%'}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{textAlign: 'center'}}>훈련시간</TableCell>
                                                <TableCell style={{textAlign: 'center'}}>처방코드</TableCell>
                                                {/*<TableCell style={{textAlign: 'center', backgroundColor: '#efefef'}}>훈련모드</TableCell>*/}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{textAlign: 'center', fontWeight: 'bold', border: 0, fontSize: '1.2rem'}}>
                                                    {state.current.exerciseCount}
                                                    {state.current.defaultCountUnit == 'minutes' ? '분' : '회' }
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center', fontWeight: 'bold', border: 0, fontSize: '1.2rem'}}>
                                                    {state.current.measureCode ? state.current.measureCode : '기본'}
                                                </TableCell>
                                                {/*<TableCell style={{textAlign: 'center', fontWeight: 'bold', border: 0}}>{state.current.measureTypeCode}</TableCell>*/}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                        }

                        {'end' === state.type &&
                            <Box textAlign={'center'}>
                                <Typography variant={'h6'}> 다음 프로그램을 준비 중입니다
                                    <br/>
                                     잠시만 기다려주세요
                                    <br/>
                                </Typography>
                            </Box>
                        }

                    </React.Fragment>
                }
                submit={() => {
                    history.replace(`/dashboard`)
                }}
                buttonName={'종료'}
            />
            
            
        </Box>
    )
}

export function TimeCounter(props) {
    function useInterval(callback, delay) {
        const savedCallback = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    const [time, setTime] = useState(props.time)
    useInterval(() => {
        if(!props.current) return
        
        if(0 === time - 1){
            props.onTimeout()
        }
        setTime(time - 1)
    }, 0 < time ? 1000 : null)    

    return (
        <Box>{time}</Box>
    )
}
