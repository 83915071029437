import React, {createRef, useRef} from "react";
import {Box, Grid} from "@material-ui/core";
import BrainActiveTest from "../BrainActiveTest";
import DateHelper from '@helper/DateHelper'

import { Bar } from 'react-chartjs-2';
import _ from "lodash";
import ColorHelper from "../../../helper/ColorHelper";
import PrintHelper from "../../../helper/PrintHelper";
import PrescriptionService from "../../../services/PrescriptionService";


export class RealtimeMeasureChart extends React.Component {
  

  tickMaxY = 0;
  constructor(props) {
    super(props)

    this.chartRef1 = createRef();
    this.chartRef2 = createRef();
    this.printRef = createRef();
    
    this.state = {
      runningTime: props.runningTime,
      prescriptionID: parseInt(props.prescriptionID),
      measureCount: 1
    };
    
  }

  update(data) {
    this.addChartData(data.time, 
      [
      data.ch1Band,
      data.ch2Band
    ])
  }

  clear(withUpdate= false) {
    this.tickMaxY = 30;
    const chart1 = this.chartRef1.current.chartInstance;
    const chart2 = this.chartRef2.current.chartInstance;
    
    chart1.data.datasets.forEach((dataset) => {
      dataset.data = Array.from({ length: 90 }, () =>  0)
    });

    chart2.data.datasets.forEach((dataset) => {
      dataset.data = Array.from({ length: 90 }, () =>  0)
    });

    chart1.update();
    chart2.update();
  }

  updateChart(){
    this.setState({
      ...this.state,
      measureCount: this.state.measureCount + 1
    })
  }

  addChartData = (time, bandData) => {
    const chart1 = this.chartRef1.current.chartInstance;
    const chart2 = this.chartRef2.current.chartInstance;

    
    // 두 차트의 데이터 중 최대값을 찾습니다.
    // 각 데이터셋의 합계를 계산합니다.
    const sumDataSet1 = bandData[0]
      // .map((v) => {
      //   return v > 50 ? 50 : v 
      // })
      .reduce((acc, value) => acc + Number(value), 0);
    
    const sumDataSet2 = bandData[1]
      // .map((v) => {
      //   return v > 50 ? 50 : v
      // })
      .reduce((acc, value) => acc + Number(value), 0);

    // 두 합계 중 더 큰 값을 찾습니다.
    let maxDataValue = Math.max(sumDataSet1, sumDataSet2);

    // 최대값을 50단위로 올림하고 50을 추가합니다.
    let newMax = Math.ceil((maxDataValue + 1) / 30) * 30;

    if(this.tickMaxY < newMax) {
      this.tickMaxY = newMax
    } else {
      newMax = this.tickMaxY
    }
    
    let bandDataSet = bandData[0];
    let each = 0;
    chart1.data.datasets.forEach((dataset, index) => {
      each = Number(bandDataSet[index]);
   
      dataset.data.unshift(each);
      dataset.data.pop();
    });
    
    // let labelTime =  1 < time && time % 30 === 0 ?  time + 's' : '' ;
    //if(1 == time) labelTime = time + 's';
    
    // chart1.data.labels.unshift(labelTime);
    // chart1.data.labels.pop();
    
    chart1.options.scales.yAxes[0].ticks.max = newMax;
    chart1.update();

    bandDataSet = bandData[1];
    chart2.data.datasets.forEach((dataset, index) => {
      each = Number(bandDataSet[index]) * -1;
      dataset.data.unshift(each);
      dataset.data.pop();
    });
    chart2.options.scales.yAxes[0].ticks.min = newMax * -1;
    chart2.update();
  };



  componentDidMount() {
  }

  
  async saveImage(fileKey) {

    try {
      const ch1Image = await PrintHelper.getPNGImage(this.printRef)

      const params = {
        ch1Image,
        // ch2Image,
        fftType: 'barchart',
        fileKey
      }
      await PrescriptionService.fileUploadFFT(this.state.prescriptionID, params);
      
      this.clear();
    } catch(e) {}
    
  }
  
  getData(type){
     
    const colors = ['#0253A4', '#FF8653',  '#4ECDC4', '#E8D500',  '#F22D20',  '#800000', '#060126']
    const defaultLabel = [
      {label: 'δ' , color: colors[0]},
      {label: 'θ' , color: colors[1]},
      {label: 'α' , color: colors[2]},
      {label: 'SMR' , color: colors[3]},
      {label: 'β' , color: colors[4]},
      {label: 'βM' , color: colors[5]},
      {label: 'βh', color: colors[6] }
    ]
    if('left' === type) {
      return {
        labels: Array.from({ length: 90 }, () =>  '') ,// ,
        datasets: defaultLabel.map((v) => {
          return   {
            label: v.label,
            data: Array.from({ length: 90 }, () => ''),
            backgroundColor: v.color,
          }
        })
      }
    } else {
      
      return {
        labels: Array.from({ length: 90 }, () =>  '') ,// ,
        datasets: defaultLabel.map((v) => {
          return   {
            label: v.label,
            data: Array.from({ length: 90 }, () => ''),
            backgroundColor: v.color
          }
        })
      }
    }
  }
  
  getChartOption(type){
    if('left' === type) {
      return {
          legend: {
            labels: {
              fontSize: 18,
              fontStyle: 'bold', // 폰트 두께 설정
            },
            
          },
          scales: {
            xAxes: [{
              stacked: true,
              // callback: function(value, index, values) {
              //   if( 0 === index) return (index + 1).toString() + 's'
              //   else if ( 30 % (index + 1) === 0 ) return (index + 1).toString() + 's'
              //   else return '' ;
              // },
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                fontSize: 16, // 폰트 크기를 원하는 값으로 설정
                fontStyle: 'bold', // 폰트 두께 설정
                min: 0, // y축 최소값
                max: 30, // y축 최대값
              },
            }],
          },
          maintainAspectRatio: false,
      }
    } else {
      return {
        legend: {
          display: false,
          position: 'bottom' // 레이블을 차트 아래에 표시
        },
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              fontSize: 16, // 폰트 크기를 원하는 값으로 설정
              fontStyle: 'bold', // 폰트 두께 설정
              min: -30, // y축 최소값
              max: 0, // y축 최대값
              callback: function(value, index, values) {
                return Math.abs(value).toString();
              }
            },
            
          }],
        },
        maintainAspectRatio: false,
      }
    }
  }

  render() {
    const { measureCount } = this.state
    return (
      <Box>
        <Box padding={2} style={{padding: 20}}>
          <Grid ref={this.printRef} container style={{width: '100%', marginBottom: 40, position:'relative', marginTop: 40}}>
            <Grid item xs={12} >
              <div style={{ height: 300, position: 'relative'}}>
                <div style={{position: 'absolute', left: 40, top: 0, fontWeight: 'bold', fontSize: '0.9rem'}}> LEFT BRAIN </div>
                <Bar ref={this.chartRef1}  data={this.getData('left')} options={this.getChartOption('left')} />
              </div>
              <div style={{paddingLeft: 35, marginBottom: 15}}>
                <div>
                  <Grid container>
                    <Grid item xs={3} style={{textAlign: 'left',fontSize: '1.1rem', fontWeight: 'bold'}}>
                      <span>90s</span>
                    </Grid>
                    <Grid item xs={3} style={{textAlign: 'center',fontSize: '1.1rem', fontWeight: 'bold'}}>
                      <span>60s</span>
                    </Grid>
                    <Grid item xs={3} style={{textAlign: 'center',fontSize: '1.1rem', fontWeight: 'bold'}}>
                      <span>30s</span>
                    </Grid>
                    <Grid item xs={3} style={{textAlign: 'right',fontSize: '1.1rem', fontWeight: 'bold'}}>
                      <span>1s</span>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div style={{ height: 300,  position: 'relative'}}>
                <Bar ref={this.chartRef2}  data={this.getData('right')} options={this.getChartOption('right')} />
                <div style={{position: 'absolute', left: 40, bottom: -14, fontWeight: 'bold', fontSize: '0.9rem'}}> RIGHT BRAIN </div>
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box style={{width: '100%'}}>
          <Box m={'auto'} width={'90%'}>
            <BrainActiveTest
              createdAt={DateHelper.now('YYYY-MM-DD HH:mm:ss')}
              measureCount={measureCount}
            />
          </Box>
        </Box>
      </Box>
    )
  }
}