import React, {useState, useEffect, useCallback} from 'react';
import ProfileService from '@user-service/ProfileService'
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {
    Box, Typography, Grid, FormControl,
    TextField, Select, MenuItem, 
    Button, Radio, RadioGroup, FormControlLabel
} from '@material-ui/core';

import ProfileBox from "./ProfileBox";
import {useLocation} from "react-router-dom";
import CustomizedDialogs from "../../components/CustomDialog";
import CustomerDataMoveDialog from "../manage/customer/CustomerDataMoveDialog";
import AuthProvider from "../../provider/AuthProvider";

const CssTextField = withStyles({
    root: {
        color: 'white',

        '& .MuiInput-input': {
            color: 'white'  ,
            height: '60px !important',
            paddingLeft: 10
        },
        '& label': {
            color: 'white',
            paddingLeft: 10,
        },
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ddd',
            },
        },
    },
})(TextField);

const CustomTextField = (props) => {
    const classes = useStyles()
    return (
        <React.Fragment>
            <Box className={classes.label}>{props.subtitle}</Box>
            <Box className={classes.textWrap}>
                <CssTextField
                    {...props}
                />
            </Box>
        </React.Fragment>

    )
}

const EditProfile = ({match, history}) => {
    const { profileID } = match.params
    
    const location = useLocation()
    const { fileName } = location.state ? location.state : {}
    
    const [profile, setProfile] = useState(null)
    const [open, setOpen] = useState(false)
    const [openDataMove, setOpenDataMove] = useState(false)
    const classes = useStyles();
    const isNewProfile = 'new' === profileID
    
    
    const fetchProfile = useCallback(async () => {
        const res = await ProfileService.getProfile(profileID)
        
        if(fileName){
            res.data.image = fileName
        }
        setProfile({
            ...res.data,
        })
    }, [profileID, fileName])

    useEffect(()=>{
        if(!isNewProfile) {
            fetchProfile().then()
        } else {
            setProfile({
                profileType: 'person',
                image: fileName
            })
        }
    },[fetchProfile])


    //프로필 저장
    const saveProfile = async () => {
        if(isNewProfile){
            await ProfileService.addProfile(profile)
        } else {
            await ProfileService.updateProfile(profile)
        }
        history.replace('/profile');
    }
    //취소
    const cancelHandler = () => {
        history.replace('/profile');
    }

    if(!profile){
        return ( <Box className={classes.root} />)
    }

    return (
        <React.Fragment>
            <Box className={classes.background} />
            <Box className={classes.root}>
                <Box>
                    <Typography variant="h5" component="h3" className={classes.title}>
                        {isNewProfile ? '프로필 등록' : '프로필 수정'}
                    </Typography>
                </Box>

                <Grid container justify="center" spacing={3} className={classes.profileWrap}>
                    {AuthProvider.isAdmin() && !isNewProfile &&
                    <Grid item xs={12}>
                        <Box display={'flex'} justifyContent={'right'}>
                            <Button
                                style={{
                                    border: '1px solid #4B4C5A',
                                    color: 'white'
                                }}
                                variant={'outlined'}
                                onClick={() => {
                                    setOpenDataMove(true)
                                }}>프로필 이전</Button>
                        </Box>
                    </Grid>
                    }
                    <Grid item xs={4}>
                        <ProfileBox {...profile} isEditMode={true} from={'single'} fileName={fileName}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <CustomTextField
                                    fullWidth
                                    subtitle={'이름'}
                                    defaultValue={profile.profileName}
                                    inputProps={{
                                        maxLength: 10
                                    }}
                                    onChange={(event)=>{
                                        setProfile({
                                            ...profile,
                                            profileName: event.target.value
                                        })
                                    }} />
                            </Grid>
                            <Grid item>
                                <label className={classes.label}> 생년월일 </label>
                                <CustomTextField
                                    fullWidth
                                    value={profile.birthDate}
                                    type="date"
                                    inputProps={{
                                        maxLength: 10
                                    }}
                                    onChange={(event)=>{
                                        setProfile({
                                            ...profile,
                                            birthDate: event.target.value
                                        })
                                    }} />

                            </Grid>
                            <Grid item>
                                <FormControl className={classes.formControl} variant="outlined"  style={{width: '100%', color:'white'}}>
                                    <Box className={classes.label}>사용자구분</Box>
                                    <Select
                                        className={classes.select}
                                        value={ profile.profileType ? profile.profileType : 'person'}
                                        onChange={(event)=>{
                                            setProfile({
                                                ...profile,
                                                profileType: event.target.value
                                            })
                                        }}>
                                        <MenuItem value={'children'}>어린이</MenuItem>
                                        <MenuItem value={'person'}>일반인</MenuItem>
                                        <MenuItem value={'patient'}>환자</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl component="fieldset" fullWidth>
                                    <Box className={classes.label}>사용자 성별</Box>
                                    <RadioGroup
                                        row
                                        aria-label="position"
                                        name="position"
                                        value={profile.gender}
                                        style={{borderColor: '#fff'}}
                                        onChange={(event)=>{
                                            setProfile({
                                                ...profile,
                                                gender: event.target.value
                                            })
                                        }}>

                                        <FormControlLabel
                                            value="male"
                                            control={<Radio color="primary" />}
                                            label="남성"
                                            className={classes.input}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="female"
                                            control={<Radio color="primary" />}
                                            label="여성"
                                            className={classes.input}
                                            labelPlacement="end"
                                        />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box className={classes.btnWrap}>
                    <Grid container spacing={1} justify={'center'} style={{width: '100%'}}>
                        {!isNewProfile &&
                        <Grid item>
                            <Button
                                variant="contained"
                                className="basic delete"
                                onClick={() => {
                                    setOpen(true)
                                }}>삭제</Button>
                        </Grid>
                        }
                        <Grid item>
                            <Button variant="contained" className="basic active" onClick={saveProfile} >저장</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" className="basic" onClick={cancelHandler} >취소</Button>
                        </Grid>
                    </Grid>
                    
                </Box>
            </Box>
            <CustomerDataMoveDialog 
                open={openDataMove}
                profile={profile}
                onClose={(isMoved) => {
                    setOpenDataMove(false)
                    
                    if(isMoved){
                        history.replace('/profile');  
                    } 
                }} />
            
            <CustomizedDialogs
                key={Math.random()}
                open={open}
                title={'프로필 삭제'}
                content={
                    <React.Fragment>
                        <Typography gutterBottom className={classes.text}>
                            <b>{profile.profileName}</b><br/>
                            선택하신 프로필을 삭제하시겠습니까?
                        </Typography>
                        <br/>
                        <Typography gutterBottom className={classes.text}>
                            <span style={{color:'red'}}>
                            측정 기록, 처방기록, 훈련 기록등 사용자의 모든 기록이 삭제됩니다.
                                <br/>
                            삭제하시겠습니까?
                            </span>
                            
                            <br/>
                            <br/>
                            <b>삭제된 프로필 정보는 복구 할 수 없습니다</b>
                        </Typography>
                    </React.Fragment>
                }
                data={profile}
                close={() => {
                    setOpen(false)
                }}
                submit={async (profile) => {
                    const isSuccess = await ProfileService.deleteProfile(profile.id)
                    if(!isSuccess){
                        setOpen(false)
                        alert('프로필 삭제에 실패했습니다. 잠시후에 다시 시도해보세요')
                        
                    } else {
                        history.replace('/profile');
                    }
                }}
                buttonName={'삭제'}
            />
        </React.Fragment>
        
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center",
        height: '100%',
        paddingTop: 100,
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: -1,
        width: `100%`,
        height: '100vh',
        backgroundColor: '#1E2030',
    },
    title: {
        color: 'white',
        textAlign: 'center',
        marginBottom: 100,
        fontSize: 42
    },
    logo: {
        position: 'fixed',
        left: 30 ,
        top: 22,
        zIndex: 0,
        width: `100%`,
    },
    textWrap: {
        backgroundColor: '#4B4D5A',
        borderRadius: 5,
        marginBottom: 15
    },
    profileWrap: {
        width: 670,
        margin: 'auto',
    },
    label: {
        color: '#666',
        fontSize: 14,
        paddingBottom: 10,
        fontWeight: 500
    },
    input: {
        color: 'white'
    },
    square: {
        width: '100%',
        height: '100%',
        borderRadius: 5
    },
    control: {
        padding: theme.spacing(3),
    },
    btnWrap: {
        marginTop: 100,
        paddingBottom: 100,
        '& .basic': {
            width: 200,
            height: 68,
            border: '1px solid #4B4C5A',
            color: 'white'
        },
        '& .basic.active': {
            color: '#333',
            backgroundColor: 'white'
        },
        '& .basic.delete': {
            color: '#fff',
            backgroundColor: 'red'
        }
    },

    formControl: {
        width: '65%'
    },
    select: {
        '& .MuiSelect-icon': {
            color: '#fff'
        },
        "& .MuiSelect-root": {
            color: 'white',
            border: '1px solid #4B4C5A',
            '&:hover' : {
                border: '1px solid #2F2F3A',
            },
            '&:focused' : {
                border: '1px solid #2F2F3A',
            },
          
        }
    }
}));



export default EditProfile
