import { combineReducers } from 'redux'
import memberReducer from '@reducers/member'
import DeviceReducer from '@reducers/DeviceReducer'


import UserAppReducer from '@reducers/UserAppReducer'

export default combineReducers({
    memberReducer,
    DeviceReducer,
    UserAppReducer
})
