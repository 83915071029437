import React, { useState, useRef,forwardRef } from 'react';
import { useHistory } from "react-router-dom";
import { useCookies } from 'react-cookie';

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography, Grid, Link} from '@material-ui/core';
import CssTextField from '@user-components/CssTextField';
import clsx from "clsx";



const LoginHelp = () => {

    const classes = useStyles();
    const [ableNext, setAbleNext] = useState(false)
    const idRef = useRef()
    const passwordRef = useRef()
    const history = useHistory()
    
    return (
        <React.Fragment>
            <Box className={classes.background} />
            <Box className={classes.loginBox}>
                <Typography className={classes.title}>
                    <span style={{fontWeight: 600}}>기억이 안나시나요?</span><br/>
                </Typography>
                <Typography className={classes.subtitle}>
                    아이디, 생년월일 및 휴대폰 번호를 <br/>입력해주세요.
                </Typography>

                {/* 로그인 입력  */ }
                    <Box style={{marginTop: 40, marginBottom: 100}}>
                        <form noValidate autoComplete="off" >
                            <Grid container direction={'column'} spacing={2}>
                                <Grid item xs={12}>
                                    <BirthDate />
                                </Grid>
                                <Grid item xs={12}>
                                    <UserIdTextField ref={idRef} update={(isValidate) => {
                                        if(isValidate && false === ableNext){
                                            setAbleNext(true)
                                        } else if(false === isValidate && ableNext){
                                            setAbleNext(false)
                                        }
                                    }}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <PasswordTextField ref={passwordRef} />
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: 60}}>
                                <Grid item xs={12}>
                                    <Button
                                        disabled={!ableNext}
                                        className={clsx(classes.registerButton, {
                                            [classes.nextAble]: ableNext,
                                            [classes.next]: !ableNext
                                        })}>다음</Button>
                                    
                                    <Box className={classes.helpText}>
                                        아이디, 패스워드 찾기가 어려우신 경우에는 고객센터로 문의해주세요
                                    </Box>
                                    <Box className={classes.phoneNumber}>
                                        02-2222-2222
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
            </Box>
        </React.Fragment>
    );
}



const UserIdTextField = forwardRef((props , ref) => {
    const [, setValue] = useState('')
    const [helpText ] = useState('')
    const [isError ] = useState(false)

    return <CssTextField label="아이디"
                         inputRef={ref}
                         fullWidth
                         onChange={(event)=>{
                             setValue(event.target.value)
                             if(3 < event.target.value.length){
                                 props.update(true)
                             } else {
                                 props.update(false)
                             }
                         }}
                         error={isError}
                         helperText={helpText}
    />
})

const PasswordTextField = forwardRef((props, ref) => {
    const [value, setValue] = useState('')
    const [helpText] = useState('.')
    const [isError] = useState(false)

    return (
        <CssTextField
            label="비밀번호"
            inputRef={ref}
            fullWidth
            type={'password'}
            onChange={(event)=>{
                setValue(event.target.value)
            }}
            onKeyUp={(event) => {
                if('Enter' === event.key ){
                    if(props.onLoginHandler){
                        props.onLoginHandler()
                    }
                    return false
                }
            }}
            error={isError}
            helperText={helpText}
        />
    )
})

const BirthDate = forwardRef((props, ref) => {
    const [value, setValue] = useState('')
    const [helpText] = useState(null)
    const [isError] = useState(false)

    return (
        <CssTextField
            label="생년월일"
            inputRef={ref}
            fullWidth
            type={'text'}
            onChange={(event)=>{
                setValue(event.target.value)
            }}
            onKeyUp={(event) => {
                if('Enter' === event.key ){
                    if(props.onLoginHandler){
                        props.onLoginHandler()
                    }
                    return false
                }
            }}
            error={isError}
            helperText={helpText}
        />
    )
})




const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center"
    },
    title: {
        fontSize: 40,
        fontFamily: 'Noto Sans KR',
        color: '#333'
    },
    subtitle: {
        fontSize: 26,
        fontFamily: 'Noto Sans KR',
        color: '#666',
        marginBottom: 75,
    },
    
    registerButton: {
        position: 'relative',
        width: '100%',
        height: 60,
        borderRadius: 10,
        color: '#333',
        fontSize: 19,
    },
   
    next: {
        backgroundColor: '#ddd',
        color: '#999'
    },
    nextAble: {
        backgroundColor: '#0053BF',
        color: 'white'
    },
    formBox: {
        marginBottom: 60
    },
    background: {
        position: 'absolute',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
        backgroundImage: `url(${require(`@assets/images/simplebg01.jpg`)})`,
        backgroundSize: 'cover'
    },
    loginBox: {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        position: 'absolute',
        width: 400,
        minHeight: 670,
        zIndex: 2,
        borderRadius: 10,
        backgroundColor: '#fff',
        padding: 50,
        paddingTop: 75,
    },
    helpText: {
        fontSize: 14,
        width: '100%',
        textAlign: 'center',
        marginTop: 20,
        color: '#333',
    },
    phoneNumber: {
        width: '100%',
        textAlign: 'center',
        fontWeight:'bold',
        fontSize: 18,
        marginTop: 20,
        color: '#2F64C0',
    }
}));

//for redux 
LoginHelp.propType = {
    text: PropTypes.string.isRequired
}

export default LoginHelp
