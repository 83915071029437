module.exports = {
    ViewChar: {
        'view1': {
            data: [0,1,2,3,4,5,6,7,8,9],
            type: 'text',
            cogBoxBackground: '#C8E6C9',
            cogItemBackground: '#98D194'
        },
        'view2': {
            data: ['Ḋ','Ḳ','Ṻ','Ṝ','Ệ','Ộ','Ḛ','Ḇ','Ṩ','Ậ','Ṋ','Ṏ','Ḅ','Ẅ','Ẍ'],
            type: 'text',
            cogBoxBackground: '#C8E6C9',
            cogItemBackground: '#98D194'
        },
        'view3': {
            data: [
                require(`@assets/exercise/cognitive/c1.png`),
                require(`@assets/exercise/cognitive/c2.png`),
                require(`@assets/exercise/cognitive/c3.png`),
                require(`@assets/exercise/cognitive/c4.png`),
                require(`@assets/exercise/cognitive/c5.png`),
                require(`@assets/exercise/cognitive/c6.png`),
                require(`@assets/exercise/cognitive/c7.png`),
                require(`@assets/exercise/cognitive/c8.png`),
                require(`@assets/exercise/cognitive/c9.png`),
                require(`@assets/exercise/cognitive/c10.png`),
            ],
            type: 'image',
            cogBoxBackground: '#C8E6C9',
            cogItemBackground: '#98D194'
        },
        'view4': {
            data: ['♬','✞','☢','✂','✈','✎','☂','♡','✉','✭'],
            type: 'text',
            cogBoxBackground: '#C8E6C9',
            cogItemBackground: '#98D194'
        },
        'view5': {
            data: [
                require(`@assets/exercise/cognitive/o1.png`),
                require(`@assets/exercise/cognitive/o5.png`),
                require(`@assets/exercise/cognitive/o2.png`),
                require(`@assets/exercise/cognitive/o7.png`),
                require(`@assets/exercise/cognitive/o3.png`),
                require(`@assets/exercise/cognitive/o9.png`),
                require(`@assets/exercise/cognitive/o4.png`),
                require(`@assets/exercise/cognitive/o10.png`),
                require(`@assets/exercise/cognitive/o6.png`),
                require(`@assets/exercise/cognitive/o12.png`),
                require(`@assets/exercise/cognitive/o8.png`),
                require(`@assets/exercise/cognitive/o13.png`),
                require(`@assets/exercise/cognitive/o11.png`),
                require(`@assets/exercise/cognitive/o14.png`),
                require(`@assets/exercise/cognitive/o16.png`),
                require(`@assets/exercise/cognitive/o15.png`),
                require(`@assets/exercise/cognitive/o21.png`),
                require(`@assets/exercise/cognitive/o17.png`),
                require(`@assets/exercise/cognitive/o20.png`),
                require(`@assets/exercise/cognitive/o18.png`),
                require(`@assets/exercise/cognitive/o22.png`),
                require(`@assets/exercise/cognitive/o25.png`),

                require(`@assets/exercise/memoryComplex/et_1.png`),
                require(`@assets/exercise/memoryComplex/et_2.png`),
                require(`@assets/exercise/memoryComplex/et_3.png`),
                require(`@assets/exercise/memoryComplex/et_4.png`),
                require(`@assets/exercise/memoryComplex/et_5.png`),
                require(`@assets/exercise/memoryComplex/et_6.png`),
                require(`@assets/exercise/memoryComplex/et_7.png`),
                require(`@assets/exercise/memoryComplex/et_8.png`),
                require(`@assets/exercise/memoryComplex/et_9.png`),
                require(`@assets/exercise/memoryComplex/et_10.png`),
                
                // require(`@assets/exercise/cognitive/o21.png`),
                // require(`@assets/exercise/cognitive/o23.png`),
                // require(`@assets/exercise/cognitive/o24.png`),
                // require(`@assets/exercise/cognitive/o26.png`),
                
            ],
            meta: [
                {type:['생물'], imageId: 1, name:'거북이', sound: require(`@assets/exercise/memoryComplex/sound/s_1.mp3`)},
                {type:['물건'], imageId: 5, name:'모자', sound: require(`@assets/exercise/memoryComplex/sound/s_5.mp3`)},
                {type:['생물'], imageId: 2, name:'생선', sound: require(`@assets/exercise/memoryComplex/sound/s_2.mp3`)},
                {type:['물건'], imageId: 7, name:'보트', sound: require(`@assets/exercise/memoryComplex/sound/s_7.mp3`)},
                {type:['생물'], imageId: 3, name:'게', sound: require(`@assets/exercise/memoryComplex/sound/s_3.mp3`)},
                {type:['물건'], imageId: 9, name:'슬리퍼', sound: require(`@assets/exercise/memoryComplex/sound/s_9.mp3`)},
                {type:['생물'], imageId: 4, name:'돌고레', sound: require(`@assets/exercise/memoryComplex/sound/s_4.mp3`)},
                {type:['물건'], imageId: 10, name:'선글래스', sound: require(`@assets/exercise/memoryComplex/sound/s_10.mp3`)},
                {type:['생물'], imageId: 6, name:'문어', sound: require(`@assets/exercise/memoryComplex/sound/s_6.mp3`)},
                {type:['물건'], imageId: 12, name:'물안경', sound: require(`@assets/exercise/memoryComplex/sound/s_12.mp3`)},
                {type:['생물'], imageId: 8, name:'불가사리', sound: require(`@assets/exercise/memoryComplex/sound/s_8.mp3`)},
                {type:['물건'], imageId: 13, name:'수영복', sound: require(`@assets/exercise/memoryComplex/sound/s_13.mp3`)},
                {type:['생물'], imageId: 11, name:'소라', sound: require(`@assets/exercise/memoryComplex/sound/s_11.mp3`)},
                {type:['물건'], imageId: 14, name:'물수경', sound: require(`@assets/exercise/memoryComplex/sound/s_14.mp3`)},
                {type:['생물'], imageId: 16, name:'오징어', sound: require(`@assets/exercise/memoryComplex/sound/s_16.mp3`)},
                {type:['물건'], imageId: 15, name:'오리발', sound: require(`@assets/exercise/memoryComplex/sound/s_15.mp3`)},
                {type:['생물'], imageId: 21, name:'바닷가재', sound: require(`@assets/exercise/memoryComplex/sound/s_21.mp3`)},
                {type:['물건'], imageId: 17, name:'튜브', sound: require(`@assets/exercise/memoryComplex/sound/s_17.mp3`)},
                {type:['생물'], imageId: 20, name:'가오리', sound: require(`@assets/exercise/memoryComplex/sound/s_20.mp3`)},
                {type:['물건'], imageId: 18, name:'파라솔', sound: require(`@assets/exercise/memoryComplex/sound/s_18.mp3`)},
                {type:['생물'], imageId: 22, name:'새우', sound: require(`@assets/exercise/memoryComplex/sound/s_22.mp3`)},
                {type:['생물'], imageId: 25, name:'파랑물고기', sound: require(`@assets/exercise/memoryComplex/sound/s_25.mp3`)},

                {type:['먹을것'], imageId: 26, name:'요거트', sound: require(`@assets/exercise/memoryComplex/sound/s_26.mp3`)},
                {type:['먹을것'], imageId: 27, name:'식빵', sound: require(`@assets/exercise/memoryComplex/sound/s_27.mp3`)},
                {type:['먹을것'], imageId: 28, name:'치킨', sound: require(`@assets/exercise/memoryComplex/sound/s_28.mp3`)},
                {type:['먹을것'], imageId: 29, name:'자장면', sound: require(`@assets/exercise/memoryComplex/sound/s_29.mp3`)},
                {type:['먹을것'], imageId: 30, name:'삼겹살', sound: require(`@assets/exercise/memoryComplex/sound/s_30.mp3`)},
                {type:['먹을것'], imageId: 31, name:'파스타', sound: require(`@assets/exercise/memoryComplex/sound/s_31.mp3`)},
                {type:['먹을것'], imageId: 32, name:'아이스크림', sound: require(`@assets/exercise/memoryComplex/sound/s_32.mp3`)},
                {type:['먹을것'], imageId: 33, name:'피자', sound: require(`@assets/exercise/memoryComplex/sound/s_33.mp3`)},
                {type:['먹을것'], imageId: 34, name:'햄버거', sound: require(`@assets/exercise/memoryComplex/sound/s_34.mp3`)},
                {type:['먹을것'], imageId: 35, name:'돈까스', sound: require(`@assets/exercise/memoryComplex/sound/s_35.mp3`)},
              
                // {type:['생물'], imageId: 19, name:'해파리'},
                // {type:['생물'], imageId: 23, name:'소라'},
                // {type:['생물'], imageId: 24, name:'인어공주'},
                // {type:['생물'], imageId: 26, name:'해마'},
            ],
            useReverse: false,
            type: 'image',
            cogBoxBackground: '#0A45B5',
            cogItemBackground: '#4AA3F5',
            sortCogItem: true,
            // cogItemBoxStyle: {
            //   border: 8,
            //   borderColor: '#ddd',
            //   borderStyle: 'solid',
            //   padding: 10
            // }
        },
        'view5-s': {
          data: [
            require(`@assets/exercise/memoryComplex/m_sf_1.png`),
            require(`@assets/exercise/memoryComplex/m_sf_2.png`),
            require(`@assets/exercise/memoryComplex/m_sf_3.png`),
            require(`@assets/exercise/memoryComplex/m_sf_4.png`),
            require(`@assets/exercise/memoryComplex/m_sf_5.png`),
            require(`@assets/exercise/memoryComplex/m_sf_6.png`),
            require(`@assets/exercise/memoryComplex/m_sf_7.png`),
            require(`@assets/exercise/memoryComplex/m_sf_8.png`),
            require(`@assets/exercise/memoryComplex/m_sf_9.png`),
            require(`@assets/exercise/memoryComplex/m_sf_10.png`),
            require(`@assets/exercise/memoryComplex/m_sf_11.png`),
            require(`@assets/exercise/memoryComplex/m_sf_12.png`),
            require(`@assets/exercise/memoryComplex/m_sf_13.png`),
            require(`@assets/exercise/memoryComplex/m_sf_14.png`),
            require(`@assets/exercise/memoryComplex/m_sf_15.png`),
            require(`@assets/exercise/memoryComplex/m_sf_16.png`),
            require(`@assets/exercise/memoryComplex/m_sf_17.png`),
          ],
          dataSub: [
            require(`@assets/exercise/memoryComplex/s_sf_1.png`),
            require(`@assets/exercise/memoryComplex/s_sf_2.png`),
            require(`@assets/exercise/memoryComplex/s_sf_3.png`),
            require(`@assets/exercise/memoryComplex/s_sf_4.png`),
            require(`@assets/exercise/memoryComplex/s_sf_5.png`),
            require(`@assets/exercise/memoryComplex/s_sf_6.png`),
            require(`@assets/exercise/memoryComplex/s_sf_7.png`),
            require(`@assets/exercise/memoryComplex/s_sf_8.png`),
            require(`@assets/exercise/memoryComplex/s_sf_9.png`),
            require(`@assets/exercise/memoryComplex/s_sf_10.png`),
            require(`@assets/exercise/memoryComplex/s_sf_11.png`),
            require(`@assets/exercise/memoryComplex/s_sf_12.png`),
            require(`@assets/exercise/memoryComplex/s_sf_13.png`),
            require(`@assets/exercise/memoryComplex/s_sf_14.png`),
            require(`@assets/exercise/memoryComplex/s_sf_15.png`),
            require(`@assets/exercise/memoryComplex/s_sf_16.png`),
            require(`@assets/exercise/memoryComplex/s_sf_17.png`),
          ],
          dataSubSingle: [
            require(`@assets/exercise/memoryComplex/m1_sf_1.png`),
            require(`@assets/exercise/memoryComplex/m1_sf_2.png`),
            require(`@assets/exercise/memoryComplex/m1_sf_3.png`),
            require(`@assets/exercise/memoryComplex/m1_sf_4.png`),
            require(`@assets/exercise/memoryComplex/m1_sf_5.png`),
            require(`@assets/exercise/memoryComplex/m1_sf_6.png`),
            require(`@assets/exercise/memoryComplex/m1_sf_7.png`),
            require(`@assets/exercise/memoryComplex/m1_sf_8.png`),
            require(`@assets/exercise/memoryComplex/m1_sf_9.png`),
            require(`@assets/exercise/memoryComplex/m1_sf_10.png`),
            require(`@assets/exercise/memoryComplex/m1_sf_11.png`),
            require(`@assets/exercise/memoryComplex/m1_sf_12.png`),
            require(`@assets/exercise/memoryComplex/m1_sf_13.png`),
            require(`@assets/exercise/memoryComplex/m1_sf_14.png`),
            require(`@assets/exercise/memoryComplex/m1_sf_15.png`),
            require(`@assets/exercise/memoryComplex/m1_sf_16.png`),
            require(`@assets/exercise/memoryComplex/m1_sf_17.png`),
          ],
          meta: [
            {type:['빨간색'],    imageId: 1,   name:'빨간색',  level:'초급' ,color: 'rgba(204, 40, 40, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_red.mp3`)},
            {type:['회색'],     imageId: 2,   name:'회색',                 color: 'rgba(83, 83, 83, 1)' , sound: require(`@assets/exercise/memoryComplex/sound/s_gray.mp3`)},
            {type:['옥색'],     imageId: 3,   name:'옥색',                 color: 'rgba(145, 196, 174, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_jade_green.mp3`)},
            {type:['연녹색'],    imageId: 4,   name:'연녹색',               color: 'rgba(142, 186, 71, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_green_2.mp3`)},
            {type:['연분홍색'],   imageId: 5,   name:'연분홍색',             color: 'rgba(238, 145, 180, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_pink_2.mp3`)},
            {type:['노랑색'],    imageId: 6,   name:'노랑색',  level:'초급', color: 'rgba(245, 226, 1, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_yellow_1.mp3`)},
            {type:['보라색'],    imageId: 7,   name:'보라색',  level:'초급', color: 'rgba(125, 25, 209, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_purple_1.mp3`)},
            {type:['진녹색'],    imageId: 8,   name:'진녹색',  level:'초급', color: 'rgba(9, 103, 29, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_green_1.mp3`)},
            {type:['갈색'],     imageId: 9,   name:'갈색',  level:'초급',   color: 'rgba(93, 62, 36, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_brown.mp3`)},
            {type:['황토색'],    imageId: 10,  name:'황토색',               color: 'rgba(200, 158, 76, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_brown_2.mp3`)},
            {type:['하늘색'],    imageId: 11,  name:'하늘색',               color: 'rgba(93, 195, 217, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_sky_blue.mp3`)},
            {type:['주황색'],    imageId: 12,  name:'주황색',  level:'초급', color: 'rgba(237, 133, 35, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_orange.mp3`)},
            {type:['파랑색'],    imageId: 13,  name:'파랑색',  level:'초급', color: 'rgba(0, 108, 187, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_blue.mp3`)},
            {type:['검정색'],    imageId: 14,  name:'검정색',               color: 'rgba(0, 0, 0, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_black.mp3`)},
            {type:['연보라색'],   imageId: 15,  name:'연보라색',             color: 'rgba(110, 115, 231, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_brown.mp3`)},
            {type:['분홍색'],    imageId: 16,  name:'분홍색',  level:'초급', color: 'rgba(240, 20, 104, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_pink_1.mp3`)},
            {type:['흰색'],      imageId: 17,  name:'흰색',  level:'초급',  color: 'rgba(255, 255, 255, 1)', textColor:'rgba(100,100,100,1)', sound: require(`@assets/exercise/memoryComplex/sound/s_white.mp3`)},
            
            // {type:['생물'], imageId: 19, name:'해파리'},
            // {type:['생물'], imageId: 23, name:'소라'},
            // {type:['생물'], imageId: 24, name:'인어공주'},
            // {type:['생물'], imageId: 26, name:'해마'},
          ],
          type: 'image',
          cogBoxBackground: '#0A45B5',
          cogItemBackground: '#4AA3F5',
          sortCogItem: true
        },
      
        'view6': {
            data: [
                require(`@assets/exercise/cognitive/s1.png`),
                require(`@assets/exercise/cognitive/s2.png`),
                require(`@assets/exercise/cognitive/s4.png`),
                require(`@assets/exercise/cognitive/s3.png`),
                require(`@assets/exercise/cognitive/s5.png`),
                require(`@assets/exercise/cognitive/s12.png`),
                require(`@assets/exercise/cognitive/s6.png`),
                require(`@assets/exercise/cognitive/s13.png`),
                require(`@assets/exercise/cognitive/s7.png`),
                require(`@assets/exercise/cognitive/s14.png`),
                require(`@assets/exercise/cognitive/s8.png`),
                require(`@assets/exercise/cognitive/s15.png`),
                require(`@assets/exercise/cognitive/s9.png`),
                require(`@assets/exercise/cognitive/s16.png`),
                require(`@assets/exercise/cognitive/s10.png`),
                require(`@assets/exercise/cognitive/s11.png`),
                require(`@assets/exercise/cognitive/s17.png`),
                require(`@assets/exercise/cognitive/s18.png`),
                require(`@assets/exercise/cognitive/s20.png`),
                require(`@assets/exercise/cognitive/s19.png`),
            ],
            meta: [
              {type:['하계', '1'],  imageId:1, name:'골프'  , sound:require(`@assets/exercise/memoryComplex/sound/s_golf.mp3`)},
              {type:['동계', '1'],  imageId:2, name:'루지'  , sound:require(`@assets/exercise/memoryComplex/sound/s_luge.mp3`)},
              {type:['하계', '10'],   imageId:4, name:'배구'  , sound:require(`@assets/exercise/memoryComplex/sound/s_volley_ball.mp3`)},
              {type:['동계', '1'],  imageId:3, name:'바이애슬론'  , sound:require(`@assets/exercise/memoryComplex/sound/s_biathlon.mp3`)},
              {type:['동계', '10'],   imageId:5, name:'봅슬레이'  , sound:require(`@assets/exercise/memoryComplex/sound/s_bobsled.mp3`)} ,
              {type:['하계', '1'],  imageId:12, name:'양궁'  , sound:require(`@assets/exercise/memoryComplex/sound/s_archery.mp3`)},
              {type:['동계', '1'],  imageId:6, name:'스노우보드'  , sound:require(`@assets/exercise/memoryComplex/sound/s_snowboard.mp3`)},
              {type:['하계', '1'],  imageId:13, name:'역도'  , sound:require(`@assets/exercise/memoryComplex/sound/s_weight_lifting.mp3`)},
              {type:['동계', '1'],  imageId:7, name:'스피드스케이팅'  , sound:require(`@assets/exercise/memoryComplex/sound/s_speed_skating.mp3`)},
              {type:['하계', '1'],  imageId:14, name:'달리기'   , sound:require(`@assets/exercise/memoryComplex/sound/s_run.mp3`)},
              {type:['동계', '1'],  imageId:8, name:'스켈레톤'  , sound:require(`@assets/exercise/memoryComplex/sound/s_skeleton.mp3`)},
              {type:['하계', '1'],  imageId:15, name:'리듬체조'  , sound:require(`@assets/exercise/memoryComplex/sound/s_rhythmic_gym.mp3`)},
              {type:['동계', '1'],  imageId:9, name:'스키점프'  , sound:require(`@assets/exercise/memoryComplex/sound/s_ski_jump.mp3`)},
              {type:['하계', '10'],   imageId:16, name:'축구'  , sound:require(`@assets/exercise/memoryComplex/sound/s_soccer.mp3`)},
              {type:['동계', '10'],   imageId:10, name:'아이스하키'  , sound:require(`@assets/exercise/memoryComplex/sound/s_ice_hockey.mp3`)},
              {type:['하계', '10'],   imageId:11, name:'야구'  , sound:require(`@assets/exercise/memoryComplex/sound/s_baseball.mp3`)},
              {type:['동계', '10'],   imageId:17, name:'컬링'  , sound:require(`@assets/exercise/memoryComplex/sound/s_curling.mp3`)},
              {type:['하계', '1'],  imageId:18, name:'태권도'  , sound:require(`@assets/exercise/memoryComplex/sound/s_taekwondo.mp3`)},
              {type:['동계', '1'],  imageId:20, name:'피겨스케이팅'  , sound:require(`@assets/exercise/memoryComplex/sound/s_figure_skating.mp3`)},
              {type:['하계', '1'],  imageId:19, name:'태니스'  , sound:require(`@assets/exercise/memoryComplex/sound/s_tennis.mp3`)},
            ],
            type: 'image',
            cogBoxBackground: '#0A45B5',
            cogItemBackground: '#EBF6FE',
            sortCogItem: true
        },
        'view7': {
            data: [
                require(`@assets/exercise/cognitive/sa1.jpg`),
                require(`@assets/exercise/cognitive/sa2.png`),
                require(`@assets/exercise/cognitive/sa3.jpg`),
                require(`@assets/exercise/cognitive/sa4.jpg`),
                require(`@assets/exercise/cognitive/sa5.jpg`),
                require(`@assets/exercise/cognitive/sa6.jpg`),
                require(`@assets/exercise/cognitive/sa7.jpg`),
                require(`@assets/exercise/cognitive/sa8.jpg`),
                require(`@assets/exercise/cognitive/sa9.jpg`),
                require(`@assets/exercise/cognitive/sa10.jpg`)
            ],
            type: 'image',
            cogBoxBackground: '#EFF9FB',
            cogItemBackground: '#EBF6FE',
            sortCogItem: true,
            borderRadius:10,
        },
        'view8': {
            data: [
                require(`@assets/exercise/cognitive/bf1.png`),
                require(`@assets/exercise/cognitive/bf2.png`),
                require(`@assets/exercise/cognitive/bf3.png`),
                require(`@assets/exercise/cognitive/bf4.png`),
                require(`@assets/exercise/cognitive/bf5.png`),
                require(`@assets/exercise/cognitive/bf6.png`),
                require(`@assets/exercise/cognitive/bf7.png`),
                require(`@assets/exercise/cognitive/bf8.png`),
                require(`@assets/exercise/cognitive/bf9.png`),
                require(`@assets/exercise/cognitive/bf10.png`),
                require(`@assets/exercise/cognitive/bf11.png`),
                require(`@assets/exercise/cognitive/bf12.png`),
                require(`@assets/exercise/cognitive/bf13.png`),
                require(`@assets/exercise/cognitive/bf14.png`)
            ],
            subImages: [
                require(`@assets/exercise/cognitive/bff1.png`),
                require(`@assets/exercise/cognitive/bff2.png`),
                require(`@assets/exercise/cognitive/bff3.png`),
                require(`@assets/exercise/cognitive/bff4.png`),
                require(`@assets/exercise/cognitive/bff5.png`),
                require(`@assets/exercise/cognitive/bff6.png`),
                require(`@assets/exercise/cognitive/bff7.png`),
                undefined,
                require(`@assets/exercise/cognitive/bff9.png`),
                require(`@assets/exercise/cognitive/bff10.png`),
                require(`@assets/exercise/cognitive/bff11.png`),
                require(`@assets/exercise/cognitive/bff12.png`),
                undefined,
                require(`@assets/exercise/cognitive/bff14.png`),
            ],
          meta: [
            {type: ['갈색'], name: '갈색',     level:'초급',     color: 'rgba(93, 62, 36, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_brown.mp3`)},
            {type: ['노랑색'], name: '노랑색',     level:'초급',     color: 'rgba(245, 226, 1, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_yellow_1.mp3`)},
            {type: ['보라색'], name: '보라색',     level:'초급',     color: 'rgba(125, 25, 209, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_purple_1.mp3`)},
            {type: ['분홍색'], name: '분홍색',     level:'초급',     color: 'rgba(240, 20, 104, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_pink_1.mp3`)},
            {type: ['빨강색'], name: '빨강색',     level:'초급',     color: 'rgba(204, 40, 40, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_red.mp3`)},
            {type: ['연녹색'], name: '연녹색',                     color: 'rgba(142, 186, 71, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_green_2.mp3`)},
            {type: ['연분홍색'], name: '연분홍색',                 color: 'rgba(238, 145, 180, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_pink_2.mp3`)},
            {type: ['하늘색'], name: '하늘색',                    color: 'rgba(93, 195, 217, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_sky_blue.mp3`)},
            {type: ['주황색'], name: '주황색',     level:'초급',     color: 'rgba(237, 133, 35, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_orange.mp3`)},
            {type: ['녹색'], name: '녹색',     level:'초급',     color: 'rgba(9, 103, 29, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_green_1.mp3`)},
            {type: ['연보라색'], name: '연보라색',                 color: 'rgba(110, 115, 231, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_purple_2.mp3`)},
            {type: ['파랑색'], name: '파랑색',     level:'초급',     color: 'rgba(0, 108, 187, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_blue.mp3`)},
            {type: ['호랑색'], name: '호랑색',                      color: 'rgba(0, 0, 0, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_ssss.mp3`)},
            {type: ['연노랑색'], name: '연노랑색',                   color: 'rgba(207, 166, 86, 1)', sound: require(`@assets/exercise/memoryComplex/sound/s_yellow_2.mp3`)},
          ],
            type: 'image',
            cogBoxBackground: '#EBF6FE',
            cogItemBackground: '#EBF6FE',
            sortCogItem: true ,
            borderRadius:10,
            cogItemBoxStyle: {
              border: 8,
              borderColor: '#ddd',
              borderStyle: 'solid',
              padding: 10
            }
        },
        'view9': {
            data: [
                require(`@assets/exercise/cognitive/car1.png`),
                require(`@assets/exercise/cognitive/car2.png`),
                require(`@assets/exercise/cognitive/car3.png`),
                require(`@assets/exercise/cognitive/car4.png`),
                require(`@assets/exercise/cognitive/car5.png`),
                require(`@assets/exercise/cognitive/car6.png`),
                require(`@assets/exercise/cognitive/car7.png`),
                require(`@assets/exercise/cognitive/car8.png`),
                require(`@assets/exercise/cognitive/car9.png`),
                require(`@assets/exercise/cognitive/car10.png`),
                require(`@assets/exercise/cognitive/car11.png`),
                require(`@assets/exercise/cognitive/car12.png`),
                require(`@assets/exercise/cognitive/car13.png`),
                require(`@assets/exercise/cognitive/car14.png`)
            ],
            dataSub: [
              require(`@assets/exercise/memoryComplex/ca_1.png`),
              require(`@assets/exercise/memoryComplex/ca_2.png`),
              require(`@assets/exercise/memoryComplex/ca_3.png`),
              require(`@assets/exercise/memoryComplex/ca_4.png`),
              require(`@assets/exercise/memoryComplex/ca_5.png`),
              require(`@assets/exercise/memoryComplex/ca_6.png`),
              require(`@assets/exercise/memoryComplex/ca_7.png`),
              require(`@assets/exercise/memoryComplex/ca_8.png`),
              require(`@assets/exercise/memoryComplex/ca_9.png`),
              require(`@assets/exercise/memoryComplex/ca_10.png`),
              require(`@assets/exercise/memoryComplex/ca_11.png`),
              require(`@assets/exercise/memoryComplex/ca_12.png`),
              require(`@assets/exercise/memoryComplex/ca_13.png`),
              require(`@assets/exercise/memoryComplex/ca_14.png`),
              // require(`@assets/exercise/memoryComplex/ca_15.png`),
              // require(`@assets/exercise/memoryComplex/ca_16.png`),
              // require(`@assets/exercise/memoryComplex/ca_17.png`),
            ],
            meta: [
              {type:['빨간색','1'],  number: 1, name:'빨간색',   level:'초급',         color: 'rgba(204, 40, 40, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_red.mp3`)},
              {type:['연분홍색','2'],  number: 2, name:'연분홍색',                     color: 'rgba(238, 145, 180, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_pink_2.mp3`)},
              {type:['주황색','3'],  number: 3, name:'주황색',   level:'초급',         color: 'rgba(237, 133, 35, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_orange.mp3`)},
              {type:['분홍색','4'],  number: 4, name:'분홍색',                        color: 'rgba(240, 20, 104, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_pink_1.mp3`)},
              {type:['노랑색','5'],  number: 5, name:'노랑색',   level:'초급',         color: 'rgba(245, 226, 1, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_yellow_1.mp3`)},
              {type:['연녹색','6'],  number: 6, name:'연녹색',                       color: 'rgba(142, 186, 71, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_green_2.mp3`)},
              {type:['녹색','7'],   number: 7, name:'녹색',   level:'초급',         color: 'rgba(9, 103, 29, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_green_1.mp3`)},
              {type:['하늘색','8'],  number: 8, name:'하늘색',                       color: 'rgba(93, 195, 217, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_sky_blue.mp3`)},
              {type:['파랑색','9'],  number: 9, name:'파랑색',   level:'초급',         color: 'rgba(0, 108, 187, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_blue.mp3`)},
              {type:['보라색','10'],  number: 10, name:'보라색',   level:'초급',         color: 'rgba(125, 25, 209, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_purple_1.mp3`)},
              {type:['연보라색','11'], number: 11, name:'연보라색',                      color: 'rgba(110, 115, 231, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_purple_2.mp3`)},
              {type:['갈색','12'],   number: 12, name:'갈색',    level:'초급',         color: 'rgba(93, 62, 36, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_brown.mp3`)},
              {type:['옥색','13'],   number: 13, name:'옥색',                        color: 'rgba(145, 196, 174, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_jade_green.mp3`)},
              {type:['연노랑색','14'], number: 14, name:'연노랑색',                      color: 'rgba(207, 166, 86, 1)' , sound:require(`@assets/exercise/memoryComplex/sound/s_yellow_2.mp3`)},
              // {type:['흰색','15'],   number: 15, name:'흰색', color: 'rgba(255, 255, 255, 1)' , sound:'/assets/memoryComplex/sound/s_white.mp3'},
            ],
            type: 'image',
            useReverse: true,
            cogBoxBackground: '#EBF6FE',
            cogItemBackground: '#EBF6FE',
            sortCogItem: true ,
            borderRadius:10,
            width: 240,
            height: 120,
            wrapWidth: 590,
            spacing: 2,
            paddingTop: 15,
            cogItemOffset : {
                marginTop:-70,
                maxWidth: '100%'
            },
            cogItemOverOffset: {
                marginTop:-140,
                maxWidth: '100%'
            },
            cogItemMarginTop: 50,
            cogItemStyle: {
                top: 'calc(50% - 300px)',
                right: 15,
                zIndex: 20,
                width: 550
            },
            cogBoxStyle: {
              //두뇌검사에 사용
                width:'calc(100% - 590px)', 
                zIndex: 20, 
                top: 'calc(50% - 150px)'
            },
            cogItemBoxStyle: {
              //두뇌계발에 사용
              border: 8,
              borderColor: '#efef',
              borderStyle: 'solid',
            }
        },
        'view9-s': {
          data: [
            require(`@assets/exercise/memoryComplex/ca_1.png`),
            require(`@assets/exercise/memoryComplex/ca_2.png`),
            require(`@assets/exercise/memoryComplex/ca_3.png`),
            require(`@assets/exercise/memoryComplex/ca_4.png`),
            require(`@assets/exercise/memoryComplex/ca_5.png`),
            require(`@assets/exercise/memoryComplex/ca_6.png`),
            require(`@assets/exercise/memoryComplex/ca_7.png`),
            require(`@assets/exercise/memoryComplex/ca_8.png`),
            require(`@assets/exercise/memoryComplex/ca_9.png`),
            require(`@assets/exercise/memoryComplex/ca_10.png`),
            require(`@assets/exercise/memoryComplex/ca_11.png`),
            require(`@assets/exercise/memoryComplex/ca_12.png`),
            require(`@assets/exercise/memoryComplex/ca_13.png`),
            require(`@assets/exercise/memoryComplex/ca_14.png`),
            // require(`@assets/exercise/memoryComplex/ca_15.png`),
            // require(`@assets/exercise/memoryComplex/ca_16.png`),
            // require(`@assets/exercise/memoryComplex/ca_17.png`),
          ],
          meta: [
            {type:['빨간색','1'],  number: 1, name:'빨간색', level:'초급',  color: 'rgba(204, 40, 40, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_red.mp3`)},
            {type:['연분홍색','2'],  number: 2, name:'연분홍색',            color: 'rgba(238, 145, 180, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_pink_2.mp3`)},
            {type:['주황색','3'],  number: 3, name:'주황색', level:'초급',  color: 'rgba(237, 133, 35, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_orange.mp3`)},
            {type:['분홍색','4'],  number: 4, name:'분홍색',               color: 'rgba(240, 20, 104, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_pink_1.mp3`)},
            {type:['노랑색','5'],  number: 5, name:'노랑색', level:'초급',  color: 'rgba(245, 226, 1, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_yellow_1.mp3`)},
            {type:['연녹색','6'],  number: 6, name:'연녹색',               color: 'rgba(142, 186, 71, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_green_2.mp3`)},
            {type:['진녹색','7'],   number: 7, name:'진녹색', level:'초급',   color: 'rgba(9, 103, 29, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_green_1.mp3`)},
            {type:['하늘색','8'],  number: 8, name:'하늘색',               color: 'rgba(93, 195, 217, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_sky_blue.mp3`)},
            {type:['파랑색','9'],  number: 9, name:'파랑색', level:'초급',  color: 'rgba(0, 108, 187, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_blue.mp3`)},
            {type:['보라색','10'],  number: 10, name:'보라색', level:'초급',  color: 'rgba(125, 25, 209, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_purple_1.mp3`)},
            {type:['연보라색','11'], number: 11, name:'연보라색',            color: 'rgba(110, 115, 231, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_purple_2.mp3`)},
            {type:['갈색','12'],   number: 12, name:'갈색',  level:'초급',  color: 'rgba(93, 62, 36, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_brown.mp3`)},
            {type:['옥색','13'],   number: 13, name:'옥색',               color: 'rgba(145, 196, 174, 1)', sound:require(`@assets/exercise/memoryComplex/sound/s_jade_green.mp3`)},
            {type:['연노랑색','14'], number: 14, name:'연노랑색',           color: 'rgba(207, 166, 86, 1)' , sound:require(`@assets/exercise/memoryComplex/sound/s_yellow_2.mp3`)},
            // {type:['흰색','15'],   number: 15, name:'흰색', color: 'rgba(255, 255, 255, 1)' , sound:'/assets/memoryComplex/sound/s_white.mp3'},
          ],
          type: 'image',
          cogBoxBackground: '#EBF6FE',
          cogItemBackground: '#EBF6FE',
          sortCogItem: true ,
          borderRadius:10,
  
          width: 240,
          height: 120,
          wrapWidth: 590,
          spacing: 2,
          paddingTop: 15,
          cogItemOffset : {
            marginTop:-70,
            maxWidth: '100%'
          },
          cogItemOverOffset: {
            marginTop:-140,
            maxWidth: '100%'
          },
          cogItemMarginTop: 50,
          cogItemStyle: {
            top: 'calc(50% - 300px)',
            right: 15,
            zIndex: 20,
            width: 550
          },
          cogBoxStyle: {
            width:'calc(100% - 590px)',
            zIndex: 20,
            top: 'calc(50% - 150px)'
          }
        },
        'view10': {
            data: [
              require(`@assets/exercise/cognitive/c13.png`),
              require(`@assets/exercise/cognitive/c1.png`),
              require(`@assets/exercise/cognitive/c14.png`),
              require(`@assets/exercise/cognitive/c2.png`),
              require(`@assets/exercise/cognitive/c15.png`),
              require(`@assets/exercise/cognitive/c3.png`),
              require(`@assets/exercise/cognitive/c16.png`),
              require(`@assets/exercise/cognitive/c4.png`),
              require(`@assets/exercise/cognitive/c18.png`),
              require(`@assets/exercise/cognitive/c5.png`),
              require(`@assets/exercise/cognitive/c19.png`),
              require(`@assets/exercise/cognitive/c6.png`),
              require(`@assets/exercise/cognitive/c20.png`),
              require(`@assets/exercise/cognitive/c7.png`),
              require(`@assets/exercise/cognitive/c21.png`),
              require(`@assets/exercise/cognitive/c8.png`),
              require(`@assets/exercise/cognitive/c22.png`),
              require(`@assets/exercise/cognitive/c9.png`),
              require(`@assets/exercise/cognitive/c23.png`),
              require(`@assets/exercise/cognitive/c10.png`),
              require(`@assets/exercise/cognitive/c24.png`),
              require(`@assets/exercise/cognitive/c11_1.png`),
              require(`@assets/exercise/cognitive/c12.png`),
              
              require(`@assets/exercise/memoryComplex/run_1.png`),
              require(`@assets/exercise/memoryComplex/run_2.png`),
              require(`@assets/exercise/memoryComplex/run_3.png`),
              require(`@assets/exercise/memoryComplex/run_4.png`),
              require(`@assets/exercise/memoryComplex/run_5.png`),
              require(`@assets/exercise/memoryComplex/run_6.png`),
              require(`@assets/exercise/memoryComplex/run_7.png`),
              require(`@assets/exercise/memoryComplex/run_8.png`),
              require(`@assets/exercise/memoryComplex/run_9.png`),
              require(`@assets/exercise/memoryComplex/run_10.png`),
              
            ],
          meta: [
            {type:['동물'], imageId: 13, name: '사자', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_13.mp3`)},
            {type:['과일'], imageId: 1, name: '피망', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_1.mp3`)},
            {type:['동물'], imageId: 14, name: '호랑이', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_14.mp3`)},
            {type:['과일'], imageId: 2, name: '당근', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_2.mp3`)},
            {type:['동물'], imageId: 15, name: '하마', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_15.mp3`)},
            {type:['과일'], imageId: 3, name: '토마토', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_3.mp3`)},
            {type:['동물'], imageId: 16, name: '코끼리', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_16.mp3`)},
            {type:['과일'], imageId: 4, name: '사과', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_4.mp3`)},
            {type:['동물'], imageId: 18, name: '사슴', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_18.mp3`)},
            {type:['과일'], imageId: 5, name: '오렌지', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_5.mp3`)},
            {type:['동물'], imageId: 19, name: '다람쥐', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_19.mp3`)},
            {type:['과일'], imageId: 6, name: '딸기', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_6.mp3`)},
            {type:['동물'], imageId: 20, name: '원숭이', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_20.mp3`)},
            {type:['과일'], imageId: 7, name: '감자', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_7.mp3`)},
            {type:['동물'], imageId: 21, name: '오리', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_21.mp3`)},
            {type:['과일'], imageId: 8, name: '가지', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_8.mp3`)},
            {type:['동물'], imageId: 22, name: '악어', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_22.mp3`)},
            {type:['과일'], imageId: 9, name: '무', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_9.mp3`)},
            {type:['동물'], imageId: 23, name: '거북이', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_23.mp3`)},
            {type:['과일'], imageId: 10, name: '멜론', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_10.mp3`)},
            {type:['동물'], imageId: 24, name: '기린', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_24.mp3`)},
            {type:['과일'], imageId: 11, name: '수박', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_11_1.mp3`)},
            {type:['과일'], imageId: 12, name: '포도', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_12.mp3`)},

            {type:['탈것'], imageId: 25, name: '버스', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_25.mp3`)},
            {type:['탈것'], imageId: 26, name: '킥보드', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_26.mp3`)},
            {type:['탈것'], imageId: 27, name: '자전거', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_27.mp3`)},
            {type:['탈것'], imageId: 28, name: '파란차', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_28.mp3`)},
            {type:['탈것'], imageId: 29, name: '오토바이', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_29.mp3`)},
            {type:['탈것'], imageId: 30, name: '스키', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_30.mp3`)},
            {type:['탈것'], imageId: 31, name: '세발자전거', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_31.mp3`)},
            {type:['탈것'], imageId: 32, name: '비행기', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_32.mp3`)},
            {type:['탈것'], imageId: 33, name: '배', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_33.mp3`)},
            {type:['탈것'], imageId: 34, name: '말', sound:require(`@assets/exercise/memoryComplex/sound/s_v10_34.mp3`)},
            

          ],
        type: 'image',
        cogBoxBackground: '#0A45B5',
        cogItemBackground: '#4AA3F5',
        sortCogItem: true,
        cogItemBoxStyle: {
          border: 8,
          borderColor: 'rgba(94,197,216,0.3)',
          borderStyle: 'solid',
          padding: 10
        }
      },
        'view10-s': {
          data: [
            require(`@assets/exercise/memoryComplex/fl_1.png`),
            require(`@assets/exercise/memoryComplex/fl_2.png`),
            require(`@assets/exercise/memoryComplex/fl_3.png`),
            require(`@assets/exercise/memoryComplex/fl_4.png`),
            require(`@assets/exercise/memoryComplex/fl_5.png`),
            require(`@assets/exercise/memoryComplex/fl_6.png`),
            require(`@assets/exercise/memoryComplex/fl_7.png`),
            require(`@assets/exercise/memoryComplex/fl_8.png`),
            require(`@assets/exercise/memoryComplex/fl_9.png`),
            require(`@assets/exercise/memoryComplex/fl_10.png`),
            require(`@assets/exercise/memoryComplex/fl_11.png`),
            require(`@assets/exercise/memoryComplex/fl_12.png`),
            require(`@assets/exercise/memoryComplex/fl_13.png`),
            require(`@assets/exercise/memoryComplex/fl_14.png`),
            require(`@assets/exercise/memoryComplex/fl_15.png`),
            require(`@assets/exercise/memoryComplex/fl_16.png`),
            require(`@assets/exercise/memoryComplex/fl_17.png`),
          ],
          meta: [
            {type:['빨간색'],    imageId: 1,   name:'빨간색', level:'초급', color: 'rgba(204, 40, 40, 1)',},
            {type:['회색'],     imageId: 2,   name:'회색', color: 'rgba(83, 83, 83, 1)'},
            {type:['옥색'],     imageId: 3,   name:'옥색', color: 'rgba(145, 196, 174, 1)'},
            {type:['연녹색'],    imageId: 4,   name:'연녹색', color: 'rgba(142, 186, 71, 1)',},
            {type:['연분홍색'],   imageId: 5,   name:'연분홍색', color: 'rgba(238, 145, 180, 1)'},
            {type:['노랑색'],    imageId: 6,   name:'노랑색', level:'초급', color: 'rgba(245, 226, 1, 1)',},
            {type:['보라색'],    imageId: 7,   name:'보라색', level:'초급', color: 'rgba(125, 25, 209, 1)',},
            {type:['진녹색'],    imageId: 8,   name:'진녹색', level:'초급', color: 'rgba(9, 103, 29, 1)',},
            {type:['갈색'],     imageId: 9,   name:'갈색', level:'초급', color: 'rgba(93, 62, 36, 1)'},
            {type:['황토색'],    imageId: 10,  name:'황토색', color: 'rgba(200, 158, 76, 1)'},
            {type:['하늘색'],    imageId: 11,  name:'하늘색', color: 'rgba(93, 195, 217, 1)'},
            {type:['주황색'],    imageId: 12,  name:'주황색', level:'초급', color: 'rgba(237, 133, 35, 1)'},
            {type:['파랑색'],    imageId: 13,  name:'파랑색', level:'초급', color: 'rgba(0, 108, 187, 1)'},
            {type:['검정색'],    imageId: 14,  name:'검정색',  color: 'rgba(0, 0, 0, 1)'},
            {type:['연보라색'],   imageId: 15,  name:'연보라색', color: 'rgba(110, 115, 231, 1)'},
            {type:['분홍색'],    imageId: 16,  name:'분홍색', color: 'rgba(240, 20, 104, 1)'},
            {type:['흰색'],      imageId: 17,  name:'흰색', level:'초급', color: 'rgba(255, 255, 255, 1)', textColor:'rgba(100,100,100,1)'},
            // {type:['생물'], imageId: 19, name:'해파리'},
            // {type:['생물'], imageId: 23, name:'소라'},
            // {type:['생물'], imageId: 24, name:'인어공주'},
            // {type:['생물'], imageId: 26, name:'해마'},
          ],
          type: 'image',
          cogBoxBackground: '#0A45B5',
          cogItemBackground: '#4AA3F5',
          sortCogItem: true,
          cogItemBoxStyle: {
            border: 8,
            borderColor: 'rgba(94,197,216,0.3)',
            borderStyle: 'solid'
          }
        },
      
        'view11': {
          data: [
            require(`@assets/exercise/cognitive/c13.png`),
            require(`@assets/exercise/cognitive/c1.png`),
            require(`@assets/exercise/cognitive/c14.png`),
            require(`@assets/exercise/cognitive/c2.png`),
            require(`@assets/exercise/cognitive/c15.png`),
            require(`@assets/exercise/cognitive/c3.png`),
            require(`@assets/exercise/cognitive/c16.png`),
            require(`@assets/exercise/cognitive/c4.png`),
            require(`@assets/exercise/cognitive/c18.png`),
            require(`@assets/exercise/cognitive/c5.png`),
            require(`@assets/exercise/cognitive/c19.png`),
            require(`@assets/exercise/cognitive/c6.png`),
            require(`@assets/exercise/cognitive/c20.png`),
            require(`@assets/exercise/cognitive/c7.png`),
            require(`@assets/exercise/cognitive/c21.png`),
            require(`@assets/exercise/cognitive/c8.png`),
            require(`@assets/exercise/cognitive/c22.png`),
            require(`@assets/exercise/cognitive/c9.png`),
            require(`@assets/exercise/cognitive/c23.png`),
            require(`@assets/exercise/cognitive/c10.png`),
            require(`@assets/exercise/cognitive/c24.png`),
            require(`@assets/exercise/cognitive/c11_1.png`),
            require(`@assets/exercise/cognitive/c12.png`),
          ],
          meta: [
            {type:['동물'], imageId: 13, name: '사자'},
            {type:['야채'], imageId: 1, name: '피망'},
            {type:['동물'], imageId: 14, name: '호랑이'},
            {type:['야채'], imageId: 2, name: '당근'},
            {type:['동물'], imageId: 15, name: '하마'},
            {type:['야채'], imageId: 3, name: '토마토'},
            {type:['동물'], imageId: 16, name: '코끼리'},
            {type:['야채'], imageId: 4, name: '사과'},
            {type:['동물'], imageId: 18, name: '사슴'},
            {type:['야채'], imageId: 5, name: '오렌지'},
            {type:['동물'], imageId: 19, name: '다람쥐'},
            {type:['야채'], imageId: 6, name: '딸기'},
            {type:['동물'], imageId: 20, name: '원숭이'},
            {type:['야채'], imageId: 7, name: '감자'},
            {type:['동물'], imageId: 21, name: '오리'},
            {type:['야채'], imageId: 8, name: '가지'},
            {type:['동물'], imageId: 22, name: '악어'},
            {type:['야채'], imageId: 9, name: '무'},
            {type:['동물'], imageId: 23, name: '거북이'},
            {type:['야채'], imageId: 10, name: '멜론'},
            {type:['동물'], imageId: 24, name: '기린'},
            {type:['야채'], imageId: 11, name: '수박'},
            {type:['야채'], imageId: 12, name: '포도'},

          ],
          type: 'image',
          cogBoxBackground: '#0A45B5',
          cogItemBackground: '#4AA3F5',
          sortCogItem: true,
          cogItemBoxStyle: {
            border: 8,
            borderColor: 'rgba(94,197,216,0.3)',
            borderStyle: 'solid',
            padding: 10
          }
        },
    }
}