
import React, {useEffect, useState} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { green , blue, red } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography'
import {Box, Grid, Card,TextField,CardContent,CardActionArea, Button} from '@material-ui/core';

const HelpDesk = () => {
    return (
        <div className="wrapper2">
            <div>
                <section className="section section2_2 bdtop">
                    <Box mt={15}></Box>
            </section>

            <Box>
                <br/><br/>
                <Grid container spacing={3} alignItems={"center"} alignContent={"center"}>
                    <Grid item>
                        <MeasureType name="EEG 측정" 
                                description="좌/우 뇌상태를 3D FFT로 측정." 
                                color={blue[100]}
                                />
                    </Grid>   
                    <Grid item>
                        <MeasureType name="균형상태 측정" 
                                description="안정, 주의력, 집중력 상태를 측정" 
                                color={green[100]}
                                />
                    </Grid>   
                    <Grid item>
                        <MeasureType name="좌우뇌 측정" 
                                description="좌/우 뇌 균형상태를 측정" 
                                color={red[100]}
                                />
                    </Grid>   
                </Grid>
            </Box>
            

        </div>
    </div>
    );
}

//측정
export function MeasureType(props) {
    const classes = useStyles();
    return <Box>
                <Card className={classes.card}>
                    <CardActionArea>
                        <CardContent >
                            <Box display="flex" style={{height: 300}} alignItems="center">
                                <Box width="100%">
                                    <Typography gutterBottom variant="h5" component="h2" align="center">
                                        {props.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p" align="center">
                                        {props.description}
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
}


const useStyles = makeStyles(theme => ({
    card: {
        minWidth: 245,
    },
    cardDetail: {
        maxWidth: 400,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    coverImage: {
        objectFit:'none',
    }
}));

export default HelpDesk
