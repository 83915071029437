export default class DeviceHelper {
    static isBind = false
    
    static async bind() {
        try {
            await window.CefSharp.BindObjectAsync('BRHW')
            this.isBind = true
        } catch {}
    }
    
    static async exitProgram() {
        await this.bind() 
        
        try {
            await window.BRHW.exit()    
        } catch (e){
            console.log(e);
            alert('프로그램 종료에 실패했습니다.');
        }
    }
    
    static async startFullScreen(){
        await this.bind()

        try {
            await window.BRHW.startFullScreen()
        } catch (e){
            console.log(e);
        }
    }
    

}