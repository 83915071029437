import {Box, Card, CardActionArea, CardContent, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, {useCallback, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import BarChart from '@user-components/BarChart';
import { useHistory } from "react-router-dom";
import AuthProvider from "@user-provider/AuthProvider";
import PrescriptionService from '@user-service/PrescriptionService';

/**
 * 뇌기능 내면 상태  δ, θ, α, SMR, β, βM, βh로 표시합니다.
 * @param {*} props
 */
export default function CurrentMeasureBandTest(props) {
    const classes = useStyles();
    // const { id } = props.measureResult;
    const history = useHistory()
    const measureCode = ['MA', 'MM' , 'MB']
    const measureText = ['개안', '폐안' , '개안']
    const [bandData, setBandData] = useState([])
    
    const fetch = useCallback(async (id) => {
        const data = await PrescriptionService.getMeasureBandTest(id)

        const newData = [];
        measureCode.map((code) => {
            newData.push(data[code])
            return code
        })
        setBandData(newData)
    },[])

    useEffect(() =>  {
        fetch(props.measureResult.id).then()
    }, [fetch, props.measureResult])
    
    
    const ableClick = true !== props.clickDisabled
    const handler = () => {
        if(AuthProvider.canViewMeasureDetail() && ableClick) {
            history.push('/measureDetail/' + props.measureResult.id + '/measure-band-test');
        }
    }

    return (
        <Box className={classes.root}>
            <Card className={classes.card} onClick={handler}>
                <CardActionArea>
                    <CardContent>
                        <Typography variant={'subtitle1'} className={classes.title}>
                            항상성 자기조절
                        </Typography>
                        <Grid container>
                            {bandData.map((data, index) => {
                                const defaultData = [
                                    data ? data.band.ch1 : [],
                                    data ? data.band.ch2 : []
                                ]
                                return (
                                    <Grid item key={`${props.measureResult.id}-ba-${index}`} xs={4}>
                                        <BarChart
                                            key={Math.random()}
                                            data={new Array(6).fill(0)}
                                            defaultData={defaultData}
                                            showTopValue={true}
                                            topValueMargin={20}
                                            labels={['δ', 'θ', 'α', 'SMR', 'β', 'βM', 'βh']}
                                            height={150}
                                            useColorIndex={true}
                                            showYAxes={true}
                                            colors={['#60C3F7', '#A9D8AA']}
                                            grouped={true}
                                            groups={2}
                                        />
                                        <Box mt={2} mb={2} >
                                            <Typography align={'center'} variant={'subtitle1'} className={classes.title}>{measureText[index]}</Typography>
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
    },
    title: {
        fontWeight: 'bold',
        paddingLeft: 15,
        marginBottom: 20
    },
    card: {
        border: "none",
        boxShadow: "none",
        padding: 0
    },
}));

