import React, {useState} from "react";
import {Box, Grid} from "@material-ui/core";
import _ from "lodash";

/**
 * 랜덤 수 생성
 * @param n1
 * @param n2
 * @returns {number}
 */
const randomRange = (n1, n2) => {
  return Math.floor( (Math.random() * (n2 - n1 + 1)) + n1 );
}


/**
 * 보기 생성
 * @param answer
 * @returns {(*|number)[]}
 */
const generateChoices = (answer) => {
  // 1. 끝자리가 answer의 끝자리와 같은 숫자
  // const lastDigitMin = 1 === rangeMin ? 10 : rangeMin
  // let lastDigit = answer % 10 ;
  // let similarLastDigit = lastDigit  + 10; // 10을 더해 다른 수 중 끝자리가 같은 숫자를 생성

  // 2. 정답
  // 3. 11보다 조금 큰 숫자 (1~3 사이의 차이로)
  let randomDifference = Math.floor(Math.random() * 3) + 1; // 1~3 사이의 무작위 숫자
  let slightlyBigger = answer + randomDifference;

  // 4. 11보다 조금 작은 숫자
  let randomDifferenceSmaller;
  do {
    randomDifferenceSmaller = Math.floor(Math.random() * 3) + 1; // 1~3 사이의 무작위 숫자
  } while (answer - randomDifferenceSmaller < 1);
  let slightlySmaller = answer - randomDifferenceSmaller;

  // 위에서 생성한 보기들을 배열에 추가
  return  _.shuffle([answer, slightlyBigger, slightlySmaller])
}


const SimpleCalcBox = (props) => {
  const { stageInfo } = props

  let rangeMin = stageInfo.rangeMin
  let rangeMax = stageInfo.rangeMax
  
  const select = (view) => {
    const correct = exView.filter(p => p.isCorrect)
    if(0 < correct.length) return
    
    // const answer = calcValues[ calcValues.length - 1 ]
    view.isCorrect = calcAnswer === view.value
    view.isSelected = true
    
    if(view.isCorrect){
      const foundIncorrect = exView.filter(p => false === p.isCorrect)
      props.onSelectEnded(0 === foundIncorrect.length)
    }
    
    setExView(_.clone(exView))
  }

  

  const [calcValues] = useState(() => {
    const val = []
    for(let i = 0 ; i < stageInfo.all ; i++){
      const value = randomRange(rangeMin, rangeMax)
      val.push(value)
    }
    return val
  })
  const [calcAnswer] = useState(_.sum(calcValues))
  const [exView, setExView] = useState(
    generateChoices(calcAnswer).map((value, index) => {
    return {
      index,
      value,
      isCorrect: undefined,
      isSelected: false
    }
  }))


  const columnText = []
  // 마지막은 정답이기 때문에 사용하지 않습니다.
  for(let i = 0; i < calcValues.length; i++) {
    if (i !== 0) {
      columnText.push({
        type: 'text',
        value: '+'
      });
    }
    columnText.push({
      type: 'value',
      value: calcValues[i]
    })
  }
  
  return (
    <>
      <Box position={'absolute'}
           left={'50%'}
           textAlign={'center'}
           fontSize={'2.7rem'}
           lineHeight={'80px'}
           fontWeight={'bold'}
           style={{transform: 'translate(-50%, -10%)', minWidth: 1300}}
      >
        <Grid container justify={'center'} >
          {columnText.map((val, index) => {
            return (
              <Grid item
                    key={`calc-box-${index}`}
                    style={{
                      padding: 60,
                      background: val.type === 'value' ? 'rgba(74, 163, 245, 0.5)' : undefined,
                      borderRadius: 20,
                    }}
              >
                <Box
                  style={{
                    fontSize: '4rem',
  
                    textAlign: 'center'
  
                  }}
                >
                  {val.value}
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
      <Box position={'fixed'} bottom={'13%'} left={'0'} width={'100%'} >
        <Grid container spacing={5} justify={"center"}>
          {exView.map((view, index) => {
            return (
              <Grid item key={index} >
                <Box
                  onClick={() => {
                    select(view,index)
                  }}
                  position={'relative'}
                  style={{
                    backgroundColor: '#fff',
                    border:'5px solid #ddd',
                    height: 100,
                    width: 200,
                    textAlign: 'center',
                    lineHeight: '90px',
                    fontWeight: 'bold',
                    fontSize:'3rem',
                    color: '#000',
                    borderRadius: 10
                  }}
                >
                  {view.value}

                  {view.isSelected &&
                    <Box position={'absolute'} top={'55%'} left={'50%'}  zIndex={100} style={{transform: 'translate(-50%, -50%)'}}>
                      {view.isCorrect &&
                        <svg fill={'#3BB438'}
                             strokeWidth={1}
                             stroke={'white'}
                             xmlns="http://www.w3.org/2000/svg"
                             height={'70%'}
                             width={'70%'}
                             viewBox="0 0 24 24">
                          <path
                            d="M0 11.386l1.17-1.206c1.951.522 5.313 1.731 8.33 3.597 3.175-4.177 9.582-9.398 13.456-11.777l1.044 1.073-14 18.927-10-10.614z"/>
                        </svg>
                      }
                      {!view.isCorrect &&
                        <svg
                          fill={'#E94825'}
                          strokeWidth={1}
                          stroke={'white'}
                          xmlns="http://www.w3.org/2000/svg"
                          height={'70%'}
                          width={'70%'}
                          viewBox="0 0 24 24">
                          <path
                            d="M24 3.752l-4.423-3.752-7.771 9.039-7.647-9.008-4.159 4.278c2.285 2.885 5.284 5.903 8.362 8.708l-8.165 9.447 1.343 1.487c1.978-1.335 5.981-4.373 10.205-7.958 4.304 3.67 8.306 6.663 10.229 8.006l1.449-1.278-8.254-9.724c3.287-2.973 6.584-6.354 8.831-9.245z"/>
                        </svg>
                      }
                    </Box>
                  }
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
      
    </>
    
  )
}

export default SimpleCalcBox