import React, {useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
    const [open, setOpen] = React.useState(false);
    const [state] = React.useState({
        title: props.title,
        content: props.content,
        buttonName: props.buttonName,
        cancelButtonName: props.cancelButtonName,
        submit: props.submit,
        close: props.close,
        data: props.data
    })
    const [disableSubmit, setDisableSubmit] = React.useState(props.disableSubmit ? props.disableSubmit : false)
    const handleClose = () => {
        if(state.close){
            state.close(state.data)
        }
        setOpen(false);
    };
    
    const handSubmit = () => {
        if(state.submit){
            state.submit(state.data)
        }
        setOpen(false);
    }

    useEffect(() => {
        setOpen(props.open)
    },[props.open])
    
    useEffect(() => {
        setDisableSubmit(props.disableSubmit)
    },[props.disableSubmit])
    
    const { title, content, buttonName, cancelButtonName} = state
    return (
        <Dialog
            aria-labelledby="customized-dialog-title" 
            disabled
            open={open}>
            <DialogTitle className={'common-top-dialog-title'} onClose={handleClose}>
                {title}
            </DialogTitle>
            <DialogContent dividers className={'common-top-dialog-content'}>
                {content}
            </DialogContent>
            <DialogActions>
                {props.close &&
                <Button onClick={handleClose}>
                    { cancelButtonName ? cancelButtonName : '취소' }
                </Button>
                }
                {buttonName &&
                <Button onClick={handSubmit} variant={'contained'} color={'primary'}  disabled={disableSubmit}
                        className={'common-top-dialog-submit'}>
                    {buttonName}
                </Button>
                }
            </DialogActions>
        </Dialog>
    );
}