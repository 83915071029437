import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CustomizedDialogs from "../../../components/CustomDialog";
import MeasureService from "../../../services/MeasureService";
import {makeStyles} from "@material-ui/core/styles";


export default function PreLevelDialog(props) {
    const classes = useStyles()
    
    const [open, setOpen] = useState(false)
    const preLevel = MeasureService.getPreLevel(props.type)
    
    useEffect(() => {
        setOpen(props.open)
    }, [props.open])
    
    let typeName = '분석지수'
    let title = 'EEG 등급 판별 기준표'
    if('EEG' !== props.type){
        typeName = '편차지수'
        title = '좌우뇌균형 등급 판별 기준표'
    }
    
    
    return (
        <CustomizedDialogs
            key={Math.random()}
            open={open}
            cancelButtonName={'닫기'}
            title={title}
            content={
                <React.Fragment>
                    <TableContainer>
                        <Table aria-label="table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>등급</TableCell>
                                    <TableCell>{typeName}</TableCell>
                                    <TableCell align="right">분류기준</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {preLevel.map((row) => (
                                    <TableRow key={row.name} style={{backgroundColor: row.backgroundColor }}>
                                        <TableCell size={'small'} className={classes.borderBottom} align="left">{row.levelName}</TableCell>
                                        <TableCell size={'small'} className={classes.borderBottom} omponent="th" scope="row" style={{fontWeight: 'bold', color: row.color}}>
                                            {row.name}
                                        </TableCell>
                                        <TableCell size={'small'} className={classes.borderBottom} align="right" >{row.levelText}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </React.Fragment>
            }
            close={() => {
                if(props.onCancel){
                    props.onCancel()
                }
            }}
        />
    );
}

const useStyles = makeStyles(() => ({
        borderBottom: {borderColor: '#ddd'}
    })
)