import React, {useState} from "react";
import {Box, Grid} from "@material-ui/core";
import _ from "lodash";

/**
 * 랜덤 수 생성
 * @param n1
 * @param n2
 * @returns {number}
 */
const randomRange = (n1, n2) => {
  return Math.floor( (Math.random() * (n2 - n1 + 1)) + n1 );
}

const MatrixCalcBox = (props) => {

  const {  stageInfo } = props

  
  let rangeMin = stageInfo.rangeMin
  let rangeMax = stageInfo.rangeMax
  const gridSize = stageInfo.all;
  
  const [start] = useState(randomRange(rangeMin, rangeMax))
  const [end] = useState(randomRange(rangeMin, rangeMax))
  const [answer] = useState(start + end)
  const [remainFlag, setRemainFlag] = useState(stageInfo.answerCount)
  
  const row = stageInfo.row
  const col = stageInfo.col
  const correctAnswer = answer;  // 예: 100을 정답으로 간주
  const answerCount = stageInfo.answerCount;
  const tolerance = stageInfo.tolerance;  // 오차범위 +-10
  
  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const generateGridValues = () => {
    let values = Array(row * col).fill(null);

    const zones = Math.sqrt(answerCount); // 예를 들어, 정답이 4개라면 그리드를 2x2로 나눕니다.

    // 각 구역에 정답 배치
    for (let z = 0; z < answerCount; z++) {
      const zoneRow = Math.floor(z / zones);
      const zoneCol = z % zones;

      const startRow = Math.floor(zoneRow * row / zones);
      const endRow = Math.floor((zoneRow + 1) * row / zones);

      const startCol = Math.floor(zoneCol * col / zones);
      const endCol = Math.floor((zoneCol + 1) * col / zones);

      let position;
      do {
        const randomRow = getRandomInt(startRow, endRow - 1);
        const randomCol = getRandomInt(startCol, endCol - 1);
        position = randomRow * col + randomCol;
      } while (values[position] !== null);

      values[position] = correctAnswer;
    }

    // 나머지 위치에 오차범위의 수 배치
    for (let i = 0; i < values.length; i++) {
      if (values[i] === null) {
        values[i] = correctAnswer + getRandomInt(-tolerance, tolerance);
      }
    }

    return values;
  };


  const [gridValues, setGridValues] = useState(() => {
    const values = generateGridValues()
    return values.map((v) => {
      return {
        isCorrect: false,
        isSelected: false,
        number: v
      }
    })
  })
  
  
  const checkCorrect = (value, index) => {
    value.isSelected = true
    value.isCorrect = answer === value.number
    
    setGridValues(_.clone(gridValues))
      
      
    const remain = remainFlag - 1
    setRemainFlag(remain)
      
    console.log(remain)
    if(0 === remain){
      const correctCount = gridValues.filter(v => v.isCorrect)
      props.onSelectEnded(correctCount === stageInfo.answerCount)
    }
  }
  
  const size = stageInfo.size
  const calcText = [start , '+' , end]
  
  return (
    <Grid container style={{minWidth: 1280}} >
      <Grid item xs={4}>
        
        <Box mt={8}>
          <Grid container justify={'center'}>
            {calcText.map((text , index) => {
              return (
                <Grid item key={index} xs={'auto'}>
                  <Box
                    style={{
                      fontSize: '2rem',
                      fontWeight: 'bold',
                      padding: 30,
                      background: '#ddd'
                    }}
                  >
                    {text}
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Box>
        <Box mt={5}>
          <Grid container justify={'center'} spacing={1}>
            {new Array(remainFlag).fill(0).map((_, index) => {
              return (
                <Grid item key={`answer-${index}`}>
                  <Box style={{
                    width: 80,
                    height: 80,
                    padding: 10,
                    background:'#ddd'
                  }}>
                    <img src={require(`@images/flagGreen.svg`)} width={'80%'}/>
                  </Box>
                </Grid>
              )  
            })}
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Grid container style={{ width: size * row }}>
          {gridValues.map((value, index) => {
            const backgroundColor = index % row === 0 ? '#FEF1BA' : '#FFF9D9'
            return (
              <Grid
                item
                key={index}
                style={{
                  textAlign: 'center',
                  width: size,
                  height: size,
                  backgroundColor,
                  fontWeight: 'bold',
                  fontSize: '1.1rem',
                  lineHeight: `${size - 5}px`,
                  position: 'relative'
                }}>
                  <Box
                   onClick={()=>{
                     checkCorrect(value, index)
                   }}
                  >
                    {value.number} 
                  </Box>

                  {value.isSelected &&
                    <div 
                      style={{
                        padding: 10,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: size,
                        height: size
                    }}>
                      {value.isCorrect 
                        ? <img src={require(`@images/flagGreen.svg`)} width={'60%'} alt="" style={{opacity:0.5}}/>
                        : <img src={require(`@images/flagRed.svg`)} width={'60%'} alt={""} style={{opacity:0.5}}/>
                      }
                    </div>
                  }
              </Grid>
            ) 
          })}
        </Grid>
      </Grid>
    </Grid>
    
  )
}

export default MatrixCalcBox