import React, {useCallback, useReducer} from 'react';
import { useHistory} from "react-router-dom";

import Moment from 'moment-timezone'
import MemberService from '@user-service/MemberService'

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import {Box, Button, IconButton, Typography, Grid} from '@material-ui/core';

import UserIdTextField from "./UserIdTextField";
import PasswordTextField from "./PassTextField";
import PhoneTextField from "./PhoneTextField";
import BirthDateTextField from "./BirthDateTextField";
import clsx from "clsx";
import FindOutCheck from "./FindOutCheck";
import NotificationBox from "./NotificationBox";
import Divider from "@material-ui/core/Divider";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useLocation } from "react-router-dom";
import CssTextField from "../../components/CssTextField";
import ProfileNameTextField from "./ProfileNameTextField";

export const InitState = {
    ableNext: false,
    
    profileName: '',
    loginID: '',
    password: '',
    cellNumber: '010',
    certNumber: '1111',
    birthDate: '',
    
    knowFrom: '',
    acceptNotify : true,
    
    isValidName: false,
    isValidLoginID: false,
    isValidPassword: false,
    isValidCertPhone: false,
    isValidBirthdate: false,
}

export function reducer(state, action){
    let newStatus
    switch(action.type){
        case 'UPDATE_LOGIN_ID':
            newStatus = {
                ...state,
                loginID: action.loginID,
                isValidLoginID:  action.isValid,
            }
            break
        case 'UPDATE_PASSWORD':
            newStatus = {
                ...state,
                password: action.password,
                isValidPassword:  action.isValid,
            }
            break
        case 'UPDATE_PROFILE_NAME':
            newStatus = {
                ...state,
                profileName: action.profileName,
                isValidName: action.isValid
            }
            break
        case 'UPDATE_CELL_NUMBER':
            newStatus = {
                ...state,
                cellNumber:  action.cellNumber,
                isValidCertPhone:  action.isValid,
                certNumber: action.certNumber,
            }
            break
        case 'UPDATE_BIRTH_DATE':
            newStatus = {
                ...state,
                birthDate: Moment(action.birthDate).format('YYYY-MM-DD'),
                isValidBirthdate:  action.isValid,
            }
            break
        case 'UPDATE_KNOW_FROM':
            newStatus = {
                ...state,
                knowFrom:  action.knowFrom,
            }
            break
        case 'UPDATE_ACCEPT_NOTIFY':
            newStatus = {
                ...state,
                acceptNotify: action.acceptNotify,
            }
            break
        case 'CLEAR':
            newStatus = {
                ...InitState
            }
            return newStatus
        default:
            break
    }
    
    
    
    if(newStatus.isValidLoginID &&
        newStatus.isValidBirthdate &&
        // newStatus.isValidCertPhone &&
        newStatus.isValidName &&
        newStatus.isValidPassword
    ) {
        newStatus.ableNext = true
    }
    
    return newStatus
}

const Join = () => {
    const classes = useStyles();
    
    const [state, dispatch]= useReducer(reducer, InitState)
    const { ableNext } = state 
    const history = useHistory()
    const location = useLocation()
    
    const { from, loginID, deviceSerial, computerMac, returnUrl, profileName} = location.state ? location.state : {}
    const isJoinFromDevice = 'device' === from
    
    const updateValidData = useCallback((param) => {
        dispatch({
            ...param,
        })
    }, [state])
    
    const joinHandler = async () => {
        if(isJoinFromDevice) {
            const res = await MemberService.joinWithDeviceRegister({
                ...state,
                deviceSerial,
                computerMac
            })
            
            if(res.success){
                if(returnUrl){
                    document.location.replace(returnUrl)
                }
            }
        } else {
            const res = await MemberService.join(state)
            if(res.success){
                history.replace('/joinDone')
            }    
        }
    }
    
    return (
        <React.Fragment>
            <Box className={classes.background} />
            <Box className={clsx(classes.logo,{
                [classes.hide] : isJoinFromDevice
            })}>
                <a href={"https://brhw.kr"}>
                    <img src={"https://brhw.kr/images/logo.png"} alt={'브레인헬스 로고'}/>
                </a>
            </Box>
            <Box className={classes.loginBoxWrap}>
                <Box className={classes.loginBox}>
                    <Box className={classes.back} onClick={() => { 
                        isJoinFromDevice
                            ? history.goBack()
                            : history.replace('/login')
                    }}>
                        <IconButton aria-label="delete">
                            <ArrowBackIcon style={{fontSize: 50}}/>
                        </IconButton>
                    </Box>
                    <Typography className={classes.title}>
                        처음이시군요.
                    </Typography>
                    <Typography className={classes.title}>
                        <span style={{fontWeight: 600}}>회원가입</span>을 진행합니다.
                    </Typography>
                    
                    <Box style={{marginTop: 40, marginBottom: 55}}>
                        <Grid container direction={'column'} spacing={4}>
                            {isJoinFromDevice &&
                            <Grid item xs={12}>
                                <CssTextField
                                    label="BRHW ID"
                                    fullWidth
                                    defaultValue={deviceSerial}
                                    disabled
                                />
                            </Grid>
                            }
                            <Grid item xs={12}>
                                <UserIdTextField 
                                    placeholder={'아이디는 영문,숫자 조합으로 사용가능합니다.'}
                                    checkLoginID
                                    defaultValue={loginID}
                                    valid={(isValid, value) => {
                                        updateValidData({
                                            type: 'UPDATE_LOGIN_ID',
                                            loginID: value,
                                            isValid
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ProfileNameTextField
                                    placeholder={'이름'}
                                    defaultValue={profileName}
                                    valid={(isValid, value) => {
                                        updateValidData({
                                            type: 'UPDATE_PROFILE_NAME',
                                            profileName: value,
                                            isValid: isValid
                                        })      
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PasswordTextField
                                    placeholder={'비밀번호는 6자리'}
                                    valid={(isValid, value) => {
                                        updateValidData({
                                            type: 'UPDATE_PASSWORD',
                                            password: value,
                                            isValid
                                        })
                                    }}
                                />
                            </Grid>
                            {/*<Grid item xs={12}>*/}
                            {/*    <PhoneTextField */}
                            {/*        valid={(isValid, value, certNumber) => {*/}
                            {/*            updateValidData({*/}
                            {/*                type: 'UPDATE_CELL_NUMBER',*/}
                            {/*                cellNumber: value,*/}
                            {/*                certNumber,*/}
                            {/*                isValid*/}
                            {/*            })*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            <Grid item xs={12}>
                                <BirthDateTextField
                                    valid={(isValid, value) => {
                                        updateValidData({
                                            type: 'UPDATE_BIRTH_DATE',
                                            birthDate: value,
                                            isValid
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    
                    <FindOutCheck
                        valid={(isValid, value) => {
                            updateValidData({
                                type: 'UPDATE_KNOW_FROM',
                                knowFrom: value,
                                isValid
                            })
                        }}    
                    />
    
                    <Grid container style={{marginTop: 60}}>
                        <Grid item xs={12}>
                            <Button
                                onClick={joinHandler}
                                disabled={!ableNext}
                                className={clsx(classes.registerButton, {
                                    [classes.nextAble]: ableNext,
                                    [classes.next]: !ableNext
                                })}>가입완료</Button>
    
                            <Box className={classes.explain}>
                                본인은 만 14세 이상이며, 이용안관, 개인정보수집 및 <br/>
                                이용개인정보제공 내용, 전자금융거래 약관을 확인하였으며 <br/>
                                이에 동의합니다.
                            </Box>
                        </Grid>
                    </Grid>
                    
                    <Divider style={{marginTop:35, marginBottom: 40}}/>
                    <NotificationBox
                        valid={(isValid, value) => {
                            updateValidData({
                                type: 'UPDATE_ACCEPT_NOTIFY',
                                acceptNotify: value,
                                isValid
                            })
                        }}
                    />
                </Box>
            </Box>
        </React.Fragment>
    );
}


const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center"
    },
    back: {
        position: 'absolute',
        top: 10,
        left: 30,
        cursor: 'pointer'
    },
    logo: {
        position: 'fixed',
        left: 30 ,
        top: 22,
        zIndex: 0,
        width: `100%`,
    },
    title: {
        fontSize: 40,
        fontFamily: 'Lato, Noto Sans KR',
        lineHeight: '45px',
        fontWeight: 300,
        color: '#333',
        letterSpacing: '-2px'
    },
    requestButton: {
        backgroundColor: '#8799AD',
        color: '#fff',
        height: 35,
        borderRadius: 10,
        marginTop: 10,
        paddingLeft: 10,
        '&:hover' : {
            backgroundColor: '#5D6C7D',
        }
    },
    buttonIcon: {
        position: 'absolute',
        left: 20,
        top: 'center',
        zIndex: 1,
        width: 32,
        height: 32
    },
    buttonIconRight: {
        position: 'absolute',
        right: 20,
        top: 'center',
        zIndex: 1,
        width: 32,
        height: 32
    },
    registerButton: {
        position: 'relative',
        width: '100%',
        height: 60,
        borderRadius: 10,
        color: '#333',
        fontSize: 19,
        '&:hover': {
            backgroundColor: '#04308E'
        }
    },
    phone: {
        background: '##fff',
        color: '#333',
        border: `1px solid #0052C1`
    },
    next: {
        backgroundColor: '#ddd',
        color: '#999'
    },
    nextAble: {
        backgroundColor: '#0053BF',
        color: 'white'
    },
    formBox: {
        marginBottom: 60
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
        backgroundImage: `url(${require(`@assets/images/simplebg01.jpg`)})`,
        backgroundSize: 'cover'
    },
    loginBoxWrap: {
        top: 110,
        left: `calc(50% - 250px)`,
        // transform: `translate(-50%, -50%)`,
        position: 'absolute',
        width: 500,
        minHeight: 670,
        zIndex: 2,
    },
    loginBox: {
        borderRadius: 10,
        backgroundColor: '#fff',
        padding: 50,
        width: '100%',
        paddingTop: 95,
        marginBottom: 110
    },
    bottom: {
        position: "absolute",
        bottom: 30,
        width: 500,
        color: '#333',
        fontSize: 14,
        textAlign: 'center'
    },
    textField: {
        backgroundColor: '#ddd'
    },
    explain: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        fontSize: 14,
        marginTop: 20,
        color: '#333',
    },
    hide: {
        display: 'none'
    }
}));

//for redux 
Join.propType = {
    text: PropTypes.string.isRequired
}

export default Join
