import React, {useState, useEffect, useCallback} from 'react'

import ProfileService from '@user-service/ProfileService'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography, Grid, Button } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import CustomerSearchText from "../manage/customer/CustomerSearchText";
import BridgeProvider from "../../provider/BridgeProvider";
import ProfileSelectorBox from "./ProfileSelectorBox";
import CustomerService from "../../services/manage/CustomerService";
import AuthProvider from "../../provider/AuthProvider";
import {useCookies} from "react-cookie";
import SocketProvider from "../../provider/SocketProvider";
import clsx from "clsx";
import ReplayIcon from "@material-ui/icons/Replay";
import CenterSelect from "../../components/CenterSelect";
import MeasureService from "../../services/MeasureService";
import UpdateGender from "../mypage/measureSelect/UpdateGender";
import DeviceStatusBox from "../../components/DeviceStatusBox";
import LastProfileSelect from "../../components/LastProfileSelect";

const Profile = ({history}) => {
  const classes = useStyles()
  
  const savedCenterId = localStorage.getItem('centerID')
  
  const [profiles, setProfiles] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [lastProfileName, setLastProfileName] = useState()
  const [centerID, setCenterID] = useState(savedCenterId ?  savedCenterId : 10)
  
  const [currentProfile, setCurrentProfile] = useState(null)
  
  const [,setCookie] = useCookies()
  const fetchProfile = useCallback(async () => {
    let { profiles, lastProfileName } = await ProfileService.getProfilesSelector(keyword, centerID)
    if('' !==  keyword){
      profiles = profiles.filter(p => !p.isNew)
    }
    setProfiles(profiles)
    setLastProfileName(lastProfileName)
    
  }, [keyword, centerID])

  useEffect(() => {
    const originalCookie = localStorage.getItem(AuthProvider.userAdminCookieName)
    if(originalCookie){
      setCookie(AuthProvider.userCookieName, originalCookie , AuthProvider.getCookieOption())  
    }

    
    BridgeProvider.instance.closeLoading()
  }, [])
  
  useEffect(()=>{
    fetchProfile().then()
  },[fetchProfile])
  

  const handleCustomerView = async (profile) => {
    if('profile-selector' !== AuthProvider.get('memberType')) {
      alert('관리자로 다시 로그인 해 주세요');
      return
    }
    
    const profileSelectorID = Number(AuthProvider.getMemberID())
    const serviceType = AuthProvider.getServiceType()
    
    await CustomerService.setCustomerView(profile.memberID, profile.id, 'profileSelector')

    AuthProvider.set('profileSelectorID' , profileSelectorID)
    AuthProvider.set('serviceType' , serviceType)

    setCookie(AuthProvider.userCookieName, AuthProvider.getUserData(), AuthProvider.getCookieOption())
    SocketProvider.disconnect()

    //마지막 사용자 이름을 기록합니다.
    await ProfileService.setLastProfileName(profileSelectorID, profile.id, AuthProvider.getProfileName())
    
    let path = '/dashboard'
    if(AuthProvider.isAppMode()){
        const page = AuthProvider.getAppServicePage()
        path = MeasureService.getMeasurePageUrl(page)
    }
    
    history.push(path)
  }

  if(!profiles){
    return(<LoadingSkeleton/>)
  }
  
  return (
    <Box className={classes.root}>
        <Box className={classes.background} />
        <Box display={'flex'} justifyContent={'center'} style={{position:'fixed', top:22, width: '100%'}}>
            <Grid container>
                <Grid item xs={3}>
                    <Box className={classes.logo} onClick={() => {
                        // if(AuthProvider.isAppMode()) {
                            // if(!window.confirm('앱의 캐시를 삭제 하시겠습니까?')) return
                            // BridgeProvider.instance.clearCache()
                            // document.location.reload()
                        // }
                    }}>
                        <img src={"https://brhw.kr/images/logo.png"} alt={'브레인헬스 로고'}/>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <CenterSelect 
                          defaultValue={centerID}
                          onChange={(centerID) => {
                            localStorage.setItem('centerID', centerID)
                            setCenterID(centerID)
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomerSearchText
                          defaultValue={keyword}  
                          keyup={async (name) => {
                          setKeyword(name)
                        }}/>
                      </Grid>
                      <Grid item xs={'auto'}>
                        {lastProfileName && 
                          <Button style={{padding:14}} className={clsx(classes.editButton,{
                            [classes.normal]: true
                          })} 
                            onClick={() => {
                              if(lastProfileName) {
                                setKeyword(lastProfileName)
                              }
                            }}
                          >
                            {lastProfileName}
                          </Button>
                        }
                      </Grid>
                     
                    </Grid> 
                </Grid>
                <Grid item xs={3}>
                  <Grid container justify="flex-end" style={{paddingRight: 30}}>
                    <Grid item>
                      <Button className={clsx(classes.editButton,{
                        [classes.normal]: true
                      })} style={{border:0}}
                      onClick={() => {
                          if(AuthProvider.isAppMode()) {
                              if (!window.confirm('앱의 캐시를 삭제 하시겠습니까?')) return
                              BridgeProvider.instance.clearCache()
                              document.location.reload()
                          } else {
                              fetchProfile().then()
                          }
                      }}        
                      >
                          <ReplayIcon/>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button className={clsx(classes.editButton,{
                        [classes.normal]: true
                      })} style={{border:0}} size="large"
                        onClick={() => {
                          if(!window.confirm('로그아웃 하시겠습니까?')) return
                          history.replace('/logout')
                        }}      
                      >
                        {AuthProvider.get('loginID')}
                      </Button>  
                    </Grid>
                  </Grid>
                </Grid>
            </Grid>
        </Box>
          

          <Box display={'flex'} justifyContent={'center'}>
              <Grid container justify="center" spacing={3} className={classes.profileWrap}>
                  {profiles.map(profile => {
                      return (
                          <Grid key={profile.id} item>
                            <ProfileSelectorBox 
                              click={(profile) => {
                                if('' !== profile.gender) {
                                  handleCustomerView(profile).then()
                                } else {
                                  setCurrentProfile(profile)
                                }
                              }}
                              {...profile}
                            />
                          </Grid>
                      )
                  })}

                  {((!profiles || 0 === profiles.length) && '' !== keyword) &&
                      <Grid item style={{color: 'white', zIndex:99}}>검색된 사용자가 없습니다.</Grid>
                  }
              </Grid>
          </Box>

          { currentProfile && 
            <UpdateGender 
              profileID={currentProfile.id} 
              open={!!currentProfile}
              onCancel={() => setCurrentProfile(null)}
              onSave={() => {
                handleCustomerView(currentProfile).then(() => {
                  setCurrentProfile(null)  
                })
              }}
            />
          }
          
        <DeviceStatusBox />
      </Box>
  )
}


//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.background} />
            <div className="editprofile_wrap wrapper">
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </div>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        justify: "center",
        alignItems: "center",
        paddingTop: 100,
        paddingBottom: 100,
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
        backgroundColor: '#1E2030',
    },
    title: {
        color: 'white',
        textAlign: 'center',
    },
    add: {
        position: 'fixed',
        right: 30 ,
        top:30,
        zIndex: 0,
        color: 'white',
    },
    logo: {
        position: 'fixed',
        left: 30 ,
        // top: 30,
        zIndex: 0,
        width: `100%`,
    },
    profileWrap: {
        marginTop:20,
        width: '100%',
        maxWidth: 1200
    },
    edit: {
        backgroundColor: '#fff',
        color: '#333',
        '&:hover': {
            backgroundColor: '#CCCCCC'
        }
    },
    normal: {
        color: 'white',
        backgroundColor: '#1E2030',
        '&:hover': {
            backgroundColor: '#12131D'
        }
    },
    isNew: {
        backgroundColor: '#4B4D5A',
        '&:hover': {
            backgroundColor: '#2F303A',    
        }
    },
    card: {
        width: 184,
        height: 184,
        cursor: 'pointer',
        backgroundColor: '#fff',
        borderRadius: 5,
        position: 'relative',
        '& img':  {
            width: 184,
            height: 184
        },
        '& .profileName': {
            color: '#FFFEFE',
            fontSize: 18,
            fontWeight: 500,
            marginTop: 20,
            textAlign: 'center'
        },
        '& .profileName.new': {
            color: '#AEACB0',
            fontWeight: 'normal'
        },
        '& .wrap': {
            width: 184,
            height: 184,
        },
        '& .addIcon': {
            position: 'absolute',
            left: 'calc(50% - 36px)',
            top: '60px',
            fontSize: 70,
            color: '#fff',
            zIndex: 2
        }
    },
    editProfile: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: 184,
        height: 184,
        '& .background': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: 184,
            height: 184,
            backgroundColor: '#000',
            opacity: 0.5,
            zIndex: 1,
            '&:hover': {
                opacity: 0.7
            }
        },
        '& .editIcon' : {
            position: 'absolute',
            left: 'calc(50% - 40px)',
            top: '60px',
            fontSize: 70,
            color: '#fff',
            zIndex: 2
        }
    },
    square: {
        width: '100%',
        height: '100%'
    },
    editWrap: {
        marginTop: 170,  
    },
    editButton: {
        fontSize:  17,
        // fontSize:  20,
        // width: 220,
        // height: 70,
        border: 1,
        borderStyle: 'solid',
        borderColor: '#4B4D5A',
    },
    newProfile: {
        width: '100%',
        height: '100%',
        background:'#eee',
        color:'#999'
    },
    media: {
        height: 170,
    }
}));


export default Profile
