import React, {useState, useEffect, useCallback} from 'react'

import {makeStyles} from '@material-ui/core/styles'
import {Box, Grid, IconButton, Typography} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';


import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import DateHelper from '@helper/DateHelper'
import CenterService from "../../../services/manage/CenterService";
import LoadingIndicator from "../../../components/LoadingIndicator";
import AddIcon from "@material-ui/icons/Add";
import CustomerSearchDialog from "../customer/CustomerSearchDialog";
import CustomerService from "../../../services/manage/CustomerService";

const CenterCustomerList = ({searchKeyword, centerID, centerName}) => {
    const [openDialog, setOpenDialog] = useState(false)
    const [customers, setCustomers] = useState(null)
    
    const classes = useStyles()
    const fetchCenterCustomer = useCallback(async () => {
        const customers = await CenterService.getCenterCustomers(centerID, searchKeyword)
        setCustomers(customers.group)
    }, [searchKeyword, centerID])

    useEffect(()=>{
        fetchCenterCustomer().then()
        
        return (() => setCustomers(null))
    },[fetchCenterCustomer])


    if(!customers){
        return(<LoadingIndicator/>)
    }
    
    const customersKey = Object.keys(customers);
    return (
        <Box className={classes.root}>
            <CustomerSearchDialog 
                centerID={centerID} 
                centerName={centerName}
                open={openDialog} 
                onClose={() => {
                    setOpenDialog(false)
                    fetchCenterCustomer().then()
                }}/>
            <Box display="flex" justifyContent={'flex-end'}>
                <IconButton
                    className={classes.button}
                    onClick={() => {
                        setOpenDialog(true)
                    }} 
                >
                    <AddIcon/>
                </IconButton>
            </Box>
            {
                customersKey.map((key) => {
                    const { memberName, profiles, loginID } = customers[key]
                    return(
                        <List key={key} >
                            <Typography variant={'h5'}>{memberName}</Typography>
                            <div className={classes.small}>{loginID}</div>
                            {profiles.map((profile, index) => {
                                const { name, code, lastLoginDate, profileID } = profile
                                return (
                                    <React.Fragment key={index}>
                                        <ListItem 
                                            onClick={async () => {
                                                // await handleCustomerView(profile.memberID, profile.profileID)
                                            }}>
                                            <ListItemAvatar>
                                                <Avatar variant="rounded" className={classes.large}>
                                                    {profile.image
                                                        ? <img src={require(`@images/profile/${profile.image}`)} alt={name}/>
                                                        : <ImageIcon/>
                                                    }
                                                </Avatar>
                                            </ListItemAvatar>

                                            <ListItemText primary={
                                                <Box style={{paddingLeft: 10}}>
                                                    {code}
                                                </Box>
                                            } secondary={
                                                <span style={{paddingLeft: 10, color: '#999'}}> 접속일: {DateHelper.format(lastLoginDate, 'YYYY.MM.DD')}</span>
                                            } />

                                            <ListItemSecondaryAction>
                                                <Grid container spacing={2}>
                                                    {/*<Grid item >*/}
                                                    {/*    <CenterStaffSelect />*/}
                                                    {/*</Grid>*/}
                                                    <Grid item >
                                                        <Button 
                                                            onClick={() => {
                                                                CustomerService.removeCustomer(centerID, profileID).then()
                                                                customers[key].profiles = profiles.filter( profile => profile.profileID !== profileID)
                                                                if(!customers[key].profiles || 0 === customers[key].profiles.length){
                                                                    delete customers[key]
                                                                }
                                                                
                                                                setCustomers({
                                                                    ...customers
                                                                })
                                                            }}
                                                            size={'large'} 
                                                            className={classes.backGray}>
                                                            삭제
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                )
                            })}
                        </List>
                    )
                })
            }
        </Box>
    )
}


//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <div className="editprofile_wrap wrapper">
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </div>
        </Box>
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center",
        maxWidth: 1280,
        height: '100vh',
        margin: 'auto'
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
        backgroundColor: '#1E2030',
    },
    title: {
        textAlign: 'center',
        marginBottom: 100,
        fontSize: 42
    },
    small: {
        fontSize: '0.8rem',
        fontWeight: 600,
        color: '#333',
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),

        backgroundColor: 'transparent',
        '& img': {
            width: '100%'
        }
    },
    button: {
        backgroundColor: '#014FB4',
        color: 'white',

        '&:hover': {
            backgroundColor: '#052D84'
        }
    }
}));


export default CenterCustomerList
