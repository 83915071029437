import {Box, Card, CardActionArea, CardContent, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import MeasureBoxBar from '@user-components/MeasureBoxBar'
import { useHistory } from "react-router-dom";
import AuthProvider from "@user-provider/AuthProvider";
import DateHelper from "../../../helper/DateHelper";
/**
 * 뇌기능 내면 상태  δ, θ, α, SMR, β, βM, βh로 표시합니다.
 * @param {*} props
 */
export default function BalanceBar(props) {
    const classes = useStyles()
    const history = useHistory()

    const ableClick = true !== props.clickDisabled
    
    const { id ,
        rest , attemption, concentration, brainactive,
        restPercent , attemptionPercent, concentrationPercent, brainactivePercent,
        measureRest , measureAttemption, measureConcentration, measureBrainactive,
        isBalance, balanceDate
        
    } = props.measureResult;

    useEffect(() => {
    }, []);


    const handler = () => {
        if(AuthProvider.canViewMeasureDetail() && ableClick) {
            if ('yes' === isBalance) {
                history.push('/measureDetail/' + id + '/balance');
            }
        }
    }

    return (
        <Box className={classes.root}>
            <Card className={classes.card} onClick={handler}>
                <CardActionArea>
                    <CardContent>
                        <Typography variant={'subtitle1'} className={classes.title}>
                            뇌기능 균형상태
                            {balanceDate &&
                             <span>({DateHelper.format(balanceDate, 'YYYY.MM.DD HH:mm')})</span>
                            }
                        </Typography>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box mt={5}>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Box textAlign={'center'}>안정 상태</Box>
                                            <Box textAlign={'center'} fontSize={20} mt={1}>
                                                <b>{ restPercent ? restPercent : 0  }%</b>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Box textAlign={'center'}>주의력 상태</Box>
                                            <Box textAlign={'center'} fontSize={20} mt={1}>
                                                <b>{ attemptionPercent ? attemptionPercent : 0 }%</b>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Box textAlign={'center'}>집중력 상태</Box>
                                            <Box textAlign={'center'} fontSize={20} mt={1}>
                                                <b>{ concentrationPercent ? concentrationPercent : 0 }%</b>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Box textAlign={'center'}>뇌활성 상태</Box>
                                            <Box textAlign={'center'} fontSize={20} mt={1}>
                                                <b>{ brainactivePercent ? brainactivePercent : 0 }%</b>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box mt={ 5 } >
                                    <MeasureBoxBar title={ '안정 지수' } width={5} height={16} value={ rest } color={ 'blue' } data={ measureRest }/>
                                    <MeasureBoxBar title={ '주의력 지수' } width={5} height={16} value={ attemption } color={ 'green' } data={ measureAttemption }/>
                                    <MeasureBoxBar title={ '집중력 지수' } width={5} height={16} value={ concentration } color={ 'red' } data={ measureConcentration }/>
                                    <MeasureBoxBar title={ '집중력 지수' } width={5} height={16} value={ brainactive } color={ 'purple' } data={ measureBrainactive }/>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    root: {
    },
    title: {
        fontWeight: 'bold',
        paddingLeft: 15,
        marginBottom: 20,

        '& span':{
            fontWeight: 'normal',
            fontSize: 14,
            paddingLeft: 5
        }
    },
    card: {
        border: "none", 
        boxShadow: "none",
        padding: 0
    },
}));

