import React, {useCallback, useReducer, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Box, Button, Grid, Typography} from "@material-ui/core";
import UserIdTextField from "../../login/UserIdTextField";
import ProfileNameTextField from "../../login/ProfileNameTextField";
import PasswordTextField from "../../login/PassTextField";
import BirthDateTextField from "../../login/BirthDateTextField";
import { useLocation} from "react-router-dom";
import {InitState, reducer} from "../../login/Join";
import MemberService from "../../../services/MemberService";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import NotificationBox from "../../login/NotificationBox";
import PhoneTextField from "../../login/PhoneTextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";

const CustomerAddDialog = (props) => {
    const classes = useStyles();

    const [ key, setKey ] = useState(Math.random())
    const { open, onClose } = props
    const [state, dispatch]= useReducer(reducer, InitState)
    const { ableNext } = state
    
    const [simpleMode, setSimpleMode] = useState(false)

    const updateValidData = useCallback((param) => {
        dispatch({
            ...param,
        })
    }, [state])
    
    

    const joinHandler = async () => {
        const res = await MemberService.join(state)
        if(res.success){
            setKey(Math.random())            
        } else {
            alert('등록에 실패했습니다.\n관리자에게 문의하세요')
        }
    }
    
    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose(false)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{width: 500}}>
                <Typography gutterBottom className={classes.text}>
                    사용자 등록
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Box style={{ marginBottom: 100}}>
                    <Grid container direction={'column'} spacing={4}>
                        <Grid item xs={12}>
                            <UserIdTextField
                                key={`user-${key}`}
                                placeholder={'아이디는 영문,숫자 조합으로 사용가능합니다.'}
                                checkLoginID
                                defaultValue={''}
                                valid={(isValid, value) => {
                                    updateValidData({
                                        type: 'UPDATE_LOGIN_ID',
                                        loginID: value,
                                        isValid
                                    })

                                    if(simpleMode && isValid){
                                        updateValidData({
                                            type: 'UPDATE_CELL_NUMBER',
                                            cellNumber: value,
                                            certNumber: '',
                                            isValid
                                        })
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ProfileNameTextField
                                key={`name-${key}`}
                                placeholder={'이름'}
                                defaultValue={''}
                                valid={(isValid, value) => {
                                    updateValidData({
                                        type: 'UPDATE_PROFILE_NAME',
                                        profileName: value,
                                        isValid: isValid
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordTextField
                                key={`password-${key}`}
                                placeholder={simpleMode ? '생년월일(20020101)' : '비밀번호는 6자리'}
                                showPassword={true}
                                useBirthDate={simpleMode}
                                valid={(isValid, value) => {
                                    updateValidData({
                                        type: 'UPDATE_PASSWORD',
                                        password: value,
                                        isValid
                                    })
                                    
                                    if(simpleMode && isValid){
                                        updateValidData({
                                            type: 'UPDATE_BIRTH_DATE',
                                            birthDate: value,
                                            isValid
                                        })
                                    }
                                }}
                            />
                        </Grid>
                       
                        <Grid item>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                    <FormControlLabel
                                        className={classes.label}
                                        control={<Checkbox
                                            checked={simpleMode}
                                            onChange={() => {
                                                setSimpleMode(!simpleMode)
                                            }}
                                            className={classes.input}
                                            name="1"/>}
                                        label={
                                            <>
                                                <span className={classes.label}>간편 회원가입 </span>
                                            </>
                                        }
                                    />
                                    <span className={classes.label}>입력된 아이디는 휴대폰 번호에 등록되며, 비밀번호는 생년월일로 자동 등록됩니다 </span>
                                </FormGroup>
                            </FormControl>
                        </Grid>

                        {!simpleMode &&
                        <>
                            <Grid item xs={12}>
                                <PhoneTextField
                                    hideRequestButton={true}
                                    valid={(isValid, value, certNumber) => {
                                        updateValidData({
                                            type: 'UPDATE_CELL_NUMBER',
                                            cellNumber: value,
                                            certNumber,
                                            isValid
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BirthDateTextField
                                    valid={(isValid, value) => {
                                        updateValidData({
                                            type: 'UPDATE_BIRTH_DATE',
                                            birthDate: value,
                                            isValid
                                        })
                                    }}
                                />
                            </Grid>
                        </>
                        }
                    </Grid>
                </Box>
                <Grid container style={{marginTop: 60}}>
                    <Grid item xs={12}>
                        <Button
                            onClick={joinHandler}
                            disabled={!ableNext}
                            className={clsx(classes.registerButton, {
                                [classes.nextAble]: ableNext,
                                [classes.next]: !ableNext
                            })}>가입완료</Button>

                        <Box className={classes.explain}>
                            본인은 만 14세 이상이며, 이용안관, 개인정보수집 및 <br/>
                            이용개인정보제공 내용, 전자금융거래 약관을 확인하였으며 <br/>
                            이에 동의합니다.
                        </Box>
                    </Grid>
                </Grid>

                <Divider style={{marginTop:35, marginBottom: 40}}/>
                <NotificationBox
                    valid={(isValid, value) => {
                        updateValidData({
                            type: 'UPDATE_ACCEPT_NOTIFY',
                            acceptNotify: value,
                            isValid
                        })
                    }}
                />
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center"
    },
    content: {
        padding: 50
        
    },
    back: {
        position: 'absolute',
        top: 10,
        left: 30,
        cursor: 'pointer'
    },
    logo: {
        position: 'fixed',
        left: 30 ,
        top: 22,
        zIndex: 0,
        width: `100%`,
    },
    title: {
        fontSize: 40,
        fontFamily: 'Lato, Noto Sans KR',
        lineHeight: '45px',
        fontWeight: 300,
        color: '#333',
        letterSpacing: '-2px'
    },
    requestButton: {
        backgroundColor: '#8799AD',
        color: '#fff',
        height: 35,
        borderRadius: 10,
        marginTop: 10,
        paddingLeft: 10,
        '&:hover' : {
            backgroundColor: '#5D6C7D',
        }
    },
    buttonIcon: {
        position: 'absolute',
        left: 20,
        top: 'center',
        zIndex: 1,
        width: 32,
        height: 32
    },
    buttonIconRight: {
        position: 'absolute',
        right: 20,
        top: 'center',
        zIndex: 1,
        width: 32,
        height: 32
    },
    registerButton: {
        position: 'relative',
        width: '100%',
        height: 60,
        borderRadius: 10,
        color: '#333',
        fontSize: 19,
        '&:hover': {
            backgroundColor: '#04308E'
        }
    },
    phone: {
        background: '##fff',
        color: '#333',
        border: `1px solid #0052C1`
    },
    next: {
        backgroundColor: '#ddd',
        color: '#999'
    },
    nextAble: {
        backgroundColor: '#0053BF',
        color: 'white'
    },
    formBox: {
        marginBottom: 60
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
        backgroundImage: `url(${require(`@assets/images/simplebg01.jpg`)})`,
        backgroundSize: 'cover'
    },
    loginBoxWrap: {
        top: 110,
        left: `calc(50% - 250px)`,
        // transform: `translate(-50%, -50%)`,
        position: 'absolute',
        width: 500,
        minHeight: 670,
        zIndex: 2,
    },
    loginBox: {
        borderRadius: 10,
        backgroundColor: '#fff',
        padding: 50,
        width: '100%',
        paddingTop: 95,
        marginBottom: 110
    },
    bottom: {
        position: "absolute",
        bottom: 30,
        width: 500,
        color: '#333',
        fontSize: 14,
        textAlign: 'center'
    },
    textField: {
        backgroundColor: '#ddd'
    },
    explain: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        fontSize: 14,
        marginTop: 20,
        color: '#333',
    },
    hide: {
        display: 'none'
    },

    stitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#333'
    },
    label: {
        fontSize: 14,
        color: '#333',
    },
    input: {
        padding: 2,
    },
    formControl: {
        fontSize: 14,
        '& .Mui-checked .MuiSvgIcon-root': {
            color : '#1261BD'
        }
    }
}));


export default CustomerAddDialog