import React, {useEffect, useState, useCallback} from "react";
import { makeStyles } from '@material-ui/core/styles';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import CustomerService from "@user-service/manage/CustomerService";
import CustomerSearchText from "./CustomerSearchText";
import {Box, Grid, Typography} from "@material-ui/core";
import AuthProvider from "../../../provider/AuthProvider";
import InfoIcon from "@material-ui/icons/Info";
import {green, yellow} from "@material-ui/core/colors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const CustomerDataMoveDialog = (props) => {
    const classes = useStyles();
    
    const { open, onClose } = props
    const [transfer, setTransfer] = useState('')
    const [ members, setMembers ] = useState([])
    const [selectMember, setSelectMember] = useState(null)
    const [profile] = useState(props.profile)
    
    const confirmMoveData = async () => {
        const success = await CustomerService.moveToData(profile.id, selectMember.id )
        if(!success){
          window.alert('프로필 이전에 실패했습니다.');
        }
        setTransfer('doing')
        
        setTimeout(() => {
            setTransfer('done')
            setTimeout(() => {
                if(onClose) onClose(true)     
            }, 1000)
        }, 1000)
    }
    
   
    const fetchCustomer = useCallback(async (name) => {
        const members = await CustomerService.getCustomerWithLoginIDs(name, 30)
        setMembers(members)
    }, [])
    
    useEffect(() => {
        if(open){
            fetchCustomer().then()    
        }
    }, [open, fetchCustomer])
    
    return (
        <Dialog
            open={open}
            onClose={() => {
                setTransfer('')
                setSelectMember(null)
                onClose(false)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{width: 600}}>
                <Typography gutterBottom className={classes.text}>
                    프로필을 이전 할 아이디를 선택하세요
                </Typography>
                <CustomerSearchText keyup={async (name) => {
                    await fetchCustomer(name)
                }}/>
            </DialogTitle>
            <DialogContent style={{height: 700}}>
                {selectMember &&
                    <Box display={'flex'} justifyContent={'center'} mt={10}>
                        <Box textAlign={'center'}>
                            <Box>
                                {'done' !== transfer
                                    ? <InfoIcon style={{ fontSize: 150, color: yellow[500] }} />
                                    : <CheckCircleIcon style={{ fontSize: 150, color: green[500] }} />
                                }
                            </Box>
                            <Box p={3}>
                                <Typography variant={'h4'}>{AuthProvider.get('loginID')}</Typography>
                                <ArrowDownwardIcon style={{fontSize:60,padding: 10}}/>
                                <Typography variant={'h4'}>{selectMember.loginID}</Typography>
                                <br/>
                                <Typography variant={'h5'}>
                                    {'done' !== transfer 
                                        ? '계정으로 프로필을 이전합니다.'
                                        : '완료되었습니다.'
                                    }
                                </Typography>
                            </Box>
                            <Grid container spacing={1} justify={'center'} style={{marginTop: 50}}>
                                <Grid item>
                                    <Button
                                        size={'large'}    
                                        variant={'contained'} 
                                        color={'primary'} 
                                        disabled={'' !== transfer}
                                        onClick={confirmMoveData}>이전하기</Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        size={'large'}
                                        variant={'outlined'}
                                        disabled={'' !== transfer}
                                        onClick={() => { 
                                            setSelectMember(null)
                                            setTransfer('')
                                        }}>
                                        취소
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                }
                {!selectMember &&
                <List className={classes.root}>
                    {members.map((member, index) => {
                        const {name, loginID} = member
                        return (
                            <React.Fragment key={index}>
                                <ListItem>
                                    <ListItemText primary={
                                        <Box style={{paddingLeft: 10}}>
                                            {name}
                                        </Box>
                                    } secondary={
                                        <span style={{paddingLeft: 10}}>{loginID}</span>
                                    }/>

                                    <ListItemSecondaryAction>
                                        <Grid container spacing={2}>
                                            <Grid item style={{paddingTop: 13}}>
                                            </Grid>
                                            <Grid item>
                                                {member.id !== AuthProvider.getMemberID() 
                                                    ?
                                                    <Button 
                                                        variant={'outlined'} 
                                                        onClick={async () => {
                                                            setSelectMember(member)
                                                    }}>선택</Button>
                                                    :
                                                    <span>로그인 계정</span>
                                                }
                                            </Grid>
                                        </Grid>

                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider/>
                            </React.Fragment>
                        )
                    })}
                </List>
                }
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        
        backgroundColor: 'transparent',
        '& img': {
            width: '100%'
        }
    },
}));

export default CustomerDataMoveDialog