

const initState = {
    measure: {state:'stop', time:0, data:'', allData: '' },
    device: {
        deviceSerial: ''
    }
  }
  
export function deviceState(){
    return initState
}
  
const DeviceReducer = (state = initState, action) => {
    switch (action.type) {
      case 'measureStart':
        return {
            ...state,
        }
        case 'setDevice':
            alert(action.version);
            return {
                ...state,
                version: action.version,
                deviceSerial: action.deviceSerial
            }   
      default:
        return state
    }
}
  
export default DeviceReducer
