import React, {useState, useEffect, useCallback} from 'react'

import {makeStyles, withStyles} from '@material-ui/core/styles'
import {Box, Typography} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';

import CustomerService from "@user-service/manage/CustomerService";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import ListItemText from "@material-ui/core/ListItemText";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import DateHelper from '@helper/DateHelper'

import Rating from "@material-ui/lab/Rating";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import FavoriteIcon from "@material-ui/icons/Favorite";

const StyledRating = withStyles({
    iconFilled: {
        color: '#ff6d75',
    },
    iconHover: {
        color: '#ff3d47',
    },
})(Rating);

const NoteList = ({searchKeyword}) => {
    const [notes, setNotes] = useState()
    const classes = useStyles()

    const fetchPrescription = useCallback(async () => {
        const notes = await CustomerService.getNotes(searchKeyword)
        setNotes(notes)
    }, [searchKeyword])

    useEffect(()=>{
        fetchPrescription().then()
    },[fetchPrescription])


    if(!notes){
        return(<LoadingSkeleton/>)
    }

    return (
        <Box className={classes.root}>
            {0 === notes.length &&
            <List style={{color: 'white', marginTop: 25}}>
                <ListItem>
                    <ListItemText primary={
                        <Box style={{paddingTop: 30, textAlign: 'center', color: '#999'}}>
                            <Typography> 기록이 없습니다</Typography>
                        </Box>
                    } />
                </ListItem>
            </List>
            }

            <List style={{color: 'white', marginTop: 25}}>
                {
                    notes.map((note, index) => {
                        const {  profileName, profileImage, createDate, name, content, rating } = note
                        return(
                            <React.Fragment key={index}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar variant="rounded" className={classes.large}>
                                            {profileImage
                                                ? <img src={require(`@images/profile/${profileImage}`)} alt={profileName}/>
                                                : <ImageIcon/>
                                            }
                                        </Avatar>
                                    </ListItemAvatar>

                                    <ListItemText primary={
                                        <Box style={{paddingLeft: 10, maxWidth: '70%'}}>
                                            <span className={classes.small}> {profileName}</span>
                                            <br/>
                                            {content}
                                        </Box>
                                    } secondary={
                                        <span style={{display:'block', paddingLeft: 10, marginTop: 10}}>
                                            <span className={classes.small}> 작성자 : {name} {DateHelper.format(createDate, 'YYYY.MM.DD')}</span>
                                        </span>
                                    } />

                                    <ListItemSecondaryAction>
                                        <StyledRating
                                            style={{paddingTop:5}}
                                            name="customized-color"
                                            defaultValue={rating}
                                            disabled
                                            getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                                            max={10}
                                            icon={<FavoriteIcon fontSize="inherit" />}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        )
                    })
                }
            </List>
        </Box>
    )
}


//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <div className="editprofile_wrap wrapper">
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </div>
        </Box>
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center",
        maxWidth: 1280,
        height: '100vh',
        paddingTop: 30,
        margin: 'auto'
    },
   
    small: {
        color: '#999'
    },

    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),

        backgroundColor: 'transparent',
        '& img': {
            width: '100%'
        }
    },
}));


export default NoteList
