import React from 'react';

// for material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography} from '@material-ui/core';



const RegisterComplete = () =>  {

    // const [activeStep, setActiveStep] = useState(1)


    //측정기 등록하기 
    // const registDeviceHandler =() => {
    //     document.location.href = '/registComplete'
    // }

    // 다음에 등록하기
    // const nextTimeHandler = () => {
    //     document.location.href = '/mypage'
    // }

    // const steps = ['계정등록', '회원가입완료', '기기등록'];

    return (
        
        <Box>
            {/* 로그인 입력  */ }
            <div className="join_wrap wrapper" xs={6} sm={6}>
                <div className="join-step">
                    {/* <Stepper alternativeLabel activeStep={activeStep}>
                        {steps.map(label => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper> */}
                </div>

                <Box>
                    <img src={require('@images/brainIconBlack.png')} style={{height:70,marginBottom:15}} alt="브레인헬스 로고"/>
                    <Typography variant="h4" gutterBottom >
                        BRAIN HEALTH
                    </Typography>
                    <div>
                        <Typography variant="h4" gutterBottom>
                        환영합니다.
                        </Typography>
                    </div>
                    <br/>
                    <br/>

                    <div className="txt01">
                        회원가입을 축하합니다. <br/>
                        {/* 새로운 아이디는 <b>wolfsrain3</b> 입니다. */}
                        입력하신 정보는 안전하게 지켜드립니다.<br/>
                    </div>

                    <div>
                        <Button variant="contained" fullWidth size="large" color="primary" onClick={()=>{
                            window.location.href="/login"
                        }}>로그인 하고 시작하기</Button>
                    </div>
                </Box>
            </div>

            <Box display="flex" flexDirection="row" justifyContent="center" >
                {/* <div className="regicom-box">
                    <p className="tit">브레인헬스 측정기가 있으신가요?</p>
                    <p className="subtxt">하나의 아이디에 1대의 측정기를 등록 할 수 있습니다.<br/>등록하기 버튼을 눌러 진행해주세요<br/><br/>기기 등록은 처음 1회만 진행됩니다</p>
                    <Button variant="contained" onClick={registDeviceHandler} className="btn-basic btn-w100">측정기 등록하기</Button> 
                </div> */}
                {/* <div className="regicom-box">
                    <p className="tit">측정기가 없습니다.</p>
                    <p className="subtxt">처방전 및 뇌운동을 위해서는 반드시 측정기가 필요합니다 . <br/>상담사에게 연락을 통해 구입하거나 쇼핑몰을 통해 구입이 가능합니다.</p>
                    <Link>구입하러 가기</Link>
                    <Button variant="contained"  onClick={nextTimeHandler} className="btn-default btn-w100">다음에 등록하겠습니다</Button> 
                </div> */}
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center"
    }
  }));

export default RegisterComplete
