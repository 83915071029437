import React, {useCallback, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {Box, Button, Grid, Select, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import MemoryComplexData from "./MemoryComplexData";
import MenuItem from "@material-ui/core/MenuItem";
import ExerciseService from "../../../services/ExerciseService";
import DateHelper from "../../../helper/DateHelper";
const ChallengeLevel = (props) =>  {
  const classes = useStyles();

  const [delay, setDelay] = useState(3000)
  const [open, setOpen] = useState(false)
  
  const [challengeResult , setChallengeResult] = useState([])
  useEffect(() => {
    setOpen(props.open)
  }, [props.open])


  const fetch = useCallback(async (exerciseID) => {
    const res = await ExerciseService.getExerciseChallengeResult(exerciseID)
    setChallengeResult(res)
    
  },[])
  
  
  useEffect(() => {
    fetch(props.exerciseID).then()
  }, [props.exerciseID, fetch])
  
  const handleChange = (newValue) => {
    setDelay(newValue.target.value)
  }
  
  const { programView } = props
  let stages = MemoryComplexData.stages[programView].filter(s => s.viewBox !== 'firstStage')
  
  return (
      <Dialog
          disableBackdropClick
          open={open}
          maxWidth={"lg"}
          style={{zIndex:999}}
      >
          <DialogContent style={{
              display: "flex",
              minWidth: 430
          }}>
              <div style={{width: '100%'}}>
                  <Typography variant="h5" className={classes.title}>
                    두뇌계발 단계를 선택하세요
                  </Typography>
                  <Grid container>
                    <Grid item xs={9}>
                      <div style={{paddingLeft: 20}}>나타나는 속도</div>

                    </Grid>
                    <Grid item xs={3}>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={delay}
                        onChange={handleChange}
                        style={{ marginLeft: 50}}
                      >
                        <MenuItem value={1000}>1초</MenuItem>
                        <MenuItem value={2000}>2초</MenuItem>
                        <MenuItem value={3000}>3초</MenuItem>
                        <MenuItem value={4000}>4초</MenuItem>
                        <MenuItem value={5000}>5초</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  {stages.map((stage, index) => {
                    // let backgroundColor = '';
                    // let tagBackgroundColor = '';
                    let grayscale = '0%';
                    let backgroundImage = ''
                    const challengeLevel = index + 1
                    
                    
                    const result = challengeResult.find( c => c.challengeLevel === challengeLevel)
                    
                    // if('선행' === stage.levelText) backgroundColor = 'rgba(93, 195, 217, 0.3)'
                    // if('초급' === stage.levelText) backgroundColor = 'rgba(142, 186, 71, 0.3)'
                    // if('중급' === stage.levelText) backgroundColor = 'rgba(0, 108, 187, 0.3)'
                    // if('고급' === stage.levelText) backgroundColor = 'rgba(204, 40, 40, 0.3)'
                    //
                    // if('선행' === stage.levelText) tagBackgroundColor = 'rgba(93, 195, 217, 1)'
                    // if('초급' === stage.levelText) tagBackgroundColor = 'rgba(142, 186, 71, 1)'
                    // if('중급' === stage.levelText) tagBackgroundColor = 'rgba(0, 108, 187, 1)'
                    // if('고급' === stage.levelText) tagBackgroundColor = 'rgba(204, 40, 40, 1)'


                    let typeId = '';

                    if('view5' === programView) typeId = '';
                    if('view10' === programView) typeId = '_2';
                    if('view8' === programView) typeId = '_3';
                    if('view9' === programView) typeId = '_4';
                    if('view11' === programView) typeId = '_5';

                    const images = [
                      require(`@assets/images/train_bg_1${typeId}.png`),
                      require(`@assets/images/train_bg_2${typeId}.png`),
                      require(`@assets/images/train_bg_3${typeId}.png`),
                    ]

                    if('초급' === stage.levelText) backgroundImage = `url(${images[0]})`
                    if('중급' === stage.levelText) backgroundImage = `url(${images[1]})`
                    if('고급' === stage.levelText) backgroundImage = `url(${images[2]})`
                    
                    if(!result) grayscale = '100%';
                    
                    return (
                      <Box
                        key={index}
                        className={classes.beginnerButton}
                        style={{
                          // backgroundColor:  result ? backgroundColor : '#efefef'
                          backgroundImage: backgroundImage,
                          backgroundSize: '100% 100%',
                          backgroundRepeat: 'no-repeat',
                          filter: `grayscale(${grayscale})`,
                        }}
                        onClick={() => {
                          try {
                            props.start(index, delay, challengeLevel)
                          } catch {}
                        }}>
                        <Grid container>
                          <Grid item xs={10} style={{position:'relative'}}>
                            <div
                              style={{position: 'absolute', fontSize: '0.8rem', fontWeight: 'bold', top: 5, paddingLeft: 5}}
                            >{'firstStage' !== stage.viewBox ? `Lv.${challengeLevel}` : ''}</div>
                            <div style={{paddingLeft: 60}}> 
                              두뇌계발 :

                              {result && 
                                <>
                                  <span style={{fontWeight: 'bold', paddingLeft: 20}}>{result.score}</span>
                                  <span style={{fontSize: '0.8rem'}}> 점</span>
                                  <span style={{fontSize: '0.8rem'}}> - {DateHelper.format(result.exerciseEndDate, 'YY.MM.DD')}</span>
                                </>
                              }
                            </div>
                          </Grid>
                          <Grid
                            item xs={2}
                          >
                            {/*<div style={{*/}
                            {/*  borderRadius: 20,*/}
                            {/*  background: tagBackgroundColor,*/}
                            {/*  color: 'white',*/}
                            {/*  fontSize: '0.7rem',*/}
                            {/*  textAlign: 'center',*/}
                            {/*  fontWeight: "bold",*/}
                            {/*  marginTop: 2,*/}
                            {/*  paddingBottom: 2*/}
                            {/*}}>*/}
                            {/*  {stage.levelText}*/}
                            {/*</div>*/}
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  })}

                  <Box mt={3} mb={3}>
                    <Button
                      className={classes.cancelButton}
                      onClick={() => {
                        props.close()
                      }}
                    >
                      취소
                    </Button>
                  </Box>
                </div>
          </DialogContent>
      </Dialog>
  );
}
  
const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  beginnerButton: {
    textAlign: "left",
    margin: theme.spacing(1),
    width: '100%',
    fontSize:'1rem',
    border: 0,
    borderRadius: 3,
    color: '#000',
    padding: '10px 10px',
    // '&:hover': {
    //   backgroundColor: '#228B22', // Darker green
    // },
  },
  intermediateButton: {
    margin: theme.spacing(1),
    width: '100%',
    fontSize:'1.3rem',
    backgroundColor: '#FF8C00', // Orange
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 140, 0, .3)', // Orange
    color: 'white',
    height: 48,
    padding: '0 30px',
    '&:hover': {
      backgroundColor: '#FF7F00', // Darker orange
    },
  },
  advancedButton: {
    margin: theme.spacing(1),
    width: '100%',
    fontSize:'1.3rem',
    backgroundColor: '#FF4500', // Red
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 69, 0, .3)', // Red
    color: 'white',
    height: 48,
    padding: '0 30px',
    '&:hover': {
      backgroundColor: '#FF0000', // Darker red
    },
  },
  cancelButton: {
    margin: theme.spacing(1),
    width: '100%',
    fontSize:'1.3rem',
    backgroundColor: '#808080', // Grey
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(128, 128, 128, .3)', // Grey
    color: 'white',
    height: 48,
    padding: '0 30px',
    '&:hover': {
      backgroundColor: '#696969', // Darker grey
    },
  },
}));


export default ChallengeLevel