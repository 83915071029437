

export default class MeasureHelper {
    
    static makeBalanceData(data) {
        let measureAll = []

        measureAll = measureAll.concat(data.measureRest)
        measureAll = measureAll.concat(data.measureAttemption)
        measureAll = measureAll.concat(data.measureConcentration)

        return measureAll
    }


    static measureToArray(measureAll, type, measureData) {
        if(!measureData) return measureAll

        for(var i = 0 ; i < measureData.length; i++){
            const measure = measureData[i]

            measureAll.push({
                type: type,
                position: measure.p,
                value: measure.v
            })
        }
        return measureAll
    }

    /**
     * 측정값의 배열을 key value 로 변경합니다.
     * @param {all} all 
     */
    static measureToMap(all){
        const map = new Array(216).fill(null)

        for(var i = 0 ; i < all.length ; i++){
            var measure = all[i]
            const measureTime = process.env.REACT_APP_MEASURE_BALANCE_TIME

            var type = 'rest'
            if(i < measureTime) {
                type = 'rest' 
            } else if(measureTime < i && measureTime * 2 > i) {
                type = 'attemption' 
            } else {
                type = 'concentration'  
            } 

            map[measure.position] = {
                type: type,
                value: measure.value
            }
        }

        return map
    }

    /**
     * object key type을 배열로 변경합니다.
     * @param {*} data 
     */
    static getValueArray(data){
        const values = []
        for(var i = 0 ; i < data.length ; i++){
            values.push(data[i].value)
        }

        return values
    }


    static isAbleMeasure(){
        try {
            window.CefSharp.BindObjectAsync('BRHW')
            return true
        } catch {
            return false
        }
    }

    /**
     * 밴드 평균을 처리합니다.
     * @param {*} bandData 
     */
    static bandRangeAvg(bandData){
        let range = 7;


        try {
            range = bandData[0].ch1.length
        } catch (e) {}


        const bandAvg = {
            ch1: new Array(range).fill(0),
            ch2: new Array(range).fill(0)
        }


        let sec = bandData.length;
        for(let i = 0 ; i < sec; i++){
            const band = bandData[i]
            for(var k = 0; k < range; k++){
                bandAvg.ch1[k] += parseFloat(band.ch1[k]);
                bandAvg.ch2[k] += parseFloat(band.ch2[k]);
            }
        }

        for(let i = 0 ;i < range; i++ ){
            bandAvg.ch1[i] = (bandAvg.ch1[i] / sec).toFixed(2);
            bandAvg.ch2[i] = (bandAvg.ch2[i] / sec).toFixed(2);

            if(isNaN(bandAvg.ch1[i])) bandAvg.ch1[i] = '0.00';
            if(isNaN(bandAvg.ch2[i])) bandAvg.ch2[i] = '0.00';
        }
    
        return bandAvg;
    }
    
    /**
     * reverse한 데이터로 반환합니다.
     */
    static reverse(arr){
        const newArr = JSON.parse(JSON.stringify(arr))
        return newArr.reverse()
    }

    /**
     * 타입을 한글로 처리
     * @param measureType
     * @returns {string}
     */
    static measureTypeToName(measureType) {
        let selectedTypeName;
        if('rest' === measureType){
            selectedTypeName = '안정';
        } else if ('attemption' === measureType){
            selectedTypeName = '주의력';
        } else if ('concentration' === measureType){
            selectedTypeName = '집중력';
        } else if ('balance' === measureType){
            selectedTypeName = '좌우뇌 균형';
        } else if ('brainactive' === measureType){
            selectedTypeName = '뇌활성';
        } else {
            selectedTypeName = measureType
        }
        
        return selectedTypeName;
    }

    /**
     * return measureType to Number Code
     * @param measureType
     * @returns {string}
     */
    static measureTypeToCode(measureType){
        let measureTypeToCode
        switch (measureType){
            case 'rest':
                measureTypeToCode = '10'
                break
            case 'attemption':
                measureTypeToCode = '20'
                break
            case 'concentration':
                measureTypeToCode = '30'
                break
            case 'balance':
                measureTypeToCode = '40'
                break
            case 'brainactive':
                measureTypeToCode = '50'
                break
            case 'type1':
                measureTypeToCode = '61'
                break
            case 'type2':
                measureTypeToCode = '62'
                break
            case 'type3':
                measureTypeToCode = '63'
                break
            case 'type4':
                measureTypeToCode = '64'
                break
            default :
                measureTypeToCode = '10'
        }
        return measureTypeToCode
    }

    static getMeasureButtons(hzType){
        const defaultButtons = [
            {name : '안정 ', value: 'rest', code: '10' },
            {name : '주의력 ', value: 'attemption', code: '20' },
            {name : '집중력 ', value: 'concentration', code: '30'  },
        ]

        const feedbackButtons = [
            {name : '안정 ', value: 'rest', code: '10' },
            {name : '주의력 ', value: 'attemption', code: '20' },
            {name : '집중력 ', value: 'concentration', code: '30'  },
            {name : '좌우뇌균형 ', value: 'balance', code: '40'  },
            {name : '뇌활성 ', value: 'brainactive', code: '50'  },
        ]
        
        const measureBalanceButtons = [
            {name : '안정 ', value: 'rest', code: '10' },
            {name : '주의력 ', value: 'attemption', code: '20' },
            {name : '집중력 ', value: 'concentration', code: '30'  },
            {name : '뇌활성 ', value: 'brainactive', code: '50'  },
        ]
        const exerciseButtons = [
            {name : '안정 ', value: 'rest', code: '10' },
            {name : '주의력 ', value: 'attemption', code: '20' },
            {name : '집중력 ', value: 'concentration', code: '30'  },
            {name : '좌우뇌균형 ', value: 'balance', code: '40'  }
        ]

        const memoryButtons = [
            {name : '안정 ', value: 'rest', code: '10' },
            {name : '주의력 ', value: 'attemption', code: '20' },
            {name : '집중력 ', value: 'concentration', code: '30'  },
        ]

        const concentrationButtons = [
            {name : '집중력 ', value: 'concentration', code: '30'  },
        ]

        const balanceButtons = [
            {name : '좌우뇌균형 ', value: 'balance', code: '40'}
        ]
        
        const meditation = [
            {name : '안정 ', value: 'type1', code: '61' },
            {name : '주의력 ', value: 'type2', code: '62' },
            {name : '집중력 ', value: 'type3', code: '63'  },
            {name : '뇌통합 ', value: 'type4', code: '64'  },
        ]
        
        const meditationTest = [
            {name : '안정 ', value: 'type1', code: '61' },
        ]

        let measureButtons
        switch (hzType){
            case 'default' :
            case 'synapse' :
                measureButtons = measureBalanceButtons
                break
            case 'relax':
                measureButtons = measureBalanceButtons
                break
            case 'concentration':
                measureButtons = concentrationButtons
                break
            case 'feedback':
            case 'natureFeedback':
                measureButtons = feedbackButtons
                break
            case 'balance':
                measureButtons = balanceButtons
                break
            case 'memoryEgg':
                measureButtons = memoryButtons
                break
            case 'feedbackBalance':
                measureButtons = exerciseButtons
                break
            case 'meditation':
            case 'meditationHeal':
            case 'intoSleep':
                measureButtons = meditation
                break
            case 'meditationTest':
            case 'measureBandTest':
                measureButtons = meditationTest
                break
            default:
                measureButtons = defaultButtons
        }
        
        return measureButtons
    }
    
    /**
     * return array, Range of MeasureType
     * @param hzType
     * @param measureType
     * @param measureCode
     * @returns {number[]}
     */
    static getMeasureTypeRange(hzType, measureType, measureCode) {
        let range
        const common = {
            rest: [8,9,10,11],
            attemption: [12,13,14,15],
            concentration: [16,17,18,19],
            balance: [8,9,10,11,12,13,14,15,16,17,18,19],
            balanceLong: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
        }
        
        const measureBalance = {
            rest: [8,9,10,11],
            attemption: [12,13,14,15],
            concentration: [16,17,18,19],
            brainactive: [20,21,22,23],
        }

        const meditation = {
            rest: [4,5,6,7,8,9,10,11],
            attemption: [4,5,6,7,12,13,14,15],
            concentration: [4,5,6,7,16,17,18,19],
            type1: [4,5,6,7,8,9,10,11],
            type2: [4,5,6,7,12,13,14,15],
            type3: [4,5,6,7,16,17,18,19],
            type4: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19],
        }

        const memory = {
            rest: [8,9,10,11,20,21,22,23],
            attemption: [12,13,14,15,20,21,22,23],
            concentration: [16,17,18,19,20,21,22,23],
            codeDE: [15,16,17,18,19,20,21,22,23]
        }

        const feedback = {
            rest: [8,9,10,11],
            attemption: [12,13,14,15],
            concentration: [16,17,18,19],
            brainactive: [20,21,22,23],
            AB: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19],
            BB: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19],
            CB: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19],
            CD: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19],
        }

        const concentration = {
            AA: [7,8,9,10,11],
            BA: [12,13,14,15,16],
            CA: [17,18,19,20,21],
            DA: [22,23,24,25,26],
        }
        
        switch (hzType){
            case 'memoryEgg':
                range = memory[measureType]
                if('DE' === measureCode){
                    range = memory['codeDE']
                }
                break
            case 'feedback':
            case 'natureFeedback':
                if('balance' === measureType){
                    range = feedback[measureCode]
                } else {
                    range = feedback[measureType]    
                }
                break
            case 'concentration':
                range = concentration[measureCode]
                break
            case 'meditation':
            case 'meditationHeal':
            case 'intoSleep':
                range = meditation[measureType]
                break
            case 'meditationTest':
            case 'measureBandTest':
                range = meditation[measureType]
                break
            case 'default':
            case 'synapse':
                range = measureBalance[measureType]
                break
            case 'relax':
                range = measureBalance[measureType]
                break;
            default :  
                range = common[measureType]
                const longHzAllowCode = ['AB', 'BB', 'CB']
                if( -1 < longHzAllowCode.indexOf(measureCode) ){
                    range = common['balanceLong']
                }
                break
        }
        
        if(null == range){
            range = common[measureType];
        }
        return range
    }

    /**
     *
     * @param hzType
     * @param measureTypeCode
     * @param useMeasureCode
     * @returns {{name: string, value: *}[]}
     */
    static getCodeNameSet(hzType, measureTypeCode, useMeasureCode = []) {
        let codes
        switch (hzType){
            case 'feedback':
            case 'natureFeedback':
                if('40' === measureTypeCode + ''){
                    codes = ['AB','BB','CB','CD']
                } else {
                    codes = ['A','B','C']
                }
                break
            case 'memoryEgg':
                codes = ['AE','BE','CE','DE']
                break
            case 'concentration':
                codes = ['AA','BA','CA','DA']
                break
            case 'balance' :
                codes = ['D','E','F','G']
                break
            case 'relax':
                codes = ['A','B']
                break
            case 'measureBand':
                codes = ['A', 'B']
                break
            case 'meditation':
            case 'meditationHeal':
            case 'intoSleep':
                codes = ['MA', 'MM', 'MB']
                codes = []
                break
            case 'meditationTest':
            case 'measureBandTest':
                codes = ['MA', 'MM', 'MB']
                codes = []
                break
            default:
                codes = ['A','B','C']
        }
        
        if(null != useMeasureCode && 0 < useMeasureCode.length){
            codes = useMeasureCode
        }
        return codes.map((code) => {
            return {
                name : `${measureTypeCode}-${code}`,
                value: code
            }
        })
    }
}
