import React, {useEffect, useState} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/List';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import CustomizedDialogs from "@user-components/CustomDialog";
import {Typography} from "@material-ui/core";
import MeasureService from "../../../services/MeasureService";
import AuthProvider from "../../../provider/AuthProvider";

const MeasureSelectDialog = (props) => {
    const [open, setOpen] = useState(false)
    const checkedRef = React.useRef([])
    const [disableSubmit, setDisableSubmit] = React.useState(true)
    
    useEffect(()=>{
        setOpen(props.open)
        if(props.open){
            checkedRef.current = []
            setDisableSubmit(true)
        }
    },[props.open])
    
    return (
        <CustomizedDialogs
            open={open}
            title={props.title}
            content={
                <Box>
                    <Typography>
                        측정 목록을 선택하세요 
                    </Typography>
                    <MeasureList 
                        defaultValue={props.defaultValue}
                        onChange={(newChecked) => {
                            setDisableSubmit(0 === newChecked.length);
                            checkedRef.current = newChecked 
                        }}
                    />
                </Box>
            }
            disableSubmit={disableSubmit}
            close={props.close}
            submit={() => {
                props.submit(checkedRef.current)
            }}
            buttonName={props.buttonName}
        />
    );
}

const MeasureList = (props) => {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(props.defaultValue ? props.defaultValue : []);
    const [products, setProducts] = React.useState(MeasureService.getDefaultMeasureTypesCount({measureGroup: 'measure'}))
    
    const fetch = React.useCallback(async () => {
        const profileID = AuthProvider.isCustomerView() ? AuthProvider.getCustomerProfileID() : AuthProvider.getProfileID() 
        return await MeasureService.getMeasureCount({ profileID, measureTypes: 'measure'})
    },[])


    useEffect(()=>{
        fetch().then((types) => {
          setProducts(types)
          
          const defaultChecked = types.filter(t => 0 < t.count).map(t => t.key) 
          setChecked(defaultChecked)
          if(props.onChange){
            props.onChange(defaultChecked)
          }
        })
    },[fetch])

    if(!products) return <></>
    
    const handleToggle = (key) => () => {
        const currentIndex = checked.indexOf(key);
        const newChecked = [...checked];
        
        const product = products.find((product) => product.key === key)
        if(0 === product.count){
            return false
        }
        
        if (currentIndex === -1) {
            newChecked.push(key);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked)
        if(props.onChange){
            props.onChange(newChecked)
        }
    };

    return (
        <List className={classes.root}>
            {products.map((product) => {
                const labelId = `checkbox-list-label-${product.key}`;

                return (
                    <ListItem key={product.key} role={undefined} dense button onClick={handleToggle(product.key)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={checked.indexOf(product.key) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={product.label} />
                        <ListItemSecondaryAction>
                            <Typography className={classes.ableCount}>
                                {0 < product.count ? `${product.count}회 측정 가능` : '충전필요'}
                            </Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </List>
    )
}


const useStyles = makeStyles(() => ({
    root: {},
    ableCount: { fontSize: '0.8rem'}
}));




export default MeasureSelectDialog
