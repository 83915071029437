import React from 'react';
import {Grid, IconButton} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";

import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import MonitorDialog from '@user-components/MonitorDialog';

class StartButton extends React.Component {
    onStart
    onStop
    onSetting
    
    constructor(props) {
        super(props)

        this.state = {
            isShow: false,
            openMonitor: false,
            measureType: props.measureType,
            measureCode: props.measureCode,
            hzType: props.hzType
        }

        this.onStart = props.onStart
        this.onStop = props.onStop
        this.onSetting = props.onSetting
        this.history = props.history
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onStart = prevProps.onStart
        this.onStop = prevProps.onStop
        this.onSetting = prevProps.onSetting
    }

    componentDidMount() {}

    setToggleMonitor(){
        this.setState({
            ...this.state,
            openMonitor: !this.state.openMonitor
        })
    }

    render() {
        const { classes } = this.props;
        const { openMonitor, hzType, measureCode, measureType } = this.state
        return (
            <React.Fragment>
                <Grid container spacing={1} >
                    {/*<Grid item>*/}
                    {/*    <IconButton*/}
                    {/*        className={clsx(classes.iconSetting)}*/}
                    {/*        onClick={() => {*/}
                    {/*            this.setToggleMonitor()*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <DesktopWindowsIcon/>*/}
                    {/*    </IconButton>*/}
                    {/*</Grid>*/}
                    <Grid item>
                        <IconButton
                            className={classes.icon}
                            onClick={() => {
                                if(this.onStop){
                                    this.onStop()
                                } else {
                                    this.history.goBack()
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                
                <MonitorDialog
                    open={openMonitor}
                    hzType={hzType}
                    isPlaying={false}
                    measureType={measureType}
                    measureCode={measureCode}
                    onTogglePlay={() => {
                        // handleStart().then()
                    }}
                    onClose={() => {
                        this.setToggleMonitor()
                    }}
                />
            </React.Fragment>
        )
    }
}

const statusBarHeight = 50
const buttonWidth = 50
const useStyles = () => ({
    icon : {
        height: statusBarHeight,
        width: buttonWidth,
        color: '#fff',

        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.7)',
        },
        backgroundColor: 'rgba(0,0,0,0.6)',
        '& .MuiSvgIcon-root': {
            fontSize: 40
        }
    },
});

export default withStyles(useStyles)(withRouter(StartButton))