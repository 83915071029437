import React, {useState, useEffect, useCallback} from 'react'

import {makeStyles} from '@material-ui/core/styles'
import {Box, Grid} from '@material-ui/core'
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";


import LoadingIndicator from "../../../components/LoadingIndicator";
import CenterService from "../../../services/manage/CenterService";
import Button from "@material-ui/core/Button";
import SchoolIcon from '@material-ui/icons/School';
import BusinessIcon from '@material-ui/icons/Business';
import CenterDialog from "./CenterDialog";

const CenterList = ({searchKeyword}) => {
    const [currentCenter, setCenter] = useState(null)
    const [centers, setCenters] = useState([])
    const classes = useStyles()
    
    const fetchCenter = useCallback(async () => {
        const centers = await CenterService.getCenter(searchKeyword)
        setCenters(centers)
        
    }, [searchKeyword])

    useEffect(()=>{
        fetchCenter().then()
        
        return (() => setCenters(null))
    },[fetchCenter])


    if(!centers){
        return(<LoadingIndicator/>)
    }
    return (
        <Box className={classes.root}>
            <CenterDialog
                center={currentCenter}
                open={!!currentCenter}
                onClose={() => {
                    setCenter(null)
                    fetchCenter().then()
                }}    
            />
            <List style={{color: 'white', marginTop: 25}}>
                {
                    centers.map((center, index) => {
                        const { name, manageCustomerCnt, type, ownerName } = center
                        return(
                            <React.Fragment key={index}>
                                <ListItem
                                    button
                                    onClick={async () => {
                                        setCenter(centers[index])
                                    }}>
                                    <ListItemAvatar>
                                        <Avatar variant="rounded" className={classes.large}>
                                            {'school' === type ? <SchoolIcon/> : <BusinessIcon/> }
                                        </Avatar>
                                    </ListItemAvatar>

                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Box style={{paddingLeft: 10}}>
                                                {name}
                                            </Box>
                                        </React.Fragment>
                                    } secondary={
                                        <span style={{paddingLeft: 10, color: '#999'}}>{ownerName} - 등록일: 2022-04-03 </span>
                                    } />

                                    <ListItemSecondaryAction>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Button
                                                    onClick={() => {
                                                    }}
                                                    size={'large'}
                                                    className={classes.backGray}>
                                                    회원수 : {manageCustomerCnt}명
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        )
                    })
                }
            </List>
        </Box>
    )
}



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center",
        maxWidth: 1280,
        height: '100vh',
        paddingTop: 30,
        margin: 'auto'
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
        backgroundColor: '#1E2030',
    },
    title: {
        color: 'white',
        textAlign: 'center',
        marginBottom: 100,
        fontSize: 42
    },
    small: {
        fontSize: '0.8rem',
        color: '#999',
    },
    logo: {
        position: 'fixed',
        left: 30 ,
        top: 22,
        zIndex: 0,
    },
    add: {
        position: 'fixed',
        right: 30 ,
        top:22,
        zIndex: 0,
    },
    profileWrap: {
        width: '100%'
    },
    back: {
        color: 'white',
        '&:hover': {
            backgroundColor: '#12131D',
        }
    },
    backGray: {
        color: '#999',
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),

        backgroundColor: 'transparent',
        '& img': {
            width: '100%'
        }
    },
}));


export default CenterList
