import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {Box, Grid, Typography} from '@material-ui/core';
import AudioPlayer from '@user-pages/exercise/common/AudioPlayer'
import SocketProvider from '@user-provider/SocketProvider'
import MeasureHelper from '@helper/MeasureHelper'
import {useParams} from "react-router-dom";

import ExerciseStatusBar from "../ExerciseStatusBar";
import ReactAudioPlayer from "react-audio-player";
const { measureTypeToCode } = MeasureHelper
let MeasureHzType = 'intoSleep'


const IntoSleep = (props) => {
    const stageRef = useRef()
    let { type } = useParams()
    
    useEffect(()=>{
        SocketProvider.connect(()=>{}, window, MeasureHzType)
        
        return () => {
            SocketProvider.measureStop(true).then()
        }
    },[])

    
    return (
        <Box style={{position: 'relative', overflow: 'hidden'}}>
            <Stage ref={stageRef} history={props.history} type={type}/>
        </Box>
    );
}

export class Stage extends React.Component {
    container
    clientRect
    
    scoreBoardRef = React.createRef()
    videoRef = React.createRef()
    audioRef = React.createRef()
    audioBackgroundRef = React.createRef()
    statusBarRef = React.createRef()

    currentStatus = 'ready'
    currentMeasureType = 'type1'
    currentMeasureCode = 'MA'
    measureCodes = ['MA','MM','MB']
    
    currentTime = 0
    timePointIndex = 0
    // timePoints = [0.7, 8.5, 11, 15, 20, 23.5, 29.5, 34, 37, 44, 56, 60, 81.8 , 87, 92, 96.5, 98.6, 101, 103  ]
    
    startDelayTimer = null
    videos = [
        {
            url: 'https://d2iajcsshqwtw3.cloudfront.net/assets/intoSleep.webm',
            poster: 'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/intoSleep.png',
        },
    ]
    
    effectTimePointIndex = 0
    effectTimePoints = []

    sumHitCountRange = 0
    
    checkNextMeasureTime = 0
    checkPlayRateTime = 3

    timeCountRef
    measureLogID
    
    audio
    
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            history: props.history,
            onMeasureData: props.onMeasureData,
            useNarration: true,
            type: props.type
        }
    }

    componentDidMount() {
        this.currentMeasureCode = this.measureCodes.shift()
        
        this.container = ReactDOM.findDOMNode(this)
        this.clientRect = this.container.getClientRects()[0]
        this.audioRef.current.loadEffect(MeasureHzType)

        this.videoRef.current.playbackRate = 1
        this.audio = this.audioBackgroundRef.current.audioEl
        
        SocketProvider.onMeasure = (data) => {
            // check per time
            if(0 !== data.time % this.checkPlayRateTime){
                return
            }
            
            const { hitCountRange } = data.hitCountRange
            
            let totalSum = 0;
            for(let row of hitCountRange){
                let sum = 0 
                for(let inner of row){ sum += inner }
                
                totalSum += sum
            }
            this.sumHitCountRange = totalSum
        }

        SocketProvider.onMeasureStop = async ({measureLogID}) => {
            if('playing' !== this.currentStatus) return
            
            this.measureLogID = measureLogID
            this.currentMeasureCode = this.measureCodes.shift()
            
            if(!this.currentMeasureCode) {
                this.timeCountRef.current.pauseInterval()

                this.measureCodes = ['MA','MM','MB']
                this.currentMeasureCode = this.measureCodes.shift()
                
                return
            }
            
            setTimeout(async () => {
                await this.startMeasure()    
            }, 1000)
        }
    }
    
    refresh(){
        this.setState({
            ...this.state
        })
    }
    setAudioMode(measureType){
        this.audioRef.current.setMode(measureType)
    }

    async startExercise(measureType) {
        this.currentStatus = 'playing'
        this.onReset()

        this.currentMeasureType = measureType
        await this.startMeasure()    
    }

    async startMeasure(){
        await SocketProvider.measureStart(
            60 * 4 ,
            0,
            MeasureHzType,
            this.currentMeasureType,
            measureTypeToCode(this.currentMeasureType),
            this.currentMeasureCode,
            () => {
              
            })
        this.videoRef.current.play()
        this.audio.play()
    }

    async stopExercise() {
        this.currentStatus = 'ready'
        this.onPause()
        
        await SocketProvider.measureStop()
    }

    onAudioEnded() {}
    onVideoLoad() {}
    
    async onVideoEnded(){
        // MB stop
        await SocketProvider.measureStop()
        
        if(this.state.useNarration){
            setTimeout(() => {
                this.audioRef.current.play(13)
            }, 10 * 1000)
        }
    }
    
    checkNextMeasure(time){
        if(0 < time && 0 === time % 35){
            if(0 < this.measureCodes.length){
                SocketProvider.measureStop().then()    
            }
        }
    } 
    
    onVideoTimeUpdate(event){
        if(this.videoRef.current.paused) {
            return false
        }
    }
    
    onSeekTime(time) {
        this.videoRef.current.currentTime = time
        this.audioRef.current.pause()
        
        let timePoint = 0
        for(let i = 0; i < this.timePoints.length; i++){
            timePoint = this.timePoints[i];
            
            if(timePoint >= time){
                this.timePointIndex = i
                break
            }
        }

        for(let i = 0; i < this.effectTimePoints.length; i++){
            timePoint = this.effectTimePoints[i];

            if(timePoint.time >= time){
                this.effectTimePointIndex = i
                break
            }
        }

        if(0 < time && 0 === this.timePointIndex){
            this.timePointIndex = 99
        }
        
        if(0 < time && 0 === this.effectTimePointIndex){
            this.effectTimePointIndex = 99
        }
    }

    onPause(){
        if(this.startDelayTimer){
            clearTimeout(this.startDelayTimer)
        }

        this.videoRef.current.pause()
        this.audioRef.current.pause()
        this.audio.pause()
    }
    
    onNarration(checked) {
        this.setState({
            ...this.state,
            useNarration: !checked
        })
    }
    onReset(){
        this.timePointIndex = 0
        this.effectTimePointIndex = 0
        this.videoRef.current.pause()
        this.videoRef.current.currentTime = 0
        // this.videoRef.current.pause()
    }

    render() {
        return (
            <Box>
                <ExerciseStatusBar 
                    ref={this.statusBarRef}
                    measureHzType={MeasureHzType}
                    measureType={this.currentMeasureType}
                    measureCode={this.currentMeasureCode}
                    hideIntervalTime={true}
                    useTimeCountInterval={true}
                    checkBoxLabel={'배경음 사용'}
                    useEffect={true}
                    // hideHit={true}
                    close={async () => {
                        await this.stopExercise()
                    }}
                    start={async (runningTime, measureType) => {
                        await this.startExercise(measureType)
                    }}
                    onInitAfter={({timeCountRef}) => {
                        this.timeCountRef = timeCountRef
                    }}
                    onNarration={this.onNarration.bind(this)}
                    pause={(isPause) => {
                        if(isPause){
                            this.videoRef.current.pause()
                            this.audioRef.current.pause()
                        } else {
                            this.videoRef.current.play()
                            this.audioRef.current.play()
                        }
                    }}
                />
                
            
                <Box style={{ position: 'relative', height: window.innerHeight}}>
                    <Box style={{height:'100%'}}>
                        <video id="meditation-video"
                               ref={this.videoRef}
                               muted
                               poster={this.videos[0].poster}
                               onEnded={this.onVideoEnded.bind(this)}
                               onLoadedData={this.onVideoLoad.bind(this)}
                               onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                               style={{height: '100%', width: '100%', objectFit: 'cover' }}>
                            <source src={this.videos[0].url} type="video/mp4"/>
                        </video>
                    </Box>


                </Box>
                
                <AudioPlayer 
                    ref={ this.audioRef }
                    onAudioEnded={this.onAudioEnded.bind(this)}
                    mode={'breathStamp'} />
                <ReactAudioPlayer
                    ref={ this.audioBackgroundRef }
                    src={require('@audio/intoSleep.mp3')}
                    loop={true}
                />
            </Box>
        )
    }
}

export default IntoSleep
