import Logs from "../logs/Logs";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";

const GatewayLogs = () => {
  let { profileID } = useParams()
  
  return (
    <>
      <Logs hideHeader={true} profileID={profileID}/>
    </>
  )
}

export default GatewayLogs
