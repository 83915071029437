import React, {useRef, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

import { Box, Grid } from '@material-ui/core';

import SocketProvider from '@user-provider/SocketProvider';
import AudioPlayer from '@user-pages/exercise/common/AudioPlayer'
import MeasureHelper from '@helper/MeasureHelper'
import ExerciseStatusBar from "../ExerciseStatusBar";
import ActiveProgress from "../common/ActiveProgress";
import ReactAudioPlayer from "react-audio-player";
const { measureTypeToCode } = MeasureHelper

let MeasureHzType
const MeasureSettings = {
    'natureFeedback' :  {
        url : 'https://d2iajcsshqwtw3.cloudfront.net/assets/natureFeedback.webm',
        poster : 'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/natureFeedback.png',
        loopTime: 47,
        audio: require('@audio/natureFeedbackEffect.mp3'),
        headTime: 0,
        activeBar: false,
        showSpeed: false,
        volume: 0.7,
        mute: true
    },
}


const NatureFeedback = (props) => {
    const stageRef = useRef()
    let currentMeasureType = 'rest'
    let currentMeasureCode = 'A'
    
    const [useSound] = useState(true)
    
    MeasureHzType = "natureFeedback"
    // const { type } = useParams()
    // MeasureHzType = type ? type : 'natureFeedback'
    
    useEffect(()=>{
        SocketProvider.connect(()=>{}, window, MeasureHzType)

        return () => {
            SocketProvider.measureStop(true).then()
        }
    },[])

    const startExercise = async (runningTime, measureType, measureCode) => {
        stageRef.current.setAudioMode(measureType)
        stageRef.current.setBackgroundAudio(MeasureHzType)
        stageRef.current.setVideoPlay()
        await SocketProvider.measureStart(runningTime,0, MeasureHzType, measureType, measureTypeToCode(measureType), measureCode)
    }

    const stopExercise = async () => {
        await SocketProvider.measureStop()
    }

    //측정 완료메세지를 받으면 
    SocketProvider.onMeasureStop = async () => {
        stageRef.current.setVideoPause()
    }
    return (
        <Box style={{position: 'relative', overflow:'hidden'}}>
            <ExerciseStatusBar
                measureHzType={MeasureHzType}
                measureType={currentMeasureType}
                measureCode={currentMeasureCode}
                close={async () => {
                    await stopExercise()
                }}
                onNarration={(checked) => {
                    stageRef.current.setSound(checked)
                }}
                checkBoxLabel={'효과음 사용'}
                useNarration={!useSound}
                start={async (runningTime, measureType, measureCode) => {
                    await startExercise(runningTime, measureType, measureCode)
                }}
                pause={(isPause) => {
                    stageRef.current.setVideoPlayToggle()
                }}
            />
            
            <Stage
                ref={stageRef}
                history={props.history}
            />
        </Box>
    );
}

export class Stage extends React.Component {
    container
    clientRect

    videoTimer
    
    videoRef = React.createRef()
    audioRef = React.createRef()
    audioBackgroundRef =  React.createRef()
    actionBoxRef = React.createRef()
    
    audio
    
    hitCount = 0
    playStatus = 'pause'
    
    constructor(props) {
        super(props)
        this.state = {
            hitCount: 0,
            onMeasureData: props.onMeasureData,
            onReset: props.onReset,
            history: props.history
        }
    }

    setAudioMode(measureType){
        this.audioRef.current.setMode(measureType)
    }
    setBackgroundAudio(measureType){
        const setting = MeasureSettings[measureType]
        if(setting.audio){
            this.audio = this.audioBackgroundRef.current.audioEl
            this.audio.src = setting.audio
            this.audio.volume = setting.volume
        }
    }
    setVideoPlayToggle(){
        if('play' === this.playStatus){
            this.videoRef.current.pause()
            this.audio.pause()
        } else {
            this.videoRef.current.play()
            this.audio.play()
        }
    }
    setVideoPause(){
        this.videoRef.current.pause()
        this.audio.pause()
    }
    setVideoPlay(){
        this.videoRef.current.pause()
        this.videoRef.current.currentTime = 0
        this.videoRef.current.play()

        this.audio.play()
    }
    
    setSound(checked){
        if(checked){
            this.audio.play()
        } else {
            this.audio.pause()     
        }
        
    }

    componentDidMount() {
        this.container = ReactDOM.findDOMNode(this)
        this.clientRect = this.container.getClientRects()[0]
        this.audio = ReactDOM.findDOMNode(this)

        SocketProvider.onMeasure = (data) => {

            if (data.isHit) {
                // this.videoRef.current.play()
                this.audioRef.current.play()
                
                // if(this.videoTimer) {
                //     clearTimeout(this.videoTimer)
                // }
                //
                // this.videoTimer = setTimeout(() => {
                //     try {
                //         this.videoRef.current.pause()    
                //     } catch {}
                // }, 1000)
            }
            
            try {
                this.actionBoxRef.current.update(data.isHit)    
            } catch {}
        }
  
    }
    
    onVideoTimeUpdate(event){
        if(this.videoRef.current.paused) {
            return false
        }

        // const settings = MeasureSettings[MeasureHzType]
        // const loopTime = settings.loopTime
        // const headTime =  settings.headTime
        //
        // if(0 < loopTime && loopTime <= event.target.currentTime) {
        //     this.videoRef.current.currentTime = headTime 
        // }
    }

    render() {
        const setting = MeasureSettings[MeasureHzType]
        return (
            <Box>
                <Grid>
                    <Grid item xs={12} style={{ position: 'relative', height: window.innerHeight}}>
                        <Box style={{height:'100%'}}>
                            <video id="breath-video"
                                   ref={this.videoRef}
                                   loop={true}
                                   muted={setting.mute}
                                   poster={setting.poster}
                                   onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                                   style={{height: '100%', width: '100%', objectFit: 'cover' }}>
                                <source src={setting.url} type="video/mp4"/>
                            </video>
                        </Box>
                        {setting.activeBar &&
                            <ActiveProgress ref={this.actionBoxRef} />
                        }
                    </Grid>
                </Grid>

                <AudioPlayer ref={ this.audioRef } mode={'rest'} />
                <ReactAudioPlayer
                    ref={ this.audioBackgroundRef }
                    loop={true}
                />
            </Box>
        )
    }
}

export default NatureFeedback
