import Axios from 'axios';
import AuthProvider from '@user-provider/AuthProvider'
import NetworkHelper from '@helper/NetworkHelper'

export default class CustomerService {
    /**
     * return assigned my customers
     * @param name
     * @param searchAll
     * @param limit
     * @param centerID
     * @returns {Promise<*[]|any>}
     */
    static async getCustomers({name, searchAll = false, limit = 0, centerID = null}){
        try{
            const result = await Axios.get('/api/manage/customers/center',{
                params: {
                    name: name ? name : '',
                    centerID,
                    limit
                }
            })
            return result.data

        }catch{}
        return []
    }
    static async getCustomerWithLoginIDs(name,  limit = 0){
        try{
            const result = await Axios.get('/api/customer/search-members',{
                params: {
                    name: name ? name : '',
                    limit
                }
            })
            return result.data

        }catch{}
        return []
    }
    
    static async moveToData(profileID, toMemberID){
        try{
            await Axios.put('/api/customer/move-to',{
                profileID,
                toMemberID
            })
            return true

        }catch{}
        return false
    }

    /**
     * return 
     * @param name
     * @returns {Promise<*[]|any>}
     */
    static async getCustomersGroup(name,){
        try{
            const result = await Axios.get('/api/manage/customers/group',{
                params: {
                    name: name ? name : '',
                }
            })
            return result.data

        }catch{}
        return []
    }

    
    /**
     * return customer token
     * @param memberID
     * @param profileID
     * @returns {Promise<*[]|any>}
     */
    static async setCustomerView(memberID, profileID, viewMode = 'customer'){
        try{
            const res = await Axios.get(`/api/manage/customer/${memberID}/view/${profileID}`)
            const { member, profile, device } = res.data
            const managerID = `${AuthProvider.getProfileID()}`
            
          
            localStorage.setItem(AuthProvider.userAdminCookieName , JSON.stringify(AuthProvider.userSession))  
            AuthProvider.setCookie(member)
            
            AuthProvider.set('viewMode',viewMode)
          
            AuthProvider.set('profileID', profile.id)
            AuthProvider.set('profileName', profile.name)
            AuthProvider.set('profileType', profile.type)
            AuthProvider.set('code', profile.code)
            AuthProvider.set('image', profile.image)
            AuthProvider.set('birthDate', profile.birthDate)
            AuthProvider.set('customerProfileID',profileID)
            AuthProvider.set('customerMemberID',memberID)
            AuthProvider.set('computerMac', device.mac)
            AuthProvider.set('managerID', Number(managerID))
          
            AuthProvider.setDeviceID(device.deviceID)
            return {
                success: true
            }
        }
        catch(e){ console.log(e)}
        
        return {
            success: false
        }
    }

    
    static async addCustomer(memberID, profileID, centerID = null){
        try{
            const res = await Axios.post(`/api/manage/customer`,{
                customerMemberID: memberID,
                customerProfileID: profileID,
                centerID: centerID
            })
            return {
                success: true,
                ID: res.data.ID
            }
        }
        catch{}

        return {
            success: false
        }
    }
    static async addStaffCustomer(memberID, profileID, centerID, staffMemberID){
        try{
            const res = await Axios.post(`/api/manage/customer`,{
                customerMemberID: memberID,
                customerProfileID: profileID,
                centerID: centerID,
                staffMemberID
            })
            return {
                success: true,
                ID: res.data.ID
            }
        }
        catch{}

        return {
            success: false
        }
    }

    static async removeCustomer(centerID, profileID, useDefault = false){
        try{
            await Axios.delete(`/api/manage/customer`,{
                data: {
                    profileID: profileID,
                    centerID: centerID,
                    useDefault
                }
            })
        }
        catch{}
    }

    /**
     * clear customer view
     * @returns {Promise<{success: boolean}>}
     */
    static async clearCustomerView(){
        AuthProvider.clearCustomer()
        
        try{
            await Axios.delete(`/api/manage/customer/view`)
            return {
                success: true
            }
        }
        catch{}
    }

    /**
     * get last measurement
     * @param profileID
     * @returns {Promise<{data: any, success: boolean}|{success: boolean}>}
     */
    static async getCustomerLastMeasure(profileID){
        try{
            const res = await Axios.get(`/api/manage/measure/${profileID}/last-result`)
            return {
                success: true,
                data: NetworkHelper.getDecParam(res.data)
            }
        }
        catch{}

        return {
            success: false
        }
    }

    /**
     * get measure by prescription ID
     * @param profileID
     * @param prescriptionID
     * @returns {Promise<{data: any, success: boolean}|{success: boolean}>}
     */
    static async getCustomerMeasure(profileID, prescriptionID){
        try{
            const res = await Axios.get(`/api/manage/measure/${profileID}/id/${prescriptionID}`)
            return {
                success: true,
                data: NetworkHelper.getDecParam(res.data)
            }
        }
        catch{}

        return {
            success: false
        }
    }
    
    /**
     * get customer prescriptionID
     * @param profileID
     * @returns {Promise<void>}
     */
    static async getPrescriptionIds(profileID) {
        try {
            const result = await Axios.get(`/api/manage/prescriptions/${profileID}/ids`)
            return result.data
        }catch(e){
            console.log(e)
        }
        return null
    }

    static async getPrescriptions(name) {
        try {
            const result = await Axios.get(`/api/manage/prescriptions`,{
                params: {
                    name: name ? name : ''
                }
            })
            return result.data
        }catch(e){
            console.log(e)
        }
        return null
    }

    static async getMeasureLogHistory(name) {
        try {
            const result = await Axios.get(`/api/manage/measure/log/history`,{
                params: {
                    name: name ? name : ''
                }
            })
            return result.data.logs
        }catch(e){
            console.log(e)
        }
        return null
    }
    
    static async getNotes(name) {
        try {
            const result = await Axios.get(`/api/manage/customer/notes`,{
                params: {
                    name: name ? name : ''
                }
            })
            return result.data
        }catch(e){
            console.log(e)
        }
        return null
    }
    
    static async updatePrescriptionMeasureType({profileID, prescriptionID, measureTypes}){
        try {
            const result = await Axios.put(`/api/manage/prescription/${prescriptionID}/measureTypes`,{
                profileID,
                measureTypes
            })
            return result.data
        }catch(e){
            console.log(e)
        }
        return null
    }
    

    /**
     * get config customer
     * @param profileID
     * @param configType
     * @returns {Promise<{data: any, success: boolean}|{success: boolean}>}
     */
    static async getCustomerConfig(profileID, configType){
        try{
            const res = await Axios.get(`/api/manage/measure/${profileID}/config`,{
                params: {
                    configType
                }
            })
            return {
                success: true,
                data: res.data.value
            }
        }
        catch{}

        return {
            success: false
        }
    }

    /**
     * set config customer
     * @param profileID
     * @param configType
     * @param value
     * @returns {Promise<{success: boolean}>}
     */
    static async setCustomerConfig(profileID, configType, value){
        try{
            await Axios.put(`/api/manage/measure/${profileID}/config`,{
                configType,
                value
            })
            return {
                success: true
            }
        }
        catch{}

        return {
            success: false
        }
    }

    /**
     * set config customer
     * @returns {Promise<{success: boolean}>}
     * @param customerMemberId
     */
    static async getCustomerProfiles(customerMemberId){
        try{
            const res = await Axios.get(`/api/manage/customers/${customerMemberId}/profiles`)
            return res.data
        }
        catch{}

        return {
            maxProfileCount: 0,
            profiles: []
        }
    }

    /**
     * set profile Max count
     * @param customerMemberId
     * @param profileCount
     * @returns {Promise<{maxProfileCount: number, profiles: *[]}|any>}
     */
    static async setCustomerProfileCount(customerMemberId, profileCount){
        try{
            const res = await Axios.put(`/api/manage/customers/${customerMemberId}/profile-count/${profileCount}`)
            return res.data
        }
        catch{}
    }

    /**
     * save note
     * @param profileID
     * @param data
     * @returns {Promise<any>}
     */
    static async saveMeasureNote(profileID, data){
        try{
            await Axios.post(`/api/manage/customer/${profileID}/note`,data)
        }
        catch{}
    }
    static async deleteMeasureNote(noteID){
        try{
            await Axios.delete(`/api/manage/customer/note/${noteID}`,)
        }
        catch{}
    }

    /**
     * 
     * @param prescriptionID
     * @returns {Promise<void>}
     */
    static async getMeasureNote(prescriptionID){
        try{
            const res = await Axios.get(`/api/manage/customer/note/${prescriptionID}`)
            return res.data
        }
        catch{}
        
        return []
    }
}
