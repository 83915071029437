import React, {useState} from 'react'

import {makeStyles} from '@material-ui/core/styles'
import {Box, Button, IconButton} from '@material-ui/core'
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from '@material-ui/icons/Add';
import CustomerSearchText from './CustomerSearchText'

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import CustomerList from "./CustomerList";
import PrescriptionList from "./PrescriptionList"
import CenterList from "../center/CenterList";
import AuthProvider from "../../../provider/AuthProvider";
import NoteList from "./NoteList";
import PaymentList from "../center/PaymentList";
import AdminList from "../center/AdminList";
import MeasureTestList from "./MeasureTestList";
import CustomerAddDialog from "./CustomerAddDialog";

const Customer = ({history}) => {
    
    const [keyword, setKeyword] = useState('')
    const [currentTab, setCurrentTab] = useState(0)
    const classes = useStyles()

    const [openAdd, setOpenAdd] = useState(false)
    return (
        <Box className={classes.root}>
            <Box className={classes.background} />
            <Box className={classes.logo}>
                {
                    !AuthProvider.isAppMode() &&
                    <IconButton
                        className={classes.back}
                        onClick={() => {
                            history.replace('/dashboard')
                        }}
                    >
                        <ArrowBackIcon style={{fontSize: 50}}/>
                    </IconButton>
                }
                
            </Box>
            <Box style={{maxWidth: 500, margin: 'auto', marginBottom: 50}} >
               <CustomerSearchText keyup={async (name) => {
                   setKeyword(name)
               }}/>
            </Box>

            {AuthProvider.isSuperAdmin() &&
            <Box className={classes.add}>
                <Button
                    onClick={() => {
                        setOpenAdd(!openAdd)
                    }}
                    className={classes.normal}
                    startIcon={<AddIcon/>}>
                    사용자 등록
                </Button>
                <CustomerAddDialog open={openAdd} onClose={() => { setOpenAdd(false)}} />
            </Box>
            }

            <Tabs
                value={currentTab}
                className={classes.tabs}
                onChange={(event, newValue) => {
                    setCurrentTab(newValue)
                }}
            >
                <Tab label="회원목록" />
                <Tab label="뇌기능 측정"  />
                <Tab label="뇌기능 검사"  />
                <Tab label="상담기록"  />
                
                {AuthProvider.isSuperAdmin() &&
                    <Tab label="센터 & 학교" />
                }
                
                {AuthProvider.isSuperAdmin() &&
                    <Tab label="교육사 관리" />
                }
                {AuthProvider.isSuperAdmin() &&
                    <Tab label="결제내역" />
                }

                {/*<Tab label="뇌기능 검사"  />*/}
                
                {/*<Tab label="결제"  />*/}
            </Tabs>

            {0 === currentTab &&
                <CustomerList searchKeyword={keyword} history={history}/>
            }
            {1 === currentTab &&
                <PrescriptionList searchKeyword={keyword} history={history}/>    
            }
            {2 === currentTab &&
                <MeasureTestList searchKeyword={keyword} history={history}/>
            }
            {3 === currentTab &&
                <NoteList searchKeyword={keyword} history={history}/>
            }
            {4 === currentTab  &&
                <CenterList searchKeyword={keyword} history={history}/>
            }
            {5 === currentTab  &&
                <AdminList searchKeyword={keyword} history={history}/>
            }
            {6 === currentTab  &&
                <PaymentList searchKeyword={keyword} history={history}/>
            }
   
        </Box>
    )
}




const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center",
        maxWidth: 1280,
        height: '100vh',
        paddingTop: 30,
        margin: 'auto'
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
        backgroundColor: '#1E2030',
    },
    tabs: {
        "& .MuiTabs-indicator": {
            backgroundColor: "white"
        },
        "& .MuiTab-wrapper": {
            color: 'white'  
        }
    },
    logo: {
        position: 'fixed',
        left: 30 ,
        top: 22,
        zIndex: 0,
    },
    add: {
        position: 'absolute',
        right: 30 ,
        top:38,
        zIndex: 0,
        color: 'white',
   
    },

    back: {
        color: 'white',
        '&:hover': {
            backgroundColor: '#12131D',
        }
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),

        backgroundColor: 'transparent',
        '& img': {
            width: '100%'
        }
    },
    normal: {
        color: 'white',
        backgroundColor: '#1E2030',
        '&:hover': {
            backgroundColor: '#12131D'
        }
    },
}));


export default Customer
