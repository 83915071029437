import React from 'react';
import red from '@material-ui/core/colors/red';
import _ from "lodash";

export default class MemoryComplexData {
  
  static stages = {
    /**
     * view5: 주의력
     * view9: 집중력
     * view6: 공감력
     * view8: 기억력
     * view7 사고력
     */
    view5: [
      {
        viewBox: 'firstStage',
        type: 'selector',
        all: 10,
        delay: 2000,
        showType: 'all',
        levelText: '선행',
        shortText: '선행학습',
        text: <div>캐릭터를 보고, 듣고, 이해한 후 소리내어 발음해 보세요. </div>
      },
      {
        viewBox: 'surfing',
        type: 'memory',
        all: 1,
        viewChar: 'view5-s',
        delay: 0,
        randomDelay: 2000,
        direction: 'first',
        showType: 'all',
        levelText: '초급',
        shortText: '서핑보드 색상',
        color: true,
        afterShowType: 'text',
        showSubType: 'background',
        text: <div>서핑보드 색상을 기억하세요.</div>,
        afterText: <div>서핑보드 색상과 같은 것을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t1.mp3`),
        afterTextSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t1_1.mp3`),
        background: require(`@assets/exercise/memoryComplex/view5_bg3.png`),
        backgroundHeight: '60%',
      },
      {
        viewBox: 'surfing',
        type: 'memory',
        all: 2,
        selectorType: "not-same",
        viewChar: 'view5-s',
        delay: 0,
        randomDelay: 2000,
        direction: 'first',
        showType: 'all',
        levelText: '초급',
        color: true,
        shortText: '서핑보드 색상이 아닌 것',
        text: <div>서핑보드 색상을 기억하세요.</div>,
        afterText: <div>서핑보드 색상과 다른 것을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t1.mp3`),
        afterTextSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t2.mp3`),
        background: require(`@assets/exercise/memoryComplex/view5_bg3.png`),
        backgroundHeight: '60%'
      },
      {
        viewBox: 'default',
        type: 'selector',
        all: 3,
        delay: 0,
        selectRatio: ['물건', '먹을것'],
        selectorType: '먹을것',
        min: 3,
        showType: 'all',
        levelText: '초급',
        shortText: '먹을 수 있는 것',
        text: <div>먹을 수 있는 것만 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t3.mp3`),
      },
      {
        viewBox: 'default',
        type: 'selector',
        all: 4,
        delay: 0,
        selectRatio: ['물건', '먹을것'],
        selectorType: '물건',
        min: 3,
        showType: 'all',
        levelText: '초급',
        shortText: '먹을 수 없는 것',
        text: <div>먹을 수 없는 것만 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t4.mp3`),
      },
      {
        viewBox: 'default',
        type: 'selector',
        all: 5,
        delay: 0,
        selectRatio: ['생물', '물건'],
        selectorType: '생물',
        min: 3,
        showType: 'all',
        levelText: '중급',
        shortText: '해양생물이 맞는 것',
        text: <div>해양생물만 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t5.mp3`),
      },
      {
        viewBox: 'default',
        type: 'selector',
        all: 6,
        delay: 0,
        selectRatio: ['생물', '물건'],
        selectorType: '물건',
        min: 3,
        showType: 'all',
        levelText: '중급',
        shortText: <div>해양생물이 아닌 것</div>,
        text: <div>해양생물이 아닌 것만 찾으세요</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t6.mp3`),
      },
      {
        viewBox: 'default',
        type: 'memory',
        all: 7,
        delay: 2000,
        selectRatio: ['생물', '물건'],
        selectorType: '생물',
        direction: 'first',
        showType: 'step',
        levelText: '중급',
        currentStep: 0,
        shortText: '해양생물이 나타난 순서',
        textForOne: <div>첫 번 나타난 해양생물을 찾으세요.</div>,
        text: <div>해양생물이 나타난 순서대로 찾으세요.</div>,
        textForOneSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t7_1.mp3`),
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t7_2.mp3`),
      },
      {
        viewBox: 'default',
        type: 'memory',
        all: 8,
        delay: 2000,
        direction: 'first',
        showType: 'step',
        currentStep: 0,
        levelText: '고급',
        shortText: '먼저 나타난 순서',
        textForOne: <div>먼저 나타난 것을 찾으세요.</div>,
        text: <div>먼저 나타난 순서대로 찾으세요.</div>,
        textForOneSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t8_1.mp3`),
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t8_2.mp3`),
      },
      {
        viewBox: 'default',
        type: 'memory',
        all: 9,
        delay: 2000,
        direction: 'last',
        showType: 'step',
        selectRatio: ['생물', '물건'],
        selectorType: '물건',
        levelText: '고급',
        shortText: '비해양생물을이 늦게 나타난 순서',
        textForOne: <div>늦게 나타난 것을 찾으세요.</div>,
        text: <div>비해양생물이 늦게 나타난 순서대로 찾으세요.</div>,
        textForOneSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t9_1.mp3`),
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t9_2.mp3`),
      },
      {
        viewBox: 'default',
        type: 'selector',
        all: 10,
        delay: 2000,
        direction: 'last',
        showType: 'step',
        levelText: '고급',
        shortText: '늦게 나타난 순서',
        textForOne: <div>늦게 나타난 것을 찾으세요.</div>,
        text: <div>늦게 나타난 순서대로 찾으세요.</div>,
        textForOneSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t9_1.mp3`),
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t10_2.mp3`),
      },
      // {viewBox:'default', type: 'selector',  all:10, delay:0,     selectRatio:['생물','물건'], selectorType:'생물', showType:'all', min:3, text: <div>물건이 <span style={{color:red[500]}}>아닌 것</span>을 모두 찾으세요</div> },
      // {viewBox:'default', type: 'calculator', all:2, calcType: 'plus', exView: 4, background:require(`@assets/exercise/memoryComplex/background.jpg`), text: 'type1', backgroundColor:'#FFFEDF'},
      // {viewBox:'default', type: 'calculator', all:2, calcType: 'minus', exView: 4, background:require(`@assets/exercise/memoryComplex/background.jpg`), text: 'type2', backgroundColor:'#FFFEDF' },
    ],
    view6: [
      {
        viewBox: 'firstStage',
        type: 'selector',
        all: 10,
        delay: 2000,
        showType: 'all',
        levelText: '선행',
        shortText: '선행학습',
        text: <div>캐릭터를 보고, 듣고, 이해한 후 소리내어 발음해 보세요. </div>
      },
      {
        viewBox: 'default',
        type: 'memory',
        all: 8,
        delay: 2000,
        selectRatio: ['하계', '동계'],
        selectorType: '하계',
        direction: 'first',
        showType: 'step',
        currentStep: 0,
        shortText: '',
        levelText: '',
        text: <div>하계 올림픽 종목을<span style={{color: red[500]}}>먼저 나타난 순서대로</span> 찾으세요.</div>
      },
      {
        viewBox: 'default',
        type: 'selector',
        all: 4,
        delay: 0,
        selectRatio: ['하계', '동계'],
        selectorType: '동계',
        min: 3,
        showType: 'all',
        text: <div>하계 올림픽 종목이 <span style={{color: red[500]}}>아닌 것</span>을 찾으세요.</div>
      },
      {
        viewBox: 'default',
        type: 'selector',
        all: 4,
        delay: 0,
        selectRatio: ['하계', '동계'],
        selectorType: '하계',
        min: 3,
        showType: 'all',
        text: <div>하계 올림피 종목을<span style={{color: red[500]}}>모두</span> 찾으세요.</div>
      },
      {
        viewBox: 'default',
        type: 'memory',
        all: 8,
        delay: 2000,
        direction: 'last',
        showType: 'step',
        text: <div><span style={{color: red[500]}}>늦게 나타난</span> 순서대로 올림픽 종목을 선택하세요.</div>
      },
      {
        viewBox: 'default',
        type: 'selector',
        all: 10,
        delay: 0,
        selectRatio: ['하계', '동계'],
        selectorType: '동계',
        showType: 'all',
        min: 3,
        text: <div>동계 올림픽 종목만 찾아보세요.</div>
      },
      {
        viewBox: 'default',
        type: 'memory',
        all: 10,
        delay: 0,
        selectRatio: ['하계', '동계'],
        selectorType: '하계',
        showType: 'all',
        min: 3,
        text: <div>동계 올림픽 종목이 <span style={{color: red[500]}}>아닌 것</span>을 찾으세요.</div>
      },
      {
        viewBox: 'default',
        type: 'selector',
        all: 10,
        delay: 0,
        selectRatio: ['1', '10'],
        selectorType: '1',
        showType: 'all',
        min: 3,
        text: <div>혼자서 하는 종목을 <span style={{color: red[500]}}>모두</span> 찾아보세요.</div>
      },
      {
        viewBox: 'default',
        type: 'calculator',
        all: 2,
        calcType: 'plus',
        exView: 4,
        background: require(`@assets/exercise/memoryComplex/view6_bg1.jpg`),
        text: 'type1',
        backgroundColor: '#E4F5FF'
      },
      {
        viewBox: 'default',
        type: 'calculator',
        all: 2,
        calcType: 'minus',
        exView: 4,
        background: require(`@assets/exercise/memoryComplex/view6_bg2.jpg`),
        text: 'type2',
        backgroundColor: '#E4F5FF'
      },
    ],
    view8: [
      {
        viewBox: 'firstStage',
        type: 'selector',
        all: 10,
        delay: 2000,
        showType: 'all',
        levelText: '선행',
        shortText: '선행학습',
        text: <div>캐릭터를 보고, 듣고, 이해한 후 소리내어 발음해 보세요. </div>
      },
      {
        viewBox: 'surfing',
        type: 'memory',
        all: 1,
        delay: 0,
        randomDelay: 2000,
        afterShowType: 'text',
        showType: 'all',
        shortText: '꽃밭에 날아든 나비 색상',
        levelText: '초급',
        color: true,
        showSubType: 'background',
        text: <div>꽃밭에 날아든 나비의 색상을 기억하세요.</div>,
        afterText: <div>꽃밭에 날아든 나비 색상을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v8_t1.mp3`),
        afterTextSound: require(`@assets/exercise/memoryComplex/sound/s_v8_t1_1.mp3`),
        background: require(`@assets/exercise/memoryComplex/view8_bg3.png`),

      },
      {
        viewBox: 'surfing',
        type: 'memory',
        all: 2,
        delay: 0,
        randomDelay: 2000,
        direction: 'first',
        showType: 'all',
        shortText: '나비 색상 같은 것 ',
        levelText: '초급',
        color: true,
        text: <div>꽃밭에 날아든 나비의 색상을 기억하세요.</div>,
        afterText: <div>꽃밭에 날아든 나비 색상이 같은 것을 찾으세요. </div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v8_t1.mp3`),
        afterTextSound: require(`@assets/exercise/memoryComplex/sound/s_v8_t2.mp3`),
        background: require(`@assets/exercise/memoryComplex/view8_bg3.png`),
      },
      {
        viewBox: 'surfing',
        type: 'memory',
        all: 3,
        selectorType: "not-same",
        randomDelay: 2000,
        showType: 'all',
        shortText: '나비 색상 다른 것 ',
        levelText: '초급',
        color: true,
        text: <div>꽃밭에 날아든 나비의 색상을 기억하세요.</div>,
        afterText: <div>꽃밭에 날아든 나비 색상이 다른 것을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v8_t1.mp3`),
        afterTextSound: require(`@assets/exercise/memoryComplex/sound/s_v8_t3.mp3`),
        background: require(`@assets/exercise/memoryComplex/view8_bg3.png`)
      },
      {
        viewBox: 'default',
        type: 'selector',
        all: 4,
        selectorType: 'not-same-name',
        minCorrect: 2,
        delay: 0,
        showType: 'all',
        showSub: 'image',
        shortText: '나비와 꽃의 색상 다른 것 ',
        levelText: '초급',
        color: true,
        text: <div>나비와 꽃 색이 <span style={{color: red[500]}}>다른 것</span>을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v8_t4.mp3`),
      },
      {
        viewBox: 'default',
        type: 'selector',
        all: 5,
        selectorType: 'same-name',
        minCorrect: 2,
        delay: 0,
        showType: 'all',
        showSub: 'image',
        shortText: '나비와 꽃의 색상 같은 것 ',
        levelText: '중급',
        color: true,
        text: <div>나비와 꽃 색이 <span style={{color: red[500]}}>같은 것</span>을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v8_t5.mp3`),
      },
      {
        viewBox: 'surfing',
        type: 'memory',
        all: 6,
        delay: 0,
        randomDelay: 2000,
        direction: 'first',
        showType: 'all',
        shortText: '나비 색상 찾기 ',
        levelText: '중급',
        color: true,
        text: <div>꽃밭에 날아든 나비의 색상을 기억하세요.</div>,
        afterText: <div>꽃밭에 날아든 같은 나비를 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v8_t1.mp3`),
        afterTextSound: require(`@assets/exercise/memoryComplex/sound/s_v8_t6.mp3`),
        background: require(`@assets/exercise/memoryComplex/view8_bg3.png`)
      },
      {
        viewBox: 'default',
        type: 'memory',
        all: 7,
        delay: 2000,
        direction: 'first',
        showType: 'step',
        shortText: '나비가 나타난 순서 ',
        levelText: '중급',
        color: true,
        text: <div>나비가 나타난 순서대로 찾으세요.</div>,
        textForOne: <div>먼저 나타난 나비를 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v8_t7.mp3`),
        textForOneSound: require(`@assets/exercise/memoryComplex/sound/s_v8_t7_2.mp3`),
      },
      {
        viewBox: 'default',
        type: 'memory',
        all: 8,
        delay: 2000,
        direction: 'first',
        showType: 'step',
        showSub: 'image',
        pairSub: true,
        shortText: '꽃과 나비가 나타난 순서 ',
        levelText: '고급',
        text: <div>꽃과 나비가 나타난 순서대로 찾으세요.</div>,
        textForOne: <div>먼저 나타난 것을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v8_t8.mp3`),
        textForOneSound: require(`@assets/exercise/memoryComplex/sound/s_v8_t7_1.mp3`),
      },
      {
        viewBox: 'default',
        type: 'memory',
        all: 9,
        delay: 2000,
        direction: 'last',
        showType: 'step',
        shortText: '나비가 늦게 나타난 순서 ',
        levelText: '고급',
        text: <div>나비가 늦게 나타난 순서부터 찾으세요.</div>,
        textForOne: <div>나비가 늦게 나타난 것을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v8_t9.mp3`),
        textForOneSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t9_1.mp3`),
      },
      {
        viewBox: 'default',
        type: 'memory',
        all: 10,
        delay: 2000,
        direction: 'last',
        showType: 'step',
        showSub: 'image',
        pairSub: true,
        shortText: '꽃과 나비가 늦게 나타난 순서 ',
        levelText: '고급',
        text: <div>꽃과 나비가 늦게 나타난 순서대로 찾으세요.</div>,
        textForOne: <div>늦게 나타난 것을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v8_t10.mp3`),
        textForOneSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t9_1.mp3`),
        
      },

      // {viewBox:'flip', type: 'selector',  selectorType: "not-same-name", all:4, delay:0, minCorrect:2, flipDelay: 4000,   showType:'all', min:3, text: <div>나비와 색상이 <span style={{color:red[500]}}>다른 것</span>을 찾으세요</div> },
      // {viewBox:'flip', type: 'selector',  selectorType: "same-name", all:4, delay:0, minCorrect:2, flipDelay: 4000,  showType:'all', text: <div>나비와 색상이 <span style={{color:red[500]}}>같은 것</span>을 찾으세요</div> },
      // {viewBox:'default', type: 'memory',    all:8, delay:2000,  direction:'first', showType:'step', text: <div>먼저 나타난 나비부터<span style={{color:red[500]}}>순서대로</span> 선택하세요</div> },
      // {viewBox:'default', type: 'memory',    all:8, delay:2000,  direction:'last', showType:'step', text: <div><span style={{color:red[500]}}>늦게 나타난</span> 순서대로 나비를 선택하세요</div> },

      // {viewBox:'default', type: 'selector',  selectorType:'same-name', all:8, minCorrect:4, delay:0,  showType:'all', showSub:'image', text: <div>나비와 꽃 색이 <span style={{color:red[500]}}>같은 것</span>을 모두 찾으세요</div> },
      // {viewBox:'default', type: 'memory', all:10, delay:0, randomDelay: 2000,  direction:'first', showType:'all', text: <div>꽃밭에 날아든 나비의 색상을 기억하세요</div>, afterText: <div>꽃밭에 날아든 나비의 색상을 <span style={{color:red[500]}}>모두</span> 찾으세요</div>, background:require(`@assets/exercise/memoryComplex/view8_bg3.png`) },
      // {viewBox:'default', type: 'calculator', all:2, calcType: 'plus', exView: 4, background:require(`@assets/exercise/memoryComplex/view8_bg1.jpg`), text: 'type1', backgroundColor:'#FEFCCD' },
      // {viewBox:'default', type: 'calculator', all:2, calcType: 'minus', exView: 4, background:require(`@assets/exercise/memoryComplex/view8_bg2.jpg`), text: 'type2', backgroundColor:'#FEFCCD' },
    ],
    view9: [
      {
        viewBox: 'firstStage',
        type: 'selector',
        all: 10,
        delay: 2000,
        showType: 'all',
        levelText: '선행',
        shortText: '선행학습',
        text: <div>캐릭터를 보고, 듣고, 이해한 후 소리내어 발음해 보세요. </div>
      },
      {
        viewBox: 'surfing',
        type: 'memory',
        all: 1,
        viewChar: 'view9-s',
        positions: [{right: 520, top: 100, width: 400, height: 400}],
        randomDelay: 2000,
        showType: 'all',
        afterShowType: 'text',
        showSubType: 'background',
        color: true,
        shortText: '도횡단보도의 자동차 색',
        levelText: '초급',
        text: <div>횡단보도의 차량을 기억하세요</div>,
        afterText: <div>횡단보도 앞에 멈춰 선 자동차 색상을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v9_t1.mp3`),
        afterTextSound: require(`@assets/exercise/memoryComplex/sound/s_v9_t1_1.mp3`),
        background: require(`@assets/exercise/memoryComplex/view9_bg4.png`)
        
      },
      {
        viewBox: 'surfing',
        type: 'memory',
        all: 2,
        viewChar: 'view9-s',
        selectorType: "not-same",
        positions: [{left: 320, top: 100, width: 400, height: 400}, {right: 320, top: 100, width: 400, height: 400}],
        randomDelay: 2000,
        showType: 'all',
        color: true,
        shortText: '횡단보도의 자동차 다른색',
        levelText: '초급',
        text: <div>횡단보도의 차량을 기억하세요.</div>,
        afterText: <div>횡단보도 앞에 멈춰 선 자동차 색상과 다른 것을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v9_t1.mp3`),
        afterTextSound: require(`@assets/exercise/memoryComplex/sound/s_v9_t2_1.mp3`),
        background: require(`@assets/exercise/memoryComplex/view9_bg4.png`)
      },
      {
        viewBox: 'surfing',
        type: 'memory',
        all: 3,
        viewChar: 'view9-s',
        positions: [{left: 300, top: 160, width: 300, height: 300}, {
          left: 600,
          top: 160,
          width: 300,
          height: 300
        }, {left: 900, top: 160, width: 300, height: 300}],
        randomDelay: 2000,
        showType: 'all',
        color: true,
        shortText: '횡단보도의 자동차 색',
        levelText: '초급',
        text: <div>횡단보도의 차량을 기억하세요.</div>,
        afterTextOne: <div>도로위에 차색상을 체크하세요.</div>,
        afterText: <div>도로위에 차색상을 모두 체크하세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v9_t1.mp3`),
        afterTextSound: require(`@assets/exercise/memoryComplex/sound/s_v9_t3_1.mp3`),
        afterTextOneSound: require(`@assets/exercise/memoryComplex/sound/s_v9_t3_2.mp3`),
        background: require(`@assets/exercise/memoryComplex/view9_bg4.png`)
      },
      {
        viewBox: 'flip',
        type: 'selector',
        all: 4,
        delay: 0,
        minCorrect: 2,
        flipDelay: 4000,
        selectorType: "not-same-name",
        showType: 'all',
        shortText: '화면의 자동차 색상이 다른 것',
        levelText: '초급',
        color: true,
        text: <div>화면의 자동차 색상이 <span style={{color: red[500]}}>다른 것</span>을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v9_t4.mp3`),
      },
      {
        viewBox: 'flip',
        type: 'selector',
        all: 5,
        delay: 0,
        minCorrect: 2,
        flipDelay: 4000,
        selectorType: "same-name",
        showType: 'all',
        shortText: '화면의 자동차 색 같은 것',
        levelText: '중급',
        color: true,
        text: <div>화면의 자동차 색상이 <span style={{color: red[500]}}>같은 것</span>을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v9_t5.mp3`),
      },
      {
        viewBox: 'flip',
        type: 'selector',
        all: 6,
        delay: 0,
        flipDelay: 4000,
        minCorrect: 4,
        selectorType: 'show-number',
        showSub: 'number',
        showSubType: 'background',
        showType: 'all',
        shortText: '번호가 있는 자동차',
        levelText: '중급',
        color: true,
        text: <div>번호가 있는 자동차는 <span style={{color: red[500]}}>무슨 색상</span>인가요?</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v9_t6.mp3`),
      },
      {
        viewBox: 'flip',
        type: 'selector',
        all: 7,
        delay: 0,
        flipDelay: 4000,
        minCorrect: 4,
        selectorType: 'not-same-number',
        showSub: 'number',
        showType: 'all',
        shortText: '자동차와 번호가 틀린 것',
        levelText: '중급',
        color: true,
        text: <div>자동차 번호와 색상이 <span style={{color: red[500]}}>틀린 것</span>을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v9_t7.mp3`),
      },
      {
        viewBox: 'flip',
        type: 'selector',
        all: 8,
        delay: 0,
        flipDelay: 4000,
        minCorrect: 4,
        selectorType: 'same-number',
        showSub: 'number',
        showType: 'all',
        shortText: '자동차와 번호가 맞는 것',
        levelText: '고급',
        color: true,
        text: <div>차 번호와 색상이 <span style={{color: red[500]}}>맞는 것</span>을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v9_t8.mp3`),
      },
      {
        viewBox: 'default',
        type: 'memory',
        all: 9,
        delay: 2000,
        direction: 'first',
        showType: 'step',
        shortText: '먼저 나타난 자동차',
        levelText: '고급',
        textForOne: <div>먼저 나타난 자동차를 찾으세요.</div>,
        text: <div>먼저 나타난 자동차부터 찾으세요.</div>,
        textForOneSound: require(`@assets/exercise/memoryComplex/sound/s_v9_t9_1.mp3`),
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v9_t9.mp3`)
      },
      {
        viewBox: 'default',
        type: 'memory',
        all: 10,
        delay: 2000,
        direction: 'last',
        showType: 'step',
        shortText: '늦게 나타난 자동차',
        levelText: '고급',
        textForOne: <div>늦게 나타난 자동차를 찾으세요.</div>,
        text: <div>늦게 나타난 자동차부터 찾으세요.</div>,
        textForOneSound: require(`@assets/exercise/memoryComplex/sound/s_v9_t10_2.mp3`),
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v9_t10_1.mp3`)
      },
      // {viewBox:'default', type: 'calculator', all:2, calcType: 'plus', exView: 4, background:require(`@assets/exercise/memoryComplex/view9_bg1.jpg`), text: 'type1', backgroundColor:'#FEFCCD' },
      // {viewBox:'default', type: 'calculator', all:2, calcType: 'minus', exView: 4, background:require(`@assets/exercise/memoryComplex/view9_bg2.jpg`), text: 'type2', backgroundColor:'#FEFCCD' },
    ],
    test: [
      {key: 'cognitiveTest1', label: '인지변별력'},
      {key: 'cognitiveTest2', label: '메타인지'},
      {key: 'memoryTest2_8', label: '주의력'},
      {key: 'memoryTest2_9', label: '집중력'},
      {key: 'memoryTest3', label: '주의 & 집중'},
      {key: 'memoryTest1', label: '기억력 (좌뇌)'},
      {key: 'memoryTest1_3', label: '기억력 (우뇌)'},
      {key: 'memoryTest1_4', label: '기억력 (균형)'},
      {key: 'memoryTest2_5', label: '장기기억력'},
      {key: 'memoryTest2_6', label: '공감력'},
      {key: 'memoryTest2_7', label: '사고력'},

    ],
    view10: [
      // {viewBox:'firstStage', type: 'selector',  all:10, delay:2000,    selectRatio:['생물','물건'], selectorType:'생물', min:3, showType:'all', text: <div>캐릭터를 보고, 듣고, 이해한 후 소리내어 발음해 보세요 </div> },
      // {viewBox:'default', type: 'memory',  all:10, forceAll: 2, position:[{x:0, y:300}, {x:200, y: 150}], delay:0, randomDelay: 20000,  direction:'first', showType:'all', text: <div>정상에 있는 깃발의 색을 기억하세요</div>, afterText: <div>나타난 깃발 색상을 <span style={{color:red[500]}}>모두</span> 찾으세요</div>, background:require(`@assets/exercise/memoryComplex/view8_bg4.jpg`) ,backgroundHeight: '73%' },
      // {viewBox:'surfing', type: 'memory',    viewChar:'view10-s', all:1 , positions:[{top:50, width: 300, height: 300}], delay:0, randomDelay: 2000,  direction:'first', showType:'all', shortText:'깃발 색상', levelText: '초급',  text: <div>정상에 있는 깃발의 색을 기억하세요</div>, afterText: <div>정상에 꽂힌 깃발 색상을 맞는 것을 찾으세요.</div>, background:require(`@assets/exercise/memoryComplex/view10_bg5.jpg`) ,backgroundHeight: '73%' },
      {
        viewBox: 'firstStage',
        type: 'selector',
        all: 10,
        delay: 2000,
        showType: 'all',
        levelText: '선행',
        shortText: '선행학습',
        text: <div>캐릭터를 보고, 듣고, 이해한 후 소리내어 발음해 보세요. </div>
      },
      {
        viewBox: 'surfing',
        type: 'memory',
        all: 1,
        viewChar: 'view10-s',
        delay: 0,
        randomDelay: 2000,
        direction: 'first',
        showType: 'all',
        shortText: '깃발 색상',
        levelText: '초급',
        color: true,
        afterShowType: 'text',
        showSubType: 'background',
        background: require(`@assets/exercise/memoryComplex/view10_bg1.jpg`),
        text: <div>깃발의 색상을 기억하세요.</div>,
        afterText: <div>기억한 깃발 색상을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v10_t1.mp3`),
        afterTextSound: require(`@assets/exercise/memoryComplex/sound/s_v10_t1_1.mp3`),
      },
      {
        viewBox: 'default',
        type: 'selector',
        all: 2,
        delay: 0,
        selectRatio: ['과일', '탈것'],
        selectorType: '탈것',
        min: 3,
        showType: 'all',
        shortText: '사람이 타고 달릴 수 있는 것',
        levelText: '초급',
        text: <div>사람이 타고 달릴 수 있는 것을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v10_t2.mp3`),
      },
      {
        viewBox: 'default',
        type: 'selector',
        all: 3,
        delay: 0,
        selectRatio: ['과일', '동물'],
        selectorType: '과일',
        min: 3,
        showType: 'all',
        shortText: '과일과 야채',
        levelText: '초급',
        text: <div>과일과 야채 종류를 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v10_t3.mp3`),
      },
      {
        viewBox: 'default',
        type: 'selector',
        all: 4,
        delay: 0,
        selectRatio: ['과일', '동물'],
        selectorType: '동물',
        min: 3,
        showType: 'all',
        shortText: '과일과 야채가 아닌것',
        levelText: '초급',
        text: <div>과일과 야채가 아닌 것을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v10_t4.mp3`),
      },
      {
        viewBox: 'default',
        type: 'selector',
        all: 5,
        delay: 0,
        selectRatio: ['과일', '동물'],
        selectorType: '동물',
        min: 3,
        showType: 'all',
        shortText: '동물',
        levelText: '중급',
        text: <div>동물을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v10_t5.mp3`),
      },
      {
        viewBox: 'default',
        type: 'selector',
        all: 6,
        delay: 0,
        selectRatio: ['과일', '동물'],
        selectorType: '과일',
        min: 3,
        showType: 'all',
        shortText: '동물이 아닌 것',
        levelText: '중급',
        text: <div>동물이 아닌것을 찾으세요.</div>,
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v10_t6.mp3`),
      },
      {
        viewBox: 'default',
        type: 'memory',
        all: 7,
        delay: 2000,
        direction: 'first',
        showType: 'step',
        shortText: '먼저 나타난 순서',
        levelText: '중급',
        textForOne: <div>먼저 나타난 것을 찾으세요</div>,
        text: <div>먼저 나타난 순서대로 찾으세요.</div>,
        textForOneSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t8_1.mp3`),
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t8_2.mp3`),
      },
      {
        viewBox: 'default',
        type: 'memory',
        all: 8,
        delay: 2000,
        selectRatio: ['과일', '동물'],
        selectorType: '과일',
        direction: 'first',
        showType: 'step',
        currentStep: 0,
        shortText: '야채와 과일이 나타난 순서',
        levelText: '고급',
        textForOne: <div>첫 번 나타난 과일과 야채를 찾으세요.</div>,
        text: <div>야채와 과일이 나타난 순서대로 찾으세요.</div>,
        textForOneSound: require(`@assets/exercise/memoryComplex/sound/s_v10_t8_1.mp3`),
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v10_t8_2.mp3`),
      },
      {
        viewBox: 'default',
        type: 'memory',
        all: 9,
        delay: 2000,
        selectRatio: ['과일', '동물'],
        selectorType: '동물',
        direction: 'first',
        showType: 'step',
        currentStep: 0,
        shortText: '동물이 나타난 순서',
        levelText: '고급',
        textForOne: <div>첫 번 나타난 동물을 찾으세요.</div>,
        text: <div>동물이 나타난 순서대로 찾으세요.</div>,
        textForOneSound: require(`@assets/exercise/memoryComplex/sound/s_v10_t9_1.mp3`),
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v10_t9_2.mp3`),
      },
      {
        viewBox: 'default',
        type: 'memory',
        all: 10,
        delay: 2000,
        direction: 'last',
        showType: 'step',
        shortText: '늦게 나타난 순서',
        levelText: '고급',
        textForOne: <div>늦게 나타난 것을 찾으세요.</div>,
        text: <div>늦게 나타난 순서대로 찾으세요.</div>,
        textForOneSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t9_1.mp3`),
        textSound: require(`@assets/exercise/memoryComplex/sound/s_v5_t10_2.mp3`),
      },

      // {viewBox:'default', type: 'memory',    all:9, delay:2000,  direction:'last', showType:'step', selectRatio:['생물','물건'], selectorType:'물건', text: <div> 비해양생물을 늦게 나타난 순서부터 선택하세요.</div> },
      // {viewBox:'default', type: 'selector',  all:10, delay:0,     selectRatio:['생물','물건'], selectorType:'생물', showType:'all', min:3, text: <div>물건이 <span style={{color:red[500]}}>아닌 것</span>을 모두 찾으세요</div> },
      // {viewBox:'default', type: 'calculator', all:2, calcType: 'plus', exView: 4, background:require(`@assets/exercise/memoryComplex/background.jpg`), text: 'type1', backgroundColor:'#FFFEDF'},
      // {viewBox:'default', type: 'calculator', all:2, calcType: 'minus', exView: 4, background:require(`@assets/exercise/memoryComplex/background.jpg`), text: 'type2', backgroundColor:'#FFFEDF' },
    ],
    view11: [
      {
        viewBox: 'calculation',
        type: 'plus',
        all: 2,
        rangeMin: 1,
        rangeMax: 9,
        shortText: '2개 더하기 (1자리)',
        levelText: '초급',
        text: <div>모든 숫자를 더한 값을 고르세요.</div>,
      },
      {
        viewBox: 'calculation',
        type: 'plus',
        all: 2,
        rangeMin: 10,
        rangeMax: 99,
        shortText: '2개 더하기 (2자리)',
        levelText: '초급',
        text: <div>모든 숫자를 더한 값을 고르세요.</div>,
      },
      {
        viewBox: 'calculation',
        type: 'plus',
        all: 2,
        rangeMin: 100,
        rangeMax: 999,
        shortText: '2개더하기 (3자리)',
        levelText: '초급',
        text: <div>모든 숫자를 더한 값을 고르세요.</div>,
      },

      {
        viewBox: 'calculation',
        type: 'plus',
        all: 3,
        rangeMin: 1,
        rangeMax: 9,
        shortText: '3개 더하기 (1자리)',
        levelText: '초급',
        text: <div>모든 숫자를 더한 값을 고르세요.</div>,
      },
      {
        viewBox: 'calculation',
        type: 'plus',
        all: 3,
        rangeMin: 10,
        rangeMax: 99,
        shortText: '3개 더하기 (2자리)',
        levelText: '초급',
        text: <div>모든 숫자를 더한 값을 고르세요.</div>,
      },
      {
        viewBox: 'calculation',
        type: 'plus',
        all: 3,
        rangeMin: 100,
        rangeMax: 999,
        shortText: '3개더하기 (3자리)',
        levelText: '초급',
        text: <div>모든 숫자를 더한 값을 고르세요.</div>,
      },

      {
        viewBox: 'calculation',
        type: 'matrix',
        all: 10,
        row: 8,
        col: 5,
        size: 100,
        tolerance: 30,
        answerCount: 4,
        rangeMin: 10,
        rangeMax: 90,
        shortText: ' 4 x 4 정답찾기',
        levelText: '초급',
        text: <div>아래 카드를 보고 정답을 선택하세요.</div>,
      },

      // {viewBox:'surfing', type: 'memory',    viewChar:'view10-s', all:1 , positions:[{top:50, width: 300, height: 300}], delay:0, randomDelay: 2000,  direction:'first', showType:'all', text: <div>정상에 있는 깃발의 색을 기억하세요</div>, afterText: <div>정상에 꽂힌 깃발 색상을 맞는 것을 찾으세요.</div>, background:require(`@assets/exercise/memoryComplex/view10_bg5.jpg`) ,backgroundHeight: '73%' },
      // {viewBox:'surfing', type: 'memory',    viewChar:'view10-s', all:2 , selectorType: "not-same", positions:[{right:250, top:40, width: 300, height: 300},{left:520, top:130, width: 300, height: 300}], delay:0, randomDelay: 2000,  direction:'first', showType:'all', text: <div>정상에 있는 깃발의 색을 기억하세요</div>, afterText: <div> 정상에 깃발 색상이 다른 것을 찾으세요.</div>, background:require(`@assets/exercise/memoryComplex/view10_bg4.jpg`) ,backgroundHeight: '73%' },
      // {viewBox:'default', type: 'selector',  all:3, delay:0,     selectRatio:['과일','동물'], selectorType:'과일', min:3, showType:'all', text: <div>과일과 야채 종류만 찾으세요.</div> },
      // {viewBox:'default', type: 'selector',  all:4, delay:0,     selectRatio:['과일','동물'], selectorType:'동물', min:3, showType:'all', text: <div>과일과 야채가 아닌 것을 찾으세요.</div> },
      // {viewBox:'default', type: 'selector',  all:5, delay:0,     selectRatio:['과일','동물'], selectorType:'동물', min:3, showType:'all', text: <div>동물만 찾으세요</div> },
      // {viewBox:'default', type: 'selector',  all:6, delay:0,     selectRatio:['과일','동물'], selectorType:'과일', min:3, showType:'all', text: <div>동물이 아닌것을 찾으세요</div> },
      // {viewBox:'default', type: 'memory',    all:7, delay:2000,  selectRatio:['과일','동물'], selectorType:'과일', direction:'first', showType:'step', currentStep:0, text: <div>야채와 과일이 나타난 순서대로 선택하세요.</div> },

      // {viewBox:'default', type: 'memory',    all:9, delay:2000,  direction:'last', showType:'step', selectRatio:['생물','물건'], selectorType:'물건', text: <div> 비해양생물을 늦게 나타난 순서부터 선택하세요.</div> },
      // {viewBox:'default', type: 'selector',  all:10, delay:0,     selectRatio:['생물','물건'], selectorType:'생물', showType:'all', min:3, text: <div>물건이 <span style={{color:red[500]}}>아닌 것</span>을 모두 찾으세요</div> },
      // {viewBox:'default', type: 'calculator', all:2, calcType: 'plus', exView: 4, background:require(`@assets/exercise/memoryComplex/background.jpg`), text: 'type1', backgroundColor:'#FFFEDF'},
      // {viewBox:'default', type: 'calculator', all:2, calcType: 'minus', exView: 4, background:require(`@assets/exercise/memoryComplex/background.jpg`), text: 'type2', backgroundColor:'#FFFEDF' },
    ],
  }
  
  static defaultStages = _.cloneDeep(this.stages)
}