
import ProfileService from '@user-service/ProfileService'

export default class AuthProvider {

    static userSession;
    static userCookieName = '_sass'
    static userAdminCookieName = '_sass_ad'
    static userOriCookieName = '_sass_a' 
    static ignoreAuthPath = ['/login' , '/join' , '/loginHelp' ,'/registerComplete', '/waiting-device', '/login-device', '/joinDone', '/reset-password', '/gateway', '/gateway', '/measureFFT','measureFFTs']
    static appPages = ['measureTest', 'survey', 'survey-life','measureTrain']
    static ignoreHost = ['localhost', 'cli', '']
  
    // web | mobile 
    // app:mind, app:health
    static serviceType = 'web' 
        
    static setCookie(cookie) {
        try{
            this.userSession = cookie
            if(!this.userSession){
                this.userSession = {}
            }
        }catch(e){
            console.log(e);
        }
    }
    
    
    static getCookieOption(){
        return {
            expires: new Date(Date.now()+ (60 * 60 * 24 * 365)),
            maxAge: 60 * 60 * 24 * 365,
            path: '/',
            sameSite: 'lax',
            domain: process.env.NODE_ENV === 'development' ? window.location.hostname : process.env.REACT_APP_COOKIE_DOMAIN
        }
    }

    static checkAuthToken(pathname){
        if('/' !== pathname){
            const isIgnore = this.ignoreAuthPath.filter(url => -1 < url.indexOf(pathname))
            if(0 < isIgnore.length) return true    
        }
        
        if(-1 < pathname.indexOf('/measureFFT/') ||
          -1 < pathname.indexOf('/measureFFTs/')){
            return true
        }

        if(-1 < pathname.indexOf('/gateway')){
          return true
        }
        
        const token = this.getToken()
        if(!token || '' === token) {
            window.location.href = '/login'
            return false
        }
        
        if( '/' === pathname ) {
            window.location.href = '/dashboard'
            return true
        }
        return true
    }

    static hasProfileID(){
        return !!this.userSession['profileID']
    }

    static set(key, value){
        try {
            this.userSession[key] = value
        }catch(e){}
    }

    static get(key){
        return this.userSession[key]
    }

    static GoServicePage(){
      try {
        const hostname = window.location.hostname;
        const isLocalhost = hostname === 'localhost' || hostname === '127.0.0.1';

        if (isLocalhost) {
          const urlParams = new URLSearchParams(window.location.search);
          if(urlParams.get('serviceType')){
            const serviceType = urlParams.get('serviceType').split(':')[1];

            if ('measureTest' === serviceType) document.location.replace(window.location.origin + '/measureTest');
            if ('measureTrain' === serviceType) document.location.replace(window.location.origin + '/measureTrain');
            if ('survey' === serviceType) document.location.replace(window.location.origin + '/survey-year');
            if ('survey-life' === serviceType) document.location.replace(window.location.origin + '/survey-year');
          }
        } else {
          const subdomain = hostname.replace('.brhw.kr', '');
          if (!this.ignoreHost.includes(subdomain)) {
            if ('app-test' === subdomain) document.location.replace(window.location.origin + '/measureTest');
            if ('app-train' === subdomain) document.location.replace(window.location.origin + '/measureTrain');
            if ('app-survey' === subdomain) document.location.replace(window.location.origin + '/survey-year');
            if ('app-survey-life' === subdomain) document.location.replace(window.location.origin + '/survey-year');
          }
        }
      } catch { }
    }
    
    static getServiceType() {
        try {
            const subdomain = window.location.hostname.replace('.brhw.kr', '')
            if(!this.ignoreHost.includes(subdomain)){
                if('app-test' === subdomain) return 'app:measureTest'
                if('app-train' === subdomain) return 'app:measureTrain'
                if('app-survey' === subdomain) return 'app:survey'
                if('app-survey-life' === subdomain) return 'app:survey-life'
            }
            
            const serviceType = this.userSession['serviceType']
            return serviceType ? serviceType :  'web:web'
            
        } catch { return 'web:web' }
    }
    
    static isAppServicePage(){
        let page = this.getServiceType().split(':')[1]
        return this.appPages.includes(page)
    }
    
    static getAppServicePage(){
        try {
            return this.getServiceType().split(':')[1]
        } catch {}
        
        return 'web'
    }
    
    static isAppMode() {
        try {
            return -1 < this.getServiceType().indexOf('app') && 'app:admin' !== this.getServiceType()    
        } catch { return false }
    }
    
    
    static isAdmin(){
        try {
            if(this.isCustomerView()) {
                const member = JSON.parse(localStorage.getItem(this.userAdminCookieName))
                return member.memberType
                
            }
            
            return 'admin' === this.userSession['memberType'];
        } catch (e) {
            return false;
        }
    }
  
    static isProfileSelector(){
      try {
        return 'profile-selector' === this.userSession['memberType'];
      } catch (e) {
        return false;
      }
    }
    
    static isSuperAdmin(){
        try {
            let profileID = this.userSession['profileID']
            if(this.isCustomerView()) {
                const member = JSON.parse(localStorage.getItem(this.userAdminCookieName))
                profileID = member.profileID
            }
            return (
                (61 === profileID || 19 === profileID || 1794 === profileID)    
            )
        } catch (e) {
            return false;
        }
    }
    
    static isShowAnswerMember(){
      try {
        let profileID = this.userSession['profileID']
        if(this.isCustomerView()) {
          const member = JSON.parse(localStorage.getItem(this.userAdminCookieName))
          profileID = member.profileID
        }
        return (
          (1805 === profileID || 62 === profileID || 19 === profileID || 1794 === profileID)
        )
      } catch (e) {
        return false;
      }
    }
    
    static getMemberTypeName(){
        switch(this.userSession['memberType']){
            case 'admin':
                return '센터관리자'
            case 'schoolAdmin':
                return '브레인헬스학교 관리자'
            default:
                return '일반 사용자'
        }
    }
    
    static getRole(){
        const viewMode = this.userSession['viewMode']
        
        if('customer' === viewMode){
            return viewMode
        }
        
        return this.userSession['memberType']
    }
    static loginByDevice(){
        return !!this.userSession['loginByDevice'];
    }

    static remove(){
        this.userSession = null
    }

    static getUserData(){
        return this.userSession
    }

    static getToken(){
        if(!this.userSession) return null

        return this.userSession['authToken']
    }

    static getProfileID(){
        if(this.isCustomerView()){
            return this.getCustomerProfileID()
        }
        return this.userSession['profileID']
    }

    static getMemberID(){
        return this.userSession['memberID']
    }

    static getProfileName(){
        if(!this.userSession) return ''
        return this.userSession['profileName']
    }

    static getProfileCode(){
        let code = this.userSession['profileCode']
        if(!code && this.userSession['code']){
          code = this.userSession['code']
        }
        return code
    }

    static getProfileType(){
        return this.userSession['profileName']
    }
    
    static getDeviceID(){
        return this.userSession['deviceID']
    }
    static setDeviceID(deviceID){
        this.userSession['deviceID'] = deviceID
    }

    static async setCookieWithProfile(cookie){
        this.setCookie(cookie)
        const profiles = await ProfileService.getProfiles()
        try{
            const profile = profiles[0]

            this.set('profileID', profile.id)
            this.set('profileName', profile.name)
            this.set('profileType', profile.type)
            
            await ProfileService.setCurrent(profile.id)

        }catch(e){}

        return this.getUserData()
    }
    
    static getCustomerProfileID(){
        return this.userSession['customerProfileID']
    }
    
    static getCustomerMemberID(){
        return this.userSession['customerMemberID']
    }
    
    static clearCustomer(){
        try {
            delete this.userSession['viewMode']
            delete this.userSession['customerProfileID']
            delete this.userSession['customerMemberID']
            delete this.userSession['deviceID']
        } catch {}
    }
    
    static isCustomerView(){
        return (this.userSession && 'customer' === this.userSession['viewMode'])
    }
    
    static isProfileSelectorView(){
      return (this.userSession && 'profileSelector' === this.userSession['viewMode'])
    }
    static canViewMeasureDetail(){
        return true
        // return (this.isCustomerView() || this.isAdmin())
    }
    
    static getProfileImage(){
        let image = '' 
        if(this.get('image')){
            image = require(`@images/profile/${this.get('image')}`)
        }
        return image
    }
    
    static getManagerID() {
        return this.userSession['managerID']
        
        // try {
        //     const member = JSON.parse(localStorage.getItem(this.userAdminCookieName))
        //     return member.memberID
        // } catch{}
        //
        // return undefined
    }
    
    static getProfileSelectorID(){
      return this.userSession['profileSelectorID']
    }
}
