import React, {useCallback, useEffect, useReducer} from 'react';
import {Box, Button, Grid, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {useParams, useHistory} from "react-router-dom";

import CommonHelper from "@helper/CommonHelper";
import PrescriptionService from '@user-service/PrescriptionService'
import AuthProvider from '@user-provider/AuthProvider'

import DraggableList from './DraggableExerciseList';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import ExerciseService from "../../../services/ExerciseService";
import clsx from "clsx";
import Message from "../Message";
import DatePicker from "../../../components/DatePicker";
import LoadingIndicator from "../../../components/LoadingIndicator";


const initState = {
    currentTab: 0,
    
    prescriptionID: null,
    exercises: [],
    defaultExercises: [],
    filteredExercises: [],
    beforeEditedExercises: [],
    changePrograms: [],
    addProgram: [],
    
    isChangeProgram: false,
    isChangeProgramCode: false,

    prescriptExpireDate: null,
    exerciseWeekCount: 0,
    exerciseDayCount: 0,
    prescriptDayCount: 0,
    reservationDate: null
    
}

function reducer(state, action){
    const { defaultExercises, exercises, filteredExercises, changePrograms, prescriptionID, addProgram } = action
    const { exerciseWeekCount, exerciseDayCount, prescriptDayCount, prescriptExpireDate, reservationDate } = action
    
    switch(action.type){
        case 'UPDATE_PRESCRIPTION_EXERCISE':
            return {
                ...state,
                filteredExercises
            }
        case 'UPDATE_EXERCISE':
            return {
                ...state,
                defaultExercises,
                exercises,
                filteredExercises,
                changePrograms,
                prescriptionID,
                exerciseWeekCount, 
                exerciseDayCount, 
                prescriptDayCount,
                prescriptExpireDate,
                addProgram,
                reservationDate
            }
        case 'UPDATE_PROGRAM_CODE':
            return {
                ...state,
                filteredExercises,
                isChangeProgramCode: true
            }
        case 'NEED_UPDATE_PROGRAM':
            return {
                ...state,
                addProgram,
                isChangeProgram: true,
            }
        case 'SAVE_PROGRAM':
            // let isChangeProgram = state.isChangeProgram
            // if(isChangeProgram) {
            //     PrescriptionService.addPrescriptionExercise(state.prescriptionID, state.addProgram).then()
            //     isChangeProgram = false
            // }
            //   
            // let isChangeProgramCode = state.isChangeProgramCode
            // if(isChangeProgramCode){
            //     PrescriptionService.updatePrescriptionExercisesCode(state.prescriptionID, state.filteredExercises).then()
            //     PrescriptionService.updatePrescriptionWorkCount(state.prescriptionID, state).then()
            //     isChangeProgramCode = false
            // }
            return{
                ...state,
                isChangeProgram : false,
                isChangeProgramCode : false,
                addProgram: []
            } 
        case 'UPDATE_TAB':
            return {
                ...state,
                currentTab: action.currentTab
            }
        case 'UPDATE_WORK_COUNT':
            return {
                ...state,
                exerciseWeekCount, 
                exerciseDayCount, 
                prescriptDayCount,
                isChangeProgramCode: true
            }

        default:
    }
    return state
}


let changePrograms = []
export default function PrescriptionExercise(props) {
    const classes = useStyles()
    const history = useHistory()
    
    let {prescriptionID} = useParams()
    if(!prescriptionID) {
        prescriptionID = props.prescriptionID
    }
    const [state, dispatch] = useReducer(reducer, initState)
    const {addProgram, exercises, filteredExercises, currentTab, defaultExercises, prescriptExpireDate} = state
    
    const fetch = useCallback(async () => {
        const res = await PrescriptionService.getExercise(prescriptionID)
        const resExercises = await ExerciseService.getExercises('exercise')
        
        const termExercise = res.exercises.filter( ex => ex.term === currentTab)
        changePrograms = res.exercises.map( exercise => exercise.id)

        setTimeout(() => {
            dispatch({
                type: 'UPDATE_EXERCISE',
                exercises: resExercises.data,
                defaultExercises: res.exercises,
                filteredExercises: termExercise,
                beforeEditedExercises : termExercise,
                changePrograms,
                prescriptionID,
                addProgram: [],

                prescriptDayCount: res.prescriptDayCount,
                prescriptExpireDate: res.prescriptExpireDate,
                exerciseDayCount: res.prescriptDayExercise,
                exerciseWeekCount: res.prescriptWeekExercise,
                reservationDate: res.reservationDate
            })
        }, 600)
        
        
    }, [prescriptionID])
    
    useEffect(() => {
        fetch().then()
    }, [fetch]);
    
    useEffect(() => {
        dispatch({
            type: 'UPDATE_PRESCRIPTION_EXERCISE',
            filteredExercises: defaultExercises.filter( ex => ex.term === currentTab)
        })
    },[currentTab, defaultExercises])
    
    if (!exercises || 0 === exercises.length) {
        return <LoadingIndicator />;
    }

    const onDragEnd = async ({ destination, source }) => {
        const sourceEx = CommonHelper.clone(filteredExercises[source.index])
        
        filteredExercises.splice(source.index, 1)
        filteredExercises.splice(destination.index, 0, sourceEx)
        
        dispatch({
            type: 'UPDATE_PROGRAM_CODE',
            filteredExercises
        })

        PrescriptionService.updatePrescriptionExercisesCode(state.prescriptionID, filteredExercises).then()
    }
    
    
    const onItemUpdate = (exercise) => {
        const newFiltered = filteredExercises.map((ex) => {
            if(ex.prescriptionExerciseID === exercise.prescriptionExerciseID){
                ex.measureTypeCode = exercise.measureTypeCode
                ex.measureCode = exercise.measureCode
                ex.exerciseCount = exercise.exerciseCount
            }
            return ex
        })
        
        dispatch({
            type: 'UPDATE_PROGRAM_CODE',
            filteredExercises: newFiltered
        })

        PrescriptionService.updatePrescriptionExercisesCode(state.prescriptionID, newFiltered).then()
        
    }
    
    
    const handleToggle = (programID) => () => {
        const index = addProgram.indexOf(programID)
        if(-1 === index){
            addProgram.push(programID)
        } else {
            delete addProgram[index]
        }
    
        dispatch({
            type: 'NEED_UPDATE_PROGRAM',
            addProgram
        })
    };
    
    const updateWorkCount = async (type, value) => {
        let { exerciseWeekCount, exerciseDayCount, prescriptDayCount } = state
        
        if('exerciseWeekCount' === type) exerciseWeekCount = value
        if('exerciseDayCount' === type) exerciseDayCount = value
        if('prescriptDayCount' === type) prescriptDayCount = value
        
        dispatch({
            type: 'UPDATE_WORK_COUNT',
            exerciseWeekCount, 
            exerciseDayCount, 
            prescriptDayCount
        })
    }

    const customList = (items) => (
        <Paper className={classes.paper}>
            <Box display={'flex'} justifyContent={'flex-end'}>
                <Button
                    disabled={0 === addProgram.length}
                    className={classes.button}
                    onClick={ async() => {
                        const { prescriptionID, addProgram } = state
                        
                        await PrescriptionService.addPrescriptionExercise(prescriptionID, addProgram, currentTab)
                        await fetch()

                        dispatch({
                            type: 'SAVE_PROGRAM'
                        })
                    }}
                >
                    추가
                </Button>
            </Box>
            <List dense component="div" role="list">
                {items.map((item) => {
                    return (
                        <ListItem key={item.name} role="listitem" button onClick={handleToggle(item.id)}>
                            <ListItemIcon>
                                <Checkbox
                                    tabIndex={-1}
                                    checked={-1 < addProgram.indexOf(item.id)}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': item.id }}
                                />
                            </ListItemIcon>
                            <ListItemText id={item.id} primary={` ${item.name}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );
    const simpleRender = (props) => (
       <Box className={clsx({
           ['innerViewport']: props.simpleRender
       })}>
        <Grid container className={clsx('tabs',{
            ['small']: props.simpleRender
        })}>
            {false !== props.useMessageBox &&
            <Grid item xs={12}>
                <Message
                    profileID={AuthProvider.getProfileID()}
                    hideEmptyMessage={true}
                    prescriptionID={prescriptionID}
                    useHighlight={true}
                />
            </Grid>
            }
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={9}>
                        <Tabs
                            value={currentTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(event, newValue) => {
                                dispatch({
                                    type: 'UPDATE_TAB',
                                    currentTab: newValue
                                })
                            }}
                        >
                            <Tab label="1회차" className={'tab'}/>
                            <Tab label="2회차" className={'tab'}/>
                            <Tab label="3회차" className={'tab'}/>
                        </Tabs>
                    </Grid>
                    <Grid item xs={3}>
                        <DatePicker title={'처방전 종료일'} value={prescriptExpireDate} onChange={(newDate) => {
                            
                            if('Invalid date' === newDate) return
                            PrescriptionService.updateExpireDate(prescriptionID, newDate).then()
                        }}/>
                    </Grid>
                    {/*<Grid item xs={3}>*/}
                    {/*    <DatePicker title={'다음예약일'} value={reservationDate} onChange={(newDate) => {*/}
                    {/*        PrescriptionService.updateReservationDate(prescriptionID, newDate).then()*/}
                    {/*    }}/>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>
            {/*<Grid item xs={2}>*/}
                {/*<CopyPrescription profileID={props.profileID}/>*/}
            {/*</Grid>*/}
        </Grid>

        <Grid
            container
            spacing={2}
            className={classes.root}
        >
            <Grid item xs={3}>{customList(exercises)}</Grid>
            <Grid item xs={8}>
                <DraggableList
                    items={filteredExercises}
                    onDragEnd={onDragEnd}
                    onUpdate={onItemUpdate}
                    onDelete={() => {
                        fetch().then()
                    }}
                    onUpdateWorkCount={updateWorkCount.bind(this)}
                    {...state}
                />
            </Grid>
        </Grid>
       </Box>
    )


    const defaultRender = (props) => (
        <div id="cont-wrap">
            <div className="sv">
                <Grid container>
                    <Grid item xs={1}>
                        <IconButton
                            className={classes.back}
                            aria-label="delete"
                            onClick={()=>{
                                history.goBack()
                            }}
                        >
                            <ArrowBackIcon style={{fontSize: 50}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <div>
                            <h3>처방전 설정 - {prescriptionID} 회차</h3>
                            <span>처방전 프로그램을 설정합니다.</span>
                            <i>Brain Health</i>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div className="cont" style={{marginTop: 20}}>
                {simpleRender(props)}
            </div>
        </div>
    )
    
    return props.simpleRender ? simpleRender(props) : defaultRender(props) 
            
}

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 1400,
        width: 1400,
        marginTop:  20
    },
    back: {
        marginTop: 30,
        '&:hover': {
            backgroundColor: '#053288',
        }  
    },
    headerBox: {
        marginBottom: 40,
        marginTop: 120
    },
    title: {
        fontSize:26,
        fontWeight: 'bold'
    },
    subTitle: {
        fontSize: 22
    },
    button: {
        border: '2px solid #0055BF',
        padding: '5px 5px',
        '& .MuiButton-endIcon': {
            marginRight: -15
        },
        '& .rightIcon': {
            color: '#00DD94',
            fontSize: 25,
            fontWeight: 'bold'
        },
        backgroundColor: '#0055BF',
        color: 'white',
        '&:hover':{
            backgroundColor: '#04318E'
        },
        '&.Mui-disabled': {
            border: '2px solid #ededed',
            backgroundColor: '#ededed',
            color:'#999'
        }
    },
}));
