import React, {useCallback, useEffect, useState} from 'react';
import {Grid, Box, Link} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import SurveyService from '@user-service/SurveyService'

import Slider from '@material-ui/core/Slider';
const CustomSlider = withStyles({
    root: {
        color: '#00D48F',
        height: 9,
        padding: '5px 0'
    },
    thumb: {
        height: 0,
        width: 0,
        display: 'none'
    },
    active: {},
    valueLabel: {
        display: 'none'
    },
    disabled: {
        color: '#00D48F',  
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 1,
        position: 'absolute',
        top: 'calc(50% - 1px)',
        backgroundColor: '#8799AD'
    },
})(Slider);

const DashSurvey = () => {
    const classes = useStyles();
    const items = [{
        text: '수면장애',
        percent: 0
    },{
        text: '기억력',
        percent: 0
    },{
        text: '불안감',
        percent: 0
    },{
        text: '긴장감',
        percent: 0
    },{
        text: '긴장감',
        percent: 0
    }]

    const [surveyRecent, setSurveyRecent] = useState(items)
    const [surveyType] = useState('emotion')

    const fetchHSurveyRecent = useCallback(async () => {
        const res = await SurveyService.getSurveyRecent(surveyType)
        const recent = []

        res.result.map((value) => {
            recent.push({
                text: value.content,
                percent: parseInt(value.level),
            })
            return value
        })

        recent.sort((a,b) => {
            return b.level - a.level
        })

        setSurveyRecent(recent.slice(0,5))
    }, [surveyType])

    useEffect(()=>{
        fetchHSurveyRecent().then()
    },[fetchHSurveyRecent])

    
    return (
        <React.Fragment>
            <h4>자각증상</h4>
            <Link className="bt-more" href={'/survey-year'}>
                <span>상세보기</span>
            </Link>
            
            <div className="awakening">
                <Grid container spacing={1}>
                    {surveyRecent.map((item, index) => {
                        return (
                            <Grid item xs={12} key={`survey-${index}`}>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Box component={'span'} className={classes.customBox}>
                                            {item.text}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className={classes.percentText}>{item.percent}%</Box>
                                    </Grid>
                                </Grid>
                                <CustomSlider
                                    key={Math.random()}
                                    disabled aria-label={item.text}
                                    defaultValue={item.percent}
                                    style={{color: '#00D48F'}}/>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        </React.Fragment>
    )
};

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#F5F9FE',
        borderRadius: 30,
        minHeight: 357,
    },
    wrap: {
        padding: '20px 24px'
    },
    title: {
        fontSize: 14
    },
    percentText: {
        textAlign: 'right',
        fontSize: 24,
        fontWeight: 700
    },
    each: {
        marginBottom: 12
    },
    customBox: {
        fontSize: 24,
        fontWeight: 400,
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: 1,
        wordBreak: "break-all",
        overflow: "hidden"
    }
}));

export default DashSurvey;
