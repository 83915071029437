import React, {useCallback, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {Box, Button, Grid, Select, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import MemoryComplexData from "./MemoryComplexData";
import MenuItem from "@material-ui/core/MenuItem";
import ExerciseService from "../../../services/ExerciseService";
const ChoiceLevel = (props) =>  {
  const classes = useStyles();

  const [delay, setDelay] = useState(3000)
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(props.open)
  }, [props.open])
  
  const start = (count, newDelay) => {
    try {
      props.start(count, newDelay, 'train')
    } catch {}
  }

  const handleChange = (newValue) => {
    setDelay(newValue.target.value)
  }

  const { programView } = props
  const stages = MemoryComplexData.stages[programView]
  
  return (
      <Dialog
          disableBackdropClick
          open={open}
          style={{zIndex:999}}
      >
          <DialogContent style={{
              display: "flex",
              justifyContent: "center",
          }}>
            <div>
              <Typography variant="h5" className={classes.title}>
                훈련 할 프로그램을 선택해 주세요
              </Typography>
              <Grid container>
                <Grid item xs={9}>
                  <div style={{paddingLeft: 20}}>나타나는 속도</div>
                  
                </Grid>
                <Grid item xs={3}>
                  
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={delay}
                    onChange={handleChange}
                    style={{ marginLeft: 50}}
                  >
                    <MenuItem value={2000}>2초</MenuItem>
                    <MenuItem value={3000}>3초</MenuItem>
                    <MenuItem value={4000}>4초</MenuItem>
                    <MenuItem value={5000}>5초</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              {stages.map((stage, index) => {
                let backgroundImage = '';

                let typeId = '';

                if('view5' === programView) typeId = '';
                if('view10' === programView) typeId = '_2';
                if('view8' === programView) typeId = '_3';
                if('view9' === programView) typeId = '_4';
                if('view11' === programView) typeId = '_5';
                
                const images = [
                  require(`@assets/images/first_bg${typeId}.png`),
                  require(`@assets/images/default_bg_1${typeId}.png`),
                  require(`@assets/images/default_bg_2${typeId}.png`),
                  require(`@assets/images/default_bg_3${typeId}.png`),
                ]
                
                if('선행' === stage.levelText) backgroundImage = `url(${images[0]})`
                if('초급' === stage.levelText) backgroundImage = `url(${images[1]})`
                if('중급' === stage.levelText) backgroundImage = `url(${images[2]})`
                if('고급' === stage.levelText) backgroundImage = `url(${images[3]})`
                
                return (
                  <Box
                    key={index} 
                    className={classes.beginnerButton}
                    style={{
                      backgroundImage: backgroundImage,
                      backgroundSize: '100% 100%',
                      backgroundRepeat: 'no-repeat'
                    }}
                    onClick={() => start(index, delay)}>
                    <Grid container>
                      <Grid item xs={11} style={{position:'relative'}}>
                        <div
                          style={{position: 'absolute', fontSize: '0.8rem', fontWeight: 'bold', top: 5, paddingLeft: 5}}
                        >{'firstStage' !== stage.viewBox ? `Lv.${stage.all}` : ''}</div>
                        <div style={{paddingLeft: 60}}>{stage.shortText}</div>
                      </Grid>
                      <Grid 
                        item xs={1} 
                      >
                        {/*<div style={{*/}
                        {/*  borderRadius: 20,*/}
                        {/*  background: tagBackgroundColor,*/}
                        {/*  color: 'white',*/}
                        {/*  fontSize: '0.7rem',*/}
                        {/*  textAlign: 'center',*/}
                        {/*  fontWeight: "bold",*/}
                        {/*  marginTop: 2,*/}
                        {/*  paddingBottom: 2*/}
                        {/*}}>*/}
                        {/*  {stage.levelText}*/}
                        {/*</div>*/}
                      </Grid>
                    </Grid>
                  </Box>
                )  
              })}
              
              <Box mt={3} mb={3}>
                <Button 
                  className={classes.cancelButton}
                  onClick={() => {
                    props.close()
                  }}
                >
                  취소
                </Button>
              </Box>
            </div>
          </DialogContent>
      </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  beginnerButton: {
    textAlign: "left",
    margin: theme.spacing(1),
    width: '100%',
    fontSize:'1rem',
    border: 0,
    borderRadius: 3,
    color: '#000',
    padding: '10px 10px',
    // '&:hover': {
    //   backgroundColor: '#228B22', // Darker green
    // },
  },
  intermediateButton: {
    margin: theme.spacing(1),
    width: '100%',
    fontSize:'1.3rem',
    backgroundColor: '#FF8C00', // Orange
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 140, 0, .3)', // Orange
    color: 'white',
    height: 48,
    padding: '0 30px',
    '&:hover': {
      backgroundColor: '#FF7F00', // Darker orange
    },
  },
  advancedButton: {
    margin: theme.spacing(1),
    width: '100%',
    fontSize:'1.3rem',
    backgroundColor: '#FF4500', // Red
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 69, 0, .3)', // Red
    color: 'white',
    height: 48,
    padding: '0 30px',
    '&:hover': {
      backgroundColor: '#FF0000', // Darker red
    },
  },
  cancelButton: {
    margin: theme.spacing(1),
    width: '100%',
    fontSize:'1.3rem',
    backgroundColor: '#808080', // Grey
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(128, 128, 128, .3)', // Grey
    color: 'white',
    height: 48,
    padding: '0 30px',
    '&:hover': {
      backgroundColor: '#696969', // Darker grey
    },
  },
}));


export default ChoiceLevel