export default class CommonHelper {
    static wake
    
    /**
     * Async array map wrapper
     * @param arr
     * @param asyncFunc
     */
    static async asyncMap(arr, asyncFunc) {
        return Promise.all(arr.map(asyncFunc));
    }
    
    static clone(val) {
        return JSON.parse(JSON.stringify(val));
    }
    
    static async wakeLock() {
        this.releaseWakeLock()
        try {
            this.wake = await window.navigator['wakeLock'].request('screen')    
        } catch {}
    } 
    
    static releaseWakeLock() {
        if(this.wake){
            this.wake.release()
            console.log('release lock')
        }
    }
}