import React from 'react';
import { Grid, Select } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import ComputeCode from "../common/ComputeCode"

import AuthProvider from '@user-provider/AuthProvider'
import MeasureHelper from '@helper/MeasureHelper'
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
const { getMeasureButtons, measureTypeToCode, getCodeNameSet } = MeasureHelper

 class CodeButtonGroup extends React.Component {
    constructor(props) {
        super(props)
        
        const measureButton = getMeasureButtons(props.hzType)
        const measureType = props.defaultMeasureType ? props.defaultMeasureType : 'rest'
        const hzType = props.hzType ? props.hzType : 'default'
        const measureCode =  props.defaultCode ? props.defaultCode : 'A'
        
        const codeNames = getCodeNameSet(hzType, measureTypeToCode(measureType))
        
        this.state = {
            code: 'higher',
            sound: 0,
            hzType,
            measureType,
            measureCode ,
            dialogOpen: false,
            useDialogOpen: null != props.useDialogOpen ? props.useDialogOpen : true,
            onMeasureSetting: props.onMeasureSetting,
            measureButtons : measureButton,
            codeNames,
            disabled: null !== props.disabled ? props.disabled : false
        }
    }
    
    setMeasureSetting(measureType, measureCode){
        const codeNames = getCodeNameSet(this.state.hzType, measureTypeToCode(measureType))
        
        this.setState({
            ...this.state,
            measureCode,
            measureType,
            codeNames
        });
    }

     setMeasureCode(measureCode) {
         if(this.state.onMeasureSetting){
             this.state.onMeasureSetting(this.state.measureType, measureCode)
         }

         this.setState({
             ...this.state,
             measureCode
         });
     }

     setMeasureType(measureType){
        const codeNames = getCodeNameSet(this.state.hzType, measureTypeToCode(measureType))
        let currentMeasureCode = this.state.measureCode
        
        const filtered = codeNames.filter( ({value}) => value === currentMeasureCode)
        if(0 === filtered.length){
            try {
                currentMeasureCode = codeNames[0].value    
            } catch(e) {}
        }
        
        if(this.state.onMeasureSetting){
            this.state.onMeasureSetting(measureType, currentMeasureCode)
        }
        
        this.setState({
            ...this.state,
            measureType,
            measureCode: currentMeasureCode,
            codeNames
        });
    }

    
    getCode() {
        return this.state.code
    }
    
    render() {
        if(!AuthProvider.isAdmin()){
            return <></>
        }
        
        const { classes } = this.props
        const { codeNames } = this.state
        return (
            <Grid container>
                <Grid item>
                    {0 < this.state.measureButtons.length &&
                    <Select
                        defaultValue={this.state.measureType}
                        value={this.state.measureType}
                        className={clsx(classes.select,{
                            [classes.disabled]: this.state.disabled
                        })}
                        disabled={this.state.disabled}
                        onChange={(event) => {
                            this.setMeasureType(event.target.value)
                        }}>
                        {this.state.measureButtons.map((button, index) => {
                            return (
                                <MenuItem
                                    key={button.value}
                                    value={button.value}
                                >
                                    {button.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                    }
                </Grid>

                {0 < codeNames.length &&
                <Grid item>
                    <Select
                        className={classes.select}
                        defaultValue={this.state.measureCode}
                        value={this.state.measureCode}
                        onChange={(event) => {
                            this.setMeasureCode(event.target.value)
                        }}>
                        {codeNames.map((code) => {
                            return (
                                <MenuItem
                                    key={code.value}
                                    value={code.value}
                                >
                                    {code.value}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </Grid>
                }
            </Grid>
        )
    }
}

const useStyles = () => ({
    select: {
        color: '#FFC004', 
        fontSize: 24,
        fontWeight: 'bold',
        
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
        '& .MuiSelect-icon': {
            color: '#FFC004',
        }
    },
    disabled: {
        color: '#FFC004 !important',
        '& .MuiSelect-root': { paddingRight: 0},
        '& .MuiSelect-icon': {
            display: 'none'
        }
    }
});
export default withStyles(useStyles)(CodeButtonGroup)