import 'date-fns';
import React from 'react';
import {Box} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";

export default function FirstMeasureMessage() {
    const classes = useStyles();

    return (
        <Box className={'sec-presc'}>
            <Box className={clsx('innerbox', classes.empty)}>
                <img src={require('@images/brainLightIcon.png')} alt={'icon'}/>
                <h4>뇌기능 측정이 처음이시군요! </h4>
                <div>담당자에게 문의하시고 측정을 요청하세요</div>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(() => ({
    empty: {
        border:0,
        textAlign: 'center',
        width: '1280px',
        color: '#999',
        fontFamily: `'Lato', 'Noto Sans KR', sans-serif`,
        '& h4': {
            fontSize: '28px !important',
            margin:0,
            height: '90px',
            color: '#999'
        },
        '& img': {
            width : '80px',
            marginTop: '50px'
        }
    }
}));