import Axios from 'axios';
// import { catchClause } from '@babel/types';


export default class MessageService {
    
      /**
     * 메세지를 포스팅합니다.
     * @returns {Promise<void>}
     */
    static async post(post) {
        try {
            const result = await Axios.post('/api/message',post)
            
            return {
                success:true,
                errorCode:200,
                data: result.data,
                message: ''
            }

        }catch(e){

            var message = ''
            switch(e.response.status){
                case 400:
                     message = '업로드 파라미터가 잘못되었습니다.'
                     break
                case 403:
                    message = "프로필 정보가 잘못되었습니다."
                    break;
                default:
                    message = '오류가 발생했습니다.'
            }

            return {
                success: false,
                errorCode: e.response.status,
                message: message
            }
        }
    }


    /**
     * 댓글을 답니다
     * @param {*} post 
     */
    static async reply(reply){
        try {
            const result = await Axios.post('/api/message/' + reply.parentMessageId + '/answer',{
                profileID: reply.profileID,
                message: reply.message
            })
            
            return {
                success:true,
                errorCode:200,
                data: result.data,
                message: ''
            }

        }catch(e){

            var message = ''
            switch(e.response.status){
                case 400:
                     message = '업로드 파라미터가 잘못되었습니다.'
                     break
                case 403:
                    message = "프로필 정보가 잘못되었습니다."
                    break;
                default:
                    message = '오류가 발생했습니다.'
            }

            return {
                success: false,
                errorCode: e.response.status,
                message: message
            }
        }
    }

    /**
     * 메세지를 가져옵니다.
     * @param {} profileID
     * @param prescriptionID
     */
    static async getMessages(profileID, prescriptionID = null){
        try {
            const result = await Axios.get('/api/messages',{
                params: {
                    page:1,
                    perPage: 50,
                    profileID,
                    prescriptionID
                }
            })
            
            return {
                success:true,
                errorCode:200,
                data: result.data,
                message: ''
            }

        }catch(e){

            let message = ''
            switch(e.response.status){
                case 400:
                     message = '파라미터가 잘못되었습니다.'
                     break
                case 403:
                    message = "프로필 정보가 잘못되었습니다."
                    break;
                default:
                    message = '오류가 발생했습니다.'
            }

            return {
                success: false,
                errorCode: e.response.status,
                message: message
            }
        } 
    }


    /**
     * 답글을 가져옵니다.
     * @param {*} parentMessageId 
     */
    static async getReplies(parentMessageID, callback){
        try {
            const result = await Axios.get('/api/message/' + parentMessageID + '/answers',{
                params: {
                    page:1,
                    perPage: 50
                }
            })
            
            callback({
                success:true,
                errorCode:200,
                data: result.data,
                message: ''
            })

        }catch(e){

            var message = ''
            switch(e.response.status){
                case 400:
                     message = '파라미터가 잘못되었습니다.'
                     break
                case 403:
                    message = "프로필 정보가 잘못되었습니다."
                    break;
                default:
                    message = '오류가 발생했습니다.'
            }

            callback({
                success: false,
                errorCode: e.response.status,
                message: message
            })
        } 
    }


    /**
     * 메세지를 삭제합니다.
     * @param {*} profileID 
     */
    static async deleteMessage(messageID){
        try {
            await Axios.delete('/api/message/' + messageID)

            return {
                success:true,
                errorCode:200
            }

        }catch(e){

            var message = ''
            switch(e.response.status){
                case 400:
                     message = '파라미터가 잘못되었습니다.'
                     break
                case 403:
                    message = "프로필 정보가 잘못되었습니다."
                    break;
                default:
                    message = '오류가 발생했습니다.'
            }

            return {
                success: false,
                errorCode: e.response.status,
                message: message
            }
        } 
    }
}
