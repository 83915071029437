import Axios from 'axios';

export default class DeviceService {

    static device 

    /**
     * 디바이스 접속 상태를 체크합니다. 
     */
    static async loginDevice(deviceInfo) {

        try {
            const result = await Axios.post('/api/login/device', deviceInfo)
            return {
                success: true,
                data: result.data
            }
        } catch {}

        return {
            success: false,
            message: '로그인에 실패했습니다.'
        }
    }

    static async connected(info){
        try{
            const result = await Axios.get('/api/devices/connected',{
                params: info
            })
            this.device = result.data

            return {
                success: true,
                data: result.data
            }

        }catch{}

        return {
            success: false,
            data: null,
            message: '접속된 기기 정보가 없습니다.'
        }
    }
    
    static async getDeviceID(){
        return this.device.deviceID
    }

    /**
     * return devices
     * @returns {Promise<*[]|any>}
     */
    static async getDevices(){
        try{
            const res = await Axios.get('/api/device/devices')
            return res.data

        }catch(e){
            alert(e)
        }

        return []
    }

    /**
     * delete 
     * @param id
     * @returns {Promise<void>}
     */
    static async delete(id){
        try{
            await Axios.delete('/api/device/' + id)
        }catch(e){}

        return null
    }
    static async getServerVersion(){
        
        let result = {
            success : false,
            version : "0.0.0.0"
        }
        try{
            const res = await Axios.get('/api/device/version')
            result = {
                success : true,
                version : res.data.version
            }

        }catch(e){
            alert(e)
        }
        
        return result
    }
    
    static async updateComputerInfo(deviceID, computerInfo){
        try{
            await Axios.put(`/api/device/${deviceID}/computer`,{
                computerInfo
            })
        }catch(e){
        }
    }

}
