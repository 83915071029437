import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Lottie from 'react-lottie';

import animation1 from './clear1.json';

const ClearFirstStepDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [animation, setAnimation] = useState(null);

  useEffect(() => {
    setOpen(props.open);

    // 랜덤한 애니메이션 선택
    const animations = [animation1];
    const randomAnimation = animations[Math.floor(Math.random() * animations.length)];
    setAnimation(randomAnimation);
  }, [props.open]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Dialog
      disableBackdropClick
      open={open}
      style={{
        zIndex: 999,
      }}>
      <DialogTitle style={{
        textAlign: "center",
      }}>축하합니다</DialogTitle>

      <DialogContent style={{
        display: "flex",
        justifyContent: "center",
      }}>
        <Lottie
          options={defaultOptions}
          height={400} width={400} />
      </DialogContent>

      <DialogActions style={{
        display: "flex",
        justifyContent: "center",
        paddingBottom: 10
      }}>
        <Button 
          variant="contained" 
          color="primary"
          size={'large'}
          fullWidth
          style={{
            fontSize: '1.5rem'
          }}
          onClick={() => {
            try {
              props.startExercise()
            } catch(e){ console.log(e)} 
          }}
        >
          훈련 시작하기
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ClearFirstStepDialog;
