import React from "react";
import {Box, Grid, Typography} from "@material-ui/core";
import ReactAudioPlayer from "react-audio-player";
import {ViewChar} from "../common/Data";
import _ from "lodash";
import ColorHelper from "../../../helper/ColorHelper";

export class SoundViewBox extends React.Component {
  constructor(props) {
    super();


    this.state = {
      width: props.width,
      height: props.height,
      widthOffset: props.widthOffset,
      stageInfo: props.stageInfo,
      randomProblem: props.randomProblem,
      renderView: props.renderView,
      blocks: props.blocks,
      programView: props.programView,
      checkCorrect: props.checkCorrect,
      activeIndex: -1,
      firstStageStart: props.firstStageStart
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    // if(this.state !== nextProps.width){
    //   this.setState({
    //     ...this.state,
    //     width: nextProps.width,
    //     height: nextProps.height
    //   })
    // }
    if(!this.state.firstStageStart && nextProps.firstStageStart){
      this.setState({
        ...this.state,
        firstStageStart: true
      })

      setTimeout(() => {
        this.setState({
          ...this.state,
          activeIndex: 0,
        })
      }, 1000)
    }
    return true

  }

  componentDidMount() {
  }

  render() {
    const { widthOffset, stageInfo, randomProblem, renderView, blocks, isDoneShowDelayProblem} = this.state
    const { width, height, programView, activeIndex} = this.state

    //집중력 프로그램은 이미지가 가로만 넓기 때문에 Top 처리 
    const marginTop = 'view9' === programView ? 50 : 0
    let text = stageInfo.text;
    // 1개 정답일 경우 
    if(stageInfo.all === 1 && stageInfo.textForOne){
      text = stageInfo.textForOne;
    }
    if(isDoneShowDelayProblem && stageInfo.afterText){
      text = stageInfo.afterText
      
      if(stageInfo.afterTextOne) {
        text = stageInfo.afterTextOne
      }
    }
    
    //박스가 커질 사이즈 
    const extendsOffset = 50
    return (
      <>
        {stageInfo.background &&
          <Box position={'absolute'}
               top={-100}
               left={0}
               width={'100%'}
               height={'62%'}
               style={{
                 opacity: isDoneShowDelayProblem ? 0 : 1,
                 transitionDuration: '0.5s',
                 backgroundImage: `url(${stageInfo.background})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundPosition: '0% 0%',
                 backgroundSize: '100% 100%'
               }}
          >
          </Box>
        }
        <Box position={'absolute'}
             top={-200}
             left={0}
             width={'100%'}
             textAlign={'center'}
             fontSize={'2.2rem'}
             fontWeight={'bold'}
        >
          {text}
        </Box>

        {-1 < this.state.activeIndex &&
          randomProblem[activeIndex] &&
          randomProblem[activeIndex].meta.sound &&
          <Box style={{
            position: 'absolute',
            left: '50%',
            top: '-100px'
          }}>
            <ReactAudioPlayer
              key={Math.random()}
              autoPlay={true}
              src={randomProblem[activeIndex].meta.sound}
              // controls
            />
          </Box>
        }

        <Grid container spacing={6} justify={"center"}
              style={{
                marginTop: -widthOffset,
                maxWidth: stageInfo.all / 2 * (this.state.width + widthOffset),
              }}
        >
          {blocks.map((pos, index) => {
            let grayscale = '0%';
            const problem = randomProblem[index]

            let boxItemStyle = {
              position: 'relative',
              width: this.state.activeIndex === index ? width + extendsOffset : width ,
              height: this.state.activeIndex === index ? height + extendsOffset : height ,
              boxShadow: this.state.activeIndex === index ? '0px 8px 8px rgba(0, 0, 0, 0.1)' : 'none',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              cursor: "pointer",
              transitionDuration: '0.3s',
              borderRadius: 20,
            }

            if(ViewChar[programView].cogItemBoxStyle){
              const style = ViewChar[programView].cogItemBoxStyle
              boxItemStyle = _.merge(boxItemStyle, ViewChar[programView].cogItemBoxStyle)

              if(style.border && problem.meta.color) {
                boxItemStyle.borderColor = ColorHelper.updateRGBAlpha(problem.meta.color, 0.3)
              }
            }

            if(stageInfo.background) {
              boxItemStyle.backgroundColor = 'transparent'
            }


            return (
              <Grid item key={index} >
                <Box style={boxItemStyle}
                     onClick={() => {

                       if(index !== this.state.activeIndex) return

                       this.setState({
                         ...this.state,
                         activeIndex: this.state.activeIndex + 1
                       })

                       if(this.state.checkCorrect) {
                         this.state.checkCorrect(problem)
                       }
                     }}
                >
                  {'text' === renderView.type &&
                    <Typography
                      variant={"h2"}
                      style={{
                        transitionDuration: '0.5s',
                        textAlign: 'center',
                        lineHeight: `${height - 20 - marginTop}px`,
                        fontWeight: 'bold'
                      }}
                    >
                      {problem.renderViewChar}
                    </Typography>
                  }

                  {'image' === renderView.type &&
                    <>
                      {problem.isSelected &&
                        <Box position={'absolute'} top={'50%'} left={'50%'} zIndex={100}
                             style={{transform: 'translate(-50%, -50%)'}}>
                          {problem.isCorrect &&
                            <svg fill={'#3BB438'}
                                 strokeWidth={1}
                                 stroke={'white'}
                                 xmlns="http://www.w3.org/2000/svg"
                                 width={'100%'}
                                 height={'90%'}
                                 viewBox="0 0 24 24">
                              <path
                                d="M0 11.386l1.17-1.206c1.951.522 5.313 1.731 8.33 3.597 3.175-4.177 9.582-9.398 13.456-11.777l1.044 1.073-14 18.927-10-10.614z"/>
                            </svg>
                          }
                          {!problem.isCorrect &&
                            <svg
                              fill={'#E94825'}
                              strokeWidth={1}
                              stroke={'white'}
                              xmlns="http://www.w3.org/2000/svg"
                              width={'100%'}
                              height={'90%'}
                              viewBox="0 0 24 24">
                              <path
                                d="M24 3.752l-4.423-3.752-7.771 9.039-7.647-9.008-4.159 4.278c2.285 2.885 5.284 5.903 8.362 8.708l-8.165 9.447 1.343 1.487c1.978-1.335 5.981-4.373 10.205-7.958 4.304 3.67 8.306 6.663 10.229 8.006l1.449-1.278-8.254-9.724c3.287-2.973 6.584-6.354 8.831-9.245z"/>
                            </svg>
                          }
                        </Box>
                      }
                      <img style={{
                        width: '100%',
                        // height: '100%',
                        maxHeight: '100%',
                        marginTop,
                        filter: `grayscale(${grayscale})`,
                        opacity: isDoneShowDelayProblem ? 1 : problem.opacity,
                        transitionDuration: '0.5s',
                        paddingTop: !!ViewChar[programView].paddingTop ? ViewChar[programView].paddingTop : '0',
                      }}
                           src={problem.renderViewChar}
                           alt={problem.randomIndex}
                      />

                      {'image' === stageInfo.showSub &&
                        <img style={{
                          width: '50%',
                          height: '50%',
                          position: 'absolute',
                          right:0,
                          bottom: 0,
                          filter: `grayscale(${grayscale})`,
                          paddingTop: !!ViewChar[programView].paddingTop ? ViewChar[programView].paddingTop : '0',
                        }}
                             src={problem.subView}
                             alt={problem.randomIndex}/>
                      }

                      {'number' === stageInfo.showSub &&
                        <div style={{
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          left:0,
                          top:0,
                          textAlign: 'center',
                          lineHeight: `${this.state.height}px`,
                          fontWeight: 'bold',
                          fontSize: '3.2rem',
                          paddingTop: !!ViewChar[programView].paddingTop ? ViewChar[programView].paddingTop : '0',
                        }} className="cal-text">
                          {problem.meta.number}
                        </div>
                      }
                    </>
                  }
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </>
    )
  }
}
