import React, {useCallback, useEffect, useState} from 'react';
import {Box,  Typography} from "@material-ui/core";
import {Line} from "react-chartjs-2";
import DateHelper from "../../../helper/DateHelper";
import MeasureService from "../../../services/MeasureService";

const MeasureTestLineChart = (props) => {
  const [bands, setBands] = useState([{
    id: 1,
    values: [0,0,0,0,0,0]
  }])
    
  const fetch = useCallback(async (filterTypes) => {
    
    const months = DateHelper.getMonthsWithLabel(6)
    const start = months[0].dateMonth
    const end = months[months.length - 1].dateMonth

    const logs = await MeasureService.getMeasureLogChart(start, end, filterTypes.map(p => p.programID))
    setBands([{
      id: 1,
      values: logs.map(log => log.score)
    }])
    
  }, [])

  useEffect(() => {
    if(1 === props.currentFilter.length){
      fetch(props.currentFilter).then()  
    }
  }, [fetch, props.currentFilter])

  
  const showChart = props.currentFilter.length === 1

  return (
    <Box pl={3} pr={3}>
      <Box style={{padding:10}}>
        {showChart &&
          <Typography style={{fontSize: '0.9rem'}}>최근 6개월
            <b style={{fontSize:'1.1rem', paddingLeft: 10}}>{props.currentFilter[0].name}</b> 그래프
          </Typography>
        }
      </Box>
      <MeasureLineChart 
          bands={bands}
          showChart={showChart}
      />
    </Box>  
  )
};

export function MeasureLineChart(props) {
  let bands = props.bands

  const [showChart, setShowCart] = useState(props.showChart)
  const labels = DateHelper.getMonthsWithLabel(6).map(date => date.label)
    
  const data = {
    labels: labels,
    datasets: [
      {
        label: `${bands[0].id}회차`,
        data: bands[0].values,
        fill: true,
        backgroundColor: "rgba(0,178,245,0.2)",
        borderColor: "rgba(0,178,245,0.5)",
        pointBorderColor: "rgba(0,178,245,1)",
        pointBackgroundColor: 'white',
        pointBorderWidth: 2,
        pointRadius: 5,
      }
    ]
  }

  const options = {
    legend: {
      display: false,
      labels: {
        // fontColor: "white",
        // fontSize: 18
      }
    },
    layout: {
      padding: 5
    },
    scales: {
      xAxes: [{
        display: true,
        gridLines: {
          display: true,
          // color: "#4476C4",
        },
        scaleLabel: {
          display: false,
          labelString: 'Month'
        },
        ticks: {
          // fontColor: "white",
          // fontSize: 18
        },
      }],
      yAxes: [{
        display: true,
        gridLines: {
          display: true,
          // color: "#4476C4"
        },
        scaleLabel: {
          display: false,
          labelString: 'Value'
        },
        ticks: {
          // fontColor: "white",
          suggestedMin: 0,
          suggestedMax: 100,
          beginAtZero: true,
          // fontSize: 18
        },
      }]
    }
  }

  useEffect(() => {
    setShowCart(props.showChart)
  }, [props.showChart])
  
  return (
    <Box position={'relative'} style={{marginBottom: 20}}>
      <Box 
          position={'absolute'} 
          top={0} 
          left={0} 
          width={'100%'} 
          height={'100%'} 
          style={{
            backgroundColor:'#efefef', 
            border:"2px solid #efefef",
            borderRadius: 10,
            opacity: !showChart ? 1 : 0,
            transitionDuration: 0.3
          }}>
          <div style={{
            textAlign: 'center',
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: '100%'
          }}>
            <div>
              <img src={require(`@images/lineIcon.png`)} style={{height: 80}} alt={'line icon'}/>
            </div>
            프로그램을 선택하면 지난 결과 그래프를 확인 할 수 있습니다
          </div>
      </Box>
      <Line data={data} options={options} height={50}/>
    </Box>
  )
}

export default MeasureTestLineChart