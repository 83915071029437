import React, {useEffect, useRef, createRef} from 'react';
import FFTChartMerge from '@user-components/FFTChartMerge';

import AudioPlayer from '@user-pages/exercise/common/AudioPlayer'

import {Grid, Box} from '@material-ui/core';
import SocketProvider from '@user-provider/SocketProvider';
import ColorHelper from '@helper/ColorHelper';

import MeasureHelper from '@helper/MeasureHelper'
import ExerciseStatusBar from "./ExerciseStatusBar";
import ActiveProgress from "./common/ActiveProgress";


const { measureTypeToCode } = MeasureHelper
const MeasureHzType = 'measureBand' 

const MeasureBand = () => {
    const audioRef = useRef()
    const measureRef = useRef()
    // const [data, setData] = useState('')
        
    useEffect(()=>{
        SocketProvider.connect(()=>{}, window, MeasureHzType)
        SocketProvider.onMeasure = (data) => {
            measureRef.current.update(data)
            if(data.isHit){
                audioRef.current.play()
            }
        }

        // if('android' === BridgeProvider.deviceType) {
        //     SocketProvider.onDeviceMeasureDataConsole = (data) => {
        //         let stringData = data + '\n\n'
        //         try {
        //             stringData += JSON.stringify(NetworkHelper.getDecParam(data))
        //         } catch (e) {
        //             stringData += e.toString()
        //         }
        //
        //         setData(stringData)
        //     }
        // }
        
        return () => {
            measureRef.current.clear()
            SocketProvider.measureStop(true).then()
        }
    },[])

    const startExercise = async (runningTime, measureType, measureCode) => {
        audioRef.current.setMode(measureType);
        measureRef.current.clear()
        await SocketProvider.measureStart(runningTime, 0, MeasureHzType, measureType, measureTypeToCode(measureType), measureCode) 
    }
    
    const stopExercise = () => {
        SocketProvider.measureStop().then()
    }
    
    return (
        <Box style={{position: 'relative'}}>
            {/*{'android' === BridgeProvider.deviceType &&*/}
            {/*<Box position={'absolute'} zIndex={1000} width={600} left={10} top={100}*/}
            {/*     style={{height: 500, overflowY: 'auto', backgroundColor: '#efefef'}}>*/}
            {/*    {data}*/}
            {/*</Box>*/}
            {/*}*/}
            
            <ExerciseStatusBar
                measureHzType={MeasureHzType}
                measureType={'rest'}
                measureCode={'A'}
                close={async () => {
                    await stopExercise()
                }}
                start={async (runningTime, measureType, measureCode) => {
                    await startExercise(runningTime, measureType, measureCode)
                }}
            />
            <Box className={'innerViewport'}>
                <RealtimeMeasure ref={measureRef}/>
            </Box>
            <AudioPlayer ref={ audioRef } />
        </Box>
    );
}


// 실시간 측정 페이지
export class RealtimeMeasure extends React.Component {
    fftChartOptions = {
        graphWireFrame: false,
        graphWireFrameColor: 0x666666,
        hzSpacing: 10,
        hzCount: 60,   // 10 spacing
        cone: false,
        gridSize: 0.7,
        mvSize: 0.3,
        timeHzGrid: true,
        timeMvGrid: true,
        mvHzGrid: true,
        mvGuideLineXY: false,
        timeGuide: true,
        graphColors: ColorHelper.getColors(),
        height: 900,
        brainType:'both',
        cameraType: 'side',
        noiseCode: 'A'
    };

    fftChartCh1Ref
    fftChartCh2Ref
    actionBoxRef
    
    constructor(props) {
        super(props)

        this.state = {
            runningTime: props.runningTime
        }

        this.fftChartCh1Ref = createRef(null)
        this.fftChartCh2Ref = createRef(null)
        this.actionBoxRef = createRef()

    }


    update(data) {
        //기기에서 측정된 데이터를 받을 경우
        this.fftChartCh1Ref.current.addData(data.time, data.ch1FFT, data.ch2FFT)
        this.actionBoxRef.current.update(data.isHit)
    }

    clear() {
        this.fftChartCh1Ref.current.clearCanvas()
        this.actionBoxRef.current.reset()
    }

    updateChart(){
        this.setState({
            ...this.state,
            measureCount: this.state.measureCount + 1
        })
    }

    componentDidMount() {
        this.fftChartCh1Ref.current.initRender()
    }
    
    render() {
        return (
            <Box>
                <Box padding={2} >
                    <Grid container style={{width: '100%', position:'relative'}}>
                        <Grid item xs={12} style={{position: 'relative'}} >
                            <FFTChartMerge label={'Brain'} options={ this.fftChartOptions } ref={ this.fftChartCh1Ref }/>
                        </Grid>
                    </Grid>
                </Box>
                <ActiveProgress ref={this.actionBoxRef} />    
            </Box>
        )
    }
}


export default MeasureBand
