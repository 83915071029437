import React, {createRef} from 'react';
import {Box, Button, Typography, Grid} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ExerciseService from "../../../services/ExerciseService";
import clsx from "clsx";
import TestService from "../../../services/TestService";
import { useHistory } from "react-router-dom";
import PrintIcon from "@material-ui/icons/Print";
import DateHelper from "../../../helper/DateHelper";
import html2canvas from "html2canvas";
import PrintHelper from "../../../helper/PrintHelper";

const svg = {
    check: 'M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z',
}
const SVG = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={props.width ? props.width : 'auto'}
            height={props.height ? props.height : 'auto'}
            viewBox="0 0 24 24"
            fill={props.color}
        >
            <path
                d={svg[props.name]}
            />
        </svg>
    );
}

export default class CompleteDialog extends React.Component {

    printRef = createRef()
  
    scoreTable = [
        {startAge:30, endAge: 30, startScore:10, endScore: 10},
        {startAge:40, endAge: 40, startScore:9, endScore: 9},
        {startAge:50, endAge: 50, startScore:8, endScore: 8},
        {startAge:60, endAge: 60, startScore:7, endScore: 7},
        {startAge:70, endAge: 70, startScore:6, endScore: 6},
        {startAge:80, endAge: 80, startScore:5, endScore: 5},
        
    ]
    constructor(props) {
        super();
        this.state = {
            open: false,
            viewMode: props.viewMode,
            history: props.history,
            onReset: props.onReset,
            exerciseName: props.exerciseName,
            gameResults: {
                memoryImages: [],
                memoryTexts: [],
                answerResults: []
            },
            score: {
                time: '00분 00초',
                correctCount: 0,
                count: 0
            }, 
            measureType: props.measureType,
            backgroundColor: TestService.getColorName(props.measureType, props.exerciseID)
        }
        
        this.scoreTable.reverse()
    }
    
    open(score, gameResults) {
        const correctCount = score && score.correctCount ? score.correctCount : 0
      
        this.setState({
            ...this.state,
            open: true,
            gameResults: gameResults,
            score : {
                time: score && score.time ? score.time : '00분 00초',
                correctCount: correctCount,
                count: score && score.count ? score.count : 0,
                isPass: ExerciseService.getExerciseResultByScoreTable(this.scoreTable, correctCount * 10)
            }
        })
    }
    
    close() {
       try{
           const returnUrl = 'train' === this.state.measureType ? '/measureTrain' : '/measureTest'
           this.state.history.push(returnUrl)
       } catch(e) {}
    }

    reset(){
        this.setState({
            ...this.state,
            open: false,
        }, () => {
            if(this.state.onReset){
                this.state.onReset()
            }    
        })
        
    }
    
    render() {
        const { score, gameResults, viewMode, exerciseName } = this.state
       
        const colSize = gameResults.memoryImages.length > 0 ? 6 : 12
        const dialogSize = gameResults.memoryImages.length > 0 ? 'lg' : 'sm'
        const boxSize =  15 > gameResults.memoryImages.length ? 100 : 80

        let isPass = score.isPass ? '성공' : '아니오'
        if(['develop','train'].includes(this.state.measureType)){
            isPass = score.correctCount === score.count ? '성공' : '아니오'
        }
        
        return (
            <Dialog
                disableBackdropClick
                open={this.state.open}
                aria-labelledby="responsive-dialog-title"
                fullWidth
                maxWidth={dialogSize}
                className={clsx(['level-dialog', this.state.measureType, this.state.backgroundColor])}
            >
                <div ref={this.printRef}>
                    <DialogTitle id="responsive-dialog-title"> 
                      <Box position={'absolute'} style={{left: 40, top: 20, display: 'none'}} className={'print-show'}>
                        <img src={require(`@assets/images/bh_logo.png`)} alt={'logo'} style={{height: 44}}/>
                      </Box>
                      <div className={'title-left'}>
                        {exerciseName} {['develop','train'].includes(this.state.measureType)  ? '증강 프로그램' : '검사'}
                      </div>
                      <Box position={'absolute'} style={{right: 10, top: 20}} className={'print-hide'}>
                        {/*<Button*/}
                        {/*  className={'btn-print'}*/}
                        {/*  startIcon={<PrintIcon/>}*/}
                        {/*  style={{padding: '7.5px 21px', marginRight: 10}}*/}
                        {/*  variant={'outlined'} size={'large'}*/}
                        {/*  onClick={() => {*/}
                        {/*    PrintHelper.print(this.printRef)*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  인쇄*/}
                        {/*</Button>*/}
                      </Box>
                      <Box position={'absolute'}
                           style={{right: 40, top: 30, color: '#000', fontWeight: "normal", fontSize: '1rem', display: 'none'}}
                           className={'print-show'}>
                        출력일: {DateHelper.now('YYYY년 MM월 DD일')}
                      </Box>
                    </DialogTitle>
                    <DialogContent ref={this.printRef}>
                        <Grid container spacing={3} style={{width: '100%'}}>
                            <Grid item xs={12} md={colSize} >
                                <Box marginTop={0}>
                                    <Typography className={'title'}>{['develop','train'].includes(this.state.measureType)  ? '두뇌계발' : '검사'} 결과</Typography>
                                    <Grid container spacing={2} className={'result-wrap'}>
                                        <Grid item xs={4}>
                                            <Paper elevation={0} className={'result-box'}>
                                                <Typography className={'text-blue'}>{['develop','train'].includes(this.state.measureType)  ? '측정' : '검사'}시간</Typography>
                                                <Typography className={'score'}>{score.time}</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Paper elevation={0} className={'result-box'}>
                                                <Typography className={'text-blue'}>인지개수</Typography>
                                                <Typography className={'score'}>{score.correctCount} / {score.count}개</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Paper elevation={0} className={'result-box'}>
                                                <Typography className={'text-blue'}>판별점수</Typography>
                                                <Typography className={'score'}>{score.correctCount * 10}점</Typography>
                                            </Paper>
                                        </Grid>
                                        {/*<Grid item xs={3}>*/}
                                        {/*    <Paper elevation={0} className={'result-box'}>*/}
                                        {/*        <Typography>평가결과</Typography>*/}
                                        {/*        <Typography className={'score'}>{isPass}</Typography>*/}
                                        {/*    </Paper>*/}
                                        {/*</Grid>*/}
                                    </Grid>
                                </Box>
                                 <Box mt={3} mb={3} className={'print-hide'}>
                                    <Button fullWidth
                                            variant={"contained"}
                                            size={'large'}
                                            color={'primary'}
                                            style={{fontSize:'1.1rem'}}
                                            onClick={this.reset.bind(this)}>
    
                                        {['develop','train'].includes(this.state.measureType)  ? '다시하기' : '재검사하기'}
                                    </Button>
                                    <Box mt={1}/>
                                    <Button fullWidth
                                            variant={"outlined"}
                                            size={'large'}
                                            style={{fontSize:'1.1rem'}}
                                            onClick={this.close.bind(this)}>
                                        종료하기
                                    </Button>
                                </Box>
                                <Box mt={2}>
                                    <Typography className={'title'} style={{fontWeight: "bold"}}>평가기준</Typography>
                                </Box>
                                <Box mt={2}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="table" size={'small'} className={'level-table'}>
                                            <TableHead>
                                                <TableRow className={''}>
                                                    <TableCell>연령 기준</TableCell>
                                                    <TableCell>인지 개수</TableCell>
                                                    <TableCell>판별 점수</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {10 === score.count &&
                                                <>
                                                {this.scoreTable.map((table, index) => {
                                                    const {startAge, startScore} = table
                                                    let score = startScore * 10;
                                                    score = score > 100 ? 100 : score
                                                    return (
                                                        <TableRow key={`result-${index}`}>
                                                            <TableCell scope="row">{startAge}대 {0 === index  ? '이상' : ''}</TableCell>
                                                            <TableCell>{startScore}개 이상</TableCell>
                                                            <TableCell>{score}점 이상</TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                                </>
                                              }
                                              {15 === score.count &&
                                                <>
                                                <TableRow>
                                                  <TableCell scope="row">80대 이상</TableCell>
                                                  <TableCell>6 ~ 7개 이상</TableCell>
                                                  <TableCell>60 ~ 70점 </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell scope="row">70대 이상</TableCell>
                                                  <TableCell>7 ~ 8개 이상</TableCell>
                                                  <TableCell>70 ~ 80점 </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell scope="row">60대 이상</TableCell>
                                                  <TableCell>8 ~ 9개 이상</TableCell>
                                                  <TableCell>80 ~ 90점 </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell scope="row">50대 이상</TableCell>
                                                  <TableCell>9 ~ 10개 이상</TableCell>
                                                  <TableCell>90 ~ 100점 </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell scope="row">40대 이상</TableCell>
                                                  <TableCell>10 ~ 11개 이상</TableCell>
                                                  <TableCell>100 ~ 110점 </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell scope="row">30대 이상</TableCell>
                                                  <TableCell>11 ~ 12개 이상</TableCell>
                                                  <TableCell>110 ~ 120점 </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell scope="row">20대 이상</TableCell>
                                                  <TableCell>13 ~ 15개</TableCell>
                                                  <TableCell>130 ~ 150점 </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell scope="row">20대 이하</TableCell>
                                                  <TableCell>14 ~ 15개</TableCell>
                                                  <TableCell>140 ~ 150점 </TableCell>
                                                </TableRow>
                                                </>
                                              }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Grid>
                            {0 < gameResults.memoryImages.length &&
                            <Grid item md={6} xs={12}>
                                <Typography className={'title'}>평가 결과</Typography>
                                <Box
                                    className={clsx({
                                        'flex': 15 === gameResults.memoryImages.length,
                                        'inline': 15 > gameResults.memoryImages.length
                                    })}>
                                    <Box className={clsx({
                                        'left-box-title': 15 === gameResults.memoryImages.length,
                                        'left-box-title-land' : 15 > gameResults.memoryImages.length
                                    })}>정답보기</Box>
                                    <Grid 
                                        container spacing={1}
                                        className={clsx({
                                            'result-each-box' : 15 > gameResults.memoryImages.length
                                        })}
                                    >
                                        {
                                            gameResults.memoryImages.map((value, index) => {
                                                const viewValue = 'image' === viewMode ? gameResults.memoryImages[index] : gameResults.memoryTexts[index]
                                                // const lineHeight = ('text' === viewMode && viewValue.length > 3) ? 43 : boxSize 
                                                return (
                                                    <Grid item key={`grid-box-${index}`}>
                                                        <Box style={{
                                                            width: boxSize,
                                                            height: boxSize,
                                                            borderRadius: '10px',
                                                            border: 3,
                                                            borderStyle: 'solid',
                                                            borderColor: '#A7A7A7',
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}>
                                                            {('image' === viewMode) &&
                                                            <img src={viewValue} alt={`grid-box-${index}`}
                                                                 style={{width: '100%'}}/>
                                                            }
    
                                                            {('text' === viewMode) &&
                                                            <Typography
                                                                variant={"h6"}
                                                                style={{
                                                                    textAlign: 'center',
                                                                    width: '100%',
                                                                    fontSize: '1rem'
                                                                    // lineHeight: `${lineHeight}px`
                                                                }}
                                                            >
                                                                {viewValue}
                                                            </Typography>
                                                            }
    
                                                        </Box>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Box>
                                <Box mt={5}
                                     className={clsx({
                                         'flex': 15 === gameResults.memoryImages.length,
                                         'inline': 15 > gameResults.memoryImages.length
                                     })}     
                                >
                                    <Box className={clsx({
                                        'left-box-title': 15 === gameResults.memoryImages.length,
                                        'left-box-title-land mt45' : 15 > gameResults.memoryImages.length
                                    })}>선택목록</Box>
                                    <Grid container
                                          className={clsx({
                                              'result-each-box' : 15 > gameResults.memoryImages.length
                                          })}
                                          spacing={1}>
                                        {
                                            gameResults.answerResults.map((answer, index) => {
                                                const {isCorrect, image, text} = answer
                                                const grayscale = isCorrect ? '0%' : '100%'
                                                const lineHeight = ('text' === viewMode && text.length > 3) ? 43 : boxSize
                                                return (
                                                    <Grid item key={`grid-box-${index}`}>
                                                        <Box style={{
                                                            position: 'relative',
                                                            width: boxSize,
                                                            height: boxSize,
                                                            borderRadius: '10px',
                                                            border: 3,
                                                            borderStyle: 'solid',
                                                            borderColor: '#A7A7A7',
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}>
                                                            {isCorrect &&
                                                            <Box position={'absolute'} zIndex={10} left={5} top={5}>
                                                                <SVG name={'check'} width={'24px'} height={'24px'}
                                                                     color={'#04CF5C'}/>
                                                            </Box>
                                                            }
    
                                                            {('image' === viewMode) &&
                                                            <img src={image} alt={`grid-box-${index}`} style={{
                                                                width: '100%',
                                                                filter: `grayscale(${grayscale})`
                                                            }}/>
                                                            }
    
                                                            {('text' === viewMode) &&
                                                            <Typography
                                                                variant={"h6"}
                                                                style={{
                                                                    textAlign: 'center',
                                                                    fontSize:'1rem',
                                                                    width: '100%'
                                                                }}
                                                            >
                                                                {text}
                                                            </Typography>
                                                            }
    
                                                        </Box>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                            }
                        </Grid>
                    </DialogContent>
                </div>
               
            </Dialog>
        );
    }
}
