import Axios from 'axios';

export default class PaymentService {
    
    static async getPayments(name,  limit = 0){
        try{
            const result = await Axios.get('/api/manage/payment',{
                params: {
                    name: name ? name : '',
                    limit
                }
            })
            return result.data

        }catch{}
        return []
    }

    
}