import React, {createRef, useCallback, useEffect, useRef, useState} from 'react';
import {Box, Button, Typography, Grid} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import clsx from "clsx";
import DateHelper from "../../../helper/DateHelper";
import MeasureService from "../../../services/MeasureService";
import PrintIcon from "@material-ui/icons/Print";
import PrintHelper from "../../../helper/PrintHelper";
import {useParams} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import {ViewChar} from "../common/Data";

const svg = {
    check: 'M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z',
}
const SVG = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={props.width ? props.width : 'auto'}
            height={props.height ? props.height : 'auto'}
            viewBox="0 0 24 24"
            fill={props.color}
        >
            <path
                d={svg[props.name]}
            />
        </svg>
    );
}

const MemoryTestPrint = () => {

    const printRef = useRef();
    const headerRef = useRef();
    const bottomRef = useRef();
    
    const  scoreTable = [
      {startAge:20, endAge: 20, startScore:10, endScore: 10},
      {startAge:20, endAge: 20, startScore:9, endScore: 9},
      {startAge:30, endAge: 30, startScore:8, endScore: 8},
      {startAge:40, endAge: 40, startScore:7, endScore: 7},
      {startAge:50, endAge: 50, startScore:6, endScore: 6},
      {startAge:60, endAge: 60, startScore:5, endScore: 5},
      {startAge:70, endAge: 70, startScore:4, endScore: 4},
      {startAge:80, endAge: 80, startScore:3, endScore: 3},
    ].reverse()

  const { logID } = useParams()
  
    const [result, setResult] = useState(null)
    const fetch = useCallback(async () => {
      setResult(await MeasureService.getMeasureLogByID(logID))
    }, [])

    useEffect(() => {
      fetch().then()
    }, [])

    useEffect(() => {
      window.addEventListener(
        "message",
        (e) => {
          if ('print-m2-trigger' === e.data.message) {
            const print = document.querySelector('.btn-print')
            if(print) {
              setTimeout(() => {
                print.click()
                window.parent.postMessage({ message: "print-m2-ready" }, '*');
              }, 1000)
            }
          }
        },
        false
      );
    }, []);
    
    const colSize = 6
  
    if(!result) return <></>

    const { exerciseName , createdAt , resultVar, centerName, code } = result
    const renderView = ViewChar[resultVar.view]
    const renderViewChar = renderView.data
    
    console.log(resultVar.view)
    
    return (
        <Box
          position={'relative'}
          className={clsx(['level-dialog'])}>
            <div>
                <div ref={headerRef} 
                     id="responsive-dialog-title" 
                     className="print"
                     style={{
                       padding: 0,
                       borderTop: 0,
                       borderWidth: 3
                     }}
                > 
                  <div className={'title-left'}
                    style={{
                      position: 'relative',
                      paddingTop:28,
                      paddingLeft: 100,
                      paddingBottom:22,
                      fontWeight: "bold",
                      fontSize: '1.5rem',
                      color: '#3F51B5'
                    }}     
                  >
                    <Box style={{left: 30, top: 10, position: 'absolute'}} >
                      <img src={require(`@assets/images/bh_logo.png`)} alt={'logo'} style={{height: 60}}/>
                    </Box>
                    {exerciseName} 검사
                    <Box style={{display: 'inline-block', paddingLeft: 30, fontSize: '1rem', color: '#333'}}>
                      {centerName}, {code}
                    </Box>
                  </div>
                  <Box
                       style={{right: 60, top: 14, color: '#000', fontWeight: "normal", fontSize: '1rem', position: 'absolute', padding: 10, paddingRight: 20, paddingLeft: 20, backgroundColor: '#EBF7FF', borderRadius: 4}}
                       className={'print-show'}>
                      <div style={{fontSize: '0.9rem'}}><span style={{ fontWeight: 'bold', color: '#2F4890' }}>검사일</span>: {DateHelper.format(createdAt,'YYYY.MM.DD')}</div>
                      <div style={{fontSize: '0.9rem'}}><span style={{ fontWeight: 'bold', color: '#2F4890' }}>출력일</span>: {DateHelper.now('YYYY.MM.DD')}</div>
                  </Box>
                </div>
                <div style={{display:'flex', justifyContent: 'center'}}>
                    <Grid container spacing={3} style={{maxWidth: '1208px'}} ref={printRef}>
                        <Grid item xs={6} md={colSize} >
                            <Box marginTop={0}>
                                <Typography className={'title'} >검사 결과</Typography>
                                <Grid container spacing={2} className={'result-wrap'} style={{fontWeight: "bold", minHeight: 0, paddingBottom: 0}}>
                                    <Grid item xs={4}>
                                        <Paper elevation={0} className={'result-box'}>
                                            <Typography className={'text-blue'}>검사시간</Typography>
                                            <Typography className={'score'}>{resultVar.time}</Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Paper elevation={0} className={'result-box'}>
                                            <Typography className={'text-blue'}>인지개수</Typography>
                                            <Typography className={'score'}>{resultVar.correctCount} / {resultVar.count}개</Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Paper elevation={0} className={'result-box'}>
                                            <Typography className={'text-blue'}>판별점수</Typography>
                                            <Typography className={'score'}>{resultVar.scoreText}</Typography>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mt={2}>
                                <Typography className={'title'} style={{fontWeight: "bold", minHeight: 0, paddingBottom: 0, marginTop: 30}}>평가 기준</Typography>
                            </Box>
                            <Box mt={2}>
                                <TableContainer>
                                    <Table aria-label="table" size={'small'} className={'level-table-d'}>
                                        <TableHead>
                                            <TableRow className={''}>
                                                <TableCell>연령 기준</TableCell>
                                                <TableCell>인지 개수</TableCell>
                                                <TableCell>판별 점수</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {scoreTable.map((table, index) => {
                                            const {startAge, startScore} = table

                                            return (
                                              <TableRow key={`table-${index}`}>
                                                <TableCell scope="row">{startAge}대 {7 === index  ? '이하' : '이상'}</TableCell>
                                                <TableCell>{startScore}개 이상</TableCell>
                                                <TableCell>{startScore * 10}점 {7 === index  ? '' : '이상'}</TableCell>
                                              </TableRow>
                                            )
                                          })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography className={'title'}>평가 결과</Typography>
                          {0 < resultVar.problems.length &&
                          <List style={{paddingTop: 0}}>
                          {
                            new Array(10).fill(0).map((_, index) => {
                              let randomIndex, selectedIndex, isCorrect = false , resultText, textColor
                              const result = resultVar.problems[index]
                              
                              if(result){
                                randomIndex = result.randomIndex
                                selectedIndex = result.selectedIndex
                                isCorrect = result.isCorrect
                              }
                              if(isCorrect){
                                resultText = '성공'
                                textColor = '#333'
                              } else {
                                resultText = '실패'
                                textColor = '#bbb'
                              }
                              if(!randomIndex) {
                                resultText = '없음'
                                textColor = '#999'
                              }
  
                              const currentNumber = index + 1
                              return(
                                <React.Fragment key={index}>
                                  <ListItem>
                                    <Grid container>
                                      <Grid item style={{paddingRight: 10, lineHeight:`46px`, width:90}}>
                                        <b style={{paddingRight: 10}}>{currentNumber}</b>
                                        <span style={{color: textColor, fontWeight: 'bold'}}>{resultText}</span>
                                      </Grid>
                                      {new Array(currentNumber).fill(0).map((val, subIndex) => {
                                        let backgroundColor = '#fff'
                                        let grayscale = '0%';
                                        if(randomIndex ){
                                          if(isCorrect){
                                            backgroundColor = '#58A25B'
                                          } else{
                                            backgroundColor = '#EFEFEF'
                                            grayscale = '100%';
                                          }
                                        }
  
                                        //my-answer = selectedIndex, correct-answer = randomIndex 
                                        // const viewIndex = 'my-answer' === this.state.answerType ? (selectedIndex || [])[subIndex] :(randomIndex || [])[subIndex]
                                        // if('correct-answer' === this.state.answerType){
                                        //   backgroundColor = randomIndex ? '#58A25B' : '#fff'
                                        //   grayscale = '0%';
                                        // }

                                        const viewIndex = (randomIndex || [])[subIndex]
                                        
                                        return (
                                          <Grid item key={`sub-${index}-${subIndex}`}>
                                            <Box style={{
                                              width: 45,
                                              height: 45,
                                              borderRadius: '50%',
                                              border: 4,
                                              borderStyle: 'solid',
                                              borderColor: '#efefef',
                                              backgroundSize: 'contain',
                                              backgroundRepeat: 'no-repeat',
                                              backgroundPosition: 'center',
                                              color: randomIndex ? '#000' : '#efefef',
                                              backgroundColor: backgroundColor
                                            }}>
                                              {'text' === renderView.type &&
                                                <Typography
                                                  style={{
                                                    transitionDuration: '0.5s',
                                                    textAlign: 'center',
                                                    fontWeight: 'bold',
                                                    lineHeight: '36px'
                                                  }}
                                                >
                                                  {randomIndex ? renderViewChar[viewIndex] : ''}
                                                </Typography>
                                              }
                                              
                                              {'image' === renderView.type && randomIndex &&
                                                <img style={{
                                                  width: '100%',
                                                  height:'100%',
                                                  borderRadius: '50%',
                                                  filter: `grayscale(${grayscale})`
                                                }}
                                                     src={renderViewChar[viewIndex]}
                                                     alt={index}  alt={subIndex} data-answer={viewIndex}/>
                                              }
                                            </Box>
                                          </Grid>
                                        )
                                      })}
                                    </Grid>
                                  </ListItem>
                                  {/*<Divider />*/}
                                </React.Fragment>
                              )
                            })
                          }
                        </List>
                          }
                        </Grid>
                    </Grid>
                </div>
                <Box ref={bottomRef} mt={3} mb={3}  style={{fontSize: '1.1rem', minHeight:44, lineHeight: '44px', fontWeight: 'normal', textAlign: 'center', borderTop: "3px solid #3F51B5"}} >
                  보건복지부 144호 한국뇌건강증진운동연합회 https://kbhd.org
                </Box>
            </div>
          <Button
            className={'btn-print'}
            startIcon={<PrintIcon/>}
            style={{padding: '7.5px 21px', marginRight: 10}}
            variant={'outlined'} size={'large'}
            onClick={() => {
              PrintHelper.print(printRef, headerRef, bottomRef)
            }}
          >
            인쇄
          </Button>
        </Box>
    )

}

export default MemoryTestPrint;
