import React from "react";
import _ from "lodash";
import {Box, Grid} from "@material-ui/core";
import SimpleCalcBox from "./SimpleCalcBox";
import MatrixCalcBox from "./MaxtrixCalcBox";

export class CalcBox extends React.Component {
  constructor(props) {
    super();

    const { stageInfo, onSelectEnded } = props

    this.state = {
      onSelectEnded,
      stageInfo,
      programView: props.programView,
    }
  }
  
  render() {
    const { stageInfo, calcValues, onSelectEnded } = this.state
    if(!stageInfo) return <></>

    return (
      <>
        <Box position={'absolute'}
             top={-200}
             left={0}
             width={'100%'}
             textAlign={'center'}
             fontSize={'2.2rem'}
             fontWeight={'bold'}
        >
          {stageInfo.text}
        </Box>

        {['plus', 'minus'].includes(stageInfo.type) &&
        <SimpleCalcBox 
          stageInfo={stageInfo} 
          calcValues={calcValues}
          onSelectEnded={onSelectEnded}
        />
        }

        {['matrix'].includes(stageInfo.type) &&
          <MatrixCalcBox
            stageInfo={stageInfo}
            calcValues={calcValues}
            onSelectEnded={onSelectEnded}
          />
        }
      </>

    )
  }
}


