import React, {useState, useEffect, useCallback} from 'react'

import {makeStyles} from '@material-ui/core/styles'
import {Box, Grid, Typography} from '@material-ui/core'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Skeleton from '@material-ui/lab/Skeleton';
import CustomerMeasureDialog from "./CustomerMeasureDialog";

import CustomerService from "@user-service/manage/CustomerService";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import DateHelper from '@helper/DateHelper'
import MeasureService from "../../../services/MeasureService";

import CheckIcon from '@material-ui/icons/Check';
import AuthProvider from "../../../provider/AuthProvider";

const PrescriptionList = ({searchKeyword}) => {
    const [prescriptions, setPrescriptions] = useState()
    const [currentProfile, setCurrentProfile] = useState({
        id: null,
        name: null,
        loginDate: null
    })
    const classes = useStyles()
    
    const fetchPrescription = useCallback(async () => {
        const { prescriptions } = await CustomerService.getPrescriptions(searchKeyword)
        setPrescriptions(prescriptions)
    }, [searchKeyword])

    useEffect(()=>{
        fetchPrescription().then()
    },[fetchPrescription])


    if(!prescriptions){
        return(<LoadingSkeleton/>)
    }
    
    return (
        <Box className={classes.root}>

            <CustomerMeasureDialog 
                open={null != currentProfile.id}
                profileID={currentProfile.id}
                profileName={currentProfile.name}
                lastLoginDate={currentProfile.loginDate}
                onClose={() => {setCurrentProfile({id:null, name:null, loginDate: null})}}/>

            {0 === prescriptions.length &&
            <List style={{color: 'white', marginTop: 25}}>
                <ListItem>
                    <ListItemText primary={
                        <Box style={{paddingTop: 30, textAlign: 'center', color: '#999'}}>
                            <Typography>측정 기록이 없습니다</Typography>
                        </Box>
                    } />
                </ListItem>
            </List>
            }

            {0 < prescriptions.length &&
                <List style={{color: 'white', marginTop: 25}}>
            {
                prescriptions.map((prescription) => {
                    const { id, profileName, profileImage, createDate } = prescription
                    const { profileID, code, lastLoginDate, measureTypes } = prescription
                    const measureTypesCount = MeasureService.getDefaultMeasureTypesCount({measureGroup: 'measure', checkedTypes: measureTypes})
                    return(
                        <React.Fragment key={id}>
                            <ListItem 
                                button
                                onClick={async () => {
                                    setCurrentProfile({
                                        id: profileID,
                                        name: code,
                                        loginDate: DateHelper.format(lastLoginDate, 'YYYY.MM.DD')
                                    })
                                }}>
                                <ListItemAvatar>
                                    <Avatar variant="rounded" className={classes.large}>
                                        {profileImage
                                            ? <img src={require(`@images/profile/${profileImage}`)} alt={profileName}/>
                                            : <ImageIcon/>
                                        }
                                    </Avatar>
                                </ListItemAvatar>

                                <ListItemText primary={
                                    <Box style={{paddingLeft: 10}}>
                                        {id} 회차 - {profileName} 
                                    </Box>
                                } secondary={
                                    <span style={{paddingLeft: 10, color: '#999'}}> 측정일: {DateHelper.format(createDate, 'YYYY.MM.DD HH:mm:ss')}</span>
                                } />

                                <ListItemSecondaryAction>
                                    <Grid container spacing={2}>
                                        
                                        <Grid item>
                                            <MeasureTypesGroup 
                                                measureTypesCount={measureTypesCount}
                                                profileID={profileID}
                                                prescriptionID={id}
                                            />
                                        </Grid>
                                        <Grid item >
                                            <Button 
                                                onClick={() => {
                                                    setCurrentProfile({
                                                        id: profileID,
                                                        name: code,
                                                        loginDate: DateHelper.format(lastLoginDate, 'YYYY.MM.DD')
                                                    })
                                                }}
                                                size={'large'} 
                                                className={classes.backGray}>
                                                측정결과
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    )
                })
            }
            </List>
            }
        </Box>
    )
}

const MeasureTypesGroup = (props) => {
    const classes = useStyles()
    const [measureTypesCount, setMeasureTypesCount] = useState(props.measureTypesCount || [])
    
    useEffect(() => {
        setMeasureTypesCount(props.measureTypesCount || [])
    },[props.measureTypesCount])
    
    return (
        <ButtonGroup style={{marginTop: 7, borderColor: '#999'}} size={'small'}>
            {measureTypesCount.map((type, index) => {
                return (
                    <Button
                        key={type.key}
                        className={type.checked ? classes.backGray : classes.backDisabled}
                        startIcon={ <CheckIcon className={type.checked ? classes.backGray : classes.backDisabled}/>}
                        onClick={ async () => {
                            if(!AuthProvider.isSuperAdmin()) return
                            
                            const checkedKey = [];
                            const newMeasureTypes = measureTypesCount.map((type, innerIndex) => {
                                if(index === innerIndex){
                                    type.checked = !type.checked
                                }
                                if(type.checked) checkedKey.push(type.key)
                                return type
                            })
                            
                            await CustomerService.updatePrescriptionMeasureType({
                                profileID: props.profileID,
                                prescriptionID: props.prescriptionID,
                                measureTypes: checkedKey.join(',')
                            })
                            
                            setMeasureTypesCount(newMeasureTypes)
                        }}
                    >
                        {type.label}
                    </Button>
                )
            })}
        </ButtonGroup>
    )
}


//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <div className="editprofile_wrap wrapper">
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </div>
        </Box>
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center",
        maxWidth: 1280,
        height: '100vh',
        paddingTop: 30,
        margin: 'auto'
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
        backgroundColor: '#1E2030',
    },
    title: {
        color: 'white',
        textAlign: 'center',
        marginBottom: 100,
        fontSize: 42
    },
    small: {
        fontSize: '0.8rem',
        color: '#999',
    },
    logo: {
        position: 'fixed',
        left: 30 ,
        top: 22,
        zIndex: 0,
    },
    add: {
        position: 'fixed',
        right: 30 ,
        top:22,
        zIndex: 0,
    },
    profileWrap: {
        width: '100%'
    },
    back: {
        color: 'white',
        '&:hover': {
            backgroundColor: '#12131D',
        }
    },
    backGray: {
        color: '#999',
    },
    backDisabled: {
        color: '#444'
    },
    
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),

        backgroundColor: 'transparent',
        '& img': {
            width: '100%'
        }
    },
}));


export default PrescriptionList
