
import React, {useCallback, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

import clsx from "clsx";

import {Grid} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TuneIcon from "@material-ui/icons/Tune";
import BridgeProvider from "../../provider/BridgeProvider";

const ComputerManage = ({history}) => {
    const classes = useStyles();

    const [state, setState] = useState({
        computerName: '...',
        version: '...',
        displayInfo: '...'
    })
    
    const computerFetch = useCallback(async () => {
        try {
            const version = await BridgeProvider.instance.getVersion()
            // const computerName = await window.BRHW.getComputerName()
            const displayInfo = await BridgeProvider.instance.getDisplayInfo()

            const displayParams = new URLSearchParams(displayInfo);
            
            
            setState({
                ...state,
                version,
                displayInfo : `${displayParams.get('width')} x ${displayParams.get('height')}`
            })
        } catch(e) {}
    },[])
    
    useEffect(()=>{
        computerFetch().then()
    },[computerFetch])
    
    
    const { computerName, version, displayInfo } = state
    
    return (
        <div id="cont-wrap" >
            <div className="sv" >
                <Grid container>
                    <Grid item xs={1}>
                        <IconButton
                            className={classes.back}
                            aria-label="delete"
                            onClick={()=>{
                                history.goBack()
                            }}
                        >
                            <ArrowBackIcon style={{fontSize: 50}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <div>
                            <h3>컴퓨터 정보</h3>
                            <i>Brain Health</i>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className={clsx('cont', classes.root)} >
                <List
                    style={{marginTop: 30}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            델타파 사용 
                        </ListSubheader>
                    }
                    className={classes.listRoot}
                >
                    <ListItem className={'item'} >
                        <ListItemIcon>
                            <TuneIcon />
                        </ListItemIcon>
                        <ListItemText primary="컴퓨터 이름" />
                        <ListItemSecondaryAction>
                            {computerName}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                
                    <ListItem className={'item'} >
                        <ListItemIcon>
                            <TuneIcon />
                        </ListItemIcon>
                        <ListItemText primary="디스플레이 해상도" />
                        <ListItemSecondaryAction>
                            {displayInfo}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </List>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 800,
        margin: 'auto',
        
        '& .item': {
            padding : '20px 10px'
        }
    },
    back: {
        marginTop: 30,
        '&:hover': {
            backgroundColor: '#053288',
        }
    },
    small: {
        fontSize: 14,
        color: '#999',
    },
    remove: {
        color: '#ccc',
        '&:hover': {
            color: '#666'
        },
        marginLeft: 20
    },
    date: {
        fontSize: 14,
        color: '#999',
        fontFamily: 'Noto Sans KR',
    },
    text:{
        fontFamily: 'Noto Sans KR',
    },
    inline: {
        display: 'inline',
    },
    listRoot: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
    },
}));

export default ComputerManage

