import React, {useEffect, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import {TextField} from "@material-ui/core";


const CssTextField = withStyles({
    root: {
        color: 'white',
        backgroundColor: '#4B4D5A',
        '& .MuiInput-input': {
            color: 'white'  ,
            height: '60px !important',
            paddingLeft: 10
        },
        '& label': {
            color: 'white',
            paddingLeft: 10,
        },
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ddd',
            },
        },
    },
})(TextField);

export default function SearchTextField(props) {
    const [state , setState] = useState({
        text: '',
        timer: null,
        keyup: props.keyup
    })

    useEffect(() => {
      setState({
        ...state,
        text: props.defaultValue
      })
    }, [props.defaultValue])
    const {text, timer, keyup}  = state

    return (
        <CssTextField
            fullWidth
            value={text}
            placeholder={'회원을 검색하세요'}
            style={{fontSize: 50}}
            inputProps={{
                maxLength: 50,
            }}
            onChange={(event)=>{
                if(timer) {
                    clearTimeout(timer)
                }
                const newText = event.target.value
                const newTimer = setTimeout(() => {
                    if(keyup){
                        keyup(newText)
                    }
                }, 500)

                setState({
                    ...state,
                    text: newText,
                    timer: newTimer
                })
            }} />
    )
}
