import React, {useState} from 'react';

import {Box, Button, Grid} from '@material-ui/core';
import ExerciseBox from '@user-pages/exercise/ExerciseBox'
import Typography from "@material-ui/core/Typography";
import FirstMeasureMessage from "../../../components/FirstMeasureMessage";
import NeedTicketDialog from "../../../components/NeedTicketDialog";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {makeStyles} from "@material-ui/core/styles";
import MeasureTestHistoryDialog from "./MeasureTestHistoryDialog";
import clsx from "clsx";

const MeasureTestDefault = (props) => {
    const [open, setOpen] = useState(false)
    
    const { state, measureType } = props
    const [ showHistory, setHistory ] = useState(false)
    const { exercise, prescription, measureTypesCount } = state
    const classes = useStyles()
  
    return (
        <div id="cont-wrap">
            <div className={clsx('sv',{
                     'green' : ['develop','train'].includes(measureType)
                 })}>
                <div>
                    <h3>
                        {['develop','train'].includes(measureType) ? '두뇌 계발' : '뇌기능 검사'}
                    </h3>
                    <span>기본적인 항목을 검사 합니다.</span>
                    <i>Brain Health</i>
                </div>
            </div>

            <div className="cont">
                <Box mb={3}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant={'h5'}>{ prescription && prescription.id ? `${prescription.id} 회차` : ''}</Typography>
                            {/*<Typography variant={'subtitle2'}>뇌기능 분서석메뉴에서 결과를 확인 할 수 있습니다.</Typography>*/}
                        </Grid>
                        <Grid item xs={6}>
                            <Box display={'flex'} justifyContent={'flex-end'} width={'100%'} style={{marginBottom: 20, paddingRight: 10}}>
                                {/*<Button*/}
                                {/*    className={classes.button}*/}
                                {/*    style={{marginRight: 10}}*/}
                                {/*    onClick={async () => {*/}
                                {/*        if(props.openMeasureTest){*/}
                                {/*            props.openMeasureTest()*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*> 프로그램 선택 </Button>*/}
                                
                                <Button
                                    className={classes.button}
                                    endIcon={<ChevronRightIcon className={'rightIcon'}/>}
                                    onClick={async () => {
                                        setHistory(!showHistory)
                                    }}
                                > 기록보기 </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                {!prescription &&
                    <FirstMeasureMessage />    
                }
                {prescription &&
                <>
                  <Grid container spacing={3}>
                      {exercise.map((ex) => {
                          return (
                              <Grid item  md={4} xs={6} key={ex.id}>
                                  <ExerciseBox
                                      {...ex}
                                      history={props.history}
                                      prescriptionID={prescription.id}
                                      measureTypesCount={measureTypesCount.find(type => type.key === ex.programID)}
                                      onNeedTicket={() => {
                                          setOpen(true)
                                      }}
                                      measureType={measureType}
                                  />
                              </Grid>
                          )
                      })}
                  </Grid>
                </>
                }
            </div>
            <NeedTicketDialog
                open={open} 
                onClose={(() => setOpen(false))}
            />
            <MeasureTestHistoryDialog
                exercise={exercise}
                open={showHistory}
                onClose={() => {
                    setHistory(false)
                }}
            />
        </div>
    )
}



const useStyles = makeStyles(() => ({
    button: {
        border: '2px solid #BEBEBE',
        padding: '5px 30px',
        borderRadius: 20,
        color: '#666',
        '& .MuiButton-endIcon': {
            marginRight: -15
        },
        '& .rightIcon': {
            color: '#00DD94',
            fontSize: 25,
            fontWeight: 'bold'
        }
    },
}));


export default MeasureTestDefault
