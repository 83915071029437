import React, {useState, useEffect} from 'react';
import { makeStyles} from '@material-ui/core/styles';

import {  Box, Typography} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import SurveyService from '@user-service/SurveyService'
import SurveyType from '@enum/SurveyType'
import SurveyDetail from './SurveyDetail';


const SurveyAdmin = (props) => {
    const classes = useStyles();
    const [surveyParticipants, setSurveyParticipants] = useState(null)
    
    
    useEffect(()=>{ 
        SurveyService.getSurveyParticipants((result)=>{
            setSurveyParticipants(result.data)
        })

    },[])

    if(!surveyParticipants) return <></>

    return (
        <div className="mypage-wrap wrapper2">
            <section className="section section2_2 bdtop pt40">

                <Typography variant="h5" component="h3" >
                    자각증상 리스트
                </Typography>

                <Typography variant="subtitle1" 
                            style={{textAlign:'left',paddingTop:20,paddingBottom:20, borderBottom:'1px solid #eee', marginBottom:20, color:'#999'}}>
                사용자가 입력한 자각증상 목록을 표시합니다.
                </Typography>

                {/* <Grid container justify="flex-end">
                    <SearchMember />
                </Grid> */}

                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>사용자</TableCell>
                        <TableCell align="right">나이</TableCell>
                        <TableCell align="right">설문타입</TableCell>
                        <TableCell align="right">문항 수</TableCell>
                        <TableCell align="right">설문일</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {surveyParticipants.map(p => (
                        <SurveyTableRow {...p} key={p.profile.id}/>
                    ))}
                    </TableBody>
                </Table>
            </section>
        </div>
    );
}


export function SurveyTableRow(props){
    var old = "-"
    const profile = props.profile

    try{
        old = new Date().getFullYear() - parseInt(profile.birthDate.substr(0,4)) + 1
    }catch(e){
    }
    
    return <TableRow key={profile.id}>
            <TableCell component="th" scope="row">
                <SurveyDetailDialog profileCode={profile.code} profileID={profile.id}/>
            </TableCell>
            <TableCell align="right">{old}</TableCell>
            <TableCell align="right">{SurveyType.getName(profile.type)}</TableCell>
            <TableCell align="right">-</TableCell>
            <TableCell align="right">{props.participatedAt.substr(0,10)}</TableCell>
            
        </TableRow>
}


//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <div className="editprofile_wrap wrapper" xs={6} sm={6}>
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </div>
        </Box>
    )
}

/**
 * 사용자를 검색합니다.
 */
export function SearchMember() {
    const classes = useStyles();
  
    return (
      <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="사용자 검색"
          inputProps={{ 'aria-label': 'search google maps' }}
        />
        <IconButton type="submit" className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
    );
  }


//상세보기 
export function SurveyDetailDialog(props) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
    
    const handleClose = () => {
        setOpen(false)
    }

    const handleClickOpen = () => {
        setOpen(true)
    };

    
    
    return (
      <div>
        <Button onClick={handleClickOpen}>
          {props.profileCode}
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth={'xl'}
        >
          <DialogTitle id="responsive-dialog-title">{props.profileCode}</DialogTitle>
          <DialogContent >
            <SurveyDetail profileID={props.profileID}/>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              닫기
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

const useStyles = makeStyles(theme => ({
    marginTop: {
        marginTop:50
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        marginBottom:30
      },
      input: {
        marginLeft: theme.spacing(1),
        flex: 1,
      },
      iconButton: {
        padding: 10,
      },
      divider: {
        height: 28,
        margin: 4,
      },
}));


export default SurveyAdmin
