import React, {useRef} from "react";
import {Box, Grid} from "@material-ui/core";

import PieChart from '@user-components/PieChart';
/**
 * 균형상태 분석 - 파이 차트
 * @param {*} props
 */
export default function BrainBalanceMap(props) {
    const chartRef = useRef()
    const labels = ['δ','θ','α','SMR','β', 'βM', 'βh']
    const {bandRangeData, width, height, circleWidth, fontSize} = props;

    return <Box style={{width, height}}>
        <Grid container justify={ 'center' } spacing={2} >
            { labels.map((label, index) => {
                const ch1 = bandRangeData.ch1[index];
                const ch2 = bandRangeData.ch2[index];
                const total = parseFloat(ch1) + parseFloat(ch2)
                const ch1Percent = 0 === total ? 50 : Math.floor(ch1 / total * 100)
                // const ch2Percent = 0 == total ? 50 : parseInt(ch2 / total * 100)
                const ch2Percent = 100 - ch1Percent;


                return <Grid item key={ Math.random() * 100 } >
                    <PieChart 
                        ref={ chartRef }
                        colors={['#BEF3B0', '#FF00A5']}
                        useColorIndex={true}
                        defaultData={[ch2Percent, ch1Percent]}
                        labels={['우: ' + ch2Percent,'좌: ' + ch1Percent]}
                        labelsReverse={true}
                        height={height ? height : 200}
                        width={circleWidth ? circleWidth : 150}
                        xLabelDisplay={true}
                        legendPosition={'bottom'}
                        title={label}
                        fontSize={fontSize ? fontSize : 14}/>
                </Grid>
            }) }
        </Grid>
    </Box>
}