import React from 'react';
import {Box, Button, ButtonGroup, Grid, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import TestService from "../../../services/TestService";
import clsx from "clsx";
import ReactAudioPlayer from "react-audio-player";
import cookie from 'react-cookies';
import AuthProvider from "../../../provider/AuthProvider";

export default class LevelDialog extends React.Component {
    constructor(props) {
        super();
        
        const measureType =  props.measureType ? props.measureType : 'measure'
        const subLevel = localStorage.getItem('answerCount')
        let answerCount
        
        switch(subLevel){
            case 'sub-level-1' : 
                answerCount = 3
                break
            case 'sub-level-2' :
                answerCount = 6
                break
            case 'sub-level-3' :
                answerCount = 10
                break
            default:
                answerCount = 10
        }
        
        if('develop' !== measureType){
            answerCount = 10

            // if('easy' === props.playMode){
            //     answerCount = 9
            // }
          
            // if(AuthProvider.isProfileSelectorView()){
            //     answerCount = 4
            // }
        }

        
        
        this.state = {
            open: false,
            onStart: props.onStart,
            history: props.history,
            measureType: measureType, // 'measure - 검사', 'develop' - 계발
            exerciseName: props.exerciseName,
            delay: 2000,
            limitTime: 300,
            showType: '1',
            view: 'view1',
            pageView: 'level', // 'level' | 'time'
            currentLevel: '1',
            answerCount: answerCount,
            backgroundColor: TestService.getColorName(measureType, props.exerciseID),
            useAudio: !('yes' === cookie.load('_sanaex')) ,
            playMode: props.playMode
        }
    }

    open(showType = '1', view= 'view1') {
        
        let limitTime = 300
        if('2' === showType || '3' === showType) limitTime = 560
        
        
        let audio = undefined
        switch (view){
            case 'view8':
                //주의력 검사
                audio = 'view8-ex1'
                break
            case 'view9':
                //집중력검사
                audio = 'view9-ex1'
                break
            case 'view5':
                audio = 'view5-ex1'
                break
            case 'view4':
            case 'view1':
            case 'view3':
                //기억력
                audio = 'ex1_3_4'
        }
      
        this.setState({
            ...this.state,
            open: true,
            showType,
            limitTime,
            // view: '3' === showType ? 'view2' : 'view1'
            view,
            audio
        })
        
    }

    close() {
        this.setState({
            ...this.state,
            open: false
        })
    }

    start() {
        if(this.state.onStart){
          // 2 == use reverse true
          let isReverse = '2' === this.state.showType
          // todo 임시 처리 제거해야함 
          if('view8' ===  this.state.view){
            isReverse = false
          }
            
          this.state.onStart(
                isReverse, 
                this.state.delay, 
                this.state.limitTime, 
                this.state.view,
                this.state.answerCount
            );
        }
        this.close()
    }

    setDelay(delay) {
        this.setState({
            ...this.state,
            delay
        })
    }
    setLimitTime(limitTime) {
        this.setState({
            ...this.state,
            limitTime
        })
    }

    setView(view) {
        this.setState({
            ...this.state,
            view
        })
    }

    onExit(){
        if(this.state.history){
            this.state.history.replace('/measureTest')
        }
    }

    render() {
        let testName = this.state.exerciseName
        // switch (this.state.showType){
        //     case '1':
        //         testName = '기억력'
        //         break
        //     case '2':
        //         testName = '장기 기억력'
        //         break
        //     case '3':
        //         testName = '주의 & 집중'
        //         break
        //     default:
        //         testName = '기억력'
        // }
        
        const useAudio = this.state.useAudio
        const explainText = TestService.getMemoryExplainText(testName, this.state.showType, this.state.view, this.state.measureType)
        return (
            <Dialog
                disableBackdropClick
                // fullScreen={fullScreen}
                open={this.state.open}
                fullWidth
                maxWidth="lg"
                aria-labelledby="responsive-dialog-title"
                className={clsx(['level-dialog', this.state.measureType, this.state.backgroundColor])}
            >
                <DialogTitle id="responsive-dialog-title">
                    <Grid container>
                        <Grid item xs={ 'develop' === this.state.measureType ? 6 : 12}>
                            <Box style={{fontSize:'1.8rem', paddingLeft: 20, paddingTop: 20, textAlign: 'center'}}>{explainText.title}</Box>
                        </Grid>
                        {'develop' === this.state.measureType &&
                        <Grid item xs={6}>
                            <Box pl={3} mt={2} className={'level-group'}>
                                {'develop' === this.state.measureType &&
                                <ButtonGroup size="large" style={{width: '100%'}}>
                                    <Button startIcon={<DoneOutlineIcon/>}
                                            color={3 === this.state.answerCount ? 'primary' : ''}
                                            variant={3 === this.state.answerCount ? 'contained' : 'outlined'}
                                            onClick={() => {
                                                this.setState({
                                                    ...this.state,
                                                    answerCount: 3
                                                })
                                            }}
                                            style={{width: '50%'}}>기초(3개)</Button>
                                    <Button startIcon={<DoneOutlineIcon/>}
                                            color={6 === this.state.answerCount ? 'primary' : ''}
                                            variant={6 === this.state.answerCount ? 'contained' : 'outlined'}
                                            onClick={() => {
                                                this.setState({
                                                    ...this.state,
                                                    answerCount: 6
                                                })
                                            }}
                                            style={{width: '50%'}}>중급(6개)</Button>
                                    <Button startIcon={<DoneOutlineIcon/>}
                                            color={9 === this.state.answerCount ? 'primary' : ''}
                                            variant={9 === this.state.answerCount ? 'contained' : 'outlined'}
                                            onClick={() => {
                                                this.setState({
                                                    ...this.state,
                                                    answerCount: 10
                                                })
                                            }}
                                            style={{width: '50%'}}>고급(9개)</Button>
                                </ButtonGroup>
                                }

                            </Box>
                        </Grid>
                        }
                    </Grid>

                </DialogTitle>
                <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={6}>
                                <Box>
                                    <Typography className={'title point'}>{explainText.text1Title} </Typography>
                                    {explainText.text1}
                                </Box>
                                <Box>
                                    <Typography className={'title point'}>{explainText.text2Title} </Typography>
                                    {explainText.text2}
                                </Box>
                                {this.state.audio &&
                                    <div style={{padding: 10}}>
                                        <ReactAudioPlayer
                                            autoPlay={useAudio}
                                            src={require(`@audio/${this.state.audio}-slow.mp3`)}
                                            controls
                                            style={{width: '100%'}}
                                        />
                                        <div style={{padding: 5}}>
                                            <input type="checkbox"
                                            checked={!useAudio} id="chk" style={{width:20, height:20}}
                                            onChange={(event,checked) => {
                                                const status = !useAudio
                                                // setAudio(status)
                                                // setCookie('_sana', status ? 'no' : 'yes')
                                                cookie.save('_sanaex', status ? 'no' : 'yes' , {
                                                    path : '/',
                                                    // secure : true,
                                                    // httpOnly : true
                                                });
                                                
                                                this.setState({
                                                    ...this.state,
                                                    useAudio: status
                                                })
                                                
                                              }}
                                            /><label htmlFor={'chk'}>다음부터 음성안내를 사용하지 않습니다 </label>
                                        </div>
                                    </div>
                                }
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Box>
                                    {/*{'1' === this.state.showType &&*/}
                                    {/*<Box>*/}
                                    {/*    <Typography className={'title'}>검사방법</Typography>*/}
                                    {/*    <ul className={'box'}>*/}
                                    {/*        <li>뇌기능 기억력 검사과정은 1단계 2개 ~ 9단계 10개 과정을 검사합니다.</li>*/}
                                    {/*        <li>설정시간과 제한시간 간.</li>*/}
                                    {/*        <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>*/}
                                    {/*        <li>기억력 검사는 1개당 10%씩 총 10단계 100%까지 완성률이 기록됩니다.</li>*/}
                                    {/*    </ul>*/}
                                    {/*</Box>*/}
                                    {/*}*/}
                                    {/*{'2' === this.state.showType &&*/}
                                    {/*<Box>*/}
                                    {/*    <Typography className={'title'}>검사방법</Typography>*/}
                                    {/*    <ul className={'box'}>*/}
                                    {/*        <li>단방행 검사가 원할한 사람 또는 년령별에 따라 적절하게 적용할 수 있습니다.</li>*/}
                                    {/*        <li>진행과정은 단방향 검사 후 반대로 기억하여 프로그램에 따라 맞추는 검사입니다.</li>*/}
                                    {/*        <li>단계별로 진행되며 총 10단계 100%까지 완성율이 기록됩니다.</li>*/}
                                    {/*    </ul>*/}
                                    {/*</Box>*/}
                                    {/*}*/}

                                    <Box>
                                        <Typography className={'title'}>{'develop' === this.state.measureType ? '두뇌계발 ' : '검사'}방법</Typography>
                                        {explainText.text3}
                                    </Box>
                                    <Box mt={3} mb={3}>
                                        <Grid container spacing={1} direction={'column'}>
                                            <Grid item>
                                                <Button fullWidth variant={"contained"}
                                                        size={'large'}
                                                        color={'primary'}
                                                        style={{fontSize:'1.1rem'}}
                                                        onClick={this.start.bind(this)}>
                                                    시작하기
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button fullWidth variant={"outlined"}
                                                        size={'large'}
                                                        style={{fontSize:'1.1rem'}}
                                                        onClick={this.onExit.bind(this)}>
                                                    종료하기
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box mt={3} mb={3} mt={3}>
                                        <Grid container className={'time-table'}>
                                            <Grid item xs={6}>
                                                <Typography className={'sub-title'}>시간설정</Typography>
                                                <FormControl component="fieldset">
                                                    <RadioGroup name="customized-radios">
                                                        <FormControlLabel
                                                            checked={1000 === this.state.delay}
                                                            value={1000} control={<Radio />} label="1초" onClick={()=>{this.setDelay(1000)}} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={1500 === this.state.delay}
                                                            value={1500} control={<Radio />} label="1.5초" onClick={()=>{this.setDelay(1500)}} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={2000 === this.state.delay}
                                                            value={2000} control={<Radio />} label="2초" onClick={()=>{this.setDelay(2000)}} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={2500 === this.state.delay}
                                                            value={2500} control={<Radio />} label="2.5초" onClick={()=>{this.setDelay(2500)}} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={3000 === this.state.delay}
                                                            value={3000} control={<Radio />} label="3초" onClick={()=>{this.setDelay(3000)}} labelPlacement="end"/>
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography className={'sub-title'}>제한시간 설정</Typography>
                                                <FormControl component="fieldset">
                                                    {'1' === this.state.showType &&
                                                    <RadioGroup name="customized-radios">
                                                        <FormControlLabel
                                                            checked={300 === this.state.limitTime}
                                                            value={300} control={<Radio/>} label="5분" onClick={() => {
                                                            this.setLimitTime(300)
                                                        }} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={360 === this.state.limitTime}
                                                            value={360} control={<Radio/>} label="6분" onClick={() => {
                                                            this.setLimitTime(360)
                                                        }} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={420 === this.state.limitTime}
                                                            value={420} control={<Radio/>} label="7분" onClick={() => {
                                                            this.setLimitTime(420)
                                                        }} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={480 === this.state.limitTime}
                                                            value={480} control={<Radio/>} label="8분" onClick={() => {
                                                            this.setLimitTime(480)
                                                        }} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={540 === this.state.limitTime}
                                                            value={540} control={<Radio/>} label="9분" onClick={() => {
                                                            this.setLimitTime(540)
                                                        }} labelPlacement="end"/>
                                                    </RadioGroup>
                                                    }

                                                    {('2' === this.state.showType) &&
                                                    <RadioGroup name="customized-radios">
                                                        <FormControlLabel
                                                            checked={420 === this.state.limitTime}
                                                            value={420} control={<Radio/>} label="7분" onClick={() => {
                                                            this.setLimitTime(420)
                                                        }} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={560 === this.state.limitTime}
                                                            value={560} control={<Radio/>} label="8분" onClick={() => {
                                                            this.setLimitTime(480)
                                                        }} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={540 === this.state.limitTime}
                                                            value={540} control={<Radio/>} label="9분" onClick={() => {
                                                            this.setLimitTime(540)
                                                        }} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={600 === this.state.limitTime}
                                                            value={600} control={<Radio/>} label="10분" onClick={() => {
                                                            this.setLimitTime(600)
                                                        }} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={660 === this.state.limitTime}
                                                            value={660} control={<Radio/>} label="11분" onClick={() => {
                                                            this.setLimitTime(660)
                                                        }} labelPlacement="end"/>
                                                    </RadioGroup>
                                                    }

                                                    {('3' === this.state.showType) &&
                                                    <RadioGroup name="customized-radios">
                                                        <FormControlLabel
                                                            checked={300 === this.state.limitTime}
                                                            value={300} control={<Radio/>} label="5분" onClick={() => {
                                                            this.setLimitTime(300)
                                                        }} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={360 === this.state.limitTime}
                                                            value={360} control={<Radio/>} label="6분" onClick={() => {
                                                            this.setLimitTime(360)
                                                        }} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={420 === this.state.limitTime}
                                                            value={420} control={<Radio/>} label="7분" onClick={() => {
                                                            this.setLimitTime(420)
                                                        }} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={560 === this.state.limitTime}
                                                            value={560} control={<Radio/>} label="8분" onClick={() => {
                                                            this.setLimitTime(480)
                                                        }} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={540 === this.state.limitTime}
                                                            value={540} control={<Radio/>} label="9분" onClick={() => {
                                                            this.setLimitTime(540)
                                                        }} labelPlacement="end"/>
                                                    </RadioGroup>
                                                    }
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4} style={{display: 'none'}}>
                                                <Typography>보기 구분 설정</Typography>
                                                <FormControl component="fieldset">
                                                    {'3' !== this.state.showType &&
                                                    <RadioGroup name="customized-radios">
                                                        <FormControlLabel
                                                            checked={'view1' === this.state.view}
                                                            value={'view1'} control={<Radio />} label="숫자" onClick={()=>{this.setView('view1')}} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={'view3' === this.state.view}
                                                            value={'view3'} control={<Radio />} label="그림" onClick={()=>{this.setView('view3')}} labelPlacement="end"/>
                                                        <FormControlLabel
                                                            checked={'view4' === this.state.view}
                                                            value={'view4'} control={<Radio />} label="기호" onClick={()=>{this.setView('view4')}} labelPlacement="end"/>
                                                    </RadioGroup>
                                                    }

                                                    {'3' === this.state.showType &&
                                                    <RadioGroup name="customized-radios">
                                                        <FormControlLabel
                                                            checked={true}
                                                            value={'view2'} control={<Radio />} label="문자"  labelPlacement="end"/>
                                                    </RadioGroup>
                                                    }
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    
                        
                    </DialogContent>
            </Dialog>
        );
    }
}



