import React, {useCallback, useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useHistory } from "react-router-dom";
// import {useCookies} from "react-cookie";
import AuthProvider from '@user-provider/AuthProvider'
import ProfileService from '@user-service/ProfileService'
import {Grid} from "@material-ui/core";
import {hiIN} from "@material-ui/core/locale";
// import MeasureHelper from '@helper/MeasureHelper'

export default function AppAvatar(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({
        profiles: [],
        current: {}
    })
    const anchorRef = useRef(null)
    // const [, setCookie] = useCookies([AuthProvider.userCookieName])
    const history = useHistory()
    
    const { profiles, current } = state
    
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);    
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleProfile = () => {
        history.replace('/profile');
    }
    
    // //프로필 선택
    // const selectProfile = async (profile) => {
    //     setOpen(false)
    //    
    //     AuthProvider.set('profileID', profile.id)
    //     AuthProvider.set('profileName', profile.name)
    //     AuthProvider.set('profileType', profile.type)
    //     AuthProvider.set('code', profile.code)
    //     AuthProvider.set('image', profile.image)
    //
    //     setCookie(AuthProvider.userCookieName, AuthProvider.getUserData())
    //     await ProfileService.setCurrent(profile.id, AuthProvider.getDeviceID())
    //    
    //     const page = AuthProvider.getAppServicePage()
    //     // window.location.href = '/mypage'
    //     history.replace(`/${page}`)
    // }
    
    const handleLogout = () => {
        window.location.replace('/logout')
    }
    
    // const handleLogin = (type) => {
    //     history.push('/login?serviceType=' + type);
    // }
    
    const prevOpen = useRef(open);
    const fetchProfile = useCallback(async () => {
        const profiles = await ProfileService.getProfiles()
        const session = AuthProvider.getUserData()
        
        const filter = profiles.filter( p => p.id === session.profileID)
        setState({
            profiles,
            current: filter ? filter[0] : {}
        })
    }, [])

    
    
    useEffect(()=>{
        fetchProfile().then()
    },[fetchProfile])
    
    
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);
    
    return (
        <div className={classes.root}>
          {AuthProvider.isProfileSelectorView() 
            ?
            <Grid container spacing={1}>
                <Grid item>
                    <Button
                        className={classes.rounded}
                        variant={'outlined'}
                        onClick={() => {
                            history.replace('/survey-year')
                        }}
                    >
                        <Typography style={{padding:6, color: '#fff'}}>자각증상</Typography>
                    </Button>
                </Grid>
                <Grid item style={{paddingRight: 20}}>
                    <Button
                        className={classes.rounded}
                        variant={'outlined'}
                        onClick={() => {
                            history.replace('/measureTest')
                        }}
                    >
                        
                        <Typography style={{padding:6, color: '#fff'}}>뇌기능검사</Typography>
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        className={classes.rounded}
                        ref={anchorRef}
                        edge="end"
                        aria-label="account of current user"
                        aria-haspopup="true"
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        variant={'outlined'}
                    >
                        {(current && current.image) &&
                            <Avatar variant="rounded" alt={AuthProvider.getProfileName()} src={require(`@images/profile/${current.image}`)}/>
                        }
                        <Typography style={{padding:'0px 5px', color: '#fff'}}>{AuthProvider.getProfileCode()}</Typography>
                    </Button>
                </Grid>
            </Grid>
            :
            <div>
                <Button
                    className={classes.rounded}
                    ref={anchorRef}
                    edge="end"
                    aria-label="account of current user"
                    aria-haspopup="true"
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    variant={'outlined'}
                    onClick={handleToggle}
                >
                    {(current && current.image) &&
                        <Avatar variant="rounded" alt={AuthProvider.getProfileName()} src={require(`@images/profile/${current.image}`)}/>
                    }
                    
                    <Typography style={{padding:'0px 5px', color: '#fff'}}>{AuthProvider.getProfileName()}</Typography>
                    {!props.buttonMode &&
                    <ArrowDropDownIcon style={{color:'rgba(255,255,255,0.5)'}}/>
                    }
                </Button>
                
                <Popper open={open} anchorEl={anchorRef.current} transition disablePortal className={"pop-over"}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            key={`popover-1`}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper style={{padding: 15}}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" >
                                        {profiles.slice(0,5).map((profile, index) => {
                                            if(profile.name){
                                                return (
                                                    <MenuItem key={`menu-${index}`} onClick={async () => {
                                                        // await selectProfile(profile)
                                                    }} key={index}>
                                                        <Avatar variant="rounded" alt={profile.name} src={require(`@images/profile/${profile.image}`)} />
                                                        <Typography style={{padding:'0 5px'}}>{profile.name}</Typography>
                                                    </MenuItem>
                                                )
                                            }
                                            return <></>
                                        })}
                                        <Divider style={{margin: '10px 0'}}/>
                                        <MenuItem onClick={handleProfile}>프로필</MenuItem>
                                        <Divider style={{margin: '10px 0'}}/>
                                        <MenuItem onClick={handleLogout}>로그아웃</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
          }
        </div>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        zIndex: 9999,
        color: '#fff'
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    rounded: {
        borderColor: 'rgba(255,255,255,0.4)',
        color: '#222',
        fontWeight: 'bold',
        fontSize:18
    }
}));

    