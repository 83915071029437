import React, { useEffect, useState, useCallback} from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SocketProvider from '@user-provider/SocketProvider';
import CustomizedDialogs from "@user-components/CustomDialog";
import { useHistory } from "react-router-dom";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

export default function CustomerResponseDialog() {
    const history = useHistory()
    
    const [state, setState] = useState({
        open: false,
        request: {
            measureType: 0,
            prescriptionID: 0,
            memberProfileID: 0,
        }
    })
    
    const onClose = () => {
        setState({
            ...state,
            open: false,
        })
    }
    
    const onMessages = (data) => {
        const request = data.msgData
        switch (data.msgType){
            case 'customer-measure-request':
                setState({
                    ...state,
                    open: true,
                    request
                })
                break
            default:
        }
    }
    
    const checkConnection = useCallback(() => {
        const pathname = window.location.pathname
        const notAllowPath = ['/exercise/', '/measureBand', '/measure/']
        const filter = notAllowPath.filter((path) => -1 < pathname.indexOf(path))
        
        if(0 === filter.length){
            if(!SocketProvider.isConnected()){
                SocketProvider.connect(async ()=>{}, window)    
            }
            
            SocketProvider.onMessages = onMessages
        }
        
        return () => {
            SocketProvider.onMessages = null
        }
        
    }, []);

    useEffect(() => {
        return () => {
            SocketProvider.onMessages = null
        }
    }, []);


    checkConnection()

    const { open, request } = state
    let measureName = ''
    switch (request.measureType){
        case 'eeg':
            measureName = 'EEG'
            break
        case 'default':
            measureName = '균형상태'
            break
        case 'meditation':
            measureName = '뇌기능 의식패턴'
            break
        default:
    }
    
    return (
        <CustomizedDialogs
            key={`ga-${Math.random()}`}
            open={open}
            title={'측정 요청'}
            content={
                <React.Fragment>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Box>
                            <div style={{textAlign:'center', borderRadius: '50%', background: '#CDD9EE', width: 150, height: 150, margin: 'auto', marginBottom: 20}}>
                                <NotificationsNoneIcon style={{fontSize: 100, color: '#0156B8', marginTop: 20}}/>
                            </div>
                            
                            <Typography gutterBottom>
                                담당자와 실시간으로 {measureName}을 측정합니다.<br/>
                                참여하시겠습니까?
                            </Typography>
                        </Box>
                    </Box>
                </React.Fragment>
            }
            close={onClose.bind(this)}
            cancel={onClose.bind(this)}
            submit={async () => {
                onClose()
                
                SocketProvider.sendMessages('customer-measure-start',request)
                
                switch(request.measureType){
                    case 'eeg':
                        history.push(`/measureBand/${request.prescriptionID}?rqs=${request.id}`)
                        break
                    case 'default':
                        history.push(`/measure/${request.prescriptionID}?rqs=${request.id}`)
                        break
                    case 'meditation':
                        history.push(`/exercise/meditationTest/${request.prescriptionID}?rqs=${request.id}`)
                        break;
                    default:
                }
            }}
            buttonName={'참여하기'}
        />
    );
}

