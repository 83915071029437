import React, {useEffect, useState, useCallback} from 'react';

import {Box, Button, Grid, IconButton, Tab, Tabs, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';


import SurveyService from '@user-service/SurveyService'
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {Line} from "react-chartjs-2";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const queryString = require('query-string');

const LifeCycle = ({history}) => {
    const querystring = queryString.parse(window.location.search)
    const classes = useStyles()
    const [surveyRecent, setSurveyRecent] = useState()
    const [currentTab, setTab] = useState(querystring.type ? parseInt(querystring.type) : 0)
    const surveyType = 'life-cycle'

    const fetchHSurveyRecent = useCallback(async () => {
        const res = await SurveyService.getSurveyRecent(surveyType)
        const recent = {
            date: '',
            isSubmitted: false,
            labels: [],
            items: [],
            level: [],
        }
        
        res.result.map((value, index) => {
            recent.labels.push(index + 1)
            recent.items.push({
                content: value.content,
                level: value.level,
                order: value.order,
            })
            recent.level.push(value.level)
            return value
        })

        recent.surveyType = res.surveyType
        recent.date = res.recentDate

        setSurveyRecent(recent)
    }, [])

    useEffect(()=>{
        fetchHSurveyRecent().then()
    },[fetchHSurveyRecent])

    return (
        <div id="cont-wrap">
            <div className="sv">
                <Grid container>
                    <Grid item xs={1}>
                        <IconButton
                            className={classes.back}
                            aria-label="delete"
                            onClick={()=>{
                                history.goBack()
                            }}
                        >
                            <ArrowBackIcon style={{fontSize: 50}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <div>
                            <h3>라이프 사이클</h3>
                            <span>인생의 라이프 사이클을 확인해 보세요</span>
                            <i>Brain Health</i>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="cont">
                {surveyRecent &&
                <Box>
                    <Grid container spacing={1} className={'tabs'}>
                        <Grid item xs={6}>
                            <Tabs
                                value={currentTab}
                                onChange={(event, newValue) => {
                                    setTab(newValue)
                                }}
                            >
                                <Tab className={'tab'} label={'라이프 사이클'}/>
                            </Tabs>
                        </Grid>
                        <Grid item xs={6}>
                            <Box display={'flex'} justifyContent={'flex-end'} width={'100%'}>
                                <Button
                                    startIcon={<RotateLeftIcon/>}
                                    className={classes.button}
                                    endIcon={<ChevronRightIcon className={'rightIcon'}/>}
                                    onClick={async () => {
                                        if (surveyRecent) {
                                            if (!window.confirm('기존 내용이 있습니다\n기존 내용을 삭제하고 시작하시겠습니까?')) {
                                                return false
                                            }
                                            await SurveyService.deleteSurvey(surveyType)
                                        }
                                        history.push(`/survey/${surveyType}`)
                                    }}
                                > 재검사하기</Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box mt={2} mb={5}>
                        {!surveyRecent.date &&
                            <Box>
                                <StartSurveyBox surveyType={surveyType} history={history} />
                            </Box>
                        }

                        {surveyRecent.date &&
                            <MeasureLineChart surveyRecent={surveyRecent}/>
                        }
                    </Box>
                </Box>
                }
            </div>
        </div>
    );
}


//자각 증상 설문 시작하기 
export function StartSurveyBox(props){
    const classes = useStyles()
    return (
        <Grid container justify="center" className={classes.startWrap}>
            <Grid item style={{paddingLeft: 200}}>
                <img src={require('@images/brainLightIcon.png')} alt={'icon'}/>
            </Grid>
            <Grid item style={{paddingLeft: 30}}>
                <p className="txt01"><span>라이프 사이클</span>을 체크해보세요 </p>
                <Typography className={"subtitle2"}>
                    라이프 사이클을 시작해보세요 
                </Typography>
                <Button
                    className="button"
                    onClick={ async ()=>{
                        const res = await SurveyService.getMySurvey(props.surveyType)
                        if(res.isSubmitted){
                            if(!window.confirm('기존 검진한 내용이 있습니다\n기존 검진을 삭제하고 시작하시겠습니까?')){
                                return false
                            }
                            await SurveyService.deleteSurvey()
                        }
                        props.history.push(`/survey/${props.surveyType}`)
                    }}>시작하기</Button>
            </Grid>
        </Grid>
    )
}

export function MeasureLineChart(props) {
    const { items, level } = props.surveyRecent 

    const data = {
        labels: items.map( item => item.content),
        datasets: [
            {
                label: `라이프 사이클`,
                data: level,
                fill: true,
                backgroundColor: "rgba(0,178,245,0.2)",
                borderColor: "rgba(0,178,245,1)"
                
            }
        ]
    }

    const options = {
        layout: {
            padding: 5
        },
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    max: 50,
                    min: -50,
                    suggestedMin: -50,
                    suggestedMax: 50, 
                }
            }]
        }
    }

    return (
        <Line data={data} key={Math.random()} options={options} height={110}/>
    )
}



const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
    title: {
        marginTop: 80,
        fontSize: 26,
        letterSpacing: -2,
        fontWeight: 'bold',
        marginBottom: 20
    },
    subtitle: {
        fontSize: 26,
        letterSpacing: -2,
        marginBottom: 20
    },
    back: {
        marginTop: 30,
        '&:hover': {
            backgroundColor: '#053288',
        }
    },
    startWrap: {
        paddingTop: 95,
        paddingBottom: 95,
        border: '1px solid #ddd',
        '& .txt01': {
            fontSize: 36,
            '& span': {
                fontWeight: 'bold'
            }
        },
        '& .subtitle2': {
            fontSize: 20,
            marginTop: 15,
        },
        '& .button': {
            backgroundColor: '#0054BF',
            borderRadius: 25,
            height: 50,
            width: 180,
            color: 'white',
            fontSize: 20,
            fontWeight: 'bold',
            marginTop: 30
        },
        '& .button:hover': {
            backgroundColor: '#05318E',
        }

    },
    wrap: {
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
        marginBottom: 15
    },
    number: {
        backgroundColor : '#0054BF',
        color: 'white',
        width: 40,
        height: 40,
        borderRadius: 20,
        lineHeight: '40px',
        fontWeight: 'bold',
        marginButton: 15
    },
    button: {
        border: '2px solid #BEBEBE',
        padding: '5px 30px',
        borderRadius: 20,
        color: '#666',
        '& .MuiButton-endIcon': {
            marginRight: -15
        },
        '& .rightIcon': {
            color: '#00DD94',
            fontSize: 25,
            fontWeight: 'bold'
        }
    },
}));

export default LifeCycle
