import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PrescriptionService from '@user-service/PrescriptionService'
import DateHelper from '@helper/DateHelper'
import clsx from "clsx";

import DashNew from "./DashNew";
import DashExercise from "./DashExercise";
import DashPattern from "./DashPattern"
import DashSurvey from "./DashSurvey";
import DashMeasureRecent from "./DashMeasureRecent";
import ProfileService from "../../../services/ProfileService";
import AuthProvider from "../../../provider/AuthProvider";
import {Button} from "@material-ui/core";

const DashBoard = ({history}) => {
    const classes = useStyles();
    const [state, setState] = useState({
        isLoading: true,
        prescription: null
    })

    const fetch = useCallback(async () => {
        const id = await PrescriptionService.getLastPrescription();
        const exercises = await PrescriptionService.getExerciseStep(id)
        const data = await PrescriptionService.getResult(id)
        const { needExercise } = await PrescriptionService.needExerciseToday(id)

        // const profiles = await ProfileService.getProfiles('')
        // ProfileService.setDefaultProfile(profiles)
        
        let measureType
 
        if(data){
            const {attemptionPercent, brainactivePercent, concentrationPercent, restPercent} = data
            measureType = [{
                text: '안정',
                css: classes.bg01,
                cssName: 'bg01',
                percent: restPercent ? parseFloat(restPercent) : 0,
            },{
                text: '주의력',
                css: classes.bg02,
                cssName: 'bg02',
                percent: attemptionPercent ? parseFloat(attemptionPercent) : 0,
            },{
                text: '집중력',
                css: classes.bg03,
                cssName: 'bg03',
                percent: concentrationPercent ? parseFloat(concentrationPercent) : 0,
            }, {
                text: '뇌활성',
                css: classes.bg04,
                cssName: 'bg04',
                percent: brainactivePercent ? parseFloat(brainactivePercent) : 0,
            }]

            measureType.sort((a,b) => {
                return a.percent - b.percent
            })
        }
        
        setState({
            isLoading: false,
            prescription: data,
            measure: measureType? measureType[0] : null,
            exercises: exercises,
            needExercise: needExercise
        })
    },[])

    useEffect(() => {
        fetch().then()
    }, [fetch]);


  if(AuthProvider.isProfileSelector()){
    history.replace('/profile-selector')
    return <></>
  }

  const { prescription, isLoading, measure, exercises, needExercise } = state
    if(isLoading){
        return <></>
    }

    return (
        <React.Fragment>
            {!prescription
                ? <DashNew/>
                : <div id="cont-wrap">
                    <div className={clsx('mv', measure.cssName, measure.css)}>
                        <div className="innerbox">
                            <a href={'/measureSchedule'} className={clsx('date', classes.date)}>
                                <span>오늘은</span>
                                <h3>{DateHelper.now('M월 DD일')} {DateHelper.weekName()}</h3>
                            </a>
                            <div className={clsx("condition", classes.condition)}>
                                <div className="inner rest">
                                    <h3>{measure.text}</h3>
                                    <p>운동이 필요합니다.</p>
                                    <span>호흡을 고르게 조절하여 몸과 마음을 이완시키고 뇌에 충분한 산소를 공급하여 뇌를 건강하게 합니다.</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <DashExercise data={prescription}/>

                    <div className={clsx('sec-graph', 'sec_graph', classes.secGraph)}>
                        <DashMeasureRecent prescription={prescription}/>
                    </div>
                    <div className="sec-bpa">
                        <div className="innerbox">
                            {/*<div className="l-pattern">*/}
                            {/*    <DashPattern prescription={prescription}/>*/}
                            {/*</div>*/}
                            <div className="r-awakening" style={{width: '100%'}}>
                                <DashSurvey/>
                            </div>
                        </div>
                    </div>
                    {AuthProvider.isSuperAdmin() &&
                    <div style={{padding:20}}>
                      <a href="/text2.html" target="_blank">음성테스트</a>
                    </div>
                    }
                </div>
            }
            
        </React.Fragment>
    )
};

const useStyles = makeStyles(() => ({
    root: {
        padding: 30,
        maxWidth: 1400,
        margin: 'auto'
    },
    date: {
        '& span::before':{
            backgroundImage: `url(${require('@images/ico_date.png')})`
        }
    },
    start: {
        '& span::after': {
            backgroundImage: `url(${require('@images/ico_arr.png')})`
        }
    },
    bg01: {
        backgroundImage: `url(${require('@images/mv_calm.jpg')})`
    },
    bg02: {
        backgroundImage: `url(${require('@images/mv_attention.jpg')})`
    },
    bg03: {
        backgroundImage: `url(${require('@images/mv_concentrate.jpg')})`
    },
    bg04: {
        backgroundImage: `url(${require('@images/mv_active.jpg')})`
    },
    
    secGraph: {
        backgroundImage: `url(${require('@images/sec_graph.jpg')})`,
    },
    condition: {
        '& .inner.rest h3::before' : {
            backgroundImage: `url(${require('@images/ico_calm.png')})`,
        },
        '& .inner.attention h3::before' : {
            backgroundImage: `url(${require('@images/ico_attention.png')})`,
        },
        '& .inner.concentration h3::before' : {
            backgroundImage: `url(${require('@images/ico_concentrate.png')})`,
        },
        '& .inner.active h3::before' : {
            backgroundImage: `url(${require('@images/ico_active.png')})`,
        },
    }



}));

export default DashBoard;
