import React, {useState, useRef, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import AuthProvider from '@user-provider/AuthProvider'

import { makeStyles } from '@material-ui/core/styles';
import {Box, Button, Typography, Grid} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import MemberService from '@user-service/MemberService'
import clsx from "clsx";
import UserIdTextField from "../login/UserIdTextField";
import PasswordTextField from "../login/PassTextField";
import BridgeProvider from "../../provider/BridgeProvider";

const DeviceLogin = (props) => {

    const classes = useStyles();
    const [isAuthUser, setAuthUser] = useState(false)
    const [ableNext, setAbleNext] = useState(false)
    const [loginResult, setLoginResult] = useState('')

    const idRef = useRef()
    const passwordRef = useRef()
    const history = useHistory()
    
    useEffect(() => {
        BridgeProvider.instance.closeLoading()
    }, [])

    const loginHandler = async () => {

        const result = await MemberService.login({
            loginID: idRef.current.value,
            password: passwordRef.current.value,
            deviceID: null,
        })

        if(!result.success){
            alert(result.message)  
            return
        }

        AuthProvider.setCookie(result.data);

        await MemberService.registerDevice({
            memberID: result.data.memberID,
            deviceSerial: props.deviceSerial,
            computerMac: props.computerMac,
            returnUrl: props.returnUrl
        });

        if(props.registerCompleteHandler){
            props.registerCompleteHandler()
        }
    }

    const checkUserId = async () => {
        const isAuthedUser = await MemberService.checkUserId(idRef.current.value)
        if(isAuthedUser){
            setAuthUser(true)
        } else {
            history.push('/join',{
                from: 'device',
                loginID: idRef.current.value,
                deviceSerial: props.deviceSerial,
                computerMac: props.computerMac,
                returnUrl: props.returnUrl
            })
        }
    }

    return (
        <React.Fragment>
            <Box className={classes.background} />
            <Box className={classes.loginBoxWrap}>
                <Box className={classes.loginBox}>
                    <Box mb={3}>
                        <img src={"https://brhw.kr/images/logo.png"} alt={'브레인헬스 로고'}/>
                    </Box>
                    <Typography className={classes.title}>
                        처음 연결된 <span style={{fontWeight: 600}}>BRHW</span>입니다.
                        <br/><span style={{fontWeight: 600}}>아이디</span>로 연결하세요.
                    </Typography>
                    
                    <Box style={{marginTop: 40, marginBottom: 100}}>
                        <Grid container direction={'column'} spacing={4}>
                            <Grid item xs={12}>
                                <UserIdTextField
                                    ref={idRef}
                                    placeholder={'아이디를 입력하세요'}
                                    next={async () => {
                                        await checkUserId()
                                    }}
                                    update={(isValidate) => {
                                        if('' !== loginResult){
                                            setLoginResult('')
                                        }

                                        if(isAuthUser){
                                            setAuthUser(false)
                                            passwordRef.current.value = ''
                                        }
                                        
                                        setAbleNext(isValidate)
                                    }}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Collapse in={isAuthUser}>
                                    <PasswordTextField
                                        ref={passwordRef}
                                        focus={isAuthUser}
                                        placeholder={'비밀번호를 입력하세요'}
                                        valid={(isValid, value) => {
                                            setAbleNext((3 < value.length && isValid))
                                        }}
                                        next={async () => {
                                            await loginHandler()
                                        }}
                                    />
                                </Collapse>
                                {'' !== loginResult &&
                                <Box color={'red'} mt={1} textAlign={'center'}>{loginResult}</Box>
                                }
                            </Grid>
                        </Grid>
                        <Grid container style={{marginTop: 60}}>
                            <Grid item xs={12}>
                                <Button
                                    disabled={!ableNext}
                                    onClick={!isAuthUser ? checkUserId.bind(this) : loginHandler.bind(this) }
                                    className={clsx(classes.registerButton, {
                                        [classes.nextAble]: ableNext,
                                        [classes.next]: !ableNext
                                    })}>{isAuthUser ? '로그인' : '다음'}</Button>

                                <Box className={classes.findIdPassword}
                                     onClick={() => {
                                         // history.push('/login-help')
                                         history.push('/reset-password')
                                     }}>
                                    비밀번호가 기억나지 않아요
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}



const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center"
    },
    logo: {
        position: 'fixed',
        left: 30 ,
        top: 22,
        zIndex: 0,
        width: `100%`,
    },
    title: {
        fontSize: 36,
        fontFamily: 'Noto Sans KR',
        fontWeight: 300,
        marginBottom: 75,
        color: '#333',
        letterSpacing: '-2px'
    },
    requestButton: {
        backgroundColor: '#8799AD',
        color: '#fff',
        height: 35,
        borderRadius: 10,
        marginTop: 10,
        paddingLeft: 10
    },
    buttonIcon: {
        position: 'absolute',
        left: 20,
        top: 'center',
        zIndex: 1,
        width: 32,
        height: 32
    },
    buttonIconRight: {
        position: 'absolute',
        right: 20,
        top: 'center',
        zIndex: 1,
        width: 32,
        height: 32
    },
    registerButton: {
        position: 'relative',
        width: '100%',
        height: 60,
        borderRadius: 10,
        color: '#333',
        fontSize: 19,
    },
    kakaoTalk: {
        background: '#FFEB03',
        color: '#3E313A',
        border: `1px solid #FFEB0`,
        '&:hover': {
            backgroundColor: '#CBBB09',
        }
    },
    phone: {
        background: '##fff',
        color: '#333',
        border: `1px solid #0052C1`
    },
    next: {
        backgroundColor: '#ddd',
        color: '#999'
    },
    nextAble: {
        backgroundColor: '#0053BF',
        color: 'white',
        '&:hover': {
            backgroundColor: '#04308E',
        }
    },
    formBox: {
        marginBottom: 60
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
        backgroundImage: `url(${require(`@assets/images/simplebg01.jpg`)})`,
        backgroundSize: 'cover'
    },
    loginBoxWrap: {
        top: 110,
        left: `calc(50% - 250px)`,
        // transform: `translate(-50%, -50%)`,
        position: 'absolute',
        width: 500,
        zIndex: 2,
    },
    loginBox: {
        width: '100%',
        minHeight: 670,
        borderRadius: 10,
        backgroundColor: '#fff',
        padding: 50,
        paddingTop: 75,
        marginBottom: 110
    },
    bottom: {
        position: "absolute",
        bottom: 140,
        width: 400,
        color: '#333',
        fontSize: 14,
        textAlign: 'center'
    },
    textField: {
        backgroundColor: '#ddd'
    },
    findIdPassword: {
        textDecoration: 'underline',
        width: '100%',
        textAlign: 'center',
        marginTop: 20,
        color: '#999',
        cursor: 'pointer'
    }
}));


export default DeviceLogin
