import Axios from 'axios';

export default class CenterService {
    
    static async getCenter(name,  limit = 0){
        try{
            const result = await Axios.get('/api/manage/center',{
                params: {
                    name: name ? name : '',
                    limit
                }
            })
            return result.data

        }catch{}
        return []
    }

    static async isOwner(){
        try{
            const result = await Axios.get('/api/manage/center/owner')
            return !!result.data

        }catch{}
        return []
    }
    
    /**
     * return
     * @param name
     * @param centerID
     * @returns {Promise<*[]|any>}
     */
    static async getCenterCustomers(centerID, name){
        try{
            const result = await Axios.get(`/api/manage/center/customers/${centerID}`,{
                params: {
                    name: name ? name : ''
                }
            })
            return result.data

        }catch{}
        return []
    }

    /**
     * return
     * @param staffMemberID
     * @param name
     * @returns {Promise<*[]|any>}
     */
    static async getStaffCustomers(staffMemberID, name){
        try{
            const result = await Axios.get(`/api/manage/center/customers/staff/${staffMemberID}`,{
                params: {
                    name: name ? name : ''
                }
            })
            return result.data

        }catch{}
        return []
    }
}