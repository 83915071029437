import React, {useEffect, useState, useCallback} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Grid,
    Card,
    CardContent,
    Button,
} from '@material-ui/core';

import { green , blue, red } from '@material-ui/core/colors';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import DateHelper from '@helper/DateHelper'
import PrescriptionService from '@user-service/PrescriptionService'
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';

import DoneIcon from '@material-ui/icons/Done';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import clsx from "clsx";

import AuthProvider from "@user-provider/AuthProvider";
import CustomizedDialogs from "@user-components/CustomDialog";
import MeasureSelectDialog from "../../mypage/measureSelect/MeasureSelectDialog";
import FirstMeasureMessage from "../../../components/FirstMeasureMessage";
import CustomerSettingDialog from "../customer/CustomerSettingDialog";

const RequestPrescript = (props) => {
    const {simpleRender} = props
    const [noiseCode] = useState('A')
    return simpleRender 
        ? <SimpleRender {...props} noiseCode={noiseCode} /> 
        : <DefaultRender {...props} noiseCode={noiseCode}/>
}

const SimpleRender = ({history, noiseCode}) => {
    return (
        <MeasureSelectBox history={history} noiseCode={noiseCode}/>         
    )
}

const DefaultRender = ({history, noiseCode}) => {
    return (
        <div id="cont-wrap">
            <div className="sv">
                <div>
                    <h3>뇌기능 측정</h3>
                    <span>EEG, 균형상태, 의식패턴을 통해 뇌의 기능을 측정합니다.</span>
                    <i>Brain Health</i>
                </div>
            </div>
            <div className="cont">
                <Box mt={10} style={{margin: 'auto'}}>
                    <MeasureSelectBox history={history} noiseCode={noiseCode}/>
                </Box>
            </div>
        </div>
    )
}
//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Skeleton variant="text" />
            <Skeleton variant="rect" width={210} height={118} />
        </Box>
    )
}

export function MeasureSelectBox(props){
    const [open, setOpen] = useState(false)
    const [openSetting, setOpenSetting] = useState(false)
    const [openWithStream, setOpenWithStream] = useState({
        open: false,
        measureType: '',
        requestID: 0,
    })
    
    const [state, setState] = useState({
        prescription: null,
        request: null,
        isFirstMeasure: false
    })
    
    const fetch = useCallback(async () => {
        let prescription = await PrescriptionService.getLatest();

        if(!prescription){
            setState({
                prescription: {},
                request: null,
                isFirstMeasure: true
            })
            return
        }

        const request = await PrescriptionService.getReadyRequests(prescription.id)
        // console.log(request.map((r) => r.MEASURE_TYPE));
        setState({
            prescription: !prescription ? {} : prescription,
            request,
            isFirstMeasure: false
            
        })
    }, [])
    
    
    useEffect(()=>{
        fetch().then()
    },[fetch])
    
    const { prescription, request, isFirstMeasure } = state
    
    const requestPrescript = async (measureType) => {
        const res = await PrescriptionService.requestPrescript(prescription.id, measureType, AuthProvider.getCustomerProfileID())
        await fetch()
        
        setOpenWithStream({
            open: true,
            measureType,
            requestID: res.id
        });
    }

    const measureNew = async (measureTypes) => {
        await PrescriptionService.updateStatus(prescription.id, 'eeg', '40','C','done')
        await PrescriptionService.newPrescription(measureTypes)
        fetch().then(() => {setOpen(false)})
    }

    const newPrescription = () => {
       setOpen(true)
    }
    
    const closeDialog = () => {
        setOpen(false)
    }

    if(!prescription){
        return <LoadingSkeleton />
    }
    
    return (
        <Box>
            <Box mb={1}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant={'h5'}>{prescription.id}회차 측정</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={1} justify={'flex-end'}>
                            {AuthProvider.isSuperAdmin() &&
                            <Grid item>
                                <IconButton aria-label="delete" style={{padding: 7, border: `1px solid #BEBEBE`}} onClick={() => setOpenSetting(true)}>
                                    <SettingsIcon/>
                                </IconButton>

                                <CustomerSettingDialog
                                    open={openSetting}
                                    memberID={AuthProvider.getCustomerMemberID()}
                                    profileID={AuthProvider.getCustomerProfileID()}
                                    profileName={''}
                                    onClose={() => {setOpenSetting(false)}}/>

                            </Grid>
                            }
                            <Grid item>
                                <Button
                                    onClick={newPrescription}
                                    className={'common-button'}
                                    endIcon={<ChevronRightIcon className={'rightIcon'} />}
                                >신규측정</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            {isFirstMeasure
                ? <FirstMeasureMessage/>
                :
                <Grid container spacing={3} alignItems={"center"} alignContent={"center"} style={{marginTop: 20}}>
                    <Grid item>
                        <MeasureType
                            name="EEG 측정"
                            description="좌/우 뇌상태를 3D FFT로 측정."
                            color={'blue'}
                            background={blue[100]}
                            isMeasure={prescription.isEEG}
                            countMeasure={prescription.countEEG}
                            prescription={prescription}
                            isRequest={request.eeg}
                            requestID={request.eegRequestID}
                            requestDate={request.eegRequestDate}
                            measureDate={prescription.measureDateEEG}
                            handler={async () => {
                                await requestPrescript('eeg')
                            }}/>
                    </Grid>
                    <Grid item>
                        <MeasureType
                            name="균형상태 측정"
                            description="안정, 주의력, 집중력 상태를 측정"
                            color={'green'}
                            background={green[100]}
                            isMeasure={prescription.isBalance}
                            countMeasure={prescription.countBalance}
                            prescription={prescription}
                            isRequest={request.default}
                            requestID={request.defaultRequestID}
                            requestDate={request.defaultRequestDate}
                            measureDate={prescription.measureDateBalance}
                            handler={async () => {
                                await requestPrescript('default')
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <MeasureType
                            name="뇌기능 의식패턴"
                            description="뇌기능 의식패턴을 측정"
                            color={'red'}
                            background={red[100]}
                            isMeasure={prescription.isBrainActive}
                            countMeasure={prescription.countBrainActive}
                            prescription={prescription}
                            isRequest={request.meditation}
                            requestID={request.meditationRequestID}
                            requestDate={request.meditationRequestDate}
                            measureDate={prescription.measureDateBrainActive}
                            handler={async () => {
                                await requestPrescript('meditation')
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <MeasureType
                            name="항상성 자기조절"
                            description="뇌기능 의식패턴을 측정"
                            color={'red'}
                            background={red[100]}
                            isMeasure={prescription.isMeasureBandTest}
                            countMeasure={'yes' === prescription.isMeasureBandTest ? 1 : 0}
                            prescription={prescription}
                            isRequest={request ? request.measureBandTest : null}
                            requestID={request ? request.measureBandTestRequestID : null}
                            requestDate={request ? request.measureBandTestRequestDate : null}
                            measureType={'measureBandTest'}
                            handler={async () => {
                                await requestPrescript('measureBandTest')
                            }}
                        />
                    </Grid>
                </Grid>
            }
            <MeasureSelectDialog
                open={open}
                title={'신규측정'}
                close={() => {
                    closeDialog()
                }}
                submit={async (measureList) => {
                    closeDialog()
                    await measureNew(measureList)
                }}
                buttonName={'신규 측정 생성'}
            />
            
            <CustomizedDialogs
                key={`ga-${Math.random()}`}
                open={openWithStream.open}
                title={'측정 요청'}
                content={
                    <Typography gutterBottom>
                        {'meditation' === openWithStream.measureType || 'measureBandTest' === openWithStream.measureType
                            ? `측정 요청이 완료되었습니다.`
                            : <span>측정 요청이 완료되었습니다. <br/>실시간 보기를 누르면 측정 내역을 실시간으로 볼 수 있습니다.</span>
                        }
                    </Typography>
                }
                close={() => {
                    setOpenWithStream({
                        ...openWithStream,
                        open: false,
                    })
                }}
                submit={() => {
                    switch(openWithStream.measureType){
                        case 'eeg':
                            props.history.push(`/measureBand/${prescription.id}?rq=${openWithStream.requestID}`)
                            break
                        case 'default':
                            props.history.push(`/measure/${prescription.id}?rq=${openWithStream.requestID}`)
                            break
                        // case 'meditation':
                        //     props.history.push(`/exercise/meditationTest/${prescription.id}?rq=${openWithStream.requestID}`)
                        //     break;
                        default:
                    }
                    setOpenWithStream({
                        ...openWithStream,
                        open: false,
                    })
                }}
                cancel={() => {}}
                cancelButtonName={'닫기'}
                buttonName={
                    'meditation' === openWithStream.measureType || 'measureBandTest' === openWithStream.measureType
                    ? `확인`
                    : `실시간 함께 보기`
                }
            />
        </Box>
    )
}

//측정
export function MeasureType(props) {
    const classes = useStyles();
    const { isRequest, requestDate, measureDate } = props
    return (
        <Card className={classes.card} elevation={0}>
            <CardContent >
                <Box display="flex" className={classes.cardBody} alignItems="center">
                    <Box width="100%">
                        <Box display="flex" alignItems="center" className={classes.statusIcon} justifyContent={'center'} mb={3}>
                            {'yes' === props.isMeasure &&
                                <DoneIcon className={clsx('icon', 'done')}/>
                            }
                            {'yes' !== props.isMeasure &&
                                <PriorityHighIcon className={clsx('icon', 'yet')}/>
                            }
                        </Box>
                        <Typography gutterBottom className={'title'} component="h2" align="center">
                            {props.name}
                        </Typography>
                        <Typography variant="body2" className={'desc'} align="center">
                            {props.description}
                        </Typography>

                        <Box display="flex" alignItems="center" justifyContent={'center'} mt={5}>
                            <Box>
                                <Button
                                    size={"large"}
                                    variant={'outlined'}
                                    style={{width: 200}}
                                    onClick={props.handler}
                                >
                                    <div>측정 요청</div>
                                </Button>
                                
                                <div style={{textAlign: 'center'}}>
                                    {isRequest
                                        ? `요청일: ${DateHelper.format(requestDate, 'YYYY.MM.DD')}`
                                        : ''
                                    }
                                </div>
                            </Box>
                        </Box>
                    
                        <Box mt={2} className={clsx(classes.measureResult , {
                            [classes.measureDone] : 'yes' === props.isMeasure,
                        })}>
                            <Typography variant={'h6'} className={'subtitle'} component="p" align="center">
                                {props.countMeasure}회 측정
                            </Typography>
                            <Typography variant="body2" className={'subdesc'} component="p" align="center">
                                {'yes' === props.isMeasure ? DateHelper.format(measureDate, 'YYYY.MM.DD') : '측정이 필요합니다'}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}

const useStyles = makeStyles(theme => ({
    root: {

    },
    headerBox: {
        marginBottom: 40,
        marginTop: 120
    },
    title: {
        fontSize:26,
        fontWeight: 'bold'
    },
    card: {
        minWidth: 302,
        backgroundColor: '#F5F9FE',
        borderRadius: 30,
        border: `1px solid #D6E1F1`,
        // cursor: 'pointer',
        // '&:hover' : {
        //     backgroundColor: '#E1E5EC'
        // },
        '& .title': {
            fontSize: 37,
            fontWeight: 'bold',
            letterSpacing: -2
        },
        '& .desc' : {
            marginTop: -15
        }
    },
    disableMeasure: {
        // backgroundColor: '#ededed',
        borderRadius: 30,
        border: `0px solid #D6E1F1`,
        cursor: 'default',

        '& .title': {
            fontWeight: 'bold',
            color: '#999'
        },
    },
    statusIcon: {
        border: '5px solid #DCE8F5'  ,
        width: 85,
        height: 85,
        margin: 'auto',
        borderRadius: '50%',
        marginBottom: 30,

        '& .yet': {
            color: '#F34821',
            fontSize: 80,
            fontWeight: 'bold',
        },
        '& .yetDisabled': {
            color: '#999',
            fontSize: 80,
            fontWeight: 'bold',
        },
        '& .done': {
            color: '#00DF97',
            fontSize: 100,
            fontWeight: 'bold',
            marginTop: -30,
            marginLeft: 30
        }
    },
    measureRequest: {
        backgroundColor: '#F34821',
        color: '#fff !important',
    },
    measureResult: {
        color: '#333',
        padding: 10,
        borderRadius: 30,
        maxWidth: 300,
        margin: 'auto',
        marginTop: 50,
        fontSize: 26,
        letterSpacing: -2,
        // minHeight: 110,
        '& .subtitle': {
            fontWeight: 'bold'
        },
        '& .subdesc': {
            fontWeight: 'normal',
            fontSize: 18
        }
    },
    measureDone: {
        backgroundColor: '#00DF97',
        color: '#fff !important',
    },
    cardBody: {
        height: 450
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));


export default RequestPrescript
