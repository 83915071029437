import Axios from 'axios';

export default class MemberService {

    static async getAdminMember(name,  limit = 0){
        try{
            const result = await Axios.get('/api/manage/members/admin',{
                params: {
                    name: name ? name : '',
                    limit
                }
            })
            return result.data

        }catch{}
        return []
    }

    /**
     * 계정 삭제
     * @param profileID
     * @returns {Promise<void>}
     */
    static async deleteMember(profileID) {
        try{
            const results = await Axios.delete(`/api/manage/customer/${profileID}`)
            return results.success
        }catch{}
        
        return false
    }

}