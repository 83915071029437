import React, {useEffect} from "react";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ReactAudioPlayer from "react-audio-player";
import {Button,Typography} from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import clsx from "clsx";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={clsx('common-dialog-close', classes.closeButton)}   onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export function StartDialog(props) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(()=>{
        setOpen(props.open)
    },[props.open])

    return (
        <Dialog
            disableBackdropClick
            fullScreen={fullScreen}
            open={open}
            maxWidth={'xl'}
            style={{borderRadius: 30}}
            aria-labelledby="responsive-dialog-title"
            className={'innerViewport'}
        >
            <DialogTitle  className="common-dialog-title" onClose={() => {
                // props.history.replace('/measureSelect')
                props.history.goBack()
            }}>
                EEG 측정
            </DialogTitle>
            <DialogContent className={'common-dialog-content'}>
                뇌 기능 측정을 시작하겠습니다. <br/>
                <br/>
                긴장하거나 움직이지 마시고 편안한 <br/>
                마음으로 화면을 바라보시기 바랍니다. <br/>
                눈을 뜬 상태로 뇌 기능 측정을 진행합니다. <br/>
                <br/>
                <ReactAudioPlayer
                    autoPlay
                    controls
                    src={require('@audio/01-01.mp3')}
                    onEnded={()=>{
                        if(props.onHide){
                            props.onHide('A')
                        }
                    }}
                />
            </DialogContent>
            {false !== props.disableButton &&
            <DialogActions className={'common-dialog-action'}>
                <Button
                    autoFocus
                    fullWidth={true}
                    endIcon={<ArrowForwardIosIcon/>}
                    onClick={() => {
                        if (props.onHide) {
                            props.onHide('A')
                        }
                    }}>
                    측정시작
                </Button>
            </DialogActions>
            }
        </Dialog>
    );
}

export default StartDialog