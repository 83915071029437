
import {Box} from "@material-ui/core";
import React from "react";

import MeasureBoxBar from '@user-components/MeasureBoxBar'

export default function MeasureState(props) {
    const maxWidth = props.maxWidth ? props.maxWidth : 1280  
    const boxHeight = props.boxHeight ? props.boxHeight : 26
    const boxWidth = props.boxWidth ? props.boxWidth : 20
    return (
        <Box mt={6} justifyContent="center" m={'auto'} style={{width: '100%', maxWidth}}>
            <MeasureBoxBar title={'안정 상태'} value={props.rest} width={boxWidth} height={boxHeight} color={"blue"}  data={props.measureRest}/>
            <MeasureBoxBar title={'주의력 상태'} value={props.attemption} width={boxWidth} height={boxHeight} color={"green"}  data={props.measureAttemption}/>
            <MeasureBoxBar title={'집중력 상태'} value={props.concentration} width={boxWidth} height={boxHeight} color={"red"}  data={props.measureConcentration}/>
            <MeasureBoxBar title={'뇌활성 상태'} value={props.brainactive} width={boxWidth} height={boxHeight} color={"purple"}  data={props.measureBrainactive}/>
        </Box>
    )
}
