
// 실시간 측정 페이지
import React, {useEffect, useRef} from "react";

import FFTChart from '@user-components/FFTChartMerge';
import ColorHelper from '@helper/ColorHelper';

import SocketProvider from '@user-provider/SocketProvider';
import PrescriptionService from '@user-service/PrescriptionService'

export function RealtimeMeasureMerged(props) {

    const closeEyesTime = parseInt(process.env.REACT_APP_MEASURE_BAND_CLOSE_EYE)
    const openEyesTime = parseInt(process.env.REACT_APP_MEASURE_BAND_OPEN_EYE)

    const fftChartOptions = {
        graphWireFrame: false,
        graphWireFrameColor: 0x666666,
        hzSpacing: 10,
        hzCount: 60,   // 10 spacing
        cone: false,
        gridSize: 0.7,
        mvSize: 0.3,
        timeHzGrid: true,
        timeMvGrid: true,
        mvHzGrid: true,
        timeGuide: true,
        graphColors: ColorHelper.getColors(),
        height: 900,
        brainType:'both',
        cameraType: 'side',
        noiseCode: props.noiseCode ? props.noiseCode : 'A'
    };
    

    const fftChartCh1Ref = useRef();

    //기기에서 측정된 데이터를 받을 경우
    SocketProvider.onMeasure = (data) => {
        try {
            fftChartCh1Ref.current.addData(data.time, data.ch1FFT, data.ch2FFT);
            
            if( closeEyesTime === data.time ) {
                if(props.onCloseEyes){
                    props.onCloseEyes()
                }
            }

            if( openEyesTime  === data.time ) {
                if(props.onOpenEyes){
                    props.onOpenEyes()
                }
            }

        } catch(e) {}
    }
    useEffect(()=>{
        fftChartCh1Ref.current.initRender()
    },[])

    //측정 완료메세지를 받으면 
    SocketProvider.onMeasureStop = async (data) => {
        
        if(SocketProvider.isBRHWService){
            await PrescriptionService.updateStatus(props.prescriptionID, 'eeg', '40','C', 'measure-completed')
            await PrescriptionService.getUpdateRequest(props.requestID)
            await saveImage(data);
        }
        
        if(props.setEndDialog){
            props.setEndDialog(true)
        }
    }

    const saveImage = async ({fileKey}) => {
        try {
            const ch1Image = fftChartCh1Ref.current.saveCanvasImage();

            const params = {
                ch1Image,
                fftType:'merged',
                fileKey
            }
            await PrescriptionService.fileUploadFFT(props.prescriptionID, params);
        } catch(e) {}
    }

    return (
        <FFTChart
            options={ fftChartOptions } 
            ref={ fftChartCh1Ref }/>
    )
}

export default RealtimeMeasureMerged
