import React, {useCallback, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import queryString from "query-string";
import MemberService from "../../services/MemberService";
import AuthProvider from "../../provider/AuthProvider";
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";

const AppWaiting = () => {    
    const classes = useStyles()
    const history = useHistory()
    const [, setCookie] = useCookies([AuthProvider.userCookieName])
    
    const qs = queryString.parse(window.location.search)
    
    const login = useCallback(async (token, serviceType) => {
        const result = await MemberService.loginByToken(token)
        
        result.data.serviceType = serviceType
        
        const cookie = await AuthProvider.setCookieWithProfile(result.data)
        setCookie(AuthProvider.userCookieName, cookie , AuthProvider.getCookieOption())

        if('admin' === AuthProvider.getAppServicePage()){
            history.replace('/manage/customer')
        } else {
            history.replace('/profile')
        }
        
        setTimeout(() => {
            if(window.APPBRHW){
                window.APPBRHW.IFCloseLoading()
            }        
        }, 1300)
        
    }, [setCookie, history])
    
    useEffect(()=>{
        const token = qs.t
        const serviceType = qs.serviceType
        
        if(token){
            login(token, serviceType).then()    
        }
    },[qs.t, qs.serviceType, login])
    

    return (
        <Box className={classes.root}>
            
            {/*<Box className={classes.text} >*/}
            {/*    브레인 헬스를 시작하고 있습니다 <br/>*/}
            {/*    잠시만 기다려 주세요*/}
            {/*</Box>*/}
            {/*<div className={classes.wrap} />*/}
            {/*<div className={classes.videoWrap}>*/}
            {/*    <video src={'https://d2iajcsshqwtw3.cloudfront.net/assets/loginBackground.webm'}*/}
            {/*           autoPlay*/}
            {/*           loop*/}
            {/*           muted*/}
            {/*           style={{width: '100%'}}*/}
            {/*    />*/}
            {/*</div>*/}
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    subTitle: {
        fontSize: 22
    },
    root: {
        overflow: 'hidden'
    },
    text: {
        position: 'absolute',
        left: 0,
        top: window.innerHeight / 2 - 100 ,
        zIndex: 3,
        color: 'white',
        width: '100%',
        textAlign: 'center',
        fontSize: 40,
        fontWeight: 500,
        marginBottom: 0,
        letterSpacing: -3
    },
    wrap : {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: window.innerHeight,
        background: '#000',
        zIndex: 1,
        opacity: 0.7,
    },
    videoWrap: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: window.innerHeight,
        background: '#100d14',
        zIndex: 0
    }
}));

export default AppWaiting

