import Axios from 'axios';
import Moment from 'moment-timezone'
import AuthProvider from "../provider/AuthProvider";

export default class ProfileService {

    static async getProfiles(keyword){
        let profiles = []
        
        try{
            const result = await Axios.get(`/api/profiles?keyword=${keyword}`)
            const { maxProfileCount } = result.data
            profiles = result.data.profiles
            
            if(maxProfileCount > profiles.length){
                const loop = maxProfileCount - profiles.length
                for(let i = 0 ; i < loop ; i++){
                    profiles.push({
                        id: 'new-' + parseInt(Math.random() * 100),
                        isNew: true
                    })
                }
            }
            
        }catch{}
        return profiles
    }

  static async getProfilesSelector(keyword, centerID){

    try{
      const result = await Axios.get(`/api/profiles/selector?keyword=${keyword}&centerID=${centerID}`)
      return result.data
  
    }catch{}
    return []
  }

  static async getMemberToken(memberID){
    try{
      const result = await Axios.get(`/api/profile/${memberID}/get-token`)
      return result.data
    }catch{}
    
    return undefined
  }
  
    static setDefaultProfile(profiles){
        if(profiles){
            profiles.map((profile) => {
                if(profile.id === AuthProvider.getProfileID()){
                    AuthProvider.set('profileID', profile.id)
                    AuthProvider.set('profileName', profile.name)
                    AuthProvider.set('code', profile.code)
                    AuthProvider.set('image', profile.image)
                    AuthProvider.set('birthDate', profile.birthDate)
                }
                return profile
            })
        }
    }
    
    static async getAvatars(){
        let result
        try {
            const res = await Axios.get('/api/profile/avatar')
            result = res.data.avatars
        } catch{}
        
        return result
    }

    static async updateAvatar(profileID, fileName){
        let result = {
            success: false
        }
        try {
            await Axios.put(`/api/profile/${profileID}/avatar`,{
                fileName
            })
            result.success = true
        } catch{}

        return result
    }
    
    /**
     * 하나의 프로파일 정보를 가져옵니다
     * @param {*} profileID
     * @param withExt
     */
    static async getProfile(profileID, withExt = false){
        let result = {
            success: true,
            data: {
                "id": 19,
                "name": "이승헌",
                "image": null,
                "birthDate": '1981-03-14',
                "type": "children"
            }
        }
        
        try{
            result = await Axios.get('/api/profile/' + profileID,{
                params: {
                    withExt
                }
            })
        }catch(e){
            //to do
            let message = ''
            switch(e.response.status){
                case 400:
                     message = '측정기 정보가 잘못되었습니다.'
                     break
                case 404:
                    message = "사용자 정보를 찾을수 없습니다."
                    break;
                default:
                    message = '오류가 발생했습니다.'
            }
            result.message = message
        }
        
        return result
    }

    /**
     * 프로필을 생성합니다.
     * @param {*} profile 
     */
    static async addProfile(profile){
        profile.birthDate = Moment(profile.birthDate).format('YYYY-MM-DD')
        try{
            const result = await Axios.post('/api/profile', profile)

            if(204 === result.status){
                return true
            }
        }catch(e){
            //to do
        }

        return false
    }

    /**
     * 프로필을 수정합니다.
     * @param {*} profile 
     */
    static async updateProfile(profile){
        try{
            const result = await Axios.put('/api/profile/' + profile.id , profile)

            if(204 === result.status){
                return true
            }
        }catch(e){
            //to do
        }

        return false
    }


    /**
     * 프로필 삭제합니다.
     * @param {*} profileID 
     */
    static async deleteProfile(profileID){
        try{
            const result = await Axios.delete('/api/profile/' + profileID)

            if(204 === result.status){
                return true
            }
        }catch(e){
            //to do
        }

        return false
    }


    /**
     * 현재 선택한 프로필을 서버로 전달합니다.
     * @param {*} profileID
     */
    static async setCurrent(profileID, deviceId){
        try{
            const result = await Axios.put('/api/profile/choice',{
                id: profileID,
                deviceId: deviceId
            })

            if(204 === result.status){
                return true
            }

        }catch(e){}

        return false
    }

    /**
     * return managers
     * @returns {Promise<*[]|any>}
     */
    static async getManager(){
        try{
            const result = await Axios.get('/api/profile/manager',)
            return result.data

        }catch(e){}

        return []
    }

    /**
     * delete manager 
     * @param id
     * @returns {Promise<boolean>}
     */
    static async deleteManager(id){
        try{
            const result = await Axios.delete(`/api/profile/manager/${id}`)

            if(204 === result.status){
                return true
            }
        }catch(e){}

        return false
    }

    /**
     * add manager
     * @param code
     * @returns {Promise<boolean>}
     */
    static async addManager(code){
        try{
            const result = await Axios.post(`/api/profile/manager`,{
                code
            })

            if(204 === result.status){
                return true
            }
        }catch(e){}

        return false
    }
    
    static async setLastProfileName(profileSelectorID, profileID, profileName) {
      try{
        await Axios.put(`/api/profile/selector/last-profile-name`,{
          profileName,
          profileID,
          profileSelectorID
        })
      }catch(e){}
    }

  static async getLastProfileDone() {
    try{
      const res = await Axios.get(`/api/profile/selector/last-profile-by-done`)
      return res.data.profiles || []
    }catch(e){}
    
    return [];
  }

  static async updateGender(profileID, gender) {
    try{
      await Axios.put(`/api/profile/${profileID}/gender`,{
        gender
      })
    }catch(e){}

  }
}
