import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateHelper from '@helper/DateHelper'
import clsx from "clsx";

const DashNew = () => {
    const classes = useStyles();
    return (
        <div id="cont-wrap" style={{height: '100%'}}>
            <div className={'innerViewport'} style={{height: '100%'}}>
                <div className={clsx('mv', 'default', classes.default)}>
                    <div className="innerbox">
                        <a href="/measureSchedule" className={clsx('date', classes.date)}>
                            <span>오늘은</span>
                            <h3>{DateHelper.now('M월 DD일')} {DateHelper.weekName()}</h3>
                        </a>
                        <div className="condition">
                            <div className="inner">
                                <h3>측정된 뇌파가 없습니다.</h3>
                                <h5>관리자 또는 담당자에 연락 후 측정를 요청하세요.</h5>
                            </div>
                            {/*<a href="#" className={clsx('bt-start', classes.start)}><span>측정하러가기</span></a>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    default: {
        backgroundImage: `url(${require('@images/mv_default.jpg')})`,
        height: 'calc(100% - 80px)'
    },
    start: {
        '& span::after': {
            backgroundImage: `url(${require('@images/ico_arr.png')})`
        }
    },
    date: {
        '& span::before':{
            backgroundImage: `url(${require('@images/ico_date.png')})`
        }
    },

}));

export default DashNew;
