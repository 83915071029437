import React from 'react';
import { GridGenerator, HexGrid, Layout, Hexagon, Text } from 'react-hexgrid';
import { Grid, Box, Button } from '@material-ui/core';
import '@user-style/hexgrid.css';

export default class BrainHexMap extends React.Component {
    
    maxHex = 60
    constructor(props) {
        super(props);

        const hex = []
        for (let i = 0 ; i < this.maxHex; i++){
            hex.push(i)
        }
            

        this.state = {
            measureCoord: new Array(hex.length).fill({measureType:'', value:0}),
            hexas: GridGenerator.hexagon(4),
            ableHex: hex,
            timeCount: 0,
            rest: 0,
            attemption: 0,
            concentration: 0,
            restAvg: new Array(4).fill(0),
            attemAvg: new Array(4).fill(0),
            constAvg: new Array(4).fill(0),
            brainactiveAvg: new Array(4).fill(0),
            prescriptionID: props.prescriptionID,
            width: props.width ? props.width : 300,
            height: props.height ? props.height : 300
        }

    }

    //기기에서 측정된 데이터를 받을 경우
    addMeasureData = (currentStep, time, isHit, hitMv = '') => {            
        let coord = this.state.ableHex.shift()
        let measureType = 'rest'

        if(0 === currentStep) { measureType = 'rest'}
        if(1 === currentStep) { measureType = 'attemption'}
        if(2 === currentStep) { measureType = 'concentration'}
        if(3 === currentStep) { measureType = 'brainactive'}


        this.state.measureCoord[coord] = {
            measureType: isHit ? 'fill ' + measureType : measureType,
            value : isHit,
            hitMv: hitMv
        }

        this.setState({
            ableHex: this.state.ableHex,
            measureCoord: this.state.measureCoord,
            timeCount: time,
            rest: 0 === currentStep && isHit ? this.state.rest + 1 : this.state.rest ,
            attemption: 1 === currentStep && isHit ? this.state.attemption + 1 : this.state.attemption ,
            concentration: 2 === currentStep && isHit ? this.state.concentration + 1 : this.state.concentration,
            brainactive: 3 === currentStep && isHit ? this.state.brainactive + 1 : this.state.brainactive
        })

        return {
            coord,
            measureType
        }
    }

    /**
     * 이전 측정된 데이터를 화면에 표시합니다.
     * @param data
     * @param measureType
     * @param needUpdate
     */
    setHexMap(data, measureType , needUpdate = false) {
        if(!data) return;
        
        data.split(',').map((value, index) => {
            this.state.measureCoord[index] = {
                measureType: '1' === value ? 'fill ' + measureType : measureType
            }    
        })

        if(needUpdate){
           this.needUpdate();
        }
    }


    /**
     * 화면을 업데이트 합니다.
     */
    needUpdate() {
        this.setState({
            ...this.state
        });
    }

    setAvr = (type, avr) =>{
        if('rest' === type){
            this.setState({
                restAvg: avr
            })
        } else if('attemption' === type){
            this.setState({
                attemAvg: avr
            })
        } else if('concentration' === type){
            this.setState({
                constAvg: avr
            })
        } else if('concentration' === type){
            this.setState({
                brainactiveAvg: avr
            })
        }
    }

    setAvgAll = (avg) =>{
        this.setState({
            restAvg: avg
        });

    }

    render() {
        return (
            <div >
                <Box width={this.state.width} 
                    position="absolute"
                    left="5%"
                    zIndex="tooltip"
                >
                </Box>
                <HexGrid width={this.state.width} height={this.state.height} viewBox="-50 -50 100 100">
                    <Layout size={ { x: 6, y: 6 } } origin={ { x: 0, y: 0 } } flat={ false } spacing={ 1.1 }>
                    { this.state.hexas.map((hex, i) => {
                        let className = ""
                        const val = this.state.measureCoord[i]
    
                        if(val && '' !== val.measureType){
                            className = val.measureType
                        }
                        
                        return <Hexagon key={i} q={hex.q} r={hex.r} s={hex.s} className={className} />
                        })
                    }
                    </Layout>
                </HexGrid>
            </div>
        )
    }
}

export class HexDebug extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            restAvg : props.restAvg,
            attemAvg: props.attemAvg,
            constAvg: props.constAvg,
            brainactiveAvg: props.brainactiveAvg
        }
    }
    render() {
        return <Box>
            <Grid container>
                <Grid item xs={4}>
                    {/* 안정 : {this.state.rest}, { parseInt(this.state.rest / 60 * 100) }% */}
                    안정
                    {this.state.restAvg.map((avr, index) => {
                        return <div key={index+8}>{index + 8}hz : {avr}</div>
                    })}
                </Grid>
                <Grid item xs={4}>
                    주의력
                    {/* 집중력 : {this.state.attemption}, { parseInt(this.state.attemption / 60 * 100) }% */}
                    {this.state.attemAvg.map((avr, index) => {
                        return <div key={index+12}>{index + 12}hz : {avr}</div>
                    })}
                </Grid>
                <Grid item xs={4}>
                    집중력
                    {/* 주의력 : {this.state.concentration}, { parseInt(this.state.concentration / 60 * 100) }% */}
                    {this.state.constAvg.map((avr, index) => {
                        return <div key={index+16}>{index + 16}hz : {avr}</div>
                    })}
                </Grid>
                <Grid item xs={4}>
                    뇌활성
                    {/* 주의력 : {this.state.concentration}, { parseInt(this.state.concentration / 60 * 100) }% */}
                    {this.state.brainactiveAvg.map((avr, index) => {
                        return <div key={index+16}>{index + 20}hz : {avr}</div>
                    })}
                </Grid>
            </Grid>
            <br/>
            <Button variant="outlined" onClick={() => {
                window.open('/measureBalance/' + this.state.prescriptionID)
            }}>측정값 보기</Button>
        </Box>
    }
}