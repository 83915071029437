import React, {useCallback, useEffect, useState} from "react";
import {Box, Button, Grid, Typography} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";

import PrescriptionService from '@user-service/PrescriptionService';
import CustomizedDialogs from "../../../components/CustomDialog";
import ProfileService from "../../../services/ProfileService";

export default function UpdateGender(props){
  const [open, setOpen] = useState(false)
  
  useEffect(() => {
    setOpen(props.open)
  }, [props.open])
  
  return (
    <CustomizedDialogs
      open={open}
      title={`성별선택`}
      content={
        <Content change={(newValue) => {
          ProfileService.updateGender(props.profileID, newValue).then()
        }}/>
      }
      close={() => {
        setOpen(false)
        try {
          props.onCancel()
        } catch {}
        
      }}
      submit={async () => {
        try {
          props.onSave()  
        } catch {}
        
      }}
      buttonName={'확인'}
    />
    
  )
}

const Content = (props) => {
  const [gender , setGender] = useState('')
  
  useEffect(() => {
    if('' === gender) return
    if(props.change)
      props.change(gender)
    
  }, [gender])
  return (
    <React.Fragment>
      <Typography gutterBottom>
        성별을 선택해주세요
      </Typography>
      <Box mb={3} mt={3}>
        <Grid container>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup row name="customized-radios">
                <FormControlLabel
                  checked={'male' === gender}
                  value="male" control={<Radio />} label="남성" onClick={()=>{setGender('male')}} labelPlacement="end"/>
                <FormControlLabel
                  checked={'female' === gender}
                  value="female" control={<Radio />} label="여성" onClick={()=>{setGender('female')}} labelPlacement="end"/>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  )
}