import React, {useRef} from "react";
import {Box} from "@material-ui/core";

import BarChart from '@user-components/BarChart';

export default function BrainBalanceBand(props) {
    const { bandRangeData, height, width, fontSize } = props
    const barChartRef = useRef()

    const defaultData = [];
    defaultData.push(bandRangeData.ch1);
    defaultData.push(bandRangeData.ch2);


    return <Box mt="2" style={{
        width,
        height
    }}>
        <BarChart 
            ref={ barChartRef }
            data={ new Array(6).fill(0) }
            defaultData={defaultData}
            showTopValue={true}
            showTopValueTitle={true}
            topValueMargin={30}
            labels={['δ','θ','α','SMR','β', 'βM', 'βh']}
            height={height ? height : 150}
            width={width}
            fontSize={fontSize ? fontSize : 20}
            topValueFontSize={fontSize ? fontSize : 20}
            suggestedMax={30}
            grouped={true}
            groups={2}
            useColorIndex={true}
            showYAxes={true}
            colors={['#60C3F7','#A9D8AA']}
        />
    </Box>
}
