
export default class MemoryTestService {

  /**
   * 정답의 순서를 없애기 위함 
   * @param selectedItems
   * @param randomIndex
   * @returns {any[]}
   */
  static makAnswerNoneSequence(selectedItems, randomIndex) {
      const newSelectedItems = new Array(randomIndex.length).fill(null)

      try {
        const incorrectSelectedItems = []
        //정답을 배열의 위치에 추가
        for(let i = 0 ; i < selectedItems.length; i++){
          const correctIndex = randomIndex.indexOf(selectedItems[i])
          if(-1 < correctIndex && !newSelectedItems[correctIndex]){
            newSelectedItems[correctIndex] = selectedItems[i]
          } else {
            incorrectSelectedItems.push(selectedItems[i])
          }
        }

        //틀린 오답은 newSelectedItems 순서대로 추가
        for(let i = 0 ; i < incorrectSelectedItems.length; i++){
          const correctVal = incorrectSelectedItems[i]

          for(let j = 0 ; j < newSelectedItems.length; j++){
            if(null === newSelectedItems[j]){
              newSelectedItems[j] = correctVal
              break;
            }
          }
        }  
      } catch {
        return selectedItems
      }
      
      return newSelectedItems
  }
}