import React from 'react';
import {Box, Button, Grid, Paper, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import clsx from "clsx";

const svg = {
    list: 'M4 22h-4v-4h4v4zm0-12h-4v4h4v-4zm0-8h-4v4h4v-4zm3 0v4h17v-4h-17zm0 12h17v-4h-17v4zm0 8h17v-4h-17v4z',
    develop: 'M24 10.935v2.131l-8 3.947v-2.23l5.64-2.783-5.64-2.79v-2.223l8 3.948zm-16 3.848l-5.64-2.783 5.64-2.79v-2.223l-8 3.948v2.131l8 3.947v-2.23zm7.047-10.783h-2.078l-4.011 16h2.073l4.016-16z',
}
const SVG = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={props.width ? props.width : 'auto'}
            height={props.height ? props.height : 'auto'}
            viewBox="0 0 24 24"
            fill={props.color}
        >
            <path
                d={svg[props.name]}
            />
        </svg>
    );
}

export default class MeasureLevelDialog extends React.Component {
    constructor(props) {
        super();
        
        this.state = {
            open: true,
            onStart: props.onStart,
            isDevelop: false,
            answerCount: null
        }
    }
    
    open() {
        this.setState({
            ...this.state,
            open: true
        })
    }
    
    close() {
        this.setState({
            ...this.state,
            open: false
        })
    }
    
    start(measureType) {
        if(this.state.onStart){
            this.state.onStart(measureType);
        }
        this.close()
    }

    setLimitTime(limitTime) {
        this.setState({
            ...this.state,
            limitTime
        })
    }

    setDevelop() {
        this.setState({
            ...this.state,
            isDevelop: true
        })
    }
    setLevel(answerCount){
        this.setState({
            ...this.state,
            answerCount: answerCount
        })
        
        if(localStorage){
            localStorage.setItem('answerCount', answerCount)
        }
    }
    
    render() {
        return (
            <Dialog
                disableBackdropClick
                open={this.state.open}
                aria-labelledby="responsive-dialog-title"
                maxWidth={false}
                className={'level-dialog'}
            >
                <div  style={{width: 810}}>
                    <div>
                        <Grid container>
                            <Grid item xs={6} style={{height:10, backgroundColor:'#033E83'}}></Grid>
                            <Grid item xs={6} style={{height:10, backgroundColor:'#186756'}}></Grid>
                        </Grid>
                    </div>
                    <DialogTitle id="responsive-dialog-title" style={{backgroundColor: '#F5FBFD', borderTop:'0'}}>
                        <div style={{fontSize: '1.8rem', padding: '30px 20px 20px 20px', color:'#000'}}>뇌기능 검사와 두뇌계발</div>
                    </DialogTitle>
                    <DialogContent >
                            <Box display={'flex'} justifyContent={'center'} style={{padding: 100}}>
                                <img src={require(`@assets/images/bh_logo.png`)} alt={'logo'}/>
                            </Box>
                        {!this.state.isDevelop
                            ? <Grid container spacing={3} style={{maxWidth: 650, margin: '0 auto'}}>
                                <Grid item xs={6}>
                                    <Paper
                                        onClick={() => {
                                            this.start('measure')
                                        }}
                                        elevation={0}
                                        className={'measure-level-dialog measure'}
                                    >검사하기</Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper
                                        onClick={() => {
                                            // this.start('develop')
                                            this.setDevelop()
                                        }}
                                        elevation={0}
                                        className={'measure-level-dialog develop'}
                                    >두뇌계발하기</Paper>
                                </Grid>
                            </Grid>
                            :<React.Fragment> 
                                <Grid container spacing={3} style={{maxWidth: 650, margin: '0 auto'}}>
                                    <Grid item xs={4}>
                                        <Paper
                                            onClick={() => { this.setLevel('sub-level-1') }}
                                            elevation={0}
                                            className={clsx('measure-level-dialog develop ',{
                                                'level-def': 'sub-level-1' !== this.state.answerCount,
                                                'level-active': 'sub-level-1' === this.state.answerCount,
                                            })}
                                        >기초</Paper>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Paper
                                            onClick={() => { this.setLevel('sub-level-2') }}
                                            elevation={0}
                                            className={clsx('measure-level-dialog develop ',{
                                                'level-def': 'sub-level-2' !== this.state.answerCount,
                                                'level-active': 'sub-level-2' === this.state.answerCount,
                                            })}
                                        >중급</Paper>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Paper
                                            onClick={() => { this.setLevel('sub-level-3') }}
                                            elevation={0}
                                            className={clsx('measure-level-dialog develop ',{
                                                'level-def': 'sub-level-3' !== this.state.answerCount,
                                                'level-active': 'sub-level-3' === this.state.answerCount,
                                            })}
                                        >고급</Paper>
                                    </Grid>
                                </Grid>
                                <Grid container style={{maxWidth: 650, margin: '0 auto', padding: 12}}>
                                    <Grid item xs={12}>
                                        <Button
                                            onClick={() => {
                                                if(!this.state.answerCount) return
                                                this.start('develop')
                                            }}
                                            elevation={0}
                                            className={clsx('measure-level-dialog develop dev-button',{
                                                'active': null !== this.state.answerCount,
                                                'disabled' : !this.state.answerCount
                                            })}
                                            style={{width: '100%'}}
                                        >두뇌계발하기</Button>    
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                                
                            <Box display={'flex'} justifyContent={'center'} style={{padding: 30, marginTop:40, backgroundColor: '#F5FBFD'}}>
                                <div style={{textAlign: 'center'}}>
                                    <img src={require(`@assets/images/mohw_logo.png`)} alt={'logo'}/>
                                    <div style={{fontSize:'1.2rem', fontWeight:'bold', marginTop: 10}}>144호 한국뇌건강증진연합회</div>
                                    <div style={{fontSize:'1.2rem', marginTop: 6}}>www.kbhd.org/ brhw.kr</div>
                                </div>
                            </Box>
                        </DialogContent>
                </div>
            </Dialog>
        );
    }
}
