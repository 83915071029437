import React, {useEffect, useState, useCallback} from 'react';
import DateHelper from '@helper/DateHelper'
import PrescriptionService from '@user-service/PrescriptionService';
import CustomerService from "@user-service/manage/CustomerService";

import MenuItem from '@material-ui/core/MenuItem';
import { Box, Button,Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Select } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
/**
 * 처방전 선택
 * @param {*} props 
 */
export default function PrescriptionSelect(props) {
    const classes = useStyles()
    const showDate = !props.hideDate
    const [ prescriptionIDs, setPrescriptionIDs ] = useState(null)
    const history = useHistory()
    
    const fetch = useCallback(async () => {
        let res
        if(props.useCustomer){
            res = await CustomerService.getPrescriptionIds(props.profileID)
        } else {
            res = await PrescriptionService.getPrescriptionIds()
        }
        setPrescriptionIDs(res.prescriptions)
    },[props.profileID])
    
    useEffect(() => {
        fetch().then()
        
        return (() => {
            setPrescriptionIDs(null)
        })
    }, [fetch]);
    
    useEffect(() => {
        fetch().then()
    },[fetch, props.reload])
    
    if(!prescriptionIDs || 0 === prescriptionIDs.length ) return <></>
    const prescriptionID = props.prescriptionID ? props.prescriptionID : prescriptionIDs[0].id
    
    return (
        <Box className={classes.root}>
            <Select
                defaultValue={prescriptionID}
                className={classes.underline}
                onChange={(event)=>{
                    if(props.onChange){
                        props.onChange(event.target.value)
                        return
                    }
                    
                    let url = props.url
                    if(props.params){
                        props.params.map((value, index) => {
                            url = url.replace(`{${index}}` , value)
                            return value
                        })

                        history.push(url.replace(':prescriptionID', event.target.value))
                    } else {
                        history.push(props.url.replace(':prescriptionID', event.target.value))
                    }
                    
                }}>
                {prescriptionIDs.map((pre)=>{
                    return (
                        <MenuItem
                            key={pre.id}
                            value={pre.id}
                            className={clsx({
                                [classes.active]: pre.id === props.prescriptionID
                            })}
                        >
                            <span className={'title'}>{pre.id}회차</span>
                            {showDate &&
                            <span className={'sub'}>
                                ({DateHelper.format(pre.createdDate, 'YY년 MM월 DD일 HH시 mm분')})
                            </span>
                            }
                        </MenuItem>
                    )
                })}
            </Select>
        </Box>
    )
}


export function AlertDialog(props) {


    const handleYes = () => {
        if(props.handleYes){
            props.handleYes()
        }
    }

    const handleClose = () => {
        if(props.close){
            props.close()
        }
    }

    return (
        <Dialog
        open={props.open}
        disableBackdropClick
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
            <DialogTitle onClose={handleClose}>{"측정 데이텃 삭제"}</DialogTitle>
            <DialogContent >
                <DialogContentText id="alert-dialog-description">
                    {props.prescriptionID}회차 측정데이터를 삭제하시겠습니까?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    취소
                </Button>
                <Button onClick={handleYes} color="primary" autoFocus>
                    삭제합니다.
                </Button>
            </DialogActions>
        </Dialog>
    );
}


const useStyles = makeStyles(() => ({
    root: {
        border: '1px solid #BEBEBE',
        padding: 10,
        
        '& .MuiInput-underline::before': {
            
        },
        '& .MuiSelect-selectMenu': {
            paddingLeft: 10,
            paddingTop: 0,
            paddingBottom: 0
        },
        '& .title': {
            fontWeight: 'bold'
        },
        '& .sub': {
            fontSize:14, 
            paddingLeft: 10,
        },
        '& .MuiSelect-icon': {
            color: '#0055BF',
        },
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
    },
    active: {
        backgroundColor: '#0055BF',
        colo: 'white'
    }
 
}));
