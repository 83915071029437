import React, {useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import ReactCardFlip from 'react-card-flip';
import AudioPlayer from '@user-pages/exercise/common/AudioPlayer'
import { Box, Grid, Typography, Button} from '@material-ui/core';
import {useLocation} from "react-router";
import { useHistory } from "react-router-dom";

import TimeCount from '@user-pages/exercise/common/TimeCount'
import LevelDialog from "./LevelDialog";
import TrainLevelDialog from "../memoryComplex/LevelDialog"
import CompleteDialog from "./CompleteDialog";

import ExerciseService from '@user-service/ExerciseService';
import MeasureService from '@user-service/MeasureService';
import TimerDialog from "../memoryTest/TimerDialog";
import AuthProvider from "../../../provider/AuthProvider";

const svg = {
    check: 'M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z',
}
const SVG = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={props.width ? props.width : 'auto'}
            height={props.height ? props.height : 'auto'}
            viewBox="0 0 24 24"
            fill={props.color}
        >
            <path
                d={svg[props.name]}
            />
        </svg>
    );
}

const CognitiveTest = (props) => {
    const stageRef = useRef()
    const location = useLocation()
    const history = useHistory()
    
    let stageContainer
    const resizeHandler = () => {
        stageContainer.style.zoom = window.innerHeight / 900
    }

    useEffect(() => {
        window.addEventListener('resize', resizeHandler)
        stageContainer = document.getElementById('stage')
        
        resizeHandler()
        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    },[])
    
    return (
        <Stage ref={stageRef}
               history={history}
               showType={props.match.params.showType}
               prescriptionID={location.state.prescriptionID}
               exerciseID={location.state.exerciseID}
               measureType={location.state.measureType}
               exerciseName={location.state.exerciseName}
               description={location.state.description}
        />
    );
}

export class Stage extends React.Component {
    container
    clientRect

    itemWidth = 176
    itemHeight = 190
    isReverse = false
    useReverse = false

    audioRef = React.createRef()
    levelDialogRef = React.createRef()
    timerDialogRef = React.createRef()
    viewBoxRef = React.createRef()
    cogNavigationRef = React.createRef()
    completeDialogRef = React.createRef()

    timeLimit = 60
    randomIndex = []
    
    logID = 0
    score = {}

    gameResult = {
        memoryImages: [],
        memoryImagesIndex: [],
        memoryTexts: [],
        answerResults: []
    }
    
    
    
    constructor(props) {
        super(props)

        this.state = {
            items: [0, 1, 2, 3, 4, 5, 6, 7, 8],
            history: props.history,
            mode: 'show',
            showType: props.showType,
            isOver: false,
            prescriptionID: null !== props.prescriptionID ? props.prescriptionID : 0,
            exerciseName: props.exerciseName,
            viewXY: [5, 2],
            answerViewXY: [10, 4],
            measureType: props.measureType,
            exerciseID: props.exerciseID,
            description: props.description,
            answerCount: 10
        }
    }

    componentDidMount() {
        this.container = ReactDOM.findDOMNode(this)
        this.clientRect = this.container.getClientRects()[0]
        this.audioRef.current.loadEffect('memoryEgg')
        
        // if(AuthProvider.isAdmin()){
            this.levelDialogRef.current.open(this.state.showType, this.state.programView, this.state.description)
        // } else {
        //     this.timerDialogRef.current.open()
        // }
        
    }

    async startExercise(delay, timeLimit, answerCount) {
        let viewXY
        let answerViewXY
        if(10 === answerCount){
            viewXY = [5,2]
            answerViewXY = [8,5]
        } else if (15 === answerCount) {
            viewXY = [5,3]
            answerViewXY = [10,6]
        } else if (5 === answerCount){
            viewXY = [5,1]
            answerViewXY = [5,4]
        }
        
        this.setState({
            ...this.state,
            viewXY: viewXY,
            answerViewXY: answerViewXY,
            answerCount
        }, async () => {
            
            this.viewBoxRef.current.init(this.audioRef, viewXY)
            this.viewBoxRef.current.play(delay)

            this.timeLimit = timeLimit;
            this.logID = await MeasureService.newMeasureLog({
                prescriptionID: this.state.prescriptionID,
                hzType: `cognitiveTest${this.state.showType}`,
                measureTime: timeLimit,
                measureType: this.state.measureType
            })
            
        })
    }

    
    onPlayEnded(selectedItems) {
        this.gameResult.memoryImages = this.viewBoxRef.current.memoryImages
        this.gameResult.memoryImagesIndex = this.viewBoxRef.current.memoryImagesIndex
        this.gameResult.memoryTexts = this.viewBoxRef.current.memoryTexts
        
        setTimeout(() => {
            this.setState({
                mode: 'answer',
                answers: selectedItems
            })    
        }, 2000)
        
    }

    onAnswerEnded(score, answerResults) {
        score.showType = this.state.showType
        
        this.gameResult.answerResults = answerResults
        this.score = score
        
        this.audioRef.current.effect('stop')
        this.setState({
            ...this.state,
            isOver: true
        })

        
        this.onShowResult().then()
    }
    
    async onShowResult(){
        if(0 < this.score.correctCount){
            this.score.scoreText = ( this.score.correctCount * 10 ) + '점'
        }
        
        if(!this.score.count) this.score.count = this.state.answerCount;
        
       
        this.score.problems = {
          q: this.gameResult.memoryImagesIndex,
          a: this.gameResult.answerResults.map( r => {
            return { index: r.index , isCorrect: r.isCorrect }
          })
        }
      
        this.score.measureType = localStorage.getItem('measure-test-type')
        await ExerciseService.setExerciseResult({
            measureHzType: `cognitiveTest${this.state.showType}`,
            measureLogID: this.logID,
            bestWave: 0,
            resultRate: this.score.correctCount, 
            timeCount: this.score.timeCount,
            isEnd: 'yes',
            resultVar: JSON.stringify(this.score)
        })

        this.completeDialogRef.current.open(this.score, this.gameResult)
    }
    
    onReset(){
        this.state.history.go(0)
    }
    
    onStop(){
        try {
            this.viewBoxRef.current.stop()
        } catch {}
        
        this.onShowResult().then()
    }

    render() {
        let boxSize
        let top
        if('show' === this.state.mode){
            boxSize = 220
            const half = (boxSize * this.state.viewXY[1]) /2
            top = `calc(50% - ${half}px)`
        } else {
            boxSize = 135
            const half = (boxSize * this.state.answerViewXY[1] + 60) /2
            top = `calc(50% - ${half}px)`
        }
        
        let viewBoxMode = 'image'
        let answerViewBoxMode = 'text'
        
        let title = '인지변별력'
        if('2' === this.state.showType){
            viewBoxMode = 'text'
            answerViewBoxMode = 'image'
            title = '메타인지'
        }
        
        // let marginTop = 1080 > window.innerHeight ? 80 : 0
        return (
            <Box id={'stage'} style={{height: '100%', overflow: 'hidden'}}>
                <Box display="flex" flexDirection="column" justifyContent="center" style={{height: '100%'}}>
                    {'show' === this.state.mode && 
                        <ViewBox width={boxSize} height={boxSize} id={Math.random()}
                                 audioRef={this.audioRef}
                                 ref={this.viewBoxRef}
                                 viewXY={this.state.viewXY}
                                 viewMode={viewBoxMode}
                                 onPlayEnded={this.onPlayEnded.bind(this)}
                        />
                    }
    
                    {'answer' === this.state.mode && 
                        <AnswerViewBox width={boxSize} height={boxSize} id={Math.random()} 
                                       audioRef={this.audioRef}
                                       ref={this.viewBoxRef} 
                                       viewXY={this.state.answerViewXY}
                                       isOver={this.state.isOver}
                                       timeLimit={this.timeLimit}
                                       viewMode={answerViewBoxMode} 
                                       answers={this.state.answers}
                                       onAnswerEnded={this.onAnswerEnded.bind(this)}
                        />
                    }
                </Box>
                <AudioPlayer ref={ this.audioRef } mode={'balance'}/>
                {'train' === this.state.measureType && 
                <TrainLevelDialog ref={this.levelDialogRef} 
                             onStart={this.startExercise.bind(this)}
                             history={this.state.history}
                             exerciseName={this.state.exerciseName}
                             measureType={this.state.measureType}
                             exerciseID={this.state.exerciseID}
                            />
                }
                {'train' !== this.state.measureType &&
                    <LevelDialog ref={this.levelDialogRef}
                                 onStart={this.startExercise.bind(this)}
                                 history={this.state.history}
                                 exerciseName={this.state.exerciseName}
                                 measureType={this.state.measureType}
                                 exerciseID={this.state.exerciseID}
                    />
                }
                
                  
                <TimerDialog
                    ref={this.timerDialogRef}
                    timeSec={3}
                    title={title}
                    onEnd={() => {
                        const delay = 1000
                        const limitTime = 180
                        this.startExercise(delay, limitTime).then()
                    }}/>
                <CompleteDialog ref={this.completeDialogRef} 
                                history={this.state.history}
                                viewMode={viewBoxMode}
                                onReset={this.onReset.bind(this)}
                                exerciseName={this.state.exerciseName}
                                exerciseID={this.state.exerciseID}
                                measureType={this.state.measureType}
                />
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    position="absolute"
                    right={10}
                    top={10}
                    zIndex={99}>
                    <Grid container justify="center" spacing={1}>
                        <Grid item>
                            <Button
                                style={{fontSize: '1.2rem', height:54, border: '3px solid #829ABC', color: '#829ABC', width: 160, borderRadius: 40, backgroundColor: '#F1F4F9', marginRight: 39,marginTop:25 }}
                                onClick={this.onStop.bind(this)}>
                                종료
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        )
    }
}


export class ViewBox extends React.Component {
    audioRef
    direction = 'forward'
    
    memoryImages = []
    memoryImagesIndex = []
  
    memoryTexts = []
    memoryItems = []
    memoryItemsIndex = []
    
    delay = 1000

    levelType = 'level1'
    memoryCount = 15
    row = []
    col = []
    
    timer
    
    constructor(props) {
        super();

        this.row = []
        this.col = []

        for(let i = 0; i < props.viewXY[0]; i++){
            this.row.push(0)
        }
        for(let i = 0; i < props.viewXY[1]; i++) {
            this.col.push(this.row)
        }
        this.memoryCount = this.row.length * this.col.length
        this.levelType = 10 === this.memoryCount ? 'level2' : 'level1'


        this.memoryItems = MeasureService.getMemoryItems(this.levelType)
        this.memoryItemsIndex = this.memoryItems.map ( value => value.index )
        
        const randomIndex = []
        let newIndex = 0;
        //이미지 초기화 
        for(let i = 0 ; i < this.memoryCount; i++){
            newIndex = this.makeRandomIndex(randomIndex)

            randomIndex.push(newIndex)
            this.memoryImages.push(require(`@assets/exercise/cognitive/c${newIndex}.png`))
            this.memoryTexts.push(this.memoryItems.find(item => item.index === newIndex).name)
        }

        this.state = {
            blocks: this.col,
            randomIndex,
            selectedItems: [],
            width: props.width,
            height: props.height,
            currentShowIndex: 0,
            viewMode: props.viewMode,
            playEnded: props.onPlayEnded,
        }
    }
    

    init(audioRef, viewXY){
        this.audioRef = audioRef
        this.build(viewXY)
    }
    
    build(viewXY){

        this.row = []
        this.col = []

        for(let i = 0; i < viewXY[0]; i++){
            this.row.push(0)
        }
        for(let i = 0; i < viewXY[1]; i++) {
            this.col.push(this.row)
        }
        this.memoryCount = this.row.length * this.col.length
        this.levelType = 10 === this.memoryCount ? 'level2' : 'level1'


        this.memoryItems = MeasureService.getMemoryItems(this.levelType)
        this.memoryItemsIndex = this.memoryItems.map( value => value.index )
        
        const randomIndex = []
        let newIndex = 0;
        //이미지 초기화 
        this.memoryImages = []
        this.memoryImagesIndex = []
        this.memoryTexts = [] 
        
        for(let i = 0 ; i < this.memoryCount; i++){
            newIndex = this.makeRandomIndex(randomIndex)

            randomIndex.push(newIndex)
            this.memoryImages.push(require(`@assets/exercise/cognitive/c${newIndex}.png`))
            this.memoryImagesIndex.push(newIndex)
            this.memoryTexts.push(this.memoryItems.find(item => item.index === newIndex).name)
        }
        
        
        this.setState({
            ...this.state,
            blocks: this.col,
            randomIndex,
        })
    }

    makeRandomIndex(randomIndex){
        const shuffled = this.memoryItemsIndex
            .filter((value) => {
                return !(-1 < randomIndex.indexOf(value))
            })
            .map(a => ([Math.random(),a]))
            .sort((a,b) => a[0]-b[0])
            .map(a => a[1])
        return shuffled[0]
    }
    
    play(delay) {
        if(delay) { this.delay = delay }
        
        if( this.state.currentShowIndex > this.memoryCount -1){
            if(this.state.playEnded){
                this.state.playEnded(this.state.randomIndex)
            }
            return false
        }
        
        if(this.timer){
            clearTimeout(this.timer)
        }
        
        this.timer = setTimeout(() => {
            try {
                const showIndex = this.state.currentShowIndex + 1
                this.audioRef.current.effect('show')

                this.setState({
                    ...this.state,
                    currentShowIndex: showIndex,
                }, () => {
                    this.play()
                })
            } catch {}
        }, 0 === this.state.currentShowIndex ? 1000 : this.delay)
    }
    
    stop(){
        if(this.timer){
            clearTimeout(this.timer)
        }
    }

    render() {
        return (
            <Box className={'cog-view-box'}>
                {this.state.blocks.map((block, outIndex) => {
                    const { viewMode, width, height, randomIndex } = this.state
                    return (
                        <Grid container spacing={2} justify={"center"} key={outIndex}>
                            {block.map((pos, index) => {
                                let pointIndex = (outIndex * this.row.length) + index
                                let isShow = pointIndex < this.state.currentShowIndex

                                const memory = this.memoryItems.find( m => m.index === randomIndex[pointIndex])
                                const name = memory ? memory.name : ''
                                
                                return (
                                    <Grid item key={index}>
                                        <Box style={{
                                            width: width,
                                            height: height,
                                            borderRadius: '10px',
                                            border: 3,
                                            borderStyle: 'solid',
                                            borderColor: '#bbb',
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            {(isShow && 'image' === viewMode) && 
                                                <img src={this.memoryImages[pointIndex]} alt={pointIndex} style={{width: '100%'}} />
                                            }

                                            {(isShow && 'text' === viewMode) &&
                                            <Typography
                                                style={{
                                                    textAlign: 'center',
                                                    fontSize: '1.8rem',
                                                    width: '100%',
                                                    fontWeight:'bold'
                                                }}
                                            >
                                                {name}
                                            </Typography>
                                            }

                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    )
                })}
            </Box>
        )
    }
}


export class AnswerViewBox extends React.Component {
    audioRef
    direction = 'forward'
    
    scoreBoardRef = React.createRef()

    memoryImages = []
    memoryItems = []
    memoryItemsIndex = []
    answerResults = []  // 맞음 틀림 배열
    
    answerIndex = []
    memoryCount = 15
    levelType = 'level1'
    row = []
    col = []
    
    flipBoxRef = []
    isOver = false
    
    constructor(props) {
        super();
        for(let i = 0; i < props.viewXY[0]; i++){
            this.row.push(0)
        }
        for(let i = 0; i < props.viewXY[1]; i++) {
            this.col.push(this.row)
        }
        this.memoryCount = this.row.length * this.col.length
        this.levelType = 40 === this.memoryCount ? 'level2' : 'level1'

        this.memoryItems = MeasureService.getMemoryItems(this.levelType)
        this.memoryItemsIndex = this.memoryItems.map ( value => value.index )

        this.answerIndex = JSON.parse(JSON.stringify(props.answers))
        const randomIndex = JSON.parse(JSON.stringify(props.answers))
        let newIndex = 0;
        
        //이미지 초기화 
        for(let i = 0 ; i < this.memoryCount - props.answers.length; i++){
            newIndex = this.makeRandomIndex(randomIndex)
            randomIndex.push(newIndex)
        }
        const shuffledRandomIndex = randomIndex.map(a => ([Math.random(),a]))
                                            .sort((a,b) => a[0]-b[0])
                                            .map(a => a[1])
        shuffledRandomIndex.map((value, index) => {
            this.memoryImages.push(require(`@assets/exercise/cognitive/c${value}.png`))
            return value
        })
        
        this.state = {
            blocks: this.col,
            randomIndex: shuffledRandomIndex,
            selectedItems: [],
            width: props.width,
            height: props.height,
            currentShowIndex: 3,
            viewMode: props.viewMode,
            timeLimit: props.timeLimit,
            onAnswerEnded: props.onAnswerEnded,
            isOver: false,
        }

        for(let i = 0 ; i < props.viewXY[0] *  props.viewXY[1] ; i++){
            this.flipBoxRef[i] = React.createRef()    
        }
    }
    
    init(audioRef){
        this.audioRef = audioRef
    }

    makeRandomIndex(randomIndex){
        const shuffled = this.memoryItemsIndex
            .filter((value) => {
                return !(-1 < randomIndex.indexOf(value))
            })
            .map(a => ([Math.random(),a]))
            .sort((a,b) => a[0]-b[0])
            .map(a => a[1])

        return shuffled[0]
    }
    
    onSelect(isCorrect, values){
        this.answerResults.push({
            isCorrect: isCorrect,
            image: values.image,
            text: values.text,
            index: values.index
        })
        const state = this.scoreBoardRef.current.update(isCorrect)

        if(state.selectedCount === this.answerIndex.length){
            this.scoreBoardRef.current.reset()
            this.isOver = true

            setTimeout(() => {
                if(this.state.onAnswerEnded){
                    this.state.onAnswerEnded(state, this.answerResults)
                }    
            }, 100)
        }
    }
    
    render() {
        return (
            <Box className={'cog-answer-box'}>
                <Box display={'flex'} justifyContent={'center'}>
                    <AnswerScoreBox 
                        ableCount={this.answerIndex.length} 
                        ref={this.scoreBoardRef}
                        timeLimit={this.state.timeLimit}
                        timeout={(state) => {
                            if(this.state.onAnswerEnded){
                                this.state.onAnswerEnded(state, this.answerResults)
                            }
                        }}
                    />
                </Box>
                
                {this.state.blocks.map((block, outIndex) => {
                    return (
                        <Grid container spacing={1} justify={"center"} key={outIndex}>
                            {block.map((pos, index) => {
                                let pointIndex = (outIndex * this.row.length) + index
                                let currentValueIndex = this.state.randomIndex[pointIndex]

                                return (
                                    <Grid item key={index}>
                                        <Box
                                            onClick={(event) => {
                                                if(this.isOver) return
                                                
                                                try {
                                                    const flip = this.flipBoxRef[pointIndex].current.handleClick(event)
                                                    if(flip.isFlipped) return
                                                    
                                                    this.onSelect(flip.isAnswer, {
                                                        index:  currentValueIndex,
                                                        image: this.memoryImages[pointIndex],
                                                        text: this.memoryItems.find(item => item.index === currentValueIndex).name
                                                    })    
                                                } catch {}
                                            }}
                                        >
                                            <AnswerFlipBox
                                                ref={ this.flipBoxRef[pointIndex]}
                                                firstView={this.state.viewMode}
                                                image={this.memoryImages[pointIndex]}
                                                text={this.memoryItems.find(item => item.index === currentValueIndex).name}
                                                width={this.state.width}
                                                height={this.state.height}
                                                isAnswer={-1 < this.answerIndex.indexOf(currentValueIndex)}
                                                onSelect={this.onSelect.bind(this)}
                                                index={this.answerIndex.indexOf(currentValueIndex)}
                                            />    
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    )
                })}
            </Box>
        )
    }
}


export class AnswerScoreBox extends React.Component {

    timeCountRef = React.createRef()
    
    constructor(props) {
        super();
        
        this.state = {
            selectedCount: 0,
            correctCount: 0,
            ableCount: props.ableCount,
            timeout: props.timeout,
            timeLimit: props.timeLimit
        }
    }
    
    componentDidMount() {
        this.timeCountRef.current.startWithCountdown(this.state.timeLimit, () => {
            const { correctCount, ableCount, timeout, selectedCount } = this.state
            if(timeout){
                timeout({
                    time: this.timeCountRef.current.getTimePassedText(this.state.timeLimit),
                    timeCount: this.timeCountRef.current.getTimePassed(this.state.timeLimit),
                    correctCount,
                    count: ableCount,
                    selectedCount
                })
            }
        })
    }
    
    update(isCorrect){
        let correctCount = this.state.correctCount;
        const selectedCount = this.state.selectedCount + 1
        if(isCorrect){
            correctCount++
        }
        
        this.setState({
            ...this.state,
            correctCount,
            selectedCount
        })
        
        return {
            time: this.timeCountRef.current.getTimePassedText(this.state.timeLimit),
            timeCount: this.timeCountRef.current.getTimePassed(this.state.timeLimit),
            correctCount: correctCount,
            count: this.state.ableCount,
            selectedCount
        }
    }
    
    reset() {
        this.timeCountRef.current.clearInterval()
    }
    
    render(){
        return (
            <Box style={{width: 1150}}>
                <Grid container>
                    <Grid item xs={6}>
                        <Box style={{textAlign: 'left', paddingLeft: 10}}>
                            <TimeCount ref={this.timeCountRef} variant={"h4"} textColor={'#999'}/>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box style={{textAlign: 'right', paddingRight: 10, color: '#999'}}>
                            <Typography
                                variant={"h4"}
                            >
                                {this.state.selectedCount}  / {this.state.ableCount}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )   
    }
}

export class AnswerFlipBox extends React.Component {
    selectedCount = 0 
    
    constructor(props) {
        super();
        this.state = {
            isFlipped: false,
            firstView: props.firstView,
            image: props.image,
            text: props.text,
            width: props.width,
            height: props.height,
            isAnswer: props.isAnswer,
            onSelect: props.onSelect,
            index: props.index
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.preventDefault();
        
        if(this.state.isFlipped) return 
        
        this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
        return {
            isAnswer: this.state.isAnswer,
            isFlipped: this.state.isFlipped
        }
    }
    
    render() {
        const { height, text, width } = this.state
        const grayscale = this.state.isAnswer ? '0%' : '100%' 
        return (
            <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="horizontal">
                <Box style={{
                         width: width,
                         height: height,
                         borderRadius: '10px',
                         border: 3,
                         borderStyle: 'solid',
                         borderColor: '#bbb',
                         backgroundSize: 'contain',
                         backgroundRepeat: 'no-repeat',
                         backgroundPosition: 'center',
                         cursor: 'pointer',
                         display: 'flex',
                         alignItems: 'center'
                     }}>

                    {'image' === this.state.firstView &&
                        <img src={this.state.image} style={{width: '100%'}} alt={"firstView"}/>
                    }
                    
                    {'text' === this.state.firstView &&
                    <Typography
                        variant={"h5"}
                        style={{
                            textAlign: 'center',
                            fontSize: '1.6rem',
                            width: '100%'
                        }}
                    >
                        {text}
                    </Typography>
                    }
                    
                    { (this.state.isAnswer && (AuthProvider.isSuperAdmin() || AuthProvider.isShowAnswerMember())) &&  
                    <Box position={'absolute'} left={10} top={10}>
                      <SVG name={'check'} width={'24px'} height={'24px'} color={'#04CF5C'}/>
                    </Box>
                    }
                </Box>

                <Box position={'relative'}
                    style={{
                         width: width,
                         height: height,
                         borderRadius: '10px',
                         border: 3,
                         borderStyle: 'solid',
                         borderColor: '#999',
                         backgroundSize: 'contain',
                         backgroundRepeat: 'no-repeat',
                         backgroundPosition: 'center',
                         cursor: 'pointer',
                         display: 'flex',
                         alignItems: 'center'
                     }}>

                    {this.state.isAnswer &&
                    <Box position={'absolute'} zIndex={10} left={5} top={5}>
                        <SVG name={'check'} width={'24px'} height={'24px'} color={'#04CF5C'}/>
                    </Box>
                    }
                    
                    {'image' === this.state.firstView &&
                    <Typography
                        variant={"h5"}
                        style={{
                            textAlign: 'center',
                            fontSize: '1.6rem',
                            width: '100%'
                        }}
                    >
                        {text}
                    </Typography>
                    }

                    {'text' === this.state.firstView &&
                        <img src={this.state.image} style={{width: '100%', filter: `grayscale(${grayscale})`}} alt={'firstView'}/>
                    }
                </Box>
            </ReactCardFlip>
        )
    }
}

export default CognitiveTest
