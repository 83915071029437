import React, {useEffect, createRef} from 'react';
import {Box, Grid, Button, Dialog, DialogContent, DialogActions, DialogTitle} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import PrescriptionService from '@user-service/PrescriptionService';
import Moment from 'moment-timezone'

const MeasureSchedule = () => {
    const measureDetailDialogRef = createRef()
    useEffect(()=>{   
    },[])

    const renderEventContent = (eventInfo) => {
        let { balance, prescriptionID } = eventInfo.event.extendedProps
        if(!balance){
            balance = [0,0,0,0]
        }

        return (
            <Box p={1} style={{width: '100%'}} onClick={()=>{
                measureDetailDialogRef.current.show({
                    title: eventInfo.event.title,
                    prescriptionID,
                    balance
                })
            }}>
                <Box style={{fontSize:12}}>{eventInfo.timeText}</Box>                
                <Box style={{fontSize:14, fontWeight: 'bold'}}>
                    {eventInfo.event.title}
                </Box>
                <br />
                <Grid container direction="column">
                    <Grid item>안정: {balance[0]}%</Grid>
                    <Grid item>주의: {balance[1]}%</Grid>
                    <Grid item>집중: {balance[2]}%</Grid>
                    <Grid item>
                        <Box style={{
                                backgroundImage:`url(/api/prescription/${prescriptionID}/image/ch1)`,
                                height:70,
                                width: '100%',
                                backgroundSize: '137%',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                            }}>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box style={{
                                backgroundImage:`url(/api/prescription/${prescriptionID}/image/ch2)`,
                                height:70,
                                width: '100%',
                                marginTop: -35,
                                backgroundSize: '137%',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                            }}>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const getPrescription = async (fetchInfo, successCallback) => {
        if (fetchInfo) {
            const start = Moment(fetchInfo.start).format('YYYY-MM-DD')
            const end = Moment(fetchInfo.end).format('YYYY-MM-DD')

            const prescriptions = await PrescriptionService.getPrescriptionByPeriod(start, end)

            successCallback(prescriptions.map((value) => {
                return {
                    title: `${value.id}회차 측정`,
                    date: `${value.date} ${value.time}`,
                    extendedProps: {
                        balance: value.balance, 
                        prescriptionID: value.id
                    },
                }
            }))
        } else {
            successCallback([])
        }
    }
    
    return (
        <div id="cont-wrap">
            <div className="sv">
                <div>
                    <h3>운동기록</h3>
                    <span>처방받은 운동기록을 확인합니다.</span>
                    <i>Brain Health</i>
                </div>
            </div>
            <div className="cont">
                <Grid container>
                    <Grid item xs={12}>
                        <Box mt={1}>
                            <FullCalendar
                                locale={'kr'}
                                plugins={[ dayGridPlugin ]}
                                initialView="dayGridMonth"
                                eventContent={renderEventContent.bind(this)}
                                selectable
                                editable
                                events={
                                    (fetchInfo, successCallback, failureCallback) => getPrescription(fetchInfo, successCallback, failureCallback)
                                }
                            />
                        </Box>
                        
                    </Grid>
                </Grid>
                <MeasureDetailDialog ref={measureDetailDialogRef} open={false}/>
            </div>
        </div>
    );
}

//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    return (
        <Box >
            <div className="editprofile_wrap wrapper" xs={6} sm={6}>
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </div>
        </Box>
    )
}
export class MeasureDetailDialog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            open: props.open ? props.open : false,
            title: '',
            timeText: '',
            balance: [0,0,0],
            prescriptionID: ''
        }
    }

    handleClose() {
        this.setState({
            open : false
        })
    }

    show(params){

        this.setState({
            open: true,
            title: params.title,
            balance: params.balance,
            prescriptionID: params.prescriptionID
        })
    }

    render() {

        return <Dialog
                open={this.state.open}
                fullWidth={true}
                maxWidth={'lg'}
                onClose={this.handleClose.bind(this)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid container spacing={1}>
                        <Grid item> {this.state.title} </Grid>
                        <Grid item> 
                            <Button variant="outlined" 
                                size="small"
                                onClick={() => {
                                    window.location.href = `/measureCurrent?prescriptionID=${this.state.prescriptionID}`; 
                                }}
                                >상세보기</Button> 
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Box p={1} style={{width: '100%'}}>
                        <Grid container direction="column">
                            <Grid item>
                                <Box style={{
                                        backgroundImage:`url(/api/prescription/${this.state.prescriptionID}/image/ch1)`,
                                        height:350,
                                        width: '100%',
                                        backgroundSize: '135%',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center'
                                    }}>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box style={{
                                        backgroundImage:`url(/api/prescription/${this.state.prescriptionID}/image/ch2)`,
                                        height:350,
                                        width: '100%',
                                        marginTop:-50,
                                        backgroundSize: '135%',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center'
                                    }}>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this)} color="primary">
                        닫기
                    </Button>
                </DialogActions>
        </Dialog>
    }

}

export default MeasureSchedule
