import React, {useCallback, useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid} from "@material-ui/core";
import clsx from "clsx";
import PrescriptionService from "../../../services/PrescriptionService";
import MeasureService from "../../../services/MeasureService";
import ViewBrainHexMap from "../measureDetail/VIewBrainHexMap";
import MeasureState from "../measureDetail/MeasureState";
import BrainBalanceBand from "../measureDetail/BrainBalanceBand";
import measureCurrent from "./MeasureCurrent";
import BrainActive from "../measureDetail/BrainActive";
import MeasureBandTest from "../measureDetail/MeasureBandTest";
import ResultBalance from "../measureBalanceCurrent/ResultBalance";
import BrainBalanceMap from "../measureDetail/BrainBalanceMap";
import ProfileService from "../../../services/ProfileService";
import ResultStress from "../measureBalanceCurrent/ResultStress";
import MeasureEEGLineChart from "./MeasureEEGLineChart";
import ResultRegulation from "../measureBalanceCurrent/ResultRegulation";
import ResultEmotion from "../measureBalanceCurrent/ResultEmotion";
import DateHelper from "../../../helper/DateHelper";

const MeasureCurrentPrint = ({prescriptionID, measureResult, profileID, onReadyToPrint}) => {
    const classes = useStyles()

    const [ isLoading , setLoading ] = useState(true);
    
    const [ fftImages , setFFTImages ] = useState(null);
    const [ preBalanceLevels ] = useState(MeasureService.getPreLevel('BALANCE').splice(0,14))
    const [ preEEGLevels ] = useState(MeasureService.getPreLevel('EEG'))
    
    const [preBalance, setPreBalance] = useState(null)
    const [preEEG, setPreEEG] = useState(null)
    
    const [ stressResult, setStressResult ] = useState(null)
    const [ bandData, setBandData ] = useState(null)
    const [ bandDataImages, setBandDataImages ] = useState(null)
    const [ brainActive, setBrainActive ] = useState(null)
    
    const [ profile, setProfile ] = useState(null)
    const [ bandAvg, setBandAvg ] = useState(null)
    
    
    /**
     * 인쇄용으로 자리 맞춤 데이터
     */
    if(preEEGLevels){
        preEEGLevels.push({levelText : 'ㅤ', name : 'ㅤ', levelName : 'ㅤ'})
    }
    
    /**
     * fft 이미지 
     */
    const fetch = useCallback(async (id) => {
        await fetchProfile()
        
        setFFTImages(await PrescriptionService.getFFTImage(id))
        
        await fetchStress(id)
        await fetchLine(id)
        
        const { bandRangeData } = measureResult
        try {
            if(bandRangeData){
                const ch1e = Number(bandRangeData.ch1[0])
                const ch2e = Number(bandRangeData.ch2[0])

                setPreEEG([
                    preEEGLevels.find(pre => pre.level === MeasureService.makeDeltaToPreLevelEEG(ch1e)),
                    preEEGLevels.find(pre => pre.level === MeasureService.makeDeltaToPreLevelEEG(ch2e))
                ])

                const ch1b = bandRangeData.ch1[2];
                const ch2b = bandRangeData.ch2[2];
                const total = parseFloat(ch1b) + parseFloat(ch2b)

                // 델타에서 알파로 변경
                // 알파파로 등급을 판정함
                const ch1Percent = 0 === total ? 50 : Math.floor(ch1b / total * 100)
                const ch2Percent = 0 === total ? 50 : Math.floor(ch2b / total * 100)

                
                const balanceLevels = MeasureService.getPreLevel('BALANCE')
                setPreBalance([
                    balanceLevels.find(pre => pre.level === MeasureService.makeDeltaToPreLevelBalance(Number(ch1Percent))),
                    balanceLevels.find(pre => pre.level === MeasureService.makeDeltaToPreLevelBalance(Number(ch2Percent)))
                ])
            }
        } catch {}

        
        await fetchBandTest(id)    
        
        await fetchBrainActive(id)
        /**
         * 로딩 시간이 가장 긴 항상성 자기조절에서 이벤트 발생
         */
        if(onReadyToPrint) {
            setTimeout(() => {
                onReadyToPrint()    
            }, 1000)
        }
    }, [])

    /**
     * 스트레스 결과를 가져옵니다 
     * @type {(function(*): Promise<void>)|*}
     */
    const fetchStress = useCallback(async (id) => {
        const result = await PrescriptionService.getBalanceByPrescriptionId(id)
        setStressResult(result)
    },[])

    /**
     * 현재 사용자의 정보를 가져옵니다 
     * @type {(function(): Promise<void>)|*}
     */
    const fetchProfile = useCallback(async () => {
        const res = await ProfileService.getProfile(profileID)
        setProfile(res.data)
    }, [profileID])
    
    const clientWidth = 1800

    /**
     * 측정한 EEG의 라인그래프 테이터를 가져옵니다 
     * @type {(function(*): Promise<void>)|*}
     */
    const fetchLine = useCallback(async (id) => {
        const bandAvg = await PrescriptionService.getPrescriptionBandAvg(id)
        setBandAvg(bandAvg)
    }, [])
    
    

    /**
     *  항상성 자기조절 데이터를 가져옵니다
     */
    const fetchBandTest = useCallback(async (id) => {
        const data = await PrescriptionService.getMeasureBandTest(id)
        setBandData(data)

        const images = await PrescriptionService.getFFTImage(id, 'measureBandTest', 'MA')
        setBandDataImages(images)
        
    }, [])

    /**
     * 뇌기능 의식패턴 분석
     * @type {(function(*): Promise<void>)|*}
     */
    const fetchBrainActive = useCallback(async (id) => {
        const data = await PrescriptionService.getBrainActive(id)
        setBrainActive(data)
    }, [])
    

    useEffect(() => {
        if(prescriptionID){
            fetch(prescriptionID).then(() => {
                setLoading(false)
            })    
        }
        return (() => {
            setStressResult(null)
        })
    }, [prescriptionID, fetch, setStressResult])

    
    if(!profile || isLoading) return <></>
    
    return (
        <div>
            <div className={'content-print'}>
                <Box className={classes.root}>
                    <Box className={classes.headerBox}>
                        <div>
                            <div className={classes.title} style={{fontSize: 160, paddingTop: 240}}>뇌기능 분석</div>
                            <div className={classes.titleSub} style={{paddingTop: 50, fontSize: 90}}>[Brain Analysis]
                            </div>
                        </div>
                    </Box>

                    <Box className={classes.contentBox}>
                        <div>
                            <Box style={{display: 'flex', justifyContent: 'center', paddingTop: 120}}>
                                <Grid container style={{maxWidth: '80%', paddingLeft: 100}}>
                                    <Grid item xs={6} className={classes.innerTitle}
                                          style={{fontWeight: 'normal', color: 'white'}}>
                                        <div> • EEG 분석</div>
                                        <div> • 좌우뇌 균형</div>
                                        <div> • 의식패턴 분석</div>
                                        <div> • 뇌특성 분석</div>
                                        <div> • 정신적 스트레스 분석</div>
                                        <div> • 육체적 스트레스 분석</div>
                                        <div> • 안정정도 분석</div>
                                        <div> • 산만정도 분석</div>
                                    </Grid>
                                    <Grid item xs={6} className={classes.innerTitle}
                                          style={{fontWeight: 'normal', color: 'white'}}>
                                        <div>• 뇌기능별 분석</div>
                                        <div>• 균형상태 분석</div>
                                        <div>• 항상성 자기조절 분석</div>
                                        <div>• 자기조절 분석</div>
                                        <div>• 정신적 스트레스저항 분석</div>
                                        <div>• 육체적 스트레스저항 분석</div>
                                        <div>• 불안정도 분석</div>
                                    </Grid>
                                </Grid>
                            </Box>
                            <div style={{marginBottom: 130}}></div>
                            <div className={classes.innerTitle}
                                 style={{
                                     border: "6px solid #fff",
                                     borderRadius: 50,
                                     color: 'white',
                                     padding: `60px 150px`
                                 }}>
                                뇌기능 분석은
                                <span style={{fontSize: 50, fontWeight: "normal"}}>뇌파를 측정하여 뇌기능 균형상태, 발달상태, 뇌특성상태 등 과학적인 방법으로 주파수(Hz), 진폭(mV), 위상각을 정량화하여 위와 같이 15항목으로 정밀 종합분석을 합니다.</span>
                            </div>
                        </div>
                        <div style={{marginBottom: 100}}></div>
                        <div className={classes.innerBox} style={{height: 1500}}>
                            <div className={'print-user-title'}>
                                Analysis Result
                                <div className={'subtitle'}>{DateHelper.format(measureResult.eegDate, 'YYYY.MM.DD')}, {measureResult.id}회차</div>
                            </div>
                            <table className={'print-user-info-table'}>
                                <colgroup>
                                    <col width="215"/>
                                    <col width="447"/>
                                    <col width="215"/>
                                    <col width="403"/>
                                    <col width="215"/>
                                    <col width="*"/>
                                </colgroup>
                                <thead></thead>
                                <tbody>
                                <tr>
                                    <th>이 름</th>
                                    <td>{profile.profileName}</td>
                                    <th>성 별</th>
                                    <td>
                                      {'male' === profile.gender ? '남' : '여'}
                                    </td>
                                    <th>생년월일</th>
                                    <td className={'text-center'}>{profile.birthDate}</td>
                                </tr>
                                <tr>
                                    <th>연락처</th>
                                    <td>{profile.cellphone}</td>
                                    <th>EMAIL</th>
                                    <td colSpan={3}></td>
                                </tr>
                                <tr>
                                    <th rowSpan={2}>주 소</th>
                                    <td colSpan={5} className={'text-left'}
                                        style={{paddingLeft: 30}}>{profile.zipcode} &nbsp; </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} className={'text-left'}
                                        style={{paddingLeft: 30}}>{profile.address} {profile.addressDetail} &nbsp;</td>
                                </tr>
                                <tr>
                                    <th>신 장</th>
                                    <td>{profile.weight && `${profile.personHeight} cm`} </td>
                                    <th>체 중</th>
                                    <td>{profile.weight && `${profile.weight} kg`} </td>
                                    <th>혈액형</th>
                                    <td>{profile.bloodType}</td>
                                </tr>
                                <tr>
                                    <th>시 력</th>
                                    <td>
                                        {profile.vision &&
                                        <Grid container>
                                            <Grid item xs={3}>좌 : </Grid>
                                            <Grid item xs={3}>{profile.vision[0]}</Grid>
                                            <Grid item xs={3}>우 : </Grid>
                                            <Grid item xs={3}>{profile.vision[1]}</Grid>
                                        </Grid>
                                        }
                                    </td>
                                    <th>약물복용</th>
                                    <td colSpan={3}>{'yes' === profile.takePills ? '복용중' : '없음'}</td>
                                </tr>
                                <tr>
                                    <th>혈 당</th>
                                    <td>{profile.bloodSugarName}</td>
                                    <th>혈 압</th>
                                    <td>{profile.bloodPressureName}</td>
                                    <th>주사용</th>
                                    <td>{profile.useHandName}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </Box>
                </Box>
                <Box className={classes.bottomTitle}>
                    <Grid container style={{width: '100%'}}>
                        <Grid item xs={6} className={'text-left p-l-100'}> - 1 -</Grid>
                        <Grid item xs={6} className={'text-right'}>한국뇌건강증진운동연구소 https://kbhd.org</Grid>
                    </Grid>
                </Box>
            </div>

            <div className={'content-print'}>
                <Box className={classes.root}>
                    <Box className={classes.headerBox}>
                        <div>
                            <div className={classes.title}>뇌파(EEG) 분석</div>
                            <div className={classes.titleSub}>[Brain Wave Analysis]</div>
                        </div>
                    </Box>
                    <Box className={classes.contentBox}>
                        <div className={classes.innerBox}>
                            <div className={clsx(classes.innerTitle, classes.mb60)}>뇌파(EEG) 3D 그래프</div>
                            <div className={classes.eegImage}>
                                {fftImages &&
                                    <img src={fftImages.ch1} style={{width: '110%', marginLeft: -100 }}
                                         alt={'image'}/>
                                }
                            </div>
                            <div style={{marginBottom: 130}}></div>
                            <div className={clsx(classes.innerTitle, classes.mb60)}>개안1-폐안-개안2 뇌기능 그래프</div>
                            <div style={{height: 590, width: '100%'}}>
                                {bandAvg &&
                                <MeasureEEGLineChart bandAvg={bandAvg}/>
                                }
                            </div>
                            <div style={{marginBottom: 100}}></div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'}/>
                                뇌파(EEG) 3D 그래프
                            </div>
                            <div className={classes.innerTitleDesc}>
                                뇌파(EEG)란 상태에 따라 다양한 뇌기능 주파수로 나타나며 인간의 뇌와 인체 활동(감정과 운동) 등,
                                유기적으로 작용하는 기능을 알 수 있습니다. 뇌파(EEG) 3D 그래프는 원시(배경) 뇌파를 변환하여
                                전문가가 아닌 일반인들도 쉽게 뇌파를 보고 이해할 수 있도록 한 그래프입니다.
                            </div>
                            <div style={{marginBottom: 50}}></div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'}/>
                                뇌파(EEG) 3D 그래프
                            </div>
                            <div className={classes.innerTitleDesc}>
                                개안(EO), 폐안(EC), 개안(EO) 뇌파측정 과정을 10Sec 단위로 나타낸 곡선 그래프로 <br/>
                                뇌기능의 변화 패턴을 분석한 그래프입니다.
                            </div>

                        </div>
                    </Box>
                </Box>
                <Box className={classes.bottomTitle}>
                    <Grid container style={{width: '100%'}}>
                        <Grid item xs={6} className={'text-left p-l-100'}> - 2 -</Grid>
                        <Grid item xs={6} className={'text-right'}>한국뇌건강증진운동연구소 https://kbhd.org</Grid>
                    </Grid>
                </Box>
            </div>

            <div className={'content-print'}>
                <Box className={classes.root}>
                    <Box className={classes.headerBox}>
                        <div>
                            <div className={classes.title}>뇌기능별 분석</div>
                            <div className={classes.titleSub}>[Brain Function Analysis]</div>
                        </div>
                    </Box>
                    <Box className={classes.contentBox}>
                        <div className={classes.innerBox}>
                            <div className={clsx(classes.innerTitle, classes.mb60, 'inner-title')} style={{padding:0}}>
                                {preEEG &&
                                    <Grid container>
                                        <Grid item style={{position: 'relative', paddingLeft:60, paddingRight:100}}>
                                            <img className={'ico'} src={'/images/circle.svg'}/>
                                            좌뇌 (Left): 
                                            <div style={{borderBottom:`10px solid red`, marginLeft: 20, display:'inline'}}>{preEEG[0].levelText} {preEEG[0].levelName}</div>           
                                        </Grid>
                                        <Grid item style={{position: 'relative', paddingLeft:60}}>
                                            <img className={'ico'} src={'/images/circle.svg'}/>
                                            우뇌 (Right):
                                            <div style={{borderBottom:`10px solid red`, marginLeft: 20, display:'inline'}}> {preEEG[1].levelText} {preEEG[1].levelName}</div>
                                        </Grid>
                                    </Grid>    
                                }
                            </div>
                            <div className={classes.eegImage}>
                                {measureCurrent &&
                                    <BrainBalanceBand
                                        width={clientWidth}
                                        height={850}
                                        fontSize={30}
                                        bandRangeData={measureResult.bandRangeData}/>
                                }
                            </div>
                            <div style={{marginBottom: 130}}></div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'}/>
                                뇌기능별 상태
                            </div>
                            <div className={classes.innerTitleDesc}>
                                뇌파(EEG), 델타파(δ) 1~4Hz 이하, 세타파(θ) 4~8Hz 이하, 알파파(α) 8~12Hz 이하, <br/>
                                SMR파 12~15Hz 이하, 베타파(β) 16~20Hz 이하, 중간 베타파(βm) 20~24Hz 이하, <br/>
                                높은 베타파(βh) 24~28Hz 까지 좌뇌와 우뇌 뇌파를 분류하여 뇌기능별 상태를 분석한 것 입니다. <br/>
                                내면 의식과 중간 의식, 외면 의식의 비교 분석이 가능한 그래프입니다.<br/>
                            </div>
                            <div style={{marginBottom: 130}}></div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'}/>
                                뇌기능별 분석기준
                            </div>
                            <div className={classes.innerTitleDesc}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <div style={{position: 'relative'}}>
                                            <table className={'print-table'}
                                                   style={{height: 850, border: `3px solid #000`, width: `100%`}}>
                                                <thead>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <th>등급</th>
                                                    <th>분석지수</th>
                                                    <th>분류기준</th>
                                                </tr>
                                                {
                                                    preEEGLevels
                                                        .slice(0, 7)
                                                        .map((row, index) => {
                                                            let text =  preEEG[0].levelName === row.levelName ? 'LEFT' : 'RIGHT'
                                                            let textAlign = preEEG[0].levelName === row.levelName ? 'left' : 'right'
                                                            if(preEEG[0].levelName === row.levelName && preEEG[1].levelName === row.levelName) {
                                                              text = 'LEFT, RIGHT'
                                                              textAlign = 'left'
                                                            }
                                                            return (
                                                                <>
                                                                    <tr key={`tr-${index}`}
                                                                        style={{
                                                                          backgroundColor: row.backgroundColor,
                                                                          // position: 'relative',
                                                                        }}
                                                                        className={`preEEG-${row.level}`}
                                                                    >
                                                                      <td>{row.levelName}</td>
                                                                      <td>{row.name}</td>
                                                                      <td className={clsx('c-r-border',{
                                                                        ['text-blue'] : '0급' !== row.levelName
                                                                      })}>{row.levelText}</td>
                                                                    </tr>

                                                                    {(preEEG[0].levelName === row.levelName) &&
                                                                        <tr>
                                                                            <td colspan={3} style={{position: 'absolute', width: '100%', height: 106, marginTop: -106}}>
                                                                                <div style={{ border: '8px solid red', width: '100%',  height: '100%'}}>
                                                                                  {/*<div className={'left-or-right'} style={{textAlign: textAlign }}>{text}</div>*/}
                                                                                  &nbsp;
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    {(preEEG[1].levelName === row.levelName) &&
                                                                        <tr>
                                                                            <td colspan={3} style={{position: 'absolute', width: '100%', height: 106, marginTop: -106}}>
                                                                                <div style={{ border: '8px solid green', width: '100%',  height: '100%'}}>
                                                                                  {/*<div className={'left-or-right'} style={{textAlign: textAlign }}>{text}</div>*/}
                                                                                  &nbsp;
                                                                                </div>
                                                                          </td>
                                                                      </tr>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{position: 'relative'}}>
                                          <table className={'print-table'}
                                                 style={{height: 850, border: `3px solid #000`, width: `100%`}}>
                                              <thead></thead>
                                              <tbody>
                                              <tr>
                                                  <th>등급</th>
                                                  <th>분석지수</th>
                                                  <th>분류기준</th>
                                              </tr>
                                              {
                                                  preEEGLevels
                                                      .slice(7, 14)
                                                      .map((row, index) => {
                                                          let text =  preEEG[0].levelName === row.levelName ? 'LEFT' : 'RIGHT'
                                                          let textAlign = preEEG[0].levelName === row.levelName ? 'left' : 'right'
                                                          if(preEEG[0].levelName === row.levelName && preEEG[1].levelName === row.levelName) {
                                                            text = 'LEFT, RIGHT'
                                                            textAlign = 'left'
                                                          }
                                                        
                                                          return (
                                                              <>
                                                                  <tr key={`tr-${index}`}
                                                                      style={{
                                                                        backgroundColor: row.backgroundColor,
                                                                      }}
                                                                      className={`preEEG-${row.level}`}
                                                                  >
                                                                    <td>{row.levelName}</td>
                                                                    <td>{row.name}</td>
                                                                    <td className={clsx('c-r-border',{
                                                                      ['text-blue'] : '0급' !== row.levelName,
                                                                    })}>{row.levelText}</td>
                                                                  </tr>
                                                                  {(preEEG[0].levelName === row.levelName) &&
                                                                  <tr>
                                                                    <td colspan={3} style={{position: 'absolute', width: '100%', height: 106, marginTop: -106}}>
                                                                      <div style={{ border: '8px solid red', width: '100%',  height: '100%'}}>
                                                                        {/*<div className={'left-or-right'} style={{textAlign: textAlign }}>{text}</div>*/}
                                                                        &nbsp;
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                  }
                                                                  {(preEEG[1].levelName === row.levelName) &&
                                                                    <tr>
                                                                      <td colspan={3} style={{position: 'absolute', width: '100%', height: 106, marginTop: -106}}>
                                                                        <div style={{ border: '8px solid green', width: '100%',  height: '100%'}}>
                                                                          {/*<div className={'left-or-right'} style={{textAlign: textAlign }}>{text}</div>*/}
                                                                          &nbsp;
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                  }
                                                              </>
                                                          )
                                                      })
                                              }
                                              </tbody>
                                          </table>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Box>
                </Box>
                <Box className={classes.bottomTitle}>
                    <Grid container style={{width: '100%'}}>
                        <Grid item xs={6} className={'text-left p-l-100'}> - 3 -</Grid>
                        <Grid item xs={6} className={'text-right'}>한국뇌건강증진운동연구소 https://kbhd.org</Grid>
                    </Grid>
                </Box>
            </div>

            <div className={'content-print'}>
                <Box className={classes.root}>
                    <Box className={classes.headerBox}>
                        <div>
                            <div className={classes.title}>좌우뇌 균형상태 분석</div>
                            <div className={classes.titleSub}>[Brain Quotient Analysis]</div>
                        </div>
                    </Box>
                    <Box className={classes.contentBox}>
                        <div className={classes.innerBox}>
                            <div className={clsx(classes.innerTitle, classes.mb60, 'inner-title')} style={{padding:0}}>
                                {preBalance && preBalance[0] &&
                                    <Grid container>
                                        <Grid item style={{position: 'relative', paddingLeft:60, paddingRight:100}}>
                                            <img className={'ico'} src={'/images/circle.svg'}/>
                                            좌뇌 (Left):
                                          <div style={{borderBottom:`10px solid red`, marginLeft: 20, display:'inline'}}>{preBalance[0].levelText} {preBalance[0].levelName}</div>
                                        </Grid>
                                        <Grid item style={{position: 'relative', paddingLeft:60}}>
                                            <img className={'ico'} src={'/images/circle.svg'}/>
                                            우뇌 (Right):
                                          <div style={{borderBottom:`10px solid red`, marginLeft: 20, display:'inline'}}>{preBalance[1].levelText} {preBalance[1].levelName}</div>
                                        </Grid>
                                    </Grid>
                                }
                            </div>
                            <div className={classes.eegImage} style={{height: 600, paddingTop: 100}}>
                                {measureCurrent &&
                                    <BrainBalanceMap
                                        circleWidth={240}
                                        height={400}
                                        fontSize={30}
                                        bandRangeData={measureResult.bandRangeData}
                                    />
                                }
                            </div>
                            <div style={{marginBottom: 60}}></div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'}/>
                                좌우뇌 균형상태
                            </div>
                            <div className={classes.innerTitleDesc}>
                                주파수(HZ)별 좌뇌와 우뇌의 뇌파(EEG) 대역과 진폭의 균형 정도(%)를 대칭과 비대칭을 분석하여<br/>
                                표기한 원형 그래프 입니다.<br/>
                                좌뇌와 우뇌의 원형분석은 주파수별로 특성에 따라 분류하여 볼 수 있으며 불균형이 심한 경우 <br/>
                                언어장애, 정서장애, 활성장애, 육체적 불균형 등 문제점들이 나타날 수 있습니다. <br/>
                                특히 깊은 숙면을 하기 어렵습니다.
                            </div>
                            <div style={{marginBottom: 130}}></div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'}/>
                                좌우뇌 균형 판별 기준표
                            </div>

                            <div className={classes.innerTitleDesc}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <div style={{position: 'relative'}}>
                                            <table className={'print-table'}
                                                   style={{height: 850, border: `3px solid #000`, width: `100%`}}>
                                                <thead>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <th>등급</th>
                                                    <th>분석지수</th>
                                                    <th>분류기준</th>
                                                </tr>
                                                {
                                                    preBalanceLevels
                                                        .slice(0, 5)
                                                        .map((row, index) => {
                                                            let text =  preBalance[0].levelName === row.levelName ? 'LEFT' : 'RIGHT';
                                                            let textAlign = preBalance[0].levelName === row.levelName ? 'left' : 'right';
                                                            if(preBalance[0].levelName === row.levelName && preBalance[1].levelName === row.levelName) {
                                                              text = 'LEFT, RIGHT'
                                                              textAlign = 'left'
                                                            }
                                                            return (
                                                                <>
                                                                    <tr key={`tr-${index}`}
                                                                        style={{
                                                                          backgroundColor: row.backgroundColor,
                                                                          // position: 'relative',
                                                                        }}
                                                                    >
                                                                      <td>{row.levelName}</td>
                                                                      <td>{row.name}</td>
                                                                      <td className={clsx('c-r-border',{
                                                                        ['text-blue'] : '0급' !== row.levelName,
                                                                      })}>{row.levelText}</td>
                                                                    </tr>
                                                                    {(preBalance[0].levelName === row.levelName) &&
                                                                        <tr>
                                                                            <td colspan={3} style={{position: 'absolute', width: '100%', height: 140, marginTop: -140}}>
                                                                                <div style={{ border: '8px solid red', width: '100%',  height: '100%'}}>
                                                                                  {/*<div className={'left-or-right'} style={{textAlign: textAlign }}>{text}</div>*/}
                                                                                  &nbsp;
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    {(preBalance[1].levelName === row.levelName) &&
                                                                      <tr>
                                                                        <td colspan={3} style={{position: 'absolute', width: '100%', height: 140, marginTop: -140}}>
                                                                          <div style={{ border: '8px solid green', width: '100%',  height: '100%'}}>
                                                                            {/*<div className={'left-or-right'} style={{textAlign: textAlign }}>{text}</div>*/}
                                                                            &nbsp;
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                    }
                                                                </>
                                                                
                                                            )
                                                        })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{position: 'relative'}}>
                                            <table className={'print-table'}
                                                   style={{height: 850, border: `3px solid #000`, width: `100%`}}>
                                                <thead></thead>
                                                <tbody>
                                                <tr>
                                                    <th>등급</th>
                                                    <th>분석지수</th>
                                                    <th>분류기준</th>
                                                </tr>
                                                {
                                                    preBalanceLevels
                                                        .slice(5, 10)
                                                        .map((row, index) => {
                                                            let text =  preBalance[0].levelName === row.levelName ? 'LEFT' : 'RIGHT';
                                                            let textAlign = preBalance[0].levelName === row.levelName ? 'left' : 'right';
                                                            if(preBalance[0].levelName === row.levelName && preBalance[1].levelName === row.levelName) {
                                                              text = 'LEFT, RIGHT'
                                                              textAlign = 'left'
                                                            }
                                                            return (
                                                                <>
                                                                    <tr key={`tr-${index}`}
                                                                        style={{
                                                                            backgroundColor: row.backgroundColor,
                                                                        }}
                                                                    >
                                                                        {/*{(preBalance[0].levelName === row.levelName || preBalance[1].levelName === row.levelName) &&*/}
                                                                        {/*  <div style={{ position: 'absolute', border: '8px solid red', width: 'calc(100% + 16px)', left: '-8px', top: 0, height: '100%', textAlign: 'right' }}>&nbsp;</div>*/}
                                                                        {/*}*/}
                                                                        <td>{row.levelName}</td>
                                                                        <td>{row.name}</td>
                                                                        <td className={clsx('c-r-border',{
                                                                            ['text-blue'] : '0급' !== row.levelName
                                                                        })}>{row.levelText}</td>
                                                                    </tr>
                                                                    {(preBalance[0].levelName === row.levelName ) &&
                                                                        <tr>
                                                                            <td colspan={3} style={{position: 'absolute', width: '100%', height: 140, marginTop: -140}}>
                                                                                <div style={{ border: '8px solid red', width: '100%',  height: '100%'}}>
                                                                                  {/*<div className={'left-or-right'} style={{textAlign: textAlign }}>{text}</div>*/}
                                                                                  &nbsp;
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    {( preBalance[1].levelName === row.levelName) &&
                                                                      <tr>
                                                                        <td colspan={3} style={{position: 'absolute', width: '100%', height: 140, marginTop: -140}}>
                                                                          <div style={{ border: '8px solid green', width: '100%',  height: '100%'}}>
                                                                            {/*<div className={'left-or-right'} style={{textAlign: textAlign }}>{text}</div>*/}
                                                                            &nbsp;
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{marginBottom: 60}}></div>
                            <div className={classes.innerTitleDesc}>
                                표와 같이 좌뇌 50% 우뇌 50%를
                                비율의 편차에 따라 대칭으로
                                균형 상태인지 불균형 상태인지에
                                따라 등급, 편차지수, 분류기준을
                                표기한 것입니다.
                                정상등급은 0등급에서 1등급까지
                                95% 편차지수, 위급관리
                                5단계까지 단계적으로 기준을
                                분류한 것입니다.
                            </div>
                        </div>
                    </Box>
                </Box>
                <Box className={classes.bottomTitle}>
                    <Grid container style={{width: '100%'}}>
                        <Grid item xs={6} className={'text-left p-l-100'}> - 4 -</Grid>
                        <Grid item xs={6} className={'text-right'}>한국뇌건강증진운동연구소 https://kbhd.org</Grid>
                    </Grid>
                </Box>
            </div>

            <div className={'content-print'}>
                <Box className={classes.root}>
                    <Box className={classes.headerBox}>
                        <div>
                            <div className={classes.title}>뇌균형상태 분석 BBCO</div>
                            <div className={classes.titleSub}>[Brain Balance Condition]</div>
                        </div>
                    </Box>
                    <Box className={classes.contentBox}>
                        <div className={classes.innerBox}>
                            <div className={classes.eegImage} style={{height: 970}}>
                                {measureResult &&
                                    <>
                                        <ViewBrainHexMap
                                            width={440}
                                            height={440}
                                            prescriptionID={measureResult.id} prescription={measureResult}/>
                                        <MeasureState
                                            maxWidth={clientWidth}
                                            boxHeight={48}
                                            boxWidth={40}

                                            {...measureResult}
                                        />
                                    </>
                                }
                            </div>
                            <div>
                                <div className={classes.innerTitle} style={{textAlign: 'center'}}>
                                    <div>뇌기능 균형상태</div>
                                    <div style={{marginTop: 38, fontSize: 52}}>뇌기능 균형상태란 안정, 주의력, 집중력, 뇌활성 상태를 측정하여 분석한
                                        것입니다.
                                    </div>
                                </div>
                            </div>
                            <div style={{marginBottom: 100}}></div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'} alt={''}/>
                                안정상태
                            </div>
                            <div className={classes.innerTitleDesc}>
                                뇌와 몸의 건강을 유지하기 위한 가장 기본적인 안정 정도를 분석한 것입니다.<br/>
                                피로회복 능력, 편안함 등 안정을 이루는 주파수 8~12Hz 뇌파를 분석한 것입니다.
                            </div>
                            <div style={{marginBottom: 60}}></div>

                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'}/>
                                주의력상태
                            </div>
                            <div className={classes.innerTitleDesc}>
                                뇌가 각성할 수 있는 정도와 동기부여 상태, 자기 주변과의 관계 능력(사회성, 사교성, 교감과 공감능력), 감정조절 능력 등 균형을 이룰 수 있는지 주파수
                                12~15Hz 뇌파를 분석한 것입니다.
                                좌뇌에 비해 우뇌 기능의 활동 정도를 더 자세히 파악할 수 있습니다.
                            </div>
                            <div style={{marginBottom: 60}}></div>

                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'} alt={'image'}/>
                                집중력상태
                            </div>
                            <div className={classes.innerTitleDesc}>
                                뇌가 마음과 정신을 하나로 모아 한 가지 일에 몰두할 수 있는 능력을 집중력 상태라 하며, <br/>
                                이성적, 정확성, 적극성, 언어능력 등을 파악할 수 있습니다. 주파수 16~20Hz 뇌파를 분석한
                                것이며, 우뇌의 기능보다 좌뇌 기능의 활동 정도를 더 자세히 파악할 수 있습니다.
                            </div>
                            <div style={{marginBottom: 60}}></div>

                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'} alt={'image'}/>
                                활성상태
                            </div>
                            <div className={classes.innerTitleDesc}>
                                집중력 상태보다 높아진 의식상태이며 몰두에 있는 일에서 빠져나오고 싶은 않은 상태입니다. <br/>
                                주파수 20~24Hz 뇌파를 분석한 것입니다. 기획력, 추진력, 사고력, 수리력, 집착정도, 행동성향 등의 균형을 이룰 수 있는 능력 정도를 분석한 것입니다.
                            </div>

                        </div>
                    </Box>
                </Box>
                <Box className={classes.bottomTitle}>
                    <Grid container style={{width: '100%'}}>
                        <Grid item xs={6} className={'text-left p-l-100'}> - 5 -</Grid>
                        <Grid item xs={6} className={'text-right'}>한국뇌건강증진운동연구소 https://kbhd.org</Grid>
                    </Grid>
                </Box>
            </div>

            <div className={'content-print'}>
                <Box className={classes.root}>
                    <Box className={classes.headerBox}>
                        <div>
                            <div className={classes.title}>뇌기능 의식패턴 분석</div>
                            <div className={classes.titleSub}>[Conscious-Pattern Analysis]</div>
                        </div>
                    </Box>
                    <Box className={classes.contentBox}>
                        <div className={classes.innerBox}>
                            <div className={classes.eegImage} style={{height: 1240}}>
                                {measureResult &&
                                    <BrainActive
                                        zoom={'140%'}
                                        barchartWidth={800}
                                        barchartHeight={500}
                                        fontSize={20}
                                        valueType={'int'}
                                        prescriptionID={measureResult.id}
                                        brainActiveData={brainActive}
                                        animationDuration={0}
                                        showTable={'yes'}
                                    />
                                }
                            </div>
                            <div style={{marginBottom: 150}}></div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'} alt={''}/>
                                안정상태
                            </div>
                            <div className={classes.innerTitleDesc}>
                                뇌파 의식별 동조화 패턴과 분포도의 변화를 3단계로 분석한 것입니다. 상단에 밴드별 뇌파분석은 주파수(Hz)별 델타파(δ) 1~4Hz 미만, 세타파(θ)
                                4~8Hz 미만, 알파파(α)
                                8~12Hz 미만, SMR파 12~15Hz 미만, 베타파(β) 16~20Hz, 중간 베타파(βm) 20~24Hz 미만, 높은 베타파(βh) 24~28Hz
                                미만과 진폭(mv)을 정량화하여 각각 기능별 분석한 것입니다.
                            </div>
                            <div style={{marginBottom: 40}}></div>
                            <div className={classes.innerTitleDesc}>
                                <Grid container className={classes.innerTitleDescSub}>
                                    <Grid item xs={2}>
                                        <b className={classes.fsb40}> 1. MA :</b>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <b className={classes.fsb40}>높은 의식상태</b> 동조화 패턴은 밴드별 의식의 주파수(Hz)와 진폭(mv)과 <br/>
                                        좌우뇌 편차 알파(α)와 세타파(θ)의 융합 분포도를 분석한 것이다.
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{marginBottom: 20}}></div>
                            <div className={classes.innerTitleDesc}>
                                <Grid container className={classes.innerTitleDescSub}>
                                    <Grid item xs={2}>
                                        <b className={classes.fsb40}> 2. MM :</b>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <b className={classes.fsb40}>중간 의식 경계지점</b> 동조화 패턴은 밴드별 의식의 주파수(Hz)와 진폭(mv)과
                                        좌우뇌 편차, 알파(α)와 세타파(θ)의 융합 분포도를 분석한 것이다.
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{marginBottom: 20}}></div>
                            <div className={classes.innerTitleDesc}>
                                <Grid container className={classes.innerTitleDescSub}>
                                    <Grid item xs={2}>
                                        <b className={classes.fsb40}> 3. MB : </b>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <b className={classes.fsb40}>낮은 의식 하강상태</b> 동조화 패턴은 밴드별 의식의 주파수(Hz)와 진폭(mv)과
                                        알파(α)와 세타파(β)의 융합 분포도를 분석한 것이다.
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{marginBottom: 40}}></div>
                            <div className={classes.innerTitleDesc}>
                                하단 네모 칸 분석지수는 MA, MM, MB의 3가지 의식 패턴을 3단계로 측정할 때 세타파(4~8Hz 미만)와 알파파 (8~12Hz)가 서로 몇 번의
                                교차점과 융합되었는지 알 수 있도록 분석하여 융합 분포도를 표기한 그래프입니다.
                            </div>

                        </div>
                    </Box>
                </Box>
                <Box className={classes.bottomTitle}>
                    <Grid container style={{width: '100%'}}>
                        <Grid item xs={6} className={'text-left p-l-100'}> - 6 -</Grid>
                        <Grid item xs={6} className={'text-right'}>한국뇌건강증진운동연구소 https://kbhd.org</Grid>
                    </Grid>
                </Box>
            </div>

            <div className={'content-print'}>
                <Box className={classes.root}>
                    <Box className={classes.headerBox} style={{height: 584}}>
                        <div>
                            <div className={classes.title}>항상성 자기조절 분석</div>
                            <div className={classes.titleSub}>[Homeostasis Self-regulation Analysis]</div>
                        </div>
                    </Box>
                    <Box className={classes.contentBox}>
                        <div className={classes.innerBox}>
                            <div className={classes.eegImage} style={{height: 1950}}>
                                {measureResult &&
                                    <MeasureBandTest
                                        zoom={'140%'}
                                        prescriptionID={measureResult.id}
                                        showAll={true}
                                        fontSize={20}
                                        valueType={'int'}
                                        bandData={bandData}
                                        images={bandDataImages}
                                        animationDuration={0}
                                        fftType={measureResult.fftType}
                                        printMode={'yes'}
                                        showTable={'yes'}
                                    />
                                }
                            </div>
                            <div style={{marginBottom: 100}}></div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'} alt={''}/>
                                항상성 자기조절 분석
                            </div>
                            <div className={classes.innerTitleDesc}>
                                뇌와 인체가 상호작용하여 신경계, 호흡계, 내분비계 등과 서로 변화를 증가 또는 감소시키는 피드백 작용을 어떻게 조절하고 있는지 분석한 것입니다. 눈을
                                뜨고(EO) 1분 30초, 눈을 감고(EC) 1분 30초, 눈을 뜨고(EO) 1분 30초 주파수(1~30Hz), 진폭(mv), 위상, 좌뇌와 우뇌 편차 등 뇌파(EEG)별 측정해
                                분석한 것입니다. 항상성 자기조절 분석은 정신과 몸과 마음을 얼마나 균형에 맞게 최적화 상태로 조절하고 있는지 그 정도를 파악하는 그래프입니다.
                            </div>
                        </div>
                    </Box>
                </Box>
                <Box className={classes.bottomTitle}>
                    <Grid container style={{width: '100%'}}>
                        <Grid item xs={6} className={'text-left p-l-100'}> - 7 -</Grid>
                        <Grid item xs={6} className={'text-right'}>한국뇌건강증진운동연구소 https://kbhd.org</Grid>
                    </Grid>
                </Box>
            </div>

            <div className={'content-print'}>
                <Box className={classes.root}>
                    <Box className={classes.headerBox} style={{height: 582}}>
                        <div>
                            <div className={classes.title}>뇌기능 특성 분석</div>
                            <div className={classes.titleSub}>[Brain Trait Analysis]</div>
                        </div>
                    </Box>
                    <Box className={classes.contentBox}>
                        <div className={classes.innerBox}>
                            <div className={classes.eegImage} style={{height: 1700}}>
                                {stressResult &&
                                    <ResultBalance
                                        zoom={'187%'}
                                        fontSize={15}
                                        suggestedMax={200}
                                        prescriptionID={prescriptionID}
                                        measureData={stressResult.tendency}
                                    />
                                }
                            </div>
                            <div style={{marginBottom: 130}}></div>
                            <div>
                                <div className={classes.innerTitle}>
                                    <div style={{textAlign: 'center'}}>뇌특성 분석이란</div>
                                    <div style={{marginTop: 38, fontSize: 47}}>
                                        뇌특성 분석은 의식적 기능의 뇌파(EEG) 8~24Hz 주파수(Hz)별, 포인트별, 원형분석과 밴드별 분석을 <br/>
                                        통해 뇌기능 특성을 정밀하게 분석한 것입니다. 사람은 개인별 살아온 환경이나 생활습관에 따라 뇌의 <br/>
                                        성향이 다르며, 유전적, 가족력 등의 영향을 받을 수 있습니다.
                                    </div>
                                </div>
                            </div>

                            <div style={{marginBottom: 100}}></div>
                            <div className={classes.innerTitleDesc}>
                                <div style={{maxWidth: '90%', paddingLeft: 100}}>
                                    <div>
                                        <div>• 8Hz - 12Hz α파 Mapping 정밀한 뇌기능 상태는 상기와 같습니다.</div>
                                        <div>• 12Hz - 16Hz 이하 SMR파 Mapping 정밀한 뇌기능 상태는 상기와 같습니다.</div>
                                        <div>• 16Hz - 20Hz 이하 β파 Mapping 정밀한 뇌기능 상태는 상기와 같습니다.</div>
                                        <div>• 20Hz - 24Hz βm파 Mapping 정밀한 뇌기능 상태는 상기와 같습니다.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
                <Box className={classes.bottomTitle}>
                    <Grid container style={{width: '100%'}}>
                        <Grid item xs={6} className={'text-left p-l-100'}> - 8 -</Grid>
                        <Grid item xs={6} className={'text-right'}>한국뇌건강증진운동연구소 https://kbhd.org</Grid>
                    </Grid>
                </Box>
            </div>

            <div className={'content-print'}>
                <Box className={classes.root}>
                      <Box className={classes.headerBoxSingle} style={{height: 381}}>
                        <div>
                            <div className={classes.title}>자기조절 분석</div>
                        </div>
                    </Box>
                    <Box className={classes.contentBoxSingle}>
                        <div className={classes.innerBox} style={{height: 1340}}>
                            <div className={classes.eegImage} style={{height: 800, paddingLeft: 80}}>
                                {stressResult && 
                                    <ResultRegulation 
                                        prescriptionID={prescriptionID} 
                                        measureData={stressResult.regulation}
                                        fontSize={40}
                                        titleFontSize={50}
                                        title={'뇌기능 자기조절 분석'}
                                    />
                                }
                            </div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'} alt={''}/>
                                <span style={{fontWeight: 'normal', fontSize: 50}}>α 조절능력은 정신과 몸의 안정, 피로회복, 침착성, 지구력 정도를 분석한 것입니다.</span>
                            </div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'} alt={''}/>
                                <span style={{fontWeight: 'normal', fontSize: 50}}>SMR 조절능력은 사회성, 주의력, 공감력, 관찰력, 발표력 등을 분석한 것입니다</span>
                            </div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'} alt={''}/>
                                <span style={{fontWeight: 'normal', fontSize: 50}}>β 조절능력은 한 가지에 몰두하는 집중력, 추진력, 정확성, 적극성 등을 분석한 것입니다.</span>
                            </div>
                        </div>
                        <div style={{marginBottom: 50, textAlign: 'center'}}>
                            <div className={classes.title} style={{marginTop: 80, paddingTop: 0}}>정서상태분석</div>
                        </div>
                        <div className={classes.innerBox} style={{height: 1340}}>
                            <div className={classes.eegImage} style={{height: 800, paddingLeft: 80}}>
                                {stressResult &&
                                    <ResultEmotion 
                                        prescriptionID={prescriptionID} 
                                        measureData={stressResult.emotion}
                                        fontSize={40}
                                        titleFontSize={50}
                                    />
                                }
                            </div>
                            <div style={{marginBottom: 60}}></div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'} altr={'image'}/>
                                안정 & 불안정 상태 
                                <span style={{fontWeight: 'normal', fontSize: 50}}>는 정신적 안정과 육체적 안정, 우울과 조울의 성향을 분석한 것입니다.</span>
                            </div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'} altr={'image'}/>
                                산만율 분석
                                <span style={{fontWeight: 'normal', fontSize: 50}}>은 정신과 몸의 불안 정도를 분석한 것입니다.</span>
                            </div>
                        </div>
                    </Box>
                </Box>
                <Box className={classes.bottomTitle}>
                    <Grid container style={{width: '100%'}}>
                        <Grid item xs={6} className={'text-left p-l-100'}> - 9 -</Grid>
                        <Grid item xs={6} className={'text-right'}>한국뇌건강증진운동연구소 https://kbhd.org</Grid>
                    </Grid>
                </Box>
            </div>
            
            <div className={'content-print'}>
                <Box className={classes.root}>
                    <Box className={classes.headerBoxSingle} style={{height: 381}}>
                        <div>
                            <div className={classes.title}>정신적 스트레스와 저항 분석</div>
                        </div>
                    </Box>
                    <Box className={classes.contentBoxSingle}>
                        <div className={classes.innerBox} style={{height: 1340}}>
                            <div className={classes.eegImage} style={{height: 800, paddingLeft: 80}}>
                                {stressResult &&
                                    <ResultStress 
                                        prescriptionID={prescriptionID} 
                                        showType={'emotion'}
                                        fontSize={40}
                                        titleFontSize={50}
                                        measureData={stressResult.stress}/>
                                }
                            </div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'} alt={''}/>
                                정신적 스트레스 분석
                                <span style={{fontWeight: 'normal', fontSize: 50}}>은 스트레스를 받은 정도의 크기를 좌뇌와 우뇌로 구분하여 밴드별, 원형별 기능 정도를 지수로 분석한 것입니다.</span>
                            </div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'} alt={''}/>
                                정신적 저항력 분석
                                <span style={{fontWeight: 'normal', fontSize: 50}}>은 스트레스를 받았을 때, 그 자극에 대한 반응에 저항하여 감당할 수 있는 능력 정도를 지수로 분석한 것입니다.</span>
                            </div>
                        </div>
                        <div style={{marginBottom: 50, textAlign: 'center'}}>
                            <div className={classes.title} style={{marginTop: 80, paddingTop: 0}}>육체적 스트레스와 저항 분석</div>
                        </div>
                        <div className={classes.innerBox} style={{height: 1340}}>
                            <div className={classes.eegImage} style={{height: 800, paddingLeft: 80}}>
                                {stressResult &&
                                    <ResultStress 
                                        prescriptionID={prescriptionID} 
                                        showType={'health'}
                                        measureData={stressResult.stress}
                                        fontSize={40}
                                        titleFontSize={50}
                                    />
                                }
                            </div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'} altr={'image'}/>
                                육체적 스트레스 분석
                                <span style={{fontWeight: 'normal', fontSize: 50}}>은 몸이 아프거나 어떤 자극의 크기가 얼마나 되는지 밴드별, 원형별 좌뇌와 우뇌를 각각 지수로 분석한 것입니다.</span>
                            </div>
                            <div className={clsx(classes.innerTitle, classes.mb40, 'inner-title')}>
                                <img className={'ico'} src={'/images/circle.svg'} alt={'image'}/>
                                육체적 저항력 분석
                                <span style={{fontWeight: 'normal', fontSize: 50}}>은 몸이 아프거나 그 자극에 대한 반응에 저항하여 우뇌가 감당할 수 있는 능력정도를 각각지수로 분석한 것입니다.</span>
                            </div>
                        </div>
                    </Box>
                </Box>
                <Box className={classes.bottomTitle}>
                    <Grid container style={{width: '100%'}}>
                        <Grid item xs={6} className={'text-left p-l-100'}> - 10 -</Grid>
                        <Grid item xs={6} className={'text-right'}>한국뇌건강증진운동연구소 https://kbhd.org</Grid>
                    </Grid>
                </Box>
            </div>
        </div>
    ); 
}

const useStyles = makeStyles(() => ({
    mb60:{ marginBottom: 60},
    mb40:{ marginBottom: 40},
    
    root: {
        fontFamily: 'KoPub Dotum',
        // width: `21cm`,
        // minHeight: `29.5cm`,
        backgroundColor: '#30468F',
        color: "white"
    },
    headerBox: {
        // padding:  40,
        // paddingTop: 100,
        height:590,
        textAlign: "center",
    },
    headerBoxSingle: {
        // padding:  40,
        // paddingTop: 100,
        height:391,
        textAlign: "center",
    },
    title: {
        paddingTop: 210,
        fontSize: `100px`,
        fontWeight: 'bold',
    },
    titleSub: {
        fontFamily: 'Noto Sans KR',
        fontSize: 72,
        fontWeight: 'bold',
    },
    contentBox: {
        paddingBottom: 190,
        paddingLeft: 134,
        paddingRight: 134,
        height: `calc(3508px - 595px)`
    },
    contentBoxSingle: {
        paddingBottom: 190,
        paddingLeft: 134,
        paddingRight: 134,
        height: `calc(3508px - 395px)`
    },
    /*
    height offset = 234 = headerbox Height(144) + contentBox padding (90)
     */
    innerBox: {
        backgroundColor: "white",
        color: "black",
        borderRadius: 50, 
        height: `calc(3508px - 785px)`,
        padding: 150,
    },
    eegImage: {
        height: 850,
        width: '100%',
        // background: '#efefef',
    },
    innerTitle: {
        fontSize: 58,
        fontWeight: 'bold',
    },
    sub: {
        marginBottom: `50px !important`
    },
    innerTitleDesc: {
        fontSize: 48,
        fontWeight: "normal",
        paddingLeft: 50
    },
    innerTitleDescSub: {
        paddingLeft: 50,
        maxWidth: '95%'
    },
    fsb40: {
        fontSize: 48,
        fontWeight: 'bold'
    },
    bottomTitle: {
        position: 'absolute',
        left: 134, 
        bottom: 10, 
        width: `calc(100% - 268px)`,
        height: 190,
        fontWeight: "bold",
        color: 'white',
        fontSize: 50,
        lineHeight: `190px`,
    }

}));

export default MeasureCurrentPrint