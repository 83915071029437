import React, {useCallback, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateHelper from '@helper/DateHelper'
import PrescriptionService from '@user-service/PrescriptionService'
import clsx from "clsx";
import Message from "../Message";
import AuthProvider from '@user-provider/AuthProvider'
import {Box} from "@material-ui/core";

const ExerciseDetail = (props) => {
    const classes = useStyles()
    const {exercises, canExercise} = props
    return (
        exercises.map((item, index) => {
            const { log, image, name, exerciseCount, countUnitName, description, measureTypeName, measureCode } = item
            return (
                <li key={item.prescriptionExerciseID} className={classes.exercise}>
                    {(log && 'yes' === log.isDone) &&
                    <div className={clsx('check', classes.check)}><span>완료</span></div>
                    }
                    <dl onClick={() => {
                        // history.push(`/exercise${item.programUrl}?prescriptionExerciseID=${item.prescriptionExerciseID}`)
                        if(canExercise) document.location.href = `/exercise${item.programUrl}?prescriptionExerciseID=${item.prescriptionExerciseID}`;
                        else {
                            alert('처방전 기간이 만료되었습니다.\n담당자에게 문의하여 다시 측정해 주십시요')
                        }
                    }}>
                        <dt><img src={require(`@images/${image}`)} alt={''}/></dt>
                        <dd>
                            <p>
                                {index + 1}. {name}
                                <span>{measureTypeName} - {measureCode}, {exerciseCount} {countUnitName}</span>
                            </p>
                            {description}
                        </dd>
                        {(log && 'yes' === log.isDone) &&
                        <div className={'log'}>
                            {DateHelper.fromNow(log.startDate)}
                        </div>
                        }
                    </dl>
                </li>
            )
        })
    )
}

const DashExercise = (props) => {
    const { data } = props
    const [exerciseGroup, setExerciseGroup ] = useState(null);
    const [lastPrescriptionID , setLastPrescriptionID] = useState(null)
    const classes = useStyles()
    
    const fetch = useCallback(async () => {
        const today = DateHelper.now('YYYY-MM-DD');
        const result = await PrescriptionService.getExercise(data.id, true, today, null , 'yes')

        const exGroup = [ [],[],[] ]
        if(result){
            result.exercises.map((ex) => {
                exGroup[ex.term].push(ex)
                return ex
            })

            setLastPrescriptionID(result.lastPrescriptionID)
        }
        setExerciseGroup(exGroup)
    }, [data.id])

    useEffect(() => {
        fetch().then()
    }, [fetch]);

    if(!exerciseGroup) return <></>
    const profileID = AuthProvider.isCustomerView() ? AuthProvider.getCustomerProfileID() : AuthProvider.getProfileID()
    const expireDate = data.prescription.expireDate ? data.prescription.expireDate : null
    const canExercise = DateHelper.isBefore(expireDate)
    
    return (
        <div className="sec-presc">
            <div className="innerbox">
                {/*<a href="#" className="bt-more"><span>더보기</span></a>*/}
                <h4>
                    처방운동 <i>Brain Health</i>
                </h4>
                {lastPrescriptionID &&
                    <div style={{textAlign: 'center', marginTop: -20, marginBottom: 40}}>
                        <div style={{fontSize: '1.4rem', fontWeight: 'bold'}}>{lastPrescriptionID} 회차</div>
                        {exerciseGroup && exerciseGroup[0] && exerciseGroup[0][0] &&
                        <div style={{fontWeight: 'bold', color: '#666'}}>{DateHelper.toDateFormat(exerciseGroup[0][0].createdAt, 'YYYY년 MM월 DD일')} 처방</div>
                        }
                    </div>
                }
                <Box>
                    <Message
                        profileID={profileID}
                        hideWriteBox={true}
                        hideEmptyMessage={true}
                        prescriptionID={data.id}
                        useHighlight={true}
                    />
                    {expireDate &&
                    <Box mt={1} className={classes.expireDate}>
                        <h3>해당 처방전은 {DateHelper.toDateFormat(expireDate, 'YYYY년 MM월 DD일')}까지 유효합니다.</h3>
                    </Box>
                    }
                    {exerciseGroup.map((exercises, index) => {
                        return (
                            <React.Fragment key={`ul-${index}`}>
                                {0 < exerciseGroup[1].length &&
                                <h5 className={classes.exerciseTitle}>{index + 1}회차</h5>
                                }

                                <ul>
                                    <ExerciseDetail exercises={exercises} canExercise={canExercise}/>
                                </ul>
                            </React.Fragment>
                        )
                    })}
                </Box>
                
                {0 === exerciseGroup[0].length &&
                <li className={classes.empty}>
                    <img src={require('@images/brainLightIcon.png')} alt={'icon'}/>
                    <h4>{data.id}회차 처방이 준비중입니다.</h4>
                    <div>담당자에게 문의하세요</div>
                </li>
                }
            </div>
        </div>
    )
}


const useStyles = makeStyles(() => ({
    check: {
        '& span':{
            backgroundImage: `url(${require('@images/ico_check.png')})`
        }
    },
    exerciseTitle: {
        margin: '1rem 0',
        fontSize: '1.5rem',
        backgroundColor: '#ededed',
        padding: '1rem'
    },
    exercise: {
        cursor: "pointer",
        '&:hover' : {
            backgroundColor: 'rgba(0,0,0,0.1)',
            transitionDuration: '0.3s',
            borderRadius: 10
        }
    },
    expireDate: {
        backgroundColor: '#ABE1CB',
        padding: 10,
        borderRadius: 10,
        '& h3': {
            color: '#176646',
            fontWeight: '400',
        }
        
    },
    empty: {
        border:0,
        textAlign: 'center',
        width: '1280px',
        color: '#999',
        '& h4': {
            fontSize: '28px !important',
            margin:0,
            height: '90px',
            color: '#999'  
        },
        '& img': {
            width : '80px',
            marginTop: '50px'       
        }
    }
}));

export default DashExercise
