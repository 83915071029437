import React, {useCallback, useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useHistory } from "react-router-dom";
import {useCookies} from "react-cookie";
import AuthProvider from '@user-provider/AuthProvider'
import ProfileService from '@user-service/ProfileService'
import MeasureHelper from '@helper/MeasureHelper'
import DeviceHelper from "@helper/DeviceHelper";

export default function PrimaryAvatar(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({
        profiles: [],
        current: {}
    })
    const anchorRef = useRef(null)
    const [, setCookie] = useCookies([AuthProvider.userCookieName])
    const history = useHistory()
    const isDeviceMode = MeasureHelper.isAbleMeasure()

    const { profiles, current } = state
    
    const handleToggle = () => {
        if(props.click){
            props.click()
        } else {
            setOpen((prevOpen) => !prevOpen);    
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleProfile = () => {
        history.replace('/profile');
    }
    
    //프로필 선택
    const selectProfile = async (profile) => {
        setOpen(false)
        
        AuthProvider.set('profileID', profile.id)
        AuthProvider.set('profileName', profile.name)
        AuthProvider.set('profileType', profile.type)
        AuthProvider.set('code', profile.code)
        AuthProvider.set('image', profile.image)

        setCookie(AuthProvider.userCookieName, AuthProvider.getUserData(), AuthProvider.getCookieOption())
        await ProfileService.setCurrent(profile.id, AuthProvider.getDeviceID())
        
        window.location.href = '/mypage'
    }
    
    const handleLogout = () => {
        window.location.replace('/logout')
    }

    const handleExit = async () => {
        await DeviceHelper.exitProgram()
    }
    
    const handleCustomer = async () => {
        history.push('/manage/customer'); 
    }
    
    const handleSetting = async () => {
        setOpen(false)
        history.push('/setting');
    }

    const handleVoucher = async () => {
        setOpen(false)
        history.push('/voucher');
    }
    
    const prevOpen = useRef(open);
    const fetchProfile = useCallback(async () => {
        const profiles = await ProfileService.getProfiles()
        const session = AuthProvider.getUserData()
        
        const filter = profiles.filter( p => p.id === session.profileID)
        setState({
            profiles,
            current: filter ? filter[0] : {}
        })
    }, [])

    
    
    useEffect(()=>{
        fetchProfile().then()
    },[fetchProfile])
    
    
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);
    
    const currentProfile = profiles.find( p => p.id === AuthProvider.getProfileID() )
  
    return (
        <div className={classes.root}>
            <div>
                <Button
                    className={classes.rounded}
                    ref={anchorRef}
                    edge="end"
                    aria-label="account of current user"
                    aria-haspopup="true"
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    variant={props.buttonMode ? 'outlined' : 'text'}
                    onClick={handleToggle}
                >
                    {(current && current.image) &&
                        <Avatar variant="rounded" alt={AuthProvider.get('code')} src={require(`@images/profile/${current.image}`)}/>
                    }
                    <Typography style={{padding:'0px 5px'}}>{currentProfile ? currentProfile.code : '' }</Typography>
                    {!props.buttonMode &&
                    <ArrowDropDownIcon/>
                    }
                </Button>
                
                <Popper open={open} anchorEl={anchorRef.current} transition disablePortal className={"pop-over"}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper style={{padding: 15}}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" >
                                        {profiles.slice(0,5).map((profile, index) => {
                                            if(profile.name){
                                                return (
                                                    <MenuItem onClick={async () => {
                                                        await selectProfile(profile)
                                                    }} key={index}>
                                                        <Avatar variant="rounded" alt={profile.name} src={require(`@images/profile/${profile.image}`)} />
                                                        <Typography style={{padding:'0 5px'}}>{profile.name}</Typography>
                                                    </MenuItem>
                                                )
                                            }
                                            return <></>
                                        })}
                                        <Divider style={{margin: '10px 0'}}/>
                                        <MenuItem onClick={handleProfile}>프로필</MenuItem>
                                        {AuthProvider.isAdmin() &&
                                        <MenuItem onClick={handleCustomer}>회원관리</MenuItem>
                                        }
                                        <Divider style={{margin: '10px 0'}}/>
                                        {/*<MenuItem onClick={handleVoucher}>이용권</MenuItem>*/}
                                        <MenuItem onClick={handleSetting}>설정</MenuItem>

                                        <Divider style={{margin: '10px 0'}}/>
                                        {!isDeviceMode &&
                                        <MenuItem onClick={handleLogout}>로그아웃</MenuItem>
                                        }
                                        {isDeviceMode &&
                                        <MenuItem onClick={handleExit}>프로그램 종료</MenuItem>
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        zIndex: 9999
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    rounded: {
        color: '#222',
        fontWeight: 'bold',
        fontSize:18
    }
}));

    