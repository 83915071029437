class DefaultInterface {
    resolve(eventName, values) {}
    async updateApp(url) { }
    async getDeviceInfo() { }
    async deviceMeasureStart(){}
    async deviceMeasureResume() { }
    async deviceMeasurePause() { }
    async getVersion() {}
    async initSocketWithSerialport(){}
    async closeLoading() { }
    async showToast(message) {}
    async getComputerName() { return ''}
    async logout() {}
    async toReady() {}
    async openPopup(url) {
      window.open(url, 'measureFFT', `width=${window.innerWidth}, height=${window.innerHeight}`)
    }
    async clearCache() {}
}

class DesktopInterface {
    type = 'desktop'

    /**
     * 덱스크 탑용 resove 는 아무것도 하지 않습니다.
     * @param eventName
     * @param values
     */
    resolve(eventName, values) {}
    async updateApp(url) { await window.BRHW.updateBrhw('https://cli.brhw.kr/api/update/check')}
    async getDeviceInfo() { return await window.BRHW.getDeviceInfo()}
    async deviceMeasureStart(measureTime, measureType) { await window.BRHW.deviceMeasureStart(measureTime, measureType) }
    async deviceMeasureResume() { await window.BRHW.deviceMeasureResume()}
    async deviceMeasurePause() { await window.BRHW.deviceMeasurePause()}
    async deviceMeasureStop() { await window.BRHW.deviceMeasureStop() }
    async getVersion() { return await window.BRHW.getVersion()}
    async setToken(token) { await window.BRHW.setToken(token)}
    async getDisplayInfo() { return await window.BRHW.getDisplayInfo()}
    async initSocketWithSerialport() { await window.BRHW.initSocketWithSerialport()}
    async closeLoading() { }
    async showToast(message) { }
    async getComputerName() { return await window.BRHW.getComputerName()}
    async logout() {}
    async toReady(){}
    async openPopup(url){
      window.open(url, 'measureFFT', `width=${window.innerWidth}, height=${window.innerHeight}`)
    }
    async clearCache() {}
}

class AndroidInterface {
    type = 'android'
    eventResolver
    maxWaitTime = 2000

    resolve( values) {
        if(this.eventResolver) {
            this.eventResolver(values)
            // this.eventResolver = undefined
        }
    }

    setResolve(resolve) {
        this.eventResolver = resolve

        if(resolve){
            setTimeout(() => {
                this.resolve(undefined)
            }, this.maxWaitTime)
        }
    }
    async setToken(token) {
        return new Promise((resolve) => {
            window.APPBRHW.setToken(token)
            resolve()
        })
    }
    async getDeviceInfo() {
        return new Promise((resolve) => {
            window.APPBRHW.getDeviceInfo()
            this.setResolve(resolve)
        })
    }
    async deviceMeasureStart(measureTime, measureType) {
        return new Promise((resolve) => {
            window.APPBRHW.deviceMeasureStart(measureTime, measureType)
            resolve()
        })
    }
    async deviceMeasureResume() {
        return new Promise((resolve) => {
            window.APPBRHW.deviceMeasureResume()
            resolve()
        })
    }
    async deviceMeasurePause() {
        return new Promise((resolve) => {
            window.APPBRHW.deviceMeasurePause()
            resolve()
        })
    }
    async deviceMeasureStop() {
        return new Promise((resolve) => {
            window.APPBRHW.deviceMeasureStop()
            resolve()
        })
    }
    async getVersion() {
        return new Promise((resolve) => {
            window.APPBRHW.getVersion()
            this.setResolve(resolve)
        })
    }
    async initSocketWithSerialport() {
        return new Promise((resolve) => {
            try {
                window.APPBRHW.initSocketWithSerialport()    
            } catch {}
            resolve()
        })
    }
    async closeLoading() {
        return new Promise((resolve) => {
            try {
                window.APPBRHW.closeLoading()    
            } catch {}
            this.setResolve(resolve)
        })
    }
    
    async showToast(message) {
        return new Promise((resolve) => {
            try {
                window.APPBRHW.showToast(message)    
            } catch {}
            resolve()
        })
    }
    
    async getComputerName() {
        return new Promise((resolve) => {
            resolve('android')
        })
    }
    async updateApp(url) { }
    async logout() {
        try {
            window.APPBRHW.setToken('')
        } catch {}
        
        try {
            window.APPBRHW.IFSetToken('')
        } catch {}
    }
    
    async toReady() {
        return new Promise((resolve) => {
            try {
                window.APPBRHW.toReady()    
            } catch(e) { console.log(e)}
            this.setResolve(resolve)
        })
    }

    async openPopup(url) {
      try {
        window.APPBRHW.openActivity(url)
      } catch {}
    }

    async clearCache() {
        return new Promise((resolve) => {
            try {
                window.APPBRHW.clearCache()
            } catch (e) {
                console.log(JSON.stringify(e))
            }
            this.setResolve(resolve)
        })
    }
    
}

export default class BridgeProvider {
    static instance
    static isBRHWService
    static deviceType

    static async init() {
        try {
            await window.CefSharp.BindObjectAsync('BRHW')
            this.instance = new DesktopInterface()
            this.isBRHWService = true
            this.deviceType = 'desktop'
        } catch {
            if(window.APPBRHW){
                this.instance = new AndroidInterface()
                this.isBRHWService = true
                this.deviceType = 'android'
            } else {
                this.instance = new DefaultInterface()
                this.isBRHWService = false
            }
        }
    }
}