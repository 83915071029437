import React, {useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Box, Grid } from '@material-ui/core';
import SocketProvider from '@user-provider/SocketProvider';
import AudioPlayer from '@user-pages/exercise/common/AudioPlayer'
import ExerciseService from '@user-service/ExerciseService';

import MeasureHelper from '@helper/MeasureHelper'
import ExerciseStatusBar from "../ExerciseStatusBar";
import Hexagon from "react-hexagon";

const MeasureHzType = 'relax'
const { measureTypeToCode } = MeasureHelper
const PointImage = [
    require('@assets/exercise/relax/p1.png'),
    require('@assets/exercise/relax/p2.png'),
    require('@assets/exercise/relax/p3.png'),
    require('@assets/exercise/relax/p4.png'),
    require('@assets/exercise/relax/p5.png'),
    require('@assets/exercise/relax/p6.png'),
    require('@assets/exercise/relax/p7.png'),
    require('@assets/exercise/relax/p8.png'),
    require('@assets/exercise/relax/p9.png'),
]

const Relax = (props) => {
    const stageRef = useRef()
    useEffect(()=>{
        SocketProvider.connect(()=>{}, window, MeasureHzType)
        return () => {
            SocketProvider.measureStop(true).then()
        }
    },[])
    
    return (
        <Box style={{position: 'relative'}}>
            <Stage 
                ref={stageRef}
                history={props.history}
            />
        </Box>
    );
}

export class Stage extends React.Component {
    container
    clientRect
    
    audioRef = React.createRef()
    leftLightsRef = React.createRef()
    rightLightsRef = React.createRef()
    mainBrainRef = React.createRef()
    scoreRef = React.createRef()
    
    hitCount = 0

    currentMeasureType = 'rest'
    currentMeasureCode = 'A'
    
    constructor(props) {
        super(props)
        this.state = {
            background: [require('@assets/exercise/relax/background.jpg')],
            hitCount: 0,
            onMeasureData: props.onMeasureData,
            onReset: props.onReset,
            history: props.history
        }
    }
    
    setAudioMode(measureType){
        this.audioRef.current.setMode(measureType)
    }
    
    reset() {
        this.hitCount = 0
        this.leftLightsRef.current.update(0)
        this.rightLightsRef.current.update(0)
        this.mainBrainRef.current.update(0)
    }
    
    componentDidMount() {
        this.container = ReactDOM.findDOMNode(this)
        this.clientRect = this.container.getClientRects()[0]
        this.audio = ReactDOM.findDOMNode(this)

        SocketProvider.onMeasure = (data) => {
            
            if(this.state.onMeasureData){
                this.state.onMeasureData(data)
            }
            
            this.scoreRef.current.update(data.isHit)
            
            if (data.isHit) {
                this.hitCount += 1
                this.audioRef.current.play()
            } else {
                this.hitCount = 0
            }

            this.leftLightsRef.current.update(this.hitCount)
            this.rightLightsRef.current.update(this.hitCount)
            this.mainBrainRef.current.update(this.hitCount)

            if (12 < this.hitCount ){
                this.hitCount = 12
            }
            
        }
    }

    async startExercise (runningTime, measureType, measureCode) {
        this.setAudioMode(measureType)
        await SocketProvider.measureStart(runningTime,0,MeasureHzType, measureType, measureTypeToCode(measureType), measureCode)
    }

    stopExercise(){
        SocketProvider.measureStop().then()
    }

    
    render() {
        const backgroundImage = this.state.background[0]
        return (
            <Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    position="absolute"
                    right={40}
                    top={90}
                    zIndex={20}
                    className={'concentration'}
                >
                    <Score ref={this.scoreRef}/>
                </Box>

                <ExerciseStatusBar
                    measureHzType={MeasureHzType}
                    measureType={this.currentMeasureType}
                    measureCode={this.currentMeasureCode}
                    close={() => {
                        this.stopExercise()
                    }}
                    start={async (runningTime, measureType, measureCode) => {
                        await this.startExercise(runningTime, measureType, measureCode)
                    }}
                    onResultVar={ async ({measureLogID, measureHzType}) => {
                        await ExerciseService.setResultVar({
                            measureLogID,
                            measureHzType,
                            resultVar: JSON.stringify(this.scoreRef.current.getScore())
                        })
                    }}
                />
                
                <Grid>
                    <Grid item xs={12} style={{ position: 'relative', height: window.innerHeight}}>
                        <Box style={{ 
                            backgroundColor: '#000',
                            backgroundImage: `url(${backgroundImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height:'100%'}}>
                            
                            <MainBrain ref={this.mainBrainRef}/>
                        </Box>    
                        <MVPoint colors={[0,2,4,6,7]} 
                                 borderColors={['#2A4D8F', '#E0CBE9', '#8FFA00', '#40F7D0', '#35BBFC']} 
                                 points={5}
                                 activeCount={4}
                                 position={"left"}
                                 ref={this.leftLightsRef}
                        />

                        <MVPoint colors={[1,3,5,7,8]} 
                                 borderColors={['#E7E7DF', '#F3A157', '#DB6D52', '#F24EBB', '#A973EB']} 
                                 points={5}
                                 activeCount={5}
                                 position={"right"}
                                 ref={this.rightLightsRef}
                        />
                    </Grid>
                </Grid>
                
                <AudioPlayer ref={ this.audioRef } mode={'rest'} />
            </Box>
        )
    }
}

export class MainBrain extends React.Component {
    defaultBrainWidth = 400
    defaultBrainHeight = 450
    
    brainWidth = 400
    brainHeight = 450

    containerHeight = 690
    brainImage = require(`@assets/exercise/relax/brain3.png`)
    
    top = 0
    left = 0
    
    constructor() {
        super()
        
        this.state = {
            sizeRatio: 5
        }
        this.brainWidth = this.defaultBrainWidth
        this.brainHeight = this.defaultBrainHeight

        this.left = (window.innerWidth) / 2 - (this.brainWidth / 2)
        this.top = (window.innerHeight) / 2 - (this.brainHeight / 2)
    }
    
    update(hitCount) {
        this.brainWidth = this.defaultBrainWidth + this.defaultBrainWidth * (hitCount * this.state.sizeRatio / 100);
        this.brainHeight = this.defaultBrainHeight + this.defaultBrainHeight * (hitCount * this.state.sizeRatio / 100);

        this.left = (window.innerWidth) / 2 - (this.brainWidth / 2)
        this.top = (window.innerHeight) / 2 - (this.brainHeight / 2)
        
        this.setState({
            ...this.state,
        })
    }
    
    render() {
        return (
            <Box position="absolute"
                 left={this.left}
                 top={this.top}
                 zIndex={1}
                 style={{width: this.brainWidth,
                     height: this.brainHeight,
                     backgroundSize: 'contain',
                     backgroundRepeat: 'no-repeat',
                     backgroundPosition: 'center',
                     backgroundImage: `url(${this.brainImage})`,
                     transitionDuration: '0.5s'
                 }}>
            </Box>
    )
    }
}

export class MVPoint extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            points: new Array(props.points).fill(0),
            colors: props.colors,
            borderColors: props.borderColors,
            position: props.position,
            hitCount: props.hitCount,
            activeCount: props.activeCount ? props.activeCount : 5,
            activeIndex: new Array(props.points + 1).fill(0).map((_, index) =>  {
                return props.activeCount + ( index * 2 )
            }),
            showIndex: -1
        }
    }

    update(hitCount) {
        
        let showIndex = -1
        this.state.activeIndex.map( (value, index) => {
            if( value <= hitCount) {
                showIndex = index
            }
            return value
        })
        
        this.setState({
            ...this.state,
            showIndex
        })
    }

    render() {
        const containerHeight = 690
        const left = 'left' === this.state.position ? 0 : window.innerWidth - 200
        
        return ( 
            <Box mt={10}
                 position="absolute"
                 left={left}
                 top={'calc(50% - 400px)'}
                 zIndex={98}
                 style={{ width: 200, 
                        height: containerHeight,
                        color: 'white',
                        }} >
                
                <Box height="100%">
                    {this.state.points.map((val, index) => {
                        let display = 'none'; 
                        if ( index <= this.state.showIndex ){
                           display = 'flex' 
                        }
                        const pointIndex = this.state.colors[index]
                        return (
                            <Box key={index}
                                 height={1 / this.state.points.length}
                                 justifyContent="center"
                                 alignItems="center"
                                 display={display}>
                                
                                <img src={PointImage[pointIndex]} alt={'point'} style={{width:100}}/>
                            </Box>
                        )}
                    )}
                </Box>
            </Box>
        )
    }
}


class Score extends  React.Component {

    defaultOptions = {
        maxCount: 0,
        currentCount: 0
    }
    constructor() {
        super();
        this.state = this.defaultOptions
    }

    getCount(){
        return this.state.currentCount
    }
    getScore(){
        return this.state
    }
    isOver(){
        return this.state.currentCount >= this.state.maxCount
    }

    reset() {
        this.setState({
            ...this.defaultOptions
        })
    }
    update(isHit) {
        let count = this.state.currentCount
        let maxCount = this.state.maxCount
        
        if(isHit){
            count += 1
        } else { 
            count = 0
        }
        
        if(3 < count){
            if(maxCount < count - 3) {
                maxCount = count - 3
            }

            if(maxCount > 10) maxCount = 10
        }

        if(count > 13) count = 13
    
        this.setState({
            ...this.state,
            maxCount: maxCount,
            currentCount: count
        })
    }

    render() {
        const {currentCount , maxCount} = this.state
        
        let count = (currentCount - 3)
        if(0 > count) count = 0
        
        
        return (
            <div className={'score'}>
                <Hexagon
                    style={{
                        fill: 'rgba(0,0,0,0.7)',
                        stroke: '#838F9A',
                        strokeWidth:5,
                        transitionDuration: 0.5
                    }} className="text">
                    <text x="50%" y="40%" style={{fontSize: 70, fontWeight: 'normal'}}>이완율%</text>
                    <text x="50%" y="63%"  style={{fontSize: 85}}>
                        {count * 10} / {maxCount * 10}
                    </text>
                </Hexagon>
            </div>
        )
    }
}


export default Relax
