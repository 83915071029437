import React, {useEffect, useState, useCallback} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Grid,
    Card,
    CardContent,
    Button,
} from '@material-ui/core';
import {useHistory} from "react-router-dom";
import { green , blue, red } from '@material-ui/core/colors';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'

import AuthProvider from '@user-provider/AuthProvider'
import PrescriptionService from '@user-service/PrescriptionService'

import DoneIcon from '@material-ui/icons/Done';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import clsx from "clsx";
import MeasureSelectDialog from "./MeasureSelectDialog";
import MeasureSelectionToggle from "./MeasureSelectionToggle";
import ReplayIcon from '@material-ui/icons/Replay';
import IconButton from "@material-ui/core/IconButton";
import BridgeProvider from "../../../provider/BridgeProvider";
import DeviceStatusBox from "../../../components/DeviceStatusBox";


const MeasureSelect = ({history}) => {
    
    const [noiseCode] = useState('A')
    
    return (
        <div id="cont-wrap">
            <div className="sv">
                <div>
                    <h3>뇌기능 측정</h3>
                    <span>EEG, 균형상태, 의식패턴을 통해 뇌의 기능을 측정합니다.</span>
                    <i>Brain Health</i>
                </div>
            </div>
            <div className="cont">
                <Box mt={10} style={{margin: 'auto'}}>
                    <MeasureSelectBox history={history} noiseCode={noiseCode}/>
                </Box>
            </div>
        </div>
    );
}

//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <div className="editprofile_wrap wrapper">
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </div>
        </Box>
    )
}

export function MeasureSelectBox(){
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({
        prescription: null,
        request: null
    })
    
    const fetch = useCallback(async () => {
        let prescription = await PrescriptionService.getLatest();

        if(!prescription){
          const measureTypes = ["eeg","balance","meditationTest","measureBalance","measureBandTest"]
          await PrescriptionService.newPrescription(measureTypes)
          prescription = await PrescriptionService.getLatest();
        }
        
        setState({
            prescription: !prescription ? {} : prescription,
            request : prescription ? await PrescriptionService.getReadyRequests(prescription.id) : null
        })
        
    }, [])
    
    
    useEffect(()=>{
        fetch().then()
    },[fetch])
  

  
  
    const { prescription, request } = state
    
    const measureNew = async (measureTypes) => {
        await PrescriptionService.updateStatus(prescription.id, 'eeg', '40','C','done')
        await PrescriptionService.newPrescription(measureTypes)
        fetch().then(() => {setOpen(false)})
    }

    const newPrescription = () => {
       setOpen(true)
    }
    
    const closeDialog = () => {
        setOpen(false)
    }

    if(!prescription){
        return <LoadingSkeleton />
    }

    return (
        <Box>
            <Box mb={3}>
                <Grid container>
                    <Grid item xs={4}>
                        <Typography variant={'h5'}>{prescription.id}회차</Typography>
                        <Typography variant={'subtitle2'}>뇌기능 분석메뉴에서 결과를 확인 할 수 있습니다.</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <MeasureSelectionToggle measureTypes={prescription.measureTypes} prescriptionID={prescription.id}/>
                    </Grid>
                    <Grid item xs={2}>
                        <Grid container spacing={1} justify={'flex-end'} style={{marginTop: 10}}>
                            <Grid item>
                                <IconButton aria-label="새로고침" style={{padding: 7, border: `1px solid #BEBEBE`}} onClick={() => { fetch().then() }}>
                                    <ReplayIcon/>
                                </IconButton>
                            </Grid>
                            {(AuthProvider.isAdmin() || AuthProvider.isProfileSelectorView() )&&
                            <Grid item>
                                <Button
                                    onClick={newPrescription}
                                    className={'common-button'}
                                    endIcon={<ChevronRightIcon className={'rightIcon'}/>}
                                >신규측정</Button>    
                            </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            
            <Grid container spacing={3} alignItems={"center"} alignContent={"center"} style={{marginTop: 70}}>
                <Grid item>
                    <MeasureType 
                        name="EEG 측정" 
                        description="좌/우 뇌상태를 3D FFT로 측정." 
                        color={'blue'}
                        background={blue[100]}
                        isMeasure={prescription.isEEG}
                        countMeasure={prescription.countEEG}
                        prescription={prescription}
                        isRequest={request ? request.eeg : null}
                        requestID={request ? request.eegRequestID : null}
                        requestDate={request ? request.eegRequestDate : null}
                        measureType={'eeg'}
                        />
                </Grid>   
                <Grid item>
                    <MeasureType 
                        name="균형상태 측정" 
                        description="안정, 주의력, 집중력 상태를 측정" 
                        color={'green'}
                        background={green[100]}
                        isMeasure={prescription.isBalance}
                        countMeasure={prescription.countBalance}
                        prescription={prescription}
                        isRequest={request ? request.default : null}
                        requestID={request ? request.defaultRequestID : null}
                        requestDate={request ? request.defaultRequestDate : null}
                        measureType={'balance'}
                        />
                </Grid>   
                <Grid item>
                    <MeasureType 
                        name="뇌기능 의식패턴" 
                        description="뇌기능 의식패턴을 측정" 
                        color={'red'}
                        background={red[100]}
                        isMeasure={prescription.isBrainActive}
                        countMeasure={prescription.countBrainActive}
                        prescription={prescription}
                        isRequest={request ? request.meditation : null}
                        requestID={request ? request.meditationRequestID : null}
                        requestDate={request ? request.meditationRequestDate : null}
                        measureType={'meditationTest'}
                        />
                </Grid>
                <Grid item>
                    <MeasureType
                        name="항상성 자기조절"
                        description="항상성 자기조절 측정"
                        color={'red'}
                        background={red[100]}
                        isMeasure={prescription.isMeasureBandTest}
                        countMeasure={'yes' === prescription.isMeasureBandTest ? 1 : 0}
                        prescription={prescription}
                        isRequest={request ? request.measureBandTest : null}
                        requestID={request ? request.measureBandTestRequestID : null}
                        requestDate={request ? request.measureBandTestRequestDate : null}
                        measureType={'measureBandTest'}
                    />
                </Grid>
            </Grid>

            <DeviceStatusBox />
          
            <MeasureSelectDialog
                open={open}
                title={'신규측정'}
                close={() => {
                    closeDialog()
                }}
                submit={async (measureList) => {
                    closeDialog()
                    await measureNew(measureList)
                }}
                buttonName={'신규 측정 생성'}
            />
        </Box>
    )
}


export function MeasureType(props) {
    const classes = useStyles();
    const { isRequest, isMeasure, prescription, requestID, measureType, requestDate } = props
    const history = useHistory()
    let isAble = -1 < (prescription.measureTypes || []).indexOf(measureType)
    if(isMeasure) isAble = true
    
    const goMeasure = () => {
        
        if(!isAble){
            return
        }
        switch(measureType){
            case 'eeg':
                document.location.href=`/measureBand/${prescription.id}?rqs=${requestID}`
                break
            case 'balance':
                history.push(`/measure/${prescription.id}?rqs=${requestID}`)
                break
            case 'meditationTest':
                history.push(`/exercise/meditationTest/${prescription.id}?rqs=${requestID}`)
                break;
            case 'measureBandTest':
                history.push(`/exercise/measureBandTest/${prescription.id}?rqs=${requestID}`)
                break;
            default:
        }
        
    }
    
    const canMeasure = 'yes' !== isMeasure && (isRequest || AuthProvider.isAdmin() || AuthProvider.isProfileSelectorView())
    return (
        <Grid>
            <Card 
                className={clsx(classes.card,{
                    [classes.disableMeasure]: !isAble
                })} 
                onClick={()=>{ 
                    if(canMeasure){
                        goMeasure()    
                    }
                }} elevation={0}
            >
                <CardContent >
                    <Box display="flex" className={clsx(classes.cardBody)} alignItems="center">
                        <Box width="100%">
                            <Box display="flex" alignItems="center" className={classes.statusIcon} justifyContent={'center'} mb={3}>
                                {'yes' === isMeasure 
                                    ? <DoneIcon className={clsx('icon', 'done')}/>
                                    : <PriorityHighIcon className={clsx('icon', 'yet', {
                                        'yetDisabled': !isAble
                                    })}/>
                                }
                            </Box>
                            <Typography gutterBottom className={'title'} component="h2" align="center">
                                {props.name}
                            </Typography>
                            <Typography variant="body2" className={'desc'} align="center">
                                {props.description}
                            </Typography>
                            
                            {('yes' !== isMeasure) 
                                ?
                                <Box mt={3} className={clsx(
                                    classes.measureResult, 
                                    {
                                        [classes.disableMeasure]: !canMeasure,
                                        [classes.measureRequest]: canMeasure
                                    }
                                )}>
                                    <Typography 
                                        variant={'h6'} 
                                        component="p" align="center"
                                        style={{paddingTop: 7, fontSize: '1 rem'}}>
                                        {canMeasure ? '측정시작' : '설정필요'}
                                    </Typography>
                                    {/*{requestDate &&*/}
                                    {/*<Typography variant="body2" className={'subdesc'} component="p" align="center">*/}
                                    {/*    요청일 : {DateHelper.format(requestDate, 'YYYY.MM.DD')}*/}
                                    {/*</Typography>*/}
                                    {/*}*/}
                                </Box>
                                :
                                <Box mt={3} className={clsx(classes.measureResult, {
                                    [classes.measureDone]: 'yes' === isMeasure,
                                })}>
                                    <React.Fragment>
                                        <Typography variant={'h6'} className={'subtitle'} component="p" align="center">
                                            {props.countMeasure}회 측정
                                        </Typography>
                                        <Typography variant="body2" className={'subdesc'} component="p" align="center">
                                            측정완료
                                        </Typography>
                                    </React.Fragment>
                                </Box>
                            }
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    )
}


const useStyles = makeStyles(theme => ({
    root: {
       
    },
    headerBox: {
        marginBottom: 40,
        marginTop: 120
    },
    title: {
        fontSize:26,
        fontWeight: 'bold'
    },
    card: {
        minWidth: 302,
        backgroundColor: '#F5F9FE',
        borderRadius: 30,
        border: `1px solid #D6E1F1`,
        cursor: 'pointer',
        '&:hover' : {
            backgroundColor: '#E1E5EC'
        },
        '& .title': {
            fontSize: 32,
            fontWeight: 'bold',
            letterSpacing: -2
        },
        '& .desc' : {
            marginTop: -15
        }
    },
    disableMeasure: {
        // backgroundColor: '#ededed',
        borderRadius: 30,
        border: `0px solid #D6E1F1`,
        cursor: 'default',

        '& .title': {
            fontWeight: 'bold',
            color: '#999'
        },
    },
    statusIcon: {
        border: '5px solid #DCE8F5'  ,
        width: 85,
        height: 85,
        margin: 'auto',
        borderRadius: '50%',
        marginBottom: 30,
        
        '& .yet': {
            color: '#F34821',
            fontSize: 80,
            fontWeight: 'bold',
        },
        '& .yetDisabled': {
            color: '#999',
            fontSize: 80,
            fontWeight: 'bold',
        },
        '& .done': {
            color: '#00DF97',
            fontSize: 100,
            fontWeight: 'bold',
            marginTop: -30,
            marginLeft: 30
        }
    },
    measureRequest: {
        backgroundColor: '#F34821',
        color: '#fff !important',
    },
    measureResult: {
        color: '#333',
        padding: 30,
        borderRadius: 30,
        maxWidth: 300,
        margin: 'auto',
        marginTop: 70,
        fontSize: 26,
        letterSpacing: -2,
        minHeight: 110,
        '& .subtitle': {
            fontWeight: 'bold'
        },
        '& .subdesc': {
            fontWeight: 'normal',
            fontSize: 18
        }
    },
    measureDone: {
        backgroundColor: '#00DF97',
        color: '#fff !important',
    },
    cardBody: {
        height: 450
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
}));

export default MeasureSelect
