
import React, {useCallback, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DevicesIcon from '@material-ui/icons/Devices';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TuneIcon from '@material-ui/icons/Tune';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import Divider from '@material-ui/core/Divider';
import StorefrontIcon from '@material-ui/icons/Storefront';
import PersonIcon from '@material-ui/icons/Person';

import ComputeFeedbackDialog from '@user-pages/exercise/common/ComputeFeedbackDialog'

import AuthProvider from '@user-provider/AuthProvider'
import PrescriptionService from '@user-service/PrescriptionService'
import ProfileService from '@user-service/ProfileService'

import clsx from "clsx";
import {Typography} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import BridgeProvider from "../../provider/BridgeProvider";

const Setting = ({history}) => {
    const classes = useStyles();
    const settingDialogRef = React.createRef()

    const handleDevice = () => {
        history.push('/setting/deviceManage')
    };
    
    const handleVoucher = () => {
        history.push('/voucher')
    }
    
    const handleNoise = () => {
        history.push('/setting/noiseManage')
    }

    const handleComputerManage = () => {
        history.push('/setting/computerManage')
    }
    
    const handleHzDefault = () => {
        settingDialogRef.current.openWithHzType('default')
    }
    
    const handleHzMeditation = () => {
        settingDialogRef.current.openWithHzType('meditationTest')
    }
    
    const handleCheckNoise = async () => {
        const prescription = await PrescriptionService.getLatest();
        history.push(`/measureBand/${prescription.id}?noiseCode=N`)
    }
    
    const handleManagers = () => {
        history.push(`/setting/managers`)
    }
    
    const [state, setState] = useState({
        computerInfo: {
            computerName: '...',
            version: '...'
        },
        manager: {name: ''}
    })
    
    const fetch = useCallback(async () => {
        let computerInfo = {
            version: '',
            computerName : ''
        }
        
        let manager = {name: ''}
        
        try {
            // await window.CefSharp.BindObjectAsync('BRHW')
            const version = await BridgeProvider.instance.getVersion()
            const computerName = await BridgeProvider.instance.getComputerName()
            
            computerInfo = {
                version,
                computerName
            }
        } catch(e) {}
        
        try {
            const managers = await ProfileService.getManager()
            if(0 < managers.length){
                manager = managers[0]
            }
        } catch {}

        setState({
            ...state,
            computerInfo,
            manager
        })
    },[])
    
    useEffect(()=>{
        fetch().then()
    },[])

    
    const { computerInfo, manager } = state

    return (
        <div id="cont-wrap" >
            <div className="sv" >
                <div>
                    <h3>설정</h3>
                </div>
            </div>
            <div className={clsx('cont', classes.root)} >
                {AuthProvider.isAdmin() &&
                <EducatorCode/>
                }
                <List
                    style={{marginTop: 30}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            기기 관리
                        </ListSubheader>
                    }
                    className={classes.listRoot}
                >
                    {AuthProvider.isAdmin() &&
                    <React.Fragment>
                        <ListItem button className={'item'}>
                            <ListItemIcon>
                                <DevicesIcon/>
                            </ListItemIcon>
                            <ListItemText primary="노이즈 측정" onClick={handleCheckNoise}/>
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                    }

                    {AuthProvider.isSuperAdmin() &&
                    <React.Fragment>
                        <ListItem button className={'item'} onClick={handleNoise}>
                            <ListItemIcon>
                                <TuneIcon />
                            </ListItemIcon>
                            <ListItemText primary="노이즈 설정" />
                            <ListItemSecondaryAction>
                                <IconButton aria-label="delete">
                                    <ChevronRightIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                    }
                    {AuthProvider.isSuperAdmin() &&
                    <React.Fragment>
                        <ListItem button className={'item'} onClick={handleHzDefault}>
                            <ListItemIcon>
                                <TuneIcon />
                            </ListItemIcon>
                            <ListItemText primary="균형상태 설정" />
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                    }
                    {AuthProvider.isSuperAdmin() &&
                    <React.Fragment>
                        <ListItem button className={'item'} onClick={handleHzMeditation}>
                            <ListItemIcon>
                                <TuneIcon />
                            </ListItemIcon>
                            <ListItemText primary="뇌기능 의식패턴 설정" />
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                    }
                    
                    <ListItem button className={'item'} onClick={handleDevice}>
                        <ListItemIcon>
                            <DevicesIcon />
                        </ListItemIcon>
                        <ListItemText primary="기기 연결 관리" />
                        <ListItemSecondaryAction>
                            <IconButton aria-label="delete">
                                <ChevronRightIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </List>

                <List
                    style={{marginTop: 30}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            컴퓨터 관리
                        </ListSubheader>
                    }
                    className={classes.listRoot}
                >
                    <ListItem className={'item'}>
                        <ListItemIcon>
                            <RemoveFromQueueIcon />
                        </ListItemIcon>
                        <ListItemText primary="컴퓨터 이름" />
                        <ListItemSecondaryAction>
                            {computerInfo.computerName}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />

                    <ListItem className={'item'}>
                        <ListItemIcon>
                            <RemoveFromQueueIcon />
                        </ListItemIcon>
                        <ListItemText primary="BRHW 버전" />
                        <ListItemSecondaryAction>
                            {computerInfo.version}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    
                    <ListItem button className={'item'} onClick={handleComputerManage}>
                        <ListItemIcon>
                            <ImportantDevicesIcon />
                        </ListItemIcon>
                        <ListItemText primary="컴퓨터 상태" />
                        <ListItemSecondaryAction>
                            <IconButton aria-label="delete">
                                <ChevronRightIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </List>

                <List
                    style={{marginTop: 30}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            담당 교육사
                        </ListSubheader>
                    }
                    className={classes.listRoot}
                >
                    <ListItem className={'item'} button onClick={handleManagers}>
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="담당 교육사 " />
                        <ListItemSecondaryAction>
                            <Grid container>
                                <Grid item>
                                    <div style={{paddingTop: 10, paddingRight: 10}}>
                                        {manager.name ? manager.name : '등록필요'}
                                    </div>    
                                </Grid>
                                <Grid item>
                                    <IconButton aria-label="delete">
                                        <ChevronRightIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </List>
                
                
                <List
                    style={{marginTop: 30}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            결제 관리
                        </ListSubheader>
                    }
                    className={classes.listRoot}
                >
                    {/*<ListItem button className={'item'}>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <PaymentIcon />*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary="카드 관리" />*/}
                    {/*    <ListItemSecondaryAction>*/}
                    {/*        <IconButton aria-label="delete">*/}
                    {/*            <ChevronRightIcon />*/}
                    {/*        </IconButton>*/}
                    {/*    </ListItemSecondaryAction>*/}
                    {/*</ListItem>*/}
                    {/*<Divider />*/}

                    <ListItem button className={'item'} onClick={handleVoucher}>
                        <ListItemIcon>
                            <StorefrontIcon />
                        </ListItemIcon>
                        <ListItemText primary="상품 구매" />
                        <ListItemSecondaryAction>
                            <IconButton aria-label="delete">
                                <ChevronRightIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </List>
            </div>
            <ComputeFeedbackDialog ref={settingDialogRef} hzType={'default'} />
        </div>
    );
}

const EducatorCode = () => {
    const classes = useStyles();
    let image = AuthProvider.getProfileImage();
    return (
        <Box border={1} mt={3} p={5} style={{borderColor: '#ddd', borderRadius: 10}}>
            <Grid container>
                <Grid item xs={2}>
                    <Avatar variant="rounded" className={classes.large} alt={AuthProvider.getProfileName()} src={image}/>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant={'subtitle1'}>
                        교육사 코드
                    </Typography>
                    <Typography variant={'h5'}>
                        {AuthProvider.get('code')}
                    </Typography>
                    <Typography variant={'caption'} style={{color: '#999'}}>
                        브레인헬스 본사
                    </Typography>
                </Grid>
            </Grid>
            
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 800,
        margin: 'auto',
        
        '& .item': {
            padding : '20px 10px'
        },
        
    },
    listRoot: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),

        // backgroundColor: 'transparent',
        '& img': {
            width: '100%'
        }
    },
}));

export default Setting
