import React, {useRef, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';

import {Box, Grid} from '@material-ui/core';
import AudioPlayer from '@user-pages/exercise/common/AudioPlayer'
import SocketProvider from '@user-provider/SocketProvider'

import Intro from '@user-pages/exercise/common/Intro'
import Radar from '@user-pages/exercise/balance/Radar'

import GifPlayer from "react-gif-player/dist/gifplayer";
import ExerciseStatusBar from "../ExerciseStatusBar";

import MeasureHelper from '@helper/MeasureHelper'
const { measureTypeToCode } = MeasureHelper
const MeasureHzType = 'balance'

const Balance = () => {
    const stageRef = useRef()
    const [status, setStatus] = useState('intro')

    useEffect(()=>{
        SocketProvider.connect(()=>{}, window, MeasureHzType)
        return () => {
            SocketProvider.measureStop(true).then()
        }
    },[])

    const onNext = () => {
        setStatus('stage')
    }

    return (
        <Box style={{position: 'relative'}}>
            <Intro 
                onNext={onNext.bind(this)} 
                poster={'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/balanceIntro.png'}
                src={'https://d2iajcsshqwtw3.cloudfront.net/assets/balanceIntro2.webm'}
                onPlay={() => {
                    setStatus('stage')
                }}
            />
            
            {'stage' === status &&
            <Stage ref={stageRef}/>
            }
        </Box>
    );
}

export class Stage extends React.Component {
    container
    clientRect

    audioRef = React.createRef()
    radarRef = React.createRef()
    gifPlayerRef = React.createRef()

    currentMeasureType = 'balance'
    currentMeasureCode = 'D'

    radarRange = 2

    constructor(props) {
        super(props)
        this.state = {
            background:[
                require(`@assets/exercise/balance/background.jpg`),
                require(`@assets/exercise/balance/backgroundBoard.png`),
                require(`@assets/exercise/balance/backgroundRadar.png`),
                require(`@assets/exercise/balance/flyDron.gif`)
            ],
            show: false,
        }
    }

    componentDidMount() {
        this.container = ReactDOM.findDOMNode(this)
        this.clientRect = this.container.getClientRects()[0]
        this.audioRef.current.loadEffect(MeasureHzType)

        SocketProvider.onMeasure = (data) => {
            this.radarRef.current.update(data, this.currentMeasureCode, this.radarRange)

            if(data.isHit){
                this.audioRef.current.play()
            }
        }
        
        SocketProvider.onMeasureStop = () => {
            this.droneFlying(false)
        }
    }

    refresh(){
        this.setState({
            ...this.state
        })
    }
    setAudioMode(measureType){
        this.audioRef.current.setMode(measureType)
    }
    droneFlying(flying) {
        this.gifPlayerRef.current.setState({
            ...this.gifPlayerRef.current,
            playing: flying
        })
    }

    async startExercise(runningTime, measureType, measureCode) {
        this.currentMeasureCode = measureCode
        
        await SocketProvider.measureStart(
            runningTime,
            0,
            MeasureHzType,
            measureType,
            measureTypeToCode(measureType),
            measureCode
        )
        this.setAudioMode(measureType)

        this.audioRef.current.effect('start');
        this.droneFlying(true)
    }

    stopExercise() {
        this.droneFlying(false)
        SocketProvider.measureStop().then()
    }

    render() {
        const backgroundBoardSize = {
            width: window.innerWidth * 0.9,
            height: window.innerHeight * 0.9
        }

        const backgroundRadarSize = {
            width: backgroundBoardSize.width * 0.7,
            height: backgroundBoardSize.height * 0.7
        }

        const droneSize = {
            width: backgroundRadarSize.width * 0.35,
            height: backgroundRadarSize.height * 0.35,
            top: backgroundRadarSize.height * 0.5 * -1 / 2 + 50
        }

        return (
            <Box>
                <ExerciseStatusBar
                    measureHzType={MeasureHzType}
                    measureType={this.currentMeasureType}
                    measureCode={this.currentMeasureCode}
                    close={async () => {
                        await this.stopExercise()
                    }}
                    start={async (runningTime, measureType, measureCode) => {
                        await this.startExercise(runningTime, measureType, measureCode)
                    }}
                />
                
                <Grid>
                    <Grid item xs={12} style={{ position: 'relative', height: window.innerHeight}}>
                        <Box style={{height:'100%'}}>
                            <Box
                                style={{
                                    backgroundColor: '#000',
                                    backgroundImage: `url(${this.state.background[0]})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    height:'100%'}}>

                                <Box position="absolute"
                                     left={`calc(50% - ${backgroundBoardSize.width / 2}px)`}
                                     top={50}
                                     zIndex={10}
                                     style={{ width: backgroundBoardSize.width,
                                         height: backgroundBoardSize.height,
                                         backgroundSize: 'contain',
                                         backgroundRepeat: 'no-repeat',
                                         backgroundPosition: 'center',
                                         backgroundImage: `url(${this.state.background[1]})`,
                                     }}>
                                    <Box position={"relative"} style={{marginTop: '8%'}}>
                                        <Box position="absolute"
                                             left={`calc(50% - ${backgroundRadarSize.width / 2}px)`}
                                             top={'20%'}
                                             zIndex={10}
                                             style={{ width: backgroundRadarSize.width,
                                                 height: backgroundRadarSize.height,
                                                 backgroundSize: 'contain',
                                                 backgroundRepeat: 'no-repeat',
                                                 backgroundPosition: 'center bottom',
                                                 backgroundImage: `url(${this.state.background[2]})`,
                                             }}>
                                            <Radar ref={this.radarRef} size={backgroundRadarSize}/>
                                        </Box>
                                        <Box position="absolute"
                                             left={'3%'}
                                             top={`-${droneSize.height}px`}
                                             zIndex={10}
                                             style={{ 
                                                 width: droneSize.width,
                                                 height: droneSize.height,
                                             }}
                                        >
                                            <GifPlayer gif={this.state.background[3]}
                                                       ref={this.gifPlayerRef}
                                                       autoPlay={false}
                                                       style={{width: '100%'}}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                    </Grid>
                </Grid>
                <AudioPlayer ref={ this.audioRef } mode={'balance'} />
            </Box>
        )
    }
}

const useStyles = makeStyles(theme => ({
    wrap: {
        width:1280,
        margin:"0 auto"

    },
    marginTop: {
        marginTop:150
    },
    background: {
        backgroundColor: '#ddd',
        height:500
    },
    paper: {
        maxWidth: 400,
        margin: `${theme.spacing(0.5)}px auto`,
        padding: theme.spacing(1),
        backgroundColor: '#efefef',
        height: 20,
        textAlign: 'center'
    },
}));


export default Balance
