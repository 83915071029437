import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { activeMenu} from '@reducers/UserAppAction'
import AuthProvider from '@user-provider/AuthProvider'
import PrimaryAvatar from "./PrimaryAvatar";
import CustomerAvatar from "./CustomerAvatar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {Grid, IconButton} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useCookies } from 'react-cookie';
import SocketProvider from "../provider/SocketProvider";
import AppAvatar from "./AppAvatar";
import clsx from "clsx";
import {appProfileButtonStore} from "../store/headerStore";
import {useRecoilState} from "recoil";

const NavBar = (props) => {
    const ignoreNavBar = ['waiting-app','/login' , '/join' , '/loginHelp', '/profile', '/editProfile', '/joinDone', 'waiting-device', 'update-report', '/measureFFT','/measureBand' ,'/measure/','/manage/customer', 'reset-password', '/gateway' ]
    
    //네비 화면 여부 
    let showNaviBar = true
    let useAppHeader = false
    for(let i = 0 ; i < ignoreNavBar.length; i++){
        const url = ignoreNavBar[i]
        if( -1 < props.location.pathname.indexOf(url) ){
            showNaviBar = false
            break
        }
    }
    
    if(-1 < props.location.pathname.indexOf('/exercise/')){
        showNaviBar = false
    }
    
    // 앱 특정 모드일 때 
    if(AuthProvider.isAppMode() && AuthProvider.getServiceType()){
        useAppHeader = true
    }
    
        
    return (
        !showNaviBar 
        ? <></>
        : useAppHeader 
            ? <AppHeader {...props}/>
            : <DefaultHeader {...props}/>
    )
}

const AppHeader = () => {
    const [showProfileButton] = useRecoilState(appProfileButtonStore);
    return (
        <>
          {AuthProvider.isProfileSelectorView() && showProfileButton &&
            <div className="name app-back-profile" style={{position: "absolute", left:30, top:30, zIndex: 99}}>
              <HeaderCustomerView color={'white'}/>
            </div>
          }
          <div className="name" style={{position: "absolute", right:30, top:30, zIndex: 99}}>
              <AppAvatar/>
          </div>
        </>
    )
}

const DefaultHeader = (props) => {
    return (
        <header id={'header'}>
            {AuthProvider.isCustomerView() || AuthProvider.isProfileSelectorView()
                ? <HeaderCustomerView />
                : <HeaderLogo />
            }
            <CenterMenu {...props} />
            <div className="name">
                {(AuthProvider.isCustomerView() || AuthProvider.isProfileSelectorView())
                    ? <CustomerAvatar />
                    : <PrimaryAvatar />
                }
            </div>
        </header>
    )
}

export function HeaderLogo(props) {
    const history = useHistory()
    const classes = useStyles()
    return (
        <h1>
            <a href="#" className={classes.logo} onClick={() => {
                if(props.click){
                    props.click()
                } else {
                    history.replace('/')
                }
            }}>브레인헬스(주)</a>
        </h1>
    )
}

export function HeaderCustomerView(props){
    const classes = useStyles()
    const history = useHistory()
    const [,setCookie] = useCookies()

    const clearSession = () => {
        if(AuthProvider.isCustomerView() || AuthProvider.isProfileSelectorView()){
            const originalCookie = localStorage.getItem(AuthProvider.userAdminCookieName)
          
            setCookie(AuthProvider.userCookieName, originalCookie , AuthProvider.getCookieOption())
            
            if(AuthProvider.isCustomerView() ){
              localStorage.removeItem(AuthProvider.userAdminCookieName)  
            }
            AuthProvider.clearCustomer()
        }
    };

    /**
     * profile-selector는 처리하지 않습니다 
     * 새로고침 해도 계속 쿠키를 유지해야하기때문
     */
    const clearSessionBeforeUnload = () => {
      //앱모드 일경우도 profile selector 타입이면 쿠키를 삭제하지 않습니다
      if(AuthProvider.isAppMode() && AuthProvider.isProfileSelectorView()) return;
      
      if(AuthProvider.isCustomerView() || AuthProvider.isAppMode()){
        clearSession()
      }
    };
    
    useEffect(() => {
        window.addEventListener("beforeunload", clearSessionBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", clearSessionBeforeUnload);
        }
    },[])
    
    return (
        <h1 style={{marginTop: -15}}>
            <IconButton
                className={clsx(classes.back, {
                  'text-white': !!props.color 
                })}
                aria-label="delete"
                onClick={async ()=>{
                    
                    let returnUrl = '/manage/customer'
                    if(AuthProvider.isProfileSelectorView()) {
                      returnUrl = '/profile-selector'
                    }
                    
                    clearSession()
                    SocketProvider.disconnect()
                  
                    history.replace(returnUrl)
                }}
            >
                <ArrowBackIcon style={{fontSize: 50}}/>
            </IconButton>
        </h1>
    )
}

//센터 메뉴를 표시합니다.
export function CenterMenu(props) {
    const [currentPath, setCurrentPath] = useState('')

    // 메뉴가 필요없는 페이지
    const ignoreMenu = ['/login', '/loginHelp', '/profile', '/editProfile' ]
    const pathname = window.location.pathname

    for(let i = 0; i < ignoreMenu.length; i ++){
        if( -1 < pathname.indexOf(ignoreMenu[i]) )
            return <></>
    }

    const userRole = AuthProvider.getRole()
    let menu = props.menu.filter(m => m.role.includes(userRole))

    /**
     * 측정전용 아이디 일경우 사용 
     */
    if(AuthProvider.isProfileSelectorView()){
      menu = props.menu.filter( m => m.profileSelectView)
    }

    return  (
            <nav>
                <ul id="gnb">
                {menu.map((value, index) => {
                    return (
                        <li key={index}>
                            <Link to={value.url}
                                  className={-1 < pathname.indexOf(value.url) ? "active" : ""}
                                  onClick={() => {
                                      setCurrentPath(currentPath + pathname)
                                  }}
                            >
                                {value.name}
                            </Link>
                        </li>
                    ) 
                })}
                </ul>
            </nav>
    )
}


const mapStateToProps = state => {
    return { menu: state.UserAppReducer.menu }
}

const mapDispatchToProps = dispatch => {
    return {
        activeMenu: (url) => {
            dispatch(activeMenu(url))
        }
    }
}

const useStyles = makeStyles(() => ({
    logo: {
        backgroundImage: `url(${require('@images/logo.png')})`
    },
    back: {
        '&:hover': {
            backgroundColor: '#ededed',
        }
    },
    colorWhite: {color: "white !important"},
    dropMenu: {
        height: 300,
        background: '#fff',
        '&:hover': {
            height: 300,
        },
        transitionDelay: '0.3s'
    }
}));


export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
