import React, {useCallback, useEffect, useState} from "react";
import {Box, Grid} from "@material-ui/core";

import PrescriptionService from '@user-service/PrescriptionService';
import BarChart from '@user-components/BarChart';
import HeatMap from "react-heatmap-grid";
import Typography from "@material-ui/core/Typography";
import ColorHelper from '@helper/ColorHelper';

export default function BrainActive(props) {
    const { zoom, barchartHeight, barchartWidth, fontSize, valueType, animationDuration, showTable } = props
    const size = 90
    
    const [brainActive, setBrainActive] = useState([])
    const measureCode = ['MA', 'MM' , 'MB']
    const measureColor = ['blue', 'green', 'red']

    const fetch = useCallback(async (prescriptionID) => {
        let data = props.brainActiveData 
        if(!data) {
            data = await PrescriptionService.getBrainActive(prescriptionID)
        }
        
        const newData = [];
        measureCode.map((code) => {
            newData.push(data[code])
            return code
        })
        setBrainActive(newData)
    },[])

    useEffect(() =>  {
        fetch(props.prescriptionID).then()
    }, [fetch, props.prescriptionID])

    const maxWidth = barchartWidth ? undefined : 1280 
    return (
        <Box mt={5}
             mb={10}
             display="flex"
             justifyContent="center"
             alignItems="center"
             style={{ zoom }}
        >
            <Grid container spacing={3} style={{maxWidth}}>
                {brainActive.map((data, index) => {
                    const defaultData = [
                        data.band.ch1,
                        data.band.ch2
                    ]

                    let xlabel = ['8hz','9hz','10hz','11hz']
                    let ylabel = ['4hz','5hz','6hz','7hz']
                    const labels = ['δ', 'θ', 'α', 'SMR', 'β', 'βM', 'βh']
                    return (
                        <Grid item key={`ba${index}`} xs={4} style={{width: barchartWidth}}>
                            <BarChart
                                style={{backgroundColor: '#ddd'}}
                                data={new Array(6).fill(0)}
                                defaultData={defaultData}
                                showTopValue={true}
                                topValueMargin={20}
                                labels={labels}
                                width={ barchartWidth }
                                height={ barchartHeight ? barchartHeight : 200}
                                useColorIndex={true}
                                showYAxes={true}
                                colors={['#60C3F7', '#A9D8AA']}
                                grouped={true}
                                groups={2}
                                fontSize={fontSize}
                                topValueFontSize={fontSize}
                                valueType={valueType}
                                animationDuration={animationDuration}
                            />

                            {showTable && 
                            <Box style={{paddingLeft:'20px', marginTop: 20}}>
                                <div>
                                    <table className={'border-table'}>
                                        <thead></thead>
                                        <tbody>
                                            <tr>
                                                {['',...labels].slice(0,4).map((text,index) => <th key={`th-text-${index}`}>{text}</th>)}
                                            </tr>
                                            <tr>
                                                <td>L</td>
                                                {labels.slice(0,3).map((text,index) => 
                                                    <td key={`td-text-${index}`} style={{fontSize: '1.1rem' }}>
                                                        <b>{defaultData ? defaultData[0][index] : ""}</b>
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td>R</td>
                                                {labels.slice(0,3).map((text,index) =>
                                                    <td key={`td-text-${index}`} style={{fontSize: '1.1rem'}}>
                                                        <b>{defaultData ? defaultData[1][index] : ""}</b>
                                                    </td>
                                                )}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{marginTop: 10}}>
                                    <table className={'border-table'}>
                                        <thead></thead>
                                        <tbody>
                                        <tr>
                                            {['',...labels.slice(3,6)].map((text,index) => <th key={`th-text-${index}`}>{text}</th>)}
                                        </tr>
                                        <tr>
                                            <td>L</td>
                                            {labels.slice(3,6).map((text,index) =>
                                                <td key={`td-text-${index}`} style={{fontSize:'1.1rem'}}>
                                                    <b>{defaultData ? defaultData[0][index] : ""}</b>
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            <td>R</td>
                                            {labels.slice(3,6).map((text,index) =>
                                                <td key={`td-text-${index}`} style={{fontSize: '1.1rem'}}>
                                                    <b>{defaultData ? defaultData[1][index] : ""}</b>
                                                </td>
                                            )}
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Box>
                            }
                            <Box mt={2} mb={5} >
                                <Typography align={'center'} variant={'h5'}>{measureCode[index]}</Typography>
                            </Box>
                            
                            <HeatMap
                                xLabels={xlabel}
                                yLabels={ylabel}
                                yLabelTextAlign={'center'}
                                data={data.active}
                                squares
                                height={size}
                                cellStyle={(background, value, min, max, data, x, y) => {
                                    return {
                                        backgroundColor: ColorHelper.getColorPercent(measureColor[index], max, value),
                                        fontSize:25,
                                        width: size,
                                        height: size,
                                        lineHeight: '50px',
                                        fontWeight: 'bold',
                                        color: '#666'
                                    }
                                }}
                                cellRender={ (value, x, y) => {
                                    return value
                                }}
                                title={(value, unit) => `${value}`}
                            />
                            
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}
