import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Grid, Typography, Box, Button, ButtonGroup} from '@material-ui/core';
import '@user-style/hexgrid.css';

import FFTChart from '@user-components/FFTChartMerge';
import ColorHelper from '@helper/ColorHelper';
import MvHeatMap from '@user-components/MvHeatMap';
import PrescriptionService from '@user-service/PrescriptionService';
import MeasureService from "../../services/MeasureService";
import queryString from "query-string";



const MeasureFFT = ({match}) => {
    const qs = queryString.parse(window.location.search)
    
    const [bandType] = useState(match.params.bandType)
    const [bandData, setBandData] = useState(null)
    const [hideHeatmap] = useState(!!qs.hideHeatmap)
    const [prescriptionID] = useState(match.params.prescriptionID)
    
    const fetch = useCallback(async () => {
        let measureTypeCode = 10
        let hzType = 'default'
        let measureCode = 'C'

        const qs = queryString.parse(window.location.search)
      
        if('rest' === bandType) {
            measureTypeCode = 10
        } else if ('attemption' === bandType) {
            measureTypeCode = 20
        } else if ('concentration' === bandType) {
            measureTypeCode = 30
        } else if ('brainactive' === bandType) {
            measureTypeCode = 50
        } else if ('eeg' === bandType) {
            measureTypeCode = 10
            hzType = 'eeg'
        } else if ('measure-band-test' === bandType){
          measureTypeCode = 61
          hzType = 'measureBandTest'
          if(qs.measureCode) measureCode = qs.measureCode  
        }
        
        const raw = await PrescriptionService.getFFTData(prescriptionID, hzType ,measureTypeCode, measureCode)
        setBandData(raw)
    },[])
    
    useEffect(() => {
        fetch().then()
    }, [fetch]);

    if (!bandData) return <></>

    return (
        <div>
            <div className="measure-wrap innerViewport z7">
                <Box>
                    <BrainFFT 
                        prescriptionID={prescriptionID} 
                        bandType={bandType} 
                        raw={bandData} 
                        hideHeatmap={hideHeatmap}/>
                </Box>
            </div>
        </div>
    );
}

/**
 * 측정한 FFT 결과를 보여주는 차트 
 * @param {*} props 
 */
export function BrainFFT(props) {
    
    let fftChartCh1Ref = useRef(null);
    
    const mvHeatmapRef = useRef()
    const [fftData, setFFTData ] = useState()
    const [brainType] = useState('both')
    const [cameraType , setCameraType] = useState('side')
    const [hideHeatmap] = useState(props.hideHeatmap)
    const [typeName] = useState( MeasureService.getBandTypeName(props.bandType))
    
    const [useFreeCamera, setUseFreeCamera] = useState(false)
    const [showCoordinate] = useState(false)
    
    const [stepSize] = useState(10)
    
    const [currentCamera, setCurrentCamera] = useState({
        position: {x:0, y:0, z:0},
        target: {x:0,y:0,z:0}
    })
    
    const nextFFT = (currentLoop, loopCount) => {
        
        const sideData = {
            ch1 : fftData.ch1.slice(currentLoop * stepSize, currentLoop * stepSize + stepSize),
            ch2 : fftData.ch2.slice(currentLoop * stepSize, currentLoop * stepSize + stepSize)
        }
        
        let order = 0
        for(let i = 0 ; i < stepSize; i++){
          //order 값은 1 부터 시작합니다.
          order = (currentLoop * stepSize + i) + 1
          fftChartCh1Ref.current.addData(order , sideData.ch1[i] , sideData.ch2[i], false)
        }

        fftChartCh1Ref.current.renderChart()
        fftChartCh1Ref.current.renderChartOnce()
        
        if(currentLoop < loopCount){
            setTimeout(() => {
                nextFFT(currentLoop + 1, loopCount)  
            }, 100)
        }
        
    }
    
    useEffect(() => {
        setFFTData(props.raw)
    }, [])

    useEffect(() => {
        if(!fftData) return

        fftChartCh1Ref.current.initRender()
        nextFFT(0,(fftData.ch1.length / stepSize) - 1)
        try {
            mvHeatmapRef.current.setData(fftData.ch1)    
        } catch {}
        
        setTimeout(() => {
            setCamera('side')
        }, 500)
    }, [fftData])
    
    useEffect(() => {
        if(fftData){
            fftChartCh1Ref.current.updateBrainType(brainType, fftData)
        }
    },[brainType])


    const fftChartOptions = {
        graphWireFrame: false,
        graphWireFrameColor: 0x666666,
        hzSpacing: 10,
        hzCount: 60,   
        cone: false,
        gridSize: 0.7,
        mvSize: 0.3,
        timeHzGrid: true,
        timeMvGrid: true,
        mvHzGrid: true,
        timeGuide: true,
        graphColors: ColorHelper.getColors(),
        height: 900,
        brainType,
        cameraType
    };

    const saveImage = async () => {
        try {
            const ch1Image = fftChartCh1Ref.current.saveCanvasImage();

            const params = {
                ch1Image,
                fileKey: fftData.fileKey,
                fftType: 'merged'
            }

            await PrescriptionService.fileUploadFFT(props.prescriptionID, params);
        } catch(e) {}
    }

    const setCamera = (type) =>{
        setCameraType(type)
        fftChartCh1Ref.current.setCameraByType(type)
    }
    
    const setFreeCamera = () => {
        const change = !useFreeCamera
        setUseFreeCamera(change)
        fftChartCh1Ref.current.setFreeCamera(change)
        
    }
    
    const setCameraControl = (direction) =>{
        fftChartCh1Ref.current.setCameraPosition(direction)
        getCoordinate()
    //     setLookAt(
    //         positionX: number, positionY: number, positionZ: number,
    //         targetX: number, targetY: number, targetZ: number,
    //         enableTransition: boolean = false,
    // ): void {
        
    }
    const getCoordinate = () => {
        const camera = fftChartCh1Ref.current.currentCamera
        
        setCurrentCamera({
            position: {
                x: camera.position.x,
                y: camera.position.y,
                z: camera.position.z,
            }, 
            target: {
                x: camera.target.x,
                y: camera.target.y,
                z: camera.target.z,
            }
        })
    }   
    
    return (
        <Box>
            <Box position={'absolute'} top={30} left={30} zIndex={9999}>
                <Grid container spacing={2}>
                    <Grid item>
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button
                                size={'small'}
                                variant={'side' === cameraType ? 'contained' : 'outlined'}
                                onClick={() => {setCamera('side')}}
                            >
                                측면
                            </Button>
                            <Button
                                size={'small'}
                                variant={'front' === cameraType ? 'contained' : 'outlined'}
                                onClick={() => {setCamera('front')}}
                            >
                                정면
                            </Button>

                            <Button
                                size={'small'}
                                variant={'top' === cameraType ? 'contained' : 'outlined'}
                                onClick={() => {setCamera('top')}}
                            >
                                평면
                            </Button>
                        </ButtonGroup>
                    </Grid>

                    <Grid item>
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button
                                size={'small'}
                                variant={useFreeCamera ? 'contained' : 'outlined'}
                                onClick={() => {setFreeCamera()}}
                            >
                                자유이동
                            </Button>
                        </ButtonGroup>
                    </Grid>

                    <Grid item>
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button
                                size={'small'}
                                variant={'outlined'}
                                onClick={() => {saveImage().then()}}
                            >
                                기본이미지로 저장
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Box>
            <Grid container justify="center" spacing={5} style={{width: '100%'}}>
                <Grid item>
                    <Box mt={1} mb={1}>
                        <Typography variant={'h5'}>
                            {props.prescriptionID} 회차 - {typeName}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box border={1} borderColor={'#eee'} padding={2} style={{width: '100%', position:'relative'}}>
                <Box position={'absolute'} left={30} top={10} zIndex={999} maxWidth={110}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}/>
                        <Grid item xs={4}>
                            <button onClick={()=>setCameraControl('top')}>
                                <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" width="29" height="29" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m16.843 13.789c.108.141.157.3.157.456 0 .389-.306.755-.749.755h-8.501c-.445 0-.75-.367-.75-.755 0-.157.05-.316.159-.457 1.203-1.554 3.252-4.199 4.258-5.498.142-.184.36-.29.592-.29.23 0 .449.107.591.291 1.002 1.299 3.044 3.945 4.243 5.498z"/></svg>
                            </button>
                        </Grid>
                        <Grid item xs={4}/>

                        <Grid item xs={4}>
                            <button onClick={()=>setCameraControl('left')}>
                                <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" width="29" height="29" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m13.789 7.155c.141-.108.3-.157.456-.157.389 0 .755.306.755.749v8.501c0 .445-.367.75-.755.75-.157 0-.316-.05-.457-.159-1.554-1.203-4.199-3.252-5.498-4.258-.184-.142-.29-.36-.29-.592 0-.23.107-.449.291-.591 1.299-1.002 3.945-3.044 5.498-4.243z"/></svg>
                            </button>
                        </Grid>
                        <Grid item xs={4}>
                            <button onClick={()=>setCameraControl('bottom')}>
                                <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" width="29" height="29" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m16.843 10.211c.108-.141.157-.3.157-.456 0-.389-.306-.755-.749-.755h-8.501c-.445 0-.75.367-.75.755 0 .157.05.316.159.457 1.203 1.554 3.252 4.199 4.258 5.498.142.184.36.29.592.29.23 0 .449-.107.591-.291 1.002-1.299 3.044-3.945 4.243-5.498z"/></svg>
                            </button>
                        </Grid>
                        <Grid item xs={4}>
                            <button onClick={()=>setCameraControl('right')}>
                                <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" width="29" height="29" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m10.211 7.155c-.141-.108-.3-.157-.456-.157-.389 0-.755.306-.755.749v8.501c0 .445.367.75.755.75.157 0 .316-.05.457-.159 1.554-1.203 4.199-3.252 5.498-4.258.184-.142.29-.36.29-.592 0-.23-.107-.449-.291-.591-1.299-1.002-3.945-3.044-5.498-4.243z"/></svg>
                            </button>
                        </Grid>

                        
                    </Grid>
                </Box>
                {showCoordinate && 
                <Box position={'absolute'} left={170} top={5}>
                    <div>
                        <span>pos x: {currentCamera.position.x}</span><br/>
                        <span>pos y: {currentCamera.position.y}</span><br/>
                        <span>pos z: {currentCamera.position.z}</span><br/>
                        <span>tar x: {currentCamera.target.x}</span><br/>
                        <span>tar y: {currentCamera.target.y}</span><br/>
                        <span>tar z: {currentCamera.target.z}</span><br/>
                    </div>
                </Box>
                }
                <Grid container style={{width: '100%'}}>
                    <Grid item xs={12} style={{position: 'relative'}} >
                        <FFTChart 
                            label={'Brain'} 
                            options={ fftChartOptions } 
                            ref={ fftChartCh1Ref }
                            cameraUpdate={(currentCamera) => {
                                getCoordinate()
                            }}
                        />
                    </Grid>

                    {!hideHeatmap &&
                    <Grid item xs={12} style={{overflowX: 'auto'}}>
                        <Grid container justify="center" spacing={5} style={{width: '100%'}}>
                            <Grid item>
                                <MvHeatMap
                                    ref={mvHeatmapRef}
                                    size={30}
                                    useButton={true}
                                    xLabelLimitCount={30}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    )
}
export default MeasureFFT
