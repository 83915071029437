import React from 'react';
import {Line} from 'react-chartjs-2';



const MeasureEEGLineChart = ({bandAvg}) => {
    if(!bandAvg) return <></>
    
    return (
        <LineChart bandAvg={bandAvg}/>
    )
};


export function LineChart({bandAvg}) {
    const labelNames = ['좌뇌(Left)','우뇌(Right)']
    const colors = [
        {
            backgroundColor: "rgba(0,178,245,0.5)",
            borderColor: "rgba(0,178,245,1)",
            pointBorderColor: "rgba(0,178,245,1)",
            pointBackgroundColor: "rgba(0,178,245,1)"
        },{
            backgroundColor: "rgba(199,116,210,0.5)",
            borderColor: "rgba(199,116,210,1)",
            pointBorderColor: "rgba(199,116,210,1)",
            pointBackgroundColor: "rgba(199,116,210,1)"
        }
    ]
    const data = {
        labels: bandAvg[0].map((d , index) => `${(index) * 10}초`),
        datasets: bandAvg.map((d, index) => {
            return {
                label: labelNames[index],
                data: d.map(values => values[0]),
                fill: false,
                // backgroundColor: "rgba(0,178,245,0.5)",
                // borderColor: "rgba(0,178,245,1)",
                // pointBorderColor: "rgba(0,178,245,1)",
                borderWidth: 8,
                pointBorderWidth: 12,
                pointRadius: 7,
                ...colors[index]
            }

        })
    }

    function getLineValue(scale, index, offsetGridLines) {
        let lineValue = scale.getPixelForTick(index);

        if (offsetGridLines) {
            if (index === 0) {
                lineValue -= (scale.getPixelForTick(1) - lineValue) / 2;
            } else {
                lineValue -= (lineValue - scale.getPixelForTick(index - 1)) / 2;
            }
        }
        return lineValue;
    }
    
    const gridBackground = {
        id: 'gridBgColor',
        beforeDraw: function (chartInstance) {
            const ctx = chartInstance.chart.ctx;
            const chartArea = chartInstance.chartArea;

            let scale;
            let si;
            let ti;
            let bgCol = [], bgColRpt;
            let x1, y1, x2, y2;
            let axisWidth;
            let isHorizontal;
            let scaleType;
            let gridLines;
            let lineWidth;

            for (si in chartInstance.scales) {
                scale = chartInstance.scales[si];
                axisWidth = scale.options.gridLines.lineWidth;
                isHorizontal = scale.isHorizontal();
                scaleType = scale.options.type;
                gridLines = chartInstance.scales[si].options.gridLines;
                lineWidth = gridLines.lineWidth;
                bgCol = scale.options.gridLines.backgroundColor;
                try {
                    bgCol = ( bgCol == undefined ? chartInstance.chart.options.plugins.gridBgColor.backgroundColor : bgCol );    
                } catch {}
                
                bgColRpt = scale.options.gridLines.backgroundColorRepeat;
                try {
                    bgColRpt = ( bgColRpt == undefined ? chartInstance.chart.options.plugins.gridBgColor.backgroundColorRepeat : bgColRpt );    
                } catch {}
                

                if (!scale.options.display || bgCol == undefined) {
                    // No background coloring for this scale; next scale.
                    continue;
                };

                if (typeof bgCol === 'string') {
                    // One color for the complete scale background.
                    // One rectangle for the chart area.
                    ctx.fillStyle = bgCol;
                    ctx.fillRect(chartArea.left, chartArea.bottom
                        , chartArea.right - chartArea.left, chartArea.top - chartArea.bottom);
                    continue; // next scale
                };

                if (bgCol.constructor === Array) {

                    // Color the individual gridline backgrounds.
                    // Algorithm in essence:
                    // Loop through the gridLines (ticks),
                    //	 If backgroundColor is different from previous,
                    //		 draw the background rectangle and 
                    //		 save the new coordinates as the start of the next rectangle

                    // A simpler algorithm would be to draw each gridLine's background individually,
                    // but I got overlaps and gaps (of 1 pixel width), so that was too ugly.
                    // Plus, this one is more efficient, as it draws less background rectangles.

                    let prevTick= {
                        bgCol: '',
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 0,
                    };
                    let curBgCol;
                    let numToDo = scale.ticks.length - (scaleType != 'category' ? 1 : 0);

                    for (ti=0; ti<=numToDo; ti++) { // Note: this does one extra tick!

                        ti = Number(ti);

                        if (ti == numToDo) {
                            // This is the last gridline, make sure we draw it.
                            // Also, there probably is no more bgCol[ti]
                            curBgCol = 'differentFromPrevious';
                        } else {
                            // Repeat the bgColors if necessary.
                            if (bgColRpt) {
                                curBgCol = bgCol[ti % bgCol.length];
                            } else {
                                curBgCol = bgCol[ti];
                            };
                        };

                        if ( curBgCol != prevTick.bgCol ) {
                            // Some code copied from Chart.js scale.
                            if (isHorizontal) {
                                // Vertical grid line / grid background
                                let xLineValue = getLineValue(scale, ti, gridLines.offsetGridLines && scale.ticks.length > 1);
                                xLineValue += (lineWidth % 2 === 0) ? 0 : 0.5;
                                x1 = x2 = xLineValue;
                                y1 = chartArea.top;
                                y2 = chartArea.bottom + axisWidth;
                            } else {
                                // Horizontal grid line
                                let yLineValue = getLineValue(scale, ti, gridLines.offsetGridLines && scale.ticks.length > 1);
                                yLineValue += (lineWidth % 2 === 0) ? 0 : 0.5;
                                x1 = chartArea.left;
                                x2 = chartArea.right + axisWidth;
                                y1 = y2 = yLineValue;
                            };

                            if (gridLines.drawOnChartArea && prevTick.bgCol != '') {

                                ctx.save();
                                ctx.fillStyle = prevTick.bgCol;

                                if (isHorizontal) {
                                    // Horizontal scale, vertical grid line / grid background
                                    ctx.fillRect(
                                        prevTick.x1, prevTick.y1,
                                        Math.abs(x2 - prevTick.x1), Math.abs(y2 - prevTick.y1)
                                    );
                                } else {
                                    // Horizontal grid line
                                    ctx.fillRect(
                                        prevTick.x1, prevTick.y1,
                                        Math.abs(x2 - prevTick.x1), Math.abs(y2 - prevTick.y1)
                                    );
                                };
                            }; // gl.drawonChartArea
                            ctx.restore();

                            // Save the new coordinates
                            prevTick.bgCol = curBgCol;
                            prevTick.x1 = x1;
                            prevTick.y1 = y1;
                            prevTick.x2 = x2;
                            prevTick.y2 = y2;

                        }; // bgCol != bgCol

                    }; //for ti in ticks

                    continue;
                }; // if bgCol is of type array

                // If we get here, that's an error!
                // console.log("chartjs-plugin-gridline-background.js: Error, unknown type of backgroundColor (not String or Array): " + bgCol);

            }; // for si

        } // beforeDraw: function
    }
    
    const options = {
        legend: {
            display: true,
            labels: {
                fontColor: "black",
                fontSize: 30,
            },
            position: 'bottom'
        },
        layout: {
            padding: 5
        },
        scales: {
            xAxes: [{
                display: true,
                gridLines: {
                    backgroundColor: [ 
                        'rgba(108,196,247,0.3)','rgba(108,196,247,0.3)','rgba(108,196,247,0.3)','rgba(108,196,247,0.3)',
                        'rgba(169, 216,170,0.3)','rgba(169, 216,170,0.3)','rgba(169, 216,170,0.3)','rgba(169, 216,170,0.3)',
                        'rgba(248, 132,122,0.3)','rgba(248, 132,122,0.3)','rgba(248, 132,122,0.3)','rgba(248, 132,122,0.3)',
                        'rgba(255, 255,255,0)'
                    ],
                    display: true,
                    color: "#fff",
                },
                scaleLabel: {
                    display: false,
                    labelString: 'Month'
                },
                ticks: {
                    fontColor: "black",
                    fontSize: 30
                },
            }],
            yAxes: [{
                display: true,
                gridLines: {
                    display: true,
                    color: "#fff"
                },
                scaleLabel: {
                    display: false,
                    labelString: 'Value'
                },
                ticks: { 
                    suggestedMin: 0,
                    suggestedMax: 50,
                    beginAtZero: true,
                    fontColor: "black",
                    fontSize: 30
                },
            }]
        }
    }
    
    return (
        <Line data={data} key={Math.random()} options={options} height={110} plugins={[gridBackground]}/>
    )
}

export default MeasureEEGLineChart;
