import React, {useEffect, useState} from 'react';

import {Box, Paper, Grid, IconButton} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'

import Skeleton from '@material-ui/lab/Skeleton'
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const Voucher = ({history}) => {
    const classes = useStyles();
    const [products , setProducts] = useState({
        P8 : {checked: false, price: 35000,},
        P15 : {checked: false, price: 35000,},
        P9 : {checked: false, price: 30000,},
        P10 : {checked: false, price: 70000},
        P11 : {checked: false, price: 3000},
        P12 : {checked: false, price: 3000},
        P13 : {checked: false, price: 3000},
        P14 : {checked: false, price: 110000},
    })

   
    const [testProgram] = useState([

        {title: '기억력 3종 검사&분석', desc: '숫자, 그림, 기호', price: 9000},
        {title: '기억 입출력 기능검사&분석', desc: '숫자, 그림, 기호', price: 9000},
        {title: '주의&집중 검사&분석', desc: '1~9단계 검사 프로그램', price: 3000},
        {title: '인지변별력 검사&분석', desc: '캐릭텨를 변별하여 인지한 다음 문자로 인지', price: 3000},
        {title: '메타인지 검사&분석', desc: '문자를 변별하여 인지한 다음 캐릭터 인지', price: 3000},
        
    ])
    useEffect(()=>{
        
    },[])
    
    const fmt = new Intl.NumberFormat()
    // let totalPrice = 0
    
    // Object.keys(products).map((key) => {
    //     const p = products[key]
    //     if(p.checked){
    //         totalPrice += p.price
    //     }
    //     return p
    // })
    
    return (
        <div id="cont-wrap">
            <div className="sv">
                <Grid container>
                    <Grid item xs={1}>
                        <IconButton
                            className={classes.back}
                            aria-label="delete"
                            onClick={()=>{
                                history.goBack()
                            }}
                        >
                            <ArrowBackIcon style={{fontSize: 50}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <div>
                            <h3>이용권</h3>
                            <span>이용권을 선택하세요</span>
                            <i>Brain Health</i>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div className="cont">
                <Box border={1} mt={3} p={5} style={{borderColor: '#ddd', borderRadius: 10}}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant={'subtitle1'}>
                                <span>계좌번호</span>: 기업은행088-044624-01-044
                            </Typography>
                            <Typography variant={'subtitle1'}>
                                <span>계좌명</span>: 브레인헬스(주)
                            </Typography>
                        </Grid>
                    </Grid>

                </Box>
                
                <Box mt={5}>
                    <Typography className={classes.title}>측정프로그램</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Paper elevation={0} 
                                   className={classes.voucherWrap}
                                   onClick={() => {
                                       const product = products.P8
                                       product.checked = !product.checked
                                       
                                        setProducts({
                                            ...products,
                                            P8: product
                                        })   
                                   }}
                            >
                                <Grid container>
                                    <Grid item xs={1}> 
                                        {/*{products.P8.checked && <CheckCircleIcon className={classes.checked}/> }*/}
                                        {/*{!products.P8.checked && <RadioButtonUncheckedIcon /> }*/}
                                    </Grid>
                                    <Grid item xs={3}> 뇌기능 4대 측정</Grid>
                                    <Grid item xs={6}> EEG 측정 / 분석</Grid>
                                    <Grid item xs={2} className={classes.price}> {fmt.format(products.P8.price)}원</Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={0} 
                                   className={classes.voucherWrap}
                                   onClick={() => {
                                       const product = products.P9
                                       product.checked = !product.checked
    
                                       setProducts({
                                           ...products,
                                           P9: product
                                       })
                                   }}
                            >
                                <Grid container>
                                    <Grid item xs={1}>
                                        {/*{products.P9.checked && <CheckCircleIcon className={classes.checked}/> }*/}
                                        {/*{!products.P9.checked && <RadioButtonUncheckedIcon/> }*/}
                                    </Grid>
                                    <Grid item xs={3}> 뇌기능 의식패턴 측정</Grid>
                                    <Grid item xs={6}> 
                                        ① MA (1단계; 1분30초)
                                        ② MM (2단계: 1분30초)
                                        ③ MB  (3단계: 1분30초)
                                    </Grid>
                                    <Grid item xs={2} className={classes.price}> {fmt.format(products.P9.price)}원</Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={0}
                                   className={classes.voucherWrap}
                                   onClick={() => {
                                       const product = products.P15
                                       product.checked = !product.checked

                                       setProducts({
                                           ...products,
                                           P15: product
                                       })
                                   }}
                            >
                                <Grid container>
                                    <Grid item xs={1}>
                                        {/*{products.P8.checked && <CheckCircleIcon className={classes.checked}/> }*/}
                                        {/*{!products.P8.checked && <RadioButtonUncheckedIcon /> }*/}
                                    </Grid>
                                    <Grid item xs={3}> 자각증상 검사</Grid>
                                    <Grid item xs={6}> 1회 측정 </Grid>
                                    <Grid item xs={2} className={classes.price}> {fmt.format(products.P15.price)}원</Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
    
                <Box mt={5}>
                    <Typography className={classes.title}>뇌기능 종합분석 프로그램</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Paper elevation={0} 
                                   className={classes.voucherWrap}
                                   onClick={() => {
                                       const product = products.P10
                                       product.checked = !product.checked
    
                                       setProducts({
                                           ...products,
                                           P10: product
                                       })
                                   }}
                            >
                                <Grid container>
                                    <Grid item xs={1}>
                                        {/*{products.P10.checked && <CheckCircleIcon className={classes.checked}/> }*/}
                                        {/*{!products.P10.checked && <RadioButtonUncheckedIcon/> }*/}
                                    </Grid>
                                    <Grid item xs={3}>뇌기능종합분석프로그램</Grid>
                                    <Grid item xs={6}>
                                        <Box>뇌성향 종합분석</Box>
                                        <Box> 뇌기능 자기조절 분석</Box>
                                        <Box> 정신적 스트레스 분석</Box>
                                        <Box> 정신적 스트레스 저항분석</Box>
                                        <Box> 육체적 스트레스 분석</Box>
                                        <Box>육체적 스트레스 저항분석</Box>
                                        <Box> 정서상태 3대 분석</Box>
                                    </Grid>
                                    <Grid item xs={2} className={classes.price}> {fmt.format(products.P10.price)}원</Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
    
                <Box mt={5}>
                    <Typography className={classes.title}>검사프로그램</Typography>
                    <Grid container spacing={1}>
                        {testProgram.map((program) => {
                            return (
                                <Grid item xs={12}>
                                    <Paper elevation={0} className={classes.voucherWrap}>
                                        <Grid container>
                                            <Grid item xs={1}>
                                                {/*{products.P11.checked && <CheckCircleIcon className={classes.checked}/> }*/}
                                                {/*{!products.P11.checked && <RadioButtonUncheckedIcon/> }*/}
                                            </Grid>
                                            <Grid item xs={3}>{program.title}</Grid>
                                            <Grid item xs={6}>{program.desc}</Grid>
                                            <Grid item xs={2} className={classes.price}> {fmt.format(program.price)}원</Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
    
                <Box mt={5}>
                    <Typography className={classes.title}>제품</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Paper elevation={0} 
                                   className={classes.voucherWrap}
                                   onClick={() => {
                                       const product = products.P14
                                       product.checked = !product.checked
    
                                       setProducts({
                                           ...products,
                                           P14: product
                                       })
                                   }}
                            >
                                <Grid container>
                                    <Grid item xs={1}>
                                        {/*{products.P14.checked && <CheckCircleIcon className={classes.checked}/> }*/}
                                        {/*{!products.P14.checked && <RadioButtonUncheckedIcon/> }*/}
                                    </Grid>
                                    <Grid item xs={3}>
                                        브레인 헬스 밴드 
                                    </Grid>
                                    <Grid item xs={6}>브레인 헬스 밴드 </Grid>
                                    <Grid item xs={2} className={classes.price}> {fmt.format(products.P14.price)}원</Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={10} mb={10}>
                  {/*<div>서비스 유의사항</div>*/}
                </Box>
                    
                {/*<Box className={classes.cartWrap}>*/}
                {/*    <Box style={{position:'relative'}}>*/}
                {/*        <Box className={classes.cartBack} />*/}
                {/*        <Box position={'absolute'} zIndex={2} width={'100%'}>*/}
                {/*            <Grid container>*/}
                {/*                <Grid item xs={10}>*/}
                {/*                    <Box display={'flex'}*/}
                {/*                         alignItems={'center'}*/}
                {/*                         justifyContent={'flex-end'}*/}
                {/*                         height={100}*/}
                {/*                         style={{fontSize:24, color: 'white', fontWeight: 'bold'}}*/}
                {/*                    >*/}
                {/*                        {fmt.format(totalPrice)}원*/}
                {/*                    </Box>*/}
                {/*                </Grid>*/}
                {/*                <Grid item xs={2}>*/}
                {/*                    <Box display={'flex'}  alignItems={'center'} justifyContent={'flex-end'} height={100}>*/}
                {/*                        <Button variant={'contained'} className={classes.buyButton}> 구매하기</Button>*/}
                {/*                    </Box>*/}
                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*        </Box>*/}
                {/*    </Box>*/}
                {/*</Box>*/}
            </div>
        </div>
    );
}

//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <div className="editprofile_wrap wrapper">
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </div>
        </Box>
    )
}


const useStyles = makeStyles(() => ({
    back: {
        marginTop: 30,
        '&:hover': {
            backgroundColor: '#053288',
        }
    },
    voucherWrap: {
        paddingTop: 28,
        paddingBottom: 28,
        paddingLeft: 30,
        paddingRight: 30,
        backgroundColor: '#ededed',
        borderRadius: 15,
        // cursor: 'pointer',
        '&:hover': {
            // backgroundColor: '#BABABA'
        }
    },
    price: {
        fontSize: 24,
        alignContent: 'flex-end',
        textAlign: 'right',
        fontWeight: 'bold'
    },
    cartWrap: {
        position: 'fixed',
        height: 100,
        width: '100%',
        left: 0,
        bottom: 0
    },
    cartBack: {
        position: 'absolute',
        backgroundColor: '#000',
        opacity:0.5,
        height: 100,
        width: '100%',
        left: 0,
        top: 0,
        zIndex: 0
    },
    buyButton: {
        backgroundColor: '#1351F9',
        borderRadius: 20,
        color: 'white',
        padding: 10,
        fontSize: 20,
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#0C28C6'
        }
    },
    checked: {
        color: '#1351F9'
    }
    
}));




export default Voucher
