import React, {useCallback, useEffect, useState} from 'react';
import {Box, Grid, Tabs, Tab} from '@material-ui/core';
import PrescriptionSelect from '@user-components/PrescriptionSelect';
import PrescriptionService from '@user-service/PrescriptionService';
import ResultBalance from "./ResultBalance";
import ResultStress from "./ResultStress";
import ResultRegulation from "./ResultRegulation";
import ResultEmotion from "./ResultEmotion";
import {useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import FirstMeasureMessage from "../../../components/FirstMeasureMessage";

const MeasureBalanceCurrent = () => {
    let { prescriptionID } = useParams()
    if('last' === prescriptionID ) {
        prescriptionID = null
    }
    const classes = useStyles()
    const [ currentSelect, setCurrentSelect ] = useState(0)
    const [ measureResult, setMeasureResult ] = useState(null)
    const [ isFirst , setFirst ] = useState(false)
    
    const handleTab = (select) => {
        if(!measureResult.isSet) {
            return
        }
        
        setCurrentSelect(select)
    }
    const fetch = useCallback(async () => {
        let id = prescriptionID
        if(null === id){
            id = await PrescriptionService.getLastPrescription({})
        }
        
        if(null === id){
            setFirst(true)
            return
        }
        
        const result = await PrescriptionService.getBalanceByPrescriptionId(id)
        setMeasureResult(result)
    },[prescriptionID])
    
    useEffect(()=>{
        fetch().then()
        
        return (() => {
            setMeasureResult(null)
        })
    },[prescriptionID, fetch])


    if(!measureResult && !isFirst){
        return <></>
    }
    
    return (
        <div id="cont-wrap">
            <div className="sv">
                <div>
                    <h3>뇌특성 분석 {measureResult? `- ${measureResult.id} 회차` : ''} </h3>
                    <span>EEG, 균형상태 측정, 뇌기능 의식 패턴검사의 특성을 분석합니다.</span>
                    <i>Brain Health</i>
                </div>
            </div>
            <div className={'cont'}>
                <Grid container className={'tabs'}>
                    <Grid item xs={8}>
                        <MenuButtons onUpdate={(select) => handleTab(select)}/>        
                    </Grid>
                    <Grid item xs={4}>
                        <Box display="flex" justifyContent="flex-end" style={{marginBottom: 10}}>
                            <PrescriptionSelect prescriptionID={prescriptionID || 0} url="/measureBalanceCurrent/:prescriptionID"/>    
                        </Box>
                    </Grid>
                </Grid>

                {isFirst 
                    ? <FirstMeasureMessage />
                    :(measureResult && !measureResult.isSet) &&
                        <Box className={'sec-presc'}>
                            <Box className={clsx('innerbox', classes.empty)}>
                                <img src={require('@images/brainLightIcon.png')} alt={'icon'}/>
                                <h4>{measureResult.id}회차 분석은 구매가 필요합니다.</h4>
                                <div>담당자에게 문의하세요</div>
                            </Box>
                        </Box>
                }
                                
                {measureResult && measureResult.isSet &&
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    <Grid item xs={12}>
                        <Box style={{width: 1024}} pt={5}>
                            {0 === currentSelect &&
                            <ResultBalance prescriptionID={prescriptionID} measureData={measureResult.tendency}/>
                            }
                            {1 === currentSelect &&
                            <ResultRegulation prescriptionID={prescriptionID} measureData={measureResult.regulation}/>
                            }

                            {2 === currentSelect &&
                            <ResultStress prescriptionID={prescriptionID} measureData={measureResult.stress}/>
                            }

                            {3 === currentSelect &&
                            <ResultEmotion prescriptionID={prescriptionID} measureData={measureResult.emotion}/>
                            }
                        </Box>
                    </Grid>
                </Grid>
                }
            </div>
        </div>
    );
}

export function MenuButtons(props){
    const classes = useStyles()
    const buttons = [
        {
            title : '뇌기능 성향분석',
            subtitle: ''
        }, 
        {
            title : '뇌기능 자기조절 분석',
        }, 
        {
            title: '스트레스 & 저항력분석',
        },
        {
            title: '정서상태분석',
        }
    ] 
    const [showType, setShowType] = useState(0)
    
    return (
        <Tabs
            value={showType}
            onChange={(event, newValue) => {
                setShowType(newValue)
                if (props.onUpdate) {
                    props.onUpdate(newValue)
                }
            }}
            indicatorColor="primary"
            textColor="primary"
        >
            {buttons.map((button,index) => {
                return (
                    <Tab key={index}
                         className={'tab'}
                         label={<span 
                             className={classes.label}>
                             {button.title}
                         </span>} />
                )
            })}
        </Tabs>
    )
}


const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 1400,
        width: 1400,
    },
    headerBox: {
        marginBottom: 40,
        marginTop: 120
    },
    title: {
        fontSize:26,
        fontWeight: 'bold'
    },
    label: {
        fontSize:20,
        fontWeight: 'bold',
        colo: '#333'
    },
    empty: {
        border:0,
        textAlign: 'center',
        width: '1280px',
        color: '#999',
        fontFamily: `'Lato', 'Noto Sans KR', sans-serif`,
        '& h4': {
            fontSize: '28px !important',
            margin:0,
            height: '90px',
            color: '#999'
        },
        '& img': {
            width : '80px',
            marginTop: '50px'
        }
    }
}));




export default MeasureBalanceCurrent
