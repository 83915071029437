
import React, {useEffect, useState, useCallback} from 'react';
import {
    Box,
    TableContainer, Button,
    Table, TableBody, TableRow, TableCell, Grid, TableHead, Typography
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

import DateHelper from '@helper/DateHelper'
import MeasureService from '@user-service/MeasureService'

import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';

const Moment = require('moment-timezone');
const MeasureTestLogs = (props) => {
    const classes = useStyles();
    const [currentFilter, setCurrentFilter] = useState(props.currentFilter)
    const [week, setWeek] = useState(DateHelper.getWeek())
    const [logs, setLogs] = useState(null)
    const [selectDate, setSelectDate] = useState(DateHelper.now('YYYY-MM-DD'))
    
    const handleDate = (selectDate) => {
        setSelectDate(selectDate)
    }
    
    const fetch = useCallback(async (selectDate, currentFilter) => {
        if(!currentFilter || 0 === currentFilter.length) return 
        
        
        const logs = await MeasureService.getMeasureLog(selectDate, currentFilter.map(p => p.programID))
        setLogs(logs)
    },[])
    
    useEffect(() => {
        // fetch(selectDate , props.currentFilter).then()
        setCurrentFilter((props.currentFilter))
    },[props.currentFilter])
    
    useEffect(()=>{
        fetch(selectDate, currentFilter).then()
    },[selectDate, currentFilter, fetch])

    
    useEffect(()=>{
        fetch(selectDate).then()
    },[week])


    if(!logs){ return <></> }

    return (
        <div id="cont-wrap" className={'app'}>
            <div className="cont" style={{marginTop: 0}} >
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container spacing={1} justify={'center'} alignItems={'center'}>
                            <Grid item>
                                <IconButton 
                                    aria-label={'prev'} 
                                    className={classes.iconButton}
                                    onClick={async () => {
                                        const date = Moment(week[0].fullDate).add(-1, 'day')
                                        setWeek(DateHelper.getWeek(date)) 
                                    }}
                                >
                                    <ArrowBackIosIcon fontSize={'small'} />
                                </IconButton>
                            </Grid>
                            <Grid item> <Typography variant={'h5'}>{week[0].month}월</Typography></Grid>
                            <Grid item> 
                                <Typography style={{marginTop:5}} component={'span'} variant={'subtitle1'}>{week[0].date}일</Typography>
                                ~
                                <Typography style={{marginTop:5}} component={'span'} variant={'subtitle1'}>{week[6].date}일</Typography>
                            </Grid>
                            
                            <Grid item>
                                <IconButton
                                    aria-label={'prev'}
                                    onClick={async () => {
                                        const date = Moment(week[6].fullDate).add(+1, 'day')
                                        setWeek(DateHelper.getWeek(date))
                                    }}
                                >
                                    <ArrowForwardIosIcon fontSize={'small'}  />
                                </IconButton>
                            </Grid>
                        </Grid>
                        
                        <Box mb={5} mt={5}>
                            <Grid container spacing={1} style={{width: '100%', backgroundColor: '#fafafc', borderRadius: 15}}>
                            {week.map((values) => {
                                const { week, date, fullDate} = values
                                return (
                                    <Grid item 
                                      className={classes.date}
                                      key={fullDate}
                                      style={{width: '14.2%', textAlign: 'center'}}
                                    >
                                        <Button
                                            fullWidth    
                                            onClick={()=>{
                                            handleDate(values.fullDate)
                                        }}>
                                            <div>
                                                <span style={{color: '#aaa'}}>{week}</span>
                                                <div className={clsx('dateButton',{
                                                    ['active'] : selectDate === fullDate
                                                })}>{date}</div>
                                            </div>
                                        </Button>
                                    </Grid>
                                )
                            })}
                            </Grid>
                        </Box>
                        
                        <TableContainer>
                            <Table  aria-label="caption table" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>검사명</TableCell>
                                        <TableCell>시작시간</TableCell>
                                        <TableCell>설정시간</TableCell>
                                        <TableCell>측정시간</TableCell>
                                        <TableCell>비율 / 정답수</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBody}>
                                    {logs.map((log, index) => {
                                        return <RenderTable key={index} classes={classes} log={log}/>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
              
            </div>
        </div>
    );
}


export function RenderTable(props){
    const {log , classes} = props
    
    const program = log.program
    const { image, name } =  program
    
    const resultVar = log.resultVar
    
    const viewText = resultVar ? resultVar.viewText : '-'
    const scoreText = resultVar && resultVar.scoreText? resultVar.scoreText : '-'
    const correctCount = resultVar && resultVar.correctCount? resultVar.correctCount + '개' : '-'
    let measureTestType = ['develop','train'].includes(log.programType) ? '계발' : '검사'
    let buttonCls = classes.blue
    
    if( resultVar && resultVar.measureType ){
        measureTestType = ['develop','train'].includes(resultVar.measureType) ? '계발' : '검사'
    }
    
    buttonCls = ['계발'].includes(measureTestType) ? classes.green : classes.blue
    
    //임시 처리 
    if(!log.timeCountText) return <></>
  
    return (
        <TableRow>
            <TableCell>
                <Box className={classes.exerciseBox}>
                    <Grid container>
                        <Grid item>
                            <div className={'title'}>
                                {measureTestType &&
                                    <Chip label={measureTestType} 
                                          style={{marginRight: 10,height:24}}
                                          className={buttonCls}
                                    />
                                }
                                {name}
                                {resultVar && resultVar.challengeID &&
                                <span style={{fontSize:'0.8rem', color:'#999'}}> - 두뇌계발 LV.{resultVar.challengeLevel} </span>
                                }
                                {viewText &&                                     
                                    <span style={{display:'none'}} className={'sub'}>({viewText})</span>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </TableCell>
            <TableCell className={'sub'}>{DateHelper.format(log.createdAt,'YY년 MM월 DD일 HH시mm분')}</TableCell>
            <TableCell className={'sub'}>{log.measureTime}</TableCell>
            <TableCell>{log.timeCountText}</TableCell>
            <TableCell>{scoreText} / {correctCount}</TableCell>
        </TableRow>
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        padding: 30,
        margin: 'auto'
    },
    date: {
        '& .dateButton': {
          fontSize: 20, paddingTop: 8, fontWeight: 'bold', width: 50, height: 50, borderRadius: '50%'
        },
        '& .active': {
            backgroundColor: '#0156B8',
            color: 'white'
        }
    },
    
    tableBody: {
        '& .center': {
          textAlign: 'center'
        },
        '& .sub': {
            color: '#999',
        },
    },
    exerciseBox: {
        '& .title': {
            fontSize: 20,
            fontWeight: 700,
        },
        
        '& .sub': { fontSize: '0.9rem', paddingLeft: 10}
    },
    image : {
        width: 50
    },
    blue: {
        backgroundColor: '#0156B8',
        color: 'white',
        paddingTop: 1
    },
    green: {
        backgroundColor: '#247C40',
        color: 'white',
        paddingTop: 1
    }
}));

export default MeasureTestLogs
