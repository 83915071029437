import React, {useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import AuthProvider from '@user-provider/AuthProvider'
import AudioPlayer from '@user-pages/exercise/common/AudioPlayer'
import { Box, Grid, Typography, Button} from '@material-ui/core';
import {useLocation} from "react-router";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import TimeCount from '@user-pages/exercise/common/TimeCount'

import LevelDialog from "./LevelDialog";
import CompleteDialog from "./CompleteDialog";

import ExerciseService from '@user-service/ExerciseService';
import MeasureService from '@user-service/MeasureService';
import TimerDialog from "./TimerDialog";

import { ViewChar } from "../common/Data"
import MemoryTestService from "./MemoryTestService";
const queryString = require('query-string');

const svg = {
    rightArrow : 'm13.022 14.999v3.251c0 .412.335.75.752.75.188 0 .375-.071.518-.206 1.775-1.685 4.945-4.692 6.396-6.069.2-.189.312-.452.312-.725 0-.274-.112-.536-.312-.725-1.451-1.377-4.621-4.385-6.396-6.068-.143-.136-.33-.207-.518-.207-.417 0-.752.337-.752.75v3.251h-9.02c-.531 0-1.002.47-1.002 1v3.998c0 .53.471 1 1.002 1z',
    leftArrow : 'm10.978 14.999v3.251c0 .412-.335.75-.752.75-.188 0-.375-.071-.518-.206-1.775-1.685-4.945-4.692-6.396-6.069-.2-.189-.312-.452-.312-.725 0-.274.112-.536.312-.725 1.451-1.377 4.621-4.385 6.396-6.068.143-.136.33-.207.518-.207.417 0 .752.337.752.75v3.251h9.02c.531 0 1.002.47 1.002 1v3.998c0 .53-.471 1-1.002 1z'
} 
const SVG = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={props.width ? props.width : 'auto'}
            height={props.height ? props.height : 'auto'}
            viewBox="0 0 24 24"
            fill={props.color}
        >
            <path
                // fill={props.color}
                d={svg[props.name]}
            />
        </svg>
    );
}

const MemoryTest = (props) => {
    const stageRef = useRef()
    const location = useLocation()
    const qs = queryString.parse(window.location.search)
    const history = useHistory()
    
    const prescriptionID = location.state && location.state.prescriptionID ? location.state.prescriptionID : 0
    const showType = props.match.params.showType.indexOf('?') 
                    ? props.match.params.showType.split('?')[0] 
                    : props.match.params.showType

    let stageContainer
    const resizeHandler = () => {
        stageContainer.style.zoom = window.innerHeight / 1080
        
    }
    
    useEffect(() => {
        window.addEventListener('resize', resizeHandler)
        stageContainer = document.getElementById('stage')
        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    },[])
    return (
        <Box style={{position: 'relative', height: '100%'}}>
            <Stage ref={stageRef}
                   history={history}
                   showType={showType}
                   prescriptionID={prescriptionID}
                   programView={qs.programView}
                   exerciseName={location.state.exerciseName}
                   measureType={location.state.measureType}
                   exerciseID={location.state.exerciseID}
                   programID={location .state.programID}
                   playMode={qs.playMode}
                   onLoaded={() => {
                       setTimeout(() => {
                           resizeHandler()    
                       }, 50)
                   }}
            />
        </Box>
    );
}

export class Stage extends React.Component {
    container
    clientRect

    itemWidth = 176
    itemHeight = 190
    isReverse = false
    useReverse = false

    audioRef = React.createRef()
    timeCountRef = React.createRef()
    levelDialogRef = React.createRef()
    timerDialogRef = React.createRef()
    cogBoxRef = React.createRef()
    cogItemsRef = React.createRef()
    cogNavigationRef = React.createRef()
    completeDialogRef = React.createRef()

    currentStep = 1
    maxStep = 10
        
    randomIndex = []
    gameResults = []
    
    constructor(props) {
        super(props)
        
        this.state = {
            items: [0,1,2,3,4,5,6,7,8],
            history: props.history,
            showType: props.showType,
            isOver: false,
            prescriptionID: null !== props.prescriptionID ? props.prescriptionID :  0,
            programView: props.programView ? props.programView : 'view1',
            exerciseName: props.exerciseName,
            exerciseID: props.exerciseID,
            programID: props.programID,
            measureType: props.measureType,
            onLoaded: props.onLoaded,
            playMode:props.playMode,
            display: 'none',
        }
    }

    componentDidMount() {
        this.container = ReactDOM.findDOMNode(this)
        this.clientRect = this.container.getClientRects()[0]
        this.audioRef.current.loadEffect('memoryEgg')
        this.cogBoxRef.current.init(this.audioRef)

       
        
        if('2' === this.state.showType) {
            this.useReverse = false
        }

        // if(AuthProvider.isProfileSelectorView()){
        //   this.useReverse = false
        // }
      
        if('easy' === this.state.playMode){
          this.useReverse = false
        }
  

        this.levelDialogRef.current.open(this.state.showType, this.state.programView)
        
        if(this.state.onLoaded){
            this.state.onLoaded()
        }
    }
    
    getHzType(){
        return this.state.programID
      
      /*
        let ext = ''
        
        const num = (this.state.programView || '').replace('view', '')
        if(2 < Number(num)){
            ext = `_${num}`
        }
       
        return `memoryTest${this.state.showType}${ext}`
       */
    }
    
    async startExercise(useReverse, delay, limitTime, view, answerCount) {
        this.setState({
            ...this.state,
            display: 'block'
        })
        
        this.useReverse = useReverse
        this.delay = delay
        this.limitTime = limitTime
        this.view = view
        this.maxStep = answerCount
        
        
        // 매 시작시 Random shuffle
        if(ViewChar[this.view].sortCogItem){
            ViewChar[this.view].data.sort(() => Math.random() - 0.5);    
        }

        if(undefined !== ViewChar[this.view].useReverse){
            this.useReverse = ViewChar[this.view].useReverse
        }
        
        this.cogNavigationRef.current.setMaxStep(this.maxStep)
        this.timeCountRef.current.startWithCountdown(this.limitTime, () => {
            this.onCompleteWithDialog()
        })
        
        this.cogItemsRef.current.setView(view)
        this.cogBoxRef.current.next(this.currentStep, this.delay, this.view)
        
        
        this.logID = await MeasureService.newMeasureLog({
            prescriptionID: this.state.prescriptionID,
            hzType: this.getHzType(),
            measureTime: limitTime
        })
        
    }


    onPlayEnded(randomIndex) {
        this.randomIndex = randomIndex
        this.cogItemsRef.current.setSelectable(this.currentStep)
        
        // setTimeout(() => {
        //     this.onSelectEnded(this.randomIndex, 'forward')
        // }, 30 * 1000)
    }

    onSelectEnded(selectedItems, direction) {
        // if(AuthProvider.isProfileSelectorView()){
        //   selectedItems = MemoryTestService.makAnswerNoneSequence(selectedItems, this.randomIndex)
        // }
      
        if('easy' === this.state.playMode){
        //  selectedItems = MemoryTestService.makAnswerNoneSequence(selectedItems, this.randomIndex)
        }
        
        const isCorrect = this.isCorrect(selectedItems, direction)
        
        if(this.useReverse && direction === 'forward' && isCorrect){
            this.cogBoxRef.current.reverse()
            this.cogItemsRef.current.reverse()
            return false
        }
        
        // 결과페이지에 넣기 위해서 문제 정보와 답변을 저장
        this.gameResults.push({
            // randomIndex: this.useReverse ? this.randomIndex.reverse() : this.randomIndex,
            randomIndex: this.randomIndex,
            selectedIndex: this.useReverse && isCorrect ? selectedItems.reverse() : selectedItems,
            isCorrect
        })
        
        this.currentStep++
        this.cogNavigationRef.current.next(this.currentStep, isCorrect)
        
        if(this.maxStep < this.currentStep){
            this.onCompleteWithDialog()
            return false
        }
        
        setTimeout(() => {
            this.cogBoxRef.current.next(this.currentStep, this.delay)
        }, 1000)
    }

    /**
   * 스테이지 전체의 맞음 / 틀림 
   * @param selectedItems
   * @param direction
   * @returns {boolean}
   */
    isCorrect(selectedItems, direction){
        //items 에 null 제거
        let items = JSON.parse(JSON.stringify(selectedItems))
        if('reverse' === direction){
            items = items.reverse()
        }
        
        // if(AuthProvider.isProfileSelectorView()){
        //   const correctItems = items.filter(val => this.randomIndex.includes(val))
        //   return correctItems.length === items.length  
        // } else {
        if('easy' === this.state.playMode){
            const correctItems = _.uniq(items).filter(val => this.randomIndex.includes(val))
            return correctItems.length === items.length  
        } else {
            for(let i = 0; i < items.length; i++){
              if(items[i] !== this.randomIndex[i]){
                  return false
              }
            }
            return true
        }
    }

    onReset(){
        this.state.history.go(0)
    }
    
    onCompleteWithDialog(){
        this.timeCountRef.current.clearInterval()
        this.audioRef.current.effect('stop')
        
        this.setState({
            ...this.state,
            isOver: true,
        })

        this.onShowResult().then()    
    }
    getViewName() {
        switch(this.state.programView){
            case 'view1':
               return '숫자'
               return '문자'
            case 'view3':
               return '그림'
            case 'view4':
               return '기호'
            default:
        }
        
    }
    async onShowResult(){
        const score = {
            time: this.timeCountRef.current.getTimePassedText(this.limitTime),
            timeCount: this.timeCountRef.current.getTimePassed(this.limitTime),
            correctCount: this.cogNavigationRef.current.getCorrectCount(),
            correctLastMemory : this.cogNavigationRef.current.getCorrectLastMemory(),
            // count: this.cogItemsRef.current.getItem().length,
            count: this.maxStep,
            view: this.view,
            scoreText: `${this.cogNavigationRef.current.getScore()}점`,
        }

        score.measureType = localStorage.getItem('measure-test-type')
        score.viewText = this.getViewName()
        score.playMode = this.state.playMode
      
        this.completeDialogRef.current.open(score, this.gameResults, ViewChar[this.view])    
      
        score.problems = this.gameResults;
        await ExerciseService.setExerciseResult({
            measureHzType: this.getHzType(),
            measureLogID: this.logID,
            bestWave: 0,
            resultRate: score.correctCount,
            timeCount: score.timeCount,
            isEnd: 'yes',
            resultVar: JSON.stringify(score),
            measureType: localStorage.getItem('measure-test-type')
        })
    }
    
    onStop(){
        this.timeCountRef.current.clearInterval()
        this.cogBoxRef.current.stop()
        this.onShowResult().then()
    }
    
    render() {

        const renderView = ViewChar[this.view]
        const width = renderView && renderView.width ? renderView.width : 240
        const height = renderView && renderView.height ? renderView.height : 240
        const spacing = renderView && renderView.spacing ? renderView.spacing : 0 
        
        let cogItemStyle = {zIndex: 20, width: '100%', bottom: 40}
        if(renderView && renderView.cogItemStyle) cogItemStyle = renderView.cogItemStyle
        
        let cogBoxStyle = {width:'100%', zIndex: 20}
        if(renderView && renderView.cogBoxStyle) cogBoxStyle = renderView.cogBoxStyle
        
        return (
            <Box id={'stage'} style={{height: '100%', display: this.state.display}}>
                <Box
                    position={'absolute'}
                    display="flex"
                    justifyContent="center"
                    zIndex={99}
                    width={'100%'}
                >
                    <CogNavigator ref={this.cogNavigationRef} maxStep={this.maxCount}/>
                </Box>
                
                <Grid container  style={{ position: 'relative', height: '100%'}} >
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            position="absolute"
                            style={cogBoxStyle}>
                                <CogBox width={width} height={height}
                                        audioRef={this.audioRef}
                                        ref={this.cogBoxRef}
                                        view={this.state.programView}
                                        playMode={this.state.playMode}
                                        onPlayEnded={this.onPlayEnded.bind(this)}
                                />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            position="absolute"
                            style={cogItemStyle}>
                            <Box>
                                <Box mb={1} ml={1}>
                                    <Grid container spacing={spacing}>
                                        <Grid item xs={6}>
                                            <TimeCount ref={this.timeCountRef} variant={"h5"} textColor={'#999'}/>
                                        </Grid>
                                        {/*<Grid item xs={6}>*/}
                                        {/*    {this.state.isOver &&*/}
                                        {/*    <Box display={'flex'} justifyContent={'flex-end'}>*/}
                                        {/*        <Button variant={'contained'}*/}
                                        {/*                color={'primary'}*/}
                                        {/*                onClick={this.onShowResult.bind(this)}*/}
                                        {/*        >결과보기</Button>*/}
                                        {/*    </Box>*/}
                                        {/*    }*/}
                                        {/*</Grid>*/}
                                    </Grid>
                                    
                                </Box>
                                <CogItems ref={this.cogItemsRef}
                                          view={this.state.programView}
                                          onSelect={(selectedItems) => {
                                              this.cogBoxRef.current.update(selectedItems)
                                          }}
                                          onSelectEnded={this.onSelectEnded.bind(this)}
                                />    
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <AudioPlayer ref={ this.audioRef } mode={'balance'}/>
                <LevelDialog ref={this.levelDialogRef} 
                             onStart={this.startExercise.bind(this)}  
                             history={this.state.history}
                             exerciseID={this.state.exerciseID}
                             exerciseName={this.state.exerciseName}
                             playMode={this.state.playMode}
                             measureType={this.state.measureType}/>
                <TimerDialog 
                    ref={this.timerDialogRef} 
                    timeSec={3}
                    title={`기억력 프로그램 (${this.getViewName()})`}         
                    onEnd={() => {
                        const delay = 1000
                        const limitTime = 480
                        this.startExercise(this.useReverse, delay, limitTime, this.state.programView).then()
                    }}/>
                <CompleteDialog
                    ref={this.completeDialogRef} 
                    history={this.state.history}
                    showType={this.state.showType}
                    onReset={this.onReset.bind(this)}
                    exerciseName={this.state.exerciseName}
                    exerciseID={this.state.exerciseID}
                    measureType={this.state.measureType}
                />
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    position="absolute"
                    right={10}
                    top={10}
                    zIndex={99}>
                    <Grid container justify="center" spacing={1}>
                        <Grid item>
                            <Button
                                style={{fontSize: '1.2rem', height:54, border: '3px solid #829ABC', color: '#829ABC', width: 160, borderRadius: 40, backgroundColor: '#F1F4F9', marginRight: 39,marginTop:25 }}
                                onClick={this.onStop.bind(this)}>
                                종료
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            
        )
    }
}


export class CogItems extends React.Component {

    selectedItems = []
    direction = 'forward'
    view = 'view1'
    
    constructor(props) {
        super();
        this.state = {
            items: [0,1,2,3,4,5,6,7,8,9],
            selectable : false,
            onSelectEnded: props.onSelectEnded,
            onSelect: props.onSelect,
        }
        this.view = props.view
    }
    
    getItem(){
        return this.state.items
    }
    
    setView(view){
        this.view = view
        this.setState({
            ...this.state
        })
    }

    reverse(){
        this.reset()
        this.direction = 'reverse'
        
        this.setState({
            ...this.state,
            selectable: true,
        })
    }
    
    setSelectable(step) {
        this.direction = 'forward'
        this.setState({
            ...this.state,
            selectable: true,
            limit: step
        })
    }

    clickItem(index){
        if(!this.state.selectable) {
            return false
        }
        
        this.selectedItems.push(index)
        if(this.state.onSelect){
            this.state.onSelect(this.selectedItems, this.direction)
        }

        if(this.state.limit <= this.selectedItems.length){
            this.setState({
                ...this.state,
                selectable: false,
            }, () => {
                if(this.state.onSelectEnded){
                    this.state.onSelectEnded(this.selectedItems, this.direction)
                }
                this.reset()
            })
            
            return false
        }
    }

    reset(){
        this.selectedItems = []
    }

    render() {
        const renderView = ViewChar[this.view]
        const renderViewChar = renderView.data
        
        const width = renderView.width ? renderView.width : 175
        const height = renderView.height ? renderView.height : 175
        
        return (
            <Box style={{
                opacity: this.state.selectable ? 1: 0.2,
                transitionDuration: '0.3s'
            }}>
                <Grid container spacing={1} justify={"center"} style={{height:'100%'}}>
                    {this.state.items.map((pos, index) => {
                        // const image = require(`@assets/exercise/memoryTest/A${pos}.png`)
                        return (
                            <Grid item key={index}>
                                <Box onClick={() => {this.clickItem(index)}}
                                     style={{
                                         width,
                                         height,
                                         borderRadius: 10,
                                         cursor: 'pointer',
                                         backgroundSize: 'contain',
                                         backgroundRepeat: 'no-repeat',
                                         // backgroundImage: `url(${image})`,
                                         backgroundPosition: 'center',
                                         color : this.state.selectable ? 'black' : '#999', 
                                         position: "relative",
                                         backgroundColor: this.state.selectable ? renderView.cogItemBackground : '#efefef'}}>

                                    {(AuthProvider.isSuperAdmin() || AuthProvider.isShowAnswerMember())&&
                                      <Box position={'absolute'} left={3} top={3}>
                                        <div style={{
                                          borderRadius: '50%',
                                          width: 34, height: 34,
                                          lineHeight: `34px`,
                                          textAlign: 'center', background:'#efefef',
                                          fontWeight: 'bold'
                                        }}>{index + 1}</div>
                                      </Box>
                                    }
                                  
                                    {'text' === renderView.type &&
                                    <Typography
                                        variant={"h2"}
                                        style={{
                                            textAlign: 'center',
                                            lineHeight: `${height}px`,
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {renderViewChar[pos]}
                                    </Typography>
                                    }

                                    {'image' === renderView.type &&
                                    <img src={renderViewChar[pos]} alt={pos} style={{
                                        width: '100%',
                                        height: '100%',
                                        paddingTop:  !!ViewChar[this.view].paddingTop ? ViewChar[this.view].paddingTop : '0',
                                    }} />
                                    }

                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        )
    }
}

export class CogNavigator extends React.Component {
    constructor(props) {
        super();
        
        this.state = {
            stepIndex: 0,
            corrects:[],
            maxStep: props.maxStep
        }
    }
    
    next(step, isCorrect){
        const corrects = this.state.corrects
        corrects.push(isCorrect)
        
        this.setState({
            stepIndex: step - 1,
            corrects
        })
    }
    
    setMaxStep(maxStep){
        this.setState({
            ...this.state,
            maxStep
        })
    }
    
    getCorrectCount() {
        let correctCount = this.state.corrects.filter( value => true === value).length
        // if(0 < correctCount) correctCount = correctCount + 1
        
        return correctCount
    }
    
    // 최대기억력 
    getCorrectLastMemory(){
      let maxMemoryStage = 0
      this.state.corrects.map((value, index) => {
        //시작이 2개이기 때문에 0번쨰는 최고 기억 2 
        if(true === value) maxMemoryStage = index + 2
      })
      
      return maxMemoryStage
    }
    
    getPercent() {
      const filteredCorrect = this.state.corrects.filter( value => true === value)
      return filteredCorrect.length > 0 ? Math.floor(filteredCorrect.length / this.state.maxStep * 100) : 0
    }
    
    getScore() {
      const filteredCorrect = this.state.corrects.filter( value => true === value)
      
      // const stepScore = 9 === this.state.maxStep ? 10 : 25 ;
      // const defaultScore = 9 === this.state.maxStep ? 10 : 0
      const defaultScore = 0 ;
      const stepScore = 10;
      return filteredCorrect.length > 0 ? filteredCorrect.length * stepScore + defaultScore : 0
    }
    
    render() {
        const percent = this.getPercent()
        return (
            <Box>
                <Box mt={3} />
                <Grid container spacing={1} justify={"center"}>
                    {new Array(this.state.maxStep).fill(0).map((_, index) => {
                        let backgroundColor = 'rgba(255,255,255)'
                        let textColor = ''
                        
                        let borderColor = '#B6B6B6';
                        if(index === this.state.stepIndex){
                            borderColor = '#66bb6a'
                            textColor = '#66bb6a'
                        }

                        const correct = this.state.corrects[index]
                        if(null != correct ){
                            if(correct){
                                backgroundColor = '#66bb6a'
                                borderColor = '#66bb6a';
                                textColor = 'white'
                            } else {
                                backgroundColor = '#F8807A'
                                borderColor = '#F8807A';
                                textColor = 'white'
                            }
                        }
                        
                        return (
                            <Grid item key={index}>
                                
                                <Box style={{
                                    width: 86,
                                    height: 86,
                                    color: textColor,
                                    borderRadius: '50%',
                                    border: 4,
                                    borderStyle: 'solid',
                                    borderColor,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundColor}}>
                                    <Typography
                                        style={{
                                            marginTop:-4,
                                            fontWeight: 'bold',
                                            fontSize: '2rem',
                                            textAlign: 'center',
                                            lineHeight: `86px`
                                        }}
                                    >
                                        {index + 1}
                                    </Typography>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
                {/*<Box style={{textAlign: 'center'}} mt={1}>*/}
                {/*    <Grid container>*/}
                {/*        <Grid item xs={4}>*/}
                {/*            <Typography style={{color: '#333', textAlign: 'left', paddingLeft: 20}}>*/}
                {/*                {this.state.stepIndex + 1}단계*/}
                {/*            </Typography>*/}
                {/*        </Grid>*/}
                {/*        <Grid item xs={8}> */}
                {/*            <Typography style={{color: '#333', textAlign: 'right'}}>*/}
                {/*                성공 : { 0 < percent ? ` ${percent}% ` : '0%' }*/}
                {/*            </Typography>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*</Box>*/}
            </Box>
        )
    }
}

export class CogBox extends React.Component {
    audioRef
    direction = 'forward'
    delay = 1000
    view = 'view1'

    timer
    constructor(props) {
        super();

        const randomIndex = []
        randomIndex.push( this.makeRandomIndex(randomIndex) )
        // randomIndex.push( this.makeRandomIndex(randomIndex) )

        this.view = props.view
        this.state = {
            blocks: new Array(randomIndex.length).fill(0),
            randomIndex,
            selectedItems: [],
            width: props.width,
            height: props.height ,
            currentShowIndex: -1,
            playEnded: props.onPlayEnded,
            playMode: props.playMode,
            renderType: "none-sequence"  // 정답 순서 상관 없이 처리할 떄 , sequence 정답 순서대로
        }
    }
    
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.state.width !== nextProps.width){
            this.setState({
                ...this.state,
                width: nextProps.width,
                height: nextProps.height
            })
        }
        return true
    }

    init(audioRef){
        this.audioRef = audioRef
    }

    update(selectedItems){
        // if(AuthProvider.isProfileSelectorView()){
        //   selectedItems = MemoryTestService.makAnswerNoneSequence(selectedItems, this.state.randomIndex)
        // }

        if('easy' === this.state.playMode){
          selectedItems = MemoryTestService.makAnswerNoneSequence(selectedItems, this.state.randomIndex)
        }
      
        this.setState({
            ...this.state,
            selectedItems
        })
    }

    reverse(){
        this.direction = 'reverse'
        setTimeout(() => {
            this.setState({
                ...this.state,
                selectedItems: [],
                currentShowIndex: -1
            })
        }, 500)
    }

    makeRandomIndex(randomIndex){
        const shuffled = [0,1,2,3,4,5,6,7,8,9]
            .filter((value) => {
                return !(-1 < randomIndex.indexOf(value))
            })
            .map(a => ([Math.random(),a]))
            .sort((a,b) => a[0]-b[0])
            .map(a => a[1])

        
        return shuffled[0]
    }

    // view1 : 숫자 , view2: 문자 , view3: 그림 
    next(step, delay , view ) {
        this.direction = 'forward'
        if(delay) this.delay = delay
        if(view)  this.view = view
        
        if(1 < step){
            const blocks = this.state.blocks;
            blocks.push(0)

            const randomIndex = []
            for(let i = 0 ; i < step; i++){
                randomIndex.push( this.makeRandomIndex(randomIndex) )
            }
            
            this.setState({
                ...this.state,
                currentShowIndex: -1,
                randomIndex,
                selectedItems: [],
                blocks
            })
        }

        setTimeout(() => {
            try {
                this.audioRef.current.effect('start')
                this.play()    
            } catch {}
        },300)
    }

    stop() {
        if(this.timer){
            clearTimeout(this.timer)
        }
    }
    
    play() {
        if( this.state.currentShowIndex > this.state.blocks.length - 1){
            if(this.state.playEnded){
                this.state.playEnded(this.state.randomIndex)
            }
            return false
        }

        if(this.timer){
            clearTimeout(this.timer)
        }
        
        this.timer = setTimeout(() => {
            const showIndex = this.state.currentShowIndex + 1
            if(showIndex < this.state.blocks.length){
                try {
                    this.audioRef.current.effect('show')    
                } catch {}
                
            }
    
            this.setState({
                ...this.state,
                currentShowIndex: showIndex,
            }, () => {
                this.play()
            })

        }, -1 === this.state.currentShowIndex ? 1000 : this.delay)
    }

    render() {
        const renderView = ViewChar[this.view]
        const renderViewChar = renderView.data
        
        let currentShowIndex = this.state.currentShowIndex
        let selectedItems = JSON.parse(JSON.stringify(this.state.selectedItems))
        if('reverse' === this.direction){
            // 뒤에서 부터 처리되록 index 변경
            const size = this.state.blocks.length
            currentShowIndex = size - currentShowIndex -1
            
            //선택을 위에서 처리되도록 배열 변
            const tempSelectItems = []
            selectedItems = selectedItems.reverse()
            for(let i = size; i > 0; i-- ){
                tempSelectItems[i - 1] = selectedItems.pop()    
            }
            selectedItems = tempSelectItems
        }

        let offset = {
            maxWidth: '70%',
            paddingTop: this.state.blocks.length > 5 ? 160 : 260
        }
        if(ViewChar[this.view].cogItemOffset){
            const {cogItemOffset, cogItemOverOffset} = ViewChar[this.view]
            offset = this.state.blocks.length > 4 
                ? cogItemOverOffset
                : cogItemOffset
        }
        let marginTop = 0
        if(ViewChar[this.view].cogItemMarginTop){
            marginTop = ViewChar[this.view].cogItemMarginTop
        }
        return (
            <Box style={offset}>
                <Box display={'flex'}>
                    <Grid container>
                        <Grid item xs={6} style={{textAlign: 'left', paddingLeft: 20}}>
                            {'forward' === this.direction  &&
                            <SVG name={'rightArrow'} width={'80px'} height={'80px'} color={'#66BB6A'} style={{fontSize: '1.2rem', fontWeight: 'bold'}}/>
                            }
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'right', paddingRight: 20}} >
                            {'reverse' === this.direction  &&
                            <SVG name={'leftArrow'} width={'80px'} height={'80px'} color={'red'}/>
                            }
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={2} justify={"center"}>
                    {this.state.blocks.map((pos, index) => {
                        let isShow = false
                        let backgroundColor = 'rgba(255,255,255)'
                        let textColor = ''
                        let grayscale = '0%';
                        
                        if(currentShowIndex === index){
                            isShow = true
                            backgroundColor = renderView.cogBoxBackground
                        }
                        const selectedValue = selectedItems[index]
                        if(null != selectedValue){
                            isShow = true

                            // if('easy' === this.state.playMode && this.state.randomIndex.includes(selectedValue)){
                            //     backgroundColor = renderView.cogBoxBackground
                            //     textColor = 'white'
                            // } else 
                            if(selectedValue === this.state.randomIndex[index]){
                                backgroundColor = renderView.cogBoxBackground
                                textColor = 'white'
                            } else {
                                backgroundColor = '#efefef'
                                textColor = '#999'
                                grayscale = '100%'
                            }
                        }
                        return (
                            <Grid item key={index}>
                                <Box style={{
                                    position: 'relative',
                                    width: this.state.width,
                                    height: this.state.height,
                                    color: textColor,
                                    borderRadius: !!ViewChar[this.view].borderRadius ? ViewChar[this.view].borderRadius : '50%',
                                    border: 6,
                                    borderStyle: 'solid',
                                    borderColor: '#CFCFCF',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundColor}}>

                                    {(AuthProvider.isSuperAdmin() || AuthProvider.isShowAnswerMember())&&
                                    <Box position={'absolute'} left={-20} top={-20}>
                                        <div style={{
                                          borderRadius: '50%', 
                                          width: 34, height: 34,
                                          lineHeight: '34px',
                                          textAlign: 'center', background:'#efefef', 
                                          fontWeight: 'bold'
                                        }}>{this.state.randomIndex[index] + 1}</div>
                                    </Box>
                                    }

                                    {'text' === renderView.type &&
                                    <Typography
                                        variant={"h2"}
                                        style={{
                                            display: isShow ? 'block' : 'none',
                                            transitionDuration: '0.5s',
                                            textAlign: 'center',
                                            lineHeight: `${this.state.height- 20}px`,
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {renderViewChar[this.state.randomIndex[index]]}
                                    </Typography>
                                    }

                                    {'image' === renderView.type &&
                                        <img style={{
                                                display: isShow ? 'block' : 'none',
                                                width: '100%',
                                                maxHeight: '100%',
                                                marginTop,
                                                borderRadius: !!ViewChar[this.view].borderRadius ? ViewChar[this.view].borderRadius : '50%',
                                                filter: `grayscale(${grayscale})`,
                                                paddingTop:  !!ViewChar[this.view].paddingTop ? ViewChar[this.view].paddingTop : '0',
                                            }}
                                             src={renderViewChar[this.state.randomIndex[index]]} 
                                             alt={index}  />
                                        
                                    }
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        )
    }
}


export default MemoryTest
