import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import ComputeFeedback from '@user-pages/exercise/common/ComputeFeedback'
import ComputeFeedbackMeditation from "./ComputeFeedbackMeditation";
import MeasureService from '@user-service/MeasureService'

export default class ComputeFeedbackDialog extends React.Component {
    constructor(props) {
        super();
        this.state = {
            open: false,
            hzType: props.hzType,
            measureType: props.measureType,
            useMeasureCode: props.useMeasureCode,
            time: props.time,
        }
    }

    async open(time) {
        const res = await MeasureService.getComputeHzLastWithCode();
        if(!res){
            alert('설정값을 가져올 수 없습니다.\n권한이 있는지 확인하세요');
            return;
        }

        this.setState({
            ...this.state,
            open: true,
            time
        })
    }

    openWithHzType(hzType){
        this.setState({
            ...this.state,
            open: true,
            hzType
        })
    }

    close() {
        this.setState({
            ...this.state,
            open: false
        })
    }

    render() {
        
        const isMeditation = (
            'meditation' === this.state.hzType ||
            'meditationHeal' === this.state.hzType ||
            'intoSleep' === this.state.hzType ||    
            'meditationTest' === this.state.hzType || 
            'measureBandTest' === this.state.hzType
        )
        return (
            <Dialog
                open={this.state.open}
                aria-labelledby="responsive-dialog-title"
                maxWidth={isMeditation ? 'lg' : 'md'}
                fullWidth
            >
                <DialogContent>
                    {isMeditation ?
                        <ComputeFeedbackMeditation
                            hzType={this.state.hzType}
                            measureType={this.state.measureType}
                            closeDialog={this.close.bind(this)}
                            useMeasureCode={this.state.useMeasureCode}
                            updateMeasureCode={() => {
                            }}
                            updateBandHz={() => {
                            }}
                            time={this.state.time}
                        />
                        :
                        <ComputeFeedback
                            hzType={this.state.hzType}
                            measureType={this.state.measureType}
                            closeDialog={this.close.bind(this)}
                            useMeasureCode={this.state.useMeasureCode}
                            updateMeasureCode={() => {
                            }}
                            updateBandHz={() => {
                            }}
                            time={this.state.time}
                        />
                    }
                </DialogContent>
            </Dialog>
        );
    }
}
