import {Box, Card, CardActionArea, CardContent, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, {useCallback, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import HeatMap from "react-heatmap-grid";
import ColorHelper from '@helper/ColorHelper';
import AuthProvider from "@user-provider/AuthProvider";
import PrescriptionService from '@user-service/PrescriptionService';
import DateHelper from "../../../helper/DateHelper";

export default function BalanceBar(props) {
    const classes = useStyles();
    const history = useHistory()

    const { id, brainActiveDate } = props.measureResult
    const ableClick = true !== props.clickDisabled
    
    const [brainActive, setBrainActive] = useState([])
    const measureCode = ['MA', 'MM' , 'MB']
    const measureColor = ['blue', 'green', 'red']

    const fetch = useCallback(async (prescriptionId) => {
        const data = await PrescriptionService.getBrainActive(prescriptionId)
        const newData = [];
        measureCode.map((code) => {
            newData.push(data[code].active)
            return code
        })
        setBrainActive(newData)
    },[])

    useEffect(() =>  {
        fetch(props.measureResult.id).then()
    }, [props.measureResult])
    

    const handler = () => {
        if(AuthProvider.canViewMeasureDetail() && ableClick) {
            history.push(`/measureDetail/${id}/brainActive`);
        }
    }
    
    return (
        <Box className={classes.root}>
            <Card className={classes.card} onClick={handler}>
                <CardActionArea>
                    <CardContent>
                        <Typography variant={'subtitle1'} className={classes.title}>
                            뇌기능 의식패턴
                            {brainActiveDate &&
                             <span>({DateHelper.format(brainActiveDate, 'YYYY.MM.DD HH:mm')})</span>
                            }
                        </Typography>
                        <Grid container spacing={1}>
                            {brainActive.map((data, index) => {
                                let xlabel = ['8hz','9hz','10hz','11hz']
                                let ylabel = ['4hz','5hz','6hz','7hz']
                                return (
                                    <Grid item key={`ba${index}`} xs={4}>
                                        <HeatMap
                                            xLabels={xlabel}
                                            yLabels={ylabel}
                                            xLabelsLocation={"top"}
                                            yLabelWidth={25}
                                            xLabelWidth={50}
                                            height={40}
                                            yLabelTextAlign={'left'}
                                            data={data}
                                            squares
                                            cellStyle={(background, value, min, max) => {
                                                return {
                                                    background: ColorHelper.getColorPercent(measureColor[index], max, value),
                                                    color: '#333',
                                                    fontSize: 15,
                                                    width: 40,
                                                    fontWeight: 'bold',
                                                    height: 40,
                                                    lineHeight: '24px',
                                                }
                                            }}
                                            cellRender={ (value) => {
                                                return value
                                            }}
                                            title={(value) => `${value}`}
                                        />
                                        <Box>
                                            <Typography align={'center'}>{measureCode[index]}</Typography>
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    root: {
    },
    title: {
        fontWeight: 'bold',
        paddingLeft: 15,
        marginBottom: 20,

        '& span':{
            fontWeight: 'normal',
            fontSize: 14,
            paddingLeft: 5
        }
    },
    card: {
        border: "none",
        boxShadow: "none",
        padding: 0
    },
}));

