import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ReactAudioPlayer from "react-audio-player";
import {Button, Grid, Box } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import AuthProvider from "../../../provider/AuthProvider";

export default class EndDialog  extends React.Component {
    constructor(props) {
        super();

        let url = props.requestID ? `/measureCurrent/last` : props.url
        if(AuthProvider.isProfileSelectorView()){
          url = `/measureSelect`
        }
      
        this.state = {
            open: false,
            onStart: props.onStart,
            history: props.history,
            url: url,
        }
    }

    open() {
        this.setState({
            ...this.state,
            open: true,
        })
    }

    close() {
        this.setState({
            ...this.state,
            open: false
        })
    }

    start() {
        if(this.state.onStart){
            this.state.onStart(this.state.delay);
        }
        this.close()
    }

    onExit() {
        if(this.state.history){
            this.state.history.replace(this.state.url)
        }
    }
    render() {
        return (
            <Dialog
                disableBackdropClick
                open={this.state.open}
                aria-labelledby="responsive-dialog-title"
                className={'innerViewport'}
            >
                <DialogTitle className="common-dialog-title" >뇌기능 의식 패턴검사</DialogTitle>
                <DialogContent className={'common-dialog-content'}>
                    뇌기능 의식 패턴검사 측정이 완료되었습니다.
                    <br/>
                    <br/>
                    <ReactAudioPlayer
                        autoPlay
                        src={require('@audio/01-14.mp3')}
                        controls
                        onEnded={()=>{
                           this.onExit()
                        }}
                    />
                </DialogContent>
                <DialogActions className={'common-dialog-action'}>
                    <Button
                        autoFocus
                        fullWidth={true}
                        onClick={()=>{
                            this.onExit()
                        }}>
                        결과 확인
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
