import React, {useState} from "react";
import {Box, Button, Grid} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";

import PrescriptionService from '@user-service/PrescriptionService';

export default function MeasureMemo(props){
    const [message , setMessage] = useState('')
    const [measureFFTResult, setMeasureFFTResult] = useState(props.fftResult ? props.fftResult : 'good');
    const [measureBalanceResult, setMeasureBalanceResult] = useState(props.balanceResult ? props.balanceResult : 'good');

    const updateMessage = async () => {
        await PrescriptionService.updateMessage(props.id,message)
        await PrescriptionService.updateMeasureResult(props.id, measureFFTResult, measureBalanceResult)
        alert('수정되었습니다')
    }
    const seteMeasureUpdate = (type, value) => {
        if('fft' === type){
            setMeasureFFTResult(value)
        } else {
            setMeasureBalanceResult(value)
        }
    }
    return (
        <Box mb={10} mt={3}>
            <Grid container>
                <Grid item>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">EEG 측정결과</FormLabel>
                        <RadioGroup row name="customized-radios">
                            <FormControlLabel
                                checked={'good' === measureFFTResult}
                                value="good" control={<Radio />} label="정상" onClick={()=>{seteMeasureUpdate('fft','good')}} labelPlacement="end"/>
                            <FormControlLabel
                                checked={'bad' === measureFFTResult}
                                value="bad" control={<Radio />} label="비정상" onClick={()=>{seteMeasureUpdate('fft','bad')}} labelPlacement="end"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item style={{marginLeft:10}}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">균형상태 측정결과</FormLabel>
                        <RadioGroup row name="customized-radios">
                            <FormControlLabel
                                checked={'good' === measureBalanceResult}
                                value="good" control={<Radio />} label="정상" onClick={()=>{seteMeasureUpdate('balance','good')}} labelPlacement="end"/>
                            <FormControlLabel
                                checked={'bad' === measureBalanceResult}
                                value="bad" control={<Radio />} label="비정상" onClick={()=>{seteMeasureUpdate('balance','bad')}} labelPlacement="end"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>

            </Grid>
            <TextField
                id="filled-multiline-static"
                label="메모입력"
                multiline
                rows={10}
                fullWidth={true}
                defaultValue={props.prescription.message}
                variant="outlined"
                onChange={(event) => {
                    setMessage(event.target.value);
                }}
            />
            <Box mt={1}>
                <Button variant="outlined" onClick={updateMessage.bind(this)}>
                    상태 저장
                </Button>
            </Box>
        </Box>
    )
}