import '@user-style/hexgrid.css';

import React, { useEffect, useState } from 'react';
import {Grid, Box, Tabs, Tab, IconButton} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PrescriptionSelect from '@user-components/PrescriptionSelect';
import PrescriptionService from '@user-service/PrescriptionService';

import BrainFFT from "./BrainFFT";
import ViewBrainHexMap from "./VIewBrainHexMap";
import BrainBalanceMap from "./BrainBalanceMap";
import MeasureState from "./MeasureState";
import BrainBalanceBand from "./BrainBalanceBand";
import BrainActive from "./BrainActive"
import MeasureBandTest from "./MeasureBandTest"
import MeasureMemo from "./MeasureMemo"


import {useParams} from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default function MeasureDetail ({history}) {
    const classes = useStyles()
    
    const buttons = [
        {title: 'EEG 분석결과'},
        {title: '뇌기능 균형상태'},
        {title: '뇌 기능별 상태'},
        {title: '좌우뇌 균형상태'},
        {title: '뇌기능 의식패턴'},
        {title: '항상성 자기조절'}
    ]

    let { prescriptionID, type } = useParams()
    const [prescription, setPrescription] = useState(null)

    let defaultShowType


    switch(type) {
        case 'fft':
            defaultShowType = 0
            break
        case 'balance':
            defaultShowType = 1
            break
        case 'balanceBar':
            defaultShowType = 2
            break
        case 'balanceBrain':
            defaultShowType = 3
            break
        case 'brainActive':
            defaultShowType = 4
            break
        case 'measure-band-test':
            defaultShowType = 5
            break
        default:
            defaultShowType = 0
    }

    const [showType, setShowType] = useState(defaultShowType)


    useEffect(() => {
        PrescriptionService.getPrescriptionById(prescriptionID, (result) => {
            setPrescription(result)
        })

        return (() => {
            setPrescription(null)
        })
    }, [prescriptionID]);

    if (!prescription) return <></>

    return (
        <div id="cont-wrap">
            <div className="sv">
                <Grid container>
                    <Grid item xs={1}>
                        <IconButton
                            className={classes.back}
                            aria-label="delete"
                            onClick={()=>{
                                history.push(`/measureCurrent/${prescriptionID}`)
                            }}
                        >
                            <ArrowBackIcon style={{fontSize: 50}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <div>
                            <h3>뇌기능 상세 - {prescriptionID} 회차</h3>
                            <span>EEG, 균형상태, 의식패턴 측정된 상세 결과를 확인합니다.</span>
                            <i>Brain Health</i>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div className="measure-wrap" style={{marginTop: 60}}>
                <Grid container spacing={1} className={'tabs'} style={{ maxWidth: 1280, margin: 'auto'}}>
                    <Grid item xs={3}>
                        <Box display="flex" justifyContent="flex-end">
                            <PrescriptionSelect
                                prescriptionID={prescriptionID}
                                url="/measureDetail/:prescriptionID/{0}"
                                params={[showType]}/>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <div >
                            <Tabs
                                value={showType}
                                onChange={(event, newValue) => {
                                    setShowType(newValue)
                                }}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                {buttons.map((button,index) => {
                                    return (
                                        <Tab key={index}
                                             className={'tab'}
                                             label={<span>{button.title}</span>} />
                                    )
                                })}
                            </Tabs>
                        </div>
                    </Grid>
                </Grid>

                {/*분석 결과 FFT*/}
                {0 === showType &&
                <Box>
                    <BrainFFT prescription={prescription}/>
                </Box>
                }

                {/* 뇌기능 균형상태 */ }
                {1 === showType &&
                <Box mb={10} mt={5}>
                    <ViewBrainHexMap prescriptionID={prescriptionID} prescription={prescription}/>
                    <MeasureState { ...prescription } />
                </Box>

                }
            </div>

            {/* 브레인맵 */ }
            {2 === showType &&
            <Box width={1200} m={'auto'} mt={5} mb={10}>
                <BrainBalanceBand bandRangeData={prescription.bandRangeData}/>
            </Box>
            }

            {/* 밴드분석 */ }
            {3 === showType &&
            <Box width={1200} m={'auto'} mt={20} mb={20}>
                <BrainBalanceMap 
                    bandRangeData={prescription.bandRangeData}
                />
            </Box>
            }

            {/* 밴드분석 */ }
            {4 === showType &&
            <Box width={1400} m={'auto'}>
                <BrainActive prescriptionID={prescription.id}/>
            </Box>
            }

            {/* 항상성  자기조절 */ }
            {5 === showType &&
            <Box width={1400} m={'auto'}>
                <MeasureBandTest prescriptionID={prescription.id} fftType={prescription.fftType}/>
            </Box>
            }
            
            {/* 메모 */}
            <Box width={1200} m={'auto'} mb={10}>
                <MeasureMemo {...prescription}/>
            </Box>
        </div>
    );
}

const useStyles = makeStyles(() => ({
    back: {
        marginTop: 30,
        '&:hover': {
            backgroundColor: '#053288',
        }
    },
}));



