import React from "react";
import {Box, Grid, Typography} from "@material-ui/core";

export class CogNavigator extends React.Component {
  constructor(props) {
    super();

    this.state = {
      stepIndex: 0,
      corrects:[],
      maxStep: props.maxStep,
      showNavigation: true
    }
  }

  showNavigation() {
    this.setState({
      ...this.state,
      showNavigation: true
    })
  }


  next(stepIndex, isCorrect, showNavigation = true){
    const corrects = this.state.corrects
    corrects.push(isCorrect)

    this.setState({
      stepIndex,
      corrects,
      showNavigation
    })
  }

  setMaxStep(maxStep, showNavigation = true){
    this.setState({
      ...this.state,
      maxStep,
      showNavigation
    })
  }

  getCorrectCount() {
    return this.state.corrects.filter( value => true === value).length
  }

  render() {

    return (
      <Box style={{
        background:'#DFF0FA',
        width: '100%',
        opacity: this.state.showNavigation ? 1 : 0,
        transitionDuration: '0.3s'
      }}>
        <Box mt={3}/>
        <Grid container spacing={1} justify={"center"}>
          {new Array(this.state.maxStep).fill(0).map((_, index) => {
            let backgroundColor = 'rgba(255,255,255)'
            let textColor = ''

            let borderColor = '#B6B6B6';
            if(index === this.state.stepIndex){
              borderColor = '#66bb6a'
              textColor = '#66bb6a'
            }

            const correct = this.state.corrects[index]
            if(null != correct ){
              if(correct){
                backgroundColor = '#66bb6a'
                borderColor = '#66bb6a';
                textColor = 'white'
              } else {
                backgroundColor = '#F8807A'
                borderColor = '#F8807A';
                textColor = 'white'
              }
            }

            return (
              <Grid item key={index}>
                <Box style={{
                  width: 86,
                  height: 86,
                  color: textColor,
                  borderRadius: '50%',
                  border: 4,
                  borderStyle: 'solid',
                  borderColor,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundColor}}>
                  <Typography
                    style={{
                      marginTop:-4,
                      fontWeight: 'bold',
                      fontSize: '2rem',
                      textAlign: 'center',
                      lineHeight: `86px`
                    }}
                  >
                    {index + 1}
                  </Typography>
                </Box>
              </Grid>
            )
          })}
        </Grid>
        <Box mb={3}/>
      </Box>
    )
  }
}
