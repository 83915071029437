import React from 'react';
import {Box, Button} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

export default class LevelDialog extends React.Component {
    constructor(props) {
        super();
        
        this.state = {
            levelMode: 'easy',
            open: false,
            onStart: props.onStart
        }
    }
    
    open() {
        this.setState({
            ...this.state,
            open: true
        })
    }
    
    close() {
        this.setState({
            ...this.state,
            open: false
        })
    }
    
    start() {
        if(this.state.onStart){
            this.state.onStart('hard' === this.state.levelMode);
        }
        this.close()
    }
    
    setLevelMode(levelMode) {
        this.setState({
            ...this.state,
            levelMode
        })
    }
    render() {
        return (
            <Dialog
                disableBackdropClick
                // fullScreen={fullScreen}
                open={this.state.open}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{'난이도 선택'}</DialogTitle>
                <DialogContent>
                    <Box>
                        <FormControl component="fieldset">
                            <RadioGroup name="customized-radios">
                                <FormControlLabel
                                    checked={'easy' === this.state.levelMode}
                                    value="easy" control={<Radio />} label="쉬운 모드 (단방향)" onClick={()=>{this.setLevelMode('easy')}} labelPlacement="end"/>
                                <Box ml={2} color={'#999'}>
                                    무작위로 표시된 계랸을 순서대로 선택합니다. 
                                </Box>
                                <Box mt={2}>
                                    
                                </Box>
                                <FormControlLabel
                                    checked={'hard' === this.state.levelMode}
                                    value="hard" control={<Radio />} label="어려운 모드(양방향)" onClick={()=>{this.setLevelMode('hard')}} labelPlacement="end"/>
                                    <Box ml={2} color={'#999'}>
                                        무작위로 표시된 계략을순서대로 선택하고 반대로 다시한번 선택합니다.
                                    </Box>
                            </RadioGroup>
                        </FormControl>
                        <Box mt={5} mb={3}>
                            <Button fullWidth variant={"outlined"}
                                onClick={this.start.bind(this)}>
                                시작하기
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }
}
