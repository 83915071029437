import React, {useCallback, useEffect, useState} from "react";
import {Box, Button, Grid, Paper} from "@material-ui/core";

import PrescriptionService from '@user-service/PrescriptionService';
import BarChart from '@user-components/BarChart';
import Typography from "@material-ui/core/Typography";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BridgeProvider from "../../../provider/BridgeProvider";

export default function MeasureBandTest(props) {
    
    const [bandData, setBandData] = useState([])
    const [fftImages, setFFTImage] = useState()
    
    // const [prescriptionID] = useState(props.prescriptionID)
    const [measureCode] = useState(['MA', 'MM' , 'MB'])
    const measureText = ['개안', '폐안', '개안']
    
    const [selectedTab, setTab] = useState(0)
    const { zoom, showAll, fontSize, valueType, animationDuration, showTable } = props
    
    const fetch = useCallback(async (prescriptionID) => {
        let data = props.bandData 
        if(!data) {
            data = await PrescriptionService.getMeasureBandTest(prescriptionID)
        }
        
        
        const newData = [];
        measureCode.map((code) => {
            newData.push(data[code])
            return code
        })
        setBandData(newData)
    },[props.prescriptionID, measureCode, props.bandData])
    
    const fetchImages = useCallback( async (prescriptionID) => {
        let images = props.images
        if(!images){
            images = await PrescriptionService.getFFTImage(prescriptionID, 'measureBandTest', measureCode[selectedTab])
        }
        setFFTImage(images)
        
    },[selectedTab, props.prescriptionID])

    useEffect(() =>  {
        fetch(props.prescriptionID).then()
    }, [fetch, props.prescriptionID])

    useEffect(() => {
        fetchImages(props.prescriptionID).then()
    },[selectedTab, props.prescriptionID])
    
    
    const labels = ['δ', 'θ', 'α', 'SMR', 'β', 'βM', 'βh']
    return (
        <Box mt={5}
             mb={10}
             display="flex"
             justifyContent="center"
             alignItems="center"
             style={{ zoom }}
        >
            <Grid container spacing={3} style={{maxWidth: zoom ? undefined :  1280}}>
                {bandData.map((data, index) => {
                    const defaultData = [
                        data ? data.band.ch1 : [],
                        data ? data.band.ch2 : []
                    ]
                    return (
                        <Grid item key={`ba${index}`} xs={4}>
                            <BarChart
                                data={new Array(6).fill(0)}
                                defaultData={defaultData}
                                showTopValue={true}
                                topValueMargin={20}
                                labels={labels}
                                height={200}
                                useColorIndex={true}
                                showYAxes={true}
                                colors={['#60C3F7', '#A9D8AA']}
                                grouped={true}
                                groups={2}
                                fontSize={fontSize}
                                topValueFontSize={fontSize}
                                valueType={valueType}
                                animationDuration={animationDuration}
                            />

                            {showTable &&
                                <Box style={{paddingLeft:'20px', marginTop: 20}}>
                                    <div>
                                        <table className={'border-table'}>
                                            <thead></thead>
                                            <tbody>
                                                <tr>
                                                    {['',...labels.slice(0,3)].map((text,index) => <th key={`th-text-${index}`}>{text}</th>)}
                                                </tr>
                                                <tr>
                                                    <td>L</td>
                                                    {labels.slice(0,3).map((text,index) =>
                                                        <td key={`td-text-${index}`} style={{fontSize: '1.1rem' }}>
                                                            <b>{defaultData ? defaultData[0][index] : ""}</b>
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td>R</td>
                                                    {labels.slice(0,3).map((text,index) =>
                                                        <td key={`td-text-${index}`}  style={{fontSize: '1.1rem' }}>
                                                            <b>{defaultData ? defaultData[1][index] : ""}</b>
                                                        </td>
                                                    )}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <table className={'border-table'}>
                                            <thead></thead>
                                            <tbody>
                                            <tr>
                                                {['',...labels.slice(0,3)].map((text,index) => <th key={`th-text-${index}`}>{text}</th>)}
                                            </tr>
                                            <tr>
                                                <td>L</td>
                                                {labels.slice(3,6).map((text,index) =>
                                                    <td key={`td-text-${index}`} style={{fontSize: '1.1rem' }}>
                                                        <b>{defaultData ? defaultData[0][index] : ""}</b>
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td>R</td>
                                                {labels.slice(3,6).map((text,index) =>
                                                    <td key={`td-text-${index}`}  style={{fontSize: '1.1rem' }}>
                                                        <b>{defaultData ? defaultData[1][index] : ""}</b>
                                                    </td>
                                                )}
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Box>
                            }

                            <Box mt={2} mb={2} display="flex" justifyContent="center">
                                <div>
                                  <div>
                                    <Typography align={'center'} variant={'h6'}>{measureText[index]}</Typography>
                                  </div>
                                  {/*인쇄시  버튼 가림 */}
                                  {!showTable &&
                                  <div>
                                    <Button
                                      size={'small'}
                                      variant={'outlined'}
                                      onClick={() => {
                                        const code = measureCode[index]
                                        try {
                                          BridgeProvider.instance.openPopup(`/measureFFT/${props.prescriptionID}/measure-band-test?measureCode=${code}`)
                                        } catch {
                                          window.open(`/measureFFT/${props.prescriptionID}/measure-band-test?measureCode=${code}`, 'measureFFT', `width=${window.innerWidth}, height=${window.innerHeight}`)
                                        }
      
                                      }}
                                    >
                                      HWF
                                    </Button>
                                  </div>
                                  }
                                </div>
                            </Box>
                        </Grid>
                    )
                })}

                
                <Grid item xs={12}>
                    {!showAll &&
                    <Paper  elevation={0}>
                        <Tabs 
                            value={selectedTab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            centered
                            onChange={(event, newValue) => {
                                  setTab(newValue)
                              }}
                        >
                            <Tab label="MA - 개안"  />
                            <Tab label="MM - 폐안" />
                            <Tab label="MB - 개안" />
                        </Tabs>
                    </Paper>
                    }

                    <ImageView
                      style={{marginTop: 20}}  
                      showAll={showAll} 
                      fftImages={fftImages} 
                      fftType={props.fftType}
                      printMode={props.printMode}
                    />

                    {(fftImages && showAll && 'barchart' !== props.fftType) &&
                      <img src={fftImages.ch1} style={{width:'140%', marginLeft:-150, marginTop:-70}}/>
                    }
                    
                    {(fftImages && showAll && 'barchart' === props.fftType) &&
                      <img src={fftImages.ch1} style={{width:'100%', marginTop: 'yes' === props.printMode ? 140 : 0}}/>
                    }
                </Grid>
            </Grid>
        </Box>
    )
}

const ImageView = (props) => {
  const { showAll , fftImages, fftType, printMode } = props

  if(!fftImages) return <></>
  
 
  
  let styles =  (props && props.prescriptionID >= 10512)
    ? {
      backgroundImage: `url(${fftImages.ch1})`,
      height: fftImages.ch2 ? 550 : 1200,
      backgroundSize: '200%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '-525px -200px ',
    }
    : {
      backgroundImage: `url(${fftImages.ch1})`,
      height: fftImages.ch2 ? 550 : 1200,
      backgroundSize: '140%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '-60px 0 ',
    }
    
    if('barchart' === fftType) {
      styles = {
        backgroundImage: `url(${fftImages.ch1})`,
        height: fftImages.ch2 ? 550 : 1200,
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat'
      }
    }
    
  return (
    <>
      {!showAll &&
        <div>
          {fftImages
            ?
            <React.Fragment>
              <Box mt={3} style={styles}/>
      
              {fftImages.ch2 &&
                <Box mt={3}
                     style={{
                       backgroundImage: `url(${fftImages.ch2})`,
                       height: 550,
                       backgroundSize: '140%',
                       backgroundRepeat: 'no-repeat',
                       backgroundPosition: '-60px 0 ',
                       marginTop: -150
                     }}/>
              }
            </React.Fragment>
            :
            <Box display={'flex'} minHeight={400}>
              <Box m="auto">
                <Typography variant="h6" style={{color:'#999'}}>
                  이미지가 없습니다
                </Typography>
              </Box>
            </Box>
          }
        </div>
      }
    </>
  )
}
