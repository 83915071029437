import React, {useState, useEffect, useRef, useCallback} from 'react';
import {
    Box,
    TextField, 
    Grid, 
    Button,
    Typography
 } from '@material-ui/core';

import MeasureService from '@user-service/MeasureService'

import Moment from "moment-timezone";
import ComputeCode from "./ComputeCode";

import MeasureHelper from '@helper/MeasureHelper'
const { measureTypeToCode, getMeasureTypeRange, getMeasureButtons } = MeasureHelper

const ComputeFeedback = (props) => {
    
    let defaultMeasureCode = 'default' === props.hzType ? 'C' : 'A'
    let title
    let measureButtons = getMeasureButtons(props.hzType)
    
    // let band
    // let stepCount
    
    const [state, setState] = useState({
        band: [],
        stepCount: 1,
        isUpdated: false
    })
    
    switch (props.hzType){
        case 'default' :
            title = '균형상태 측정'
            break
        case 'relax':
            title = '뇌이완'
            break
        case 'breath':
            title = '호흡 융합피드백'
            break
        case 'balloon':
            title = '생명본능 뇌 호흡'
            break
        case 'cross':
            title = '기도 교감 피드백'
            break
        case 'nature':
            title = '뇌&호흡 피드백'
            break
        case 'concentration':
            title = '집중력'
            break
        case 'feedback':
            title = '피드백'
            break
        case 'intoSleep':
            title = '수면속으로'
            break
        case 'natureFeedback':
            title = '오감자연피드백'
            break
        case 'balance':
            title = '좌우뇌 균형'
            defaultMeasureCode = 'D'
            break
        case 'memoryEgg':
            title = '기억력2 & 인지증진 '
            break
        case 'feedbackBalance':
            title = '피드백 - 좌우뇌균형'
            break
        case 'measureBand':
            title = '균형조절'
            break
        case 'meditation':
            title = "치유명상"
            break
        case 'meditationHeal':
            title = "신앙 치유명상"
            break
        default:
            title = ''
    }
    
    const [hzType] = useState(null !== props.hzType ? props.hzType : 'feedback' )
    const [measureCode, setMeasureCode] = useState(defaultMeasureCode)
    const [measureType, setMeasureType] = useState(props.measureType ? props.measureType : 'rest')
    const computeCodeRef = useRef()
    
   
    useEffect(() => {
    },[measureCode, measureType, hzType])
    
    return (
        <Box>
            <Box mt={2} mb={2}>
                <Typography variant={'h5'}>
                    {title} 설정 값
                </Typography>
            </Box>
            
            <Box>
                <Box mb={2}>
                    {'default' !== hzType &&
                    <ComputeCode
                        ref={computeCodeRef}
                        hzType={hzType}
                        measureType={measureType}
                        defaultCode={measureCode}
                        useMeasureCode={props.useMeasureCode}
                        onChange={(code) => {
                            if (props.updateMeasureCode) {
                                props.updateMeasureCode(code)
                            }
                            setMeasureCode(code)
                        }}
                    />
                    }
                </Box>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {measureButtons.map((button, index) => {
                                return (
                                    <Grid item key={index}>
                                        <Button variant={ button.value ===  measureType ? "contained" : "outlined"}
                                                color="primary"
                                                onClick={() => {
                                                    setMeasureType(button.value)
                                                    try {
                                                        computeCodeRef.current.setMeasureType(button.value)    
                                                    } catch (e) {}
                                                }}>
                                            {button.name}
                                        </Button>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <HzMinMax 
                    hzType={hzType} 
                    measureType={measureType} 
                    measureCode={measureCode}
                    time={props.time}
                    onSetBand={(updatedBand, count) => {
                        setState({
                            band: updatedBand,
                            stepCount: count,
                            isUpdated: true
                        })
                    }}
                />
            </Box>
            <Box p={2}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button
                            onClick={async () => {
                                if(!state.isUpdated) return
                                    
                                await MeasureService.updateComputeHzWithCode(
                                    hzType, 
                                    measureTypeToCode(measureType) ,
                                    measureCode, 
                                    state.band,
                                    props.time,
                                    state.stepCount
                                )
                                alert('수정되었습니다.');
                                setState({
                                    ...state,
                                    isUpdated: false
                                })
                            }}
                            variant={"contained"}
                            color="primary" >수정</Button>
                    </Grid>
                    {props.closeDialog &&
                    <Grid item>
                        <Button variant={'outlined'} onClick={() => {
                            props.closeDialog()
                        }}>
                            닫기
                        </Button>
                    </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    )
}

export function HzMinMax(props){
    const { hzType, measureType, measureCode, onSetBand } = props
    const [state, setState] = useState({
        band: null,
        stepCount: 1
    })
    const [time] = useState(props.time)

    let hzValMin, hzValMax, hzOverCut
    const range = getMeasureTypeRange(hzType, measureType, measureCode)

    const fetchComputeHz = useCallback(async () => {
        const res = await MeasureService.getComputeHzLastWithCode(hzType, measureTypeToCode(measureType), measureCode, time)
        const { band, stepCount } = res
        
        let newBand = band
        if(!newBand){
            const baseRange = getMeasureTypeRange(hzType, measureType, measureCode)
            newBand = baseRange.map((hz) => {
                return {
                    hz,
                    min: 0,
                    max: 0,
                    overCut: 0,
                }
            })
        }
        setState({
            stepCount,
            band: newBand
        })
    }, [hzType, measureType, measureCode, time])

    useEffect(() => {
        fetchComputeHz().then()
    },[fetchComputeHz])

    
    const { band, stepCount } = state

    if(!band) return <></>
    
    let keyId, createdAt 
    if(band && band[0]){
        keyId = band[0].keyId
        createdAt = band[0].createdAt
    }
    
    let useMinVal
    let useMaxVal
    let useMaxOverCutVal
    let labelMin, labelMax, explainMin, explainMax
    labelMin = '최소'
    labelMax = '최대'
    useMaxOverCutVal = false
    
    switch (measureCode){

        // case 'A':
        // case 'AE':
        //     // useMinVal = false
        //     // useMaxVal = true
        //     // labelMax = '설정값'
        //     explainMin = 'hz의 최소값 = 1초전 hz 평균 측정값'
        //     explainMax = 'hz의 최대값 = 1초전 hz 평균 측정값 + 설정값'
        //     //
        //     // if('feedback' === hzType ){
        //         useMinVal = true
        //         useMaxVal = true
        //         labelMin = '설정값'
        //         labelMax = '버리는 값'
        //     // }
        //     break
        case 'AB':
            useMinVal = true
            useMaxVal = true
            labelMin = '설정값'
            labelMax = '버리는 값'
            explainMin = 'hz의 최소값 = 1초전 (1-19hz) 평균 측정값'
            explainMax = 'hz의 최대값 = 1초전 (1-19hz) 평균 측정값 + 설정값'
            break
        case 'AA':
        case 'BA':
        case 'CA':
        case 'DA':
        case 'MA':
            useMinVal = true
            useMaxVal = true
            labelMin = '낮은 설정값'
            labelMax = '높은 설정값'
            explainMin = 'hz별 낮은값~ 높은값 사이의 측정'
            explainMax = 'hz별 낮은값~ 높은값 사이의 측정'
            break;
        case 'BB':
            useMinVal = true
            useMaxVal = true
            labelMin = '설정값'
            labelMax = '이상 버리는 값'
            explainMin = 'hz의 최소값 = 1초전 (1-19hz) 평균 측정값 - 설정값'
            explainMax = 'hz의 최대대값 = 1초전 (1-19hz) 평균 측정값'
            break
        case 'CB':
            useMinVal = true
            useMaxVal = true
            labelMin = '설정값'
            labelMax = '이상 버리는 값'
            explainMin = 'hz의 최소값 = 1초전 (1-19hz) 좌/우뇌의 평균값 중 낮은값'
            explainMax = 'hz의 최대값 = 1초전 (1-19hz) 좌/우뇌의 평균값 중 낮은값 + 설정값'
            break
        case 'CD':
            useMinVal = true
            useMaxVal = false
            labelMin = '설정값'
            // labelMax = '이상 버리는 값'
            explainMin = 'hz의 최소값 = 1초전 (1-19hz) 좌/우뇌의 평균값 중 높은값 - 설정값'
            explainMax = 'hz의 최값 = 1초전 (1-19hz) 좌/우뇌의 평균값 중 높은값'
            break
        case 'A':
        case 'AE':
        case 'B':
        case 'BE':
        case 'MB':
            useMinVal = true
            useMaxVal = true
            useMaxOverCutVal = true
            labelMin = '억제값(-)'
            labelMax = '강화값(+)'
            explainMin = 'hz의 최소값 = 1초전 hz 평균 측정값 - 억제값'
            explainMax = 'hz의 최대값 = 1초전 hz 평균 측정값 + 강화값'
            break;
        case 'C':
        case 'CE':
        case 'DE':
            useMinVal = true
            useMaxVal = true
            explainMin = 'hz의 최소값 = 최소값'
            explainMax = 'hz의 최대값 = 최대값'
            break;
        case 'D':
            useMinVal = true
            useMaxVal = false
            labelMin = '설정값'
            explainMin = '좌뇌 기준 hz의 최소값 = 1초전 hz 평균 측정값 '
            explainMax = '좌뇌 기준 hz의 최대값 = 1초전 hz 평균 측정값 + 설정값'
            break
        case 'E':
            useMinVal = true
            useMaxVal = true
            labelMin = '설정값'
            labelMax = '이상 버리는 값'
            explainMin = '좌뇌 기준 hz의 최소값 = 1초전 hz 평균 측정값 - 설정값'
            explainMax = '좌뇌 기준 hz의 최대값 = 1초전 hz 평균 측정값'
            break
        case 'F':
            useMinVal = true
            useMaxVal = false
            labelMin = '설정값'
            explainMin = '우뇌 기준 hz의 최소값 = 1초전 hz 평균 측정값 '
            explainMax = '우뇌 기준 hz의 최대값 = 1초전 hz 평균 측정값 + 설정값'
            break
        case 'G':
            useMinVal = true
            useMaxVal = true
            labelMin = '설정값'
            labelMax = '이상 버리는 값'
            explainMin = '우뇌 기준 hz의 최소값 = 1초전 hz 평균 측정값 - 설정값'
            explainMax = '우뇌 기준 hz의 최대값 = 1초전 hz 평균 측정값'
            break
        default:
            useMinVal = true
            useMaxVal = true
    }
    
    return (
        <Box>
            <Box mb={1}>
                <Typography variant="body2" display="block" gutterBottom>
                    {explainMin}
                </Typography>
                <Typography variant="body2" display="block" gutterBottom>
                    {explainMax}
                </Typography>
            </Box>
            <Typography variant="caption" display="block" gutterBottom>
                {Moment.utc(createdAt).tz(Moment.tz.guess()).format('YY년 MM월 DD일 HH시 mm분')} - {keyId}
            </Typography>
            {
                range.map((val, index) => {
                    const hzVal = band[index]
                    hzValMin = hzVal ? hzVal.min : 0
                    hzValMax = hzVal ? hzVal.max : 0
                    hzOverCut = (hzVal && hzVal.overCut) ? hzVal.overCut : 0
                    
                    return (
                        <Box p={2}
                             key={val}
                             style={{
                                 border: 1,
                                 borderColor: '#ddd',
                                 backgroundColor: props.active ? '#ddd' : '#fff'
                             }}>
                            <Grid container spacing={1}>
                                {useMinVal &&
                                <Grid item>
                                    <TextField
                                        label={`${val}Hz ${labelMin}`}
                                        value={hzValMin}
                                        onChange={(event) => {
                                            if (!band[index]) band[index] = {hz: val, min: 0, max: 0}
                                            band[index].min = event.target.value
                                            setState({
                                                ...state,
                                                band: band 
                                            })

                                            if (onSetBand) {
                                                onSetBand({
                                                    ...band
                                                }, stepCount)
                                            }
                                        }}
                                        size="small"/>
                                </Grid>
                                }
                                {useMaxVal &&
                                <Grid item>
                                    <TextField
                                        label={`${val}Hz ${labelMax}`}
                                        value={hzValMax}
                                        onChange={(event) => {
                                            if (!band[index]) band[index] = {hz: val, min: 0, max: 0}
                                            band[index].max = event.target.value
                                            setState({
                                                ...state,
                                                band: band
                                            })

                                            if (onSetBand) {
                                                onSetBand({
                                                    ...band
                                                }, stepCount)
                                            }
                                        }}
                                        size="small"/>
                                </Grid>
                                }
                                {useMaxOverCutVal &&
                                <Grid item>
                                    <TextField
                                        label={`${val}Hz 버리는값`}
                                        value={hzOverCut}
                                        onChange={(event) => {
                                            if (!band[index]) band[index] = {hz: val, min: 0, max: 0}
                                            band[index].overCut = event.target.value
                                            setState({
                                                ...state,
                                                band: band
                                            })

                                            if (onSetBand) {
                                                onSetBand({
                                                    ...band
                                                }, stepCount)
                                            }
                                        }}
                                        size="small"/>
                                </Grid>
                                }
                            </Grid>
                        </Box>
                    )
                })
            }

            <Box p={2}>
                <Typography style={{fontSize: 16, fontWeight: 'bold', backgroundColor: '#efefef'}}>
                    1회 묶음 처리 수
                </Typography>
                <TextField
                    value={stepCount}
                    onChange={(event) => {
                        setState({
                            ...state,
                            stepCount: event.target.value
                        })

                        if (onSetBand) {
                            onSetBand({
                                ...band
                            }, event.target.value)
                        }
                    }}
                    size="small"/>
            </Box>
        </Box>
    )
}

export default ComputeFeedback