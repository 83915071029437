import React from 'react';
import {Box, Button} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from "@material-ui/core/FormControl";

export default class CompleteDialog extends React.Component {
    constructor(props) {
        super();
        this.state = {
            open: false,
            history: props.history
        }
    }
    
    open() {
        this.setState({
            ...this.state,
            open: true
        })
    }
    
    close() {
       try{
           this.state.history.goBack()
       } catch(e) {}
    }
    
    render() {
        return (
            <Dialog
                disableBackdropClick
                open={this.state.open}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <Box>
                        <Box display={'flex'} justifyContent="center" >
                            <img src={require('@assets/exercise/memoryEgg/complete.png')} style={{width:200}}/>
                        </Box>
                        <FormControl component="fieldset">
                            <Box style={{textAlign: 'center'}}>
                                <h2>
                                    축하합니다. <br/> 모든 난이도를 완료했습니다!
                                </h2>
                            </Box>
                        </FormControl>
                        <Box mt={5} mb={3}>
                            <Button fullWidth variant={"outlined"}
                                onClick={this.close.bind(this)}>
                                종료하기
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }
}
