import React, {useCallback, useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import AuthProvider from '@user-provider/AuthProvider'
import ProfileService from '@user-service/ProfileService'

export default function CustomerAvatar() {
    const classes = useStyles()
    
    const [open] = useState(false)
    const [state, setState] = useState({
        current: {}
    })
    const anchorRef = useRef(null)
    const { current } = state

    const prevOpen = useRef(open);
    const fetchProfile = useCallback(async () => {
        const profiles = await ProfileService.getProfiles()
        const customerProfileID = AuthProvider.getCustomerProfileID()
        const filter = profiles.filter( p => p.id === customerProfileID)
        
        setState({
            current: filter ? filter[0] : {}
        })
    }, [])

    useEffect(()=>{
        fetchProfile().then()
    },[fetchProfile])
    
    
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);
    
    if(!current || !current.name) return <></>
    return (
        <div className={classes.root}>
            <div>
                <Button
                    className={classes.rounded}
                    ref={anchorRef}
                    edge="end"
                    aria-label="account of current user"
                    aria-haspopup="true"
                    aria-controls={open ? 'menu-list-grow' : undefined}
                >
                    {(current && current.image) &&
                    <Avatar variant="rounded" alt={AuthProvider.getProfileName()} src={require(`@images/profile/${current.image}`)}/>
                    }
                    <Typography style={{padding:'0px 5px'}}>{current.code}</Typography>
                </Button>
            </div>
        </div>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        zIndex: 9999
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    rounded: {
        color: '#222',
        fontWeight: 'bold',
        fontSize:18
    }
}));

    