import React, {useEffect, useRef, useCallback} from 'react';
import Button from '@material-ui/core/Button';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import {useTheme, withStyles} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MeasureService from '@user-service/MeasureService'

import MeasureBalanceFeedback from '@user-pages/mypage/MeasureBalanceFeedback'
import SocketProvider from '@user-provider/SocketProvider';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import MeasureHelper from '@helper/MeasureHelper'
const { measureTypeToCode } = MeasureHelper

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


export default function MonitorDialog(props) {
    const [state, setState] = React.useState({
        open: false,
        isPlaying: false,
        stepCount: 1,
        hzType: 'default',
        measureType: 'rest',
        measureCode: 'A',
        band: []
    })

    const stateRef = useRef(state)
    const mvHeatmapBalanceRef = useRef()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const handleClose = () => {
        if(props.onClose){
            props.onClose()
        }
    };

    const onMeasure = (data) => {
        mvHeatmapBalanceRef.current.addData(data.time, data.hitArr, stateRef.current.stepCount)
    }

    const fetch = useCallback( async () => {
        const {hzType, measureType, measureCode} = stateRef.current
        const res = await MeasureService.getComputeHzLastWithCode(hzType, measureTypeToCode(measureType), measureCode)
        if(!res) return

        const { stepCount, band } = res

        stateRef.current.stepCount = stepCount
        stateRef.current.band = band

        setState({
            ...stateRef.current
        });

    },[])

    useEffect(() => {
        if(0 === stateRef.current.band.length && props.open){
            alert('설정값을 가져올 수 없습니다.\n권한이 있는지 확인하세요');
            return;
        }

        stateRef.current.open = props.open
        stateRef.current.isPlaying = props.isPlaying
        stateRef.current.hzType = props.hzType
        stateRef.current.measureType = props.measureType
        stateRef.current.measureCode = props.measureCode

        setState({
            ...stateRef.current
        });

        SocketProvider.onMeasureMonitor = onMeasure
        fetch().then()

        return () => {
            SocketProvider.onMeasureMonitor = null
        }
    }, [props.open])

    const { open, isPlaying, stepCount } = state

    return (
        <Dialog
            open={open}
            fullScreen={fullScreen}
            maxWidth={'lg'}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" onClose={handleClose}>
                측정 모니터
            </DialogTitle>
            <DialogContent>
                <Button
                    onClick={() => {
                        if(props.onTogglePlay){
                            props.onTogglePlay()
                        }

                        setState({
                            ...state,
                            isPlaying: !isPlaying
                        })
                    }}
                    startIcon={isPlaying ? <PauseIcon /> : <PlayArrowIcon /> }
                    variant={isPlaying ? 'contained' : 'outlined'}
                > {isPlaying ? '중지' : '시작'}
                </Button>
                <Typography>설정된 1회 묶음 값 : { stepCount }</Typography>
                <br/>
                <br/>
                <div className={'monitor-dialog-content'}>
                    <MeasureBalanceFeedback
                        yLabels={[0,0,0,0]}
                        ref={mvHeatmapBalanceRef}
                        hzType={props.hzType}
                        measureType={props.measureType}
                        measureCode={props.measureCode}
                    />
                </div>

            </DialogContent>

        </Dialog>
    );
}