
import React, {useCallback, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

import clsx from "clsx";

import NoiseSetDialog from "@user-pages/mypage/measureSelect/NoiseSetDialog";
import {Grid, TextField, Button} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DevicesIcon from "@material-ui/icons/Devices";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TuneIcon from "@material-ui/icons/Tune";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MeasureService from '@user-service/MeasureService'
import SocketProvider from '@user-provider/SocketProvider';
import AuthProvider from '@user-provider/AuthProvider'

const NoiseManage = ({history}) => {
    const classes = useStyles();
    const noiseSetDialogRef = React.createRef()
    const [state, setState] = useState()
    
    const handleNoiseSet = (type) => {
        noiseSetDialogRef.current.open(type)
    }
    const handleDeltaChange = async (deltaHz, isUsed) => {
        const delta = state.deltaValue
        delta[deltaHz] = isUsed ? -1 : 0
        
        setState({
            ...state,
            deltaValue: delta
        })

        await MeasureService.updateConfigEach('DELTA_VALUE', delta.join(','))
    }
    
    const handleDefaultNoise = async () => {
        const info = await SocketProvider.checkDeviceStatus()
        const res = await MeasureService.getNoise(info.deviceID)

        await MeasureService.updateConfigEach('NOISE_CH1', res.ch1.join(','))
        await MeasureService.updateConfigEach('NOISE_CH2', res.ch2.join(','))
        
        alert('수정되었습니다.')
    }
    
    const handleSetting = async (type, value)  => {
        let key, sendValue 
        
        switch (type){
            case 'lowPass': 
                setState({
                    ...state,
                    lowPass: value
                })
                key = 'LOW_PASS'
                sendValue = value
                break
            case 'highPass':
                setState({
                    ...state,
                    highPass: value
                })
                key = 'HIGH_PASS'
                sendValue = value
                break
            case 'rawAmpPercent':
                setState({
                    ...state,
                    rawAmpPercent: value
                })
                key = 'RAW_AMP_PERCENT'
                sendValue = value
                break
            case 'userRawAmpPercent':
                setState({
                    ...state,
                    userRawAmpPercent: value
                })
                key = 'USER_RAW_AMP_PERCENT'
                sendValue = value
                break
                
            case 'noiseUseEachDevice': 
                setState({
                    ...state,
                    noiseUseEachDevice: value
                })
                key = 'NOISE_USE_EACH_DEVICE'
                sendValue = value ? 'yes' : 'no'
                break
            case 'noiseFilter': 
                setState({
                    ...state,
                    noiseFilter: value
                })
                key = 'NOISE_FILTER'
                sendValue = value ? 'yes' : 'no'
                break
            case 'ampUseEachMember':
                setState({
                    ...state,
                    ampUseEachMember: value
                })
                key = 'AMP_USE_EACH_MEMBER'
                sendValue = value ? 'yes' : 'no'
                break
            default:
        }
        
        await MeasureService.updateConfigEach(key, sendValue)
    }
    
    const fetch = useCallback(async () => {
        const { data , success, message } = await MeasureService.getConfig()
        const config = {}
        
        if(!success){
            alert(message);
            history.goBack()
            return
        }
        
        data.config.map(value => {
            if('DELTA_VALUE' === value.CFG_TYPE){
                try {
                    config.deltaValue = value.VALUE.split(',').map((v) => v ? parseFloat(v) : 0 )    
                } catch (e) { config.deltaValue = [-1,-1,-1] }
                
            }

            if('NOISE_FILTER' === value.CFG_TYPE){
                config.noiseFilter = 'yes' === value.VALUE 
            }

            if('HIGH_PASS' === value.CFG_TYPE){
                config.highPass = value.VALUE
            }

            if('LOW_PASS' === value.CFG_TYPE){
                config.lowPass = value.VALUE
            }

            if('RAW_AMP_PERCENT' === value.CFG_TYPE){
                config.rawAmpPercent = value.VALUE
            }
            
            if('NOISE_USE_EACH_DEVICE' === value.CFG_TYPE){
                config.noiseUseEachDevice = 'yes' === value.VALUE
            } 
            
            if('AMP_USE_EACH_MEMBER' === value.CFG_TYPE){
                config.ampUseEachMember = 'yes' === value.VALUE
            }

            config.userRawAmpPercent = 100
            if('USER_RAW_AMP_PERCENT' === value.CFG_TYPE){
                config.userRawAmpPercent = value.VALUE
            }
            
            
            return value
        });
        
        setState(config)
    }, [])
    
    useEffect(()=>{
        fetch().then()
    },[fetch])

    
    if(!state) return <></>
    const { deltaValue, noiseFilter, highPass, lowPass, rawAmpPercent, noiseUseEachDevice, ampUseEachMember, userRawAmpPercent } = state 
    let timer 
    
    return (
        <div id="cont-wrap" >
            <div className="sv" >
                <Grid container>
                    <Grid item xs={1}>
                        <IconButton
                            className={classes.back}
                            aria-label="delete"
                            onClick={()=>{
                                history.goBack()
                            }}
                        >
                            <ArrowBackIcon style={{fontSize: 50}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <div>
                            <h3>노이즈 설정</h3>
                            <i>Brain Health</i>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className={clsx('cont', classes.root)} >
                <List
                    style={{marginTop: 30}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            델타파 사용 
                        </ListSubheader>
                    }
                    className={classes.listRoot}
                >
                    <ListItem className={'item'} >
                        <ListItemIcon>
                            <TuneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Chart - Delta 0hz 사용" />
                        <ListItemSecondaryAction>
                            <FormControlLabel
                                control={<Switch checked={-1 === deltaValue[3]} onChange={(event)=>{
                                    handleDeltaChange(3, event.target.checked).then()
                                }} name="checkedA" />}
                                label={''}
                                color="primary"
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem className={'item'} >
                      <ListItemIcon>
                        <TuneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Band - Delta 0hz 사용" />
                      <ListItemSecondaryAction>
                        <FormControlLabel
                          control={<Switch checked={-1 === deltaValue[0]} onChange={(event)=>{
                            handleDeltaChange(0, event.target.checked).then()
                          }} name="checkedA" />}
                          label={''}
                          color="primary"
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
  
                    <Divider />
                
                    <ListItem className={'item'} >
                        <ListItemIcon>
                            <TuneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Delta 1hz 사용" />
                        <ListItemSecondaryAction>
                            <FormControlLabel
                                control={<Switch checked={-1 === deltaValue[1]} onChange={(event)=>{
                                    handleDeltaChange(1, event.target.checked).then()
                                }} name="checkedA" />}
                                label={''}
                                color="primary"
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />

                    <ListItem className={'item'} >
                        <ListItemIcon>
                            <TuneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Delta 2hz 사용" />
                        <ListItemSecondaryAction>
                            <FormControlLabel
                                control={<Switch checked={-1 === deltaValue[2]} onChange={(event)=>{
                                    handleDeltaChange(2, event.target.checked).then()
                                }} name="checkedA" />}
                                label={''}
                                color="primary"
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </List>



                <List
                    style={{marginTop: 30}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            증폭처리
                        </ListSubheader>
                    }
                    className={classes.listRoot}
                >
                    <ListItem className={'item'} >
                        <ListItemIcon>
                            <TuneIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <div>
                                기본 증폭값
                                <div className={classes.small}>RAW 데이터의 %증가 후 FFT 처리합니다. </div>
                                <div className={classes.small}>(100 기본값, 150 = 50% 증폭)</div>
                            </div>
                        } />
                        <ListItemSecondaryAction>
                            <TextField 
                                defaultValue={rawAmpPercent}
                                variant="filled"
                                inputProps={{min: 0, style: { textAlign: 'right', paddingRight: 10, width: 50 }}}
                                InputProps={{ disableUnderline: true }}
                                onChange={(event)=>{
                                    if(timer) clearTimeout(timer)
                                    const value = event.target.value
                                    
                                    timer = setTimeout(() => {
                                        handleSetting('rawAmpPercent', value).then() 
                                    }, 500)
                                       
                                }} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem button className={'item'} onClick={() => {
                        handleNoiseSet('device')
                    }}>
                        <ListItemIcon>
                            <TuneIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <div>
                                사용자 개별 증폭 사용
                                <div className={classes.small}>사용자마다 개별로 증폭값을 사용하도록 합니다.</div>
                                <div className={classes.small}>설정하지 않은 사용자는 <b>"기본증폭값({rawAmpPercent}%)"</b>으로 처리됩니다.</div>
                                
                            </div>
                        } />
                        <ListItemSecondaryAction>
                            <ListItemSecondaryAction>
                                <FormControlLabel
                                    control={<Switch checked={ampUseEachMember} onChange={(event)=>{
                                        handleSetting('ampUseEachMember', event.target.checked).then()
                                    }} name="checkedA" />}
                                    label={''}
                                    color="primary"
                                />
                            </ListItemSecondaryAction>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />

                    <ListItem className={'item'} >
                        <ListItemIcon>
                            <TuneIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <div>
                                {AuthProvider.getProfileName()} 의 증폭값
                                <div className={classes.small}>(100 기본값, 150 = 50% 증폭)</div>
                            </div>
                        }/>
                        <ListItemSecondaryAction>
                            <TextField
                                defaultValue={userRawAmpPercent}
                                variant="filled"
                                inputProps={{min: 0, style: { textAlign: 'right', paddingRight: 10, width: 50 }}}
                                InputProps={{ disableUnderline: true }}
                                onChange={(event)=>{
                                    if(timer) clearTimeout(timer)

                                    const value = event.target.value
                                    timer = setTimeout(() => {
                                        handleSetting('userRawAmpPercent', value).then()
                                    }, 500)
                                }} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    
                    <ListItem className={'item'} >
                        <ListItemIcon>
                            <TuneIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <div>
                                하이패스 필터
                                <div className={classes.small}>hz 이하값 처리</div>
                            </div>
                            }/>
                        <ListItemSecondaryAction>
                            <TextField 
                                defaultValue={highPass}
                                variant="filled"
                                inputProps={{min: 0, style: { textAlign: 'right', paddingRight: 10, width: 50 }}}
                                InputProps={{ disableUnderline: true }}
                                onChange={(event)=>{
                                    if(timer) clearTimeout(timer)

                                    const value = event.target.value
                                    timer = setTimeout(() => {
                                        handleSetting('highPass', value).then()
                                    }, 500)
                                }} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />

                    <ListItem className={'item'} >
                        <ListItemIcon>
                            <TuneIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <div>
                                로우패스 필터
                                <div className={classes.small}>hz 이상값 처리</div>
                            </div>
                        } />
                        <ListItemSecondaryAction>
                            <TextField 
                                defaultValue={lowPass}
                                variant="filled"
                                inputProps={{min: 0, style: { textAlign: 'right' , paddingRight: 10, width: 50}}}
                                InputProps={{ disableUnderline: true }}
                                onChange={(event)=>{
                                    if(timer) clearTimeout(timer)

                                    const value = event.target.value
                                    timer = setTimeout(() => {
                                        handleSetting('lowPass', value).then()
                                    }, 500)
                                }} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </List>

                <List
                    style={{marginTop: 30, paddingBottom: 100}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            노이즈 설정
                        </ListSubheader>
                    }
                    className={classes.listRoot}
                >
                    <ListItem button className={'item'} onClick={() => {
                        handleNoiseSet('common')
                    }}>
                        <ListItemIcon>
                            <DevicesIcon />
                        </ListItemIcon>
                        <ListItemText primary="공통 노이즈 Hz 설정" />
                        <ListItemSecondaryAction>
                            <IconButton aria-label="delete">
                                <ChevronRightIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    
                    <ListItem button className={'item'} onClick={() => {
                        handleNoiseSet('device')
                    }}>
                        <ListItemIcon>
                            <DevicesIcon />
                        </ListItemIcon>
                        <ListItemText primary="기기별 노이즈 Hz 설정" />
                        <ListItemSecondaryAction>
                            <IconButton aria-label="delete">
                                <ChevronRightIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    
                    <ListItem className={'item'} >
                        <ListItemIcon>
                            <DevicesIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <div>
                                 기기별 노이즈 사용
                                <div className={classes.small}>기기 마다 노이즈 개별사용을 허용합니다.</div>
                            </div>
                        } />
                        <ListItemSecondaryAction>
                            <FormControlLabel
                                control={<Switch checked={noiseUseEachDevice} onChange={(event)=>{
                                    handleSetting('noiseUseEachDevice', event.target.checked).then()
                                }} name="checkedA" />}
                                label={''}
                                color="primary"
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />

                    <ListItem className={'item'} >
                        <ListItemIcon>
                            <DevicesIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <div>
                                현재 계정 노이즈 설정을 기본으로 사용 
                                <div className={classes.small}>현재 측정된 노이즈를 기본 노이즈로 설정합니다.</div>
                            </div>
                        } />
                        <ListItemSecondaryAction>
                            <Button variant={'outlined'} 
                                    onClick={() => {
                                        handleDefaultNoise().then()
                                    }}>
                                 노이즈 설정
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />

                    <ListItem className={'item'} >
                        <ListItemIcon>
                            <DevicesIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <div>
                                FFT 노이즈 필터 적용
                                <div className={classes.small}>FFT에 노이즈 필터를 적용합니다 (mV값이 필터만큼 잘라집니다.)</div>
                            </div>
                        } />
                        <ListItemSecondaryAction>
                            <FormControlLabel
                                control={<Switch checked={noiseFilter} onChange={(event)=>{
                                    handleSetting('noiseFilter', event.target.checked).then()
                                }} name="checkedA" />}
                                label={''}
                                color="primary"
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </List>
                
             
                <NoiseSetDialog ref={noiseSetDialogRef}/>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 800,
        margin: 'auto',
        
        '& .item': {
            padding : '20px 10px'
        }
    },
    back: {
        marginTop: 30,
        '&:hover': {
            backgroundColor: '#053288',
        }
    },
    small: {
        fontSize: 14,
        color: '#999',
    },
    remove: {
        color: '#ccc',
        '&:hover': {
            color: '#666'
        },
        marginLeft: 20
    },
    date: {
        fontSize: 14,
        color: '#999',
        fontFamily: 'Noto Sans KR',
    },
    text:{
        fontFamily: 'Noto Sans KR',
    },
    inline: {
        display: 'inline',
    },
    listRoot: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
    },
}));

export default NoiseManage
