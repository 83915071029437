import 'react-app-polyfill/ie9'; 
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import Axios from 'axios';
import Qs from 'qs';
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from '@reducers'
import {HelmetProvider} from "react-helmet-async";
import {RecoilRoot} from "recoil";

Axios.interceptors.request.use((config) => {
    config.paramsSerializer = (params) => {
        return Qs.stringify(params, {
            arrayFormat: 'brackets',
            encode: false,
        });
    };
    return config;
});
//
// Sentry.init({
//     dsn: "https://3af16617420e40b1a80960babb4c1dbf@o967145.ingest.sentry.io/5918141",
//     integrations: [new Integrations.BrowserTracing()],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     environment: process.env.NODE_ENV,
//     tracesSampleRate: 1.0,
// });


const store = createStore(rootReducer)
ReactDOM.render(
<RecoilRoot>
  <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
          <HelmetProvider>
              <App />
          </HelmetProvider>
      </SnackbarProvider>
  </Provider>
</RecoilRoot>
, 
document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
