import React, {useState} from 'react';

import {Box, Button, Grid} from '@material-ui/core';

import ExerciseBox from '@user-pages/exercise/ExerciseBox'
import {LoadingSkeleton} from "./MeasureTest";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {makeStyles} from "@material-ui/core/styles";
import MeasureTestHistoryDialog from "./MeasureTestHistoryDialog";
import clsx from "clsx";
import AuthProvider from "../../../provider/AuthProvider";

const MeasureTestApp = (props) => {
    // const { state, measureType } = props
    const { state, measureType } = props
    const { exercise, measureTypesCount } = state
    const [ showHistory, setHistory ] = useState(false)
    const classes = useStyles()

    // const measureType = 'measure'
        
    
    return (
        <div id="cont-wrap" className={`app ${AuthProvider.get('viewMode')}`} >
            <div className={clsx('sv',{
                'green' : ['develop','train'].includes(measureType)
            })}>
                <div className={'ti-wrap'}>
                    <h3>
                        {['develop','train'].includes(measureType) ? '두뇌 계발' : '뇌기능 검사'}
                    </h3>
                    <span>기본적인 인지, 기억을 테스트 합니다.</span>
                    <i>Brain Health</i>
                </div>
            </div>
            
            <div className="cont">
                <Box display={'flex'} justifyContent={'flex-end'} width={'100%'} style={{marginBottom: 20, paddingRight: 10}}>
                    {/*<Button*/}
                    {/*    className={classes.button}*/}
                    {/*    style={{marginRight: 10}}*/}
                    {/*    onClick={async () => {*/}
                    {/*        if(props.openMeasureTest){*/}
                    {/*            props.openMeasureTest()*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*> 프로그램 선택 </Button>*/}
                    
                    <Button
                        className={classes.button}
                        endIcon={<ChevronRightIcon className={'rightIcon'}/>}
                        onClick={async () => {
                            setHistory(!showHistory)
                        }}
                    > 기록보기 </Button>
                </Box>
                <Grid container spacing={3}>
                    {exercise.map((ex) => {
                        const typeCount = measureTypesCount ? measureTypesCount.find(type => type.key === ex.programID) : ''
                        
                        return (
                            <Grid item md={4} xs={6} key={ex.id}>
                                <ExerciseBox
                                    {...ex}
                                    history={props.history}
                                    measureTypesCount={typeCount}
                                    measureType={measureType}
                                    onNeedTicket={() => {
                                    }}
                                />
                            </Grid>
                        )
                    })}

                    {0 === exercise.length && <LoadingSkeleton/> }
                </Grid>
            </div>
            <MeasureTestHistoryDialog
                exercise={exercise}
                open={showHistory}
                onClose={() => {
                    setHistory(false)
                }}
            />
        </div>
    )
}


const useStyles = makeStyles(() => ({
    button: {
        border: '2px solid #BEBEBE',
        padding: '5px 30px',
        borderRadius: 20,
        color: '#666',
        '& .MuiButton-endIcon': {
            marginRight: -15
        },
        '& .rightIcon': {
            color: '#00DD94',
            fontSize: 25,
            fontWeight: 'bold'
        }
    },
}));



export default MeasureTestApp
