import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {Button, Grid, ButtonGroup} from '@material-ui/core';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItem from '@material-ui/core/ListItem';
import PrescriptionService from '@user-service/PrescriptionService'

import {TextField} from "@material-ui/core";
import {useState} from "react";
import MeasureHelper from '@helper/MeasureHelper'
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import IconButton from "@material-ui/core/IconButton";
import { debounce } from "lodash";

const useStyles = makeStyles({
    draggingListItem: {
        background: '#ededed'
    },
    title: {
        // fontSize: 24,
        fontWeight: 500,
        paddingBottom: 10
    },
    list : {
        // paddingBottom: 20,
        // marginBottom: 20,
        borderBottom: '1px solid #ddd !important'
    },
    remove: {
        color: '#ccc',
        '&:hover': {
            color: '#666'
        },
        marginLeft: 20
    },
});

const DraggableListItem = ({ item, index, onUpdate, onDelete }) => {
    const classes = useStyles();
    // const codeSet = item.codeSet ? item.codeSet : []
    
    
    const [exercise, setExercise] = useState({
        exerciseCount : item.exerciseCount ? item.exerciseCount : item.defaultCount,
        measureCode: item.measureCode,
        measureTypeCode: item.measureTypeCode,
        prescriptionExerciseID : item.prescriptionExerciseID,
        countUnitName: item.countUnitName,
        init: true
    })

    const codeSet = MeasureHelper.getCodeNameSet(item.hzType, exercise.measureTypeCode)

    const onUpdateDB = React.useMemo(() => 
        debounce(exercise => {
            if (!exercise.init) onUpdate(exercise)
        },500)
    ,[onUpdate])
    
    const update = React.useCallback((exercise, init = false) => {
        const updatedExercise = {
            ...exercise,
            init
        }
        setExercise(updatedExercise)
        onUpdateDB(updatedExercise)
        
    },[onUpdateDB])
    
    const handleDelete = async (prescriptionExerciseId) => {
        if(!window.confirm('삭제하시겠습니까?')) return
        await PrescriptionService.deletePrescriptionExercise(prescriptionExerciseId)
        onDelete()
    }
  
    //
    // useEffect(() => {
    //     if(!exercise.init){
    //         console.log(1234)
    //         onUpdate(exercise)
    //     }
    // },[exercise])
    
    return (
        <Draggable draggableId={`exercise-${item.prescriptionExerciseID}`} index={index}>
            {(provided, snapshot) => (
                <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={snapshot.isDragging ? classes.draggingListItem : classes.list}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <div className={classes.title}>{index + 1}.{item.name}</div>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container style={{width: '100%'}}>
                                <Grid item xs={6}>
                                    <ButtonGroup>
                                        {item.typeCodeSet.map(({name, code}) => {
                                            return (
                                                <Button
                                                    variant={'contained'}
                                                    key={`ex-${code}`}
                                                    size={'small'}
                                                    color={code === exercise.measureTypeCode ? 'primary' : 'default'}
                                                    onClick={() => {
                                                        update({
                                                            ...exercise,
                                                            measureTypeCode: code,
                                                        })
                                                    }}
                                                >
                                                    {name}
                                                </Button>
                                            )
                                        })}
                                    </ButtonGroup>
                                </Grid>
                                <Grid item xs={3}>
                                    <ButtonGroup>
                                        {codeSet.map((code) => {
                                            return (
                                                <Button
                                                    variant={'contained'}
                                                    size={'small'}
                                                    color={code.value === exercise.measureCode ? 'primary' : 'default'}
                                                    key={`ex1-${code.value}`}
                                                    onClick={() => {
                                                        update({
                                                            ...exercise,
                                                            measureCode: code.value,
                                                        })
                                                    }}
                                                >
                                                    {code.value}
                                                </Button>
                                            )
                                        })}
                                    </ButtonGroup>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        label={`설정시간 (${exercise.countUnitName})`}
                                        value={exercise.exerciseCount}
                                        style={{width: 120}}
                                        onChange={(event) => {
                                            const newValue = event.target.value 
                                            
                                            update({
                                                ...exercise,
                                                exerciseCount: newValue,
                                            },'' === newValue)    
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => {
                                        handleDelete(item.prescriptionExerciseID).then()
                                    }} className={classes.remove}>
                                        <HighlightOffIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ListItem>
            )}
        </Draggable>
    );
};

export default DraggableListItem;
