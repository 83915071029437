import React, {useCallback, useEffect, useRef, useState} from "react";
import {Box, Card, CardActionArea, CardContent, Grid, Button, CardHeader} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import PrescriptionService from '@user-service/PrescriptionService';
import AuthProvider from "@user-provider/AuthProvider";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import DateHelper from "../../../helper/DateHelper";
import CurrentPreLevel from "./CurrentPreLevel";
import BridgeProvider from "../../../provider/BridgeProvider";

/**
 * 측정 결과이미지를 가져옵니다.
 * @param {*} props
 */
export default function CurrentFFT(props) {
    const classes = useStyles();
    const ableClick = true !== props.clickDisabled
    
    const { isEEG, id, eegDate, fftType} = props.measureResult;
    const [ fftImages , setFFTImages ] = useState(null);
    const [ show3DFFT, setShow3DFFT ] = useState(false)
    const [ viewMode, setViewMode ] = useState('image')
    const history = useHistory()
    const ref = useRef(null);
    
    const handler = () => {
        if(AuthProvider.canViewMeasureDetail() && ableClick){
            history.push(`/measureDetail/${id}/fft`);    
        }
    }

    const fetch = useCallback(async () => {
        setFFTImages(await PrescriptionService.getFFTImage(id))
    }, [id])

    useEffect(() => {
        fetch().then()
        setTimeout(() => {
            setShow3DFFT(true)
        }, 2000)
    },[fetch])
    
    let height = ref.current ? ref.current.offsetWidth * 6 / 16 : 400
    let width = ref.current ? ref.current.offsetWidth : 0;
    
    if('split' != fftType){
        height = ref.current ? ref.current.offsetWidth * 10 / 16 : 400
    }
  
    return (
        <Box className={classes.root} ref={ref}>
            <Card className={clsx([classes.card])}>
                <CardHeader
                    style={{paddingBottom: 0}}
                    title={
                        <>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Typography variant={'subtitle1'} className={classes.title} style={{marginTop: 3}}>
                                        EEG 측정
                                        {eegDate &&
                                        <span>({DateHelper.format(eegDate, 'YYYY.MM.DD HH:mm')})</span>
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item className={'p-button'}>
                                    <Button
                                        size={'small'}
                                        variant={'outlined'}
                                        onClick={() => {
                                            try {
                                              BridgeProvider.instance.openPopup(`/measureFFT/${id}/eeg`)
                                            } catch {
                                              window.open(`/measureFFT/${id}/eeg`, 'measureFFT', `width=${window.innerWidth}, height=${window.innerHeight}`)  
                                            }
                                            
                                        }}
                                    >
                                        HWF
                                    </Button>
                                    <Button
                                        size={'small'}
                                        variant={'outlined'}
                                        style={{marginLeft: 10}}
                                        onClick={() => {
                                          try {
                                            BridgeProvider.instance.openPopup(`/measureFFTs/${id}/eeg`)
                                          } catch {
                                            window.open(`/measureFFTs/${id}/eeg`, 'measureFFTs', `width=${window.innerWidth}, height=${window.innerHeight}`)
                                          }
                                          
                                            
                                        }}
                                    >
                                        FFT
                                    </Button>
                                </Grid>
                                {/*{'merged' === fftType &&*/}
                                {/*<Grid item className={'p-button'} style={{position: 'relative'}}>*/}
                                {/*    {!show3DFFT &&*/}
                                {/*    <div style={{paddingLeft: 20}}>*/}
                                {/*        <CircularProgress style={{height: 20, width: 20}}/>*/}
                                {/*        <div style={{*/}
                                {/*            fontSize: 14,*/}
                                {/*            position: "absolute",*/}
                                {/*            top: 13,*/}
                                {/*            left: 50,*/}
                                {/*            width: 200*/}
                                {/*        }}>3D FFT를 불러오고 있습니다.*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    }*/}
                                {/*    {show3DFFT &&*/}
                                {/*    <Button*/}
                                {/*        size={'small'}*/}
                                {/*        variant={'outlined'}*/}
                                {/*        onClick={() => {*/}
                                {/*            let changeViewMode = viewMode*/}
                                {/*            if('3d' === changeViewMode) changeViewMode = 'image'*/}
                                {/*            else changeViewMode = '3d'*/}
                                {/*            */}
                                {/*            setViewMode(changeViewMode)*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        {'3d' === viewMode ? '이미지 보기' : '3D FFT 보기'}*/}
                                {/*    </Button>*/}
                                {/*    }*/}
                                {/*</Grid>*/}
                                {/*}*/}
                                <Grid item xs={12}>
                                    <CurrentPreLevel type="EEG" measureResult={props.measureResult} />
                                </Grid>
                            </Grid>
                        </>
                    }
                />
                <CardActionArea>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} onClick={handler}>
                                {'yes' === isEEG &&
                                    <React.Fragment>
                                        <Box>
                                            {fftImages && 'split' === fftType &&
                                            <Box className="ratio-16-9 print-image" style={{
                                                display:  'image' === viewMode ? 'block' : 'none',
                                                backgroundImage: `url(${fftImages.ch1})`,
                                                backgroundColor: 'transparent',
                                                backgroundSize: `${width}px ${height}px`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: '20px -30px',
                                                minHeight: height,
                                                marginTop: -20
                                            }}/>
                                            }

                                            {fftImages && 'split' !== fftType && 0 < width &&
                                            <>

                                                {/*<Box className="ratio-16-9 print-image" style={{*/}
                                                {/*    display:  'image' === viewMode ? 'block' : 'none',*/}
                                                {/*    backgroundImage: `url(${fftImages.ch1})`,*/}
                                                {/*    backgroundColor: 'transparent',*/}
                                                {/*    backgroundSize: `${width + (width * 0.1)}px ${height}px`,*/}
                                                {/*    backgroundRepeat: 'no-repeat',*/}
                                                {/*    backgroundPosition: '-20px -20px',*/}
                                                {/*    minHeight: height,*/}
                                                {/*    marginTop: -20*/}
                                                {/*}}/>*/}
                                                
                                                <Box>
                                                    <img src={fftImages.ch1} style={{width: '100%'}}/>
                                                </Box>
                                                {/*<Box className="ratio-16-9" style={{*/}
                                                {/*    display:  '3d' === viewMode ? 'block' : 'none',*/}
                                                {/*    minHeight: height,*/}
                                                {/*    marginTop: -20*/}
                                                {/*}}>*/}
                                                {/*    <iframe width="100%" height="100%" src={`/measureFFT/${id}/eeg?hideHeatmap=yes&width=${width}`} />*/}
                                                {/*</Box>*/}
                                            </>
                                            }
                                                
                                            {!fftImages &&
                                            <Box display={'flex'} className="ratio-16-9" minHeight={400} style={{color: '#aaa', backgroundColor:'#efefef'}}>
                                                <Box m="auto" style={{
                                                    justifyContent: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    height: '100%'
                                                }}>
                                                    <Typography variant="h6" align={'center'} >
                                                        이미지가 없습니다
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            }
                                        </Box>
                                        <Box style={{position: 'relative'}}>
                                            {fftImages && 'split' === fftType &&
                                            <Box className="ratio-16-9" style={{
                                                backgroundImage: `url(${fftImages.ch2})`,
                                                backgroundSize: `${width}px ${height}px`,
                                                backgroundColor: 'transparent',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: '20px -30px',
                                                minHeight: height,
                                                marginTop: (height / 4 * -1)
                                            }}/>
                                            }
    
                                            {!fftImages &&  'split' === fftType &&
                                            <Box display={'flex'} className="ratio-16-9" minHeight={400}  style={{color: '#aaa', backgroundColor:'#efefef'}}>
                                                <Box m="auto" style={{
                                                    justifyContent: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    height: '100%'
                                                }}>
                                                    <Typography variant="h6" align={'center'}>
                                                        이미지가 없습니다
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            }
                                        </Box>
                                </React.Fragment>
                                }

                                {'yes' !== isEEG &&
                                <Box display={'flex'} minHeight={200}>
                                    <Box m="auto">
                                        <Typography variant="h6" style={{color:'#999'}}>
                                            뇌기능(EEG) 측정이 필요합니다.
                                        </Typography>
                                    </Box>
                                </Box>
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    root: {
    },
    title: {
        fontWeight: 'bold',
        
        '& span':{
            fontWeight: 'normal',
            fontSize: 14
        } 
    },
    card: {
        backgroundColor: '#F5F9FE',
        border: "none", boxShadow: "none"
    },
}));
