import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
    Chart,
    BarSeries,
    PieSeries,
    ArgumentAxis,
    ValueAxis,
  } from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';

import { GridGenerator, HexGrid, Layout, Hexagon, Text } from 'react-hexgrid';
import '@user-style/hexgrid.css';

import PrescriptionService from '@user-service/PrescriptionService';
import MeasureHelper from '@helper/MeasureHelper'

import FFTChart from '@user-components/FFTChart';
import ColorHelper from '@helper/ColorHelper';



const Measure = () => {
    const [prescription, setPrescription] = useState(null)
    
    const fetch = useCallback(async () => {
        const result = await PrescriptionService.getLatestThisMonth()

        result.measureAll = MeasureHelper.makeBalanceData(result)
        result.measureMap = MeasureHelper.measureToMap(result.measureAll)

        setPrescription(result)
    }, [])
    
    useEffect(() =>{
        fetch().then()
    },[fetch])

    if(!prescription) return <></>

    return (
        <div>
            <div className="measure-wrap wrapper2" style={{width:1200, maxWidth:1200}}>
                <section className="section section2_2 bdtop pt40">
                    <Typography variant="h5" component="h3" >
                        뇌 군형상태 분석 결과 - {prescription.id}회차
                    </Typography>

                    <Typography variant="subtitle1" 
                                style={{textAlign:'left',paddingTop:20,paddingBottom:20, borderBottom:'1px solid #eee', marginBottom:20, color:'#999'}}>
                    <b>이승헌님</b> 뇌 균형상태 분석 결과입니다. 
                    </Typography>
                </section>

                {/*분석 결과 FFT*/}
                <BrainFFT prescriptionID={prescription.id} />

                {/* 실시간 측정 */}
                <RealtimeMeasure {...prescription}/>
            </div>

            <div className="mypage-wrap wrapper2">
                {/* 브레인맵 */}
                {/* <BrainMap /> */}

                {/* 밴드분석 */}
                {/* <section className="section section2_2">
                    <h3 className={classes.subTitle}>밴드분석</h3>
                    <p className="txt02">균형상태 측정에 대한 결과를 한눈에 볼수 있습니다 </p>

                    <Grid container style={{height:100}}>
                        <Grid item xs={6}>
                            <BrainBand title="왼쪽"/>
                        </Grid>
                        <Grid item xs={6}>
                            <BrainBand title="오른쪽"/>
                        </Grid>
                    </Grid>
                </section> */}
            </div>
        </div>
    );
}

// 실시간 측정 페이지
export function RealtimeMeasure(props) {

    const maxHexValue = 216
    const blankCoordinate = [0,216,5,6,
        7,8,17,18,28,29,40,41,53,54,67,68,82,83,98,99,115,116,
        16,27,39,52,66,81,147,
        38,51,161,65,
        55,69,84,100,101,117,133,148,162,175,187,188,198,199,200,211,208,209,210
        ]

    const ableHex = new Array(maxHexValue).fill(0)
                                            .map((_, index) => {
                                                if (-1 === blankCoordinate.indexOf(index)) return index
                                            });
    
    const hexas = GridGenerator.hexagon(8);
    const [measureCoord] = useState(ableHex.map((_,index)=>{

    
        const data = props.measureMap[index]

        var measureType = ''
        var value = 0 

        if(null != data ){
            measureType = data.type
            value = data.value
        } 
        
        return {
            measureType: value ? 'fill ' + measureType : measureType,
            value: value
        }

    }))
    

    return <div>

                <HexGrid width={800} height={800} viewBox="-50 -50 100 100">

                    <Layout size={{ x: 3, y: 3 }} origin={{ x: 0, y: 0 }} flat={false} spacing={1.1}>
                    { hexas.map((hex, i) => {

                    var className = ""
                    if(-1 < blankCoordinate.indexOf(i)) {
                        className = "blank"
                    } else {
                        if(measureCoord[i] && '' !== measureCoord[i].measureType){
                            //측정된 결과가 있으면 색을 채우고 아니면 storke만 처리 
                            className = measureCoord[i].measureType
                        }
    
                    }
                        
                    
                    return <Hexagon key={i} q={hex.q} r={hex.r} s={hex.s} className={className} onClick={(event)=>{
                            }}>
                            <Text>{'' + i}</Text>
                            </Hexagon>
                    })
                    }
                    </Layout>
                </HexGrid>
            </div>
}



/**
 * 측정한 FFT 결과를 보여주는 차트 
 * @param {*} props 
 */
export function BrainFFT(props) {

    let fftChartCh1Ref = useRef(null);
    let fftChartCh2Ref = useRef(null);

    useEffect(() => {
        PrescriptionService.getMeasureFromFile(props.prescriptionID, 'fft', (result) => {
    
            for(var i = 0; i < result.ch1.length; i++ ){
                fftChartCh1Ref.current.addData(i+1, result.ch1[i]);
                fftChartCh2Ref.current.addData(i+1, result.ch2[i]);
            }
        })
    }, []);


    const fftChartOptions = {
        graphWireFrame: false,
        graphWireFrameColor: 0x666666,
        cone: true,
        gridSize: 0.7,
        mvSize: 0.3,
        timeHzGrid: false,
        timeMvGrid: false,
        mvHzGrid: false,
        timeGuide: true,
        graphColors: ColorHelper.getColors()
    };
    
    return (
        <Box>
            <div >
                <FFTChart label={'Left Brain'} options={ fftChartOptions } ref={ fftChartCh1Ref }/>
            </div>
            <div>
                <FFTChart label={'Right Brain'} options={ fftChartOptions } ref={ fftChartCh2Ref }/>
            </div>
        </Box>
    )
}



//균형상태 분석
export function BrainMap(){
    const classes = useStyles();

    const data = [
        { country: 'Russia', area: 5 },
        { country: 'Canada', area: 5 },
    ]

    const labels = ['Raw', 'Delta', 'Theta', 'Aplha', 'SMR', 'BetaL', 'BetaH'];

    return <section className="section section2_2">
                <h3 className={classes.subTitle}>균형상태 분석</h3>
                <p className="txt02">균형상태 측정에 대한 결과를 한눈에 볼수 있습니다 </p>
                <div>
                <Grid container>
                    <Grid item >
                        <Chart
                            data={data}
                            height={200}
                            width={200}
                            >
                            <PieSeries
                                valueField="area"
                                argumentField="country"
                            />
                            <Animation />
                        </Chart>
                    </Grid>
                    <Grid item>
                        상태정보 
                    </Grid>
                </Grid>
                </div>
            </section>
}


//밴드분석
export function BrainBand(props){
    
    const data = [
        { year: '1950', population: 2.525 },
        { year: '1960', population: 3.018 },
        { year: '1970', population: 3.682 },
        { year: '1980', population: 4.440 },
        { year: '1990', population: 5.310 },
        { year: '2000', population: 6.127 },
        { year: '2010', population: 6.930 },
    ];

    return <Chart data={data} height={150} >
                <ArgumentAxis />
                <ValueAxis />

                <BarSeries
                    valueField="population"
                    argumentField="year"
                />

                <Animation />
            </Chart>
}




//균형상태 분석
export function MeasureState(){
    const classes = useStyles();

    return <section className="section section2_2">
                <h3 className={classes.subTitle}>균형상태 분석</h3>
                <div>
                    <ul className="clearfix">
                        <li>
                            <span>안정 지수</span>
                            <b>22</b>
                        </li>
                        <li>
                            <span>주의력 지수</span>
                            <b>22</b>
                        </li>
                        <li>
                            <span>집중력 지수</span>
                            <b>22</b>
                        </li>
                    </ul>
                </div>
            </section>
}





const useStyles = makeStyles(theme => ({
    marginTop: {
        marginTop:50
    },

   
}));


export default Measure
