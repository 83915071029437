import React, {useEffect} from 'react';
import {Box, Grid} from '@material-ui/core';
import ResultBarPie from "./ResultBarPie";

const ResultEmotion = (props) => {
    const { measureData,  fontSize, titleFontSize } = props
    
    useEffect(()=>{
    },[])
        
    const parseMeasureData = (measureData) => {
        return measureData.map((val) => {
            if(100 < val) {
                return 100
            } else {
                return val
            }
        }) 
    }
    return (
        <Box>
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ResultBarPie title={"안정 & 불안정 상태 "} 
                            data={parseMeasureData(measureData[0])} 
                            labels={['안정', '불안정']}
                            useEachPieChart={true}
                            barChartColor={['#60C3F7','#F8807A']}
                            pieChartColor={['#60C3F7','#F8807A']}
                            lineChartXs={8}
                            pieChartXs={2} 
                            fontSize={fontSize}
                            titleFontSize={titleFontSize}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ResultBarPie 
                            title={"산만율(%)"}
                            data={parseMeasureData(measureData[1])}
                            useEachPieChart={true}
                            barChartColor={['#60C3F7','#F8807A']}
                            pieChartColor={['#60C3F7','#F8807A']}
                            lineChartXs={8}
                            pieChartXs={2}
                            fontSize={fontSize}
                            titleFontSize={titleFontSize}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}



export default ResultEmotion
