import React, {useState} from 'react';
import {
    Box, Typography, Grid,
    TextField,
} from '@material-ui/core';

import {makeStyles, withStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import CustomerSearchDialog from "../customer/CustomerSearchDialog";



const CenterProfile = (props) => {
    const { center } = props
    const [open] = useState(false)
    const classes = useStyles();
    return (
        <React.Fragment>
            <CustomerSearchDialog
                centerID={center.id}
                centerName={center.name}
                open={open}
                onClose={() => {
                  
                }}/>
            
            <Box mt={5}>
                <Box  style={{margin: '0 auto', width: 1024}}>
                    <Box border={1} mt={3} p={5} style={{borderColor: '#ddd', borderRadius: 10}}>
                        <Grid container>
                            <Grid item xs={2}>
                                <Avatar variant="rounded" className={classes.large}/>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant={'subtitle1'}>
                                    {center.name}
                                </Typography>
                                <Typography variant={'h5'}>
                                    {center.ownerName} - {center.loginID}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                {/*<Button */}
                                {/*    variant={'outlined'} */}
                                {/*    size={'large'}*/}
                                {/*    onClick={() => {*/}
                                {/*        setOpen(true)*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    변경*/}
                                {/*</Button>*/}
                            </Grid>
                        </Grid>

                    </Box>
                </Box>
            </Box>
        </React.Fragment>

    )
}

const useStyles = makeStyles(theme => ({
   
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),

        // backgroundColor: 'transparent',
        '& img': {
            width: '100%'
        }
    },

}));



export default CenterProfile
