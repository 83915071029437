import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";

const EmptyPage = () => {
  const history = useHistory()
  const location = useLocation()
  
  useEffect(()=>{
    history.replace(location.state.nextUrl, {
      ...location.state
    })
  },[])
  
  return (
    <></>
  )
}

export default EmptyPage

