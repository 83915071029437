import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, IconButton, Typography} from "@material-ui/core";
import clsx from "clsx";
import SettingsIcon from '@material-ui/icons/Settings';
import MeasureSelectDialog from "./MeasureSelectDialog";
import MeasureService from "../../../services/MeasureService";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    box: {
      marginRight: 10  
    },
    text: {
        fontSize: '0.875rem',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontWeight: 500,
    },
    icon: {
        color: '#aaa',
        fontSize: 20,
        marginRight: 10,
    },
    selected: {
        '& .selected': {
            color: '#000'    
        },
        
        background: '#F5F9FE',
        borderRadius: 10,
        border: `1px solid #D6E1F1`
    }
}));

export default function MeasureSelectionToggle(props) {
    const classes = useStyles();
    const [open , setOpen] = useState(false)
    const [data, setData] = React.useState(MeasureService.getDefaultMeasureTypesCount({
        checkedTypes: props.measureTypes || []
    }))
    
    useEffect(() => {
        setData(MeasureService.getDefaultMeasureTypesCount({
            checkedTypes: props.measureTypes || []
        }))
    }, [props.measureTypes])
    
    return (
        <React.Fragment>
            <Grid container spacing={3} style={{marginTop:5}}>
                {data.map((data) => {
                    return (
                        <Grid item key={data.key} className={clsx(classes.box, {[classes.selected]: data.checked})}>
                            
                            <Typography className={classes.text}>
                                {data.label}
                            </Typography>
                        </Grid>
                    );
                })}
                {/*<Grid item className={clsx(classes.box)}>*/}
                {/*    <IconButton style={{padding:0}} onClick={() =>{ setOpen(!open)}} >*/}
                {/*        <SettingsIcon />*/}
                {/*    </IconButton>*/}
                {/*</Grid>*/}
            </Grid>
            
            <MeasureSelectDialog
                title={`${props.prescriptionID}회차 설정 변경`}
                open={open}
                mode={'update'}
                defaultValue={props.measureTypes}
                close={() => {
                    setOpen(false)
                }}
                submit={async () => {
                    setOpen(false)
                }}
                buttonName={'설정변경'}
            />
        </React.Fragment>
    );
}