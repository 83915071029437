

export default class SurveyType {
    constructor(props){
    }

    static getName(type) {
        switch(type){
            case 'person':
                return '알반인'
            case 'children':
                return '어린이'
            case 'patient':
                return '환자'
        }
    }
}
