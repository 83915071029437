import React from 'react';
import {Box, Button, Grid, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import ReactAudioPlayer from "react-audio-player";
import DialogActions from "@material-ui/core/DialogActions";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default class TimerDialog extends React.Component {
    timer
    remainSec 
    
    constructor(props) {
        super();

        this.state = {
            open: false,
            onStart: props.onStart,
            onEnd: props.onEnd,
            history: props.history,
            delay: 1000,
            limitTime: 480,
            showType: '1',
            view: 'view1',
            timerSec: props.timerSec,
            title: props.title
        }
        this.remainSec = props.timeSec
    }

    componentWillUnmount() {
        if(this.timer) clearInterval(this.timer)
    }

    open() {
        this.setState({
            ...this.state,
            open: true,
        })
        
        this.timer = setInterval(() => {
            if(0 === this.remainSec){
                clearInterval(this.timer)
                this.close()
                
                if(this.state.onEnd) this.state.onEnd()
            } else {
                this.remainSec--
                
                this.setState({
                    ...this.state,
                    timerSec: this.remainSec,
                })
            }
        }, 1000)
    }

    close() {
        clearInterval(this.timer)
        this.setState({
            ...this.state,
            open: false
        })
    }

    render() {
        
        return (
            <Dialog
                disableBackdropClick
                open={this.state.open}
                maxWidth={'xl'}
                style={{borderRadius: 30}}
                aria-labelledby="responsive-dialog-title"
                className={'innerViewport'}
            >
                <DialogTitle  
                    className="common-dialog-title"
                    style={{textAlign: 'center'}}
                    onClose={() => {
                    this.state.history.goBack()
                }}>
                    {this.state.title}
                </DialogTitle>
                <DialogContent className={'common-dialog-content'} style={{fontWeight: '400'}}>
                    프로그램이 곧 시작 됩니다. <br/>
                    <br/>
                    <span style={{fontSize:'1.5rem'}}>{this.remainSec}</span>
                </DialogContent>
            </Dialog>
        );
    }
}
