import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CustomizedDialogs from "./CustomDialog";


export default function NeedTicketDialog(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    useEffect(() => {
        setOpen(props.open)
    }, [props.open])
    return (
        <CustomizedDialogs
            key={Math.random()}
            open={open}
            title={'이용권 필요'}
            content={
                <React.Fragment>
                    <Typography gutterBottom className={classes.text}>
                        해당 서비스를 사용하기 위해서는 이용권이 필요합니다.
                    </Typography>
                   
                    <Typography gutterBottom className={classes.text}>
                        관리자 또는 담당자에게 문의하세요
                    </Typography>
                </React.Fragment>
            }
      
            submit={async () => {
                setOpen(false)
                if(props.onClose){
                    props.onClose()
                }
            }}
            buttonName={'확인'}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 800,
        margin: 'auto',

        '& .item': {
            padding : '20px 10px'
        }
    },
    back: {
        marginTop: 30,
        '&:hover': {
            backgroundColor: '#053288',
        }
    },
    small: {
        fontSize: 14,
        color: '#999',
    },
    remove: {
        color: '#ccc',
        '&:hover': {
            color: '#666'
        },
        marginLeft: 20
    },
    date: {
        fontSize: 14,
        color: '#999',
        fontFamily: 'Noto Sans KR',
    },
    text:{
        fontFamily: 'Noto Sans KR',
    },
    inline: {
        display: 'inline',
    },
    listRoot: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));
