import React, {useState, useEffect, useCallback} from 'react';
import ProfileService from '@user-service/ProfileService'
import DaumPost from './DauimPost'
import {
    Box, Typography, Grid,
    TextField,
    Button, FormControlLabel, RadioGroup, Radio, MenuItem, Select, Snackbar,
} from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';
import CustomerDataMoveDialog from "./CustomerDataMoveDialog";
import LoadingIndicator from "../../../components/LoadingIndicator";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import AuthProvider from "../../../provider/AuthProvider";
import CustomizedDialogs from "../../../components/CustomDialog";
import MemberService from "../../../services/manage/MemberService";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CssTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: '#333', // Text color
            fontSize:  16
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#ddd', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#ddd', // Solid underline on hover
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#ddd', // Solid underline on focus
        },
    },
})(TextField);



const MemberInfo = (props) => {
    const classes = useStyles();
    const { image, loginID, profileName, code} = props
    
    const [memberType, setMemberType] = useState(props.staffType ? props.staffType : 'normal')
    return (
        <Box border={1} mt={3} p={5} style={{borderColor: '#ddd', borderRadius: 10}}>
            <Grid container>
                <Grid item xs={2}>
                    <Avatar variant="rounded" className={classes.large} alt={profileName} src={require(`@images/profile/${image}`)}/>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant={'subtitle1'}>
                        브레인헬스 센터
                    </Typography>
                    <Typography variant={'h5'}>
                        {profileName} - {loginID}
                    </Typography>
                    <Typography variant={'caption'} style={{color: '#999'}}>
                        {code}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    {AuthProvider.isSuperAdmin() &&
                    <Select
                        style={{marginTop: 15}}
                        variant={'outlined'}
                        value={memberType ? memberType : 'normal'}
                        onChange={(event) => {
                            setMemberType(event.target.value)
                            if (props.onChange) {
                                props.onChange(event.target.value)
                            }
                        }}>
                        <MenuItem value={'normal'}>일반사용자</MenuItem>
                        <MenuItem value={'educator'}>교육사</MenuItem>
                        <MenuItem value={'director'}>학교장</MenuItem>
                        {/*<MenuItem value={'schoolAdmin'}>브레인헬스학교 관리자 </MenuItem>*/}
                    </Select>
                    }

                    {!AuthProvider.isSuperAdmin() &&
                        <Typography style={{marginTop: 35}}>{AuthProvider.getMemberTypeName()}</Typography>
                    }
                </Grid>
            </Grid>

        </Box>
    )
}

const CustomerProfile = (props) => {
    const { profileID } = props
    
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [profile, setProfile] = useState(null)
    const [openDataMove, setOpenDataMove] = useState(false)
    const [openZipcode, setOpenZipcode] = useState(false)
    const [open, setOpen] = useState(false)
    const [openDeleteAccount, setOpenDeleteAccount] = useState(false)
    
    const classes = useStyles();

    const fetchProfile = useCallback(async () => {
        if(!profileID) return
        const res = await ProfileService.getProfile(profileID, true)
        
        setTimeout(function(){
            setProfile(res.data)    
        }, 600)
        
    }, [profileID])

    const closeSnackbar = () => {
        setOpenSnackbar(false)
    }
    
    useEffect(()=>{
        fetchProfile().then()    
    },[fetchProfile])
    
    //프로필 저장
    const saveProfile = async () => {
        await ProfileService.updateProfile(profile)
        setOpenSnackbar(true)
    }

    if(!profile) return <LoadingIndicator />
    
    return (
        <React.Fragment>
            
            <Box mt={5}>
                <Box  style={{margin: '0 auto', width: 1024}}>
                    <Box mt={3} mb={3}>
                        <MemberInfo {...profile}
                            onChange={(newValue) => {
                                setProfile({
                                    ...profile,
                                    memberType: newValue
                                })
                            }}
                        />
                    </Box>
                    <table className={classes.table}>
                        <thead>
                        
                        </thead>
                        <tbody>
                            <tr>
                                <th>이름</th>
                                <td>
                                    <CssTextField
                                        fullWidth
                                        defaultValue={profile.profileName}
                                        onChange={(event)=>{
                                            setProfile({
                                                ...profile,
                                                profileName: event.target.value
                                            })
                                        }}
                                    />
                                </td>
                                <th>성별</th>
                                <td>
                                    <RadioGroup
                                        row
                                        aria-label="position"
                                        name="position"
                                        value={profile.gender ? profile.gender : 'male'}
                                        style={{borderColor: '#fff'}}
                                        onChange={(event)=>{
                                            setProfile({
                                                ...profile,
                                                gender: event.target.value
                                            })
                                        }}>

                                        <FormControlLabel
                                            value="male"
                                            control={<Radio color="primary" />}
                                            label="남성"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="female"
                                            control={<Radio color="primary" />}
                                            label="여성"
                                            labelPlacement="end"
                                        />

                                    </RadioGroup>
                                </td>
                                <th>생년월일</th>
                                <td>
                                    <CssTextField
                                        defaultValue={profile.birthDate}
                                        style={{width: 100}}
                                        onChange={(event)=>{
                                            setProfile({
                                                ...profile,
                                                birthDate: event.target.value
                                            })
                                        }}
                                    />
                                    <div style={{display:"inline-block", paddingLeft:10, paddingRight: 5, paddingTop: 10}}>
                                     ( 만
                                    </div>
                                    <CssTextField
                                        defaultValue={profile.koreanAge || 0}
                                        style={{width: 30, textAlign: 'right'}}
                                        inputProps={{
                                            maxLength: 3
                                        }}
                                        onChange={(event)=>{
                                            setProfile({
                                                ...profile,
                                                koreanAge: event.target.value
                                            })
                                        }}
                                    />
                                    <div style={{display:"inline-block", paddingRight: 10, paddingTop: 10}}>
                                        세 )
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>연락처</th>
                                <td>
                                    <CssTextField
                                        fullWidth
                                        defaultValue={profile.cellphone}
                                        onChange={(event)=>{
                                            setProfile({
                                                ...profile,
                                                cellphone: event.target.value
                                            })
                                        }}
                                    />
                                </td>
                                <th>EMAIL</th>
                                <td colSpan={4}>
                                    <CssTextField
                                        fullWidth
                                        defaultValue={profile.email}
                                        onChange={(event)=>{
                                            setProfile({
                                                ...profile,
                                                email: event.target.value
                                            })
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>주소</th>
                                <td colSpan={5}>
                                    <DaumPost open={openZipcode}
                                        onClose={(address) => {
                                            setOpenZipcode(false)
                                            
                                            if(address && address.zipcode){
                                                setProfile({
                                                    ...profile,
                                                    zipcode: address.zipcode,
                                                    address: address.address,
                                                    addressDetail: address.addressDetail
                                                })
                                            }
                                            
                                        }}          
                                    />
                                    <Grid container spacing={1} style={{width: '100%'}}>
                                        <Grid item xs={12}>
                                            <CssTextField
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                                onClick={()=>{ setOpenZipcode(true) }}
                                                defaultValue={profile.zipcode}
                                                value={(profile.zipcode || '')}
                                                placeholder={'우편번호'}
                                                onChange={(event)=>{
                                                    setProfile({
                                                        ...profile,
                                                        zipcode: event.target.value
                                                    })
                                                }}
                                            />
                                            <Button variant={'outlined'} onClick={()=>{ setOpenZipcode(true) }}>우편번호</Button>
                                        </Grid>
                                        <Grid item  xs={6}>
                                            <CssTextField
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                                onClick={()=>{ setOpenZipcode(true) }}
                                                defaultValue={profile.address}
                                                value={profile.address}
                                                fullWidth
                                                placeholder={'기본주소'}
                                                onChange={(event)=>{
                                                    setProfile({
                                                        ...profile,
                                                        address: event.target.value
                                                    })
                                                }}
                                            />        
                                        </Grid>
                                        <Grid item xs={4}>
                                            <CssTextField
                                                fullWidth
                                                defaultValue={profile.addressDetail}
                                                placeholder={'상세주소'}
                                                onChange={(event)=>{
                                                    setProfile({
                                                        ...profile,
                                                        addressDetail: event.target.value
                                                    })
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </td>
                            </tr>
                            <tr>
                                <th>신장</th>
                                <td>
                                    <CssTextField
                                        fullWidth
                                        defaultValue={profile.personHeight}
                                        inputProps={{
                                            maxLength: 10
                                        }}
                                        onChange={(event)=>{
                                            setProfile({
                                                ...profile,
                                                personHeight: event.target.value
                                            })
                                        }}
                                    />
                                </td>
                                <th>체중</th>
                                <td>
                                    <CssTextField
                                        fullWidth
                                        defaultValue={profile.weight}
                                        inputProps={{
                                            maxLength: 10
                                        }}
                                        onChange={(event)=>{
                                            setProfile({
                                                ...profile,
                                                weight: event.target.value
                                            })
                                        }}
                                    />
                                </td>
                                <th>혈액형</th>
                                <td>
                                    <Select
                                        value={ profile.bloodType ? profile.bloodType : 'DEFAULT'}
                                        onChange={(event)=>{
                                            setProfile({
                                                ...profile,
                                                bloodType: event.target.value
                                            })
                                        }}>
                                        <MenuItem value={'DEFAULT'}>선택하세요</MenuItem>
                                        <MenuItem value={'A'}>A</MenuItem>
                                        <MenuItem value={'B'}>B</MenuItem>
                                        <MenuItem value={'AB'}>AB</MenuItem>
                                        <MenuItem value={'O'}>O</MenuItem>
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <th>시력</th>
                                <td>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <CssTextField
                                                defaultValue={profile.vision ? profile.vision[0] : 0}
                                                label={'좌'}
                                                style={{width: 120}}
                                                inputProps={{
                                                    maxLength: 10
                                                }}
                                                onChange={(event)=>{
                                                    setProfile({
                                                        ...profile,
                                                        vision: [event.target.value, profile.vision ? profile.vision[1] : 0]
                                                    })
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CssTextField
                                                defaultValue={profile.vision ? profile.vision[1] : 0}
                                                label={'우'}
                                                style={{width: 120}}
                                                inputProps={{
                                                    maxLength: 10
                                                }}
                                                onChange={(event)=>{
                                                    setProfile({
                                                        ...profile,
                                                        vision: [profile.vision ? profile.vision[0] : 0, event.target.value]
                                                    })
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    
                                    
                                </td>
                                <th>약물복용</th>
                                <td>
                                    <RadioGroup
                                        row
                                        aria-label="position"
                                        name="position"
                                        value={profile.takePills ? profile.takePills : 'no'}
                                        style={{borderColor: '#fff'}}
                                        onChange={(event)=>{
                                            setProfile({
                                                ...profile,
                                                takePills: event.target.value
                                            })
                                        }}>

                                        <FormControlLabel
                                            value="no"
                                            control={<Radio color="primary" />}
                                            label="없음"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio color="primary" />}
                                            label="복용중"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </td>
                            </tr>
                            <tr>
                                <th>혈당</th>
                                <td>
                                    <CssTextField
                                        fullWidth
                                        defaultValue={profile.bloodSugar}
                                        inputProps={{
                                            maxLength: 10
                                        }}
                                        onChange={(event)=>{
                                            setProfile({
                                                ...profile,
                                                bloodSugar: event.target.value
                                            })
                                        }}
                                    />
                                </td>
                                <th>혈압</th>
                                <td>
                                    <CssTextField
                                        fullWidth
                                        defaultValue={profile.bloodPressure}
                                        inputProps={{
                                            maxLength: 10
                                        }}
                                        onChange={(event)=>{
                                            setProfile({
                                                ...profile,
                                                bloodPressure: event.target.value
                                            })
                                        }}
                                    />
                                </td>
                                <th>주사용</th>
                                <td>
                                    <RadioGroup
                                        row
                                        aria-label="position"
                                        name="position"
                                        value={profile.useHand ? profile.useHand : 'right'}
                                        style={{borderColor: '#fff'}}
                                        onChange={(event)=>{
                                            setProfile({
                                                ...profile,
                                                useHand: event.target.value
                                            })
                                        }}>
        
                                        <FormControlLabel
                                            value="left"
                                            control={<Radio color="primary" />}
                                            label="왼손"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="right"
                                            control={<Radio color="primary" />}
                                            label="오른손"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="both"
                                            control={<Radio color="primary" />}
                                            label="양손"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Box mt={5}>
                        <Grid container spacing={1} justify={'center'} style={{width: '100%'}}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    className="basic delete"
                                    onClick={() => {
                                        setOpenDeleteAccount(true)
                                    }}>계정 삭제</Button>
                            </Grid>
                            {/*<Grid item>*/}
                            {/*    <Button*/}
                            {/*        variant="contained"*/}
                            {/*        className="basic delete"*/}
                            {/*        onClick={() => {*/}
                            {/*            setOpen(true)*/}
                            {/*        }}>프로필 삭제</Button>*/}
                            {/*</Grid>*/}
                            <Grid item>
                                <Button variant="contained" className="basic active" onClick={saveProfile} >저장</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>c

            <CustomizedDialogs
                key={Math.random()}
                open={open}
                title={'프로필 삭제'}
                content={
                    <React.Fragment>
                        <Typography gutterBottom className={classes.text}>
                            <b>{profile.profileName}</b><br/>
                            선택하신 프로필을 삭제하시겠습니까?
                        </Typography>
                        <br/>
                        <Typography gutterBottom className={classes.text}>
                            <span style={{color:'red'}}>
                            측정 기록, 처방기록, 훈련 기록등 사용자의 모든 기록이 삭제됩니다.
                                <br/>
                            삭제하시겠습니까?
                            </span>

                            <br/>
                            <br/>
                            <b>삭제된 프로필 정보는 복구 할 수 없습니다</b>
                        </Typography>
                    </React.Fragment>
                }
                data={profile}
                close={() => {
                    setOpen(false)
                }}
                submit={async (profile) => {
                    const isSuccess = await ProfileService.deleteProfile(profile.id)
                    if(!isSuccess){
                        setOpen(false)
                        alert('프로필 삭제에 실패했습니다. 잠시후에 다시 시도해보세요')
                        
                    } else {
                        setOpen(false)
                    }
                }}
                buttonName={'삭제'}
            />


            <CustomizedDialogs
                key={Math.random()}
                open={openDeleteAccount}
                title={'계정 삭제'}
                content={
                    <React.Fragment>
                        <Typography gutterBottom className={classes.text}>
                            <b>{profile.loginID}</b><br/>
                            선택하신 계정을 삭제하시겠습니까?
                        </Typography>
                        <br/>
                        <Typography gutterBottom className={classes.text}>
                            <span style={{color:'red'}}>
                            측정 기록, 처방기록, 훈련 기록등 사용자의 모든 기록이 삭제됩니다.
                                <br/>
                            삭제하시겠습니까?
                            </span>

                            <br/>
                            <br/>
                            <b>삭제된 더 이상 로그인이 할 수 없습니다.</b>
                        </Typography>
                    </React.Fragment>
                }
                data={profile}
                close={() => {
                    setOpenDeleteAccount(false)
                }}
                submit={async (profile) => {
                    await MemberService.deleteMember(profile.id)
                    setOpenDeleteAccount(false)
                    
                    if(props.onDeleted) {
                        props.onDeleted()
                    }
                }}
                buttonName={'계정 삭제'}
            />
            <CustomerDataMoveDialog
                open={openDataMove}
                profile={profile}
                onClose={() => {
                    setOpenDataMove(false)
                }} />

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success">
                    저장되었습니다
                </Alert>
            </Snackbar>
        </React.Fragment>

    )
}

const useStyles = makeStyles(theme => ({
    table: {
        width: '100%',
        maxWidth: 1024,
        borderSpacing: '5px',
        borderCollapse: 'inherit',
        
        '& th': {
            padding: 3,
            fontSize: 16,
            fontWeight: 'normal',
            color:'#333',
            backgroundColor: '#efefef'
        },
        '& td': {
            padding: 3,
            fontSize: '0.8rem',
        }
    },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),

        // backgroundColor: 'transparent',
        '& img': {
            width: '100%'
        }
    },

}));



export default CustomerProfile
