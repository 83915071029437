import React from "react";


export default class TestService {

    static getColor(measureType, programID ) {
        if('measure' === measureType) {
            return '#004598'
        }
        if('develop' === measureType) {
            if(-1 < [16,32,33].indexOf(programID)) {
                return '#26A0A3'
            }
            if(-1 < [19,34,35].indexOf(programID)) {
                return '#035031'
            }
            if(21 === programID) {
                return '#3E540D'
            }
            if(17 === programID) {
                return '#237C3E'
            }
            if(18 === programID) {
                return '#42279B'
            }
        }
    }

    static getColorName(measureType, programID ) {
        if('measure' === measureType) {
            return 'test-1-color'
        }
        if('develop' === measureType || 'train' === measureType) {

            if(-1 < [16, 48].indexOf(programID)) {
                return 'test-2-color'
            }
            if(-1 < [32].indexOf(programID)) {
                return 'test-32-color'
            }
            if(-1 < [33].indexOf(programID)) {
                return 'test-33-color'
            }
            if(-1 < [19,34,35].indexOf(programID)) {
                return 'test-3-color'
            }
            if(21 === programID) {
                return 'test-4-color'
            }
            if(17 === programID) {
                return 'test-5-color'
            }
            if(18 === programID) {
                return 'test-6-color'
            }
        }
    }

    /**
     *
     * @param testName
     * @param view, 숫자(view1),그림(view2),문자(view3)
     * @param showType, 단반향(1), 양방향(2), 문자(3)
     * @param measureType
     */
    static getMemoryExplainText(testName, showType, view, measureType) {

        if ('view1' === view && '1' === showType) return this.getMemoryShow1View1(testName, measureType)
        if ('view3' === view && '1' === showType) return this.getMemoryShow1View3(testName, measureType)
        if ('view4' === view && '1' === showType) return this.getMemoryShow1View4(testName, measureType)
        if ('view5' === view && '1' === showType) return this.getMemoryShow1View4(testName, measureType)
        if ('view6' === view && '1' === showType) return this.getMemoryShow1View4(testName, measureType)
        if ('view7' === view && '1' === showType) return this.getMemoryShow1View4(testName, measureType)
        if ('view8' === view && '2' === showType) return this.getMemoryShow2View8(testName, measureType)
        if ('view9' === view && '2' === showType) return this.getMemoryShow2View9(testName, measureType)

        if ('view1' === view && '2' === showType) return this.getMemoryShow2View1(testName, measureType)
        if ('view3' === view && '2' === showType) return this.getMemoryShow2View3(testName, measureType)
        if ('view4' === view && '2' === showType) return this.getMemoryShow2View4(testName, measureType)
        if ('view5' === view && '2' === showType) return this.getMemoryShow2View4(testName, measureType)
        if ('view6' === view && '2' === showType) return this.getMemoryShow2View6(testName, measureType)
        if ('view7' === view && '2' === showType) return this.getMemoryShow2View7(testName, measureType)

        if ('view2' === view && '3' === showType) return this.getMemoryShow3View2(testName, measureType)

    }

    /**
     * 기억력 좌뇌
     * @param testName
     * @param measureType
     */
    static getMemoryShow1View3(testName, measureType){
        if('develop' === measureType){
            return {
                title: `${testName} 증강 프로그램`,
                text1Title: `${testName} 증강 프로그램은?`,
                text1:  <ul className={'box'}>
                    <li>최근 받은 정보를 단기기억에 저장하는 반복훈련</li>
                    <li>우뇌의 인지 속도 처리 능력 증진훈련</li>
                    <li>우뇌 발달의 수행 능력 증진훈련</li>
                    <li>인지, 단기기억 저장과 인출 재생 반복훈련</li>
                    <li>기억 순서와 과정 로드맵 형성 발달훈련</li>
                </ul>,
                text2Title: `${testName} 증강 프로그램 평가 항목`,
                text2: <ul className={'box'}>
                    <li>주의&집중 기능과 순서기억 수행 능력평가</li>
                    <li>인지별 뇌의 정보 속도 처리 능력평가</li>
                    <li>우뇌 수리능력, 두뇌 발달 수행 능력평가</li>
                    <li>우뇌의 단기기억 저장 능력평가</li>
                    <li>우뇌의 단기기억 인출 재생 능력평가</li>
                    <li>기억 순서와 과정 로드맵 형성 수행 능력평가</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>기억력 증강 두뇌계발 과정은 1개 ~ 10개까지 높여갑니다.</li>
                    <li>설정한 시간과 제한 시간은 설정에 따라 순서대로 시작합니다.</li>
                    <li>화면에 나타난 숫자를 단계별로 맞추면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율을 높여갑니다.</li>
                    <li>개인별 뇌기능 상태에 따라 기초, 중급, 고급을 선택할 수 있습니다.</li>
                </ul>
            }
        } else {
            return {
                title: `${testName} 검사`,
                text1Title: `${testName} 검사 항목`,
                text1:  <ul className={'box'}>
                    <li>우뇌에서 인지한 정보를 단기 기억하는 수행 능력</li>
                    <li>연령별 뇌의 계산(수리) 속도 처리 능력</li>
                    <li>우뇌 발달의 수행 능력</li>
                    <li>단기기억 저장 능력, 인출 재생 능력</li>
                    <li>기억 순서와 과정 로드맵 형성 수행 능력</li>
                </ul>,
                text2Title: `${testName}을 증강해야 할 대상`,
                text2: <ul className={'box'}>
                    <li>물건을 둔 장소를 기억 못하는 건망증이 있는 사람</li>
                    <li>날짜, 요일. 약속 등, 잘 잃어버리는 사람</li>
                    <li>머릿속에서는 생각이 떠오르는데 이름을 기억하지 못하는사람</li>
                    <li>머리가 몽롱하고 기억력이 저하 된 사람</li>
                    <li>계산이 잘 안되고 순서대로 기억 재생이 잘 안되는 사람</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>검사 과정은 1개 ~ 10개 과정을 검사합니다.</li>
                    <li>설정 시간에 따라 제한 시간 안에 검사가 진행됩니다.</li>
                    <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율이 기록됩니다.</li>
                </ul>
            }
        }
    }


    /**
     * 기억력 우뇌
     * @param testName
     * @param measureType
     */
    static getMemoryShow1View1(testName, measureType){
        if('develop' === measureType){
            return {
                title: `${testName} 증강 프로그램`,
                text1Title: `${testName} 증강 프로그램은?`,
                text1:  <ul className={'box'}>
                    <li>최근 받은 정보를 단기기억에 저장하는 반복훈련</li>
                    <li>뇌의 계산(수리) 속도 처리 능력 증진훈련</li>
                    <li>좌뇌 발달의 수행 능력 증진훈련</li>
                    <li>인지, 단기기억 저장과 인출 재생 반복훈련</li>
                    <li>기억 순서와 과정 로드맵 형성 발달훈련</li>
                </ul>,
                text2Title: `${testName} 증강 프로그램 평가 항목`,
                text2: <ul className={'box'}>
                    <li>주의&집중 기능과 순서기억 수행 능력평가</li>
                    <li>인지별 뇌의 정보 속도 처리 능력평가</li>
                    <li>좌뇌 수리능력, 두뇌 발달 수행 능력평가</li>
                    <li>좌뇌의 단기기억 저장 능력평가</li>
                    <li>좌뇌의 단기기억 인출 재생 능력평가</li>
                    <li>기억 순서와 과정 로드맵 형성 수행 능력평가</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>기억력 증강 두뇌계발 과정은 1개 ~ 10개까지 높여갑니다.</li>
                    <li>설정한 시간과 제한 시간은 설정에 따라 순서대로 시작합니다.</li>
                    <li>화면에 나타난 숫자를 단계별로 맞추면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율을 높여갑니다.</li>
                    <li>개인별 뇌기능 상태에 따라 기초, 중급, 고급을 선택할 수 있습니다.</li>
                </ul>
            }
        } else {
            return {
                title: `${testName} 검사`,
                text1Title: `${testName} 검사 항목`,
                text1:  <ul className={'box'}>
                    <li>좌뇌에서 인지한 정보를 단기 기억하는 수행 능력</li>
                    <li>연령별 뇌의 계산(수리) 속도 처리 능력</li>
                    <li>좌뇌 발달의 수행 능력</li>
                    <li>단기기억 저장 능력, 인출 재생 능력</li>
                    <li>기억 순서와 과정 로드맵 형성 수행 능력</li>
                </ul>,
                text2Title: `${testName}을 증강해야 할 대상`,
                text2: <ul className={'box'}>
                    <li>물건을 둔 장소를 기억 못하는 건망증이 있는 사람</li>
                    <li>날짜, 요일, 약속 등을 잘 잊어버리는 사람</li>
                    <li>머릿속에서는 생각이 떠오르는데 이름을 기억하지 못하는 사람</li>
                    <li>머리가 몽롱하고 기억력이 저하된 사람</li>
                    <li>계산이 잘 안되고 ,순서대로 기억 재생이 잘 안 되는 사람</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>검사 과정은 1개 ~ 10개 과정을 검사합니다.</li>
                    <li>설정 시간에 따라 제한 시간 안에 검사가 진행됩니다.</li>
                    <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율이 기록됩니다.</li>
                </ul>
            }
        }
    }


    /**
     * 기억력 균형
     * @param testName
     * @param measureType
     */
    static getMemoryShow1View4(testName, measureType){
        if('develop' === measureType){
            return {
                title: `${testName} 증강 프로그램`,
                text1Title: `${testName} 증강 프로그램은?`,
                text1:  <ul className={'box'}>
                    <li>인지력과 기억력을 높이는 두뇌계발 훈련</li>
                    <li>작업기억과 기억 재생 능력 발달 훈련</li>
                    <li>단기기억의 신경가소성 뇌기능 증진 반복 훈련</li>
                    <li>뇌기능 재충진과 균형조절 향상 훈련</li>
                    <li>기억 인출, 연합, 통합 능력 향상 훈련</li>
                </ul>,
                text2Title: `${testName} 증강 프로그램 평가 항목`,
                text2: <ul className={'box'}>
                    <li>좌우뇌 인지 기능, 작업기억 능력평가</li>
                    <li>연령별 뇌의 계산 속도 정보처리 능력평가</li>
                    <li>좌우뇌의 균형조절 수행 능력평가</li>
                    <li>좌우뇌 단기기억 반복 증강 저장 능력평가</li>
                    <li>좌우뇌 단기기억 인출 재생 능력평가</li>
                    <li>기억 순서와 로드맵 형성과정 수행 능력평가</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>검사 과정은 1개 ~ 10개 과정을 검사합니다.</li>
                    <li>설정 시간에 따라 제한 시간 안에 검사가 진행됩니다.</li>
                    <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율이 기록됩니다.</li>
                    <li>개인별 뇌기능 상태에 따라 기초, 중급, 고급을 선택할 수 있습니다.</li>
                </ul>
            }
        } else {
            return {
                title: `${testName} 검사`,
                text1Title: `${testName} 검사 항목`,
                text1:  <ul className={'box'}>
                    <li>좌우뇌 인지 기능과 작업기억 수행 능력</li>
                    <li>연령별 뇌의 계산 속도 처리 능력</li>
                    <li>좌우뇌 발달의 수행 능력</li>
                    <li>좌우뇌 단기기억 저장 능력 평가</li>
                    <li>좌우뇌 단기기억 인출 재생 능력</li>
                    <li>기억 순서와 로드맵 형성과정 수행 능력</li>
                </ul>,
                text2Title: `${testName}을 증강해야 할 대상`,
                text2: <ul className={'box'}>
                    <li>물건을 둔 장소를 기억 못하는 건망증이 있는 사람</li>
                    <li>날짜, 요일, 약속 등 잘 잊어버리는 사람</li>
                    <li>머릿속에서 생각은 떠오르는데 이름을 기억하지 못하는 사람</li>
                    <li>감정 조율 균형이 불안해지면 기억력이 뚝 떨어지는 사람</li>
                    <li>머리가 몽롱하고 기억력이 저하 된 사람</li>
                    <li>계산이 잘 안되고, 순서대로 기억 재생이 잘 안되는 사람</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>검사 과정은 1개 ~ 10개 과정을 검사합니다.</li>
                    <li>설정 시간에 따라 제한 시간 안에 검사가 진행됩니다.</li>
                    <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율이 기록됩니다.</li>
                </ul>
            }
        }
    }


    /**
     * 주의력
     * @param testName
     * @param measureType
     */
    static getMemoryShow2View8(testName, measureType){
        if('develop' === measureType){
            return {
                title: `${testName} 증강 프로그램`,
                text1Title: `${testName} 증강 프로그램은?`,
                text1:  <ul className={'box'}>
                    <li>한 가지 일에 마음과 정신을 모아 집중하는 능력</li>
                    <li>중요한 자극에 집중하고 선택하는 능력</li>
                    <li>동기부여, 정서적인 교류를 원만하게 하는 능력</li>
                    <li>과제나 목표에 초점을 맞추어 수행하는 능력</li>
                    <li>다양한 정보의 차이점을 명확하게 구분하는 관찰 능력</li>
                </ul>,
                text2Title: `${testName} 증강 프로그램 평가 항목`,
                text2: <ul className={'box'}>
                    <li>주어진 일에 빠르게 대처 방법이 서지 않는 사람</li>
                    <li>사회성이 부족하고 새로운 일에 긴장과 불안이 많은 사람</li>
                    <li>정서적 교류와 이해 능력이 저하된 사람</li>
                    <li>동기부여가 잘 안되고 흥이 나지 않는 사람</li>
                    <li>다양한 정보의 차이점을 구분하는 관찰 능력이 저하된 사람</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>검사과정은 1개 ~ 10개 과정을 검사합니다.</li>
                    <li>설정 시간에 따라 제한 시간 안에 검사가 진행됩니다.</li>
                    <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율이 기록됩니다.</li>
                    <li>개인별 뇌기능 상태에 따라 기초, 중급, 고급을 선택할 수 있습니다.</li>
                </ul>
            }
        } else {
            return {
                title: `${testName} 검사`,
                text1Title: `${testName} 검사 항목`,
                text1:  <ul className={'box'}>
                    <li>한 가지 일에 마음과 정신을 모아 집중하는 능력</li>
                    <li>중요한 자극에 집중하고 선택하는 능력</li>
                    <li>동기부여, 정서적인 교류를 원만하게 하는 능력</li>
                    <li>과제나 목표에 초점을 맞추어 수행하는 능력</li>
                    <li>다양한 정보의 차이점을 명확하게 구분하는 관찰 능력</li>
                </ul>,
                text2Title: `${testName}을 증강해야 할 대상`,
                text2: <ul className={'box'}>
                    <li>주어진 일에 어떻게 대처해야할지 빠른 판단이 서지 않는 분</li>
                    <li>사회성이 부족하고 새로운 일에 긴장과 불안이 많은 사람</li>
                    <li>정서적 교류와 이해 능력이 저하된 사람</li>
                    <li>동기부여가 잘 안되고 흥이 나지 않는 사람</li>
                    <li>다양한 정보의 차이점을 구분하는 관찰 능력이 저하된 사람</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>검사과정은 1개 ~ 10개 과정을 검사합니다.</li>
                    <li>설정 시간에 따라 제한 시간 안에 검사가 진행됩니다.</li>
                    <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10개 100%까지 완성율이 기록됩니다.</li>
                </ul>
            }
        }
    }

    /**
     * 집중력
     * @param testName
     * @param measureType
     */
    static getMemoryShow2View9(testName, measureType){
        if('develop' === measureType){
            return {
                title: `${testName} 증강 프로그램`,
                text1Title: `${testName} 증강 프로그램은?`,
                text1:  <ul className={'box'}>
                    <li>한 곳에 몰입하여 업무를 수행할 수 있는 능력</li>
                    <li>주어진 과제의 핵심 정보를 이해하여 처리할 수 있는 능력</li>
                    <li>들어오는 정보를 잘 분류하여 집중할 수 있는 능력</li>
                    <li>다양한 정보의 차이점을 명확하게 관찰하고 판단하는 능력</li>
                    <li>핵심을 정리하여 특성을 명확하게 판단하여 수행하는 능력</li>
                </ul>,
                text2Title: `${testName} 증강 프로그램 평가 항목`,
                text2: <ul className={'box'}>
                    <li>한 곳에 몰입하여 집중하는 시간이 짧은 사람</li>
                    <li>새로운 일에 빠르게 적응을 못하고 긴장하는 사람</li>
                    <li>주어진 과제에 이해력이 부족하여 빠른 판단이 서지 않는 사람</li>
                    <li>잡념이 많고 쉽게 실증내며, 효율적인 학습 능력이 부족한 사람</li>
                    <li>조금 복잡한 일을 생각하는 것도 싫고 쉽게 포기하고 싶은 사람</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>검사과정은 1개 ~ 10개 과정을 검사합니다.</li>
                    <li>설정 시간에 따라 제한 시간 안에 검사가 진행됩니다.</li>
                    <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율이 기록됩니다.</li>
                    <li>개인별 뇌기능 상태에 따라 기초, 중급, 고급을 선택할 수 있습니다.</li>
                </ul>
            }
        } else {
            return {
                title: `${testName} 검사`,
                text1Title: `${testName} 검사 항목`,
                text1:  <ul className={'box'}>
                    <li>한 곳에 몰입하여 업무를 수행할 수 있는 능력</li>
                    <li>주어진 과제의 핵심 정보를 이해하여 처리할 수 있는 능력</li>
                    <li>들어오는 정보를 잘 분류하여 집중할 수 있는 능력</li>
                    <li>다양한 정보의 차이점을 명확하게 관찰하고 판단하는 능력</li>
                    <li>핵심을 정리하여 특성을 명확하게 판단하여 수행하는 능력</li>
                </ul>,
                text2Title: `${testName}을 증강해야 할 대상`,
                text2: <ul className={'box'}>
                    <li>한 곳에 몰입하여 집중하는 시간이 짧은 사람</li>
                    <li>새로운 일에 빠른 적응을 못하고 긴장하게 되는 사람</li>
                    <li>주어진 과제에 이해력이 부족하여 빠른 판단이 서지 않는 분</li>
                    <li>잡념이 많고 쉽게 실증내며, 효율적인 학습능력이 부족한 분</li>
                    <li>조금 복잡한 일을 생각하는 것도 싫고 쉽게 포기하고 싶은 사람</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>검사과정은 1개 ~ 10개 과정을 양방향 검사합니다.</li>
                    <li>설정 시간에 따라 제한 시간 안에 검사가 진행됩니다.</li>
                    <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10개 100%까지 완성율이 기록됩니다.</li>
                </ul>
            }
        }
    }

    /**
     * 기억력 좌뇌
     * @param testName
     * @param measureType
     */
    static getMemoryShow2View3(testName, measureType){
        if('develop' === measureType){
            return {
                title: `${testName} 증강 프로그램`,
                text1Title: `${testName} 증강 프로그램은?`,
                text1:  <ul className={'box'}>
                    <li>최근 받은 정보를 입출력하여 장기기억에 저장하는 반복훈련</li>
                    <li>뇌의 다양한 정보 양방향 속도 처리능력 증진시키는 훈련</li>
                    <li>우뇌를 두뇌 발달시켜 정보 입출력 양방향 수행 능력 증진훈련</li>
                    <li>인지, 단기기억, 장기기억 저장과 인출 재생 반복훈련</li>
                    <li>기억 순서와 과정 로드맵 신경가소성 발달훈련</li>
                </ul>,
                text2Title: `${testName} 증강 프로그램 평가 항목`,
                text2: <ul className={'box'}>
                    <li>주의집중 기능과 순서, 절차기억 수행 능력평가</li>
                    <li>인지별 뇌의 정보 속도 처리 능력평가</li>
                    <li>우뇌 수리능력, 두뇌 발달 수행 능력평가</li>
                    <li>우뇌의 장기기억 양방향 입출력 저장 능력평가</li>
                    <li>우뇌의 장기기억 양방향 인출 재생 능력평가</li>
                    <li>장기기억 입출력 과정 로드맵 형성 수행 능력평가</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>증강 과정은 1개 ~ 10개까지 높여갑니다.</li>
                    <li>설정한 시간과 제한 시간은 설정에 따라 순서대로 시작합니다.</li>
                    <li>화면에 나타난 숫자를 단계별로 맞추면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율을 높여갑니다.</li>
                    <li>개인별 뇌기능 상태에 따라 기초, 중급, 고급을 선택할 수 있습니다.</li>
                </ul>
            }
        } else {
            return {
                title: `${testName} 검사`,
                text1Title: `${testName} 검사 항목`,
                text1:  <ul className={'box'}>
                    <li>우뇌 인지기능과 뇌기능정보 입출력 기억 수행 능력</li>
                    <li>연령별 뇌의 양방향 정보 처리 능력</li>
                    <li>절차기억, 단기기억 장기기억 입출력 양방향 능력평가</li>
                    <li>단기기억, 장기기억 저장 능력, 인출 재생 능력</li>
                    <li>기억 순서와 과정 통합 양방향 수행 능력</li>
                </ul>,
                text2Title: `${testName}을 증강해야 할 대상`,
                text2: <ul className={'box'}>
                    <li>건망증, 어디에 물건을 두었는지 기억을 못 하는 사람</li>
                    <li>날짜, 요일. 약속 등, 잘 잃어버리는 사람</li>
                    <li>머릿속에서는 생각이 떠오르는데 이름을 기억하지 못하는 분</li>
                    <li>머리가 몽롱하고 기억력이 저하 된 사람</li>
                    <li>계산이 잘 안되어 순서대로 기억 재생이 잘 안되는 사람</li>
                    <li>한곳에 주의를 기우리지 못해 집중력, 기억력이 저하된 사람</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>증강 과정은 1개 ~ 10개까지 높여갑니다.</li>
                    <li>설정한 시간과 제한 시간은 설정에 따라 순서대로 시작합니다.</li>
                    <li>화면에 나타난 숫자를 단계별로 맞추면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율을 높여갑니다.</li>
                </ul>
            }
        }
    }


    /**
     * 기억력 우뇌
     * @param testName
     * @param measureType
     */
    static getMemoryShow2View1(testName, measureType){
        if('develop' === measureType){
            return {
                title: `${testName} 증강 프로그램`,
                text1Title: `${testName} 증강 프로그램은?`,
                text1:  <ul className={'box'}>
                    <li>최근 받은 정보를 입출력하여 장기기억에 저장하는 반복훈련</li>
                    <li>뇌의 계산(수리) 속도 양방향 처리 능력 증진시키는 훈련</li>
                    <li>좌뇌를 두뇌 발달시켜 정보 입출력 양방향 수행 능력 증진훈련</li>
                    <li>인지, 단기기억, 장기기억 저장과 인출 재생 반복훈련</li>
                    <li>기억 순서와 과정 로드맵 신경가소성 발달훈련</li>
                </ul>,
                text2Title: `${testName} 증강 프로그램 평가 항목`,
                text2: <ul className={'box'}>
                    <li>주의집중 기능과 순서기억 수행 능력평가</li>
                    <li>인지별 뇌의 정보 속도 처리 능력평가</li>
                    <li>좌뇌 수리능력, 두뇌 발달 수행 능력평가</li>
                    <li>좌뇌의 장기기억 양방향 입출력 저장 능력평가</li>
                    <li>좌뇌의 장기기억 양방향 인출 재생 능력평가</li>
                    <li>장기기억 입출력 과정 로드맵 형성 수행 능력평가</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>증강 과정은 1개 ~ 10개까지 높여갑니다.</li>
                    <li>설정한 시간과 제한 시간은 설정에 따라 순서대로 시작합니다.</li>
                    <li>화면에 나타난 숫자를 단계별로 맞추면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율을 높여갑니다.</li>
                    <li>개인별 뇌기능 상태에 따라 기초, 중급, 고급을 선택할 수 있습니다.</li>
                </ul>
            }
        } else {
            return {
                title: `${testName} 검사`,
                text1Title: `${testName} 검사 항목`,
                text1:  <ul className={'box'}>
                    <li>좌뇌 인지기능과 단기정보 입출력 기억 수행 능력</li>
                    <li>연령별 뇌의 양방향 정보 처리 능력</li>
                    <li>절차기억, 단기기억 입출력 능력평가</li>
                    <li>단기기억, 장기기억 저장 능력, 인출 재생 능력</li>
                    <li>기억 순서와 과정 통합 수행 능력</li>
                </ul>,
                text2Title: `${testName}을 증강해야 할 대상`,
                text2: <ul className={'box'}>
                    <li>건망증, 어디에 물건을 두었는지 기억을 못 하는 사람</li>
                    <li>날짜, 요일. 약속 등, 잘 잃어버리는 사람</li>
                    <li>머릿속에서는 생각이 떠오르는데 이름을 기억하지 못하는 분</li>
                    <li>머리가 몽롱하고 기억력이 저하 된 사람</li>
                    <li>계산이 잘 안되어 순서대로 기억 재생이 잘 안되는 사람</li>
                    <li>한곳에 주의를 기우리지 못해 집중력, 기억력이 저하된 사람</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>검사과정은 1개 ~ 10개 과정을 양방향 검사합니다.</li>
                    <li>설정 시간에 따라 제한 시간 안에 검사가 진행됩니다.</li>
                    <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율이 기록됩니다.</li>
                </ul>
            }
        }
    }


    /**
     * 기억력 균형
     * @param testName
     * @param measureType
     */
    static getMemoryShow2View4(testName, measureType){
        if('develop' === measureType){
            return {
                title: `${testName} 증강 프로그램`,
                text1Title: `${testName} 증강 프로그램은?`,
                text1:  <ul className={'box'}>
                    <li>최근 받은 정보를 입출력하여 장기기억에 저장하는 반복훈련</li>
                    <li>좌우뇌 다양한 정보를 양방향 통합하는 기능 증진 훈련</li>
                    <li>좌우뇌 정보를 양방향 입출력하는 수행 능력 증진훈련</li>
                    <li>인지력, 단기기억, 장기기억 저장과 인출 재생 반복훈련</li>
                    <li>기억 순서와 과정 로드맵 신경가소성 재조직 훈련</li>
                </ul>,
                text2Title: `${testName} 증강 프로그램 평가 항목`,
                text2: <ul className={'box'}>
                    <li>주의집중 기능과 순서, 절차기억 수행 능력평가</li>
                    <li>인지별 뇌의 정보 속도 처리 능력평가</li>
                    <li>좌우뇌 수리능력, 두뇌 발달 수행 능력평가</li>
                    <li>좌우뇌의 장기기억 양방향 입출력 저장 능력평가</li>
                    <li>좌우뇌의 장기기억 양방향 통합기능 재생 능력평가</li>
                    <li>장기기억 입출력 과정 로드맵 형성 수행 능력평가</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>증강 과정은 1개 ~ 10개까지 높여갑니다.</li>
                    <li>설정한 시간과 제한 시간은 설정에 따라 순서대로 시작합니다.</li>
                    <li>화면에 나타난 기호를 맞추면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율을 높여 갑니다.</li>
                    <li>개인별 뇌기능 상태에 따라 기초, 중급, 고급을 선택할 수 있습니다.</li>
                </ul>
            }
        } else {
            return {
                title: `${testName} 검사`,
                text1Title: `${testName} 검사 항목`,
                text1:  <ul className={'box'}>
                    <li>좌우뇌 인지기능과 뇌기능정보 입출력 기억 수행 능력</li>
                    <li>연령별 좌우뇌의 정보 처리 능력</li>
                    <li>절차기억, 단기기억, 장기 기억 입출력 능력 평가</li>
                    <li>단기기억, 장기기억 저장 능력, 인출 재생 능력</li>
                    <li>기억 순서와 과정 통합 수행 능력</li>
                </ul>,
                text2Title: `${testName}을 증강해야 할 대상`,
                text2: <ul className={'box'}>
                    <li>건망증, 어디에 물건을 두었는지 기억을 못 하는 사람</li>
                    <li>날짜, 요일. 약속 등, 기억 재생이 잘 안되는 사람</li>
                    <li>머릿속에서는 생각이 떠오르는데 이름을 기억하지 못하는 분</li>
                    <li>머리가 몽롱하고 기억력이 저하 된 사람</li>
                    <li>한곳에 주의를 기우리지 못해 집중력, 기억력이 저하된 사람</li>
                    <li>수면장애, 무기력증, 참을성이 부족한 사람</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>검사과정은 1개 ~ 10개 과정을 검사합니다.</li>
                    <li>설정 시간에 따라 제한 시간 안에 검사가 진행됩니다.</li>
                    <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율이 기록됩니다.</li>
                </ul>
            }
        }
    }


    /**
     * 사고력 검사
     * @param testName
     * @param measureType
     */
    static getMemoryShow2View6(testName, measureType){
        if('develop' === measureType){
            return {
                title: `${testName} 증강 프로그램`,
                text1Title: `${testName} 증강 프로그램은?`,
                text1:  <ul className={'box'}>
                    <li>타인의 감정표현에 동조할 수 있는 감정의 능력</li>
                    <li>상황에 맞는 타인의 주장에 대한 이해력 공감 능력</li>
                    <li>타인과 의견 교류 및 일치감에 대한 반응 조화 능력</li>
                </ul>,
                text2Title: `${testName} 증강 프로그램 평가 항목`,
                text2: <ul className={'box'}>
                    <li>다른 사람이 하는 말을 주의 깊게 듣지 못하는 사람</li>
                    <li>타인과 대화를 나눌 때 반응의 조화를 잘 이루지 못하는 사람</li>
                    <li>다른 사람들과 감정 소통이 원활하지 않은 사람</li>
                    <li>서로 교류하는 감정보다 내 감정을 우선시 하는 사람</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>1개 ~ 10개 과정을 양방향 검사합니다.</li>
                    <li>설정 시간에 따라 제한 시간 안에 검사가 진행됩니다.</li>
                    <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율이 기록됩니다.</li>
                    <li>개인별 뇌기능 상태에 따라 기초, 중급, 고급을 선택할 수 있습니다.</li>
                </ul>
            }
        } else {
            return {
                title: `${testName} 검사`,
                text1Title: `${testName} 검사 항목`,
                text1:  <ul className={'box'}>
                    <li>타인의 감정표현에 동조할 수 있는 감정의 능력</li>
                    <li>상황에 맞는 타인의 주장에 대한 이해력 공감 능력</li>
                    <li>타인과 의견 교류 및 일치감에 대한 반응 조화 능력</li>
                </ul>,
                text2Title: `${testName}을 증강해야 할 대상`,
                text2: <ul className={'box'}>
                    <li>다른 사람이 하는 말을 주의 깊게 듣지 못하는 사람</li>
                    <li>타인과 대화를 나눌 때 반응의 조화를 잘 이루지 못하는 사람</li>
                    <li>다른 사람들과 감정 소통이 원활하지 않은 사람</li>
                    <li>서로 교류하는 감정보다 내 감정을 우선시 하는 사람</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>1개 ~ 10개 과정을 양방향 검사합니다.</li>
                    <li>설정 시간에 따라 제한 시간 안에 검사가 진행됩니다.</li>
                    <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율이 기록됩니다.</li>
                </ul>
            }
        }
    }

    /**
     * 사고력 검사
     * @param testName
     * @param measureType
     */
    static getMemoryShow2View7(testName, measureType){
        if('develop' === measureType){
            return {
                title: `${testName} 증강 프로그램`,
                text1Title: `${testName} 증강 프로그램은?`,
                text1:  <ul className={'box'}>
                    <li>업무 수행을 원활하게 하기 위한 통합적 능력</li>
                    <li>고정관념을 깨는 창조적 능력</li>
                    <li>객관적 시선으로 바라보는 논리력</li>
                    <li>문제 해결을 위한 대안과 실행 능력</li>
                </ul>,
                text2Title: `${testName} 증강 프로그램 평가 항목`,
                text2: <ul className={'box'}>
                    <li>고정관념과 습관에서 벗어나기 어려운 사람</li>
                    <li>자신의 생각은 절대 틀리지 않다고 주장하는 사람</li>
                    <li>주어진 업무에 명확한 기준을 파악하려 하지 않는 사람</li>
                    <li>문제가 발생하면 적당히 빨리 포기하고 싶은 사람</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>1개 ~ 10개 과정을 양방향 검사합니다.</li>
                    <li>설정 시간에 따라 제한 시간 안에 검사가 진행됩니다.</li>
                    <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율이 기록됩니다.</li>
                    <li>개인별 뇌기능 상태에 따라 기초, 중급, 고급을 선택할 수 있습니다.</li>
                </ul>
            }
        } else {
            return {
                title: `${testName} 검사`,
                text1Title: `${testName} 검사 항목`,
                text1:  <ul className={'box'}>
                    <li>업무 수행을 원활하게 하기 위한 통합적 능력</li>
                    <li>고정관념을 깨는 창조적 능력</li>
                    <li>객관적 시선으로 바라보는 논리력</li>
                    <li>문제 해결을 위한 대안과 실행 능력</li>
                </ul>,
                text2Title: `${testName}을 증강해야 할 대상`,
                text2: <ul className={'box'}>
                    <li>고정관념과 습관에서 법어나기 어려운 사람</li>
                    <li>자신의 생각은 절대 틀리지 않다고 주장하는 사람</li>
                    <li>주어진 업무에 명확한 기준을 파악하려 하지 않는 사람</li>
                    <li>문제가 발행하면 적당히 빨리 포기하고 싶은 사람</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>1개 ~ 10개 과정을 양방향 검사합니다.</li>
                    <li>설정 시간에 따라 제한 시간 안에 검사가 진행됩니다.</li>
                    <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율이 기록됩니다.</li>
                </ul>
            }
        }
    }

    /**
     * 주의 집중
     * @param testName
     * @param measureType
     */
    static getMemoryShow3View2(testName, measureType){
        if('develop' === measureType){
            return {
                title: `${testName} 증강 프로그램`,
                text1Title: `${testName} 증강 프로그램은?`,
                text1:  <ul className={'box'}>
                    <li>주의력과 집중력 동시에 높이는 두뇌 향상 훈련</li>
                    <li>주어진 과제와 과정 독해 능력 향상 훈련</li>
                    <li>주의집중 시간을 높여주고 유지 발달시키는 훈련</li>
                    <li>뇌의 정신기능 효율적인 재충진 향상 훈련</li>
                    <li>뇌의 통합기능 발달 훈련. 멘탈 안정성 향상 훈련</li>
                    <li>뇌기능 융합, 응용 능력 향상 훈련</li>
                </ul>,
                text2Title: `${testName} 증강 프로그램 평가 항목`,
                text2: <ul className={'box'}>
                    <li>정서적 교류와 안정성 수행 능력평가</li>
                    <li>한곳에 주의를 기울여 집중 할 수 있는 능력</li>
                    <li>다양한 정보의 차이점을 명확하게 구분하는 관찰 능력평가</li>
                    <li>주어진 과제의 핵심 정보를 분류하여 저장할 수 있는 능력평가</li>
                    <li>저장된 정보를 기억하여 인출 해내는 능력평가</li>
                    <li>핵심정리하여 특성을 명확하게 판단할 수 있는 수행 능력평가</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>검사과정은 1개 ~ 10개를 검사합니다.</li>
                    <li>설정 시간과 제한 시간은 설정 기준 순서대로 기억하여 검사를 시작합니다.</li>
                    <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10단계 100%까지 완성율이 기록됩니다.</li>
                    <li>개인별 뇌기능 상태에 따라 기초, 중급, 고급을 선택할 수 있습니다.</li>
                </ul>
            }
        } else {
            return {
                title: `${testName} 검사`,
                text1Title: `${testName} 검사 항목`,
                text1:  <ul className={'box'}>
                    <li>정서적 교류와 안정성 수행 능력</li>
                    <li>한곳에 주의를 기울여 집중 할 수 있는 능력</li>
                    <li>다양한 정보의 차이점을 명확하게 구분하는 관찰 능력</li>
                    <li>주어진 과제의 핵심 정보를 분류하여 저장할 수 있는 능력</li>
                    <li>저장된 정보를 기억하여 인출 해내는 능력</li>
                    <li>핵심정리하여 특성을 명확하게 판단할 수 있는 수행 능력</li>
                </ul>,
                text2Title: `${testName}을 증강해야 할 대상`,
                text2: <ul className={'box'}>
                    <li>주어진 일에 빠르게 대처하는 방법이 서지 않는 사람</li>
                    <li>사회성이 부족하고 새로운 일에 긴장과 불안이 많은 사람</li>
                    <li>한곳에 몰입하지 못하고 집중력이 부족한 사람</li>
                    <li>주어진 과제의 이해력이 부족하고 참을성이 부족한 사람</li>
                    <li>동기부여가 잘 안되고 흥이 나지 않는 사람</li>
                    <li>집중이 잘 안되고, 기억 재생 능력이 저하된 사람</li>
                </ul>,
                text3: <ul className={'box'}>
                    <li>검사과정은 1개 ~ 10개 과정을 검사합니다.</li>
                    <li>설정 시간에 따라 제한 시간 안에 검사가 진행됩니다.</li>
                    <li>단계별 검사를 마치면 프로그램이 다음 단계로 넘어갑니다.</li>
                    <li>1개당 10%씩 총 10개 100%까지 완성율이 기록됩니다.</li>
                </ul>
            }
        }
    }
}
