import {Box, Card, CardActionArea, CardContent, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import BarChart from '@user-components/BarChart';
import { useHistory } from "react-router-dom";
import AuthProvider from "@user-provider/AuthProvider";

/**
 * 뇌기능 내면 상태  δ, θ, α, SMR, β, βM, βh로 표시합니다.
 * @param {*} props
 */
export default function BalanceBar(props) {
    const classes = useStyles();
    const { id,  bandRangeData, isEEG, createdAt} = props.measureResult;
    const history = useHistory()

    const ableClick = true !== props.clickDisabled
    
    const defaultData = [];
    defaultData.push(bandRangeData.ch1);
    defaultData.push(bandRangeData.ch2);

    const handler = () => {
        if(AuthProvider.canViewMeasureDetail() && ableClick) {
            history.push('/measureDetail/' + id + '/balanceBar');
        }
    }

    return (
        <Box className={classes.root}>
            <Card className={classes.card} onClick={handler}>
                <CardActionArea>
                    <CardContent>
                        <Typography variant={'subtitle1'} className={classes.title}>
                            뇌 기능별 상태
                        </Typography>
                        <Grid container>
                            <Grid item xs={12}>
                                {'yes' === isEEG &&
                                <BarChart
                                    key={Math.random()}
                                    data={new Array(6).fill(0)}
                                    defaultData={defaultData}
                                    showTopValue={true}
                                    // showTopValueTitle={true}
                                    topValueMargin={20}
                                    labels={['δ', 'θ', 'α', 'SMR', 'β', 'βM', 'βh']}
                                    height={100}
                                    useColorIndex={true}
                                    showYAxes={true}
                                    colors={['#60C3F7', '#A9D8AA']}
                                    grouped={true}
                                    groups={2}
                                />
                                }

                                {'yes' !== isEEG &&
                                <Box display={'flex'} minHeight={200}>
                                    <Box m="auto">
                                        <Typography variant="h6" style={{color:'#999'}}>
                                            뇌기능(EEG) 측정이 필요합니다.
                                        </Typography>
                                    </Box>
                                </Box>
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
    },
    title: {
        fontWeight: 'bold',
        paddingLeft: 15,
        marginBottom: 20
    },
    card: {
        border: "none",
        boxShadow: "none",
        padding: 0
    },
}));

