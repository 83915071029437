import 'date-fns';
import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { ko } from "date-fns/locale";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {Typography} from "@material-ui/core";
import DateHelper from '@helper/DateHelper'
import makeStyles from "@material-ui/core/styles/makeStyles";

export default function DatePicker(props) {

    const classes = useStyles();
    const [selectedDate, setSelectedDate] = React.useState(null);
    const handleDateChange = (date) => {
        setSelectedDate(date);
        if(props.onChange){
            props.onChange(date ? DateHelper.toDateFormat(date, 'YYYY-MM-DD') : null)
        }
    }
    useEffect(() => {
        if(props.value){
            setSelectedDate( DateHelper.toDateFormat(props.value, 'YYYY-MM-DDT00:00:00'));    
        }
    },[props.value])
    
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ko} >
            <Grid container>
                {props.title &&
                <Grid item xs={6} style={{paddingRight: 20, marginTop: 10 }}>
                    <Typography style={{ verticalAlign: "middle", textAlign: 'right' }}>
                        {props.title}
                    </Typography>
                </Grid>
                }
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        style={{margin: 0}}
                        margin="normal"
                        id="date-picker-dialog"
                        format="yyyy-MM-dd"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        emptyLabel={''}
                        InputProps={{ className: !selectedDate ? classes.datepicker : null }} //<----apply style when no date selected
                        okLabel={'선택'}
                        cancelLabel={'취소'}
                        // readOnly={true}
                    />    
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
}

const useStyles = makeStyles(theme => ({
    datepicker: {
        
        // margin: "2px 0px 2px 0px",
        // height: "60px",
        // // width: 'fit-content',
        // padding: "20px 15px 10px",
        // borderBottom: "2px solid blue",
        // backgroundColor: "#fff",
        // color: "#2c2c2c",
        // width: 300,
        // fontWeight: 600
    }
}));