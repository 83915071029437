
import React, {useCallback, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import ComputerIcon from '@material-ui/icons/Computer';
import Typography from '@material-ui/core/Typography';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DateHelper from '@helper/DateHelper'

import CustomizedDialogs from "../../components/CustomDialog";
import clsx from "clsx";

import DeviceService from '@user-service/DeviceService';
import {Grid} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const DeviceManage = ({history}) => {
    const classes = useStyles();
    const [devices, setDevices] = React.useState([]);
    const [selectDevice , setSelectDevice] = React.useState(null)

    const fetch = useCallback(async () => {
        const devices =  await DeviceService.getDevices()
        setDevices(devices)
    }, [])

    const handleDelete = (index) => {
        setSelectDevice( devices[index] )
    }

    useEffect(()=>{
        fetch().then()
    },[fetch])

    return (
        <div id="cont-wrap" >
            <div className="sv" >
                <Grid container>
                    <Grid item xs={1}>
                        <IconButton
                            className={classes.back}
                            aria-label="delete"
                            onClick={()=>{
                                history.goBack()
                            }}
                        >
                            <ArrowBackIcon style={{fontSize: 50}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <div>
                            <h3>연결된 기기 관리</h3>
                            <i>Brain Health</i>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className={clsx('cont')} >
                <List
                    style={{marginTop: 30}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            기기 목록
                        </ListSubheader>
                    }
                    className={classes.listRoot}
                >
                    {devices.map((device, index) => {
                        return (
                            <React.Fragment key={Math.random()}>
                                <ListItem className={'item'}>
                                    <ListItemIcon>
                                        <ComputerIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                {device.computerName}
                                            </Typography>
                                            <div className={classes.small}>
                                                BRHW 고유번호 : {device.deviceSerial}
                                            </div>
                                        </React.Fragment>
                                    } />
                                    <ListItemSecondaryAction>
                                        <React.Fragment>
                                            <span className={classes.date}>{DateHelper.format(device.loginDate, 'YYYY-MM-DD HH:mm')}</span>
                                            <IconButton onClick={() => {
                                                handleDelete(index)
                                            }} className={classes.remove}>
                                                <HighlightOffIcon />
                                            </IconButton>
                                        </React.Fragment>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        )
                    })}
                </List>
            </div>
            <CustomizedDialogs
                key={Math.random()}
                open={selectDevice}
                title={'기기 연결 해제'}
                content={
                    <React.Fragment>
                        <Typography gutterBottom className={classes.text}>
                            {selectDevice
                                ? selectDevice.computerName
                                : ''
                            }
                        </Typography>
                        <Typography gutterBottom className={classes.text}>
                            <span className={classes.small}>
                                BRHW 고유번호:
                                {selectDevice
                                    ? selectDevice.deviceSerial
                                    : ''
                                }
                            </span>
                        </Typography>
                        <Typography gutterBottom className={classes.text}>
                            연결된 기기를 해제 하시곘습니까?
                        </Typography>
                        <br/>
                        <Typography gutterBottom className={classes.text}>
                            해제된 기기는 BRHW 계정과 연동 후 다시 사용 할 수 있습니다. <br/> 측정 기록 및 자각증상의 데이터는 삭제되지 않고 그대로 유지됩니다.
                        </Typography>
                    </React.Fragment>
                }
                data={selectDevice}
                close={() => {
                    setSelectDevice(null)
                }}
                submit={async (data) => {
                    await DeviceService.delete(selectDevice.id)
                    await fetch()
                    setSelectDevice(null)
                }}
                buttonName={'기기해제'}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 800,
        margin: 'auto',

        '& .item': {
            padding : '20px 10px'
        }
    },
    back: {
        marginTop: 30,
        '&:hover': {
            backgroundColor: '#053288',
        }
    },
    small: {
        fontSize: 14,
        color: '#999',
    },
    remove: {
        color: '#ccc',
        '&:hover': {
            color: '#666'
        },
        marginLeft: 20
    },
    date: {
        fontSize: 14,
        color: '#999',
        fontFamily: 'Noto Sans KR',
    },
    text:{
        fontFamily: 'Noto Sans KR',
    },
    inline: {
        display: 'inline',
    },
    listRoot: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default DeviceManage
