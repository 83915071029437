import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {Box, Grid, Typography} from '@material-ui/core';
import AudioPlayer from '@user-pages/exercise/common/AudioPlayer'
import SocketProvider from '@user-provider/SocketProvider'
import MeasureHelper from '@helper/MeasureHelper'
import {useParams} from "react-router-dom";
import SeekBar from "./SeekBar";

import ExerciseStatusBar from "../ExerciseStatusBar";
import ReactAudioPlayer from "react-audio-player";
const { measureTypeToCode } = MeasureHelper
let MeasureHzType = 'meditation'


const Meditation = (props) => {
    const stageRef = useRef()
    let { type } = useParams()
    
    switch (type){
        case 'heal':
            MeasureHzType = 'meditationHeal';
            break
        case 'intoSleep':
            MeasureHzType = 'intoSleep';
            break
        default:
            MeasureHzType = 'meditation'
    }
    
    useEffect(()=>{
        SocketProvider.connect(()=>{}, window, MeasureHzType)
        
        return () => {
            SocketProvider.measureStop(true).then()
        }
    },[])

    
    return (
        <Box style={{position: 'relative', overflow: 'hidden'}}>
            <Stage ref={stageRef} history={props.history} type={type}/>
        </Box>
    );
}

export class Stage extends React.Component {
    container
    clientRect
    
    scoreBoardRef = React.createRef()
    videoRef = React.createRef()
    seekBarRef = React.createRef()
    audioRef = React.createRef()
    audioBackgroundRef = React.createRef()
    statusBarRef = React.createRef()

    currentStatus = 'ready'
    currentMeasureType = 'type1'
    currentMeasureCode = 'MA'
    measureCodes = ['MA','MM','MB']
    
    currentTime = 0
    timePointIndex = 0
    // timePoints = [0.7, 8.5, 11, 15, 20, 23.5, 29.5, 34, 37, 44, 56, 60, 81.8 , 87, 92, 96.5, 98.6, 101, 103  ]
    timePoints = [0.7, 8.5, 12, 20, 29.5, 34, 44, 54, 60, 81.8, 91, 98, 105 ]
    
    startDelayTimer = null
    videos = [
        {
            url: 'https://d2iajcsshqwtw3.cloudfront.net/assets/meditation_normal.webm',
            poster: 'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/meditation.png',
            playTime: 105
        },
        {
            url: 'https://d2iajcsshqwtw3.cloudfront.net/assets/meditation.webm',
            poster: 'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/meditation.png',
            playTime: 105
        },
        {
            url: 'https://d2iajcsshqwtw3.cloudfront.net/assets/intoSleep.webm',
            poster: 'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/intoSleep.png',
            playTime: 105
        },
        
    ]
    
    effectTimePointIndex = 0
    effectTimePoints = []
    effectTimePointsByNoraml = [
        { time: 3.5, effect: 'windChime1' }, 
        { time: 6, effect: 'windChime1' }, 
        { time: 7.2,  effect: 'ex15' }, 
        { time: 11.3, effect: 'ex16', vol: 0.3 }, 
        { time: 23, effect: 'fade' }, 
        // { time: 25, effect: 'fade' },{ time: 25.1, effect: 'fade' },{ time: 25.4, effect: 'fade' },{ time: 25.5, effect: 'fade' },{ time: 26.6, effect: 'fade' },{ time: 26.7, effect: 'fade' },
        // { time: 28.3, effect: 'fade' },{ time: 29.3, effect: 'fade' },
        // { time: 32.2, effect: 'fade' },{ time: 33.9, effect: 'fade' },
        // { time: 36.1, effect: 'ex09', vol: 0.3},{ time: 37, effect: 'ex09', vol: 0.3},
        // { time: 39.6, effect: 'ex09', vol: 0.3}, { time: 40.2, effect: 'ex09', vol: 0.3},
        // { time: 42.8, effect: 'ex09', vol: 0.3},
        // { time: 45.6, effect: 'windChimes4'}, { time: 48.5, effect: 'windChimes4'},
        // { time: 53, effect: 'fade' },
        // { time: 57.3, effect: 'ex30' },
        // { time: 58.8, effect: 'ex31' },
        { time: 62, effect: 'fade' },{ time: 63.2, effect: 'blink' },{ time: 64.9, effect: 'blink' },
        { time: 66.7, effect: 'fade' },{ time: 68.2, effect: 'blink' },{ time: 69.9, effect: 'blink' },
        { time: 71.8, effect: 'fade' },{ time: 73.3, effect: 'blink' },{ time: 75.0, effect: 'blink' },
        { time: 76.8, effect: 'fade' },{ time: 78.2, effect: 'blink' },{ time: 79.8, effect: 'blink' },
        { time: 81.7, effect: 'fade' },{ time: 83.2, effect: 'blink' },{ time: 85, effect: 'blink' },
        { time: 86.7, effect: 'fade' },{ time: 88.2, effect: 'blink' },{ time: 89.9, effect: 'blink' },
        { time: 92.2, effect: 'windChimes2' },
        { time: 94.3, effect: 'windChimes21' },{ time: 96.3, effect: 'windChimes21' },
    ]
    effectTimePointsByHeal = [
        { time: 3.5, effect: 'windChime1' },
        { time: 6, effect: 'windChime1' },
        { time: 7.2,  effect: 'ex15' },
        { time: 11.3, effect: 'ex16', vol: 0.3 },
        { time: 23, effect: 'fade' },
        // { time: 25, effect: 'fade' },{ time: 25.1, effect: 'fade' },{ time: 25.4, effect: 'fade' },{ time: 25.5, effect: 'fade' },{ time: 26.6, effect: 'fade' },{ time: 26.7, effect: 'fade' },
        // { time: 28.3, effect: 'fade' },{ time: 29.3, effect: 'fade' },
        // { time: 32.2, effect: 'fade' },{ time: 33.9, effect: 'fade' },
        // { time: 36.1, effect: 'ex09', vol: 0.3},{ time: 37, effect: 'ex09', vol: 0.3},
        // { time: 39.6, effect: 'ex09', vol: 0.3}, { time: 40.2, effect: 'ex09', vol: 0.3},
        // { time: 42.8, effect: 'ex09', vol: 0.3},
        // { time: 45.6, effect: 'windChimes4'}, { time: 48.5, effect: 'windChimes4'},
        // { time: 53, effect: 'fade' },
        // { time: 57.3, effect: 'ex30' },
        // { time: 58.8, effect: 'ex31' },
        { time: 62, effect: 'fade' },{ time: 63.2, effect: 'blink' },{ time: 64.9, effect: 'blink' },
        { time: 66.7, effect: 'fade' },{ time: 68.2, effect: 'blink' },{ time: 69.9, effect: 'blink' },
        { time: 71.8, effect: 'fade' },{ time: 73.3, effect: 'blink' },{ time: 75.0, effect: 'blink' },
        { time: 76.8, effect: 'fade' },{ time: 78.2, effect: 'blink' },{ time: 79.8, effect: 'blink' },
        { time: 81.7, effect: 'fade' },{ time: 83.2, effect: 'blink' },{ time: 85, effect: 'blink' },
        { time: 86.7, effect: 'fade' },{ time: 88.2, effect: 'blink' },{ time: 89.9, effect: 'blink' },
        { time: 92.2, effect: 'windChimes2' },
        { time: 94.3, effect: 'windChimes21' },{ time: 96.3, effect: 'windChimes21' },
    ]

    videoPlaybackRate = [0.14, 0.20, 0.26, 0.32]
    sumHitCountRange = 0
    
    checkNextMeasureTime = 0
    checkPlayRateTime = 3

    timeCountRef
    measureLogID
    
    maxMeasureTime = 4  //4배시간
    
    constructor(props) {
        super(props)

        let video, playTime, poster
        switch (props.type){
            case 'heal':
                video = this.videos[1].url
                playTime = this.videos[1].playTime
                poster = this.videos[1].poster
                this.effectTimePoints = this.effectTimePointsByHeal
                break
            case 'intoSleep':
                video = this.videos[2].url
                playTime = this.videos[2].playTime
                poster = this.videos[2].poster
                this.maxMeasureTime = 4
                break
            default:
                this.effectTimePoints = this.effectTimePointsByNoraml
                video = this.videos[0].url
                playTime = this.videos[0].playTime
                poster = this.videos[0].poster
        }
        
        this.state = {
            background:[
                require(`@assets/exercise/balance/background.jpg`),
                require(`@assets/exercise/balance/backgroundBoard.png`),
                require(`@assets/exercise/balance/backgroundRadar.png`),
                require(`@assets/exercise/balance/flyDron.gif`)
            ],
            show: false,
            history: props.history,
            onMeasureData: props.onMeasureData,
            useNarration: true,
            useBackgroundAudio: true,
            type: props.type,
            video: video,
            poster: poster,
            playTime: playTime
        }
        
        
    }

    componentDidMount() {
        this.currentMeasureCode = this.measureCodes.shift()
        
        this.container = ReactDOM.findDOMNode(this)
        this.clientRect = this.container.getClientRects()[0]
        this.audioRef.current.loadEffect(MeasureHzType)
        this.videoRef.current.playbackRate = 0.2
        
        SocketProvider.onMeasure = (data) => {
            // check per time
            if(0 !== data.time % this.checkPlayRateTime){
                return
            }
            
            const { hitCountRange } = data.hitCountRange
            
            const range = []
            let totalSum = 0;
            for(let row of hitCountRange){
                let sum = 0 
                for(let inner of row){ sum += inner }
                
                totalSum += sum
                range.push(sum)
            }
            
            //pause video, previous hit count range
            let playbackRate
            if(this.sumHitCountRange === totalSum){
                playbackRate = 0.1
                // this.videoRef.current.pause()
            } else {
                const playbackIndex = range.indexOf(Math.max(...range));
                playbackRate = this.videoPlaybackRate[playbackIndex]
            }
            
            if(this.videoRef.current.playbackRate !== playbackRate){
                this.videoRef.current.playbackRate = playbackRate
            }

            this.videoRef.current.play()
            this.sumHitCountRange = totalSum
        }

        SocketProvider.onMeasureStop = async ({measureLogID}) => {
            if('playing' !== this.currentStatus) {
                return
            }
            if('MB' === this.currentMeasureCode){
                this.audioBackgroundRef.current.audioEl.pause()
            } 
            
            
            this.measureLogID = measureLogID
            this.currentMeasureCode = this.measureCodes.shift()
            
            if(!this.currentMeasureCode) {
                this.timeCountRef.current.pauseInterval()

                this.measureCodes = ['MA','MM','MB']
                this.currentMeasureCode = this.measureCodes.shift()
                
                return
            }
            
            setTimeout(async () => {
                await this.startMeasure()    
            }, 1000)
        }
    }

    refresh(){
        this.setState({
            ...this.state
        })
    }
    setAudioMode(measureType){
        this.audioRef.current.setMode(measureType)
    }

    async startExercise(measureType) {
        this.currentStatus = 'playing'
        this.onReset()

        this.currentMeasureType = measureType
        await this.startMeasure()

        if('intoSleep' === this.state.type && this.state.useBackgroundAudio){
            this.audioBackgroundRef.current.audioEl.currentTime = 0
            this.audioBackgroundRef.current.audioEl.play()
        }
    }

    async startMeasure(){
        //최대 측정시간 설정
            
        await SocketProvider.measureStart(
            60 * this.maxMeasureTime ,
            0,
            MeasureHzType,
            this.currentMeasureType,
            measureTypeToCode(this.currentMeasureType),
            this.currentMeasureCode,
            null
            )
    }

    async stopExercise() {
        this.currentStatus = 'ready'
        this.onPause()
        
        await SocketProvider.measureStop()
    }

    onAudioEnded() {
        if(this.timePoints.length === this.timePointIndex){
            
        }
    }
    
    onVideoLoad() {
        // this.videoRef.current.play()
        //
        // setTimeout(() => {
        //     this.videoRef.current.pause()
        // }, 2000)
    }
    
    async onVideoEnded(){
        // MB stop
        await SocketProvider.measureStop()
        
        if(this.state.useNarration){
            setTimeout(() => {
                try {
                    this.audioRef.current.play(13)    
                } catch {}
                
            }, 10 * 1000)
        }
    }
    
    checkNextMeasure(time){
        if(0 < time && 0 === time % 35){
            if(0 < this.measureCodes.length){
                SocketProvider.measureStop().then()
            }
        }    
    } 
    
    onVideoTimeUpdate(event){
        if(this.videoRef.current.paused) {
            return false
        }
        
        const time = Math.floor(event.target.currentTime)
        const timePoint = this.timePoints[this.timePointIndex]

        if(this.checkNextMeasureTime !== time){
            this.checkNextMeasure(time)    
        }
        this.checkNextMeasureTime = time
        
        
        if(timePoint < event.target.currentTime){
            if(this.state.useNarration){
                this.audioRef.current.play(this.timePointIndex)    
            }
            this.timePointIndex++
        }
        
        try {
            const effectTimePoint = this.effectTimePoints[this.effectTimePointIndex]
            if(effectTimePoint.time < event.target.currentTime){
                this.audioRef.current.effect(effectTimePoint.effect, effectTimePoint.vol ? effectTimePoint.vol : 0.5)
                this.effectTimePointIndex++
            }    
        } catch(e){}
        
        
        if(this.currentTime < time) {
            this.currentTime = time
            this.seekBarRef.current.update(time)
        }
    }
    
    onSeekTime(time) {
        this.videoRef.current.currentTime = time
        this.audioRef.current.pause()
        
        let timePoint = 0
        for(let i = 0; i < this.timePoints.length; i++){
            timePoint = this.timePoints[i];
            
            if(timePoint >= time){
                this.timePointIndex = i
                break
            }
        }

        for(let i = 0; i < this.effectTimePoints.length; i++){
            timePoint = this.effectTimePoints[i];

            if(timePoint.time >= time){
                this.effectTimePointIndex = i
                break
            }
        }

        if(0 < time && 0 === this.timePointIndex){
            this.timePointIndex = 99
        }
        
        if(0 < time && 0 === this.effectTimePointIndex){
            this.effectTimePointIndex = 99
        }
    }
    
    onPlay(){
        const currentTime = parseInt(this.videoRef.current.currentTime);
        this.videoRef.current.playbackRate = 0.2 //this.seekBarRef.current.getVideoSpeed()

        if(0 === this.measureCodes.length) return
        
        if(0 === currentTime){
            if(this.startDelayTimer){
                clearTimeout(this.startDelayTimer)
            }
            this.startDelayTimer = setTimeout(() => {
                this.videoRef.current.play()    
            }, 10000);
        } else {
            this.videoRef.current.play()
        }
        
    }
    
    onPause(){
        if(this.startDelayTimer){
            clearTimeout(this.startDelayTimer)
        }
        
        this.videoRef.current.pause()
        this.audioRef.current.pause()
        this.audioBackgroundRef.current.audioEl.pause()
        
    }
    
    onNarration(checked) {

        if('intoSleep' === this.state.type && 0 < this.videoRef.current.currentTime ){
            const audio = this.audioBackgroundRef.current.audioEl
            if(checked){
                audio.play()
            } else  {
                audio.pause()
                audio.currentTime = 0
            }
            this.setState({
                ...this.state,
                useBackgroundAudio: !checked
            })

        } else {
            
            this.audioRef.current.pause()
            
            this.setState({
                ...this.state,
                useNarration: !checked
            })

        }
        
    }
    
    onReset(){
        this.timePointIndex = 0
        this.effectTimePointIndex = 0
        this.videoRef.current.pause()
        
        this.audioBackgroundRef.current.audioEl.currentTime = 0
        this.audioBackgroundRef.current.audioEl.pause()
        
        this.videoRef.current.currentTime = 0
        this.seekBarRef.current.update(0, this.currentMeasureType, this.currentMeasureCode)
        this.videoRef.current.play()
        
    }

    render() {
        return (
            <Box>
                <ExerciseStatusBar 
                    ref={this.statusBarRef}
                    measureHzType={MeasureHzType}
                    measureType={this.currentMeasureType}
                    measureCode={this.currentMeasureCode}
                    hideIntervalTime={true}
                    useTimeCountInterval={true}
                    checkBoxLabel={'intoSleep' !== this.state.type ? '효과음만 사용' : '배경음 사용'}
                    useNarration={'intoSleep' !== this.state.type ? this.state.useNarration : !this.state.useBackgroundAudio}
                    // hideHit={true}
                    close={async () => {
                        await this.stopExercise()
                    }}
                    start={async (runningTime, measureType) => {
                        await this.startExercise(measureType)
                    }}
                    onInitAfter={({timeCountRef}) => {
                        this.timeCountRef = timeCountRef
                    }}
                    onNarration={this.onNarration.bind(this)}
                    pause={(isPause) => {
                        if(isPause){
                            this.videoRef.current.pause()
                            this.audioRef.current.pause()
                        } else {
                            this.videoRef.current.play()
                            this.audioRef.current.play()
                        }
                    }}
                />
                
            
                <Box style={{ position: 'relative', height: window.innerHeight}}>
                    <Box style={{height:'100%'}}>
                        <video id="meditation-video"
                               ref={this.videoRef}
                               muted
                               poster={this.state.poster}
                               onEnded={this.onVideoEnded.bind(this)}
                               onLoadedData={this.onVideoLoad.bind(this)}
                               onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                               style={{height: '100%', width: '100%', objectFit: 'cover' }}>
                            <source src={this.state.video} type="video/mp4"/>
                        </video>
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="center"
                        position="fixed"
                        bottom={50}
                        style={{height: 50, width: '100%', zIndex: 20}}>
                        <Box style={{width: '80%'}}>
                            <SeekBar ref={this.seekBarRef} 
                                     onSeekTime={this.onSeekTime.bind(this)}
                                     onPlay={this.onPlay.bind(this)}
                                     onPause={this.onPause.bind(this)}
                                     measureType={this.currentMeasureType}
                                     measureCode={this.currentMeasureCode}
                                     onReset={this.onReset.bind(this)}
                                     playTime={this.state.playTime}
                                     
                            />    
                        </Box>
                        
                    </Box>
                </Box>
                
                <AudioPlayer 
                    ref={ this.audioRef }
                    onAudioEnded={this.onAudioEnded.bind(this)}
                    mode={'breathStamp'} />
                <ReactAudioPlayer
                    ref={ this.audioBackgroundRef }
                    src={require('@audio/intoSleep.mp3')}
                    loop={true}
                />
            </Box>
        )
    }
}

export class ScoreBoard extends  React.Component {

    constructor() {
        super();
        
        this.state = {
            MA: [[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0]],
            MM: [[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0]],
            MB: [[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0]],
        }
    }

    reset(){
        this.setState({
            MA: [[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0]],
            MM: [[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0]],
            MB: [[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0]],
        })
    }

    update(score){
        if('MA' === score.measureCode){
            this.setState({
                ...this.state,
                MA: score.hitCountRange
            })    
        } else if ('MM' === score.measureCode){
            this.setState({
                ...this.state,
                MM: score.hitCountRange
            })
        } else if ('MB' === score.measureCode){
            this.setState({
                ...this.state,
                MB: score.hitCountRange
            })
        }

    }

    render() {
        return (
            <Box>
                
                <Box style={{
                    color: 'white'
                }}>
                    <Typography >
                        MA
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Box>비율 4:x</Box>
                            {this.state.MA[0].map((score, index) => {
                                return (
                                    <Box key={index}>
                                        {index + 1}  >  {score} 회
                                    </Box>
                                )
                            })}        
                        </Grid>
                        <Grid item>
                            <Box>비율 3:x</Box>
                            {this.state.MA[1].map((score, index) => {
                                return (
                                    <Box key={index}>
                                        {index + 1}  >  {score} 회
                                    </Box>
                                )
                            })}
                        </Grid>
                        <Grid item>
                            <Box>비율 2:x</Box>
                            {this.state.MA[2].map((score, index) => {
                                return (
                                    <Box key={index}>
                                        {index + 1}  >  {score} 회
                                    </Box>
                                )
                            })}
                        </Grid>
                        <Grid item>
                            <Box>비율 1:x</Box>
                            {this.state.MA[3].map((score, index) => {
                                return (
                                    <Box key={index}>
                                        {index + 1}  >  {score} 회
                                    </Box>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Box>
               
                <Box mt={1} style={{color: 'white'}}>
                    <Typography>
                        MM
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Box>비율 4:x</Box>
                            {this.state.MM[0].map((score, index) => {
                                return (
                                    <Box key={index}>
                                        {index + 1}  >  {score} 회
                                    </Box>
                                )
                            })}
                        </Grid>
                        <Grid item>
                            <Box>비율 3:x</Box>
                            {this.state.MM[1].map((score, index) => {
                                return (
                                    <Box key={index}>
                                        {index + 1}  >  {score} 회
                                    </Box>
                                )
                            })}
                        </Grid>
                        <Grid item>
                            <Box>비율 2:x</Box>
                            {this.state.MM[2].map((score, index) => {
                                return (
                                    <Box key={index}>
                                        {index + 1}  >  {score} 회
                                    </Box>
                                )
                            })}
                        </Grid>
                        <Grid item>
                            <Box>비율 1:x</Box>
                            {this.state.MM[3].map((score, index) => {
                                return (
                                    <Box key={index}>
                                        {index + 1}  >  {score} 회
                                    </Box>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Box>

                <Box mt={1} style={{color: 'white'}}>
                    <Typography>
                        MB
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Box>비율 4:x</Box>
                            {this.state.MB[0].map((score, index) => {
                                return (
                                    <Box key={index}>
                                        {index + 1}  >  {score} 회
                                    </Box>
                                )
                            })}
                        </Grid>
                        <Grid item>
                            <Box>비율 3:x</Box>
                            {this.state.MB[1].map((score, index) => {
                                return (
                                    <Box key={index}>
                                        {index + 1}  >  {score} 회
                                    </Box>
                                )
                            })}
                        </Grid>
                        <Grid item>
                            <Box>비율 2:x</Box>
                            {this.state.MB[2].map((score, index) => {
                                return (
                                    <Box key={index}>
                                        {index + 1}  >  {score} 회
                                    </Box>
                                )
                            })}
                        </Grid>
                        <Grid item>
                            <Box>비율 1:x</Box>
                            {this.state.MB[3].map((score, index) => {
                                return (
                                    <Box key={index}>
                                        {index + 1}  >  {score} 회
                                    </Box>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        )
    }
}

export default Meditation
