import React, {useState, useEffect, useCallback} from 'react'
import { useHistory, useParams} from "react-router-dom";

import ProfileService from '@user-service/ProfileService'
import { makeStyles } from '@material-ui/core/styles'
import {Box, Typography, Grid, IconButton} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const ProfileAvatar = () => {
    const classes = useStyles()
    const history = useHistory()
    
    const {profileID} = useParams()
    const [avatars, setAvatars] = useState(null)

    const fetchProfile = useCallback(async () => {
        const avatars = await ProfileService.getAvatars()
        setAvatars(avatars)
    }, [])

    useEffect(()=>{
        fetchProfile().then()
    },[fetchProfile])


    if(!avatars){
        return(<Box className={classes.root} />)
    }
    
    const changeAvatar = async (fileName) => {
        history.push(`/editProfile/${profileID}`, {
            fileName
        })
    }
    
    return (
        <Box className={classes.root}>
            <Box className={classes.background} />
            <Box display={'flex'} justifyContent={'center'} >
                <Box style={{maxWidth: 1280, paddingBottom: 100}}  >
                    <Box>
                        <Grid container style={{width: '100%'}}>
                            <Grid item>
                                <IconButton className={classes.back} onClick={() => {
                                    history.goBack()
                                }}>
                                    <ArrowBackIcon style={{fontSize: 50}}/>
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" component="h3" className={classes.title}>
                                    프로필 변경
                                    <span className={'subtitle'}>
                                        이미지를 선택해 주세요.
                                    </span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container justify="center" spacing={3} style={{width: '100%'}}>
                        {avatars.map(avatar => {
                            return (
                                <Grid key={avatar.id} item>
                                    <Box 
                                        className={classes.box} 
                                        onClick={() => {
                                            changeAvatar(avatar.fileName)
                                        }}
                                    >
                                        <img src={require(`@images/profile/${avatar.fileName}`)} alt={avatar.fileName}/>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>      
                </Box>
            </Box>
        </Box>
    )
}


//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <div className="editprofile_wrap wrapper">
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </div>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        justify: "center",
        alignItems: "center",
        height: '100%',
        paddingTop: 100,
        paddingBottom: 100
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: -1,
        width: `100%`,
        height: '100vh',
        backgroundColor: '#1E2030',
    },
    title: {
        color: 'white',
        textAlign: 'left',
        marginTop: 12,
        marginBottom: 100,
        fontSize: 42,
        '& .subtitle': {
            fontSize: 28,
            color: '#606161',
            paddingLeft: 10
        }
    },
    logo: {
        position: 'fixed',
        left: 30 ,
        top: 22,
        zIndex: 0,
        width: `100%`,
    },
    back: {
        color: 'white',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#12131D'
        },
        marginRight: 10
    },
    box: {
        width: 153,
        height: 153,
        borderRadius: 5,
        backgroundColor: '#8799AD',
        '& img': {
            width: '100%',
            borderRadius: 5,    
        },
        '& img:hover': {
            opacity: 0.5,
            cursor: 'pointer'
        }
    }
}));


export default ProfileAvatar
