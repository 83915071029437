import React, {useCallback, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {Box, Button, Grid, TextField, FormControl, RadioGroup, FormControlLabel, Radio} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import MeasureService from '@user-service/MeasureService'
import SocketProvider from '@user-provider/SocketProvider';

export default class NoiseSetDialog extends React.Component {
    constructor(props) {
        super();
        this.state = {
            open: false,
            hzType: props.hzType,
            measureType: props.measureType,
            useMeasureCode: props.useMeasureCode,
            type: 'device' //device: get noise from device, common : get noise from common 
        }
    }

    open(type) {
        this.setState({
            ...this.state,
            open: true,
            type
        })
    }
    
    close() {
        this.setState({
            ...this.state,
            open: false
        })
    }
    
    render() {
        return (
            <Dialog
                open={this.state.open}
                aria-labelledby="responsive-dialog-title"
                maxWidth={'lg'}
            >
                <DialogContent>
                    <LoadNoiseFilter onClose={this.close.bind(this)} type={this.state.type}/>
                </DialogContent>
            </Dialog>
        );
    }
}


export function LoadNoiseFilter(props) {
    const [channelNoise, setChannelNoise] = useState(null)
    const [channel, setChannel] = useState('ch1')

    const saveNoiseConfig = async (deviceID) => {
        if('common' === props.type){
            await MeasureService.updateConfigEach('NOISE_CH1', channelNoise.ch1.join(','))
            await MeasureService.updateConfigEach('NOISE_CH2', channelNoise.ch2.join(','))
        } else {
            await MeasureService.updateNoise(
                deviceID,
                channelNoise
            )
        }
        
        alert('수정되었습니다.')
    }
    
    const fetch = useCallback(async () => {
        const info = await SocketProvider.checkDeviceStatus()
        const res = await MeasureService.getNoise('common' === props.type  ? 'common' :info.deviceID)
        
        setChannelNoise(res)
    },[])
    
    useEffect(() => {
        fetch().then()
    },[])

    if(!channelNoise) return <></>
    const noiseArray = channelNoise[channel]
    
    return (
        <Box mt={2} mb={2}>
            <Typography>
                설정된 노이즈 값
            </Typography>
            <Box style={{marginBottom: 10}}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button variant={'ch1' === channel ? 'contained': 'outlined'} 
                                color={'primary'}
                                onClick={() => {
                                    setChannel('ch1')
                                }}
                        >CH1</Button>        
                    </Grid>
                    <Grid item>
                        <Button variant={'ch2' === channel ? 'contained': 'outlined'} 
                                color={'primary'}
                                onClick={() => {
                                    setChannel('ch2')
                                }}
                        >CH2</Button>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container spacing={3}>
                    {noiseArray.map((val , index) => {
                        return <Grid item key={`${channel}${index}`}>
                            <TextField label={`${index +1}Hz`}
                                       defaultValue={val}
                                       onChange={(event)=>{
                                           if('ch1' === channel){
                                               channelNoise.ch1[index] = parseFloat(event.target.value)    
                                           } else {
                                               channelNoise.ch2[index] = parseFloat(event.target.value)
                                           }
                                       }}
                                       size="small" />
                        </Grid>
                    })}
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button variant={'contained'}  color={'primary'} onClick={async ()=>{
                            const info = await SocketProvider.checkDeviceStatus()
                            await saveNoiseConfig(info.deviceID)
                        }}>
                            수정
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant={'outlined'} onClick={async ()=>{
                            if(window.confirm('노이즈 값을 초기화 하겠습니까?')){
                                const info = await SocketProvider.checkDeviceStatus()
                                await MeasureService.clearNoise(info.deviceID)
                                fetch().then()
                            }
                        }}>
                            노이즈 초기화
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant={'outlined'} onClick={()=>{
                            props.onClose()
                        }}>
                            닫기
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
