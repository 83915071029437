import React from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from "react-router-dom";

import AuthProvider from '@user-provider/AuthProvider'
import ProfileService from '@user-service/ProfileService'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import clsx from "clsx";

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import MeasureService from "../../services/MeasureService";

export default function ProfileBox(props) {
    const classes = useStyles();
    const [, setCookie] = useCookies(['']);
    const history = useHistory()

    //프로필 선택
    const selectProfile = async () => {
        AuthProvider.set('profileID', props.id)
        AuthProvider.set('profileName', props.name)
        AuthProvider.set('profileType', props.type)
        AuthProvider.set('code', props.code)
        AuthProvider.set('image', props.image)
        AuthProvider.set('birthDate', props.birthDate)
        

        setCookie(AuthProvider.userCookieName, AuthProvider.getUserData(), AuthProvider.getCookieOption())
        await ProfileService.setCurrent(props.id, AuthProvider.getDeviceID())

        let path = '/dashboard'
        
        if(AuthProvider.isAppMode()){
            const page = AuthProvider.getAppServicePage()
            path = MeasureService.getMeasurePageUrl(page)
        }
        history.push(path)
    }

    const { id, isEditMode, name,  isNew, from, fileName } = props
    const isSingleEdit = 'single' === from 
    const image = props.image ? props.image : fileName
    
    return (
        <Box className={classes.card}
             onClick={async ()=>{
                 if(isSingleEdit){
                     const paramID = id ? id : 'new'
                     history.push(`/profile/avatar/${paramID}`)
                 } else if(isNew){
                     history.push(`/editProfile/new`)
                 } else if(isEditMode) {
                     history.push(`/editProfile/${id}`)
                 } else {
                     await selectProfile()
                 }
             }}>

            <Box className={clsx({
                [classes.editProfile]: isEditMode && !isNew
            })} style={{display:isEditMode && !isNew ? 'block' : 'none'}}>
                <Box style={{position: 'relative'}}>
                    <Box className={'background'} />
                    <EditIcon className={'editIcon'}/>
                </Box>
            </Box>

            <Box 
                style={{}}
                className={clsx('wrap',{
                [classes.isNew] : isNew,
                [classes.default] : !isNew
            })}>
                {(!isNew && image) && 
                <img src={require(`@images/profile/${image}`)} alt={name}/>
                }
                {isNew &&
                <AddIcon className={'addIcon'}/>
                }
            </Box>

            <Box className={clsx('profileName',{
                'new' : isNew
            })}>
                {isNew ? '프로필 추가' : name }
            </Box>
        </Box>
    )

}

const useStyles = makeStyles(() => ({
    edit: {
        backgroundColor: '#fff',
        color: '#333',
        '&:hover': {
            backgroundColor: '#CCCCCC'
        }
    },
    
    isNew: {
        backgroundColor: '#4B4D5A',
        '&:hover': {
            backgroundColor: '#2F303A',
        }
    },
    default: {
        backgroundColor: '#fff',
        borderRadius: 5, 
    },
    card: {
        width: 184,
        height: 220,
        cursor: 'pointer',
        position: 'relative',
        
        '& img':  {
            width: 184,
            height: 184
        },
        '& .profileName': {
            color: '#FFFEFE',
            fontSize: 18,
            fontWeight: 500,
            marginTop: 10,
            textAlign: 'center'
        },
        '& .profileName.new': {
            color: '#AEACB0',
            fontWeight: 'normal'
        },
        '& .wrap': {
            width: 184,
            height: 184,
        },
        '& .wrap img': {
            width: '100%',
            borderRadius: 5
        },
        '& .addIcon': {
            position: 'absolute',
            left: 'calc(50% - 36px)',
            top: '60px',
            fontSize: 70,
            color: '#fff',
            zIndex: 2
        }
    },
    editProfile: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: 184,
        height: 184,
        '& .background': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: 184,
            height: 184,
            backgroundColor: '#000',
            opacity: 0.5,
            zIndex: 1,
            '&:hover': {
                opacity: 0.7
            }
        },
        '& .editIcon' : {
            position: 'absolute',
            left: 'calc(50% - 40px)',
            top: '60px',
            fontSize: 70,
            color: '#fff',
            zIndex: 2
        }
    },
        
}));

