
import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';

import {Box} from '@material-ui/core';
import AudioPlayer from '@user-pages/exercise/common/AudioPlayer'
import SocketProvider from '@user-provider/SocketProvider'

import Intro from '@user-pages/exercise/common/Intro'

import MeasureHelper from '@helper/MeasureHelper'
import ExerciseStatusBar from "../ExerciseStatusBar";
import ExerciseService from '@user-service/ExerciseService';

const { measureTypeToCode } = MeasureHelper
const MeasureHzType = 'synapse'

const Balls = {
    'E': require(`@assets/exercise/synapse/ball_e.png`),
    'S': require(`@assets/exercise/synapse/ball_s.png`),
    'A': require(`@assets/exercise/synapse/ball_a.png`),
    'O': require(`@assets/exercise/synapse/ball_o.png`),
    'D': require(`@assets/exercise/synapse/ball_d.png`)
}
const Bowls = {
    'E': require(`@assets/exercise/synapse/ball_001_a.png`),
    'S': require(`@assets/exercise/synapse/ball_002_a.png`),
    'A': require(`@assets/exercise/synapse/ball_003_a.png`),
    'O': require(`@assets/exercise/synapse/ball_004_c.png`),
    'D': require(`@assets/exercise/synapse/ball_005_c.png`)
}

const Synapse = (props) => {
    const stageRef = useRef()
    const [status, setStatus] = useState('intro')
    const [testType] = useState(props.match.params.testType)

    useEffect(()=>{
        SocketProvider.connect(()=>{}, window, MeasureHzType)

        return () => {
            SocketProvider.measureStop(true).then()
        }
    },[])

    const onNext = () => {
        setStatus('stage')
    }

    return (
        <Box style={{position: 'relative'}}>
            <Intro
                onNext={onNext.bind(this)}
                onPlay={() => {
                    setStatus('stage')
                }}
                poster={'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/synapse_intro_v2.png'}
                src={'https://d2iajcsshqwtw3.cloudfront.net/assets/synapse_intro_v2.webm'}
            />
            {'stage' === status &&
            <Stage ref={stageRef} testType={testType} history={props.history}/>
            }
        </Box>
    );
}

export class Stage extends React.Component {
    container
    clientRect


    videoRef = React.createRef()
    audioRef = React.createRef()
    scoreRef = React.createRef()

    currentStatus = 'ready'
    currentMeasureType = 'rest'
    currentMeasureCode = 'A'
    isPlaying = false

    readyTime = 2.28
    hitCount = 0

    onSynapseTime = 1.2
    
    ballRef = {
        'E': React.createRef(),
        'S': React.createRef(),
        'A': React.createRef(),
        'O': React.createRef(),
        'D': React.createRef(),
    }
    bowlRef = {
        'E': React.createRef(),
        'S': React.createRef(),
        'A': React.createRef(),
        'O': React.createRef(),
        'D': React.createRef(),
    }

    ballSettings = {
        'E': {
            leftOffset: -0
        },
        'S': {
            leftOffset: -0
        },
        'A': {
            leftOffset: 0
        },
        'O': {
            leftOffset: 0
        },
        'D': {
            leftOffset: 0
        },
    }

    constructor(props) {
        super(props)
        this.state = {
            background:[
                require(`@assets/exercise/balance/background.jpg`),
                require(`@assets/exercise/balance/backgroundBoard.png`),
                require(`@assets/exercise/balance/backgroundRadar.png`),
                require(`@assets/exercise/balance/flyDron.gif`)
            ],
            show: false,
            history: props.history,
            onMeasureData: props.onMeasureData,
            testType: props.testType
        }
    }

    componentDidMount() {
        this.container = ReactDOM.findDOMNode(this)
        this.clientRect = this.container.getClientRects()[0]
        this.audioRef.current.loadEffect(MeasureHzType)
        this.videoRef.current.playbackRate = 1.3

        SocketProvider.onMeasure = (data) => {
            // data.isHit = true

            if(data.isHit){
                this.hitCount++

                if(!this.isPlaying){
                    this.onSynapse()
                }
                this.audioRef.current.play()

                if(10 < this.hitCount){
                    this.hitCount = 4
                }
            } else {
                this.hitCount = 0
            }

            let hitKey = '';
            switch (this.hitCount){
                case 4:
                    hitKey = 'E'
                    break
                case 5:
                    hitKey = 'S'
                    break
                case 6:
                    hitKey = 'A'
                    break
                case 7:
                    hitKey = 'O'
                    break
                case 8:
                    hitKey = 'D'
                    break
                default:
            }

            if('' !== hitKey) {
                this.ballRef[hitKey].current.show()
                this.bowlRef[hitKey].current.update()
                
                this.scoreRef.current.update(hitKey)
                
            }
        }

        SocketProvider.onMeasureStop = () => {
            this.onPause()
        }
    }

    refresh(){
        this.setState({
            ...this.state
        })
    }
    setAudioMode(measureType){
        this.audioRef.current.setMode(measureType)
    }

    async startExercise(runningTime, measureType, measureCode) {
        this.videoRef.current.currentTime = this.readyTime
        this.videoRef.current.play()

        this.currentStatus = 'playing'
        this.onReset()

        this.setAudioMode(measureType)
        await this.startMeasure(runningTime, measureType, measureCode)
    }

    async startMeasure(runningTime, measureType, measureCode){
        await SocketProvider.measureStart(
            runningTime,
            0,
            MeasureHzType,
            measureType,
            measureTypeToCode(measureType),
            measureCode,
            null,
            (isStart) => {
                if(isStart){
                    this.onPlay()
                }
            })
    }

    async stopExercise() {
        this.onPause()
        await SocketProvider.measureStop()
    }

    onSynapse() {
        this.isPlaying = true
        this.videoRef.current.playbackRate = 1
        this.videoRef.current.currentTime = 0
        this.videoRef.current.play()
    }
    onVideoLoad() {}


    async onVideoEnded(){
        this.videoRef.current.currentTime = this.readyTime
        this.videoRef.current.play()
    }

    onVideoTimeUpdate(event) {
        if(this.readyTime < event.target.currentTime){
            this.isPlaying = false
        }
    }

    onPlay(){
        this.videoRef.current.currentTime = this.readyTime
        this.videoRef.current.play()
    }

    onPause(){
        this.videoRef.current.pause()
        this.audioRef.current.pause()
    }

    onReset(){
        this.timePointIndex = 0
        this.effectTimePointIndex = 0
        this.videoRef.current.pause()
        this.videoRef.current.currentTime = 0
    }

    render() {
        return (
            <Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    position="absolute"
                    right={0}
                    top={120}
                    zIndex={20}
                    className={'synapse'}
                >
                    <Score ref={this.scoreRef}/>
                </Box>

                <ExerciseStatusBar
                    measureHzType={MeasureHzType}
                    measureType={this.currentMeasureType}
                    measureCode={this.currentMeasureCode}
                    onResultVar={ async ({measureLogID, measureHzType}) => {
                        const json = {
                            synapse : this.scoreRef.current.getScore()
                        }

                        await ExerciseService.setResultVar({
                            measureLogID,
                            measureHzType,
                            resultVar: JSON.stringify(json)
                        })
                    }}
                    close={async () => {
                        await this.stopExercise()
                    }}
                    start={async (runningTime, measureType, measureCode) => {
                        await this.startExercise(runningTime, measureType, measureCode)
                    }}
                    pause={(isPause) => {
                        if(isPause){
                            this.videoRef.current.pause()
                        } else {
                            this.videoRef.current.play()
                        }
                    }}
                />

                <Box style={{ position: 'relative', height: window.innerHeight}}>
                    <Box style={{height:'100%'}}>
                        <video id="meditation-video"
                               ref={this.videoRef}
                               muted
                               onEnded={this.onVideoEnded.bind(this)}
                               onLoadedData={this.onVideoLoad.bind(this)}
                               onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                               poster={'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/synapse_v2_1.png'}
                               style={{height: '100%', width: '100%', objectFit: 'cover' }}>
                            <source src={'https://d2iajcsshqwtw3.cloudfront.net/assets/synapse_v2_1.webm'} type="video/mp4"/>
                        </video>
                    </Box>
                </Box>

                <Box position={'absolute'} bottom={300} width={'75%'} style={{left: '50%', transform: 'translateX(-50%)'}}>
                    {['E','S','A','O','D'].map((key, index) => {
                        const setting = this.ballSettings[key]
                        return (
                            <Box  key={`ball-${index}`}  width={'20%'} display={'inline-block'} position={'relative'}>
                                <Ball ballType={key} index={index} ref={this.ballRef[key]} leftoffset={setting.leftOffset}/>
                                <Bowl ballType={key} index={index} ref={this.bowlRef[key]} />
                            </Box>
                        )
                    })}
                </Box>
                <AudioPlayer ref={ this.audioRef } mode={'synapse'} />
            </Box>
        )
    }
}

export class Bowl extends React.Component {
    constructor(props) {
        super();
        
        this.state = {
            key: props.ballType,
            percent: 0
        }
    }
    
    update(){
        this.setState({
            ...this.state,
            percent: this.state.percent + 10
        })
    }
    
    render() {
        const { key, percent } = this.state
        return (
            <Box position={'absolute'}
                 width={'100%'}
                 top={this.state.top}
                 zIndex={1}
                 style={{
                     backgroundImage:`url(${Bowls[key]})`,
                     height: 280,
                     width: '100%',
                     backgroundSize: 'contain',
                     backgroundRepeat: 'no-repeat',
                     backgroundPosition: `center`,
                     transitionDuration: '1s'
                 }}
            >
                <Box className={'box'}
                     style={{
                         width: '100%',
                         bottom: -7,
                         position: 'absolute'
                     }}
                >
                    <div style={{
                        textAlign: 'center',
                        paddingTop: 5,
                        fontSize: 35,
                        fontWeight: 'bold',
                        color: 'white',
                        paddingLeft: 20
                    }}>
                        {percent}
                        <span style={{fontSize: 20, fontWeight: 'normal'}}> %</span>
                    </div>
                </Box>
            </Box>
        )
    }
}

export class Ball extends React.Component {
    isPlaying = false
    constructor(props) {
        super();

        this.state = {
            ballType: props.ballType,
            index: props.index,
            top: -220,
            opacity : 0,
            size: 250,
            leftOffset: null !== props.leftoffset ? props.leftoffset : 0,
            sizePercent : 110
        }
    }
    componentDidMount() {
    }

    show(){
        this.isPlaying = true

        this.setState({
            ...this.state,
            opacity: 1,
        }, () => {
            setTimeout(() => {
                this.hide()
            },1000)
        })
    }

    hide(){
        this.setState({
            ...this.state,
            top: 35,
            opacity: 0,
            sizePercent: 30
        })
        setTimeout(() => {
            this.setState({
                ...this.state,
                top: -200,
                sizePercent: 110
            })

            this.isPlaying = false
        },1000)
    }

    render() {
        return (
            <Box position={'absolute'}
                 width={'100%'}
                 top={this.state.top}
                 style={{
                     backgroundImage:`url(${Balls[this.state.ballType]})`,
                     height: this.state.size,
                     width: '100%',
                     backgroundSize: `${this.state.sizePercent}%`,
                     backgroundRepeat: 'no-repeat',
                     backgroundPosition: `calc(50% - ${this.state.leftOffset}px) 50%`,
                     transitionDuration: '1s',
                     opacity: this.state.opacity,
                     zIndex: 90
                 }}
            />
        )
    }
}


class Score extends  React.Component {

    defaultOptions = {
        E: 0,
        S: 0,
        A: 0,
        O: 0,
        D: 0
    }
    constructor() {
        super();
        this.state = this.defaultOptions
    }

    getScore(toJson = false) {
        return toJson ? JSON.stringify(this.state) : this.state
    }

    reset() {
        this.setState({
            ...this.defaultOptions
        })
    }
    update(hitKey) {
        const state = this.state
        state[hitKey] += 10

        this.setState({
            ...state
        })
    }

    render() {
        // const { E, S, A, O , D} = this.state
        return <></>
        // return (
        //     <div className={'score'}>
        //         <Box className={'box'}>
        //             <div className={'title'}>E - 엔돌핀</div>
        //             <div className={'value'}>{E}<span className={'small'}>%</span></div>
        //         </Box>
        //         <Box className={'box'}>
        //             <div className={'title'}>S - 세르토닌</div>
        //             <div className={'value'}>{S}<span className={'small'}>%</span></div>
        //         </Box>
        //         <Box className={'box'}>
        //             <div className={'title'}>A - 아세틸콜린 </div>
        //             <div className={'value'}>{A}<span className={'small'}>%</span></div>
        //         </Box>
        //         <Box className={'box'}>
        //             <div className={'title'}>O - 옥시토신 </div>
        //             <div className={'value'}>{O}<span className={'small'}>%</span></div>
        //         </Box>
        //         <Box className={'box'}>
        //             <div className={'title'}>D - 도파민</div>
        //             <div className={'value'}>{D}<span className={'small'}>%</span></div>
        //         </Box>
        //     </div>
        // )
    }
}


export default Synapse
