import React, {useEffect, useState, useCallback} from 'react';

import { Box, Button, Grid, Typography} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import StarIcon from '@material-ui/icons/Star';
import { makeStyles } from '@material-ui/core/styles';

import ColorHelper from '@helper/ColorHelper';
import SurveyService from '@user-service/SurveyService'
import Skeleton from '@material-ui/lab/Skeleton';
import HeatMap from "react-heatmap-grid";
import clsx from "clsx";


const SurveyYear = (props) => {
    const classes = useStyles()
    
    const [survey, setSurvey] = useState(null)
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

    const fetchHSurveyHistory = useCallback(async () => {
        const res = await SurveyService.getMySurveyHistory(currentYear, props.surveyType)
        setSurvey(res)
    }, [currentYear, props.surveyType])

    useEffect(()=>{
        fetchHSurveyHistory().then()
    },[fetchHSurveyHistory])

    if(!survey) return <LoadingSkeleton/>
    
    return (
        <Box className={classes.root}>
            <Grid container justify="center" className={classes.wrap}>
                <Grid item>
                    <Button
                        className={clsx('year', 'sideLeft')}    
                        startIcon={<KeyboardArrowLeftIcon />}  
                        onClick={()=>{
                            setCurrentYear(currentYear-1)
                        }}>
                        {currentYear-1}년
                    </Button>
                </Grid>
                <Grid item>
                    <Button className={'year'}>
                        {currentYear}년
                    </Button>
                </Grid>
                <Grid item>
                    <Button className={clsx('year', 'sideRight')} 
                            endIcon={<KeyboardArrowRightIcon />} 
                            onClick={()=>{
                        setCurrentYear(currentYear+1)
                    }}>
                        {currentYear+1}년
                    </Button>
                </Grid>
            </Grid>
            
            <Box mt={3} ml={8}>
                <SurveyHeatMap 
                    items={survey.items}
                    startIndex={0}
                    currentYear={currentYear}
                    useTerm={false}
                    recent={props.recent}
                    surveyType={props.surveyType}
                />
            </Box>
        </Box>
    )
}

//로딩 스켈레톤 화면 
export function LoadingSkeleton() {
    return (
        <Box>
            <div className="editprofile_wrap wrapper">
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={118} />
            </div>
        </Box>
    )
}

//자각증상 1년치 히트맵 
export  function SurveyHeatMap(props) {
    const heatmapColor = ColorHelper.getSurveyColors()
    const top5OrderIndex = []
    const classes = useStyles();
    
    if(props.recent && 0 < props.recent.items.length){
        let item 
        for(let i = 0 ; i < 5; i++){
            item = props.recent.items[i]
            if(0 < item.level){
                top5OrderIndex.push(item.order)    
            }
        }
    }
    
    let range = 12
    let startMonth = 1
    if(props.useTerm){
        const term = 1 !== props.currentTerm ? props.currentTerm - 1 : 0
        startMonth = ( term * range) + 1
    }

    const xLabels = new Array(range).fill(0).map((_, i) => `${i+startMonth}월`);
    const xLabelsVisibility = new Array(range).fill(false)

    const yLabels = props.items.map((item, index) => {
        const title = `${index + 1 + props.startIndex}. ${item.content}`
        const isCheck = -1 < top5OrderIndex.indexOf(index + 1)
        return (
            <Box position={'relative'} className={'mapTitle'}>
                <Typography 
                    variant={'body1'} 
                    className={clsx(classes.wrapIcon,{
                        [classes.check] : isCheck
                    })}>
                    {title}
                    {isCheck && <StarIcon className={classes.check}/> }
                </Typography>
                
            </Box>
        )   
    })
    
    const data = new Array(yLabels.length).fill(0).map((_, rowIndex) =>
        new Array(xLabels.length).fill(0).map((_, columnIndex) => {
            const rounds = props.items[rowIndex].rounds
            if(rounds){
                const key = props.currentYear + '-' + ('00' + (columnIndex + startMonth)).substr(-2)
                const surveyAnswer = rounds[key]

                if(surveyAnswer && 0 < surveyAnswer.length){
                    return surveyAnswer[0].symptomLevel
                } else {
                    return null
                }
            }

            return _
        })
    )
    
    yLabels.unshift('')
    data.unshift(xLabels)

    return (
        <HeatMap
            className={classes.map}
            xLabels={xLabels}
            yLabels={yLabels}
            xLabelsLocation={"bottom"}
            xLabelsVisibility={xLabelsVisibility}
            yLabelWidth={550}
            yLabelTextAlign={'left'}
            data={data}
            squares
            cellStyle={(background, value, min, max) => {
                let newBackground = `rgba(123, 201, 110, ${1 - (max - value) / (max - min)})`
                let color = '#333'

                if(!value && 0 !== value) {
                    newBackground = '#f3f3f3'
                    color = `#ddd`
                } else {
                    newBackground = heatmapColor[(value / 10)]
                    if( 0 === value ) {
                        color = '#999'
                        newBackground = '#efefef'
                    } else if ( 60 < value ){
                        color = '#fff'
                    }
                }
                return {
                    background: newBackground,
                    color,
                    fontWeight: '900',
                    fontSize: 13,
                    width: '50px',
                    height: '40px',
                    lineHeight: '28px',
                }
            }}
            cellRender={ (value) => {
                let _value = value
                if('life' === props.surveyType && value && !isNaN(_value)){
                    _value = 100 - Number(value) + 10
                }
                
                return (
                    <Box>
                        {_value}
                    </Box>
                )
            }}
            title={(value) => `${value}`}
        />
    )
}

const useStyles = makeStyles(theme => ({
    wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex'
    },
    check: {
        color: '#FF843A'
    },
    map: {
        '& .mapTitle': {
            backgroundColor: '#efefef'
        }
    },
    root: {
        border: '2px solid #ddd'  
    },
    survey: {
          paddingLeft: 20
    },
    wrap: {
        backgroundColor: '#0054BF',
        '& .year': {
            fontSize: 32,
            fontWeight: 'bold',
            color: 'white'
        }, 
        '& .sideLeft': {
            color: '#CCCCCC',
            paddingRight: 60,
            '& .MuiSvgIcon-root': {
                fontSize: 30
            }
        },
        '& .sideRight': {
            color: '#CCCCCC',
            paddingLeft: 60,
            '& .MuiSvgIcon-root': {
                fontSize: 30
            }
        }
    }
    
}));

export default SurveyYear
